import React, { ReactNode } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker as DateTimePickerMui, DatePicker as DatePickerMui } from '@mui/x-date-pickers';
import { Control, FieldValues, Controller } from 'react-hook-form';
import { styled } from '@mui/material';
import { typography } from '../shared/TypographySharedElements';
import { colors } from '../shared/Colors';

interface CustomDatePickerProps<T> {
  name: Extract<keyof T, string>;
  control: any;
  error?: boolean;
  helperText?: ReactNode;
  defaultValue: any;
  onDateChange?: any;
  disabled?: boolean;
  label: string;
  disablePast?: boolean;
}

export const DateTimePicker = <T,>({
  name,
  control,
  error,
  helperText,
  defaultValue,
  onDateChange,
  disabled,
  label,
  disablePast,
}: CustomDatePickerProps<T>) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div
        className={'mui-date'}
        style={{ width: '100%' }}
      >
        <Controller
          name={name}
          control={control as Control<FieldValues, any>}
          defaultValue={defaultValue}
          render={({ field: { onChange, value } }) => (
            <DateTimePickerElement
              disablePast={disablePast}
              value={value ? new Date(value) : null}
              onChange={(value) => (onDateChange ? onDateChange(value) : onChange(new Date(value)))}
              slotProps={{
                inputAdornment: <i className="material-symbols-outlined">schedule</i>,
                actionBar: {
                  actions: ['today', 'accept'],
                },
                textField: {
                  error: error,
                  helperText: helperText,
                },
              }}
              disabled={disabled}
              label={label}
            />
          )}
        />
      </div>
    </LocalizationProvider>
  );
};

const DatePickerStyling = ({ disabled, label }: { disabled?: boolean; label: string }) => ({
  width: '100%',
  '.MuiInputBase-root.MuiOutlinedInput-root': {
    marginTop: '0px',
    paddingLeft: '0',
    paddingRight: '0',
    minWidth: '100%',
    '.MuiInputAdornment-root.MuiInputAdornment-positionEnd.MuiInputAdornment-outlined.MuiInputAdornment-sizeMedium': {
      position: 'absolute',
      left: 'calc(100% - 36px)',
      top: 'calc(50% + 11px)',
      transform: 'translate(-50%, -50%)',
    },
    '.MuiOutlinedInput-notchedOutline': {
      border: `none`,
      borderRadius: '8px',
    },
    '&::after': {
      display: 'none',
      border: 'none',
    },
    '&::before': {
      display: 'none',
      border: 'none',
    },
    '.MuiInputBase-input.MuiOutlinedInput-input': {
      display: 'flex',
      flexGrow: 1,
      paddingLeft: '12px',
      paddingRight: '12px',
      paddingTop: '13px',
      paddingBottom: '13px',
      marginTop: label ? '22px' : '0',
      width: '100%',
      maxWidth: '100%',
      fontSize: typography.bodyLargeRegular.fontSize,
      fontWeight: typography.bodyLargeRegular.fontWeight,
      lineHeight: typography.bodyLargeRegular.lineHeight,
      letterSpacing: typography.bodyLargeRegular.letterSpacing,
      border: `1px solid ${colors.SystemGray200}`,
      borderRadius: '8px',
      color: disabled ? colors.SystemGray500 : colors.SystemGray900,
    },
    '&.Mui-focused .MuiInputBase-input.MuiOutlinedInput-input': {
      border: `1px solid ${colors.SystemGray400}`,
    },
  },
  '.MuiFormLabel-root.MuiInputLabel-root': {
    top: '0px',
    left: '4px',
    transform: 'none',
    color: colors.SystemGray900,
    ...typography.bodyMediumRegular,
  },
  '.MuiFormHelperText-root': {
    color: colors.SystemGray900,
    ...typography.subtitle,
  },
});

export const DatePickerElement = styled(DatePickerMui, {
  name: 'DatePickerElement',
})(DatePickerStyling);

export const DateTimePickerElement = styled(DateTimePickerMui, {
  name: 'DateTimePickerElement',
})(DatePickerStyling);
