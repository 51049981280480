import React from 'react';
import CreateSRGForm from '../../Forms/CreateSRGForm';

export default function CreateSRG() {
  return (
    <>
      <CreateSRGForm />
    </>
  );
}
