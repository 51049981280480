import React, { useEffect } from 'react';
import { Box, Typography, styled } from '@mui/material';
import { DataCy } from '../../Helpers/DataCy';
import { colors } from '../../ui/shared/Colors';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { setFullscreen } from '../../Redux/UI/UISlice';
import { Button } from '../../ui/buttons/Button';
import { typography } from '../../ui/shared/TypographySharedElements';
import { redirect, useNavigate, useParams } from 'react-router-dom';
import { UserTypeEnum } from './UserTypeEnum';
import { FullPageContainer } from './Shared/Containers';
import { getCookie } from '../../Helpers/HelperFunctions';
import {
  publicAPI,
  useCookieLogInMutation,
  useGetAllArtistsQuery,
  useGetAllShowrunnerGroupsQuery,
  useGetAllVenuesQuery,
} from '../../Redux/API/PublicAPI';

export const RegisterSuccessModal = () => {
  const [login] = useCookieLogInMutation();
  const SECRET_UID = getCookie('SECRET_UID');
  const displayUID = useAppSelector((state) => state.user.data.displayUID);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { userType } = useParams();

  const handleInvalidate = () => {
    dispatch(publicAPI.util.invalidateTags([{ type: 'Artist' }, { type: 'Venue' }, { type: 'SRG' }]));
  };

  useEffect(() => {
    dispatch(setFullscreen({ status: true }));
  }, [dispatch]);

  useEffect(() => {
    if (!displayUID && SECRET_UID) {
      login(SECRET_UID);
    }
    return () => {
      dispatch(setFullscreen({ status: false }));
    };
  }, []);

  useEffect(() => {
    //refetch to accomodate for newly created data types
    if (displayUID && userType) {
      handleInvalidate();
    }
  }, [displayUID]);

  return (
    <FullPageContainerWithBg data-cy={DataCy.modal('registerSuccessModal')}>
      <ResetLinkModalContainer>
        <ResetLinkInnerModalContainer>
          <ResetLinkSentHeadingContainer>
            <ResetLinkHeadingIcon className="material-symbols-outlined">mic_external_on</ResetLinkHeadingIcon>
          </ResetLinkSentHeadingContainer>
          <ResetLinkSentTypographyContainer>
            <ResetLinkSentHeadline>
              {userType === UserTypeEnum.VENUE ? 'Time to put on a show!' : 'Time to shine!'}
            </ResetLinkSentHeadline>
            <ResetLinkSentSubText>
              {userType === UserTypeEnum.VENUE
                ? "You've now registered your venue. Get started and post your first show!"
                : 'Congratulations! You are now registered as an artist.'}
            </ResetLinkSentSubText>
          </ResetLinkSentTypographyContainer>
          <CTAContainer>
            {userType === UserTypeEnum.ARTIST && (
              <>
                <Button
                  isPrimary
                  onClick={() => {
                    navigate('/profile/artist/' + displayUID);
                  }}
                  size="large"
                  data-cy={DataCy.button('checkElectronicPressKit')}
                >
                  Check your electronic press kit
                </Button>
                <Button
                  isPrimary
                  onClick={() => {
                    navigate('/profile/artist/' + displayUID);
                  }}
                  size="large"
                  data-cy={DataCy.button('customizeHatchLink')}
                >
                  Customize your HatchLink
                </Button>
              </>
            )}
            {userType === UserTypeEnum.PROMOTER && (
              <>
                <Button
                  isPrimary
                  onClick={() => {
                    navigate('/showrunner-tools');
                  }}
                  size="large"
                  data-cy={DataCy.button('checkElectronicPressKit')}
                >
                  Check your electronic press kit
                </Button>
                <Button
                  isPrimary
                  onClick={() => {
                    navigate('/showrunner-tools');
                  }}
                  size="large"
                  data-cy={DataCy.button('customizeHatchLink')}
                >
                  Customize your HatchLink
                </Button>
              </>
            )}
            {userType === UserTypeEnum.VENUE && (
              <Button
                isPrimary
                onClick={() => {
                  navigate('/venues/manage');
                }}
                size="large"
                data-cy={DataCy.button('manageVenues')}
              >
                Learn more about how to organize a show
              </Button>
            )}
          </CTAContainer>
        </ResetLinkInnerModalContainer>
        <ButtonContainer>
          <Button
            onClick={() => {
              userType === UserTypeEnum.VENUE && navigate('/venues/manage');
              userType === UserTypeEnum.ARTIST && navigate('/profile/artist/' + displayUID);
              userType === UserTypeEnum.PROMOTER && navigate('/showrunner-tools');
            }}
            size="large"
            data-cy={DataCy.button('close')}
          >
            Close
          </Button>
        </ButtonContainer>
      </ResetLinkModalContainer>
    </FullPageContainerWithBg>
  );
};

const FullPageContainerWithBg = styled(FullPageContainer, {
  name: 'FullPageContainerWithBg',
})({
  backgroundColor: colors.SystemGray500,
  justifyContent: 'center',
  alignItems: 'center',
});

const ResetLinkModalContainer = styled(Box, {
  name: 'ResetLinkModalContainer',
})({
  display: 'flex',
  flexDirection: 'column',
  padding: '24px',
  width: '100%',
  maxWidth: '520px',
  justifyContent: 'space-between',
  borderRadius: '16px',
  backgroundColor: colors.SystemWhite,
});

const ResetLinkInnerModalContainer = styled(Box, {
  name: 'ResetLinkInnerModalContainer',
})({
  display: 'flex',
  flexDirection: 'column',
  gap: '40px',
  paddingBottom: '64px',
});

const ResetLinkSentHeadingContainer = styled(Box, {
  name: 'ResetLinkSentHeadingContainer',
})({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  overflow: 'hidden',
  width: '100%',
  padding: '50px 0',
  borderRadius: '12px',
  backgroundColor: colors.THOrangeLight,
  '&:after': {
    content: '""',
    position: 'absolute',
    left: '-8%',
    top: '25%',
    width: '273px',
    height: '276px',
    transform: 'rotate(12deg)',
    backgroundColor: '#41A4FF',
    borderRadius: '12px',
  },
  '&:before': {
    content: '""',
    position: 'absolute',
    right: '-0.15%',
    top: '-45%',
    width: '273px',
    height: '276px',
    transform: 'rotate(-12deg)',
    backgroundColor: '#FF9534',
    borderRadius: '12px',
  },
});

const ResetLinkHeadingIcon = styled('i', {
  name: 'ResetLinkHeadingIcon',
})({
  width: '186px',
  height: '186px',
  transform: 'rotateY(180deg) rotateZ(11deg)',
  fontSize: '168px',
  zIndex: '2',
  color: colors.SystemGray900,
});

const ResetLinkSentTypographyContainer = styled(Box, {
  name: 'ResetLinkTypographyContainer',
})({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
});

const ResetLinkSentHeadline = styled(Typography, {
  name: 'ResetLinkSentHeadline',
})({
  color: colors.SystemGray900,
  textAlign: 'center',
  fontSize: typography.headlineLarge.fontSize,
  lineHeight: typography.headlineLarge.lineHeight,
  letterSpacing: '-1px',
  fontWeight: 700,
});

const ResetLinkSentSubText = styled(Typography, {
  name: 'ResetLinkSentSubText',
})({
  color: colors.SystemGray900,
  textAlign: 'center',
  ...typography.bodyMediumRegular,
});

const CTAContainer = styled(Box, {
  name: 'CTAContainer',
})({
  display: 'flex',
  flexDirection: 'column',
  width: 'auto',
  gap: '12px',
});

const ButtonContainer = styled(Box, {
  name: 'ButtonContainer',
})({
  margin: '0 auto',
});
