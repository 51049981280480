import { Box, styled, Typography } from '@mui/material';
import React, { FC } from 'react';
import { colors } from '../../ui/shared/Colors';
import { typography } from '../../ui/shared/TypographySharedElements';

export enum ShowType {
  IKWP = 'IKWP',
  GIG = 'Gig',
  EXTERNAL = 'Ext',
}

interface SingleTicketTypeButtonProps {
  title: string;
  subtitle: string;
  code: ShowType;
  handleClick: (val: ShowType) => void;
}

export const SingleTicketTypeButton: FC<SingleTicketTypeButtonProps> = ({ title, subtitle, code, handleClick }) => {
  return (
    <SingleTicketTypeButtonCont
      onClick={() => {
        handleClick(code);
      }}
    >
      <SingleTicketTypeButtonInnerCont>
        <SingleTicketTypeButtonTitle>{title}</SingleTicketTypeButtonTitle>
        <SingleTicketTypeButtonSubtitle>{subtitle}</SingleTicketTypeButtonSubtitle>
      </SingleTicketTypeButtonInnerCont>
      <SingleTicketTypeButtonIcon className="material-symbols-outlined">arrow_forward_ios</SingleTicketTypeButtonIcon>
    </SingleTicketTypeButtonCont>
  );
};

const SingleTicketTypeButtonCont = styled(Box, {
  name: 'SingleTicketTypeButtonCont',
})({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '24px',
  cursor: 'pointer',
  border: `1px solid ${colors.SystemGray200}`,
  borderRadius: '12px',
});

const SingleTicketTypeButtonInnerCont = styled(Box, {
  name: 'SingleTicketTypeButtonInnerCont',
})({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
});

const SingleTicketTypeButtonTitle = styled(Typography, {
  name: 'SingleTicketTypeButtonTitle',
})({
  color: colors.SystemGray900,
  ...typography.headlineSmall,
});

const SingleTicketTypeButtonSubtitle = styled(Typography, {
  name: 'SingleTicketTypeButtonSubtitle',
})({
  color: colors.SystemGray500,
  ...typography.bodySmallRegular,
});

const SingleTicketTypeButtonIcon = styled('i', {
  name: 'SingleTicketTypeButtonIcon',
})({
  color: colors.SystemGray900,
  width: '24px',
  height: '24px',
});
