import willPellerin from '../../../Images/Homepage/PerformersTab/WillPellerin.png';
import blaine from '../../../Images/Homepage/PerformersTab/blaine.png';
import garrett from '../../../Images/Homepage/PerformersTab/garrett.png';

export const performersTestimonials = [
  {
    person: {
      firstName: 'Will Pellerin',
      lastName: '',
      title: '',
      avatarWebp: willPellerin,
    },
    reviewText: `“TuneHatch has been awesome! I love getting paid out immediately after my shows - no delay and no mismatch.”`,
  },
  {
    person: {
      firstName: 'Blaine Holcomb',
      lastName: '',
      title: '',
      avatarWebp: blaine,
    },
    reviewText: `“The listings are so vibey and beautiful, and my fans really like being able to preview the other artists on the bill. Plus, creating the show took literally 2 minutes and it automatically updated my link in bio, so easy.”`,
  },
  {
    person: {
      firstName: 'Garrett Thorn',
      lastName: '',
      title: '',
      avatarWebp: garrett,
    },
    reviewText: `“Using TuneHatch on tour has made the biggest difference. All my shows are in one place and I can manage the payouts to all the artists on my bill, the venues, and have the best looking show pages out there. There's no way I'm going back to Eventbrite.”`,
  },
];
