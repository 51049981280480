import axios from 'axios';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { ViewType } from '../../../Helpers/shared/Models/ViewType';
import {
  useGetExternalUpcomingShowsByArtistQuery,
  useGetExternalShowsByShowrunnerQuery,
} from '../../../Redux/API/ShowAPI';
import { isEmpty, isEqual, isNil } from 'lodash';
import { isAfter } from 'date-fns';
import { ExternalShow } from '../../../Helpers/shared/Models/Show';

interface Props {
  userId: string;
  page: number;
  isMobile: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  type?: ViewType;
}

interface UpcomingShowUser {
  id: string;
  avatar: string;
  avatarWebP: string;
}
interface UpcomingShowArtist extends UpcomingShowUser {
  firstName: string;
  lastName: string;
}
interface UpcomingShowVenueOrShowrunner extends UpcomingShowUser {
  name: string;
}

export interface UpcomingShow {
  id: string;
  artists: UpcomingShowArtist[];
  name: string;
  showrunnerGroups: UpcomingShowVenueOrShowrunner[];
  venue: UpcomingShowVenueOrShowrunner;
  startTime: string;
  hatchLinkLabel?: string;
}
interface UpcomingShowData {
  data: Array<UpcomingShow | ExternalShow>;
  total: number;
}

export const useGetSortedUpcomingShowsWithExternal = ({ userId, page, isMobile, setIsLoading, type }: Props) => {
  let SGID = type === ViewType.SRGroup ? userId : '';
  let artistID = type === ViewType.Artist || type === ViewType.User ? userId : '';
  const [innerShowsLoading, setInnerShowsLoading] = useState(true);
  const externalShowsByArtist = useGetExternalUpcomingShowsByArtistQuery({ artistID }, { skip: isNil(artistID) });
  const externalShowsByShowrunner = useGetExternalShowsByShowrunnerQuery({ SGID }, { skip: isNil(SGID) });
  const [upcomingShows, setUpcomingShows] = useState<UpcomingShowData>({ data: null, total: null });
  const [upcomingShowsWithExternal, setUpcomingShowsWithExternal] = useState<UpcomingShowData>({
    data: null,
    total: null,
  });
  const getUserTypeParam = useMemo(() => {
    switch (type) {
      case ViewType.Artist:
        return 'performerId';
      case ViewType.Venue:
        return 'venueId';
      case ViewType.SRGroup:
        return 'showrunnerId';
      default:
        return 'performerId';
    }
  }, [type]);
  const getUpcomingShows = useCallback(async () => {
    try {
      const response = await axios.get(
        // to do - switch to v2 here
        `${process.env.REACT_APP_PUBLIC_URL}:${process.env.REACT_APP_SERVER_PORT}/api/shows?perPage=30&page=1&${getUserTypeParam}=${userId}&resultOptions[withPerformers]=true&sortBy[startTime]=asc&onlyUpcoming=true&resultOptions[withShowrunnerGroups]=true&resultOptions[withVenue]=true&resultOptions[withArtists]=true`,
      );
      if (response.status === 200) {
        setInnerShowsLoading(false);
        setUpcomingShows(response.data);
      } else {
        setInnerShowsLoading(false);
        setUpcomingShows(null);
      }
    } catch {
      setInnerShowsLoading(false);
      setUpcomingShows(null);
    }
  }, [
    isMobile,
    page,
    userId,
    getUserTypeParam,
    setInnerShowsLoading,
    externalShowsByArtist,
    externalShowsByShowrunner,
  ]);

  useEffect(() => {
    getUpcomingShows();
  }, [getUpcomingShows]);

  useEffect(() => {
    if (
      !innerShowsLoading &&
      externalShowsByArtist.isLoading === false &&
      externalShowsByShowrunner.isLoading === false
    ) {
      setIsLoading(false);
    }
  }, [upcomingShowsWithExternal, externalShowsByArtist, externalShowsByShowrunner]);

  useEffect(() => {
    if (
      !innerShowsLoading &&
      externalShowsByArtist.isLoading === false &&
      externalShowsByShowrunner.isLoading === false
    ) {
      const showsWithExternals: (UpcomingShow | ExternalShow)[] = [...upcomingShows.data];
      let totalSum = upcomingShows.total;
      if (!isEmpty(externalShowsByArtist?.data?.data)) {
        showsWithExternals.push(...externalShowsByArtist.data?.data);
        totalSum += externalShowsByArtist.data.total;
      }
      if (!isEmpty(externalShowsByShowrunner?.data?.data)) {
        showsWithExternals.push(...externalShowsByShowrunner.data?.data);
        totalSum += externalShowsByShowrunner.data.total;
      }

      const sortedShowsWithExternals = showsWithExternals.sort((a, b) => {
        if (isAfter(new Date(a.startTime), new Date(b.startTime))) {
          return 1;
        } else if (isEqual(new Date(a.startTime), new Date(b.startTime))) {
          return 0;
        } else {
          return -1;
        }
      });
      setUpcomingShowsWithExternal({ data: sortedShowsWithExternals, total: totalSum });
    }
  }, [externalShowsByArtist, externalShowsByShowrunner, upcomingShows, innerShowsLoading]);

  return upcomingShowsWithExternal;
};
