import { Box, TextFieldProps, ToggleButton, ToggleButtonGroup, ToggleButtonGroupProps, styled } from '@mui/material';
import { TextField } from '../../../ui/inputs/TextField';
import { colors } from '../../../ui/shared/Colors';
import { typography } from '../../../ui/shared/TypographySharedElements';
import React, { FC, forwardRef } from 'react';

export const FileInputLikeButton = forwardRef<unknown, TextFieldProps>(({ InputProps, ...props }, ref) => {
  return (
    <FileInputLikeButtonElement
      InputProps={{ ...InputProps }}
      inputRef={ref}
      {...props}
    />
  );
});
const FileInputLikeButtonElement = styled(TextField, {
  name: 'FileInputLikeButton',
})({
  display: 'flex',
  height: 'auto',
  position: 'relative',
  flexGrow: 0,
  backgroundColor: colors.SystemGray100,
  padding: '8px 16px',
  border: 'none',
  borderRadius: '990px',
  width: '112px',
  '.MuiInputBase-root.MuiInput-root': {
    height: '20px',
    '.MuiInputBase-input.MuiInput-input': {
      position: 'absolute',
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      opacity: 0,
      border: 'none',
      padding: 0,
      margin: 0,
    },
  },
});

export const FormToggleGroup = forwardRef<unknown, ToggleButtonGroupProps>(({ ...props }, ref) => {
  return (
    <FormToggleGroupElement
      {...props}
      ref={ref}
    />
  );
});

const FormToggleGroupElement = styled(ToggleButtonGroup, {
  name: 'FormInputGroup',
})({
  '&.MuiToggleButtonGroup-root': {
    display: 'flex',
    gap: '16px',
    flexGrow: 0,
  },
});

export const Toggle = styled(ToggleButton, {
  name: 'ToggleSigned',
})({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '73px',
  height: '68px',
  backgroundColor: colors.SystemGray100,
  borderRadius: '999px',
  textTransform: 'none',
  color: colors.SystemGray900,
  ...typography.buttonSmall,
  '&:hover': {
    backgroundColor: colors.SystemGray300,
  },
  '&.Mui-selected': {
    backgroundColor: colors.SystemGray400,
    '&:hover': {
      backgroundColor: colors.SystemGray300,
    },
  },
  '&.MuiButtonBase-root.MuiToggleButtonGroup-grouped.MuiToggleButtonGroup-groupedHorizontal.MuiToggleButton-root.MuiToggleButton-sizeMedium.MuiToggleButton-standard.MuiToggleButtonGroup-firstButton':
    {
      borderTopRightRadius: '999px',
      borderBottomRightRadius: '999px',
      border: 'none',
    },
  '&.MuiButtonBase-root.MuiToggleButtonGroup-grouped.MuiToggleButtonGroup-groupedHorizontal.MuiToggleButton-root.MuiToggleButton-sizeMedium.MuiToggleButton-standard.MuiToggleButtonGroup-lastButton':
    {
      borderTopLeftRadius: '999px',
      borderBottomLeftRadius: '999px',
      border: 'none',
    },
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: string;
  value: string;
}
export const TabPanel: FC<TabPanelProps> = ({ children, value, index }) => {
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index && <Box width="100%">{children}</Box>}
    </Box>
  );
};
