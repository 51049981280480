import { ViewType } from './ViewType';
import ExternalLink from './ExternalLink';

/**
 * Note: this is a duplicate of the ColorExtended type in '../../../Pages/EditProfile/HatchLinksColors/ColorPickers.tsx'
 * This is needed because we can't import the type from there into this file @RDF
 */
type ColorExtended = {
  hex: string;
  isDark: boolean;
};

/**
 * This defines the status if venue is already claimed (confirmed by email):
 *  pending-off-platform - invitation sent but not claimed yet
 *  claimed - invitation has been confirmed by venue
 */

export enum VenueClaimStatus {
  PENDING_OFFPLATFORM = 'pending-off-platform',
  CLAIMED = 'claimed',
}

export interface Venue {
  uid: string;
  _key: string;
  name: string;
  description: string;
  avatar?: string;
  avatarWebP?: string;
  banner?: string;
  bannerWebP?: string;
  type: ViewType.Venue;
  meta: any;
  themeColor?: string;
  capacity?: string;
  performanceAgreement?: any;
  hasTextContactEnabled?: boolean;
  customBackline?: string;
  min_age?: number;
  address?: string;
  location?: any;
  contact?: any;
  about?: string;
  phone?: string;
  email?: string;
  images?: any[];
  socials?: any;
  venueFee?: string;
  media?: string;
  stripe?: any;
  terminalLocation?: string;
  dealProfiles?: { [key: string]: any };
  isOpenForManagers?: boolean;
  claim_status?: VenueClaimStatus;
  username?: string;
  externalLinks?: ExternalLink[];
  customOptions?: {
    alternativeHatchLinkLabel?: boolean;
    colors?: {
      profileColor?: ColorExtended;
      colorsToPickFrom?: ColorExtended[];
      hlColor?: ColorExtended;
    };
  };
  tourStatus?: {
    profileTour: {
      step: number;
      completed: boolean;
    };
  };
}
