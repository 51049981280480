import z from 'zod';
import { RegularExp } from '../../../Helpers/RegularExp';
import { locationValueSchema } from '../../../ui/inputs/LocationField';
import { HatchlinksFormData } from '../HatchLinks/HatchlinksFormData';

export namespace VenueFormData {
  export const step1Schema = z.object({
    venueName: z.string().min(1, 'You must enter your venue name'),
    ageRestriction: z
      .string()
      .min(1, 'You must enter an age restriction')
      .regex(RegularExp.Int, 'You must enter a number'),
    capacity: z.string().min(1, 'You must enter capacity').regex(RegularExp.Int, 'You must enter a number'),
    owner: z.object({
      firstName: z.string().min(1, 'You must enter your first name'),
      lastName: z.string().min(1, 'You must enter your last name'),
    }),
    contact: z.object({
      phone: z.string().min(1, 'You must enter your contact phone number'),
      email: z.string().min(1, 'You must enter your contact email').email('The provided value must be an email'),
    }),
    address: z.object({
      ...locationValueSchema.shape,
      displayAddress: z.string().min(1, 'You must enter your address'),
    }),
  });
  export type Step1Values = z.output<typeof step1Schema>;
  export const step1Fields = Object.keys(step1Schema.shape) as (keyof Step1Values)[];

  const operatingSpanSchema = z.object({ from: z.string(), to: z.string() }).nullable();
  export const step2Schema = z.object({
    operatingHours: z
      .object({
        monday: operatingSpanSchema,
        tuesday: operatingSpanSchema,
        wednesday: operatingSpanSchema,
        thursday: operatingSpanSchema,
        friday: operatingSpanSchema,
        saturday: operatingSpanSchema,
        sunday: operatingSpanSchema,
      })
      .nullable(),
  });
  export type Step2Values = z.output<typeof step2Schema>;
  export const step2Fields = Object.keys(step2Schema.shape) as (keyof Step2Values)[];

  export const step3Schema = z.object({
    socials: z.object({
      instagram: z
        .string()
        .regex(RegularExp.InstagramUsername, 'The link must be a valid Instagram username')
        .or(z.literal(''))
        .nullable(),
      tiktok: z.string().nullable(),
      spotify: z.string().nullable(),
      youtube: z
        .string()
        .regex(RegularExp.YoutubeVideo, 'The link must be a YouTube video URL, channels are not allowed')
        .or(z.literal(''))
        .nullable(),
    }),
    about: z.string().nullish(),
  });
  export type Step3Values = z.output<typeof step3Schema>;
  export const step3Fields = Object.keys(step3Schema.shape) as (keyof Step3Values)[];

  export const step4Schema = z.object({});
  export type Step4Values = z.output<typeof step4Schema>;
  export const step4Fields = Object.keys(step4Schema.shape) as (keyof Step4Values)[];

  export const formSchema = step1Schema.merge(step2Schema).merge(step3Schema).merge(step4Schema);
  export const formSchemaFull = step1Schema
    .merge(step2Schema)
    .merge(step3Schema)
    .merge(step4Schema)
    .merge(HatchlinksFormData.formSchema);
  export type Values = z.TypeOf<typeof formSchema>;
  export type ValuesFull = z.TypeOf<typeof formSchemaFull>;
}
