import React, { FC, useMemo, useRef, useState } from 'react';
import { ParsedNotifications } from '../../Helpers/shared/Models/Notifications';
import { Box, styled } from '@mui/material';
import { colors } from '../../ui/shared/Colors';
import { typography } from '../../ui/shared/TypographySharedElements';
import { useAppSelector } from '../../hooks';
import { parseNotifications } from '../../Helpers/HelperFunctions';
import { useAllNotificationsReadMutation, useClearNotificationsMutation } from '../../Redux/API/UserAPI';
import { NotificationsMenu } from './NotificationMenu';

interface NotificationsProps {
  limit?: number;
  notifications: {
    read?: ParsedNotifications[];
    unread?: ParsedNotifications[];
  };
}

export const Notifications: FC<NotificationsProps> = ({ limit, notifications }) => {
  const ref = useRef(null);
  const darkMode = useAppSelector((state) => state.ui.grid.darkMode);
  let venueList = useAppSelector((state) => state.user.data.venues);
  let [expanded, setExpanded] = useState(false);
  const user = useAppSelector((state) => state.user.data);
  const [notifsAllRead] = useAllNotificationsReadMutation();
  const SECRET_UID = useAppSelector((state) => state.user.data.uid);
  const [clearNotifications] = useClearNotificationsMutation();

  const clearAllNotifs = () => {
    clearNotifications({ SECRET_UID, venueList: venueList || [] });
  };

  const handleVisibility = (state: boolean) => {
    setExpanded(state);
    if (state === false && unread > 0) {
      notifsAllRead({ SECRET_UID, venueList: venueList || [] });
    }
  };

  const { sortedNotifications, unread } = useMemo(() => {
    const notifData =
      Object.keys(notifications).length > 0
        ? parseNotifications(notifications)
        : {
            notifications: [],
            unread: user?.type?.artist?.enabled && !user.stripeEnabled ? 1 : 0,
          };

    return {
      sortedNotifications: notifData.notifications,
      unread: notifData.unread,
    };
  }, [notifications]);

  return (
    sortedNotifications && (
      <>
        <NotificationIconContainer ref={ref}>
          <NotificationIcon
            darkMode={darkMode}
            className="material-symbols-outlined"
            onClick={() => setExpanded(!expanded)}
          >
            notifications
          </NotificationIcon>
          {unread > 0 && (
            <NotificationDot onClick={() => handleVisibility(!expanded)}>{unread < 10 ? unread : '+'}</NotificationDot>
          )}
        </NotificationIconContainer>
        <NotificationsMenu
          refEl={ref.current}
          isOpen={expanded}
          setIsOpen={setExpanded}
          limit={limit}
          clearAllNotifs={clearAllNotifs}
          handleVisibility={handleVisibility}
          notifications={notifications}
          sortedNotifications={sortedNotifications}
        />
      </>
    )
  );
};

const NotificationIconContainer = styled(Box, {
  name: 'NotificationIconContainer',
})({
  display: 'block',
  paddingTop: 8,
  position: 'relative',
});

const NotificationIcon = styled('i', {
  name: 'NotificationIcon',
  shouldForwardProp: (prop) => prop !== 'darkMode',
})<{ darkMode: boolean }>(({ darkMode }) => ({
  width: '24px',
  height: '24px',
  marginRight: '20px',
  color: darkMode ? colors.SystemGray500 : colors.SystemGray900,
}));

const NotificationDot = styled(Box, {
  name: 'NotificationDot',
})({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  bottom: 0,
  right: '12px',
  width: '16px',
  height: '16px',
  borderRadius: '100%',
  backgroundColor: colors.AlertRed,
  color: colors.SystemWhite,
  ...typography.bodySmallRegular,
});
