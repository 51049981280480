import React, { FC, useState } from 'react';
import { RightHandSideComponentTitle } from '../Shared/SharedElements';
import { RightHandSideComponentContainer } from '../Shared/SharedContainers';
import { Box, Typography } from '@mui/material';
import { RecentlyPlayedWithNames } from './RecentlyPlayedWithNames';
import { useParams } from 'react-router-dom';
import LoadingSpinner from '../../../Components/LoadingSpinner';
import { useGetTuneStats } from '../TuneStats/useGetTuneStats';

interface RecentlyPlayedWithProps {
  userID?: string;
}

export const RecentlyPlayedWith: FC<RecentlyPlayedWithProps> = ({ userID }) => {
  const { profileID } = useParams();
  userID = String(userID || profileID);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const tuneStats = useGetTuneStats({ userId: userID, setIsLoading, setError });

  //dont show if there are no artists
  if (tuneStats?.artistsRecentlyPerformedWith.length === 0) {
    return null;
  }

  return (
    <RightHandSideComponentContainer>
      <RightHandSideComponentTitle>Recently Played with</RightHandSideComponentTitle>
      {error && <Typography>{error}</Typography>}
      {isLoading && !error && (
        <Box
          display="block"
          position="relative"
        >
          <LoadingSpinner />
        </Box>
      )}
      {!error && !isLoading && tuneStats && (
        <RecentlyPlayedWithNames artistsList={tuneStats?.artistsRecentlyPerformedWith} />
      )}
    </RightHandSideComponentContainer>
  );
};
