import dayjs from 'dayjs';
import React from 'react';
import styled from 'styled-components';
import { ShowShareStatus, ShowShareTargetStatus, ShareErrorCode } from '../Helpers/shared/Models/Show';
import FacebookIcon from '../Images/Icons/Facebook.webp';
import InstagramIcon from '../Images/Icons/Instagram.webp';
import { useGetAllShowsQuery } from '../Redux/API/PublicAPI';
import { useCancelScheduledShare } from '../Redux/API/VenueAPI';
import Img from './Images/Img';

const errorCodeToMsgMap: Record<ShareErrorCode, string> = {
  [ShareErrorCode.Unknown]: 'Something went wrong',
};

export default function FlyerSharingStatus(props: { showID: string; useListView?: boolean }) {
  const [triggerCancelScheduledShare, cancelScheduledShareStatus] = useCancelScheduledShare();
  const shows = useGetAllShowsQuery();
  const show = shows.data?.[props.showID];
  const shareStatus: ShowShareStatus | null = show?.shareStatus ?? null;
  const determineShareOutput = (status: ShowShareTargetStatus | null) => {
    if (status === null) {
      return 'Not shared yet';
    }
    if (status.error !== null) {
      return <ErrorMsg>Error: {errorCodeToMsgMap[status.error] ?? errorCodeToMsgMap[ShareErrorCode.Unknown]}</ErrorMsg>;
    }
    if (status.scheduledAt !== null) {
      return (
        <span>
          Scheduled: {dayjs(status.scheduledAt).format('MM/DD/YYYY [at] h:mm A')}
          {status.jobId !== null && (
            <>
              {' - '}
              <ButtonLink
                onClick={() =>
                  triggerCancelScheduledShare({ venueId: show.venueID, showId: props.showID, jobId: status.jobId })
                }
              >
                Cancel
              </ButtonLink>
            </>
          )}
        </span>
      );
    }
    if (status.sharedAt !== null) {
      return <span>Shared: {dayjs(status.sharedAt).format('MM/DD/YYYY [at] h:mm A')}</span>;
    }
  };
  if (shareStatus === null) {
    return <></>;
  }
  if (cancelScheduledShareStatus.isLoading === true) {
    return <Box>Loading...</Box>;
  }

  return show ? (
    !props.useListView ? (
      <Box>
        <p className="flex w-full text-center justify-center font-bold">Flyer Sharing Status</p>
        <SocialBox className="flex w-full text-center justify-center">
          <Img
            src={FacebookIcon}
            className="w-6 h-6"
          />{' '}
          {determineShareOutput(shareStatus.facebook)}
        </SocialBox>
        <SocialBox className="flex w-full text-center justify-center">
          <Img
            src={InstagramIcon}
            className="w-6 h-6"
          />{' '}
          {determineShareOutput(shareStatus.instagram)}
        </SocialBox>
      </Box>
    ) : (
      <Box>
        <SocialBox className="flex w-full">
          <Img
            src={FacebookIcon}
            className="w-6 h-6"
          />{' '}
          {determineShareOutput(shareStatus.facebook)}
        </SocialBox>
        <SocialBox className="flex w-full ">
          <Img
            src={InstagramIcon}
            className="w-6 h-6"
          />{' '}
          {determineShareOutput(shareStatus.instagram)}
        </SocialBox>
      </Box>
    )
  ) : (
    <></>
  );
}

const Box = styled.div`
  padding: 3px;
`;

const SocialBox = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 0;
  gap: 5px;
`;

const ErrorMsg = styled.span`
  color: #bd0000;
  font-weight: 500;
`;

const ButtonLink = styled.button`
  font-weight: 500;
  color: rgb(96, 165, 250);

  &:hover {
    color: rgb(124, 184, 255);
  }
`;
