import { MenuItem } from '@mui/material';
import React, { FC } from 'react';
import { TH_GENRES } from '../../../Helpers/configConstants';
import { TextField, Select } from '../../../ui/inputs/TextField';
import {
  FormTitleWithSubtitleContainer,
  FormSectionContainer,
  FormInputGroupWrapper,
  FormInputGroupElementWrapper,
} from '../Shared/Containers';
import { FormSectionTitle, FormSectionSubtitle, GrayText } from '../Shared/RegistrationTypographies';
import { useFormContext } from 'react-hook-form';
import { LocationField } from '../../../ui/inputs/LocationField';

export const PromoterPersonalDetailsForm: FC = () => {
  const promoterForm = useFormContext();
  return (
    <>
      <FormSectionContainer>
        <FormSectionTitle>Who are you?</FormSectionTitle>
        <TextField
          {...promoterForm.register('groupName')}
          type="text"
          label="Group Name"
          placeholder="Enter group name"
          error={!!promoterForm.formState.errors?.groupName?.message}
          helperText={
            !!promoterForm.formState.errors?.groupName?.message && (
              <>{promoterForm.formState.errors?.groupName?.message}</>
            )
          }
        />
        <FormInputGroupWrapper>
          <FormInputGroupElementWrapper>
            <Select
              {...promoterForm.register('genre')}
              type="text"
              label="Genre"
              error={!!promoterForm.formState.errors?.genre?.message}
              helperText={
                !!promoterForm.formState.errors?.genre?.message && <>{promoterForm.formState.errors?.genre?.message}</>
              }
              select
              placeholder="Select"
              value={promoterForm.getValues('genre')}
            >
              <MenuItem
                value="none"
                disabled
              >
                Select
              </MenuItem>
              {TH_GENRES.map((genre) => {
                return (
                  <MenuItem
                    key={genre}
                    value={genre}
                  >
                    {genre}
                  </MenuItem>
                );
              })}
            </Select>
          </FormInputGroupElementWrapper>
          <FormInputGroupElementWrapper>
            <TextField
              {...promoterForm.register('subGenre')}
              type="text"
              label={
                <>
                  Any sub-genre? <GrayText>(Optional)</GrayText>
                </>
              }
              placeholder="Enter sub genre"
              error={!!promoterForm.formState.errors?.subGenre?.message}
              helperText={
                !!promoterForm.formState.errors?.subGenre?.message && (
                  <>{promoterForm.formState.errors?.subGenre?.message}</>
                )
              }
            />
          </FormInputGroupElementWrapper>
        </FormInputGroupWrapper>
      </FormSectionContainer>
      <FormSectionContainer>
        <FormSectionTitle>Contact Details</FormSectionTitle>
        <FormInputGroupWrapper>
          <FormInputGroupElementWrapper>
            <TextField
              {...promoterForm.register('firstName')}
              type="text"
              label="First Name"
              placeholder="Enter first name"
              error={!!promoterForm.formState.errors?.firstName?.message}
              helperText={
                !!promoterForm.formState.errors?.firstName?.message && (
                  <>{promoterForm.formState.errors?.firstName?.message}</>
                )
              }
            />
          </FormInputGroupElementWrapper>
          <FormInputGroupElementWrapper>
            <TextField
              {...promoterForm.register('lastName')}
              type="text"
              label="Last Name"
              placeholder="Enter last name"
              error={!!promoterForm.formState.errors?.lastName?.message}
              helperText={
                !!promoterForm.formState.errors?.lastName?.message && (
                  <>{promoterForm.formState.errors?.lastName?.message}</>
                )
              }
            />
          </FormInputGroupElementWrapper>
        </FormInputGroupWrapper>
        <FormInputGroupWrapper>
          <FormInputGroupElementWrapper>
            <TextField
              {...promoterForm.register('contact.phone')}
              type="tel"
              label="Contact phone"
              placeholder="Enter contact phone"
              error={promoterForm.getFieldState('contact.phone').invalid}
              helperText={
                promoterForm.getFieldState('contact.phone').invalid && (
                  <>{promoterForm.getFieldState('contact.phone').error?.message}</>
                )
              }
            />
          </FormInputGroupElementWrapper>
          <FormInputGroupElementWrapper>
            <TextField
              {...promoterForm.register('contact.email')}
              type="email"
              label="Contact Email"
              placeholder="Enter contact email"
              error={promoterForm.getFieldState('contact.email').invalid}
              helperText={
                promoterForm.getFieldState('contact.email').invalid && (
                  <>{promoterForm.getFieldState('contact.email').error?.message}</>
                )
              }
            />
          </FormInputGroupElementWrapper>
        </FormInputGroupWrapper>
      </FormSectionContainer>
      <FormSectionContainer>
        <FormTitleWithSubtitleContainer>
          <FormSectionTitle>Where do you want to play?</FormSectionTitle>
          <FormSectionSubtitle>
            Your primary city is where you play regularly, when your secondary city is where you play 2nd most often.
          </FormSectionSubtitle>
        </FormTitleWithSubtitleContainer>
        <FormInputGroupWrapper>
          <FormInputGroupElementWrapper>
            <LocationField
              fieldName={'primaryCity'}
              label="Primary City"
              placeholder="Enter your primary city"
              types={['locality']}
            />
          </FormInputGroupElementWrapper>
          <FormInputGroupElementWrapper>
            <LocationField
              fieldName={'secondaryCity'}
              label="Secondary City"
              placeholder="Enter your secondary city"
              types={['locality']}
            />
          </FormInputGroupElementWrapper>
        </FormInputGroupWrapper>
      </FormSectionContainer>
    </>
  );
};
