import { Box, InputAdornment, styled } from '@mui/material';
import React, { FC, useCallback, ChangeEvent, Dispatch, SetStateAction, useMemo } from 'react';
import { DataCy } from '../../../Helpers/DataCy';
import { FormSectionContainer, FormInputGroupWrapper, FormInputGroupElementWrapper } from '../Shared/Containers';
import {
  FormSectionTitle,
  LogosInformationText,
  FileInputLikeButtonText,
  GrayText,
} from '../Shared/RegistrationTypographies';
import kimbros from '../../../Images/Venues/kimbros.webp';
import the5spot from '../../../Images/Venues/the-5-spot.webp';
import springwater from '../../../Images/Venues/springwater-club-lounge.webp';
import teddystavern from '../../../Images/Venues/teddys-tavern.webp';
import raystevens from '../../../Images/Venues/ray-stevens.webp';
import { Avatar } from '../Shared/RegistrationIcons';
import AvatarImg from '../../../Images/Avatar.webp';
import { TextField } from '../../../ui/inputs/TextField';
import { Button } from '../../../ui/buttons/Button';
import { FileInputLikeButton } from '../Shared/Other';
import { ButtonIcon, SpecialButtonIcon } from '../../../ui/shared/ButtonIcon';
import { colors } from '../../../ui/shared/Colors';
import { useFormContext } from 'react-hook-form';

interface VenuePublicProfileProps {
  setAvatar: Dispatch<SetStateAction<File | null>>;
  selectedAvatar: File | null;
  defaultAvatar?: string | null;
  setForceDirty?: Dispatch<SetStateAction<boolean>>;
}

export const VenuePublicProfileForm: FC<VenuePublicProfileProps> = ({
  setAvatar,
  selectedAvatar,
  defaultAvatar,
  setForceDirty,
}) => {
  const venueForm = useFormContext();

  const avatarPreview = useMemo<string | null>(() => {
    if (selectedAvatar === null) {
      return null;
    }
    return window.URL.createObjectURL(selectedAvatar);
  }, [selectedAvatar]);

  return (
    <>
      <FormSectionContainer>
        <FormSectionTitle>Upload profile photo</FormSectionTitle>
        <Box
          display="flex"
          alignItems="center"
          gap="16px"
        >
          <Avatar
            src={avatarPreview ?? defaultAvatar ?? AvatarImg}
            dataCy={DataCy.image('uploadedAvatar')}
          />
          <Box data-cy={'venueLogo'}>
            <FileInputLikeButton
              name="avatar"
              type="file"
              size="small"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setAvatar(e.target.files[0] ?? null);
                setForceDirty?.(true);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <ButtonIcon
                      size="small"
                      disabled={false}
                      iconName="file_upload"
                    />
                    <FileInputLikeButtonText>Upload</FileInputLikeButtonText>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          {avatarPreview !== null && (
            <Button
              sx={{ backgroundColor: colors.AlertRedLight }}
              size="small"
              startIcon={
                <SpecialButtonIcon
                  disabled={false}
                  size="small"
                  iconName="close"
                />
              }
              onClick={() => setAvatar(null)}
            >
              Remove
            </Button>
          )}
        </Box>{' '}
        <LogosInformationText>Note: your image will be cropped into a circle</LogosInformationText>
        <LogosInformationText>Look how other venues did it</LogosInformationText>
        <GroupOfLogosExamples>
          <Avatar src={springwater} />
          <Avatar src={kimbros} />
          <Avatar src={raystevens} />
          <Avatar src={the5spot} />
          <Avatar src={teddystavern} />
        </GroupOfLogosExamples>
      </FormSectionContainer>
      <FormSectionContainer>
        <FormSectionTitle>
          Add social links <GrayText>(Optional)</GrayText>
        </FormSectionTitle>
        <FormInputGroupWrapper>
          <FormInputGroupElementWrapper>
            <TextField
              {...venueForm.register('socials.instagram')}
              type="text"
              label="Instagram"
              placeholder="Enter your Instagram username"
              error={venueForm.getFieldState('socials.instagram').invalid}
              helperText={
                venueForm.getFieldState('socials.instagram').invalid && (
                  <>{venueForm.getFieldState('socials.instagram').error.message}</>
                )
              }
            />
          </FormInputGroupElementWrapper>
          <FormInputGroupElementWrapper>
            <TextField
              {...venueForm.register('socials.tiktok')}
              type="text"
              label="TikTok"
              placeholder="Paste a link or write @username"
              error={venueForm.getFieldState('socials.tiktok').invalid}
              helperText={
                venueForm.getFieldState('socials.tiktok').invalid && (
                  <>{venueForm.getFieldState('socials.tiktok').error.message}</>
                )
              }
            />
          </FormInputGroupElementWrapper>
        </FormInputGroupWrapper>
        <FormInputGroupWrapper>
          <FormInputGroupElementWrapper>
            <TextField
              {...venueForm.register('socials.spotify')}
              type="text"
              label="Spotify"
              placeholder="Paste a link"
              error={venueForm.getFieldState('socials.spotify').invalid}
              helperText={
                venueForm.getFieldState('socials.spotify').invalid && (
                  <>{venueForm.getFieldState('socials.spotify').error.message}</>
                )
              }
            />
          </FormInputGroupElementWrapper>
          <FormInputGroupElementWrapper>
            <TextField
              {...venueForm.register('socials.youtube')}
              type="text"
              label="YouTube"
              placeholder="Paste a link"
              error={venueForm.getFieldState('socials.youtube').invalid}
              helperText={
                venueForm.getFieldState('socials.youtube').invalid && (
                  <>{venueForm.getFieldState('socials.youtube').error.message}</>
                )
              }
            />
          </FormInputGroupElementWrapper>
        </FormInputGroupWrapper>
      </FormSectionContainer>
      <FormSectionContainer>
        <FormSectionTitle>
          Tell a few words about your venue to others <GrayText>(Optional)</GrayText>
        </FormSectionTitle>
        <TextField
          {...venueForm.register('about')}
          type="text"
          label="About"
          placeholder="Write something about yourself"
          error={!!venueForm.formState.errors?.about?.message}
          helperText={!!venueForm.formState.errors?.about?.message && <>{venueForm.formState.errors?.about?.message}</>}
          multiline
          minRows={5}
          maxRows={5}
        />
      </FormSectionContainer>
    </>
  );
};

const GroupOfLogosExamples = styled(Box, {
  name: 'GroupOfLogosExamples',
})({
  display: 'flex',
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
  alignItems: 'center',
  gap: '16px',
});
