import { Dialog, styled } from '@mui/material';
import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { YouTubeEmbed } from '../../ProfilePage/YouTube/YouTubeEmbed';
import { YouTubeSkeleton } from '../../ProfilePage/YouTube/YouTubeSkeleton';
import { colors } from '../../../ui/shared/Colors';

interface HeroVidModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const HeroVidModal: FC<HeroVidModalProps> = ({ isOpen, setIsOpen }) => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <VideoModal
      open={isOpen}
      onClose={() => setIsOpen(false)}
    >
      {isLoading && <YouTubeSkeleton />}
      <YouTubeEmbed
        link="https://www.youtube.com/watch?v=UVOORMhrflI"
        setIsLoading={setIsLoading}
        isLoading={isLoading}
      />
    </VideoModal>
  );
};

const VideoModal = styled(Dialog, {
  name: 'VideoModal',
})({
  width: '100%',
  height: '100%',
  '.MuiPaper-root': {
    width: '80%',
    height: 'auto',
  },
  '.MuiBackdrop-root': {
    backgroundColor: colors.SystemGray900,
    opacity: 0.7,
  },
});
