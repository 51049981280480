import { FastAverageColor } from 'fast-average-color';
import React, { useCallback, useState } from 'react';
import { ColorExtended } from './ColorPickers';

interface GetExtendedColorFromHexProps {
  hex: string;
}

export const getExtendedColorFromHex = async ({ hex }: GetExtendedColorFromHexProps) => {
  let extendedColor: ColorExtended;

  const getExtendedColor = async () => {
    const fac = new FastAverageColor();
    try {
      const canvas = document.createElement('canvas');
      const canvasCtx = canvas.getContext('2d');
      canvasCtx.fillStyle = hex;
      canvasCtx.fillRect(0, 0, canvas.width, canvas.height);
      const base64Image = canvas.toDataURL('image/jpeg', 0.7);
      if (base64Image) {
        const image = new Image();
        image.src = base64Image;
        const color = await fac.getColorAsync(base64Image, { algorithm: 'dominant' });
        extendedColor = { hex: color.hex, isDark: color.isDark };
      }
      fac.destroy();
    } catch {
      console.log('sth went wrong');
      fac.destroy();
    }
  };

  if (hex) {
    await getExtendedColor();
  }

  console.log(extendedColor, 'we came here');

  return extendedColor;
};
