import { Box, Tooltip, Typography, styled } from '@mui/material';
import React, { FC } from 'react';
import { colors } from '../../../ui/shared/Colors';
import { typography } from '../../../ui/shared/TypographySharedElements';

interface TuneStatBoxProps {
  title: string;
  tooltipText: string;
  amount: string;
}

export const TuneStatBox: FC<TuneStatBoxProps> = ({ title, tooltipText, amount }) => {
  return (
    <TuneStatBoxMainContainer>
      <TuneStatBoxInnerContainer>
        <TuneStatName>{title}</TuneStatName>
        <StatBoxTooltip title={tooltipText}>
          <TooltipIcon className="material-symbols-outlined">help</TooltipIcon>
        </StatBoxTooltip>
      </TuneStatBoxInnerContainer>
      <TuneStatAmount>{amount}</TuneStatAmount>
    </TuneStatBoxMainContainer>
  );
};

const TuneStatBoxMainContainer = styled(Box, {
  name: 'TuneStatBoxMainContainer',
})({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '12px 28px',
  borderRadius: '12px',
  backgroundColor: 'rgba(242, 240, 236, 0.5)',
});

const TuneStatBoxInnerContainer = styled(Box, {
  name: 'TuneStatBoxInnerContainer',
})({
  display: 'flex',
  gap: '8px',
  flexGrow: 1,
});

const StatBoxTooltip = styled(Tooltip, {
  name: 'StatBoxTooltip',
})({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const TooltipIcon = styled('i', {
  name: 'TooltipIcon',
})({
  width: '20px',
  height: '20px',
  borderRadius: '100%',
  backgroundColor: colors.SystemGray400,
  color: 'white',
});

const TuneStatName = styled(Typography, {
  name: 'TuneStatName',
})({
  color: colors.SystemGray900,
  ...typography.subtitle,
});

const TuneStatAmount = styled(Typography, {
  name: 'TuneStatAmount',
})(({ theme }) => ({
  color: colors.SystemBlack,
  ...typography.headlineLarge,
  [theme.breakpoints.down('lg')]: {
    ...typography.headlineMedium,
  },
}));
