import React, { PropsWithChildren, useCallback, useState } from 'react';
import { ViewType } from '../../Helpers/shared/Models/ViewType';
import { Button } from '../Buttons/Button';
import { openModal } from '../../Redux/UI/UISlice';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { format } from 'date-fns';
import { LabelButton } from '../Buttons/LabelButton';
import { ExternalShow } from '../../Helpers/shared/Models/Show';
import {
  useRemoveExternalShowByArtistMutation,
  useRemoveExternalShowByShowrunnerMutation,
} from '../../Redux/API/ShowAPI';
import { useNavigate } from 'react-router-dom';

export default function ExternalShowTile(
  props: PropsWithChildren<{
    show: ExternalShow;
    viewType?: ViewType;
    manageView?: boolean;
    hideDate?: boolean;
    showrunnerID?: string;
  }>,
) {
  const user = useAppSelector((state) => state.user.data);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const eventStartDate = new Date(props.show.startTime);
  const month = format(eventStartDate, 'MMM');
  const dayOfMonth = format(eventStartDate, 'd');
  const [open, setOpen] = useState(false);
  const [removeExternalTicketByArtist] = useRemoveExternalShowByArtistMutation();
  const [removeExternalTicketByShowrunner] = useRemoveExternalShowByShowrunnerMutation();

  const handleDeleteExternalTicket = useCallback(() => {
    if (props.viewType === ViewType.Artist && user.displayUID) {
      removeExternalTicketByArtist({ artistID: user.displayUID, showID: props.show.id });
    } else {
      removeExternalTicketByShowrunner({ SGID: props.showrunnerID, showID: props.show.id });
    }
  }, [user.displayUID, removeExternalTicketByArtist, removeExternalTicketByShowrunner]);

  return (
    <>
      <div className="flex flex-col flex-grow w-full border-b">
        <div
          className="flex hover:bg-gray-200 bg-white w-full flex-grow"
          onClick={() => setOpen(!open)}
        >
          <div className="flex flex-col lg:flex-row w-full">
            <div className="flex flex-grow">
              <div className="p-3 flex flex-col flex-shrink-0 justify-center flex-grow-0 w-14">
                <>
                  <>
                    <p className="text-xs text-center">{month}</p>
                    <p className="text-s text-center">{dayOfMonth}</p>
                  </>
                </>
              </div>
              <div className="flex-grow p-2 flex flex-col justify-center">
                <h1 className="text-xl font-black">{props.show.title}</h1>
              </div>
            </div>
            <div className="flex flex-grow w-full md:w-auto lg:flex-grow-0 items-center p-1 pt-0 md:p-2 ">
              <i className={`material-symbols-outlined transition ${open ? '-rotate-180' : ''}`}>expand_more</i>
            </div>
          </div>
        </div>
      </div>
      <div className={`${!open ? 'max-h-0 overflow-hidden' : 'max-h-full p-1 pb-3'} transition-all`}>
        <div className="w-full gap-2 m-1 justify-around flex flex-wrap">
          <LabelButton
            onClick={() => handleDeleteExternalTicket()}
            className="border text-red-400 border-red-400"
            icon="close"
          >
            Remove Booking
          </LabelButton>
          {/* <LabelButton onClick={() => dispatch(openModal({ status: true, component: "DealVisualizer", data: { showID: show._key} }))} className="border text-green-400 border-green-400" icon="payments">Payouts</LabelButton> */}
          <LabelButton
            onClick={() => {
              if (props.viewType === ViewType.Artist) {
                navigate(`/edit-show/${props.viewType}/${user.displayUID}/${props.show.id}`);
              } else {
                navigate(`/edit-show/${props.viewType}/${props.showrunnerID}/${props.show.id}`);
              }
            }}
            className="border text-blue-400 border-blue-400"
            icon="construction"
          >
            Manage
          </LabelButton>
        </div>
      </div>
    </>
  );
}
