import { Box, Typography } from '@mui/material';
import React, { FC, useState, useCallback, ChangeEvent } from 'react';
import { colors } from '../../../ui/shared/Colors';
import { Checkbox, FormControlLabel } from '../../../ui/inputs/Checkbox';
import { AutocompleteElement, Select } from '../../../ui/inputs/TextField';
import {
  AutocompleteWithLabelContainer,
  FormSectionContainer,
  InputGroupRowHolder,
  MultipleAutocompleteWithLabelContainer,
} from '../Shared/Containers';
import { AutocompleteInputLabel, FormSectionTitle } from '../Shared/RegistrationTypographies';
import { DAYS_OF_WEEK, LIST_OF_POSSIBLE_HOURS } from './StaticVars';
import { Controller, useFormContext } from 'react-hook-form';

export const VenueHoursOfOperationForm: FC = () => {
  const venueForm = useFormContext();
  const [everyDayInPlace, setEverydayInPlace] = useState(false);
  const [everydayFrom, setEverydayFrom] = useState('10:00am');
  const [everydayTo, setEverydayTo] = useState('11:00pm');
  const BASE_TIME_INPUT = { from: '10:00am', to: '11:00pm' };
  const [singleDaysOpen, setSingleDaysOpen] = useState<string[]>([]);

  const handleToggleDay = useCallback(
    (evt: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      const key = evt.target.value;
      const newSingleDaysOpen = [...singleDaysOpen];
      if (checked) {
        newSingleDaysOpen.push(evt.target.value);
        setSingleDaysOpen(newSingleDaysOpen);
        if (!everyDayInPlace) {
          venueForm.setValue(`operatingHours.${evt.target.value}`, BASE_TIME_INPUT);
        }
      } else {
        newSingleDaysOpen.filter((singleDayOpen) => singleDayOpen !== evt.target.value);
        setSingleDaysOpen(newSingleDaysOpen);
        if (!everyDayInPlace) {
          venueForm.setValue(`operatingHours.${evt.target.value}`, { from: null, to: null });
        }
      }
    },
    [venueForm, singleDaysOpen, setSingleDaysOpen],
  );

  const checkIfIsDisabled = useCallback(
    (dayName: string) => {
      if (!singleDaysOpen.includes(dayName) || everyDayInPlace) {
        return true;
      } else {
        return false;
      }
    },
    [singleDaysOpen, everyDayInPlace],
  );

  const handleToggleAll = useCallback(
    (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      e.stopPropagation();
      if (checked) {
        setEverydayInPlace(true);
        DAYS_OF_WEEK.forEach((day) => {
          venueForm.setValue(`operatingHours.${day.toLowerCase()}`, { from: everydayFrom, to: everydayTo });
        });
      } else {
        DAYS_OF_WEEK.forEach((day) => {
          venueForm.setValue(`operatingHours.${day.toLowerCase()}`, { from: null, to: null });
        });
        setEverydayInPlace(false);
      }
    },
    [venueForm, everydayFrom, everydayTo, setEverydayInPlace],
  );

  const createHoursPickersForDays = useCallback(() => {
    return DAYS_OF_WEEK.map((singleDay) => {
      return (
        <InputGroupRowHolder key={singleDay}>
          <FormControlLabel
            control={
              <Checkbox
                value={singleDay.toLowerCase()}
                onChange={handleToggleDay}
                defaultChecked={false}
              />
            }
            label={singleDay}
          />
          <MultipleAutocompleteWithLabelContainer>
            <AutocompleteWithLabelContainer>
              <AutocompleteInputLabel>From: </AutocompleteInputLabel>
              <Controller
                name={`operatingHours.${singleDay.toLowerCase()}.from`}
                control={venueForm.control}
                render={(field) => (
                  <AutocompleteElement
                    disablePortal
                    options={LIST_OF_POSSIBLE_HOURS}
                    sx={{ width: '144px' }}
                    value={venueForm.getValues(`operatingHours.${singleDay.toLowerCase()}.from`)}
                    renderInput={(params) => (
                      <Select
                        {...field}
                        {...params}
                        disabled={checkIfIsDisabled(singleDay.toLowerCase())}
                      />
                    )}
                  />
                )}
              />
            </AutocompleteWithLabelContainer>
            <AutocompleteWithLabelContainer>
              <AutocompleteInputLabel>To: </AutocompleteInputLabel>
              <Controller
                name={`operatingHours.${singleDay.toLowerCase()}.to`}
                control={venueForm.control}
                render={(field) => (
                  <AutocompleteElement
                    disablePortal
                    options={LIST_OF_POSSIBLE_HOURS}
                    sx={{ width: '144px' }}
                    value={venueForm.getValues(`operatingHours.${singleDay.toLowerCase()}.to`)}
                    renderInput={(params) => (
                      <Select
                        {...field}
                        {...params}
                        disabled={checkIfIsDisabled(singleDay.toLowerCase())}
                      />
                    )}
                  />
                )}
              />
            </AutocompleteWithLabelContainer>
          </MultipleAutocompleteWithLabelContainer>
        </InputGroupRowHolder>
      );
    });
  }, [everyDayInPlace, venueForm]);

  return (
    <>
      <FormSectionContainer>
        <FormSectionTitle>Hours of operation</FormSectionTitle>
      </FormSectionContainer>
      <FormSectionContainer>
        <InputGroupRowHolder>
          <FormControlLabel
            control={
              <Checkbox
                value="everyday"
                onChange={(e, checked) => handleToggleAll(e, checked)}
                checked={everyDayInPlace}
              />
            }
            label={'Everyday'}
          />
          <MultipleAutocompleteWithLabelContainer>
            <AutocompleteWithLabelContainer>
              <AutocompleteInputLabel>From: </AutocompleteInputLabel>
              <AutocompleteElement
                disablePortal
                options={LIST_OF_POSSIBLE_HOURS}
                defaultValue={everydayFrom}
                sx={{ width: '144px' }}
                renderInput={(params) => (
                  <Select
                    name="everydayFrom"
                    {...params}
                    onChange={(e) => setEverydayFrom(e.target.value)}
                    disabled={false}
                  />
                )}
              />
            </AutocompleteWithLabelContainer>
            <AutocompleteWithLabelContainer>
              <AutocompleteInputLabel>To: </AutocompleteInputLabel>
              <AutocompleteElement
                disablePortal
                options={LIST_OF_POSSIBLE_HOURS}
                defaultValue={everydayTo}
                sx={{ width: '144px' }}
                renderInput={(params) => (
                  <Select
                    name="everydayTo"
                    {...params}
                    onChange={(e) => setEverydayTo(e.target.value)}
                    disabled={false}
                  />
                )}
              />
            </AutocompleteWithLabelContainer>
          </MultipleAutocompleteWithLabelContainer>
        </InputGroupRowHolder>
        <Box
          display="flex"
          gap="20px"
          alignItems="center"
        >
          <Box
            height="1px"
            width="100%"
            sx={{ backgroundColor: colors.SystemGray300 }}
          />
          <Typography>or</Typography>
          <Box
            height="1px"
            width="100%"
            sx={{ backgroundColor: colors.SystemGray300 }}
          />
        </Box>
        {createHoursPickersForDays()}
      </FormSectionContainer>
    </>
  );
};
