import React, { useRef, useState, useEffect } from 'react';
import { Box, Typography, styled } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { colors } from '../../ui/shared/Colors';
import { typography } from '../../ui/shared/TypographySharedElements';
import TopVenueCard from './TopVenueCard';
import { IconButton } from '../../ui/buttons/IconButton';
import allVenuesAvatar from '../../Images/Icons/other/starInCircle.svg';

export default function TopVenueSelector({ venues }: { venues: any }) {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);
  const [search, setSearch] = useSearchParams();

  //Set up event listeners for scrolling so we can enable/disable the arrow buttons
  useEffect(() => {
    const handleScroll = () => {
      if (scrollRef.current) {
        const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
        setCanScrollLeft(scrollLeft > 0);
        setCanScrollRight(scrollLeft < scrollWidth - clientWidth);
      }
    };

    const scrollElement = scrollRef.current;
    if (scrollElement) {
      scrollElement.addEventListener('scroll', handleScroll);
      handleScroll(); // Initial check
    }

    return () => {
      if (scrollElement) {
        scrollElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    <MainContent>
      <TopVenuesTitleRow>
        <VenueReviewsTitle>Venues</VenueReviewsTitle>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap="8px"
        >
          <IconButton
            isPrimary={true}
            onClick={() => (scrollRef.current.scrollLeft -= 400)}
            disabled={!canScrollLeft}
            size="small"
            iconName="chevron_left"
          />
          <IconButton
            isPrimary={true}
            onClick={() => (scrollRef.current.scrollLeft += 400)}
            disabled={!canScrollRight}
            size="small"
            iconName="chevron_right"
          />
        </Box>
      </TopVenuesTitleRow>
      <TopVenuesList ref={scrollRef}>
        <TopVenueCard
          key={0}
          venue={{ name: 'All Venues', avatar: allVenuesAvatar }}
          isSelected={!search.get('venueID')}
          onClick={() =>
            setSearch((params) => {
              params.delete('venueID');
              return params;
            })
          }
        />
        {venues &&
          venues.map((venue: any, index: number) => (
            <TopVenueCard
              key={index}
              venue={venue}
              isSelected={search.get('venueID') === venue.name}
              onClick={() =>
                setSearch((params) => {
                  params.set('venueID', venue.name);
                  return params;
                })
              }
            />
          ))}
      </TopVenuesList>
    </MainContent>
  );
}

const MainContent = styled(Box, {
  name: 'MainContent',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  [theme.breakpoints.down('lg')]: {
    gap: '16px',
  },
}));

const VenueReviewsTitle = styled(Typography, {
  name: 'VenueReviewsTitle',
})(({ theme }) => ({
  fontFamily: 'Satoshi-Variable',
  color: colors.SystemGray900,
  ...typography.headlineMedium,
  [theme.breakpoints.down('lg')]: {
    ...typography.headlineSmall,
  },
}));

const TopVenuesTitleRow = styled(Box, {
  name: 'TopVenuesTitleRow',
})(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '52px',
  [theme.breakpoints.down('lg')]: {
    height: '36px',
  },
}));

const TopVenuesList = styled(Box, {
  name: 'TopVenuesList',
})(({ theme }) => ({
  display: 'flex',
  gap: '16px',
  overflowX: 'hidden',
  [theme.breakpoints.down('lg')]: {
    overflowX: 'auto',
    gap: '12px',
  },
}));
