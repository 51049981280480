import { Box, styled, Typography } from '@mui/material';
import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { colors } from '../../../ui/shared/Colors';
import { typography } from '../../../ui/shared/TypographySharedElements';
import { ColorExtended, ColorPickers } from './ColorPickers';
import { Preview } from './Preview';
import { isEmpty } from 'lodash';
import AvatarImage from '../../../Images/Avatar.webp';
import { useGetColorsPaletteFromImage } from './useGetColorsPaletteFromImage';
import { ViewType } from '../../../Helpers/shared/Models/ViewType';
import { useSendColorData } from './useSendColorData';
import { useParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { HiddenField } from '../../../ui/inputs/HiddenField';

interface HatchLinksColorsProps {
  avatarUrl: string;
  profileName: string;
  viewType: ViewType;
  colorsFromDB?: {
    profileColor?: ColorExtended;
    colorsToPickFrom?: ColorExtended[];
    hlColor?: ColorExtended;
  };
  setForceDirty?: Dispatch<SetStateAction<boolean>>;
}

interface SendColorDataPayload {
  profileColor?: ColorExtended;
  colorsToPickFrom?: ColorExtended[];
  profileID?: string;
}

export const HatchLinksColors: FC<HatchLinksColorsProps> = ({
  avatarUrl,
  profileName,
  viewType,
  colorsFromDB,
  setForceDirty,
}) => {
  const { profileID } = useParams();
  const editProfileForm = useFormContext();
  const { getColorFromImage } = useGetColorsPaletteFromImage({
    avatarUrl: avatarUrl ? avatarUrl : AvatarImage,
    limit: 5,
  });
  const [selectedColor, setSelectedColor] = useState<ColorExtended>(colorsFromDB?.hlColor);
  const [colorsList, setColorsList] = useState<ColorExtended[]>(colorsFromDB?.colorsToPickFrom ?? []);

  const getColorsToSendData = useCallback(
    (colorsListArr: ColorExtended[]) => {
      const payload: SendColorDataPayload = !isEmpty(colorsFromDB?.profileColor)
        ? { colorsToPickFrom: colorsListArr }
        : { profileColor: colorsListArr[0], colorsToPickFrom: colorsListArr };
      if (viewType !== ViewType.Artist) {
        payload.profileID = profileID;
      }
      return payload;
    },
    [colorsList, profileID],
  );

  const sendColorData = useSendColorData({ viewType: viewType });

  const handleGetColorsFromImage = useCallback(async () => {
    if (isEmpty(colorsFromDB?.colorsToPickFrom)) {
      const colorsListArr = await getColorFromImage();
      const colorsToSendData = getColorsToSendData(colorsListArr);
      if (colorsToSendData) {
        setColorsList(colorsListArr);
        setSelectedColor(colorsListArr[0]);
        sendColorData(colorsToSendData);
      }
    }
  }, [getColorFromImage, getColorsToSendData]);

  useEffect(() => {
    if (isEmpty(colorsFromDB?.colorsToPickFrom) && isEmpty(colorsList)) {
      handleGetColorsFromImage();
    }
  }, [handleGetColorsFromImage, colorsFromDB, colorsList]);

  useEffect(() => {
    if (!isEmpty(colorsFromDB?.hlColor) && isEmpty(selectedColor)) {
      setSelectedColor(colorsFromDB?.profileColor);
    }
  }, [selectedColor]);

  useEffect(() => {
    if (!isEmpty(selectedColor)) {
      editProfileForm.setValue('customOptions.colors', { hlColor: selectedColor });
      setForceDirty?.(true);
    }
  }, [selectedColor]);

  return (
    <HatchLinksColorsMainCont>
      <HatchLinksColorsTitleCont>
        <HatchLinksColorsTitle>Choose your aesthetic</HatchLinksColorsTitle>
        <HatchLinksColorsSubtitle>
          Select background color for your HatchLink based on your avatar colors or a custom one.
        </HatchLinksColorsSubtitle>
      </HatchLinksColorsTitleCont>
      <ColorPickers
        selectedColor={selectedColor}
        setSelectedColor={setSelectedColor}
        colorsToPickFrom={colorsList}
        avatarUrl={avatarUrl}
      />
      {!isEmpty(selectedColor) && (
        <Preview
          avatarUrl={avatarUrl}
          profileName={profileName}
          selectedColor={selectedColor}
        />
      )}
    </HatchLinksColorsMainCont>
  );
};

const HatchLinksColorsMainCont = styled(Box, {
  name: 'HatchLinksColorsMainCont',
})({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
});

const HatchLinksColorsTitleCont = styled(Box, {
  name: 'HatchLinksColorsTitleCont',
})({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
});

const HatchLinksColorsTitle = styled(Typography, {
  name: 'HatchLinksColorsTitle',
})({
  color: colors.SystemGray900,
  ...typography.bodyLargeBold,
});

const HatchLinksColorsSubtitle = styled(Typography, {
  name: 'HatchLinksColorsSubtitle',
})({
  color: colors.SystemGray600,
  ...typography.bodySmallRegular,
});
