import { compact, isEmpty } from 'lodash';
import React, { useState, useCallback, useEffect } from 'react';
import { VenueGeneralInfoForm } from '../../Forms/AccountInfoForms/Venue/VenueGeneralInfoForm';
import { VenueHoursOfOperationForm } from '../../Forms/AccountInfoForms/Venue/VenueHoursOfOperationForm';
import { VenuePublicProfileForm } from '../../Forms/AccountInfoForms/Venue/VenuePublicProfileForm';
import { FieldValues, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import axios, { AxiosError } from 'axios';
import { VenueFormData } from '../../Forms/AccountInfoForms/Venue/VenueFormData';
import {
  EditProfileHeadlineContainer,
  EditProfileFormContainer,
  EditProfileInnerContainer,
  StyledTabs,
  StyledTab,
  TabLabel,
  EditProfileTitle,
  StyledButton,
  CancelButton,
  TitleAndButtonsContainer,
} from './Shared/Styles';
import { addStatusMessage } from '../../Redux/UI/UISlice';
import { TabPanel } from './Shared/Other';
import { useAppSelector, useAppDispatch } from '../../hooks';
import { useGetAllVenuesQuery } from '../../Redux/API/PublicAPI';
import { HatchlinksForm } from '../../Forms/AccountInfoForms/HatchLinks/HatchlinksForm';
import { useEditVenueProfileMutation } from '../../Redux/API/UserAPI';
import { CancelModal } from './CancelModal/CancelModal';
import { ImageUploader } from '../../ui/shared/ImageUploader';
import { HatchlinksFormData } from '../../Forms/AccountInfoForms/HatchLinks/HatchlinksFormData';
import { ShareHatchLinks } from '../ShareHatchLinks/ShareHatchLinks';
import { ViewType } from '../../Helpers/shared/Models/ViewType';

export const VenueForm = (props: { id: string; startingTab: string }) => {
  const user = useAppSelector((select) => select.user.data);
  const venues = useGetAllVenuesQuery();
  const venue = props.id ? venues.data?.[props.id] : null;
  const [avatar, setAvatar] = useState<File | null>(null);
  const [venueImages, setVenueImages] = useState<File[]>([]);
  const [loading, setLoading] = useState(false);
  const [currentTab, setCurrentTab] = React.useState(props.startingTab || 'generalInfo');
  const navigate = useNavigate();
  const dipatch = useAppDispatch();
  const [editVenueProfile, editVenueProfileStatus] = useEditVenueProfileMutation();
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [forceDirty, setForceDirty] = useState(false);
  const [shareHatchLinkModalOpen, setShareHatchLinkModalOpen] = useState(false);

  const isDirty = () => {
    return venueForm.formState.isDirty || forceDirty;
  };

  const initialVenueFormValues: VenueFormData.ValuesFull = {
    venueName: venue?.name ?? '',
    ageRestriction: venue?.min_age?.toString() ?? '',
    capacity: venue?.capacity?.toString() ?? '',
    owner: {
      firstName: user?.firstname ?? '',
      lastName: user?.lastname ?? '',
    },
    contact: {
      phone: venue?.contact?.phone ?? '',
      email: venue?.contact?.email ?? '',
    },
    address: {
      displayAddress: compact([venue?.location?.address, venue?.location?.city]).join(', '),
      address: venue?.location?.address ?? '',
      city: venue?.location?.city ?? '',
      state: venue?.location?.state ?? '',
      zip: venue?.location?.zip ?? '',
      latitude: venue?.location?.latitude ?? null,
      longitude: venue?.location?.longitude ?? null,
    },
    operatingHours: null,
    socials: {
      instagram: venue?.socials?.instagram ?? null,
      tiktok: venue?.socials?.tiktok ?? null,
      spotify: venue?.socials?.spotify ?? null,
      youtube: venue?.socials?.youtubeLink ?? null,
    },
    about: venue?.description ?? '',
    username: venue?.username ?? '',
    externalLinks: venue?.externalLinks ?? [],
    customOptions: {
      alternativeHatchLinkLabel: venue?.customOptions?.alternativeHatchLinkLabel ?? false,
      colors: {},
    },
  };

  const venueForm = useForm({
    resolver: zodResolver(VenueFormData.formSchemaFull),
    defaultValues: initialVenueFormValues,
    values: initialVenueFormValues,
    mode: 'all',
  });

  const submitVenue: SubmitHandler<FieldValues> = async (data: VenueFormData.ValuesFull) => {
    try {
      const formData = new FormData();
      formData.append(
        'body',
        JSON.stringify({
          id: props.id,
          name: data.venueName,
          about: data.about,
          capacity: parseFloat(data.capacity),
          minAge: parseFloat(data.ageRestriction),
          owner: {
            firstName: data.owner.firstName,
            lastName: data.owner.lastName,
          },
          contact: {
            phone: data.contact.phone,
            email: data.contact.email,
          },
          socials: {
            instagramLink: data.socials.instagram ?? null,
            youtubeLink: data.socials.youtube ?? null,
            spotifyLink: data.socials.spotify ?? null,
            tikTokLink: data.socials.tiktok ?? null,
          },
          operatingHours: data.operatingHours,
          location: {
            address: isEmpty(data.address.address) ? data.address.displayAddress : data.address.address,
            city: data.address.city,
            state: data.address.state,
            zip: data.address.zip,
            latitude: data.address.latitude,
            longitude: data.address.longitude,
          },
          username: data.username,
          hatchLinks: data.externalLinks,
          customOptions: {
            alternativeHatchLinkLabel: data.customOptions.alternativeHatchLinkLabel,
            colors: data.customOptions.colors,
          },
        }),
      );
      formData.append('logo', avatar);
      venueImages.forEach((venueImage) => {
        return formData.append('images', venueImage);
      });

      const response = await editVenueProfile(formData);

      if ('error' in response) {
        window.alert('Please fix any errors before saving.');
        setCurrentTab('generalInfo');
      } else {
        venueForm.reset(venueForm.getValues());
        setForceDirty(false);
        dipatch(addStatusMessage({ type: 'success', message: 'Profile successfully updated' }));
      }
    } catch (error) {
      if (error instanceof AxiosError && error.response.status === 403) {
        window.alert('');
        setCurrentTab('generalInfo');
      } else if (error instanceof AxiosError && error.response.status === 413) {
        window.alert('Uploaded images are too large');
        setCurrentTab('venuePhotos');
      } else {
        setCurrentTab('generalInfo');
        window.alert('Unknown server error');
        console.log(error);
      }
    }
  };

  const handleSaveChanges = () => {
    venueForm.trigger();
    const data = venueForm.getValues();
    // Validate each tab manually so we can set the correct tab if there's an error
    if (!VenueFormData.step1Schema.safeParse(data).success) return setCurrentTab('generalInfo');
    if (!VenueFormData.step2Schema.safeParse(data).success) return setCurrentTab('operatingHours');
    if (!VenueFormData.step3Schema.safeParse(data).success) return setCurrentTab('publicProfile');
    if (!VenueFormData.step4Schema.safeParse(data).success) return setCurrentTab('venuePhotos');
    if (!HatchlinksFormData.formSchema.safeParse(data).success) return setCurrentTab('hatchlinks');
    if (currentTab === 'hatchlinks') {
      setShareHatchLinkModalOpen(true);
    }
    setLoading(true);
    venueForm.handleSubmit(submitVenue)();
    setLoading(false);
  };

  const handleChangeTab = useCallback((e: React.SyntheticEvent, newTab: string) => {
    e.preventDefault();
    setCurrentTab(newTab);
  }, []);

  return (
    <FormProvider {...venueForm}>
      <EditProfileHeadlineContainer>
        <EditProfileInnerContainer>
          <TitleAndButtonsContainer>
            <EditProfileTitle>Edit your profile</EditProfileTitle>
            <CancelButton
              size="large"
              onClick={() => (isDirty() ? setCancelModalOpen(true) : navigate(-1))} //navigate(-1) is the same as window.history.back() but rerenders the route instead of reloading the page
            >
              Back
            </CancelButton>
            <StyledButton
              isPrimary
              size="large"
              onClick={handleSaveChanges}
              isLoading={loading}
              disabled={!isDirty() || loading}
            >
              Save changes
            </StyledButton>
          </TitleAndButtonsContainer>
        </EditProfileInnerContainer>
        <EditProfileInnerContainer>
          <StyledTabs
            variant="scrollable" //support for mobile
            value={currentTab}
            onChange={handleChangeTab}
          >
            <StyledTab
              value="generalInfo"
              label={<TabLabel>General Info</TabLabel>}
            />
            {/* <StyledTab
              value="hoursOfOperation"
              label={<TabLabel>Hours of Operation</TabLabel>}
            /> */}
            <StyledTab
              value="publicProfile"
              label={<TabLabel>Public Profile</TabLabel>}
            />
            <StyledTab
              value="hatchlinks"
              label={<TabLabel>Hatchlink</TabLabel>}
            />
            <StyledTab
              value="venuePhotos"
              label={<TabLabel>Venue's Photos</TabLabel>}
            />
          </StyledTabs>
        </EditProfileInnerContainer>
      </EditProfileHeadlineContainer>
      <form onSubmit={venueForm.handleSubmit(submitVenue)}>
        <TabPanel
          index="generalInfo"
          value={currentTab}
        >
          <EditProfileFormContainer>
            <EditProfileInnerContainer>
              <VenueGeneralInfoForm />
            </EditProfileInnerContainer>
          </EditProfileFormContainer>
        </TabPanel>
        <TabPanel
          index="hoursOfOperation"
          value={currentTab}
        >
          <EditProfileFormContainer>
            <EditProfileInnerContainer>
              <VenueHoursOfOperationForm />
            </EditProfileInnerContainer>
          </EditProfileFormContainer>
        </TabPanel>
        <TabPanel
          index="publicProfile"
          value={currentTab}
        >
          <EditProfileFormContainer>
            <EditProfileInnerContainer>
              <VenuePublicProfileForm
                setAvatar={setAvatar}
                selectedAvatar={avatar}
                defaultAvatar={venue?.avatarWebP}
                setForceDirty={setForceDirty}
              />
            </EditProfileInnerContainer>
          </EditProfileFormContainer>
        </TabPanel>
        <TabPanel
          index="venuePhotos"
          value={currentTab}
        >
          <EditProfileFormContainer>
            <EditProfileInnerContainer>
              {venue && (
                <ImageUploader
                  maxNumber={5}
                  entityType="venue"
                  resourceId={venue._key}
                  field="images"
                  forceDirty={setForceDirty}
                />
              )}
            </EditProfileInnerContainer>
          </EditProfileFormContainer>
        </TabPanel>
        <TabPanel
          index="hatchlinks"
          value={currentTab}
        >
          <EditProfileFormContainer>
            <EditProfileInnerContainer>
              <HatchlinksForm
                setForceDirty={setForceDirty}
                profileName={venue?.name}
                avatarUrl={venue?.avatarWebP}
                colors={venue?.customOptions?.colors}
                viewType={ViewType.Venue}
              />
            </EditProfileInnerContainer>
          </EditProfileFormContainer>
        </TabPanel>
      </form>
      <CancelModal
        open={cancelModalOpen}
        setOpen={setCancelModalOpen}
      />
      <ShareHatchLinks
        open={shareHatchLinkModalOpen}
        setOpen={setShareHatchLinkModalOpen}
        link={
          venue?.username ? `https://tunehatch.link/${venue.username}` : `https://tunehatch.com/hl/venue/${venue?._key}`
        }
      />
    </FormProvider>
  );
};
