import React, { FC, useCallback, useMemo, useState } from 'react';
import { Button } from '../../../ui/buttons/Button';
import { Box, styled } from '@mui/material';
import { typography } from '../../../ui/shared/TypographySharedElements';
import { colors } from '../../../ui/shared/Colors';
import { HeadlineItem } from '../Shared/HeadlineItem';
import { LeftHandSideComponentTitle } from '../Shared/SharedElements';
import { LeftSideComponentContainer } from '../Shared/SharedContainers';
import { ViewType } from '../../../Helpers/shared/Models/ViewType';
import { useNavigate } from 'react-router-dom';
import GoogleMapsEmbed from '../../../Components/Embeds/GoogleMapsEmbed';

interface AboutProps {
  about: string;
  location?: string | null;
  secondaryLocation?: string | null;
  recordLabel?: string | null;
  genre?: string | null;
  isOwnProfile: boolean;
  profileID: string;
  profileType: ViewType;
}

export const About: FC<AboutProps> = ({
  about,
  location,
  secondaryLocation,
  recordLabel,
  genre,
  isOwnProfile,
  profileID,
  profileType,
}) => {
  const [isMoreVisible, setIsMoreVisible] = useState(false);
  const MAX_VISIBLE_CHARS = 250;
  const aboutLongerThanMaxVisibleChars = about?.length > MAX_VISIBLE_CHARS;
  const navigate = useNavigate();

  const aboutFirstPart = useMemo(() => {
    if (aboutLongerThanMaxVisibleChars) return about.slice(0, 250);
    return about;
  }, [about, aboutLongerThanMaxVisibleChars]);

  const aboutRest = useMemo(() => {
    if (aboutLongerThanMaxVisibleChars) return about.slice(250, about.length);
  }, [about, aboutLongerThanMaxVisibleChars]);

  const toggleExpanded = useCallback(() => {
    setIsMoreVisible((isMoreVisible) => !isMoreVisible);
  }, [setIsMoreVisible]);

  //TODO check why location can be a string "null" - after verification remove error and clean the if below

  if (!about && (!location || location === 'null') && !genre && !isOwnProfile) {
    return null;
  }

  return (
    <LeftSideComponentContainer>
      <LeftHandSideComponentTitle>About</LeftHandSideComponentTitle>
      <AboutContainer>
        {isOwnProfile && !about && (!location || location === 'null') && !genre ? (
          <Button
            isPrimary
            size="large"
            disabled={false}
            onClick={() =>
              navigate(`/profile/edit/${profileType === ViewType.User ? 'artist' : profileType}/${profileID}`)
            }
          >
            Complete your profile
          </Button>
        ) : (
          <>
            {location && profileType === ViewType.Venue && <StyledGoogleMaps address={location} />}
            {location && (
              <HeadlineItem
                text={location}
                icon="distance"
              />
            )}
            {secondaryLocation && (
              <HeadlineItem
                text={secondaryLocation}
                icon="add_location_alt"
              />
            )}
            {genre && (
              <HeadlineItem
                text={genre}
                icon="music_note"
              />
            )}
            {recordLabel && (
              <HeadlineItem
                text={recordLabel}
                icon="album"
              />
            )}
            {about && (
              <>
                <Box>
                  <AboutContent>
                    {aboutFirstPart}{' '}
                    <Box
                      component="span"
                      sx={{ display: aboutLongerThanMaxVisibleChars && isMoreVisible ? 'inline' : 'none' }}
                    >
                      {aboutRest}
                    </Box>
                  </AboutContent>
                </Box>
                {aboutLongerThanMaxVisibleChars && (
                  <Box>
                    <Button
                      isPrimary
                      disabled={false}
                      size="small"
                      onClick={toggleExpanded}
                    >
                      {isMoreVisible ? 'Show less' : 'Show more'}
                    </Button>
                  </Box>
                )}
              </>
            )}
          </>
        )}
      </AboutContainer>
    </LeftSideComponentContainer>
  );
};

const StyledGoogleMaps = styled(GoogleMapsEmbed, {
  name: 'StyledGoogleMaps',
})({
  borderRadius: '4px',
  border: `1px solid ${colors.SystemGray200}`,
});

const AboutContainer = styled(Box, {
  name: 'AboutContainer',
})({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
});

const AboutContent = styled(Box, {
  name: 'AboutContent',
})(({ theme }) => ({
  color: colors.SystemGray900,
  ...typography.bodyMediumRegular,
  [theme.breakpoints.down('lg')]: {
    ...typography.bodySmallRegular,
  },
}));
