import { Box, Typography, styled } from '@mui/material';
import React, { FC } from 'react';
import { ButtonIcon } from '../../../ui/shared/ButtonIcon';
import { typography } from '../../../ui/shared/TypographySharedElements';
import { colors } from '../../../ui/shared/Colors';

interface HeadlineItemProps {
  text: React.ReactNode;
  icon: string;
  iconClassName?: string;
}

export const HeadlineItem: FC<HeadlineItemProps> = ({ text, icon, iconClassName = 'material-symbols-outlined' }) => {
  return (
    <HeadlineContainer>
      <ButtonIcon
        disabled={true}
        size="large"
        iconName={icon}
        iconClassName={iconClassName}
      />
      <HeadlineText>{text}</HeadlineText>
    </HeadlineContainer>
  );
};

const HeadlineContainer = styled(Box, {
  name: 'HeadlineContainer',
})({
  display: 'flex',
  alignItems: 'center',
  gap: '2px',
});

const HeadlineText = styled(Typography, {
  name: 'HeadlineText',
})(({ theme }) => ({
  color: colors.SystemGray900,
  ...typography.bodyLargeRegular,
  [theme.breakpoints.down('lg')]: {
    ...typography.bodyMediumRegular,
  },
}));
