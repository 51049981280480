import { Autocomplete, TextField as MuiTextField, TextFieldProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DataCy } from '../../Helpers/DataCy';
import { typography } from '../shared/TypographySharedElements';
import React, { FC, forwardRef } from 'react';
import { colors } from '../shared/Colors';

export const HiddenField = forwardRef<unknown, TextFieldProps>(({ InputProps, ...props }, ref) => {
  return (
    <StyledTextField
      InputProps={{
        ...InputProps,
        // @ts-ignore
        'data-cy': DataCy.input(props.name),
      }}
      inputRef={ref}
    />
  );
});

const StyledTextField = styled(MuiTextField, {
  name: 'TextField',
})<TextFieldProps>(() => ({
  display: 'none',
}));
