import React, { FC, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { DataCy } from '../../../Helpers/DataCy';
import { Button } from '../../../ui/buttons/Button';
import {
  FormMainContainerColumn,
  FormStepFormContainerOuter,
  FormStepHeadlineContainer,
  FormStepInnerContainer,
  FormTitleWithSubtitleContainer,
} from '../Shared/Containers';
import { FormStepHeadlineStepCounter, FormStepHeadlineTitle } from '../Shared/RegistrationTypographies';
import { FormStepProps } from '../Shared/Types';
import { VenueFormData } from '../../../Forms/AccountInfoForms/Venue/VenueFormData';
import { VenueGeneralInfoForm } from '../../../Forms/AccountInfoForms/Venue/VenueGeneralInfoForm';

export const VenueFormStep1: FC<FormStepProps> = ({ step, setStep }) => {
  const venueForm = useFormContext<VenueFormData.Values>();

  const handleNextStep = useCallback(async () => {
    const isValid = await venueForm.trigger(VenueFormData.step1Fields);
    if (isValid === true) {
      setStep((step) => step + 2);
    }
  }, [venueForm, setStep]);

  return (
    <FormMainContainerColumn>
      <FormStepHeadlineContainer>
        <FormStepInnerContainer>
          <FormTitleWithSubtitleContainer>
            <FormStepHeadlineStepCounter>Step 1 of 4</FormStepHeadlineStepCounter>
            <FormStepHeadlineTitle>Add information about your venue</FormStepHeadlineTitle>
          </FormTitleWithSubtitleContainer>
        </FormStepInnerContainer>
      </FormStepHeadlineContainer>
      <FormStepFormContainerOuter>
        <FormStepInnerContainer>
          <VenueGeneralInfoForm />
          <Button
            isPrimary
            onClick={handleNextStep}
            size="large"
            data-cy={DataCy.button('nextStep')}
          >
            Next
          </Button>
        </FormStepInnerContainer>
      </FormStepFormContainerOuter>
    </FormMainContainerColumn>
  );
};
