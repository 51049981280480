import Lo from 'lodash';
import { z } from 'zod';

const internalAuthStateSchema = z.object({
  stateToken: z.string(),
  internalRedirectUrl: z.string(),
});

type InternalAuthState = z.TypeOf<typeof internalAuthStateSchema>;

type Provider = 'spotify' | 'tiktok';

export class AuthState {
  private readonly state: InternalAuthState;

  private constructor(state: InternalAuthState) {
    this.state = state;
  }

  public static build(params: { internalRedirectUrl: string; provider: Provider }) {
    const stateToken = Math.random().toString(36).substring(2);
    const instance = new AuthState({
      internalRedirectUrl: params.internalRedirectUrl,
      stateToken,
    });

    AuthState.buildStorage(params.provider).save(instance.toString());

    return instance;
  }

  public static reconstruct(provider: Provider) {
    const storedValue = JSON.parse(AuthState.buildStorage(provider).get());

    const parsed = internalAuthStateSchema.safeParse(storedValue);
    if (parsed.success === false) {
      return new AuthState({ stateToken: '', internalRedirectUrl: '' });
    }
    return new AuthState({
      stateToken: parsed.data.stateToken,
      internalRedirectUrl: parsed.data.internalRedirectUrl,
    });
  }

  public isEqual(stateFromProvider: string | null) {
    try {
      const state = JSON.parse(stateFromProvider);
      return Lo.isEqual(state, this.state);
    } catch (error: unknown) {
      return false;
    }
  }

  public toString() {
    return JSON.stringify(this.state);
  }

  private static buildStorage(provider: Provider) {
    const key = `${provider}_auth_state`;
    return {
      get: () => localStorage.getItem(key),
      save: (value: string) => localStorage.setItem(key, value),
    };
  }
}
