import { Box, Dialog, styled, Typography } from '@mui/material';
import React, { Dispatch, FC, SetStateAction, useCallback } from 'react';
import { Button } from '../../ui/buttons/Button';
import { ViewType } from '../../Helpers/shared/Models/ViewType';
import { IconButton } from '../../ui/buttons/IconButton';
import {
  CancelModalDialogContainer,
  CancelModalDialogBottomContainer,
  CancelModalDialogHeading,
  CancelModalDialogInnerContainer,
  CancelModalDialogTitle,
  CancelModalDialogText,
} from './CancelModalDialog';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import ShareFlyer from '../../Tools/Venue/ShareFlyer/ShareFlyer';
import FlyerSharingStatus from '../../Components/FlyerSharingStatus';
import { colors } from '../../ui/shared/Colors';
import { typography } from '../../ui/shared/TypographySharedElements';

interface ShowFormSuccessModalProps {
  isEdit: boolean;
  showID?: string;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  viewType: ViewType;
  viewID: string;
}

export const ShowFormSuccessModal: FC<ShowFormSuccessModalProps> = ({
  isEdit,
  showID,
  isOpen,
  setIsOpen,
  viewType,
  viewID,
}) => {
  const [search, setSearch] = useSearchParams();
  const navigate = useNavigate();

  const goToCalendar = useCallback(() => {
    if (viewType === ViewType.Venue) {
      navigate(`/venues/manage/${viewID}`);
    }
    if (viewType === ViewType.Artist) {
      navigate(`/profile/artist/${viewID}`);
    }
    if (viewType === ViewType.SRGroup) {
      navigate(`/showrunner-tools/${viewID}`);
    }
  }, [navigate, viewType]);

  const createNewShow = useCallback(() => {
    window.location.reload();
    if (!isEmpty(search)) {
      setSearch(null);
    }
  }, [search]);

  return (
    <SuccessDialog open={isOpen}>
      <CancelModalDialogContainer>
        <CancelModalDialogInnerContainer>
          <CancelModalDialogHeading>
            <CancelModalDialogTitle>{`Show succesfully ${isEdit ? 'created' : 'edited'}.`}</CancelModalDialogTitle>
            <IconButton
              size="small"
              isPrimary={false}
              onClick={() => {
                setIsOpen(false);
                goToCalendar();
              }}
              iconName="close"
            />
          </CancelModalDialogHeading>

          {!isEdit && showID && (
            <>
              <SuccessDialogSubtitle>Share your show link:</SuccessDialogSubtitle>
              <CancelModalDialogText>{`https://tunehatch.com/shows/${showID}`}</CancelModalDialogText>
            </>
          )}
          {!isEdit && viewType === 'venue' && (
            <>
              <SuccessDialogSubtitle>Share to social media</SuccessDialogSubtitle>
              <CancelModalDialogText>
                TuneHatch allows you to share to your connected business Instagram and Facebook pages in a single click.
                You can share the flyer right now, or schedule it for any time in the future.
              </CancelModalDialogText>
              <StyledShareFlyer
                showID={showID}
                venueId={viewID}
              />
              <FlyerSharingStatus showID={showID} />
            </>
          )}
          <CancelModalDialogBottomContainer>
            <Button
              isPrimary
              size="large"
              disabled={false}
              onClick={goToCalendar}
            >
              Back to {viewType === 'artist' ? 'profile' : 'calendar'}
            </Button>
            {!isEdit && (
              <Button
                isPrimary
                size="large"
                disabled={false}
                onClick={createNewShow}
              >
                Create another show
              </Button>
            )}
          </CancelModalDialogBottomContainer>
        </CancelModalDialogInnerContainer>
      </CancelModalDialogContainer>
    </SuccessDialog>
  );
};

const SuccessDialog = styled(Dialog, {
  name: 'SuccessDialog',
})(({ theme }) => ({
  '.MuiBackdrop-root': {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    [theme.breakpoints.down('md')]: {
      display: 'block',
      backgroundColor: 'unset',
    },
  },
}));

const SuccessDialogSubtitle = styled(Typography, {
  name: 'SuccessDialogSubtitle',
})({
  color: colors.SystemBlack,
  ...typography.headlineSmall,
  padding: '0 20px',
});

const StyledShareFlyer = styled(ShareFlyer)`
  padding-top: 10px;
  gap: 5px;
`;
