import Lo from 'lodash';
import z from 'zod';
import { locationValueSchema } from '../../../ui/inputs/LocationField';
import { HatchlinksFormData } from '../HatchLinks/HatchlinksFormData';
import { RegularExp } from '../../../Helpers/RegularExp';

export namespace PromoterFormData {
  export const step1Schema = z.object({
    groupName: z.string().min(1, 'You must enter your group name'),
    genre: z.string().refine((value) => Lo.isEmpty(value) == false && value !== 'none', 'You must choose a genre'),
    subGenre: z.string().nullable(),
    firstName: z.string().min(1, 'You must enter your first name'),
    lastName: z.string().min(1, 'You must enter your last name'),
    contact: z.object({
      phone: z.string().min(1, 'You must enter your phone number'),
      email: z.string().min(1, 'You must enter your email').email('The provided value must be an email'),
    }),
    primaryCity: z.object({
      ...locationValueSchema.shape,
      displayAddress: z.string().min(1, 'Primary city is required'),
    }),
    secondaryCity: z
      .object({
        ...locationValueSchema.shape,
        displayAddress: z.string().nullish(),
      })
      .nullish(),
  });
  export type Step1Values = z.output<typeof step1Schema>;
  export const step1Fields = Object.keys(step1Schema.shape) as (keyof Step1Values)[];

  export const step2Schema = z.object({
    socials: z.object({
      instagram: z
        .string()
        .regex(RegularExp.InstagramUsername, 'Must be a valid Instagram username')
        .or(z.literal(''))
        .nullable(),
      tiktok: z.string().nullable(),
      spotify: z.string().nullable(),
      youtube: z
        .string()
        .regex(RegularExp.YoutubeVideo, 'The link must be a YouTube video URL, channels are not allowed')
        .or(z.literal(''))
        .nullable(),
    }),
    about: z.string().nullish(),
  });
  export type Step2Values = z.output<typeof step2Schema>;
  export const step2Fields = Object.keys(step2Schema.shape) as (keyof Step2Values)[];

  export const step3Schema = z.object({
    roster: z.string().array().nullable(),
  });

  export const formSchema = step1Schema.merge(step2Schema).merge(step3Schema);
  export const formSchemaFull = step1Schema.merge(step2Schema).merge(step3Schema).merge(HatchlinksFormData.formSchema);
  export type Values = z.TypeOf<typeof formSchema>;
  export type ValuesFull = z.TypeOf<typeof formSchemaFull>;
}
