import { ThunkDispatch } from '@reduxjs/toolkit';
import Lo from 'lodash';
import { z } from 'zod';
import { updateView } from '../UI/UISlice';
import { baseAPI, RTKTagTypes } from './BaseAPI';
import { postRequest, patchRequest, deleteRequest, getRequest } from './Request/ApiRequest';
import { handleApiErrors } from './Request/handleErrors';

export namespace ShowApi {
  const flatTicketTypeSchema = z.object({
    type: z.literal('flat'),
    cost: z.number().min(0),
    doorPrice: z.number().min(0).nullable(),
    quantity: z.number().min(0),
  });
  export type FlatTicketType = z.TypeOf<typeof flatTicketTypeSchema>;

  const tiersTicketTypeSchema = z.object({
    type: z.literal('tiers'),
    tiers: z.record(
      z.object({
        name: z.string(),
        description: z.string().nullable(),
        price: z.number().min(0),
        quantity: z.number().min(0),
      }),
    ),
  });
  export type TiersTicketType = z.TypeOf<typeof tiersTicketTypeSchema>;

  const showrunnerGroupsSchema = z.array(
    z.discriminatedUnion('isNew', [
      z.object({
        isNew: z.literal(false),
        id: z.string(),
      }),
      z.object({
        isNew: z.literal(true),
        email: z.string(),
        name: z.string(),
      }),
    ]),
  );
  export const createShowBaseSchema = z.object({
    name: z.string(),
    description: z.string(),
    startTime: z.string(),
    endTime: z.string(),
    tickets: z.discriminatedUnion('type', [flatTicketTypeSchema, tiersTicketTypeSchema]),
    minAge: z.number().min(0).nullable(),
    isPrivate: z.boolean(),
    isManageableByPerformers: z.boolean(),
    base64Flyer: z.string().nullable(),
    calendarColor: z.string(),
    repeatSchema: z
      .object({
        type: z.union([z.literal('monthly'), z.literal('weekly')]),
        times: z.number().min(1),
      })
      .nullable(),
    performers: z.array(
      z.discriminatedUnion('isNew', [
        z.object({
          isNew: z.literal(false),
          id: z.string(),
        }),
        z.object({
          isNew: z.literal(true),
          email: z.string(),
          stageName: z.string(),
        }),
      ]),
    ),
    showrunnerGroups: showrunnerGroupsSchema,
    deal: z.discriminatedUnion('type', [
      z.object({
        type: z.literal('ticket_split'),
        defaults: z.object({
          percentage: z.number().optional().nullable(),
          guarantee: z.number().optional().nullable(),
          production_fee: z.number().optional().nullable(),
          even_split: z.boolean().optional().nullable(),
        }),
      }),
      z.object({
        type: z.literal('guarantee'),
        defaults: z.object({
          guarantee: z.number().optional().nullable(),
          production_fee: z.number().optional().nullable(),
        }),
      }),
      z.object({
        type: z.literal('default'),
      }),
    ]),
  });
  export type CreateShowBasePayload = z.TypeOf<typeof createShowBaseSchema>;

  const createShowByManagerSchema = createShowBaseSchema.extend({
    venue: z.discriminatedUnion('isNew', [
      z.object({
        isNew: z.literal(false),
        id: z.string(),
        isEdited: z.boolean().optional(),
        email: z.string().optional(),
      }),
      z.object({
        isNew: z.literal(true),
        email: z.string(),
        name: z.string(),
        bookingContactName: z.string(),
        location: z.object({
          address: z.string(),
          city: z.string(),
          state: z.string(),
          zip: z.string(),
        }),
      }),
    ]),
  });

  const createdShowsSchema = z.object({
    createdShowIds: z.array(z.string()),
  });

  export namespace GetShowsList {
    const paramsSchema = z.object({
      page: z.string().nullish(),
      perPage: z.string().nullish(),
      name: z.string().optional(),
      venueId: z.string().min(1).optional(),
      showrunnerId: z.string().min(1).optional(),
      performerId: z.string().min(1).optional(),
      onlyUpcoming: z.enum(['true', 'false']).optional(),
      isPublished: z.enum(['true', 'false']).optional(),
      // sortBy: z.record(z.string(), z.enum(['asc', 'desc'])).optional(),
      // resultOptions: z
      //   .object({
      //     withPerformers: z.enum(['true', 'false']).optional(),
      //     withShowrunnerGroups: z.enum(['true', 'false']).optional(),
      //     withVenue: z.enum(['true', 'false']).optional(),
      //     withArtists: z.enum(['true', 'false']).optional(),
      //   })
      //   .optional(),
    });
    export type Params = z.TypeOf<typeof paramsSchema>;
    export const Request = async (params: Params) => {
      const filteredParams = Object.fromEntries(Object.entries(params).filter(([_, value]) => value));
      const parsed = paramsSchema.parse(filteredParams);

      let response;
      if (Object.values(parsed).some((value) => value)) {
        response = await getRequest({ endpoint: `/v2/list-shows?${new URLSearchParams(parsed).toString()}` });
      } else {
        response = await getRequest({ endpoint: `/v2/list-shows` });
      }
      return response;
    };
  }

  export namespace GetExternalShowsByArtist {
    const paramsSchema = z.object({
      artistID: z.string(),
    });
    export type Params = z.TypeOf<typeof paramsSchema>;

    const responseSchema = z.object({
      data: z
        .object({
          id: z.string(),
          title: z.string(),
          startTime: z.string(),
          endTime: z.string(),
          link: z.string(),
        })
        .array(),
      page: z.number(),
      perPage: z.number(),
      total: z.number(),
    });
    export type Response = z.TypeOf<typeof responseSchema>;

    export const Request = async (params: Params) => {
      const parsed = paramsSchema.parse(params);
      if (parsed.artistID) {
        const response = await getRequest({
          endpoint: `/v2/artists/${parsed.artistID}/external-shows?page=1&perPage=30&onlyUpcoming=false&sortBy[startTime]=asc`,
        });
        return responseSchema.parse(response);
      }
    };
  }

  export namespace GetExternalUpcomingShowsByArtist {
    const paramsSchema = z.object({
      artistID: z.string(),
    });
    export type Params = z.TypeOf<typeof paramsSchema>;

    const responseSchema = z.object({
      data: z
        .object({
          id: z.string(),
          title: z.string(),
          startTime: z.string(),
          endTime: z.string(),
          link: z.string(),
        })
        .array(),
      page: z.number(),
      perPage: z.number(),
      total: z.number(),
    });
    export type Response = z.TypeOf<typeof responseSchema>;

    export const Request = async (params: Params) => {
      const parsed = paramsSchema.parse(params);
      if (parsed.artistID) {
        const response = await getRequest({
          endpoint: `/v2/artists/${parsed.artistID}/external-shows?page=1&perPage=30&onlyUpcoming=true&sortBy[startTime]=asc`,
        });
        return responseSchema.parse(response);
      }
    };
  }

  export namespace GetExternalShowsByShowrunner {
    const paramsSchema = z.object({
      SGID: z.string(),
    });
    export type Params = z.TypeOf<typeof paramsSchema>;

    const responseSchema = z.object({
      data: z
        .object({
          id: z.string(),
          title: z.string(),
          startTime: z.string(),
          endTime: z.string(),
          link: z.string(),
        })
        .array(),
      page: z.number(),
      perPage: z.number(),
      total: z.number(),
    });
    export type Response = z.TypeOf<typeof responseSchema>;

    export const Request = async (params: Params) => {
      const parsed = paramsSchema.parse(params);
      if (parsed.SGID) {
        const response = await getRequest({
          endpoint: `/v2/sr-groups/${parsed.SGID}external-shows?page=1&perPage=30&onlyUpcoming=false&sortBy[startTime]=desc`,
        });
        return responseSchema.parse(response);
      }
    };
  }

  export namespace CreateExternalShowByArtist {
    const paramsSchema = z.object({
      title: z.string(),
      startTime: z.string(),
      endTime: z.string(),
      link: z.string(),
      artistID: z.string(),
    });
    export type Params = z.TypeOf<typeof paramsSchema>;

    export const Request = async (params: Params) => {
      const parsed = paramsSchema.parse(params);
      return await postRequest({
        endpoint: `/v2/artists/${parsed.artistID}/external-shows`,
        data: Lo.omit(parsed, 'artistID'),
        timeout: 30000,
      });
    };
  }

  export namespace CreateExternalShowByShowrunner {
    const paramsSchema = z.object({
      title: z.string(),
      startTime: z.string(),
      endTime: z.string(),
      link: z.string(),
      SGID: z.string(),
    });
    export type Params = z.TypeOf<typeof paramsSchema>;

    export const Request = async (params: Params) => {
      const parsed = paramsSchema.parse(params);
      return await postRequest({
        endpoint: `/v2/sr-groups/${parsed.SGID}/external-shows`,
        data: Lo.omit(parsed, 'SGID'),
        timeout: 30000,
      });
    };
  }

  export namespace EditExternalShowByArtist {
    const paramsSchema = z.object({
      title: z.string(),
      startTime: z.string(),
      endTime: z.string(),
      link: z.string(),
      artistID: z.string(),
      showID: z.string(),
    });
    export type Params = z.TypeOf<typeof paramsSchema>;

    export const Request = async (params: Params) => {
      const parsed = paramsSchema.parse(params);
      return await patchRequest({
        endpoint: `/v2/artists/${parsed.artistID}/external-shows/${parsed.showID}`,
        data: Lo.omit(parsed, 'artistID', 'showID'),
      });
    };
  }

  export namespace EditExternalShowByShowrunner {
    const paramsSchema = z.object({
      title: z.string(),
      startTime: z.string(),
      endTime: z.string(),
      link: z.string(),
      SGID: z.string(),
      showID: z.string(),
    });
    export type Params = z.TypeOf<typeof paramsSchema>;

    export const Request = async (params: Params) => {
      const parsed = paramsSchema.parse(params);
      return await patchRequest({
        endpoint: `/v2/sr-groups/${parsed.SGID}/external-shows/${parsed.showID}`,
        data: Lo.omit(parsed, 'SGID', 'showID'),
      });
    };
  }

  export namespace RemoveExternalShowByArtist {
    const paramsSchema = z.object({
      artistID: z.string(),
      showID: z.string(),
    });
    export type Params = z.TypeOf<typeof paramsSchema>;

    export const Request = async (params: Params) => {
      const parsed = paramsSchema.parse(params);
      return await deleteRequest({
        endpoint: `/v2/artists/${parsed.artistID}/external-shows/${parsed.showID}`,
      });
    };
  }

  export namespace RemoveExternalShowByShowrunner {
    const paramsSchema = z.object({
      SGID: z.string(),
      showID: z.string(),
    });
    export type Params = z.TypeOf<typeof paramsSchema>;

    export const Request = async (params: Params) => {
      const parsed = paramsSchema.parse(params);
      return await deleteRequest({
        endpoint: `/v2/sr-groups/${parsed.SGID}/external-shows/${parsed.showID}`,
      });
    };
  }

  export namespace CreateShowByVenue {
    const paramsSchema = createShowBaseSchema.extend({
      venueId: z.string(),
      type: z.union([z.literal('IKWP'), z.literal('Gig')]),
    });
    export type Params = z.TypeOf<typeof paramsSchema>;
    const responseSchema = createdShowsSchema;
    export type Response = z.TypeOf<typeof responseSchema>;

    export const Request = async (params: Params) => {
      const parsed = paramsSchema.parse(params);
      const result = await postRequest({
        endpoint: `/v2/venues/${parsed.venueId}/shows`,
        data: Lo.omit(parsed, 'venueId'),
        timeout: 30000,
      });

      return responseSchema.parse(result);
    };
  }

  export namespace CreateShowByArtist {
    const paramsSchema = createShowByManagerSchema;
    export type Params = z.TypeOf<typeof paramsSchema>;
    const responseSchema = createdShowsSchema;
    export type Response = z.TypeOf<typeof responseSchema>;

    export const Request = async (params: Params) => {
      const parsed = paramsSchema.parse(params);
      const result = await postRequest({
        endpoint: `/v2/managed-shows/by-artists`,
        data: parsed,
        timeout: 30000,
      });

      return responseSchema.parse(result);
    };
  }

  export namespace CreateShowBySRGroup {
    const paramsSchema = createShowByManagerSchema.extend({
      srGroupId: z.string(),
    });
    export type Params = z.TypeOf<typeof paramsSchema>;
    const responseSchema = createdShowsSchema;
    export type Response = z.TypeOf<typeof responseSchema>;

    export const Request = async (params: Params) => {
      const parsed = paramsSchema.parse(params);
      const result = await postRequest({
        endpoint: `/v2/managed-shows/by-sr-groups/${parsed.srGroupId}`,
        data: Lo.omit(parsed, 'srGroupId'),
        timeout: 30000,
      });

      return responseSchema.parse(result);
    };
  }

  export namespace EditShowDetails {
    const paramsSchema = z.object({
      showId: z.string(),
      name: z.string(),
      description: z.string(),
      startTime: z.string(),
      endTime: z.string(),
      tickets: z.discriminatedUnion('type', [flatTicketTypeSchema, tiersTicketTypeSchema]),
      minAge: z.number().min(0).nullable(),
      isPrivate: z.boolean(),
      isManageableByPerformers: z.boolean(),
      calendarColor: z.string(),
      showrunnerGroups: showrunnerGroupsSchema,
    });
    export type Params = z.TypeOf<typeof paramsSchema>;
    export const Request = async (params: Params) => {
      const parsed = paramsSchema.parse(params);
      await patchRequest({
        endpoint: `/v2/shows/${parsed.showId}`,
        data: Lo.omit(parsed, 'showId'),
      });
    };
  }
}

async function handleCreateShowOnQueryStarted(params: {
  dispatch: ThunkDispatch<unknown, unknown, any>;
  queryFulfilled: Promise<{ data: { createdShowIds?: string[] } }>;
}) {
  const { dispatch, queryFulfilled } = params;
  dispatch(updateView({ target: 'createShow', view: 1 }));
  try {
    const result = await queryFulfilled;
    const showId = Lo.get(result.data, 'createdShowIds.0');
    if (typeof showId === 'string') {
      dispatch(updateView({ target: 'createShow', view: 2, data: { showID: showId } }));
    }
  } catch (error) {
    dispatch(updateView({ target: 'createShow', view: 0 }));
  }
}

function resolveInvalidatedTags(
  params: ShowApi.CreateShowByArtist.Params | ShowApi.CreateShowByVenue.Params | ShowApi.CreateShowBySRGroup.Params,
) {
  const tags: { type: (typeof RTKTagTypes)[number] }[] = [{ type: 'Show' }];
  if ('venue' in params && params.venue.isNew === true) {
    tags.push({ type: 'Venue' });
  }
  if (params.showrunnerGroups.some((srGroup) => srGroup.isNew === true) === true) {
    tags.push({ type: 'SRG' });
  }
  if (params.performers.some((performer) => performer.isNew === true) === true) {
    tags.push({ type: 'Artist' });
  }
  return tags;
}

export const showAPI = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    createShowByVenue: builder.mutation<ShowApi.CreateShowByVenue.Response, ShowApi.CreateShowByVenue.Params>({
      async queryFn(data) {
        return await handleApiErrors(async () => await ShowApi.CreateShowByVenue.Request(data));
      },
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        handleCreateShowOnQueryStarted({
          dispatch,
          queryFulfilled,
        });
      },
      invalidatesTags: (res, err, params) => resolveInvalidatedTags(params),
    }),
    createShowByArtist: builder.mutation<ShowApi.CreateShowByArtist.Response, ShowApi.CreateShowByArtist.Params>({
      async queryFn(data) {
        return await handleApiErrors(async () => await ShowApi.CreateShowByArtist.Request(data));
      },
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        handleCreateShowOnQueryStarted({
          dispatch,
          queryFulfilled,
        });
      },
      invalidatesTags: (res, err, params) => resolveInvalidatedTags(params),
    }),
    createShowBySRGroup: builder.mutation<ShowApi.CreateShowBySRGroup.Response, ShowApi.CreateShowBySRGroup.Params>({
      async queryFn(data) {
        return await handleApiErrors(async () => await ShowApi.CreateShowBySRGroup.Request(data));
      },
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        handleCreateShowOnQueryStarted({
          dispatch,
          queryFulfilled,
        });
      },
      invalidatesTags: (res, err, params) => resolveInvalidatedTags(params),
    }),
    editShowDetails: builder.mutation<unknown, ShowApi.EditShowDetails.Params>({
      async queryFn(data) {
        return await handleApiErrors(async () => await ShowApi.EditShowDetails.Request(data));
      },
      invalidatesTags: (_res, _err, arg) => [{ type: 'Show', id: arg.showId }],
    }),
    getShowList: builder.query<any, ShowApi.GetShowsList.Params>({
      async queryFn(data) {
        return await handleApiErrors(async () => await ShowApi.GetShowsList.Request(data));
      },
      // providesTags: (result) => {
      //   if (result && result.data && Array.isArray(result.data)) {
      //     return result.data.map((res) => {
      //       return {
      //         type: 'Show',
      //         id: res?.id,
      //       };
      //     });
      //   }
      //   return [];
      // },
    }),
    getExternalShowsByArtist: builder.query<
      ShowApi.GetExternalShowsByArtist.Response,
      ShowApi.CreateExternalShowByArtist.Params
    >({
      async queryFn(data) {
        return await handleApiErrors(async () => await ShowApi.GetExternalShowsByArtist.Request(data));
      },
      providesTags: (result) => {
        if (result && result.data && Array.isArray(result.data)) {
          return result.data.map((res) => {
            return {
              type: 'ExternalShow',
              id: res?.id,
            };
          });
        }
        return [];
      },
    }),
    getExternalUpcomingShowsByArtist: builder.query<
      ShowApi.GetExternalShowsByArtist.Response,
      ShowApi.CreateExternalShowByArtist.Params
    >({
      async queryFn(data) {
        return await handleApiErrors(async () => await ShowApi.GetExternalUpcomingShowsByArtist.Request(data));
      },
      providesTags: (result) => {
        if (result && result.data && Array.isArray(result.data)) {
          return result.data.map((res) => {
            return {
              type: 'ExternalShow',
              id: res?.id,
            };
          });
        }
        return [];
      },
    }),

    getExternalShowsByShowrunner: builder.query<
      ShowApi.GetExternalShowsByShowrunner.Response,
      ShowApi.CreateExternalShowByShowrunner.Params
    >({
      async queryFn(data) {
        return await handleApiErrors(async () => await ShowApi.GetExternalShowsByShowrunner.Request(data));
      },
      providesTags: (result) => {
        if (result && result.data && Array.isArray(result.data)) {
          return result.data.map((res) => {
            return {
              type: 'ExternalShow',
              id: res?.id,
            };
          });
        }
        return [];
      },
    }),

    createExternalShowByArtist: builder.mutation<unknown, ShowApi.CreateExternalShowByArtist.Params>({
      async queryFn(data) {
        return await handleApiErrors(async () => await ShowApi.CreateExternalShowByArtist.Request(data));
      },
      invalidatesTags: (res, err) => [{ type: 'ExternalShow' }],
    }),
    createExternalShowByShowrunner: builder.mutation<unknown, ShowApi.CreateExternalShowByShowrunner.Params>({
      async queryFn(data) {
        return await handleApiErrors(async () => await ShowApi.CreateExternalShowByShowrunner.Request(data));
      },
      invalidatesTags: (res, err) => [{ type: 'ExternalShow' }],
    }),
    editExternalShowByArtist: builder.mutation<unknown, ShowApi.EditExternalShowByArtist.Params>({
      async queryFn(data) {
        return await handleApiErrors(async () => await ShowApi.EditExternalShowByArtist.Request(data));
      },
      invalidatesTags: (res, err) => [{ type: 'ExternalShow' }],
    }),
    editExternalShowByShowrunner: builder.mutation<unknown, ShowApi.EditExternalShowByShowrunner.Params>({
      async queryFn(data) {
        return await handleApiErrors(async () => await ShowApi.EditExternalShowByShowrunner.Request(data));
      },
      invalidatesTags: (res, err, params) => [{ type: 'ExternalShow', id: params.showID }],
    }),
    removeExternalShowByArtist: builder.mutation<unknown, ShowApi.RemoveExternalShowByArtist.Params>({
      async queryFn(data) {
        return await handleApiErrors(async () => await ShowApi.RemoveExternalShowByArtist.Request(data));
      },
      invalidatesTags: (res, err, params) => [{ type: 'ExternalShow', id: params.showID }],
    }),
    removeExternalShowByShowrunner: builder.mutation<unknown, ShowApi.RemoveExternalShowByShowrunner.Params>({
      async queryFn(data) {
        return await handleApiErrors(async () => await ShowApi.RemoveExternalShowByShowrunner.Request(data));
      },
      invalidatesTags: (res, err, params) => [{ type: 'ExternalShow', id: params.showID }],
    }),
  }),
});

export const {
  useCreateShowByVenueMutation,
  useCreateShowByArtistMutation,
  useCreateShowBySRGroupMutation,
  useEditShowDetailsMutation,
  useGetShowListQuery,
  useGetExternalShowsByArtistQuery,
  useGetExternalUpcomingShowsByArtistQuery,
  useGetExternalShowsByShowrunnerQuery,
  useCreateExternalShowByArtistMutation,
  useCreateExternalShowByShowrunnerMutation,
  useEditExternalShowByArtistMutation,
  useEditExternalShowByShowrunnerMutation,
  useRemoveExternalShowByArtistMutation,
  useRemoveExternalShowByShowrunnerMutation,
} = showAPI;
