import React, { Dispatch, FC, SetStateAction } from 'react';
import { TitleBar } from '../Shared/TitleBar';
import { FormInnerCont, FormSectionCont } from '../Shared/Containers';
import { TicketTypeButtons } from '../TicketTypeButtons';
import { ViewType } from '../../../Helpers/shared/Models/ViewType';
import { Box } from '@mui/material';
import { ShowType } from '../SingleTicketTypeButton';
import { useParams } from 'react-router-dom';
import { Show } from '../../../Helpers/shared/Models/Show';

interface ShowFormStep1Props {
  type: ViewType;
  setStep: Dispatch<SetStateAction<number>>;
  setShowType: Dispatch<SetStateAction<ShowType>>;
  setOpen: Dispatch<SetStateAction<boolean>>;
  show: Show;
}

export const ShowFormStep1: FC<ShowFormStep1Props> = ({ type, setShowType, setStep, setOpen }) => {
  const { showID } = useParams();

  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <TitleBar
        formName="Create Show"
        maxSteps={!showID ? 5 : 3}
        step={1}
        stepTitle="What type of show are you putting on?"
        setOpen={setOpen}
      />
      <FormInnerCont>
        <FormSectionCont>
          <TicketTypeButtons
            type={type}
            setShowType={setShowType}
            setStep={setStep}
          />
        </FormSectionCont>
      </FormInnerCont>
    </Box>
  );
};
