import React from 'react';
import { styled, Box, useTheme, useMediaQuery } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { application, Performer, Show } from '../../Helpers/shared/Models/Show';
import { openModal, openSidebar } from '../../Redux/UI/UISlice';
import { useAppSelector } from '../../hooks';
import { Button } from '../../ui/buttons/Button';
import { displayTicketPrice } from '../../Helpers/HelperFunctions';
import { colors } from '../../ui/shared/Colors';

interface GigButtonProps {
  show: Pick<
    Show,
    'performers' | 'applications' | 'invites' | '_key' | 'ticket_cost' | 'doorPrice' | 'ticket_tiers' | 'rsvpOnly'
  >;
  isGig?: boolean;
}
/**
 * @description Dynamic buttons for a show card
 * @param show - The show object
 * @param isGig - Whether the show is a gig
 */
export default function GigButton(props: GigButtonProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user.data);

  // Callback for array.prototype.some() to check if the current user is a performer
  const CurrentUserIsPerformer = (performer: Performer | application<any>) => performer.uid === user.displayUID;

  if (props.isGig) {
    // If the user is not an artist, return a disabled button
    if (!user.type?.artist?.enabled) {
      return (
        <ButtonsContainer>
          <WideButton
            disabled
            isPrimary
            // className={'disabled:bg-gray-300 hover:cursor-not-allowed'}
            // icon={'lock'}
            // iconClassName="text-base font-medium"
          >
            Apply
          </WideButton>
        </ButtonsContainer>
      );
    }

    if (props.show.performers.some(CurrentUserIsPerformer)) {
      return (
        <ButtonsContainer>
          <BlueButton isPrimary={false}>Performing</BlueButton>
        </ButtonsContainer>
      );
    }

    if (props.show.applications.some(CurrentUserIsPerformer)) {
      return (
        <ButtonsContainer>
          <WideButton isPrimary={false}>Applied</WideButton>
        </ButtonsContainer>
      );
    }

    if (props.show.invites?.some(CurrentUserIsPerformer)) {
      return (
        <ButtonsContainer>
          <WideButton isPrimary={false}>Invited</WideButton>
        </ButtonsContainer>
      );
    }

    return (
      <ButtonsContainer>
        <WideButton
          isPrimary
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation();
            dispatch(
              openSidebar({
                status: true,
                component: 'Apply',
                data: { showID: props.show._key },
              }),
            );
          }}
        >
          Apply
        </WideButton>
      </ButtonsContainer>
    );
  } else {
    let ticketLabel = displayTicketPrice(props.show);

    if (props.show?.rsvpOnly) {
      return (
        <ButtonsContainer>
          <WideButton
            size={isMobile ? 'small' : 'large'}
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation();
              dispatch(
                openSidebar({
                  status: true,
                  component: 'TicketPurchase',
                  data: { showID: props.show._key, rsvp: true },
                }),
              );
            }}
          >
            Sign In
          </WideButton>
          <WideButton
            size={isMobile ? 'small' : 'large'}
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation();
              navigate(`/shows/${props.show._key}`);
            }}
          >
            Details
          </WideButton>
        </ButtonsContainer>
      );
    }
    if (ticketLabel === 'Free') {
      return (
        <ButtonsContainer>
          <WideButton
            size={isMobile ? 'small' : 'large'}
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation();
              dispatch(
                openSidebar({
                  status: true,
                  component: 'TicketPurchase',
                  data: { showID: props.show._key, rsvp: true },
                }),
              );
            }}
          >
            Free
          </WideButton>
          <WideButton
            size={isMobile ? 'small' : 'large'}
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation();
              navigate(`/shows/${props.show._key}`);
            }}
          >
            Details
          </WideButton>
        </ButtonsContainer>
      );
    } else {
      return (
        <ButtonsContainer>
          <WideButton
            isPrimary
            size={isMobile ? 'small' : 'large'}
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation();
              dispatch(
                openModal({
                  status: true,
                  component: 'TicketPurchase',
                  data: { showID: props.show._key },
                }),
              );
            }}
          >
            Tickets
          </WideButton>
          <WideButton
            size={isMobile ? 'small' : 'large'}
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation();
              navigate(`/shows/${props.show._key}`);
            }}
          >
            Details
          </WideButton>
        </ButtonsContainer>
      );
    }
  }
}

const WideButton = styled(Button, {
  name: 'WideButton',
})({
  flexGrow: 1,
});

const ButtonsContainer = styled(Box, {
  name: 'ButtonsContainer',
})({
  display: 'flex',
  height: '100%',
  flexWrap: 'wrap',
  gap: '16px',
});

const BlueButton = styled(WideButton, {
  name: 'BlueButton',
})({
  color: colors.SystemWhite,
  backgroundColor: '#63b3ed',
});
