import { Box, styled, Typography } from '@mui/material';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { colors } from '../../ui/shared/Colors';
import { typography } from '../../ui/shared/TypographySharedElements';
import { GoogleMapsEmbed } from './GoogleMapsEmbed';
import { Button } from '../../ui/buttons/Button';
import { openModal } from '../../Redux/UI/UISlice';
import { displayTicketPrice } from '../../Helpers/HelperFunctions';
import { isNil } from 'lodash';
import calculateEventTime from '../../Helpers/shared/calculateEventTime';
import { useGetActiveRegionsQuery } from '../../Redux/API/RegionAPI';
import { getVenueLocationCode } from '../../Helpers/shared/getVenueLocationCode';
import { Venue } from '../../Helpers/shared/Models/Venue';
import { useAppDispatch } from '../../hooks';

export interface ShowDetailsMapWithAddrProps {
  location: {
    address: string;
    city: string;
    state: string;
    zip: string;
  };
  show: any;
  venue: Venue;
  isBottomLine?: boolean;
  applicationDetails?: boolean;
  manageView?: boolean;
}

export const ShowDetailsCondensedHeader: FC<ShowDetailsMapWithAddrProps> = ({
  location,
  show,
  venue,
  isBottomLine,
  applicationDetails,
  manageView,
}) => {
  const activeRegions = useGetActiveRegionsQuery();
  const [timezone, setTimezone] = useState('America/Chicago');
  const regions = activeRegions.data;
  const regionCode = getVenueLocationCode(venue);
  const dispatch = useAppDispatch();

  const displayTime = useMemo(() => {
    return isNil(show) === false
      ? calculateEventTime({ starttime: show.startTime, endtime: show.endTime }, timezone)
      : undefined;
  }, [show, timezone]);

  const ticketPrice = useMemo(
    () =>
      displayTicketPrice({
        ticket_tiers: show.ticketTiers,
        ticket_cost: show.ticketCost,
        doorPrice: show.doorPrice,
      }),
    [show],
  );

  const getPurschaseButtonLabel = () => {
    if (show.rsvpOnly) {
      return 'Sign In';
    }

    if (ticketPrice === 'Free') {
      return 'Show Info';
    }

    return 'Buy Tickets';
  };

  const handlePurchaseTicket = useCallback(() => {
    let modalData: {
      showID: string;
      free?: boolean;
      rsvp?: boolean;
    } = { showID: show.id };

    if (show.rsvpOnly) {
      modalData.rsvp = true;
    } else if (ticketPrice === 'Free') {
      modalData.free = true;
    }

    dispatch(
      openModal({
        status: true,
        component: 'TicketPurchase',
        data: modalData,
      }),
    );
  }, [ticketPrice, show.id, dispatch]);

  useEffect(() => {
    if (regions && regionCode) {
      regions.forEach((region) => {
        if (region.locations.includes(regionCode)) {
          setTimezone(region.timezone);
        }
      });
    }
  }, [regions, regionCode]);

  return (
    <ShowDetailsMapWithAddrCont>
      {venue?.name && <ShowDetailsMapWithAddrTitle>{show?.name}</ShowDetailsMapWithAddrTitle>}
      <GoogleMapsEmbedContainer>
        <GoogleMapsEmbed address={`${location.address} ${location.city} ${location.state} ${location.zip}`} />
      </GoogleMapsEmbedContainer>
      <ShowDetailsTicker>
        <ShowDetailsTickerItem>
          <i className="material-symbols-outlined">schedule</i> {displayTime.startdate.long} at {displayTime.starttime}
        </ShowDetailsTickerItem>
        <ShowDetailsTickerItem>
          <i className="material-symbols-outlined">local_activity</i>
          {ticketPrice}
        </ShowDetailsTickerItem>
        <ShowDetailsTickerItem>
          <i className="material-symbols-outlined">universal_currency</i>
          {show.minAge}+<i className="material-symbols-outlined">location_on</i>
          {location.city}, {location.state}
        </ShowDetailsTickerItem>
      </ShowDetailsTicker>
      {!applicationDetails && !manageView && (
        <ShowDetailsMapWithAddrStickyButtonContainer>
          <Button
            isPrimary
            size="large"
            disabled={false}
            onClick={handlePurchaseTicket}
          >
            {getPurschaseButtonLabel()}
          </Button>
        </ShowDetailsMapWithAddrStickyButtonContainer>
      )}
    </ShowDetailsMapWithAddrCont>
  );
};

const ShowDetailsMapWithAddrCont = styled(Box, {
  name: 'ShowDetailsHeadingRightCont',
})({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: 'auto',
  gap: '24px',
  backgroundColor: colors.SystemWhite,
  borderRadius: '12px',
  padding: '20px',
});

const ShowDetailsMapWithAddrTitle = styled(Typography, {
  name: 'ShowDetailsMapWithAddrTitle',
})({
  color: colors.SystemBlack,
  ...typography.headlineMedium,
  textAlign: 'center',
});

const ShowDetailsMapWithAddrStickyButtonContainer = styled(Box, {
  name: 'ShowDetailsMapWithAddrStickyButtonContainer',
})(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  bgcolor: 'transparent',
  [theme.breakpoints.down('md')]: {
    position: 'sticky',
    bottom: '20px',
  },
}));

const ShowDetailsTicker = styled(Box, {
  name: 'ShowDetailsTicker',
})({
  display: 'flex',
  gap: '10px',
  flexWrap: 'wrap',
  justifyContent: 'center',
});

const GoogleMapsEmbedContainer = styled(Box, {
  name: 'GoogleMapsEmbedContainer',
})(({ theme }) => ({
  height: '120px',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const ShowDetailsTickerItem = styled(Box, {
  name: 'ShowDetailsTickerItem',
})({
  display: 'flex',
  alignItems: 'center',
  gap: '3px',
});
