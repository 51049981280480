import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import AppControl from './AppControl';
import { SocketContext, socket } from './Context/socket';
import './Fonts/css/satoshi.css';
import { SHORT_LINK_URL } from './Helpers/configConstants';
import { initFacebook } from './initFacebook';
import './Main.css';
import { LinkAppControl } from './LinkAppControl';
import { store } from './Redux/store';

function resolveAppControl() {
  const origin = window.location.origin;
  if (origin.includes(SHORT_LINK_URL)) {
    return <LinkAppControl />;
  }
  return <AppControl />;
}

const container = document.getElementById('root');
const root = createRoot(container);
initFacebook();
root.render(
  <SocketContext.Provider value={socket}>
    <Provider store={store}>{resolveAppControl()}</Provider>
  </SocketContext.Provider>,
);
