import { Box, MenuItem, styled, Typography } from '@mui/material';
import React, { FC, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { colors } from '../../../ui/shared/Colors';
import { typography } from '../../../ui/shared/TypographySharedElements';
import { format } from 'date-fns';
import { Select } from '../../../ui/inputs/TextField';

enum RepeatTypeOptionsEnum {
  WEEKLY = 'Weekly',
  MONTHLY = 'Monthly',
}

export const RecurringShowFormPart: FC = () => {
  const createShowForm = useFormContext();
  const startTime = createShowForm.getValues('startTime');
  const dayName = useMemo(() => {
    return startTime ? format(startTime, 'EEEE') : '-';
  }, [startTime, format]);
  const timesOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  return (
    <RecurringShowFormPartCont>
      <RecurringShowFormPartRowCont>
        <RecurringShowFormPartTypography>This show will repeat</RecurringShowFormPartTypography>
        <SmallSelect
          {...createShowForm.register('repeatType')}
          type="text"
          label=""
          error={!!createShowForm.formState.errors?.repeatType?.message}
          helperText={
            !!createShowForm.formState.errors?.repeatType?.message && (
              <>{createShowForm.formState.errors?.repeatType?.message}</>
            )
          }
          select
          placeholder="Select"
          defaultValue={RepeatTypeOptionsEnum.WEEKLY.toLowerCase()}
          value={createShowForm.getValues('repeatType') || RepeatTypeOptionsEnum.WEEKLY.toLowerCase()}
        >
          <MenuItem
            key={RepeatTypeOptionsEnum.WEEKLY}
            value={RepeatTypeOptionsEnum.WEEKLY.toLowerCase()}
          >
            {RepeatTypeOptionsEnum.WEEKLY}
          </MenuItem>
          <MenuItem
            key={RepeatTypeOptionsEnum.MONTHLY}
            value={RepeatTypeOptionsEnum.MONTHLY.toLowerCase()}
          >
            {RepeatTypeOptionsEnum.MONTHLY}
          </MenuItem>
        </SmallSelect>
        <RecurringShowFormPartTypography>on {dayName}</RecurringShowFormPartTypography>
      </RecurringShowFormPartRowCont>
      <RecurringShowFormPartRowCont>
        <RecurringShowFormPartTypography>and will repeat</RecurringShowFormPartTypography>
        <SmallSelect
          {...createShowForm.register('repeatTimes')}
          type="number"
          label=""
          error={!!createShowForm.formState.errors?.repeatTimes?.message}
          helperText={
            !!createShowForm.formState.errors?.repeatTimes?.message && (
              <>{createShowForm.formState.errors?.repeatTimes?.message}</>
            )
          }
          select
          placeholder="Select"
          defaultValue={createShowForm.getValues('repeatTimes') || 4}
        >
          {timesOptions.map((option) => {
            return (
              <MenuItem
                key={option}
                value={option}
              >
                {option}
              </MenuItem>
            );
          })}
        </SmallSelect>
        <RecurringShowFormPartTypography>times</RecurringShowFormPartTypography>
      </RecurringShowFormPartRowCont>
    </RecurringShowFormPartCont>
  );
};

const RecurringShowFormPartCont = styled(Box, {
  name: 'RecurringShowFormPartCont',
})({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  alignItems: 'center',
  background: colors.SystemGray50,
  borderRadius: '12px',
  padding: '16px',
});

const RecurringShowFormPartRowCont = styled(Box, {
  name: 'RecurringShowFormPartRowCont',
})({
  display: 'flex',
  gap: '16px',
  alignItems: 'center',
  flexGrow: 1,
});

const RecurringShowFormPartTypography = styled(Typography, {
  name: 'RecurringShowFormPartTypography',
})({
  color: colors.SystemGray900,
  ...typography.bodySmallRegular,
});

const SmallSelect = styled(Select, {
  name: 'SmallSelect',
})({
  '.MuiSelect-select.MuiSelect-standard.MuiInputBase-input.MuiInput-input': {
    paddingRight: '32px',
  },
  '.MuiSvgIcon-root.MuiSelect-icon.MuiSelect-iconStandard': {
    top: 'calc(50% - 12px)',
    right: '10px',
  },
});
