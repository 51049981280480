import { styled, Typography } from '@mui/material';
import { colors } from '../../../ui/shared/Colors';
import { typography } from '../../../ui/shared/TypographySharedElements';

export const SectionTitle = styled(Typography, {
  name: 'SectionTitle',
})({
  color: colors.SystemGray900,
  ...typography.bodyLargeBold,
});

export const OffPlatformTitle = styled(Typography, {
  name: 'OffPlatformTitle',
})({
  color: colors.SystemGray900,
  ...typography.buttonSmall,
});

export const OffPlatformSubTitle = styled(Typography, {
  name: 'OffPlatformSubTitle',
})({
  color: colors.SystemGray900,
  ...typography.bodySmallRegular,
});
