import React, { Dispatch, FC, SetStateAction } from 'react';
import { ColorExtended } from './ColorPickers';
import { Box, styled } from '@mui/material';
import { colors } from '../../../ui/shared/Colors';

interface SingleColorToggleProps {
  color: ColorExtended;
  selected: boolean;
  setSelectedColor: Dispatch<SetStateAction<ColorExtended>>;
}

export const SingleColorToggle: FC<SingleColorToggleProps> = ({ color, selected, setSelectedColor }) => {
  return (
    <SingleColorToggleCont
      selected={selected}
      onClick={() => setSelectedColor(color)}
    >
      <SingleColorToggleInnerCont hex={color.hex}>
        <SingleColorToggleInnerItem isDark={color.isDark}>
          <SingleColorToggleInnerBlock isDark={color.isDark} />
        </SingleColorToggleInnerItem>
      </SingleColorToggleInnerCont>
    </SingleColorToggleCont>
  );
};

const SingleColorToggleCont = styled(Box, {
  name: 'SingleColorToggleCont',
  shouldForwardProp: (prop) => prop !== 'selected',
})<{ selected: boolean }>(({ selected }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: selected ? '2px' : 0,
  border: selected ? '2px solid black' : 'none',
  width: '54px',
  height: '54px',
  borderRadius: '9999px',
}));

const SingleColorToggleInnerCont = styled(Box, {
  name: 'SingleColorToggleInnerCont',
  shouldForwardProp: (prop) => prop !== 'hex',
})<{ hex: string }>(({ hex }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: hex,
  width: '48px',
  height: '48px',
  borderRadius: '9999px',
}));

const SingleColorToggleInnerBlock = styled(Box, {
  name: 'SingleColorToggleInnerBlock',
  shouldForwardProp: (prop) => prop !== 'isDark',
})<{ isDark: boolean }>(({ isDark }) => ({
  width: '20px',
  height: '4px',
  backgroundColor: isDark ? colors.SystemBlack : colors.SystemWhite,
  borderRadius: '999px',
}));

const SingleColorToggleInnerItem = styled(Box, {
  name: 'SingleColorToggleInnerItem',
  shouldForwardProp: (prop) => prop !== 'isDark',
})<{ isDark: boolean }>(({ isDark }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '32px',
  height: '12px',
  backgroundColor: isDark ? colors.SystemWhite : colors.SystemBlack,
  borderRadius: '999px',
}));
