export const VENUE_FAQ_DATA = [
  {
    name: 'faq1',
    title: 'I signed up, how do I get started?',
    details: `If you are in an established market, find your favorite artists and start booking shows. If you are in a newer market, reach out to the artists and tell them about TuneHatch. If you or they want a live demo, email info@tunehatch.com and we will set one up.`,
  },
  {
    name: 'faq2',
    title: 'Where do audience members purchase tickets to my stage(s)?',
    details: `Once an act is booked for your specific show (time/date/artist), TuneHatch will generate a unique QR Code that will automatically populate to all of you and the artist's social media and your website. This unique show-code can be cut-and-pasted to almost any medium – digital, print, posters, flyers, email, social media, facial tattoos, tee shirts, etc.`,
  },
  {
    name: 'faq3',
    title: 'Will I know how many tickets have been sold?',
    details: `Yes, all in real-time. You and the artist(s) will see all the information as-it's-happening on your TuneHatch dashboard. This will also help you prove to other artists how successful your venue has been.`,
  },
  {
    name: 'faq4',
    title: 'How is the money handled?',
    details: `TuneHatch has partnered with one of the most secure and reliable payment-processing companies on the planet. They will collect the money, and at your discretion, push the agreed payouts directly to everyone's preselected account. No more dealing with large piles of money, having a long line at the ATM or worrying about employees with sticky-fingers.`,
  },
  {
    name: 'faq5',
    title: 'What does TuneHatch cost?',
    details: `TuneHatch is free to the artist and venue. A small service charge is added to each ticket at the time of purchase – usually $2.00 to $4.00 – which is far below industry standard.`,
  },
  // {
  //   name: 'faq6',
  //   title: 'How does TuneHatch help with marketing and promotion of my upcoming shows?',
  //   details: `[NATHAN]`,
  // },
  // {
  //   name: 'faq7',
  //   title: 'As this is a digital platform, what other useful analytics will be on my dashboard?',
  //   details: `[NATHAN]`,
  // },
  {
    name: 'faq8',
    title: `I have a couple of different stages/venues I manage. Is TuneHatch right for me?`,
    details: `Yes, TuneHatch is designed to manage multiple stages or venues with ease. Whether you’re booking a single event or scheduling across multiple locations, you can keep everything organized within the platform. You'll also have access to real-time data for each venue, and you can manage payments, ticket sales, and promotions all in one place.`,
  },
  {
    name: 'faq9',
    title: `I'm having issues with a performer who played here. Who should I contact?`,
    details: `Please contact the performer directly. We try not to get involved in discussions that don't involve the TuneHatch platform directly.`,
  },
  {
    name: 'faq10',
    title: `I'm having issues with TuneHatch and I'm stuck. Is there an ACTUAL PERSON I can speak with?`,
    details: `Yes! TuneHatch offers real-time support through our customer service team. You can contact us by email at info@tunehatch.com, or call us at +1-954-515-7809 for direct assistance. We're here to help you with anything you need!`,
  },
];
