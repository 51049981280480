import React, { FC, Ref, useCallback, useMemo, useState } from 'react';
import { Box, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import { RightHandSideComponentContainer } from '../Shared/SharedContainers';
import { RightHandSideComponentTitle } from '../Shared/SharedElements';
import { UpcomingShow, useGetSortedUpcomingShowsWithExternal } from './useGetUpcomingShows';
import { isEmpty } from 'lodash';
import LoadingSpinner from '../../../Components/LoadingSpinner';
import { IconButton } from '../../../ui/buttons/IconButton';
import { ViewType } from '../../../Helpers/shared/Models/ViewType';
import { Button } from '../../../ui/buttons/Button';
import { useAppDispatch } from '../../../hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { ExternalShow } from '../../../Helpers/shared/Models/Show';
import { isExternalShow } from './helpers';
import { ExternalShowCard } from '../ShowCard/ExternalShowCard';
import { TopShowCard } from '../ShowCard/TopShowCard';

interface UpcomingShowsProps {
  userId: string;
  isMobile: boolean;
  type?: ViewType;
  isOwnProfile?: boolean;
  createShowBtnRef: Ref<HTMLButtonElement>;
}

export const UpcomingShows: FC<UpcomingShowsProps> = ({ userId, isMobile, type, isOwnProfile, createShowBtnRef }) => {
  const navigate = useNavigate();
  const { profileID } = useParams();
  const userID = profileID || userId;
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const upcomingShows = useGetSortedUpcomingShowsWithExternal({ userId, page, isMobile, setIsLoading, type });
  const actualPerPage = isMobile ? 1 : 3;
  const theme = useTheme();
  const isSmallDesktop = useMediaQuery(theme.breakpoints.down('lg'));
  const isNextPageEnabled = useMemo(() => {
    const totalViewed = page * actualPerPage;
    return upcomingShows.total > totalViewed;
  }, [isMobile, page, upcomingShows.total]);
  const viewableUpcomingShows = [...(upcomingShows.data ?? [])].slice(
    0 + (page - 1) * actualPerPage,
    page * actualPerPage,
  );
  const isPrevPageEnabled = useMemo(() => {
    return page > 1;
  }, [page]);

  const getEditPath = useCallback(() => {
    if (type === ViewType.Artist || type === ViewType.User) {
      return '/artist/manage-shows';
    } else if (type === ViewType.SRGroup) {
      return `/showrunner-tools/${userID}`;
    } else {
      return `/venues/manage/${userID}`;
    }
  }, [type, userID]);

  const setNextPage = useCallback(() => {
    if (isNextPageEnabled) {
      setPage((page) => page + 1);
    }
  }, [isNextPageEnabled]);

  const setPreviousPage = useCallback(() => {
    if (isPrevPageEnabled) {
      setPage((page) => page - 1);
    }
  }, [isPrevPageEnabled]);

  if (isEmpty(upcomingShows.data) && !isOwnProfile) {
    return null;
  }

  if (isLoading) {
    return (
      <RightHandSideComponentContainer>
        <RightHandSideComponentTitle>Upcoming shows</RightHandSideComponentTitle>
        <Box
          display="block"
          position="relative"
        >
          <LoadingSpinner />
        </Box>
      </RightHandSideComponentContainer>
    );
  }

  return (
    <RightHandSideComponentContainer>
      <UpcomingShowsHeadingContainer>
        <RightHandSideComponentTitle>Upcoming shows</RightHandSideComponentTitle>
        {/* {isOwnProfile && ( */}
        <Box
          display="flex"
          gap="8px"
        >
          {/* <Button
            size="small"
            onClick={() => {
              const path = getEditPath();
              navigate(path);
            }}
          >
            Edit Show
          </Button> */}
          <Button
            size="small"
            onClick={() => {
              navigate(`/create-show/${type}/${userID}`);
            }}
            ref={createShowBtnRef}
          >
            Add Show
          </Button>
        </Box>
        {/* )} */}
      </UpcomingShowsHeadingContainer>
      {!isEmpty(upcomingShows.data) ? (
        <>
          {viewableUpcomingShows.map((upcomingShow) => {
            const externalShow: ExternalShow = isExternalShow(upcomingShow) ? upcomingShow : null;
            const checkedUpcomingShow: UpcomingShow = !isExternalShow(upcomingShow)
              ? (upcomingShow as unknown as UpcomingShow)
              : null;
            return (
              <>
                {isExternalShow(upcomingShow) ? (
                  <ExternalShowCard {...externalShow} />
                ) : (
                  <TopShowCard
                    performers={{ artists: checkedUpcomingShow.artists, total: checkedUpcomingShow.artists.length }}
                    showrunnerGroup={checkedUpcomingShow.showrunnerGroups[0]}
                    id={checkedUpcomingShow.id}
                    date={checkedUpcomingShow.startTime}
                    venue={checkedUpcomingShow.venue}
                    name={checkedUpcomingShow.name}
                    profileColors={true}
                  />
                )}
              </>
            );
          })}
          <Box
            display="flex"
            justifyContent="center"
            gap="12px"
          >
            <IconButton
              isPrimary
              onClick={setPreviousPage}
              disabled={!isPrevPageEnabled}
              size={isSmallDesktop ? 'small' : 'large'}
              iconName="chevron_left"
            />
            <IconButton
              isPrimary
              onClick={setNextPage}
              disabled={!isNextPageEnabled}
              size={isSmallDesktop ? 'small' : 'large'}
            />
          </Box>
        </>
      ) : (
        <Typography
          padding="60px 0"
          textAlign="center"
          width="100%"
        >
          No upcoming shows
        </Typography>
      )}
    </RightHandSideComponentContainer>
  );
};

const UpcomingShowsHeadingContainer = styled(Box, {
  name: 'YouTubeHeadingContainer',
})({
  display: 'flex',
  justifyContent: 'space-between',
});
