import { Box, styled } from '@mui/material';
import React, { FC } from 'react';
import { ShowDetailsMapWithAddr, ShowDetailsMapWithAddrProps } from './ShowDetailsMapWithAddr';
import { NewModels } from '../../Helpers/shared/Models/NewModels';
import { ShowDetailsCondensedHeader } from './ShowDetailsCondensedHeader';

interface ShowDetailsHeadingProps extends ShowDetailsMapWithAddrProps {
  show: NewModels.Show;
}

export const ShowDetailsHeading: FC<ShowDetailsHeadingProps> = ({
  show,
  venue,
  location,
  applicationDetails,
  manageView,
}) => {
  return (
    <ShowDetailsHeadingCont>
      <ShowDetailsFlyer flyerUrl={show?.flyerWebP ?? show?.flyer} />
      <ShowDetailsCondensedHeader
        location={location}
        show={show}
        venue={venue}
        applicationDetails={applicationDetails}
        manageView={manageView}
      />
    </ShowDetailsHeadingCont>
  );
};

const ShowDetailsHeadingCont = styled(Box, {
  name: 'ShowDetailsHeadingCont',
})(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '40px',
  padding: '60px 70px',
  maxWidth: '1280px',
  width: '100%',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    padding: '0',
    gap: '20px',
  },
}));

const ShowDetailsFlyer = styled(Box, {
  name: 'ShowDetailsFlyer',
  shouldForwardProp: (prop) => prop !== 'flyerUrl',
})<{ flyerUrl: string }>(({ flyerUrl, theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  maxWidth: '580px',
  maxHeight: '580px',
  width: '100%',
  height: 0,
  paddingBottom: '45%',
  backgroundImage: `url(${flyerUrl})`,
  backgroundPosition: 'center',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  borderRadius: '8px',
  [theme.breakpoints.down('md')]: {
    backgroundRepeat: 'no-repeat',
    paddingBottom: '100%',
    maxWidth: '100%',
  },
}));
