import { Box, styled } from '@mui/material';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import LoadingSpinner from '../../Components/LoadingSpinner';
import { isViewType } from '../../Helpers/shared/Models/ViewType';
import { useResolveUsernameQuery } from '../../Redux/API/PublicAPI';
import ErrorPage from '../404';
import { HatchLinksPageContent } from './HatchLinksPage';

export const HatchLinkShortLink: FC<{}> = ({}) => {
  const { username } = useParams();
  const { isLoading, data } = useResolveUsernameQuery({ username });

  if (isLoading) {
    return (
      <LoadingContainer>
        <LoadingSpinner />
      </LoadingContainer>
    );
  }

  const resolvedType = data?.type;
  const resolvedID = data?.id;
  if (!isViewType(resolvedType) || typeof resolvedID !== 'string') {
    console.warn(`Type "${resolvedType}" is not supported view type or the ID "${resolvedID}" is invalid.`);
    return <ErrorPage />;
  }

  return (
    <HatchLinksPageContent
      type={resolvedType}
      profileID={resolvedID}
    />
  );
};

const LoadingContainer = styled(Box, {
  name: 'LoadingContainer',
})({
  justifyContent: 'center',
  width: '100%',
  height: '80vh',
  display: 'flex',
  alignItems: 'center',
});
