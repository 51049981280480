import React, { ChangeEvent, Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from 'react';
import { TitleBar } from '../Shared/TitleBar';
import { FormInnerCont, FormSectionCont } from '../Shared/Containers';
import { ViewType } from '../../../Helpers/shared/Models/ViewType';
import { Box, MenuItem } from '@mui/material';
import { ShowType } from '../SingleTicketTypeButton';
import { ShowFormNavButtons } from './ShowFormNavButtons';
import { FormSectionSubtitle, FormSectionTitle } from '../../Register/Shared/RegistrationTypographies';
import { Select } from '../../../ui/inputs/TextField';
import { useFormContext } from 'react-hook-form';
import { TH_DEFAULT_DEALS } from '../../../Helpers/configConstants';
import { NonDefaultPaymentDetailsForm } from './nonDefaultPaymentDetailsForm';
import {
  useCreateShowByArtistMutation,
  useCreateShowBySRGroupMutation,
  useCreateShowByVenueMutation,
} from '../../../Redux/API/ShowAPI';
import { z } from 'zod';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addStatusMessage } from '../../../Redux/UI/UISlice';
import { getTicketsFromCurrentStructure } from './helperFunctions';
import { ShowFormSuccessModal } from '../ShowFormSuccessModal';
import { getShowCreationFormSchema } from './ShowCreationFormSchema';
import { useGetActiveRegionsQuery } from '../../../Redux/API/RegionAPI';
import { useGetAllVenuesQuery } from '../../../Redux/API/PublicAPI';
import { isEmpty } from 'lodash';
import { locationValueSchema } from '../../../ui/inputs/LocationField';
import { getTimezoneOffset } from 'date-fns-tz';

type locationType = z.infer<typeof locationValueSchema>;

interface ShowFormStep5Props {
  viewType: ViewType;
  handleChangeStep: (fieldsToCheck: any[], back?: boolean) => void;
  showType: ShowType;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

interface getStartTimeAndEndTimeWithActualVenuesTimezoneParams {
  startTime: Date;
  endTime: Date;
  venueId: string;
  location?: locationType;
}

export const ShowFormStep5: FC<ShowFormStep5Props> = ({ viewType, showType, handleChangeStep, setOpen }) => {
  const { viewID } = useParams();
  const dispatch = useDispatch();
  const createShowForm = useFormContext();
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [submitBtnLoading, setSubmitBtnLoading] = useState(false);
  const [isDefault, setIsDefault] = useState(true);
  const [createShowByArtist, createShowByArtistStatus] = useCreateShowByArtistMutation();
  const [createShowBySRGroup, createShowBySRGroupStatus] = useCreateShowBySRGroupMutation();
  const [createShowByVenue, createShowByVenueStatus] = useCreateShowByVenueMutation();
  const [idOfCreatedShow, setIdOfCreatedShow] = useState('');
  const paymentTypeOptions = Object.entries(TH_DEFAULT_DEALS).map((entry) => ({
    value: entry[0],
    label: entry[1].label,
  }));
  const activeRegions = useGetActiveRegionsQuery();
  const showCreationFormSchema = getShowCreationFormSchema(false);
  const venues = useGetAllVenuesQuery();
  const changeStep = useCallback((back?: boolean) => {
    if (back) {
      handleChangeStep([], true);
    }
  }, []);

  const handleChangePaymentType = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      e.preventDefault();
      createShowForm.setValue('paymentType', e.target.value);
      setIsDefault(e.target.value === 'default');
    },
    [createShowForm],
  );

  const venueLocationParamsToCityAndStateCode = useCallback(
    (venueId: string, location?: locationType) => {
      if (venueId === '0' && location) {
        return `${(location.city, location.state)}`;
      }
      if (!isEmpty(venues?.data) && venueId !== '0') {
        const selectedVenue = venues.data[venueId];
        const venuesLocation = selectedVenue?.location;
        if (!isEmpty(venuesLocation)) {
          return `${venuesLocation.city}, ${venuesLocation.state}`;
        } else {
          return null;
        }
      }
    },
    [venues?.data],
  );

  //If needed we can export this and use also in step 3
  const getStartTimeAndEndTimeWithActualVenuesTimezone = useCallback(
    ({ startTime, endTime, venueId, location }: getStartTimeAndEndTimeWithActualVenuesTimezoneParams) => {
      if (!isEmpty(activeRegions?.data)) {
        let locationParam = '';
        if (venueId === '0' && location) {
          locationParam = venueLocationParamsToCityAndStateCode(venueId, location);
        } else {
          locationParam = venueLocationParamsToCityAndStateCode(venueId);
        }
        const tzObject = activeRegions.data?.find((activeRegion) => {
          const foundRegion = activeRegion.locations.find((location) => location === locationParam);
          return !isEmpty(foundRegion);
        });
        if (!isEmpty(tzObject)) {
          const tzOffset = getTimezoneOffset(tzObject.timezone, startTime);
          let offsetMinutes = Math.abs(Math.floor((tzOffset / (1000 * 60)) % 60)).toString();
          if (offsetMinutes.length === 1) {
            offsetMinutes = `0${offsetMinutes}`;
          }
          let offsetHours = Math.floor((tzOffset / (1000 * 60 * 60)) % 24).toString();
          if (offsetHours.length === 1) {
            offsetHours = `0${offsetHours}`;
          }
          if (offsetHours.indexOf('-') !== -1 && offsetHours.length === 2) {
            offsetHours = `${offsetHours[0]}0${offsetHours[1]}`;
          }
          const isoStartTime = startTime.toISOString();
          const isoEndTime = endTime.toISOString();
          const isoStartTimeSubstr = isoStartTime.substring(0, isoStartTime.length - 1);
          const isoEndTImeSubstr = isoEndTime.substring(0, isoEndTime.length - 1);
          const startTimeLocalized = `${isoStartTimeSubstr}${offsetHours}:${offsetMinutes}`;
          const endTimeLocalized = `${isoEndTImeSubstr}${offsetHours}:${offsetMinutes}`;
          return { startTimeLocalized, endTimeLocalized };
        }
        return { startTimeLocalized: startTime.toISOString(), endTimeLocalized: endTime.toISOString() };
      }
    },
    [activeRegions?.data, venueLocationParamsToCityAndStateCode],
  );

  const submitCreateForm = useCallback(
    async (data: z.infer<typeof showCreationFormSchema>) => {
      setSubmitBtnLoading(true);
      const { startTimeLocalized, endTimeLocalized } = getStartTimeAndEndTimeWithActualVenuesTimezone({
        startTime: data.startTime,
        endTime: data.endTime,
        venueId: data.venues[0]?.id,
        location: data.venues[0]?.location,
      });
      const getRepeatType = () => {
        return data.repeatType === 'monthly' ? 'monthly' : 'weekly';
      };
      const getShowType = () => {
        return showType === ShowType.GIG ? 'Gig' : 'IKWP';
      };
      const sharedPayloadPart = {
        name: data.name,
        description: data.description,
        calendarColor: data.calendarColor,
        endTime: endTimeLocalized,
        startTime: startTimeLocalized,
        isManageableByPerformers: data.isManageableByArtists,
        isPrivate: data.isPrivate,
        minAge: Number(data.minAge),
        tickets: getTicketsFromCurrentStructure(data, false),
        base64Flyer: data.flyer,
        repeatSchema: data.repeatingShow
          ? { type: getRepeatType() as 'weekly' | 'monthly', times: Number(data.repeatTimes) }
          : null,
        performers: data.artists.map((artist) => ({ ...artist, isNew: artist.id === '0' })),
        showrunnerGroups: data.showrunners.map((showrunner) => ({ ...showrunner, isNew: showrunner.id === '0' })),
        deal:
          data.paymentType === 'default'
            ? { type: data.paymentType as 'default' }
            : ({
                type: data.paymentType,
                defaults: {
                  guarantee: data.guaranteeAmount ? Number(data.guaranteeAmount) : null,
                  productionFee: data.productionFee ? Number(data.productionFee) : null,
                },
              } as
                | {
                    type?: 'ticket_split';
                    defaults?: {
                      percentage?: number;
                      guarantee?: number;
                      production_fee?: number;
                      even_split?: boolean;
                    };
                  }
                | { type?: 'guarantee'; defaults?: { guarantee?: number; production_fee?: number } }),
      };
      try {
        if (viewType === 'venue') {
          const payload = {
            ...sharedPayloadPart,
            type: getShowType() as 'Gig' | 'IKWP',
            venueId: viewID,
          };
          const res = await createShowByVenue(payload);
          if ('data' in res) {
            dispatch(
              addStatusMessage({
                type: 'success',
                message: 'Show was succesfully created',
              }),
            );
            setIdOfCreatedShow(res.data?.createdShowIds[0]);
            setSuccessModalOpen(true);
          } else if ('error' in res) {
            if ('data' in res.error) {
              dispatch(
                addStatusMessage({
                  type: 'error',
                  message: `${res.error?.data}`,
                }),
              );
            } else {
              dispatch(
                addStatusMessage({
                  type: 'error',
                  message: 'Something went wrong',
                }),
              );
            }
          } else {
            dispatch(
              addStatusMessage({
                type: 'error',
                message: 'Something went wrong',
              }),
            );
          }
        }
        if (viewType === 'artist') {
          const payload = {
            ...sharedPayloadPart,
            venue: data.venues.map((venue) => ({ ...venue, isNew: venue.id === '0' }))[0],
          };
          const res = await createShowByArtist(payload);
          if ('data' in res) {
            dispatch(
              addStatusMessage({
                type: 'success',
                message: 'Show was succesfully created',
              }),
            );
            setIdOfCreatedShow(createShowByArtistStatus.data?.createdShowIds[0]);
            setSuccessModalOpen(true);
          } else if ('error' in res) {
            if ('data' in res.error) {
              dispatch(
                addStatusMessage({
                  type: 'error',
                  message: `${res.error?.data}`,
                }),
              );
            } else {
              dispatch(
                addStatusMessage({
                  type: 'error',
                  message: 'Something went wrong',
                }),
              );
            }
          } else {
            dispatch(
              addStatusMessage({
                type: 'error',
                message: 'Something went wrong',
              }),
            );
          }
        }
        if (viewType === 'showrunner') {
          const payload = {
            ...sharedPayloadPart,
            venue: data.venues.map((venue) => ({ ...venue, isNew: venue.id === '0' }))[0],
            srGroupId: viewID,
          };
          const res = await createShowBySRGroup(payload);
          if ('data' in res) {
            dispatch(
              addStatusMessage({
                type: 'success',
                message: 'Show was succesfully created',
              }),
            );
            setIdOfCreatedShow(createShowBySRGroupStatus.data?.createdShowIds[0]);
            setSuccessModalOpen(true);
          } else if ('error' in res) {
            if ('data' in res.error) {
              dispatch(
                addStatusMessage({
                  type: 'error',
                  message: `${res.error?.data}`,
                }),
              );
            } else {
              dispatch(
                addStatusMessage({
                  type: 'error',
                  message: 'Something went wrong',
                }),
              );
            }
          } else {
            dispatch(
              addStatusMessage({
                type: 'error',
                message: 'Something went wrong',
              }),
            );
          }
        }
        setSubmitBtnLoading(false);
      } catch {
        setSubmitBtnLoading(false);
        dispatch(
          addStatusMessage({
            type: 'error',
            message: 'Something went wrong',
          }),
        );
      }
    },
    [
      createShowByArtist,
      createShowBySRGroup,
      createShowByVenue,
      createShowByArtistStatus,
      createShowBySRGroupStatus,
      createShowByVenueStatus,
      dispatch,
      getStartTimeAndEndTimeWithActualVenuesTimezone,
    ],
  );

  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <TitleBar
        formName="Create Show"
        maxSteps={5}
        step={5}
        stepTitle="Choose how artists will be paid"
        setOpen={setOpen}
      />
      <FormInnerCont>
        <FormSectionCont>
          <FormSectionTitle>Payment agreement</FormSectionTitle>
          <FormSectionSubtitle>
            Choose how artists who perform at your show will be paid. These will be the defaults for artists who are
            invited or apply. You can change specifics on guarantees and production feed for each artist who you invite
            or receive an application form.
          </FormSectionSubtitle>
          <Select
            {...createShowForm.register('paymentType')}
            type="text"
            label="Payment Option"
            error={!!createShowForm.formState.errors?.paymentType?.message}
            helperText={
              !!createShowForm.formState.errors?.paymentType?.message && (
                <>{createShowForm.formState.errors?.paymentType?.message}</>
              )
            }
            onChange={(e) => handleChangePaymentType(e)}
            select
            placeholder="Select"
            defaultValue={'default'}
          >
            {paymentTypeOptions.map((paymentType) => {
              return (
                <MenuItem
                  key={paymentType.value}
                  value={paymentType.value}
                >
                  {paymentType.label}
                </MenuItem>
              );
            })}
          </Select>
          {!isDefault && <NonDefaultPaymentDetailsForm />}
        </FormSectionCont>
        <ShowFormNavButtons
          changeStep={changeStep}
          isSubmit
          submitFn={createShowForm.handleSubmit(submitCreateForm, (err) => {
            console.log(err);
          })}
          disabledSubmit={isEmpty(activeRegions?.data) || isEmpty(venues?.data) || submitBtnLoading}
          isSubmitLoading={submitBtnLoading}
        />
      </FormInnerCont>
      <ShowFormSuccessModal
        viewType={viewType}
        viewID={viewID}
        isEdit={false}
        isOpen={successModalOpen}
        setIsOpen={setSuccessModalOpen}
        showID={idOfCreatedShow}
      />
    </Box>
  );
};
