import { Box, useMediaQuery, useTheme } from '@mui/material';
import React, { Dispatch, FC, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataCy } from '../../Helpers/DataCy';

import { useAppSelector } from '../../hooks';
import { NavbarLinkButton } from './Navbar';
import { isEmpty } from 'lodash';
import { LoginContainer, NavbarLoginIcon, NavbarLoginText } from './Login';
import { ProfileUserMenuLink, ProfileUserMenuMainCont } from './ProfileUserMenu';

interface HamburgerMenuProps {
  isMenuOpen: boolean;
  setIsMenuOpen: Dispatch<SetStateAction<boolean>>;
  parentRef: Element;
}

export const HamburgerMenu: FC<HamburgerMenuProps> = ({ isMenuOpen, setIsMenuOpen, parentRef }) => {
  const navigate = useNavigate();
  const darkMode = useAppSelector((state) => state.ui.grid.darkMode);
  const user = useAppSelector((state) => state.user.data);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <ProfileUserMenuMainCont
      open={isMenuOpen && isMobile}
      onClose={() => {
        setIsMenuOpen(false);
      }}
      darkMode={darkMode}
      anchorEl={parentRef}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      isLoggedIn={!!user.displayUID}
    >
      <ProfileUserMenuLink
        darkMode={darkMode}
        to="/apply"
        onClick={() => setIsMenuOpen(false)}
      >
        Explore Gigs
      </ProfileUserMenuLink>
      <ProfileUserMenuLink
        darkMode={darkMode}
        to="/shows"
        onClick={() => setIsMenuOpen(false)}
      >
        Explore Shows
      </ProfileUserMenuLink>
      <ProfileUserMenuLink
        darkMode={darkMode}
        to="/artists"
        onClick={() => setIsMenuOpen(false)}
      >
        Explore Artists
      </ProfileUserMenuLink>
      {/* <ProfileUserMenuLink
        darkMode={darkMode}
        to="/resources"
        onClick={() => setIsMenuOpen(false)}
      >
        Resources
      </ProfileUserMenuLink> */}
      {isEmpty(user.displayUID) && (
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          justifyContent="flex-end"
        >
          <Box
            display="flex"
            flexDirection="column"
            gap="20px"
            padding="10px 20px"
          >
            <NavbarLinkButton
              href="/register"
              isVenuesLink={false}
              darkMode={darkMode}
              onClick={() => setIsMenuOpen(false)}
            >
              Join as artist or promoter
            </NavbarLinkButton>
            <NavbarLinkButton
              href="/register/venue"
              isVenuesLink={true}
              darkMode={darkMode}
              onClick={() => setIsMenuOpen(false)}
            >
              Join as venue
            </NavbarLinkButton>
          </Box>
          <LoginContainer
            onClick={() => navigate('/login')}
            data-cy={DataCy.button('login')}
          >
            <NavbarLoginIcon
              className="material-symbols-outlined"
              darkMode={darkMode}
              onClick={() => setIsMenuOpen(false)}
            >
              login
            </NavbarLoginIcon>
            <NavbarLoginText darkMode={darkMode}>Login</NavbarLoginText>
          </LoginContainer>
        </Box>
      )}
    </ProfileUserMenuMainCont>
  );
};
