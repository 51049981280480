import React, { FC } from 'react';
import { FormRowCont } from '../Shared/Containers';
import { TextField } from '../../../ui/inputs/TextField';
import { useFormContext } from 'react-hook-form';

export const NonDefaultPaymentDetailsForm: FC = () => {
  const createShowForm = useFormContext();
  return (
    <FormRowCont>
      <TextField
        {...createShowForm.register('guaranteeAmount')}
        type="text"
        label="Guarantee Amount"
        placeholder="Enter guarantee amount"
        error={!!createShowForm.formState.errors?.guaranteeAmount?.message}
        helperText={
          !!createShowForm.formState.errors?.guaranteeAmount?.message && (
            <>{createShowForm.formState.errors?.guaranteeAmount?.message}</>
          )
        }
        fullWidth
      />
      <TextField
        {...createShowForm.register('productionFee')}
        type="text"
        label="Production Fee"
        placeholder="Enter production fee"
        error={!!createShowForm.formState.errors?.productionFee?.message}
        helperText={
          !!createShowForm.formState.errors?.productionFee?.message && (
            <>{createShowForm.formState.errors?.productionFee?.message}</>
          )
        }
        fullWidth
      />
    </FormRowCont>
  );
};
