import { createTheme } from '@mui/material';

export const theme = createTheme({
  typography: {
    fontFamily: 'Satoshi-Variable',
  },
  breakpoints: {
    values: {
      xs: 401,
      sm: 601,
      md: 901,
      lg: 1281,
      xl: 1441,
    },
  },
});
