import React, { FC } from 'react';
import { Box, styled } from '@mui/material';
import { HeadlineItem } from '../Shared/HeadlineItem';
import { LeftHandSideComponentTitle } from '../Shared/SharedElements';
import { LeftSideComponentContainer } from '../Shared/SharedContainers';

interface ContactInfoProps {
  email?: string;
  phone?: string;
  instagram?: string;
}

export const ContactInfo: FC<ContactInfoProps> = ({ email, phone, instagram }) => {
  return (
    <LeftSideComponentContainer>
      <LeftHandSideComponentTitle>Contact</LeftHandSideComponentTitle>
      <ContactContainer>
        {email && (
          <HeadlineItem
            text={<a href={`mailto:${email}`}>{email}</a>}
            icon="email"
          />
        )}
        {phone && (
          <HeadlineItem
            text={<a href={`tel:${phone}`}>{phone}</a>}
            icon="phone"
          />
        )}
        {instagram && (
          <HeadlineItem
            text={<a href={`https://www.instagram.com/${instagram}`}>{instagram}</a>}
            icon=" " //since were using remix icons, the icon goes into the class name instead
            iconClassName="ri-instagram-line"
          />
        )}
      </ContactContainer>
    </LeftSideComponentContainer>
  );
};

const ContactContainer = styled(Box, {
  name: 'ContactContainer',
})({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
});
