import { Box, styled } from '@mui/material';
import React, { FC } from 'react';
import { IconButton } from '../../../ui/buttons/IconButton';

export const VenueReviewsButtonBox = ({ next, previous, ...rest }: any) => {
  const {
    carouselState: { currentSlide, totalItems, slidesToShow },
  } = rest;
  console.log(totalItems - 2, currentSlide);
  return (
    <VenueReviewsButtonBoxContainer>
      <IconButtonSpecial
        isPrimary
        iconName="chevron_left"
        disabled={currentSlide === 0}
        size="small"
        onClick={previous}
      />
      <IconButtonSpecial
        isPrimary
        iconName="chevron_right"
        disabled={currentSlide === totalItems - slidesToShow}
        size="small"
        onClick={next}
      />
    </VenueReviewsButtonBoxContainer>
  );
};

const VenueReviewsButtonBoxContainer = styled(Box, {
  name: 'VenueReviewsButtonBoxContainer',
})(({ theme }) => ({
  display: 'flex',
  gap: '12px',
  position: 'absolute',
  top: '-76px',
  right: '50px',
  transform: 'translate(0, -50%)',
  [theme.breakpoints.down('md')]: {
    top: '-50px',
    left: 0,
    right: 'unset',
    justifyContent: 'center',
  },
}));

const IconButtonSpecial = styled(IconButton, {
  name: 'IconButtonSpecial',
})(({ theme }) => ({
  width: '72px',
  height: '72px',
  i: {
    fontSize: '32px',
  },
  [theme.breakpoints.down('lg')]: {
    width: '48px',
    height: '48px',
    i: {
      fontSize: '20px',
    },
  },
}));
