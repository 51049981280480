import React from 'react';
import ArtistCard from './ArtistCard/ArtistCard';
import VenueCard from './VenueCard';
import ShowrunnerCard from './ShowrunnerCard';
import { ViewType } from '../../Helpers/shared/Models/ViewType';

export default function TargetCard(props: { type: ViewType; id: string }) {
  switch (props.type) {
    case ViewType.User:
    case ViewType.Artist:
      return <ArtistCard id={props.id} />;
    case ViewType.Venue:
      return <VenueCard id={props.id} />;
    case ViewType.SRGroup:
      return <ShowrunnerCard id={props.id} />;
  }
}
