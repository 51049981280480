import z from 'zod';

export namespace HatchlinksFormData {
  const externalLinkSchema = z.object({
    url: z.string().url('Must be a valid URL with http or https').or(z.literal('')).nullish(),
    text: z.string().nullish(),
  });

  export const formSchema = z.object({
    username: z.string().nullable(),
    customOptions: z.object({
      alternativeHatchLinkLabel: z.boolean(),
      colors: z.object({
        hlColor: z.object({
          hex: z.string().optional(),
          isDark: z.boolean().optional(),
        }),
      }),
    }),
    externalLinks: z.array(externalLinkSchema).max(10, 'Maximum of 10 external links allowed'),
  });

  export type Values = z.infer<typeof formSchema>;
}
