import React, { useState } from 'react';
import styled from 'styled-components';
import IconButton from '../../../../Components/Buttons/IconButton';
import ToggleSlider from '../../../../Components/Inputs/InputTypes/ToggleSlider';
import { VenueApi } from '../../../../Redux/API/VenueAPI';
import { ScheduleSection } from './ScheduleSection';

export type OnShareFlyer = (
  params: Pick<VenueApi.ShareFlyer.Params, 'shareAt' | 'pageId' | 'shareToFacebook' | 'shareToInstagram'>,
) => void;
interface Props {
  page: VenueApi.MetaPage;
  onShareFlyer: OnShareFlyer;
  isSharing: boolean;
}

export function ShareSection(props: Props) {
  const { page } = props;
  const [shareToFB, setShareToFB] = useState(false);
  const [shareToIG, setShareToIG] = useState(false);
  const [isScheduling, setIsScheduling] = useState(false);

  if (props.isSharing) {
    return <Box>{isScheduling ? 'Scheduling...' : 'Sharing...'}</Box>;
  }

  return (
    <Box>
      <div className="flex flex-col items-center gap-2 mb-3">
        {page.fbPageId && (
          <ToggleSlider
            label="Share to Facebook"
            value={shareToFB}
            clickFn={() => setShareToFB(!shareToFB)}
          />
        )}
        {page.igPageId && (
          <ToggleSlider
            label="Share to Instagram"
            value={shareToIG}
            clickFn={() => setShareToIG(!shareToIG)}
          />
        )}
        {isScheduling === false && (
          <>
            <IconButton
              className="flex items-center mx-auto border p-2 pl-4 pr-4 mb-2 rounded-full filter hover:brightness-105 bg-orange text-white"
              onClick={(e: React.MouseEvent) => {
                props.onShareFlyer({
                  shareAt: null,
                  pageId: page.id,
                  shareToFacebook: shareToFB,
                  shareToInstagram: shareToIG,
                });
              }}
            >
              Share Now
            </IconButton>
            <IconButton
              icon="schedule"
              className="flex items-center mx-auto border gap-2 p-2 pl-4 pr-4 mb-2 rounded-full hover:bg-gray-100"
              onClick={(e: React.MouseEvent) => {
                setIsScheduling(true);
              }}
            >
              Schedule
            </IconButton>
          </>
        )}
        {isScheduling === true && (
          <ScheduleSection
            onPanelClose={() => setIsScheduling(false)}
            onSchedule={(date) => {
              props.onShareFlyer({
                shareAt: date,
                pageId: page.id,
                shareToFacebook: shareToFB,
                shareToInstagram: shareToIG,
              });
            }}
          />
        )}
      </div>
    </Box>
  );
}

const Box = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 10px;
`;
