import React, { useState } from 'react';
import IconButton from '../../../../Components/Buttons/IconButton';

interface Props {
  onPanelClose: VoidFunction;
  onSchedule: (date: string) => void;
}

export function ScheduleSection(props: Props) {
  const [shareAt, setShareAt] = useState<string | null>(null);
  console.log({ shareAt });
  return (
    <>
      <div className="flex gap-2 justify-center items-center mt-4">
        <input
          className="border rounded-full h-8 pr-2 pl-2"
          type="datetime-local"
          name="posttime"
          value={shareAt ?? ''}
          onChange={(e) => {
            const value = e.target.value;
            setShareAt(value !== '' ? value : null);
          }}
        />
        <button
          className="material-symbols-outlined border rounded-full w-8 h-8 text-xl text-red-500"
          onClick={() => {
            props.onPanelClose();
          }}
        >
          close
        </button>
      </div>
      <IconButton
        icon="schedule"
        className="mt-4 flex items-center mx-auto border gap-2 p-2 pl-4 pr-4 mb-2 rounded-full hover:bg-gray-100"
        disabled={shareAt === null}
        onClick={() => {
          if (shareAt === null) {
            return;
          }
          props.onSchedule(new Date(shareAt).toISOString());
        }}
      >
        Schedule Post
      </IconButton>
    </>
  );
}
