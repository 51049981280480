import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'remixicon/fonts/remixicon.css';
import Grid from './Components/Layout/Grid';
import { LinkFactory } from './Helpers/LinkFactory';
import { HatchLinkShortLink } from './Pages/HatchLinks/HatchLinkShortLink';

function RedirectToHomepage() {
  window.open(LinkFactory.homepage(), '_self');
  return <></>;
}

export function LinkAppControl() {
  return (
    <Router>
      <div
        className="hidden bg-sky-400 bg-amber-700 text-amber-700 text-sky-400 text-amber-400 text-blue-600 text-gray-800 text-green-400"
        id="colorInitializer"
      ></div>
      <Grid>
        <Routes>
          <Route
            path="/:username"
            element={<HatchLinkShortLink />}
          />
          <Route
            path={'*'}
            element={<RedirectToHomepage />}
          />
        </Routes>
      </Grid>
    </Router>
  );
}
