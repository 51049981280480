import { Avatar, Box, Icon, styled, Typography } from '@mui/material';
import React, { FC } from 'react';
import { ColorExtended } from './ColorPickers';
import { colors } from '../../../ui/shared/Colors';
import { typography } from '../../../ui/shared/TypographySharedElements';
import Img from '../../../Components/Images/Img';
import { isEmpty } from 'lodash';

interface PreviewProps {
  avatarUrl: string;
  selectedColor: ColorExtended;
  profileName: string;
}

export const Preview: FC<PreviewProps> = ({ avatarUrl, selectedColor, profileName }) => {
  return (
    <PreviewCont>
      <PreviewTitle>Preview</PreviewTitle>
      <PreviewProfileMock hex={selectedColor.hex}>
        <PreviewProfileAvatar src={avatarUrl} />
        <PreviewProfileNameCont>
          <PreviewProfileName isDark={selectedColor.isDark}>{profileName}</PreviewProfileName>
          <PreviewProfileSocialIconsCont>
            <PreviewProfileSocialIconCont>
              <PreviewProfileSocialIcon isDark={selectedColor.isDark}></PreviewProfileSocialIcon>
            </PreviewProfileSocialIconCont>
            <PreviewProfileSocialIconCont>
              <PreviewProfileSocialIcon isDark={selectedColor.isDark}></PreviewProfileSocialIcon>
            </PreviewProfileSocialIconCont>
            <PreviewProfileSocialIconCont>
              <PreviewProfileSocialIcon isDark={selectedColor.isDark}></PreviewProfileSocialIcon>
            </PreviewProfileSocialIconCont>
            <PreviewProfileSocialIconCont>
              <PreviewProfileSocialIcon isDark={selectedColor.isDark}></PreviewProfileSocialIcon>
            </PreviewProfileSocialIconCont>
          </PreviewProfileSocialIconsCont>
          <PreviewProfileElementMock isDark={selectedColor.isDark} />
          <PreviewProfileElementMock isDark={selectedColor.isDark} />
        </PreviewProfileNameCont>
      </PreviewProfileMock>
    </PreviewCont>
  );
};

const PreviewCont = styled(Box, {
  name: 'PreviewCont',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '24px',
  gap: '16px',
  borderRadius: '24px',
  backgroundColor: colors.SystemGray100,
}));

const PreviewTitle = styled(Typography, {
  name: 'PreviewTitle',
})(({ theme }) => ({
  color: colors.SystemGray500,
  ...typography.bodyMediumBold,
  width: '100%',
  textAlign: 'center',
}));

const PreviewProfileMock = styled(Box, {
  name: 'PreviewProfileMock',
  shouldForwardProp: (prop) => prop !== 'hex',
})<{ hex: string }>(({ hex }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '32px 20px',
  gap: '20px',
  backgroundColor: hex,
  maxWidth: '320px',
  width: '100%',
  borderRadius: '24px',
}));

const PreviewProfileAvatar = styled(Avatar, {
  name: 'PreviewProfileAvatar',
})({
  width: '60px',
  height: '60px',
  borderRadius: '9999px',
});

const PreviewProfileNameCont = styled(Box, {
  name: 'PreviewProfileNameCont',
})({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  alignItems: 'center',
});

const PreviewProfileName = styled(Typography, {
  name: 'PreviewProfileName',
  shouldForwardProp: (prop) => prop !== 'isDark',
})<{ isDark: boolean }>(({ isDark }) => ({
  color: isDark ? colors.SystemWhite : colors.SystemBlack,
  ...typography.subtitle,
  width: '100%',
  textAlign: 'center',
  textWrap: 'wrap',
}));

const PreviewProfileSocialIconsCont = styled(Box, {
  name: 'PreviewProfileSocialIconsCont',
})({
  display: 'flex',
  justifyContent: 'center',
  gap: '24px',
  width: '100%',
});

const PreviewProfileSocialIconCont = styled(Box, {
  name: 'PreviewProfileSocialIconCont',
})(({ theme }) => ({
  position: 'relative',
  width: '24px',
  height: '0',
  paddingBottom: '24px',
  [theme.breakpoints.down('md')]: {
    width: '36px',
    paddingBottom: '36px',
  },
}));

const PreviewProfileSocialIcon = styled(Img, {
  name: 'PreviewProfileSocialIcon',
  shouldForwardProp: (prop) => prop !== 'isDark',
})<{ isDark: boolean }>(({ isDark }) => ({
  position: 'absolute',
  left: '0',
  top: '0',
  width: '100%',
  height: '100%',
  color: isDark ? colors.SystemWhite : colors.SystemBlack,
}));

const PreviewProfileElementMock = styled(Box, {
  name: 'PreviewProfileElementMock',
  shouldForwardProp: (prop) => prop !== 'isDark',
})<{ isDark: boolean }>(({ isDark }) => ({
  width: '100%',
  height: '32px',
  backgroundColor: isDark ? colors.SystemWhite : colors.SystemBlack,
  borderRadius: '28px',
}));
