import { Button as MuiButton, ButtonProps as MuiButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { typography } from '../shared/TypographySharedElements';
import { colors } from '../shared/Colors';
import { FC, forwardRef, ForwardRefExoticComponent, PropsWithChildren, ReactNode, RefAttributes } from 'react';
import LoadingSpinner from '../../Components/LoadingSpinner';
import React from 'react';

export interface ButtonProps extends MuiButtonProps {
  isPrimary?: boolean;
  isLoading?: boolean;
}

export const Button: ForwardRefExoticComponent<
  Omit<ButtonProps & { children?: ReactNode }, 'ref'> & RefAttributes<HTMLButtonElement>
> = forwardRef(function Button(props, ref) {
  return (
    <ButtonElement
      {...props}
      ref={ref}
    >
      {props.children}
      {props.isLoading && <LoadingSpinner />}
    </ButtonElement>
  );
});

export const ButtonElement = styled(MuiButton, {
  name: 'Button',
  shouldForwardProp: (prop) => prop !== 'isPrimary' && prop !== 'isLoading',
})<ButtonProps>(({ disabled, size, isPrimary }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
  padding: size === 'large' ? '12px 24px' : '8px 20px',
  borderRadius: '999px',
  fontSize: size === 'large' ? typography.buttonLarge.fontSize : typography.buttonSmall.fontSize,
  lineHeight: size === 'large' ? typography.buttonLarge.lineHeight : typography.buttonSmall.lineHeight,
  fontWeight: size === 'large' ? typography.buttonLarge.fontWeight : typography.buttonSmall.fontWeight,
  backgroundColor: isPrimary ? (disabled ? colors.SystemGray200 : colors.SystemGray900) : colors.SystemGray100,
  color: isPrimary ? colors.SystemWhite : colors.SystemGray900,
  textTransform: 'none',
  textWrap: 'wrap',
  '&.Mui-disabled': {
    backgroundColor: isPrimary ? colors.SystemGray200 : colors.SystemGray100,
    color: isPrimary ? colors.SystemGray300 : colors.SystemGray400,
  },
  '&:hover': {
    backgroundColor: isPrimary
      ? disabled
        ? colors.SystemGray200
        : colors.SystemGray700
      : disabled
        ? colors.SystemGray100
        : colors.SystemGray200,
  },
  '.MuiButton-endIcon': {
    marginRight: 0,
    marginLeft: 0,
  },
  '.MuiButton-startIcon': {
    marginRight: 0,
    marginLeft: 0,
  },
}));
