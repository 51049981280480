import { Box, Dialog, styled } from '@mui/material';
import React, { Dispatch, FC, SetStateAction, useCallback } from 'react';
import { colors } from '../../ui/shared/Colors';
import { CancelModalDialog } from './CancelModalDialog';

interface CancelModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export const CancelModal: FC<CancelModalProps> = ({ open, setOpen }) => {
  const closeModal = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <CancelModalDialogElement open={open}>
      <CancelModalDialog closeModal={closeModal} />
    </CancelModalDialogElement>
  );
};

const CancelModalDialogElement = styled(Dialog, {
  name: 'ShareHatchLinksDialog',
})(({ theme }) => ({
  '.MuiBackdrop-root': {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    [theme.breakpoints.down('md')]: {
      display: 'block',
      backgroundColor: 'unset',
    },
  },
}));
