import React, { useCallback, useState } from 'react';
import z from 'zod';
import { postRequest, PostRequestParams } from '../../../Redux/API/Request/ApiRequest';
import { zodResolver } from '@hookform/resolvers/zod';
import { TextField } from '../../../ui/inputs/TextField';
import { useForm } from 'react-hook-form';
import { HiddenField } from '../../../ui/inputs/HiddenField';
import { Box, styled, Typography } from '@mui/material';
import LoadingSpinner from '../../../Components/LoadingSpinner';
import { Button } from '../../../ui/buttons/Button';
import { colors } from '../../../ui/shared/Colors';
import { typography } from '../../../ui/shared/TypographySharedElements';
import { HttpError } from '../../../Redux/API/Request/RequestErrors';

interface RSVPFormProps {
  showId: string;
}

export const RSVPForm: React.FC<RSVPFormProps> = ({ showId }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const schema = z
    .object({
      email: z.string({ message: 'The email must present' }).email('The email must be a valid email'),
      name: z.string({ message: 'The name must present' }).min(1, 'The name must present'),
      quantity: z.string(),
      organization: z.string().optional(),
    })
    .superRefine((args, ctx) => {
      if (isNaN(+args.quantity)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Quantity must be a number',
          path: ['quantity'],
        });
      }
    });

  const initialFormState: z.infer<typeof schema> = {
    email: '',
    name: '',
    quantity: '1',
    organization: '',
  };

  const rsvpForm = useForm<any>({
    resolver: zodResolver(schema),
    defaultValues: initialFormState,
    values: initialFormState,
    mode: 'all',
  });

  const submitRsvp = useCallback(
    async (data: z.infer<typeof schema>) => {
      setLoading(true);
      setError('');

      const request: PostRequestParams = {
        endpoint: '/rsvp',
        data: { ...data, showId },
      };

      try {
        await postRequest(request);
        setSuccess(true);
      } catch (error) {
        const message = (error as HttpError)?.response?.message;
        if (message) {
          setError(message);
        } else {
          setError('Something went wrong, please try again later or contact us.');
        }
      } finally {
        setLoading(false);
      }
    },
    [showId],
  );

  const handleQuantityChange = useCallback(
    (isAdded: boolean) => {
      const currentValue = rsvpForm.getValues('quantity');
      if (isAdded) {
        const newValue = Number(currentValue) + 1;
        rsvpForm.setValue('quantity', `${newValue}`);
      } else {
        if (currentValue !== '1') {
          const newValue = Number(currentValue) - 1;
          rsvpForm.setValue('quantity', `${newValue}`);
        }
      }
      rsvpForm.trigger('quantity');
    },
    [rsvpForm.getValues()],
  );

  return (
    <FormStyled onSubmit={rsvpForm.handleSubmit(submitRsvp)}>
      <Box
        display="flex"
        flexDirection="column"
        gap="16px"
        alignItems="center"
        width="50%"
      >
        <Subtitle>This name is going to appear at the venue list.</Subtitle>
        <TextField
          {...rsvpForm.register('name')}
          type="text"
          label="Name"
          fullWidth
          placeholder="Enter Name"
          error={!!rsvpForm.formState.errors?.name?.message}
          helperText={!!rsvpForm.formState.errors?.name?.message && <>{rsvpForm.formState.errors?.name?.message}</>}
        />

        <Subtitle>We'll send your digital tickets to this email.</Subtitle>
        <TextField
          {...rsvpForm.register('email')}
          type="text"
          label="Email"
          placeholder="Provide Email"
          fullWidth
          error={!!rsvpForm.formState.errors?.email?.message}
          helperText={!!rsvpForm.formState.errors?.email?.message && <>{rsvpForm.formState.errors?.email?.message}</>}
        />
        <TextField
          {...rsvpForm.register('organization')}
          type="text"
          label="Organization (optional)"
          placeholder="Provide Organization - optional"
          fullWidth
          error={!!rsvpForm.formState.errors?.organization?.message}
          helperText={
            !!rsvpForm.formState.errors?.organization?.message && (
              <>{rsvpForm.formState.errors?.organization?.message}</>
            )
          }
        />

        <HiddenField
          {...rsvpForm.register('quantity')}
          type="text"
        />
      </Box>

      <Box
        display="flex"
        gap="8px"
        alignItems="center"
      >
        <ButtonWithIcon
          type="button"
          onClick={() => handleQuantityChange(false)}
        >
          <Icon className="material-symbols-outlined text-white">keyboard_arrow_down</Icon>
        </ButtonWithIcon>
        <QuantityNumber>{rsvpForm.getValues('quantity')}</QuantityNumber>

        <ButtonWithIcon
          type="button"
          onClick={() => handleQuantityChange(true)}
        >
          <Icon className="material-symbols-outlined text-white">keyboard_arrow_up</Icon>
        </ButtonWithIcon>
      </Box>

      {!success && (
        <Button
          type="submit"
          isPrimary
          size="large"
          disabled={loading}
          isLoading={loading}
        >
          Submit
        </Button>
      )}
      {success && (
        <Typography>
          {Number(rsvpForm.getValues('quantity')) > 1 ? 'Tickets have' : 'Ticket has'} been sent to your email!
        </Typography>
      )}
      {error.length > 1 && <Typography color="red">{error}</Typography>}
    </FormStyled>
  );
};

const FormStyled = styled('form', {
  name: 'FormStyled ',
})({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  width: '100%',
  padding: '20px',
  alignItems: 'center',
});

const QuantityNumber = styled(Typography, {
  name: 'QuantityNumber',
})({
  color: colors.SystemBlack,
  ...typography.buttonLarge,
});

const Subtitle = styled(Typography, {
  name: 'Subtitle',
})({
  color: colors.SystemGray500,
  ...typography.bodyMediumRegular,
});

const Icon = styled('i', {
  name: 'Icon',
})({
  color: colors.SystemWhite,
  fontSize: '16px',
});

const ButtonWithIcon = styled('button', {
  name: 'ButtonWithIcon',
})({
  display: 'flex',
  width: '24px',
  height: '24px',
  padding: '4px',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '999px',
  backgroundColor: colors.THOrange,
});
