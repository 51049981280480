import React, { useEffect, useState } from 'react';
import { Box, styled, Typography } from '@mui/material';
import { Button } from '../../ui/buttons/Button';
import { Show, Performer } from '../../Helpers/shared/Models/Show';
import ExploreShowCard from './ExploreShowCard';
import { typography } from '../../ui/shared/TypographySharedElements';
import Img from '../../Components/Images/Img';
import { useDispatch } from 'react-redux';
import { openSidebar } from '../../Redux/UI/UISlice';
import { useGetAllArtistsQuery, useGetAllVenuesQuery } from '../../Redux/API/PublicAPI';

export default function ExtraDetails({ show }: { show: Show }) {
  const dispatch = useDispatch();
  const artists = useGetAllArtistsQuery();
  const venues = useGetAllVenuesQuery();
  const venue = venues.data?.[show.venueID];

  // We cant use useMemo because we need to await the avatarPromises
  const [performerAvatars, setPerformerAvatars] = useState([]);
  const [performerIDs, setPerformerIDs] = useState(null);
  const getPerformers = (performers: Performer[]) => {
    const performerIDs = performers?.map((performer) => performer.uid);

    setPerformerIDs(performerIDs);
  };
  const getPerformerAvatars = async (performers: string[] = []) => {
    const avatarPromises =
      performers?.map(async (performer) => {
        const artist = await artists?.data?.[performer];
        return artist?.avatarWebP || artist?.avatar || null;
      }) ?? [];

    const avatars = await Promise.all(avatarPromises);
    setPerformerAvatars(avatars);
  };
  useEffect(() => {
    if (show?.performers.length > 0) {
      getPerformers(show?.performers);
    }
  }, [show?.performers]);
  useEffect(() => {
    if (show?.performers.length > 0) {
      getPerformerAvatars(performerIDs);
    }
  }, [show?.performers, performerIDs]);

  return (
    <div
      className={`flex w-full p-4 flex-col sm:flex-row text-[20px] justify-around align-center text-center sm:text-justify`}
    >
      {/** details */}
      <div className="p-2">
        <div className="tracking-wide font-light">Performing Artists</div>
        <div className="break-after-right">
          <div className="flex flex-row justify-center items-center flex-grow w-full">
            {show.performers.length > 0 ? (
              performerAvatars.map((avatar, i) => {
                const artistData = artists?.data?.[performerIDs[i]];
                const artistName = artistData
                  ? `${artistData.firstname} ${artistData.lastname || artistData.stagename}`
                  : 'Unknown Artist';
                const artistID = artistData && artistData._key;

                return (
                  <div
                    key={i}
                    className="flex items-center flex-col"
                  >
                    <Img
                      src={avatar}
                      className="w-16 h-16 p-2 rounded-full"
                      title={artistName}
                      onClick={(e: React.MouseEvent) => {
                        e.stopPropagation();
                        dispatch(
                          openSidebar({
                            status: true,
                            component: 'ViewProfile',
                            data: { profileID: artistID, type: 'artist' },
                          }),
                        );
                      }}
                    />
                  </div>
                );
              })
            ) : (
              <div className="text-center">TBA</div>
            )}
          </div>
        </div>
      </div>
      <div className="p-2">
        <div className="tracking-wide font-light">Peep Venue</div>
        <div className="flex flex-row justify-center items-center flex-grow w-full">
          <Img
            src={venue?.avatarWebP ?? venue?.avatar}
            alt={show?.name}
            title={venue?.name}
            className="w-16 h-16 p-2 rounded-full"
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation();
              dispatch(
                openSidebar({
                  status: true,
                  component: 'ViewProfile',
                  data: { profileID: venue._key, type: 'venue' },
                }),
              );
            }}
          />
        </div>
      </div>
      {/* <div className="p-2">
        <div className="tracking-wide font-light">Capacity</div>
        <div>{show.capacity}</div>
      </div> */}
    </div>
  );
}
