import { Box, styled } from '@mui/material';
import React, { FC, useState } from 'react';
import { Button } from '../../../ui/buttons/Button';
import YouTubeIcon from '../../../Images/Icons/YouTube.svg';
import { YouTubeSkeleton } from './YouTubeSkeleton';
import { YouTubeEmbed } from './YouTubeEmbed';
import { RightHandSideComponentContainer } from '../Shared/SharedContainers';
import { RightHandSideComponentTitle, RightHandSideHeadingIcon } from '../Shared/SharedElements';

interface YouTubeProps {
  youTubeLink: string;
}

export const YouTube: FC<YouTubeProps> = ({ youTubeLink }) => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <RightHandSideComponentContainer>
      <YouTubeHeadingContainer>
        <YouTubeInnerHeadingContainer>
          <RightHandSideHeadingIcon src={YouTubeIcon} />
          <RightHandSideComponentTitle>YouTube</RightHandSideComponentTitle>
        </YouTubeInnerHeadingContainer>
        <Button
          disabled={false}
          size="small"
          onClick={() => window.open(youTubeLink)}
        >
          Listen on YouTube
        </Button>
      </YouTubeHeadingContainer>
      {isLoading && <YouTubeSkeleton />}
      <YouTubeEmbed
        link={youTubeLink}
        setIsLoading={setIsLoading}
        isLoading={isLoading}
      />
    </RightHandSideComponentContainer>
  );
};

const YouTubeHeadingContainer = styled(Box, {
  name: 'YouTubeHeadingContainer',
})({
  display: 'flex',
  justifyContent: 'space-between',
});

const YouTubeInnerHeadingContainer = styled(Box, {
  name: 'YouTubeInnerHeadingContainer',
})({
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
});
