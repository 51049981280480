import { Box, styled } from '@mui/material';
import { typography } from '../../../ui/shared/TypographySharedElements';
import { colors } from '../../../ui/shared/Colors';

export const SideContainer = styled(Box, {
  name: 'SideContainer',
  shouldForwardProp: (prop) => prop !== 'isLeft',
})<{ isLeft?: boolean }>(({ isLeft, theme }) => ({
  display: isLeft ? 'flex' : 'flex',
  flexDirection: 'column',
  justifyContent: isLeft ? 'center' : 'space-between',
  alignItems: isLeft ? 'center' : 'flex-start',
  width: '50%',
  padding: isLeft ? '0' : '40px',
  backgroundColor: isLeft ? colors.SystemWhite : colors.THOrangeLight,
  [theme.breakpoints.down('md')]: {
    justifyContent: 'center',
    display: isLeft ? 'flex' : 'none',
    width: '100%',
    padding: '20px',
  },
}));

export const FormMainContainer = styled(Box, {
  name: 'FormMainContainer',
})({
  display: 'flex',
  flexGrow: 1,
  gap: '12px',
});

export const FormMainContainerColumn = styled(Box, {
  name: 'FormMainContainerColumn',
})({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
});

export const SideContainerInner = styled(Box, {
  name: 'SideContainerInner',
})({
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
  maxWidth: '440px',
  width: '100%',
});

export const RightSideTextContainer = styled(Box, {
  name: 'RightSideTextContainer',
})({
  display: 'flex',
  gap: '6px',
  alignItems: 'center',
  ...typography.headlineMedium,
});

export const LeftHandSideContainerInner = styled(Box, {
  name: 'LeftHandSideContainerInner',
})({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
});

export const RightHandSideInnerContainer = styled(Box, {
  name: 'RightHandSideInnerContainer',
})({
  display: 'flex',
  flexDirection: 'column',
  gap: '40px',
});

export const FullPageContainer = styled(Box, {
  name: 'FullPageContainer',
})({
  display: 'flex',
  flexGrow: 1,
});

export const FormStepHeadlineContainer = styled(Box, {
  name: 'FormStepHeadlineContainer',
})({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: 'auto',
  backgroundColor: colors.SystemGray100,
  padding: '20px',
});

export const FormTitleWithSubtitleContainer = styled(Box, {
  name: 'FormStepTitleWithSubtitleContainer',
})({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  width: '100%',
  gap: '8px',
});

export const FormStepInnerContainer = styled(Box, {
  name: 'FormStepInnerContainer',
})({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: '520px',
  gap: '40px',
});

export const FormSectionContainer = styled(Box, {
  name: 'FormSectionContainer',
})({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
});

export const FormStepFormContainerOuter = styled(Box, {
  name: 'FormStepFormContainerOuter',
})({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '40px 20px',
});

export const FormInputGroupWrapper = styled(Box, {
  name: 'FormInputGroupWrapper',
})(({ theme }) => ({
  display: 'flex',
  gap: '16px',
  flexGrow: 1,
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

export const FormInputGroupElementWrapper = styled(Box, {
  name: 'FormInputGroupElementWrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: 'calc(50% - 8px)',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

export const InputGroupRowHolder = styled(Box, {
  name: 'InputGroupRowHolder',
})({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
});

export const AutocompleteWithLabelContainer = styled(Box, {
  name: 'AutocompleteWithLabelContainer',
})({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '12px',
});

export const MultipleAutocompleteWithLabelContainer = styled(Box, {
  name: 'MultipleAutocompleteWithLabelContainer',
})({
  display: 'flex',
  gap: '16px',
});

export const FormStyled = styled('form', {
  name: 'FormStyled',
})({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
});
