import React, { FC } from 'react';
import { Box, styled } from '@mui/material';

import { TabBodyText, TabText } from './Shared';

export const PricingText: FC = () => {
  return (
    <StyledBox>
      <TabText>TuneHatch is 100% free for venues, artists, and promoters</TabText>
      <TabBodyText>
        A small service fee is applied to each ticket sold, so the charge never comes out of the venues pocket.
      </TabBodyText>
    </StyledBox>
  );
};

const StyledBox = styled(Box, {
  name: 'StyledBox',
})({
  padding: '20px',
});
