import { Slider as MuiSlider, styled } from '@mui/material';
import { colors } from '../shared/Colors';

export const Slider = styled(MuiSlider, {
  name: 'Slider',
})({
  color: colors.SystemGray500,
  '.MuiSlider-thumb.MuiSlider-thumbSizeMedium.MuiSlider-thumbColorPrimary': {
    backgroundColor: colors.SystemBlack,
    '&:hover': {
      boxShadow: '0px 0px 0px 8px rgba(0, 0, 0, 0.10)',
    },
  },
  '.MuiSlider-track': {
    backgroundColor: 'transparent',
    border: 'none',
  },
  '.MuiSlider-rail': {
    backgroundColor: colors.SystemGray300,
  },
});
