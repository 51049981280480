export enum ViewType {
  User = 'user',
  Artist = 'artist',
  Venue = 'venue',
  SRGroup = 'showrunner',
}

export function isViewType(type: unknown): type is ViewType {
  return Object.values(ViewType).includes(type as unknown as ViewType);
}
