import { Chip, InputLabel, styled, FormControl, Avatar, Autocomplete, TextField } from '@mui/material';
import Lo, { isArray, uniq } from 'lodash';
import React, {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  SyntheticEvent,
  forwardRef,
  useCallback,
  useMemo,
} from 'react';
import { SetURLSearchParams } from 'react-router-dom';
import AvatarImg from '../../Images/Avatar.webp';
import { colors } from '../shared/Colors';
import { typography } from '../shared/TypographySharedElements';

interface MultipleSelectChipProps {
  selectValue: SingleOption[];
  setSelectValue: Dispatch<SetStateAction<SingleOption[]>>;
  setSearch: SetURLSearchParams;
  search: string;
  label: string;
  options: SingleOption[];
  placeholder: string;
  handleChangeOuter: (value: SingleOption | SingleOption[], isDelete?: boolean) => void;
  isLoading: boolean;
  dataCy?: string;
  name: string;
  isSingle?: boolean;
  isOffPlatformPossible?: boolean;
  offPlatformOptionsList?: SingleOption[];
  setOffPlatformOptionsList?: Dispatch<SetStateAction<SingleOption[]>>;
  error: boolean;
  helperText?: ReactNode;
}
export interface SingleOption {
  label: string;
  id: string;
  avatar: string | null;
}

export const MultipleSelectChip: FC<MultipleSelectChipProps> = forwardRef<unknown, MultipleSelectChipProps>(
  (
    {
      selectValue,
      isLoading,
      setSelectValue,
      label,
      options,
      placeholder,
      handleChangeOuter,
      setSearch,
      search,
      dataCy,
      name,
      isSingle,
      isOffPlatformPossible,
      offPlatformOptionsList,
      setOffPlatformOptionsList,
      error,
      helperText,
    },
    ref,
  ) => {
    const handleChange = useCallback(
      (event: SyntheticEvent<Element, Event>, value: SingleOption[]) => {
        event.preventDefault();
        event.stopPropagation();
        if (typeof value[0] !== 'object') {
          return;
        }
        if (!isSingle) {
          setSelectValue(uniq(value));
          handleChangeOuter(uniq(value));
        } else {
          setSelectValue([value[value.length - 1]]);
          handleChangeOuter([value[value.length - 1]]);
        }
      },
      [setSelectValue, handleChangeOuter, isSingle],
    );

    const handleRemove = useCallback(
      (e: React.MouseEvent<HTMLElement, MouseEvent>, value: SingleOption | SingleOption[]) => {
        if (!isSingle) {
          let newValue: SingleOption[] = [];
          if (!isArray(value)) {
            newValue = selectValue.filter((selectedElement) => selectedElement.id !== value.id);
          } else {
            newValue = selectValue.filter((selectedElement) => {
              return value.find((singleValue) => selectedElement.id !== singleValue.id);
            });
          }
          if (isOffPlatformPossible) {
            let filtered: SingleOption[] = [];
            if (!isArray(value)) {
              filtered = [...offPlatformOptionsList].filter((el) => el.id !== value.id);
            } else {
              filtered = [...offPlatformOptionsList].filter((el) => {
                return value.find((singleValue) => singleValue.id !== el.id);
              });
            }
            setOffPlatformOptionsList(uniq(filtered));
          }
          setSelectValue(newValue);
          handleChangeOuter(value, true);
        } else {
          if (isOffPlatformPossible) {
            setOffPlatformOptionsList([]);
          }
          setSelectValue([]);
          handleChangeOuter([]);
        }
      },
      [
        selectValue,
        setSelectValue,
        isSingle,
        offPlatformOptionsList,
        isOffPlatformPossible,
        setOffPlatformOptionsList,
        handleChangeOuter,
      ],
    );

    const findAvatarSrc = useCallback(
      (label: string) => {
        const element = options.find((el) => el.label === label);
        if (element) {
          return element.avatar;
        } else {
          return null;
        }
      },
      [options],
    );

    const handleInputChange = useMemo(
      () =>
        Lo.debounce(
          (event: unknown, value: string) => {
            setSearch({ [name]: value }, { replace: true });
          },
          250,
          { maxWait: 1000 },
        ),
      [setSearch],
    );

    return (
      <FormControlElement>
        <InputLabelElement>{label}</InputLabelElement>
        <Autocomplete
          multiple
          options={options}
          value={selectValue}
          autoComplete
          clearIcon={<></>}
          getOptionKey={(option: SingleOption) => option.id}
          isOptionEqualToValue={(opt, value) => {
            if (value.id === '0') {
              return true;
            } else {
              return opt.id === value.id;
            }
          }}
          noOptionsText={
            isOffPlatformPossible ? (
              <ChipElement
                onMouseDown={(e) => {
                  e.stopPropagation();
                  if (!isSingle) {
                    setOffPlatformOptionsList((offPlatformOptionsList) => {
                      const newOffPlatList = [...offPlatformOptionsList];
                      newOffPlatList.push({ id: '0', avatar: null, label: search });
                      return newOffPlatList;
                    });
                    setSelectValue((selectValue) => [...selectValue, { id: '0', avatar: null, label: search }]);
                    handleChangeOuter({ id: '0', label: search, avatar: null });
                  } else {
                    setOffPlatformOptionsList([{ id: '0', avatar: null, label: search }]);
                    setSelectValue([{ id: '0', avatar: null, label: search }]);
                    handleChangeOuter([{ id: '0', label: search, avatar: null }]);
                  }
                }}
                key={`${offPlatformOptionsList.length - 1}`}
                avatar={<Avatar src={AvatarImg} />}
                label={`Off platform: ${search}`}
                clickable
              />
            ) : (
              'No options for your current search'
            )
          }
          renderTags={(value: SingleOption[], getTagProps) => {
            if (!isSingle) {
              const valueArr = value as SingleOption[];
              return valueArr.map((option: SingleOption, index: number) => {
                const { key, ...tagProps } = getTagProps({ index });
                return (
                  <ChipElement
                    onMouseDown={(e) => {
                      e.stopPropagation();
                    }}
                    key={option.id}
                    avatar={<Avatar src={findAvatarSrc(option.label) ?? AvatarImg} />}
                    label={option.label}
                    clickable
                    onClick={(e) => handleRemove(e, option)}
                    deleteIcon={<i className="material-symbols-outlined">close</i>}
                    onDelete={(evt) => handleRemove(evt, option)}
                  />
                );
              });
            } else {
              return (
                <ChipElement
                  onMouseDown={(e) => {
                    e.stopPropagation();
                  }}
                  key={value[0].id}
                  avatar={<Avatar src={findAvatarSrc(value[0].label) ?? AvatarImg} />}
                  label={value[0].label}
                  clickable
                  onClick={(e) => handleRemove(e, value[0])}
                  deleteIcon={<i className="material-symbols-outlined">close</i>}
                  onDelete={(evt) => handleRemove(evt, value[0])}
                />
              );
            }
          }}
          onChange={handleChange}
          loading={isLoading}
          onInputChange={handleInputChange}
          renderInput={(params) => (
            <TextField
              {...params}
              value={search}
              placeholder={placeholder}
              data-cy={dataCy}
              error={error}
              helperText={helperText}
            />
          )}
        />
      </FormControlElement>
    );
  },
);

const FormControlElement = styled(FormControl, {
  name: 'FormControlElement',
})({
  width: '100%',
  maxWidth: '100%',
  paddingTop: '23px',
});

const ChipElement = styled(Chip, {
  name: 'ChipElement',
})({
  ...typography.bodyMediumBold,
  paddingRight: '28px',
  zIndex: 20,
  '.MuiSvgIcon-root': {
    width: '20px',
    height: '20px',
    pointerEvents: 'none',
  },
  '.MuiChip-deleteIcon': {
    fontSize: '20px',
    color: colors.SystemBlack,
    position: 'absolute',
    right: '12px',
    top: '50%',
    transform: 'translate(0, -50%)',
    zIndex: 5,
    marginRight: 0,
  },
});

const InputLabelElement = styled(InputLabel, {
  name: 'InputLabelElement',
})<{ error?: boolean }>(({ error }) => ({
  top: '0px',
  left: '4px',
  transform: 'none',
  color: error ? colors.AlertRed : colors.SystemGray900,
  ...typography.bodyMediumRegular,
  '&.Mui-focused': {
    color: colors.SystemGray900,
  },
}));
