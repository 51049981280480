import React, { FC, useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingPage } from '../Register/Loading';
import { Box, Typography, styled } from '@mui/material';
import { getCookie } from '../../Helpers/HelperFunctions';
import { typography } from '../../ui/shared/TypographySharedElements';
import { colors } from '../../ui/shared/Colors';

export const AccountActivation: FC = () => {
  const token = getCookie('SECRET_UID');
  const { activationToken } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  const handleActivateAccount = useCallback(async () => {
    try {
      const result = await axios.put(
        `${process.env.REACT_APP_PUBLIC_URL}:${process.env.REACT_APP_SERVER_PORT}/api/v2/me/activation`,
        { activationToken: activationToken },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json',
            Accept: '*/*',
            'Accept-Encoding': 'gzip,deflate,br',
            Connection: 'keep-alive',
          },
        },
      );
      if (result.status === 204) {
        setIsLoading(false);
        navigate('/');
      } else {
        setError(result.data?.message);
        setIsLoading(false);
      }
    } catch {
      setError('Unknown server error, please reload the page');
      setIsLoading(false);
    }
  }, [setError, setIsLoading, navigate, token, activationToken]);

  useEffect(() => {
    handleActivateAccount();
  }, [handleActivateAccount]);

  if (isLoading) {
    return <LoadingPage loadingText="Your account is being activated" />;
  }
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="100%"
      minHeight="100vh"
    >
      <ErrorMessagePrompt>{error}</ErrorMessagePrompt>
    </Box>
  );
};

const ErrorMessagePrompt = styled(Typography, {
  name: 'ErrorMessagePrompt',
})({
  color: colors.AlertRed,
  ...typography.bodyLargeRegular,
});
