import { Box, styled, Typography } from '@mui/material';
import React, { FC } from 'react';
import { colors } from '../../../ui/shared/Colors';
import { typography } from '../../../ui/shared/TypographySharedElements';
import {
  borderBottomLeftRadius,
  borderBottomRightRadius,
} from 'html2canvas/dist/types/css/property-descriptors/border-radius';

interface TabLabelProps {
  icon: string;
  labelText: string;
  selected: boolean;
}

export const TabLabel: FC<TabLabelProps> = ({ icon, labelText, selected }) => {
  return (
    <TabLabelContainer selected={selected}>
      <TabIconCont>
        <TabIcon
          src={icon}
          alt="icon"
        />
      </TabIconCont>
      <LabelText>{labelText}</LabelText>
    </TabLabelContainer>
  );
};

const TabLabelContainer = styled(Box, {
  name: 'TabLabelContainer',
})<{ selected: boolean }>(({ selected, theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  padding: '40px',
  alignItems: 'center',
  borderTopRightRadius: '24px',
  borderTopLeftRadius: '24px',
  borderTop: `2px solid ${selected ? colors.SystemGray500 : colors.SystemGray700}`,
  borderRight: `2px solid ${selected ? colors.SystemGray500 : colors.SystemGray700}`,
  borderLeft: `2px solid ${selected ? colors.SystemGray500 : colors.SystemGray700}`,
  backgroundColor: selected ? colors.SystemGray900 : 'transparent',
  height: '100%',
  [theme.breakpoints.down('lg')]: {
    padding: '28px',
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'row',
    borderRadius: '16px',
    width: '80%',
    borderBottom: `2px solid ${selected ? colors.SystemGray500 : colors.SystemGray700}`,
    padding: '20px',
  },
}));

const TabIconCont = styled(Box, {
  name: 'TabIconCont',
  shouldForwardProp: (prop) => prop !== 'selected',
})(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  width: '60px',
  height: '60px',
  [theme.breakpoints.down('lg')]: {
    width: '50px',
    height: '50px',
  },
  [theme.breakpoints.down('md')]: {
    width: '30px',
    height: '30px',
  },
}));

const TabIcon = styled('img', {
  name: 'TabIcon',
})({
  position: 'absolute',
  left: 0,
  top: 0,
  wdith: '100%',
  height: '100%',
});

const LabelText = styled(Typography, {
  name: 'LabelText',
})(({ theme }) => ({
  color: colors.SystemWhite,
  ...typography.headlineSmall,
  textTransform: 'none',
  [theme.breakpoints.down('lg')]: {
    ...typography.bodyLargeBold,
  },
  [theme.breakpoints.down('md')]: {
    ...typography.bodySmallBold,
  },
}));
