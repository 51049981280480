import { Box, styled, Tooltip, Typography, useMediaQuery } from '@mui/material';
import React, { FC, Ref, SyntheticEvent, useCallback, useState } from 'react';
import step0Icon from '../../Images/GuidedTours/Step0.svg';
import step1Icon from '../../Images/GuidedTours/Step1.svg';
import step2Icon from '../../Images/GuidedTours/Step2.svg';
import step3Icon from '../../Images/GuidedTours/Step3.svg';
import step4Icon from '../../Images/GuidedTours/Step4.svg';

import { Button } from '../../ui/buttons/Button';
import { colors } from '../../ui/shared/Colors';
import { typography } from '../../ui/shared/TypographySharedElements';
import { ViewType } from '../../Helpers/shared/Models/ViewType';
import { GuidedToursModal, GuidedToursModalVersion, OpenGuidedTourModalProps } from './GuidedToursModal';
import { useNavigate, useParams } from 'react-router-dom';
import { useUpdateGuidedTours } from './useUpdateGuidedTours';

interface GuidedToursProps {
  viewType: ViewType;
  buttonRefs: {
    show: HTMLButtonElement;
    profile: HTMLButtonElement;
    hatchLinks: HTMLButtonElement;
  };
  isMobile: boolean;
  profileID: string;
  actualStep?: number;
}

export const GuidedTours: FC<GuidedToursProps> = ({ viewType, buttonRefs, isMobile, profileID, actualStep }) => {
  const [step, setStep] = useState(actualStep ? actualStep : 0);
  const navigate = useNavigate();
  const [modalData, setModalData] = useState<
    Omit<OpenGuidedTourModalProps, 'buttonRefs' | 'pos' | 'isMobile' | 'handleClick' | 'closeModal' | 'viewType'>
  >({
    visible: false,
    version: 'create_show',
  });
  const MAX_STEP = 5;
  const [selectedBtnPos, setSelectedBtnPos] = useState({ x: 0, y: 0 });

  const [updateGuidedTours] = useUpdateGuidedTours(viewType);

  const handleChangeStep = useCallback(
    (isBack?: boolean) => {
      if (step < MAX_STEP) {
        const payload =
          viewType === ViewType.Artist
            ? {
                tourStatus: {
                  profileTour: {
                    step: isBack ? step - 1 : step + 1,
                    completed: false,
                  },
                },
              }
            : {
                tourStatus: {
                  profileTour: {
                    step: isBack ? step - 1 : step + 1,
                    completed: false,
                  },
                },
                profileID: profileID,
              };
        updateGuidedTours(payload);
        setStep((step) => (isBack ? step - 1 : step + 1));
      }
    },
    [updateGuidedTours, step, setStep],
  );

  const handleFinishGuidedTour = useCallback(() => {
    const payload =
      viewType === ViewType.Artist
        ? {
            tourStatus: {
              profileTour: {
                step: step + 1,
                completed: true,
              },
            },
          }
        : {
            tourStatus: {
              profileTour: {
                step: step + 1,
                completed: true,
              },
            },
            profileID: profileID,
          };
    updateGuidedTours(payload);
  }, [updateGuidedTours, step, setStep]);

  const handleClickActionButton = useCallback(
    (version: GuidedToursModalVersion) => {
      if (isMobile) {
        if (version === 'create_show') {
          navigate(`/create-show/${viewType}/${profileID}`);
        } else if (version === 'edit_profile') {
          navigate(`/profile/edit/${viewType}/${profileID}`);
        } else {
          navigate(`/profile/edit/${viewType}/${profileID}/hatchlinks`);
        }
      } else {
        if (version === 'create_show' && buttonRefs.show) {
          const pos = buttonRefs.show.getBoundingClientRect();
          setSelectedBtnPos({ x: pos.x, y: window.innerHeight / 2 });
          buttonRefs.show.scrollIntoView({ behavior: 'smooth', block: 'center' });
        } else if (version === 'edit_profile' && buttonRefs.profile) {
          const pos = buttonRefs.profile.getBoundingClientRect();
          setSelectedBtnPos({ x: pos.x, y: window.innerHeight / 2 });
          buttonRefs.profile.scrollIntoView({ behavior: 'smooth', block: 'center' });
        } else if (version === 'hatch_link' && buttonRefs.hatchLinks) {
          const pos = buttonRefs.hatchLinks.getBoundingClientRect();
          setSelectedBtnPos({ x: pos.x, y: window.innerHeight / 2 });
          buttonRefs.hatchLinks.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
      setModalData({ visible: true, version: version });
    },
    [buttonRefs.profile, buttonRefs.show, buttonRefs.hatchLinks],
  );

  const handleClick = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault();
      e.stopPropagation();
      if (modalData.version === 'create_show') {
        buttonRefs.show.click();
      } else if (modalData.version === 'edit_profile') {
        buttonRefs.profile.click();
      } else {
        buttonRefs.hatchLinks.click();
      }
    },
    [modalData],
  );

  const closeModal = useCallback((e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setModalData({ visible: false, version: 'create_show' });
  }, []);

  return (
    <>
      <GuidedToursMainCont viewType={viewType}>
        {step === 0 && (
          <GuidedToursStepCont>
            <Box></Box>
            <GuidedToursTextWithIconCont>
              <GuidedToursIcon src={step0Icon} />
              <GuidedToursTextCont>
                <GuidedToursTitle>Welcome to TuneHatch!</GuidedToursTitle>
                <GuidedToursText>The first steps are behind you, now let’s explore the product!</GuidedToursText>
              </GuidedToursTextCont>
            </GuidedToursTextWithIconCont>
            <GuidedToursButtonCont isSingle={isMobile}>
              <Button
                isPrimary
                size="large"
                disabled={false}
                onClick={() => handleChangeStep(false)}
                fullWidth
                sx={{ maxWidth: '50%' }}
              >
                Next
              </Button>
            </GuidedToursButtonCont>
          </GuidedToursStepCont>
        )}
        {step === 1 && (
          <GuidedToursStepCont>
            {!isMobile && (
              <GuidedToursButtonCont>
                <Button
                  size="large"
                  disabled={false}
                  onClick={() => handleChangeStep(true)}
                >
                  Back
                </Button>
              </GuidedToursButtonCont>
            )}
            <GuidedToursTextWithIconCont>
              <GuidedToursIcon src={step1Icon} />
              <GuidedToursTextCont>
                <GuidedToursTitle>1 of 3 • Create your first show</GuidedToursTitle>
                <GuidedToursText>
                  Let’s get you started by creating your first show! It’s quick and easy, and we’ll guide you through
                  every step.{' '}
                </GuidedToursText>
                <GuidedToursActionRow>
                  <Button
                    size="small"
                    disabled={false}
                    onClick={() => handleClickActionButton('create_show')}
                  >
                    Create show
                  </Button>
                  <Tooltip
                    title="If a venue has already added you to a show on TuneHatch, you're all set! You can manage your show from the dashboard. If you haven't yet been added to a TuneHatch show, reach out to the venue and ask them to add you."
                    enterTouchDelay={25}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      gap="4px"
                    >
                      <GuidedToursTooltipIcon className="material-symbols-outlined">help</GuidedToursTooltipIcon>
                      <GuidedToursTooltipText>Hey, I’ve already been added to a show?!</GuidedToursTooltipText>
                    </Box>
                  </Tooltip>
                </GuidedToursActionRow>
              </GuidedToursTextCont>
            </GuidedToursTextWithIconCont>
            <GuidedToursButtonCont>
              {isMobile && (
                <Button
                  size="large"
                  disabled={false}
                  onClick={() => handleChangeStep(true)}
                  fullWidth
                >
                  Back
                </Button>
              )}
              <Button
                isPrimary
                size="large"
                disabled={false}
                onClick={() => handleChangeStep(false)}
                fullWidth={isMobile ? true : false}
              >
                Next
              </Button>
            </GuidedToursButtonCont>
          </GuidedToursStepCont>
        )}
        {step === 2 && (
          <GuidedToursStepCont>
            {!isMobile && (
              <GuidedToursButtonCont>
                <Button
                  size="large"
                  disabled={false}
                  onClick={() => handleChangeStep(true)}
                >
                  Back
                </Button>
              </GuidedToursButtonCont>
            )}
            <GuidedToursTextWithIconCont>
              <GuidedToursIcon src={step2Icon} />
              <GuidedToursTextCont>
                <GuidedToursTitle>2 of 3 • Complete your profile</GuidedToursTitle>
                <GuidedToursText>
                  Complete your profile to stand out! A full profile boosts bookings, builds credibility, and shows what
                  makes you unique — whether you're an artist, venue, or promoter. Shine and get noticed!
                </GuidedToursText>
                <GuidedToursActionRow>
                  <Button
                    size="small"
                    disabled={false}
                    onClick={() => handleClickActionButton('edit_profile')}
                  >
                    Edit profile
                  </Button>
                </GuidedToursActionRow>
              </GuidedToursTextCont>
            </GuidedToursTextWithIconCont>
            <GuidedToursButtonCont>
              {isMobile && (
                <Button
                  size="large"
                  disabled={false}
                  onClick={() => handleChangeStep(true)}
                  fullWidth
                >
                  Back
                </Button>
              )}
              <Button
                isPrimary
                size="large"
                disabled={false}
                onClick={() => handleChangeStep(false)}
                fullWidth={isMobile ? true : false}
              >
                Next
              </Button>
            </GuidedToursButtonCont>
          </GuidedToursStepCont>
        )}
        {step === 3 && (
          <GuidedToursStepCont>
            {!isMobile && (
              <GuidedToursButtonCont>
                <Button
                  size="large"
                  disabled={false}
                  onClick={() => handleChangeStep(true)}
                >
                  Back
                </Button>
              </GuidedToursButtonCont>
            )}
            <GuidedToursTextWithIconCont>
              <GuidedToursIcon src={step3Icon} />
              <GuidedToursTextCont>
                <GuidedToursTitle>3 of 3 • Share your HatchLink</GuidedToursTitle>
                <GuidedToursText>
                  HatchLink is your hub for social media, upcoming shows, and custom links. It’s auto-created from your
                  profile, so it's ready for your followers to access everything!
                </GuidedToursText>
                <GuidedToursActionRow>
                  <Button
                    size="small"
                    disabled={false}
                    onClick={() => handleClickActionButton('hatch_link')}
                  >
                    Go to your HatchLink
                  </Button>
                </GuidedToursActionRow>
              </GuidedToursTextCont>
            </GuidedToursTextWithIconCont>
            <GuidedToursButtonCont>
              {isMobile && (
                <Button
                  size="large"
                  disabled={false}
                  onClick={() => handleChangeStep(true)}
                  fullWidth
                >
                  Back
                </Button>
              )}
              <Button
                isPrimary
                size="large"
                disabled={false}
                onClick={() => handleChangeStep(false)}
                fullWidth={isMobile ? true : false}
              >
                Next
              </Button>
            </GuidedToursButtonCont>
          </GuidedToursStepCont>
        )}
        {step === 4 && (
          <GuidedToursStepCont>
            {!isMobile && (
              <GuidedToursButtonCont>
                <Button
                  size="large"
                  disabled={false}
                  onClick={() => handleChangeStep(true)}
                >
                  Back
                </Button>
              </GuidedToursButtonCont>
            )}
            <GuidedToursTextWithIconCont>
              <GuidedToursIcon src={step4Icon} />
              <GuidedToursTextCont>
                <GuidedToursTitle>Onboarding complete!</GuidedToursTitle>
                <GuidedToursText>
                  Now that you’re all set, you’re ready to dive in and explore TuneHatch.
                </GuidedToursText>
              </GuidedToursTextCont>
            </GuidedToursTextWithIconCont>
            <GuidedToursButtonCont>
              {isMobile && (
                <Button
                  size="large"
                  disabled={false}
                  onClick={() => handleChangeStep(true)}
                  fullWidth
                >
                  Back
                </Button>
              )}
              <Button
                isPrimary
                size="large"
                disabled={false}
                onClick={() => handleFinishGuidedTour()}
                fullWidth={isMobile ? true : false}
              >
                Close
              </Button>
            </GuidedToursButtonCont>
          </GuidedToursStepCont>
        )}
      </GuidedToursMainCont>
      <GuidedToursModal
        {...modalData}
        pos={{ ...selectedBtnPos }}
        isMobile={isMobile}
        handleClick={handleClick}
        closeModal={closeModal}
        viewType={viewType}
      />
    </>
  );
};

const GuidedToursMainCont = styled(Box, {
  name: 'GuidedToursMainCont',
  shouldForwardProp: (prop) => prop !== 'viewType',
})<{ viewType: ViewType }>(({ viewType, theme }) => ({
  width: '100%',
  background: colors.SystemWhite,
  borderBottom:
    viewType === ViewType.SRGroup || viewType === ViewType.Venue ? `2px solid ${colors.SystemGray100}` : '0',
  marginBottom: viewType === ViewType.SRGroup || viewType === ViewType.Venue ? '20px' : '0',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

const GuidedToursStepCont = styled(Box, {
  name: 'GuidedToursStepCont',
})(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '60px 120px',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    padding: '32px 20px',
  },
}));

const GuidedToursTextWithIconCont = styled(Box, {
  name: 'GuidedToursTextWithIconCont',
})(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '32px',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

const GuidedToursIcon = styled('img', {
  name: 'GuidedToursIcon',
})({
  display: 'block',
  width: '120px',
  height: '120px',
});

const GuidedToursTextCont = styled(Box, {
  name: 'GuidedToursTextCont',
})({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  maxWidth: '508px',
  width: '100%',
});

const GuidedToursTitle = styled(Typography, {
  name: 'GuidedToursTitle',
})(({ theme }) => ({
  color: colors.SystemGray900,
  ...typography.headlineMedium,
  [theme.breakpoints.down('md')]: {
    ...typography.headlineSmall,
  },
}));

const GuidedToursText = styled(Typography, {
  name: 'GuidedToursTitle',
})({
  color: colors.SystemGray900,
  ...typography.bodyMediumRegular,
});

const GuidedToursActionRow = styled(Box, {
  name: 'GuidedToursActionRow',
})(({ theme }) => ({
  display: 'flex',
  gap: '24px',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    gap: '32px',
    marginTop: '20px',
  },
}));

const GuidedToursTooltipIcon = styled('i', {
  name: 'GuidedToursTooltipIcon',
})({
  fontSize: '24px',
  cursor: 'pointer',
});

const GuidedToursTooltipText = styled(Typography, {
  name: 'GuidedToursTooltipText',
})({
  fontFamily: 'Satoshi-Variable',
  color: colors.SystemGray900,
  ...typography.buttonLarge,
  cursor: 'pointer',
});

const GuidedToursButtonCont = styled(Box, {
  name: 'GuidedToursButtonCont',
  shouldForwardProp: (prop) => prop !== 'isSingle',
})<{ isSingle?: boolean }>(({ isSingle, theme }) => ({
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    justifyContent: isSingle ? 'center' : 'unset',
    gap: '16px',
    marginTop: '20px',
  },
}));
