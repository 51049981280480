import React, { useEffect, useState } from 'react';
import { styled, Box, Typography } from '@mui/material';
import Img from '../../Components/Images/Img';
import { Button } from '../../ui/buttons/Button';
import { openSidebar } from '../../Redux/UI/UISlice';
import PerformanceHistory from './PerformanceHistory';
import { useAppDispatch } from '../../hooks';
import { typography } from '../../ui/shared/TypographySharedElements';
import { colors } from '../../ui/shared/Colors';

/**
 * @param artist the artist to be displayed
 * @param hideIfHatchy
 */

export default function ArtistCard(props: { artist: any; hideIfHatchy?: boolean }) {
  const dispatch = useAppDispatch();
  if (!props.artist.stageName && (!props.artist.firstname || !props.artist.lastname)) return null;

  return (
    <Card>
      <Avatar src={props.artist?.avatarWebP || props.artist?.avatar || ''} />
      <Details>
        <NameContainer>
          <Name>{props.artist.stageName || props.artist.firstname + ' ' + props.artist.lastname}</Name>
        </NameContainer>
        <PerformanceHistory performances={props.artist?.performances} />
        <Button
          size="small"
          onClick={() =>
            dispatch(
              openSidebar({
                status: true,
                component: 'ViewProfile',
                data: { profileID: props.artist.id, type: 'user' },
              }),
            )
          }
        >
          View Profile
        </Button>
      </Details>
    </Card>
  );
}

const Card = styled(Box, {
  name: 'Card',
})(({ theme }) => ({
  width: '240px',
  display: 'flex',
  flexShrink: 0,
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: colors.SystemWhite,
  borderRadius: '24px',
  padding: '20px',
  gap: '20px',
  [theme.breakpoints.down('lg')]: {
    width: '180px',
    borderRadius: '16px',
    gap: '16px',
  },
}));

const Avatar = styled(Img, {
  name: 'Avatar',
})(({ theme }) => ({
  width: '120px',
  height: '120px',
  borderRadius: '50%',
  [theme.breakpoints.down('lg')]: {
    width: '80px',
    height: '80px',
  },
}));

const Details = styled(Box, {
  name: 'Details',
})(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '24px',
  [theme.breakpoints.down('lg')]: {
    gap: '16px',
  },
}));

//to vertically center the name
const NameContainer = styled(Box, {
  name: 'NameContainer',
})(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '64px',
  [theme.breakpoints.down('lg')]: {
    height: '48px',
  },
}));

const Name = styled(Typography, {
  name: 'Name',
})(({ theme }) => ({
  ...typography.headlineSmall,
  fontFamily: 'Satoshi-Variable',
  textAlign: 'center',
  [theme.breakpoints.down('lg')]: {
    ...typography.buttonLarge,
  },
}));
