import { Box, InputAdornment, styled } from '@mui/material';
import React, { FC, useCallback, ChangeEvent, Dispatch, SetStateAction, useMemo } from 'react';
import { DataCy } from '../../../Helpers/DataCy';
import {
  FormStepHeadlineContainer,
  FormStepInnerContainer,
  FormTitleWithSubtitleContainer,
  FormStepFormContainerOuter,
  FormMainContainer,
  FormMainContainerColumn,
} from '../Shared/Containers';
import { FormStepHeadlineStepCounter, FormStepHeadlineTitle } from '../Shared/RegistrationTypographies';
import { FormStepProps } from '../Shared/Types';
import { Button } from '../../../ui/buttons/Button';
import { useFormContext } from 'react-hook-form';
import { VenueFormData } from '../../../Forms/AccountInfoForms/Venue/VenueFormData';
import { VenuePublicProfileForm } from '../../../Forms/AccountInfoForms/Venue/VenuePublicProfileForm';

interface VenueStep3Props extends FormStepProps {
  setAvatar: Dispatch<SetStateAction<File | null>>;
  selectedAvatar: File | null;
}

export const VenueFormStep3: FC<VenueStep3Props> = ({ step, setStep, setAvatar, selectedAvatar }) => {
  const venueForm = useFormContext();

  const handleBack = useCallback(() => {
    setStep((step) => step - 2);
  }, [setStep]);

  const handleNextStep = useCallback(async () => {
    const isValid = await venueForm.trigger(VenueFormData.step3Fields);
    if (isValid === true) {
      setStep((step) => step + 1);
    }
  }, [venueForm, setStep]);

  return (
    <FormMainContainerColumn>
      <FormStepHeadlineContainer>
        <FormStepInnerContainer>
          <FormTitleWithSubtitleContainer>
            <FormStepHeadlineStepCounter>Step 3 of 4</FormStepHeadlineStepCounter>
            <FormStepHeadlineTitle>Set up your public profile</FormStepHeadlineTitle>
          </FormTitleWithSubtitleContainer>
        </FormStepInnerContainer>
      </FormStepHeadlineContainer>
      <FormStepFormContainerOuter>
        <FormStepInnerContainer>
          <VenuePublicProfileForm
            setAvatar={setAvatar}
            selectedAvatar={selectedAvatar}
          />
          <FormMainContainer>
            <Button
              size="large"
              onClick={handleBack}
              data-cy={DataCy.button('prevStep')}
            >
              Back
            </Button>
            <FormMainContainerColumn>
              <Button
                isPrimary
                size="large"
                onClick={handleNextStep}
                data-cy={DataCy.button('nextStep')}
              >
                Next
              </Button>
            </FormMainContainerColumn>
          </FormMainContainer>
        </FormStepInnerContainer>
      </FormStepFormContainerOuter>
    </FormMainContainerColumn>
  );
};
