import { zodResolver } from '@hookform/resolvers/zod';
import axios, { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { getClaimData } from '../Shared/ClaimDataHelpers';
import { ArtistFormStep1 } from './ArtistForm1';
import { ArtistFormStep2 } from './ArtistForm2';
import { ArtistFormData } from '../../../Forms/AccountInfoForms/Artist/ArtistFormData';
import { useAppDispatch } from '../../../hooks';
import { setNavbarLogoOnly } from '../../../Redux/UI/UISlice';
import { useCreateOrEditArtistProfileMutation } from '../../../Redux/API/UserAPI';

export const ArtistForm = () => {
  const parsedInitialData = getClaimData();
  const artistInitialData = parsedInitialData?.artist;
  const [step, setStep] = useState(1);
  const [avatar, setAvatar] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);
  const [createOrEditArtistProfile, createOrEditArtistProfileStatus] = useCreateOrEditArtistProfileMutation();
  const navigate = useNavigate();

  const initialArtistFormValues: ArtistFormData.Values = {
    firstName: parsedInitialData?.firstName ?? '',
    lastName: parsedInitialData?.lastName ?? '',
    stageName: artistInitialData?.stagename ?? null,
    genre: 'none',
    subGenre: null,
    recordLabel: {
      signed: false,
      label: null,
    },
    primaryCity: {},
    secondaryCity: null,
    instagram: null,
    tiktok: null,
    spotify: null,
    youtube: null,
    livePerformance: null,
    about: '',
  };

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setNavbarLogoOnly({ status: true }));
    return () => {
      dispatch(setNavbarLogoOnly({ status: false }));
    };
  }, []);

  const artistForm = useForm({
    resolver: zodResolver(ArtistFormData.formSchema),
    defaultValues: initialArtistFormValues,
    values: initialArtistFormValues,
    mode: 'all',
  });

  const submitArtist = async (data: ArtistFormData.Values) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append(
        'body',
        JSON.stringify({
          firstName: data.firstName,
          lastName: data.lastName,
          stageName: data.stageName,
          genre: data.genre,
          subgenre: data.subGenre,
          recordLabelName: data.recordLabel.signed === true ? data.recordLabel.label : null,
          primaryCity: {
            name: data.primaryCity.city,
            meta: data.primaryCity,
          },
          secondaryCity: data.secondaryCity?.displayAddress
            ? {
                name: data.secondaryCity.city,
                meta: data.secondaryCity,
              }
            : null,
          socials: {
            instagramLink: data.instagram ?? null,
            youtubeLink: data.youtube ?? null,
            spotifyLink: data.spotify ?? null,
            tikTokLink: data.tiktok ?? null,
          },
          livePerformance: data.livePerformance ?? null,
          about: data.about,
        }),
      );
      formData.append('avatar', avatar);
      const response = await createOrEditArtistProfile(formData);
      setLoading(false);
      if ('error' in response) {
        window.alert('please fix any errors before submitting.');
        setStep(1);
      } else {
        navigate('/register/success/artist');
      }
    } catch (error: unknown) {
      setLoading(false);
      if (error instanceof AxiosError && error.response.status === 413) {
        window.alert('Uploaded images are too large');
        setStep(2);
      } else {
        setStep(1);
        window.alert('Unknown server error');
        console.log(error);
      }
    }
  };

  return (
    <FormProvider {...artistForm}>
      <form onSubmit={artistForm.handleSubmit(submitArtist)}>
        {step === 1 && (
          <ArtistFormStep1
            step={step}
            setStep={setStep}
          />
        )}
        {step === 2 && (
          <ArtistFormStep2
            step={step}
            setStep={setStep}
            setAvatar={setAvatar}
            submitArtist={submitArtist}
            selectedAvatar={avatar}
            isLoading={loading}
          />
        )}
      </form>
    </FormProvider>
  );
};
