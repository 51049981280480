import { Box, Typography, styled } from '@mui/material';
import React, { FC } from 'react';
import { colors } from '../../../ui/shared/Colors';
import { typography } from '../../../ui/shared/TypographySharedElements';
import { SingleVenueReview, SingleVenueReviewProps } from './SingleVenueReview';

import Carousel from 'react-multi-carousel';
import { VenueReviewsButtonBox } from './VenueReviewsButtonBox';

interface VenueReviewsProps {
  testimonials: SingleVenueReviewProps[];
}

export const VenueReviews: FC<VenueReviewsProps> = ({ testimonials }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 9999, min: 1441 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1440, min: 641 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 640, min: 0 },
      items: 1,
    },
  };
  return (
    <VenueReviewsMainContainer>
      <VenueReviewsTitleRow>
        <VenueReviewsTitle>Testimonials</VenueReviewsTitle>
      </VenueReviewsTitleRow>
      <VenueReviewsContainer>
        <Carousel
          responsive={responsive}
          arrows={false}
          swipeable={true}
          showDots={false}
          infinite={false}
          customTransition="all .5"
          transitionDuration={500}
          renderButtonGroupOutside={true}
          customButtonGroup={<VenueReviewsButtonBox />}
        >
          {testimonials.map((testimonial, index) => (
            <a href={`https://tunehatch.com/profile/venue/${testimonial.venue?.id}`}>
              <SingleVenueReview
                key={`${testimonial.person.firstName} ${testimonial.person.lastName}-${index}`}
                venue={
                  testimonial.venue
                    ? {
                        name: testimonial?.venue?.name,
                        avatarWebp: testimonial.venue.avatarWebp,
                        id: testimonial.venue.id,
                      }
                    : null
                }
                reviewText={testimonial.reviewText}
                person={{
                  firstName: testimonial.person.firstName,
                  lastName: testimonial.person.lastName,
                  title: testimonial.person.title,
                  avatarWebp: testimonial.person.avatarWebp,
                }}
              />
            </a>
          ))}
        </Carousel>
      </VenueReviewsContainer>
    </VenueReviewsMainContainer>
  );
};
const VenueReviewsMainContainer = styled(Box, {
  name: 'VenueReviewsMainContainer',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '40px',
  padding: '120px 80px',
  [theme.breakpoints.down('md')]: {
    padding: '40px 20px',
  },
}));

const VenueReviewsTitle = styled(Typography, {
  name: 'VenueReviewsTitle',
})(({ theme }) => ({
  color: colors.SystemWhite,
  ...typography.displaySmall,
  [theme.breakpoints.down('lg')]: {
    ...typography.headlineMedium,
  },
}));

const VenueReviewsContainer = styled(Box, {
  name: 'VenueReviewsContainer',
})({
  width: '100%',
  minHeight: '365px',
  position: 'relative',
});

const VenueReviewsTitleRow = styled(Box, {
  name: 'VenueReviewsTitleRow',
})(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  maxWidth: '1280px',
  width: '100%',
  margin: '0 auto',
  paddingRight: '25%',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    gap: '24px',
    padding: 0,
    paddingBottom: '50px',
    justifyContent: 'center',
  },
}));
