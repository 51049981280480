import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from '../../hooks';
import { setFullscreen } from '../../Redux/UI/UISlice';
import { LoadingPage } from '../Register/Loading';
import { LoginForm } from './LogInForm';
import { Box } from '@mui/material';
import { UserTypeEnum } from '../Register/UserTypeEnum';
import { FullPageContainer, SideContainer, SideContainerInner } from '../Register/Shared/Containers';
import {
  UserSelectionHeadline,
  SubRegistrationText,
  SubRegistrationLink,
  RightHandSideTitle,
  RightHandSideFootText,
} from '../Register/Shared/RegistrationTypographies';
import { generateRightHandSideImages } from '../Register/UserTypeSelection';

export const LoginPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { userType } = useParams();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setFullscreen({ status: true }));
    return () => {
      dispatch(setFullscreen({ status: false }));
    };
  }, []);

  if (isLoading) {
    return (
      <FullPageContainer>
        <LoadingPage loadingText="Signing you in" />
      </FullPageContainer>
    );
  }

  return (
    <FullPageContainer>
      <SideContainer isLeft={true}>
        <SideContainerInner>
          <UserSelectionHeadline>Sign in</UserSelectionHeadline>
          <LoginForm setIsLoading={setIsLoading} />
          <SubRegistrationText>
            <SubRegistrationLink to={'/reset-password'}>I forgot my password</SubRegistrationLink>
          </SubRegistrationText>
          <Box
            display="flex"
            flexDirection="column"
            gap="8px"
          >
            <SubRegistrationText>Don't have an account?</SubRegistrationText>
            <SubRegistrationText>
              <SubRegistrationLink to={`/register/${UserTypeEnum.VENUE}`}>Join as venue</SubRegistrationLink> or{' '}
              <SubRegistrationLink to={`/register`}>Join as artist or promoter</SubRegistrationLink>
            </SubRegistrationText>
          </Box>
        </SideContainerInner>
      </SideContainer>
      <SideContainer>
        <RightHandSideTitle>TuneHatch</RightHandSideTitle>
        {generateRightHandSideImages('artist')}
        <RightHandSideFootText>© 2024 TuneHatch Inc.</RightHandSideFootText>
      </SideContainer>
    </FullPageContainer>
  );
};
