import { Menu, Typography, styled } from '@mui/material';
import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { DataCy } from '../../Helpers/DataCy';
import { colors } from '../../ui/shared/Colors';
import { typography } from '../../ui/shared/TypographySharedElements';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { logOut } from '../../Redux/User/UserSlice';
import axios from 'axios';
import { APIURL } from '../../Helpers/configConstants';

interface ProfileUserMenuProps {
  isUserMenuOpen: boolean;
  setIsUserMenuOpen: Dispatch<SetStateAction<boolean>>;
  parentRef: Element;
}

export const ProfileUserMenu: FC<ProfileUserMenuProps> = ({ isUserMenuOpen, setIsUserMenuOpen, parentRef }) => {
  const dispatch = useAppDispatch();
  const darkMode = useAppSelector((state) => state.ui.grid.darkMode);
  const user = useAppSelector((state) => state.user.data);
  const [stripeLink, setStripeLink] = useState(null);
  const navigate = useNavigate();

  const viewType = useMemo(() => {
    if (user.host) {
      return 'venue';
    } else if (user.artist) {
      return 'artist';
    } else if (user.showrunner) {
      return 'showrunner';
    }
  }, [user]);

  const getStripeAccount = useCallback(async () => {
    let stripeRequest = await axios.post(`${APIURL}ind/stripe-request`, {
      SECRET_UID: user.uid,
      venueID: user.host ? user.venues[0] : null,
      viewType: viewType,
      targetID: user.displayUID,
      stripeID: user.stripe?.id,
    });
    setStripeLink(stripeRequest.data.url);
  }, [user, viewType]);

  useEffect(() => {
    if (viewType === 'artist' && user.displayUID && !stripeLink && user.uid) {
      getStripeAccount();
    }
  }, [user, viewType, stripeLink, getStripeAccount]);

  const logOutFunction = useCallback(() => {
    dispatch(logOut());
    setIsUserMenuOpen(false);
    navigate('/');
  }, [dispatch, setIsUserMenuOpen]);

  return (
    <ProfileUserMenuMainCont
      open={isUserMenuOpen}
      onClose={() => {
        setIsUserMenuOpen(false);
      }}
      anchorEl={parentRef}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      darkMode={darkMode}
      isLoggedIn={true}
    >
      {user.artist && (
        <ProfileUserMenuLink
          darkMode={darkMode}
          to="/profile"
          onClick={() => setIsUserMenuOpen(false)}
        >
          Profile
        </ProfileUserMenuLink>
      )}
      {user.artist && (
        <ProfileUserMenuLink
          darkMode={darkMode}
          to={'/artist/manage-shows'}
          onClick={() => setIsUserMenuOpen(false)}
        >
          Artist Shows
        </ProfileUserMenuLink>
      )}
      {user.artist && (
        <ProfileUserMenuLink
          darkMode={darkMode}
          to={stripeLink}
          target="_blank"
          onClick={() => setIsUserMenuOpen(false)}
        >
          Manage payouts
        </ProfileUserMenuLink>
      )}
      {(user.host || user.artist) && (
        <ProfileUserMenuLink
          darkMode={darkMode}
          to="/messages"
          onClick={() => setIsUserMenuOpen(false)}
        >
          Messages
        </ProfileUserMenuLink>
      )}
      {/* <ProfileUserMenuLink
        darkMode={darkMode}
        to="/tickets"
        onClick={() => setIsUserMenuOpen(false)}
      >
        Tickets
      </ProfileUserMenuLink> */}
      {user.host && (
        <ProfileUserMenuLink
          darkMode={darkMode}
          to="/venues/manage"
          onClick={() => setIsUserMenuOpen(false)}
        >
          Manage Venues
        </ProfileUserMenuLink>
      )}
      {user.showrunner && (
        <ProfileUserMenuLink
          darkMode={darkMode}
          to="/showrunner-tools"
          onClick={() => setIsUserMenuOpen(false)}
        >
          Showrunners
        </ProfileUserMenuLink>
      )}
      <ProfileUserMenuLink
        darkMode={darkMode}
        to="/account-settings"
        onClick={() => setIsUserMenuOpen(false)}
      >
        Settings
      </ProfileUserMenuLink>
      <ProfileUserMenuItem
        darkMode={darkMode}
        onClick={logOutFunction}
        data-cy={DataCy.link('logout')}
      >
        Logout
      </ProfileUserMenuItem>
    </ProfileUserMenuMainCont>
  );
};

export const ProfileUserMenuMainCont = styled(Menu, {
  name: 'ProfileUserMenuMainCont',
  shouldForwardProp: (prop) => prop !== 'darkMode' && prop !== 'isLoggedIn',
})<{ darkMode: boolean; isLoggedIn: boolean }>(({ darkMode, isLoggedIn, theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minWidth: '151px',
  width: '100%',
  borderRadius: '12px',
  flexGrow: 1,
  '.MuiPaper-root': {
    backgroundColor: darkMode ? colors.SystemBlack : colors.SystemWhite,
  },
  [theme.breakpoints.down('md')]: {
    '.MuiList-root': {
      width: '100%',
      height: '100%',
    },
    '.MuiPaper-root': {
      width: '100%',
      height: '100%',
      minWidth: '100%',
      minHeight: '100vh',
      top: isLoggedIn ? '88.67px !important' : '57.29px !important',
      left: '0 !important',
    },
  },
}));

export const ProfileUserMenuLink = styled(Link, {
  name: 'ProfileUserMenuLink',
  shouldForwardProp: (prop) => prop !== 'darkMode',
})<{ darkMode: boolean }>(({ darkMode, theme }) => ({
  display: 'block',
  width: '100%',
  padding: '16px',
  color: darkMode ? colors.SystemWhite : colors.SystemGray900,
  ...typography.bodyMediumRegular,
  '&:hover': {
    color: colors.THOrange,
  },
  [theme.breakpoints.down('md')]: {
    padding: '10px 20px',
    ...typography.headlineSmall,
    borderBottom: `0.5px solid ${colors.SystemGray100}`,
  },
}));

const ProfileUserMenuItem = styled(Typography, {
  name: 'ProfileUserMenuItem',
  shouldForwardProp: (prop) => prop !== 'darkMode',
})<{ darkMode: boolean }>(({ darkMode, theme }) => ({
  display: 'block',
  width: '100%',
  padding: '16px',
  color: darkMode ? colors.SystemWhite : colors.SystemGray900,
  ...typography.bodyMediumRegular,
  cursor: 'pointer',
  '&:hover': {
    color: colors.THOrange,
  },
  [theme.breakpoints.down('md')]: {
    ...typography.headlineSmall,
  },
}));
