import { Box, styled } from '@mui/material';
import React, { FC } from 'react';
import Img from '../../../Components/Images/Img';
import SpotifyIcon from '../../../Images/Icons/SpotifyIcon.svg';

export const SpotifySkeleton: FC = () => {
  return (
    <SpotifySkeletonContainer>
      <SpotifyIconContainer src={SpotifyIcon} />
    </SpotifySkeletonContainer>
  );
};

const SpotifySkeletonContainer = styled(Box, {
  name: 'SpotifySkeletonContainer',
})({
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
  minWidth: '100%',
  height: '352px',
  backgroundColor: '#2A2E36',
  padding: '16px',
});

const SpotifyIconContainer = styled(Img, {
  name: 'SpotifyIconContainer',
})({
  display: 'block',
  height: '20px',
  width: 'auto',
});
