import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from 'react';
import { TitleBar } from '../Shared/TitleBar';
import { FormInnerCont, FormRowCont, FormSectionCont } from '../Shared/Containers';
import { ViewType } from '../../../Helpers/shared/Models/ViewType';
import { Box, FormControlLabel, MenuItem } from '@mui/material';
import { ShowType } from '../SingleTicketTypeButton';
import { FormSectionTitle } from '../../Register/Shared/RegistrationTypographies';
import { Select, TextField } from '../../../ui/inputs/TextField';
import { useFormContext } from 'react-hook-form';
import {
  useGetAllArtistsQuery,
  useGetAllShowrunnerGroupsQuery,
  useGetAllVenuesQuery,
} from '../../../Redux/API/PublicAPI';
import LoadingSpinner from '../../../Components/LoadingSpinner';
import { isArray, isEmpty, uniqBy } from 'lodash';
import { DateTimePicker } from '../../../ui/inputs/DateTimePicker';
import { Checkbox } from '../../../ui/inputs/Checkbox';
import { MultipleSelectChip, SingleOption } from '../../../ui/inputs/ChipMultiselect';
import { useParams, useSearchParams } from 'react-router-dom';
import { getDisplayName } from '../../../Helpers/HelperFunctions';
import { DEFAULT_CALTAGS } from '../../../Helpers/shared/calTagsConfig';
import { RecurringShowFormPart } from './RecurringShowFormPart';
import { ShowFormNavButtons } from './ShowFormNavButtons';
import { OffPlatformVenueForm } from './OffPlatformVenueForm';
import { OffPlatformArtistForm } from './OffPlatformArtistForm';
import { OffPlatformShowrunnerForm } from './OffPlatformShowrunnerForm';
import { Show } from '../../../Helpers/shared/Models/Show';
import { isAfter, differenceInHours } from 'date-fns';
import { LongShowWarning, ShortShowWarning } from './ShowTimeWarnings';

interface ShowFormStep2Props {
  type: ViewType;
  handleChangeStep: (fieldsToCheck: any[], back?: boolean) => void;
  showType: ShowType;
  setOpen: Dispatch<SetStateAction<boolean>>;
  show: Show;
  showrunnerOptions: SingleOption[];
  setShowrunnerOptions: Dispatch<SetStateAction<SingleOption[]>>;
  showrunnersSelectValue: SingleOption[];
  setShowrunnersSelectValue: Dispatch<SetStateAction<SingleOption[]>>;
  artistOptions: SingleOption[];
  setArtistOptions: Dispatch<SetStateAction<SingleOption[]>>;
  artistSelectValue: SingleOption[];
  setArtistSelectValue: Dispatch<SetStateAction<SingleOption[]>>;
  venueOptions: SingleOption[];
  setVenueOptions: Dispatch<SetStateAction<SingleOption[]>>;
  venueSelectValue: SingleOption[];
  setVenueSelectValue: Dispatch<SetStateAction<SingleOption[]>>;
  offPlatformArtists: SingleOption[];
  setOffPlatformArtists: Dispatch<SetStateAction<SingleOption[]>>;
  offPlatformShowrunners: SingleOption[];
  setOffPlatformShowrunners: Dispatch<SetStateAction<SingleOption[]>>;
  offPlatformVenues: SingleOption[];
  setOffPlatformVenues: Dispatch<SetStateAction<SingleOption[]>>;
  repeatingShow: boolean;
  setRepeatingShow: Dispatch<SetStateAction<boolean>>;
  showLength: number;
  setShowLength: Dispatch<SetStateAction<number>>;
}
export interface OffPlatformArtistShowrunnerFull {
  id: string;
  name: string;
  email: string;
}

export interface OffPlatformVenueFull {
  id: string;
  name: string;
  bookingContactName: string;
  email: string;
  location: any;
}
function uniqueById(e: { id: string }) {
  return e.id;
}

export const ShowFormStep2: FC<ShowFormStep2Props> = ({
  type,
  handleChangeStep,
  showType,
  setOpen,
  show,
  showrunnerOptions,
  setShowrunnerOptions,
  showrunnersSelectValue,
  setShowrunnersSelectValue,
  artistOptions,
  setArtistOptions,
  artistSelectValue,
  setArtistSelectValue,
  venueOptions,
  setVenueOptions,
  venueSelectValue,
  setVenueSelectValue,
  offPlatformArtists,
  setOffPlatformArtists,
  offPlatformShowrunners,
  setOffPlatformShowrunners,
  offPlatformVenues,
  setOffPlatformVenues,
  repeatingShow,
  setRepeatingShow,
  showLength,
  setShowLength,
}) => {
  const { showID, viewID } = useParams();
  const createShowForm = useFormContext();
  const showrunners = useGetAllShowrunnerGroupsQuery();
  const venues = useGetAllVenuesQuery();
  const artists = useGetAllArtistsQuery();
  const [venueErrorInfo, setVenueErrorInfo] = useState<string>(null);
  const handleChangeCheckbox = useCallback(
    (name: string, value: boolean) => {
      createShowForm.setValue(name, value);
    },
    [createShowForm],
  );
  const [search, setSearch] = useSearchParams();

  const generateCalTagOptions = () => {
    return Object.entries(DEFAULT_CALTAGS).map((calTag) => ({ value: calTag[0], label: calTag[1].label }));
  };

  const changeStep = useCallback((back?: boolean) => {
    if (back) {
      handleChangeStep([], true);
    } else {
      if (!venueErrorInfo) {
        handleChangeStep([
          'name',
          'description',
          'venues',
          'showrunners',
          'artists',
          'startTime',
          'endTime',
          'minAge',
          'repeatingShow',
          'isPrivate',
          'isManageableByArtists',
          'calendarColor',
        ]);
      }
    }
    window.scrollTo(0, 0);
  }, []);

  const handleToggleShowrunnerElements = useCallback(
    (value: SingleOption | SingleOption[], isDelete?: boolean) => {
      createShowForm.clearErrors('showruners');
      if (isDelete) {
        const actualValues: string[] = createShowForm.getValues('showrunners');
        const deletedElements: SingleOption | null = showrunnersSelectValue.find((singleShowrunner) => {
          if (!isArray(value)) {
            return singleShowrunner.label === value.label;
          } else {
            return value.map((singleValue) => singleShowrunner.label === singleValue.label);
          }
        });
        if (!isEmpty(deletedElements)) {
          const newValues = actualValues.filter((singleValue) => singleValue !== deletedElements.id);
          createShowForm.setValue('showrunners', newValues);
        }
      } else {
        let offplatforms = [];
        if (isArray(value)) {
          const valueCopy = [...value];
          offplatforms = valueCopy.filter((singleValue) => singleValue.id === '0');
        } else {
          if (value.id === '0') {
            offplatforms.push(value);
          }
        }
        const filteredOptions = showrunnerOptions.filter((singleOption) => {
          if (!isArray(value)) {
            return singleOption.label === value.label;
          } else {
            return !!value.find((singleValue) => {
              return singleValue.label === singleOption.label;
            });
          }
        });
        const filteredOptionsWithOffplatforms = [...filteredOptions, ...offplatforms];
        const filteredOptionsWithOffplatformsMapped = filteredOptionsWithOffplatforms.map((opt) => {
          if (opt.id !== '0') {
            return { id: opt.id };
          } else {
            return {
              id: opt.id,
              name: opt.label,
              email: '',
            };
          }
        });
        createShowForm.setValue('showrunners', uniqBy(filteredOptionsWithOffplatformsMapped, uniqueById));
      }
    },
    [showrunnersSelectValue, createShowForm, showrunnerOptions],
  );

  const handleToggleArtistsElements = useCallback(
    (value: SingleOption | SingleOption[], isDelete?: boolean) => {
      createShowForm.clearErrors('artists');
      if (isDelete) {
        const actualValues: { id: string }[] = createShowForm.getValues('artists');
        const deletedElements: SingleOption | null = artistSelectValue.find((singleArtist) => {
          if (!isArray(value)) {
            return singleArtist.id === value.id;
          } else {
            return value.map((singleValue) => singleArtist.label === singleValue.id);
          }
        });
        if (!isEmpty(deletedElements)) {
          const newValues = actualValues.filter((singleValue) => singleValue.id !== deletedElements.id);
          createShowForm.setValue('artists', uniqBy(newValues, uniqueById));
        }
      } else {
        let offplatforms = [];
        if (isArray(value)) {
          const valueCopy = [...value];
          offplatforms = [...valueCopy.filter((singleValue) => singleValue.id === '0')];
        } else {
          if (value.id === '0') {
            offplatforms.push(value);
          }
        }
        const filteredOptions = artistOptions.filter((singleOption) => {
          if (!isArray(value)) {
            return singleOption.label === value.label;
          } else {
            return !!value.find((singleValue) => {
              return singleValue.label === singleOption.label;
            });
          }
        });
        const filteredOptionsWithOffplatforms = [...filteredOptions, ...offplatforms];
        const filteredOptionsWithOffplatformsMapped = filteredOptionsWithOffplatforms.map((opt) => {
          if (opt.id !== '0') {
            return { id: opt.id };
          } else {
            return {
              id: opt.id,
              stageName: opt.label,
              email: '',
            };
          }
        });
        const prevVal = createShowForm.getValues('artists');
        const currentNewVal = [...prevVal, ...filteredOptionsWithOffplatformsMapped];
        createShowForm.setValue('artists', null);
        createShowForm.setValue('artists', uniqBy(currentNewVal, uniqueById));
      }
    },
    [artistSelectValue, createShowForm, artistOptions],
  );

  const handleToggleVenueElements = useCallback(
    (value: SingleOption | SingleOption[], isDelete?: boolean) => {
      createShowForm.clearErrors('venues');
      if (type !== 'venue') {
        if (value && !isArray(value)) {
          const selectedVenue = venues?.data[value.id];
          if (!selectedVenue.isOpenForManagers) {
            setVenueErrorInfo(selectedVenue.name);
            return;
          } else {
            setVenueErrorInfo(null);
          }
        } else if (value && isArray(value)) {
          const selectedVenue = venues?.data?.[value[0]?.id];
          if (!selectedVenue?.isOpenForManagers) {
            setVenueErrorInfo(selectedVenue?.name);
            return;
          } else {
            setVenueErrorInfo(null);
          }
        }
      }
      if (isDelete) {
        const actualValues: string[] = createShowForm.getValues('venues');
        const deletedElements: SingleOption | null = venueSelectValue.find((singleVenue) => {
          if (!isArray(value)) {
            return singleVenue.label === value.label;
          } else {
            return value.map((singleValue) => singleVenue.label === singleValue.label);
          }
        });
        if (!isEmpty(deletedElements)) {
          const newValues = actualValues.filter((singleValue) => singleValue !== deletedElements.id);
          createShowForm.setValue('venues', newValues);
        }
      } else {
        let offplatforms = [];
        if (isArray(value)) {
          const valueCopy = [...value];
          offplatforms = valueCopy.filter((singleValue) => singleValue.id === '0');
        } else {
          if (value.id === '0') {
            offplatforms.push(value);
          }
        }
        const filteredOptions = venueOptions.filter((singleOption) => {
          if (!isArray(value)) {
            return singleOption.label === value.label;
          } else {
            return !!value.find((singleValue) => {
              return singleValue.label === singleOption.label;
            });
          }
        });
        const filteredOptionsWithOffplatforms = [...filteredOptions, ...offplatforms];
        const filteredOptionsWithOffplatformsMapped = filteredOptionsWithOffplatforms.map((opt) => {
          if (opt.id !== '0') {
            return { id: opt.id };
          } else {
            return {
              id: opt.id,
              name: opt.label,
              bookingContactName: '',
              email: '',
              location: null,
            };
          }
        });
        createShowForm.setValue('venues', uniqBy(filteredOptionsWithOffplatformsMapped, uniqueById));
        if (type === ViewType.SRGroup || type === ViewType.Artist) {
          const selectedVenueId = filteredOptionsWithOffplatformsMapped[0]?.id;
          if (selectedVenueId && selectedVenueId !== '0') {
            const selectedVenueWholeData = venues?.data[selectedVenueId];
            if (!isEmpty(selectedVenueWholeData)) {
              createShowForm.setValue('numberOfAvailableTickets', `${selectedVenueWholeData.capacity}`);
              const isMinAgeSet = !!createShowForm.getValues('minAge');
              if (!isMinAgeSet) {
                createShowForm.setValue('minAge', `${selectedVenueWholeData.min_age}`);
              }
            }
          }
        }
      }
    },
    [venueSelectValue, createShowForm, venueOptions],
  );

  useEffect(() => {
    if (!isEmpty(showrunners?.data)) {
      const entries = Object.entries(showrunners.data);
      const options = entries.map((entry) => ({
        label: entry[1].name,
        id: entry[0],
        avatar: entry[1].avatar || entry[1].avatarWebP,
      }));
      setShowrunnerOptions(options);
      if (type === 'showrunner') {
        const currentUser = options.find((option) => option.id === viewID);
        setShowrunnersSelectValue([currentUser]);
      }
    }
  }, [showrunners]);

  useEffect(() => {
    const formDataArtists = createShowForm.getValues('artists');
    if (formDataArtists.length < 2) {
      if (!isEmpty(artists?.data)) {
        const entries = Object.entries(artists.data);
        const options = entries.map((entry) => ({
          label: getDisplayName('artist' as ViewType, entry[1]),
          id: entry[0],
          avatar: entry[1].avatar || entry[1].avatarWebP,
        }));
        setArtistOptions(options);
        if (type === 'artist') {
          const currentUser = options.find((option) => option.id === viewID);
          setArtistSelectValue([currentUser]);
        }
      }
    }
  }, [artists]);
  useEffect(() => {
    if (!isEmpty(venues?.data)) {
      const entries = Object.entries(venues.data);
      const options = entries.map((entry) => ({
        label: entry[1].name || '',
        id: entry[0],
        avatar: entry[1].avatar || entry[1].avatarWebP,
      }));
      setVenueOptions(options);
      if (type === 'venue') {
        const currentUser = options.find((option) => option.id === viewID);
        setVenueSelectValue([currentUser]);
      }
    }
  }, [venues]);

  useEffect(() => {
    if (type === 'artist' && !isEmpty(artistOptions)) {
      const currentArtistFormData = createShowForm.getValues('artists');
      if (isEmpty(currentArtistFormData)) {
        const currentArtistOption = artistOptions.find((option) => option.id == viewID);
        if (currentArtistOption) {
          setArtistSelectValue([currentArtistOption]);
        }
      }
    }
  }, [artistOptions]);

  useEffect(() => {
    if (show) {
      if (show.venueID && !isEmpty(venueOptions)) {
        const selectedVenueOption = venueOptions.find((venueOpt) => venueOpt.id === show.venueID);
        setVenueSelectValue([selectedVenueOption]);
      }
      if (!isEmpty(show.performers) && !isEmpty(artistOptions)) {
        const selectedPerformers = show.performers.map((performer) => {
          return artistOptions.find((artistOpt) => artistOpt.id === performer.uid);
        });
        setArtistSelectValue(selectedPerformers);
      }
      if (!isEmpty(show.showrunner) && !isEmpty(showrunnerOptions)) {
        const selectedPerformer = showrunnerOptions.find((showrunnerOpt) => showrunnerOpt.id === show.showrunner[0].id);
        setShowrunnersSelectValue([selectedPerformer]);
      }
    }
  }, [show, showrunnerOptions, artistOptions, venueOptions]);

  if (showrunners.isLoading || artists.isLoading || venues.isLoading) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100%"
        width="100%"
      >
        <LoadingSpinner />
      </Box>
    );
  }

  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <TitleBar
        formName="Create Show"
        step={2}
        maxSteps={!showID ? 5 : 3}
        stepTitle="Add show details"
        setOpen={setOpen}
      />
      <FormInnerCont>
        <FormSectionCont>
          <FormSectionTitle>Show details</FormSectionTitle>
          <TextField
            {...createShowForm.register('name')}
            type="text"
            label="Show Name"
            placeholder="Show Name"
            error={!!createShowForm.formState.errors?.name?.message}
            helperText={
              !!createShowForm.formState.errors?.name?.message && <>{createShowForm.formState.errors?.name?.message}</>
            }
          />
          <TextField
            {...createShowForm.register('description')}
            type="text"
            label="Show Description"
            placeholder="Show Description"
            error={!!createShowForm.formState.errors?.description?.message}
            multiline={true}
            minRows={3}
            helperText={
              !!createShowForm.formState.errors?.description?.message && (
                <>{createShowForm.formState.errors?.description?.message}</>
              )
            }
          />
          {type !== 'venue' && (
            <>
              <MultipleSelectChip
                selectValue={venueSelectValue}
                setSelectValue={setVenueSelectValue}
                options={venueOptions}
                handleChangeOuter={handleToggleVenueElements}
                label="Add venue"
                placeholder="Add venue"
                setSearch={setSearch}
                search={search.get('venues')}
                isLoading={venues.isLoading}
                name="venues"
                isSingle
                isOffPlatformPossible
                offPlatformOptionsList={offPlatformVenues}
                setOffPlatformOptionsList={setOffPlatformVenues}
                error={!!venueErrorInfo || !!createShowForm.formState.errors?.venues?.message}
                helperText={
                  venueErrorInfo ? (
                    <>{`You cannot create a show on behalf of ${venueErrorInfo} because they are a TuneHatch partner venue. Please email ${venueErrorInfo} to receive your official show listing/ticket link.`}</>
                  ) : (
                    !!createShowForm.formState.errors?.venues?.message && (
                      <>{createShowForm.formState.errors?.venues?.message}</>
                    )
                  )
                }
              />
            </>
          )}
          {!isEmpty(offPlatformVenues) &&
            offPlatformVenues.map((offPlatVenue) => (
              <OffPlatformVenueForm
                key={offPlatVenue.label}
                singleOption={offPlatVenue}
                index={venueSelectValue.findIndex((opt: SingleOption) => opt.label === offPlatVenue.label)}
              />
            ))}
          {type !== 'showrunner' && (
            <MultipleSelectChip
              selectValue={showrunnersSelectValue}
              setSelectValue={setShowrunnersSelectValue}
              options={showrunnerOptions}
              handleChangeOuter={handleToggleShowrunnerElements}
              label="Add showrunner (optional)"
              placeholder="Add showrunner"
              setSearch={setSearch}
              search={search.get('showrunners')}
              isLoading={showrunners.isLoading}
              name="showrunners"
              isSingle
              isOffPlatformPossible
              offPlatformOptionsList={offPlatformShowrunners}
              setOffPlatformOptionsList={setOffPlatformShowrunners}
              error={!!createShowForm.formState.errors?.showrunners?.message}
              helperText={
                !!createShowForm.formState.errors?.showrunners?.message && (
                  <>{createShowForm.formState.errors?.showrunners?.message}</>
                )
              }
            />
          )}

          {!isEmpty(offPlatformShowrunners) &&
            offPlatformShowrunners.map((offPlatShowrunner) => (
              <OffPlatformShowrunnerForm
                key={offPlatShowrunner.label}
                singleOption={offPlatShowrunner}
                index={showrunnersSelectValue.findIndex((opt: SingleOption) => opt.label === offPlatShowrunner.label)}
              />
            ))}
          {!showID && (
            <MultipleSelectChip
              selectValue={artistSelectValue}
              setSelectValue={setArtistSelectValue}
              options={artistOptions}
              handleChangeOuter={handleToggleArtistsElements}
              label="Add performers (optional)"
              placeholder="Add performers"
              setSearch={setSearch}
              search={search.get('artists')}
              isLoading={artists.isLoading}
              name="artists"
              isOffPlatformPossible
              offPlatformOptionsList={offPlatformArtists}
              setOffPlatformOptionsList={setOffPlatformArtists}
              error={!!createShowForm.formState.errors?.artists?.message}
              helperText={
                !!createShowForm.formState.errors?.artists?.message && (
                  <>{createShowForm.formState.errors?.artists?.message}</>
                )
              }
            />
          )}
          {!isEmpty(offPlatformArtists) &&
            offPlatformArtists.map((offPlatArtist) => (
              <OffPlatformArtistForm
                key={offPlatArtist.label}
                singleOption={offPlatArtist}
                index={artistSelectValue.findIndex((opt: SingleOption) => opt.label === offPlatArtist.label)}
              />
            ))}
          <FormRowCont>
            <DateTimePicker
              name="startTime"
              disablePast
              control={createShowForm.control}
              onDateChange={(value: string) => {
                const val = new Date(value);
                const newEndDateVal = new Date(val);
                newEndDateVal.setHours(newEndDateVal.getHours() + 2);
                createShowForm.setValue('startTime', val);
                createShowForm.setValue('endTime', newEndDateVal);
                if (isAfter(val, createShowForm.getValues('endTime'))) {
                  const startHour = val.getHours();
                  const newEndDate = new Date(val);
                  newEndDate.setHours(startHour + 2, 0, 0, 0);
                  createShowForm.setValue('endTime', newEndDate);
                  setShowLength(null);
                }
                const diff = Math.abs(differenceInHours(val, createShowForm.getValues('endTime')));
                if (diff >= 8) {
                  setShowLength(diff);
                } else {
                  setShowLength(null);
                }
              }}
              defaultValue={new Date()}
              disabled={false}
              label="Enter start time"
              error={!!createShowForm.getFieldState('startTime').error?.message}
              helperText={
                !!!!createShowForm.getFieldState('startTime').error?.message && (
                  <>{createShowForm.getFieldState('startTime').error?.message}</>
                )
              }
            />
            <DateTimePicker
              name="endTime"
              disablePast
              control={createShowForm.control}
              defaultValue={new Date()}
              onDateChange={(value: string) => {
                const val = new Date(value);
                createShowForm.setValue('endTime', val);
                if (isAfter(createShowForm.getValues('startTime'), val)) {
                  const endHour = val.getHours();
                  const newStartDate = new Date(val);
                  newStartDate.setHours(endHour - 2, 0, 0, 0);
                  createShowForm.setValue('startTime', newStartDate);
                  setShowLength(null);
                }
                const diff = Math.abs(differenceInHours(createShowForm.getValues('startTime'), val));
                if (diff >= 8 || diff <= 1) {
                  setShowLength(diff);
                } else {
                  setShowLength(null);
                }
              }}
              disabled={false}
              label="Enter end time"
              error={!!createShowForm.getFieldState('endTime').error?.message}
              helperText={
                !!createShowForm.getFieldState('endTime').error?.message && (
                  <>{createShowForm.getFieldState('endTime').error?.message}</>
                )
              }
            />
          </FormRowCont>
          {showLength && showLength >= 8 && <LongShowWarning distance={showLength} />}
          {showLength && showLength <= 1 && <ShortShowWarning distance={showLength} />}

          <TextField
            {...createShowForm.register('minAge')}
            type="text"
            label="Minimum Age"
            placeholder="Minimum Age"
            error={!!createShowForm.formState.errors?.minAge?.message}
            helperText={
              !!createShowForm.formState.errors?.minAge?.message && (
                <>{createShowForm.formState.errors?.minAge?.message}</>
              )
            }
            fullWidth
          />
          <FormControlLabel
            control={
              <Checkbox
                {...createShowForm.register('repeatingShow')}
                checked={createShowForm.getValues('repeatingShow')}
                onChange={(value, checked) => {
                  handleChangeCheckbox('repeatingShow', checked);
                  setRepeatingShow((repeatingShow) => !repeatingShow);
                }}
              />
            }
            label={'Repeating show'}
          />
          {repeatingShow && <RecurringShowFormPart />}
          <FormControlLabel
            control={
              <Checkbox
                {...createShowForm.register('isPrivate')}
                defaultChecked={createShowForm.getValues('isPrivate')}
                onChange={(value, checked) => handleChangeCheckbox('isPrivate', checked)}
              />
            }
            label={'Private event'}
          />
          <FormControlLabel
            control={
              <Checkbox
                {...createShowForm.register('isManageableByArtists')}
                defaultChecked={createShowForm.getValues('isManageableByArtists')}
                onChange={(value, checked) => handleChangeCheckbox('isManageableByArtists', checked)}
              />
            }
            label={'Artists can manage show'}
          />
          <Select
            {...createShowForm.register('calendarColor')}
            type="text"
            label="Calendar Color"
            error={!!createShowForm.formState.errors?.calendarColor?.message}
            helperText={
              !!createShowForm.formState.errors?.calendarColor?.message && (
                <>{createShowForm.formState.errors?.calendarColor?.message}</>
              )
            }
            select
            placeholder="Select"
            defaultValue={createShowForm.getValues('calendarColor')}
          >
            {generateCalTagOptions().map((calTagOption) => {
              return (
                <MenuItem
                  key={calTagOption.value}
                  value={calTagOption.value}
                >
                  {calTagOption.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormSectionCont>
        <ShowFormNavButtons changeStep={changeStep} />
      </FormInnerCont>
    </Box>
  );
};
