export namespace RegularExp {
  export const Int = /^-?\d+$/;
  /**
   * Actually allows most youtube links, but does not allow channel links since thats the most likely case of a user entering an invalid link
   * @see https://stackoverflow.com/questions/19377262/regex-for-youtube-url
   * @see https://regex101.com/r/vHEc61/1
   */
  export const YoutubeVideo =
    /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(?:-nocookie)?\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|live\/|v\/)?(?!channel))([\w\-]+)(\S+)?$/;
  /**
   * only letters, numbers, dots and underscores
   */
  export const InstagramUsername = /^[a-zA-Z0-9_.]+$/;
}
