import { Box, styled, Typography } from '@mui/material';
import React, { Dispatch, FC, SetStateAction, useCallback } from 'react';
import { colors } from '../../../ui/shared/Colors';
import { typography } from '../../../ui/shared/TypographySharedElements';
import { Button } from '../../../ui/buttons/Button';

export interface TitleBarProps {
  formName: string;
  stepTitle: string;
  step: number;
  maxSteps: number;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export const TitleBar: FC<TitleBarProps> = ({ formName, stepTitle, step, maxSteps, setOpen }) => {
  const handleOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  return (
    <TitleBarCont>
      <TitleBarInnercont>
        <TitleBarTitleCont>
          <Box>
            <TitleBarStepText>{`${formName} • Step ${step} of ${maxSteps}`}</TitleBarStepText>
          </Box>
          <TitleBarStepTitle>{stepTitle}</TitleBarStepTitle>
        </TitleBarTitleCont>
        <Box
          display="flex"
          alignItems="center"
        >
          <Button
            disabled={false}
            size="large"
            sx={{ background: colors.SystemWhite }}
            onClick={handleOpen}
          >
            Cancel
          </Button>
        </Box>
      </TitleBarInnercont>
    </TitleBarCont>
  );
};

const TitleBarCont = styled(Box, {
  name: 'TitleBarCont',
})({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  padding: '20px',
  background: colors.SystemGray100,
});

const TitleBarInnercont = styled(Box, {
  name: 'TitleBarInnerCont',
})({
  display: 'flex',
  maxWidth: '520px',
  justifyContent: 'space-between',
  gap: '16px',
  width: '100%',
});

const TitleBarTitleCont = styled(Box, {
  name: 'TitleBarTitleCont',
})({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  width: '100%',
  alignItems: 'flex-start',
});

const TitleBarStepText = styled(Typography, {
  name: 'TitleBarStepText',
})({
  color: colors.SystemGray900,
  ...typography.bodySmallRegular,
});

const TitleBarStepTitle = styled(Typography, {
  name: 'TitleBarStepTitle',
})({
  color: colors.SystemGray900,
  ...typography.headlineSmall,
});
