import React, { useEffect, useState } from 'react';
import { Box, styled, Typography } from '@mui/material';
import { Button } from '../../ui/buttons/Button';
import { Show } from '../../Helpers/shared/Models/Show';
import ExploreShowCard from './ExploreShowCard';
import { typography } from '../../ui/shared/TypographySharedElements';
import { colors } from '../../ui/shared/Colors';
export default function ExploreUpcomingShows({ shows, max, gigs }: { shows: Show[]; max?: number; gigs?: boolean }) {
  const [maxShows, setMaxShows] = useState(max || 10);

  return (
    <MainContent>
      <Title>Upcoming Shows</Title>
      {shows?.length ? (
        <>
          {shows.slice(0, maxShows || shows.length).map((show) => (
            <ExploreShowCard
              key={show._key}
              show={show}
              isGig={gigs}
            />
          ))}
          <LoadMoreButton
            isPrimary
            size="large"
            disabled={maxShows >= shows.length}
            onClick={() => setMaxShows(maxShows + 10)}
          >
            Load More
          </LoadMoreButton>
        </>
      ) : (
        <EmptyMessage>No upcoming {gigs ? 'gigs' : 'shows'} found</EmptyMessage>
      )}
    </MainContent>
  );
}

const MainContent = styled(Box, {
  name: 'MainContent',
})({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
});

const Title = styled(Typography, {
  name: 'Title',
})(({ theme }) => ({
  ...typography.headlineMedium,
  fontFamily: 'Satoshi-Variable',
  color: colors.SystemGray900,
  [theme.breakpoints.down('lg')]: {
    ...typography.headlineSmall,
  },
}));

const EmptyMessage = styled(Typography, {
  name: 'EmptyMessage',
})(({ theme }) => ({
  ...typography.bodyMediumRegular,
  fontFamily: 'Satoshi-Variable',
  color: colors.SystemGray500,
  textAlign: 'center',
}));

const LoadMoreButton = styled(Button, {
  name: 'LoadMoreButton',
})(({ theme }) => ({
  width: 'fit-content',
  alignSelf: 'center',
}));
