import { z } from 'zod';
import { postRequest } from './Request/ApiRequest';
import { baseAPI } from './BaseAPI';
import { handleApiErrors } from './Request/handleErrors';

export namespace ImagesAPI {
  export namespace UploadImages {
    const paramsSchema = z.object({
      entityType: z.string(),
      field: z.string(),
      resourceId: z.string(),
      images: z.instanceof(FormData),
    });

    export const responseSchema = z.object({
      data: z.object({
        _key: z.string(),
        imagesPaths: z.array(z.string()),
      }),
    });

    export type Params = z.TypeOf<typeof paramsSchema>;
    export type Response = z.TypeOf<typeof responseSchema>;
    export const Request = async (params: Params) => {
      const parsedParams = paramsSchema.parse(params);

      return await postRequest({
        endpoint: `/v2/images/${parsedParams.entityType}/${parsedParams.field}/${parsedParams.resourceId}`,
        data: params.images,
      });
    };
  }
}

export const imagesAPI = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    uploadImages: builder.mutation<ImagesAPI.UploadImages.Response, ImagesAPI.UploadImages.Params>({
      async queryFn(params) {
        const result = await handleApiErrors(async () => await ImagesAPI.UploadImages.Request(params));
        const parsedResult = ImagesAPI.UploadImages.responseSchema.parse(result);
        return { data: parsedResult };
      },
    }),
  }),
});

export const { useUploadImagesMutation } = imagesAPI;
