import { useCallback, useEffect, useState } from 'react';
import { ColorExtended } from './ColorPickers';
import { extractColors } from 'extract-colors';
import { FinalColor } from 'extract-colors/lib/types/Color';
import { isEmpty } from 'lodash';

interface GetColorsFromImageProps {
  avatarUrl: string;
  limit: number;
}

function sortColors(a: FinalColor, b: FinalColor) {
  if (a.area > b.area) {
    return -1;
  } else if (a.area === b.area) {
    return 0;
  } else {
    return 1;
  }
}

export const useGetColorsPaletteFromImage = ({ avatarUrl, limit }: GetColorsFromImageProps) => {
  const getColorFromImage = useCallback(async () => {
    try {
      const options = {
        pixels: 64000,
        distance: 0.22,
        saturationDistance: 0.2,
        lightnessDistance: 0.2,
        hueDistance: 0.083333333,
      };
      const colorsFull = await extractColors(avatarUrl, options);
      const sortedColorsFull = colorsFull.sort(sortColors);
      const filteredColorsFull = sortedColorsFull.slice(0, limit);
      const mappedFilteredAndSortedColors = filteredColorsFull.map((singleColor) => {
        let isColorDark = singleColor.lightness < 0.5;
        return { hex: singleColor.hex, isDark: isColorDark };
      });
      return mappedFilteredAndSortedColors;
    } catch {
      console.log('sth went wrong');
    }
  }, [sortColors, extractColors]);

  return { getColorFromImage };
};
