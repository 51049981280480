import { Autocomplete, TextField as MuiTextField, TextFieldProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DataCy } from '../../Helpers/DataCy';
import { typography } from '../shared/TypographySharedElements';
import React, { FC, forwardRef } from 'react';
import { colors } from '../shared/Colors';

export const TextField = forwardRef<unknown, TextFieldProps>(({ InputProps, ...props }, ref) => {
  return (
    <TextFieldElement
      InputProps={{
        ...InputProps,
        // @ts-ignore
        'data-cy': DataCy.input(props.name),
      }}
      inputRef={ref}
      {...{ variant: 'standard', ...props }}
    />
  );
});

export const Select = forwardRef<unknown, TextFieldProps>(({ InputProps, ...props }, ref) => {
  return (
    <SelectField
      InputProps={{
        ...InputProps,
        // @ts-ignore
        'data-cy': DataCy.select(props.name),
      }}
      inputRef={ref}
      {...{ variant: 'standard', ...props }}
    />
  );
});

export const TextFieldElement = styled(MuiTextField, {
  name: 'TextField',
})<TextFieldProps>(({ disabled, error, label }) => ({
  '.MuiInputBase-root.MuiInput-root': {
    marginTop: '0px',
    paddingLeft: '0',
    paddingRight: '0',
    minWidth: '100%',
    '&::after': {
      display: 'none',
      borderBottom: 'none',
    },
    '&::before': {
      display: 'none',
      borderBottom: 'none',
    },
    '.MuiInputBase-input.MuiInput-input': {
      display: 'flex',
      flexGrow: 1,
      paddingLeft: '12px',
      paddingRight: '12px',
      paddingTop: '13px',
      paddingBottom: '13px',
      marginTop: label ? '22px' : '0',
      width: '100%',
      maxWidth: '100%',
      fontSize: typography.bodyLargeRegular.fontSize,
      fontWeight: typography.bodyLargeRegular.fontWeight,
      lineHeight: typography.bodyLargeRegular.lineHeight,
      letterSpacing: typography.bodyLargeRegular.letterSpacing,
      backgroundColor: error ? colors.AlertRedLight : 'transparent',
      border: `1px solid ${error ? colors.AlertRed : colors.SystemGray200}`,
      borderRadius: '8px',
      color: disabled ? colors.SystemGray500 : colors.SystemGray900,
    },
    '&.Mui-focused .MuiInputBase-input.MuiInput-input': {
      border: `1px solid ${error ? colors.AlertRed : colors.SystemGray400}`,
    },
  },
  '.MuiFormLabel-root.MuiInputLabel-root': {
    top: '0px',
    left: '4px',
    transform: 'none',
    color: error ? colors.AlertRed : colors.SystemGray900,
    ...typography.bodyMediumRegular,
  },
  '.MuiFormHelperText-root': {
    color: error ? colors.AlertRed : colors.SystemGray900,
    ...typography.subtitle,
  },
}));

const SelectField = styled(TextFieldElement, {
  name: 'SelectField',
})(({ label }) => ({
  '.MuiInputBase-root.MuiInput-root.MuiAutocomplete-root.MuiAutocomplete-inputRoot': {
    paddingRight: 0,
  },
  '.MuiSvgIcon-root.MuiSelect-icon.MuiSelect-iconStandard': {
    top: 'calc(50% - 2px)',
    right: '12px',
  },
  '.MuiAutocomplete-endAdornment': {
    transform: label ? 'translate(0, -20%)' : 'translate(0, -50%)',
    '.MuiButtonBase-root': {
      top: 'calc(50% - 2px)',
      right: '12px',
    },
  },
  '.MuiFormHelperText-root.Mui-error': {
    color: colors.AlertRed,
  },
}));

export const AutocompleteElement = styled(Autocomplete, {
  name: 'AutocompleteElement',
})({
  '.MuiInputBase-root.MuiInput-root.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot ': {
    paddingRight: 0,
  },
});
