import React from 'react';
import CreateVenueForm from '../../Forms/CreateVenueForm';

export default function CreateVenue() {
  return (
    <>
      <CreateVenueForm />
    </>
  );
}
