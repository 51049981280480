import React from 'react';
import ArtistPayoutCard from './ArtistPayoutCard';
import ShowrunnerPayoutCard from './ShowrunnerPayoutCard';
import VenuePayoutCard from './VenuePayoutCard';
import { ViewType } from '../../../Helpers/shared/Models/ViewType';
import { IPaymentObject } from '../../../Helpers/shared/Models/Payout';

export default function TargetPayoutCard(props: {
  id: string;
  showID: string;
  paymentObject: IPaymentObject;
  disabled: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  overpaid: boolean;
  remainder: number;
  viewType: ViewType;
}) {
  switch (props.paymentObject['type']) {
    case ViewType.Artist:
      return (
        <ArtistPayoutCard
          id={props.id}
          showID={props.showID}
          paymentObject={props.paymentObject}
          disabled={props.disabled}
          onChange={props.onChange}
          overpaid={props.overpaid}
          remainder={props.remainder}
          viewType={props.viewType}
        />
      );
    case ViewType.SRGroup:
      return (
        <ShowrunnerPayoutCard
          id={props.id}
          showID={props.showID}
          paymentObject={props.paymentObject}
          disabled={props.disabled}
          onChange={props.onChange}
          overpaid={props.overpaid}
          remainder={props.remainder}
          viewType={props.viewType}
        />
      );
    case ViewType.Venue:
      return (
        <VenuePayoutCard
          id={props.id}
          showID={props.showID}
          paymentObject={props.paymentObject}
          disabled={props.disabled}
          onChange={props.onChange}
          overpaid={props.overpaid}
          remainder={props.remainder}
          viewType={props.viewType}
        />
      );
    default:
      return (
        <ArtistPayoutCard
          id={props.id}
          showID={props.showID}
          paymentObject={props.paymentObject}
          disabled={props.disabled}
          onChange={props.onChange}
          overpaid={props.overpaid}
          remainder={props.remainder}
          viewType={props.viewType}
        />
      );
  }
}
