import { Dialog, styled } from '@mui/material';
import React, { Dispatch, FC, SetStateAction, useCallback } from 'react';
import { ShareHatchLinksModal } from './ShareHatchLinksModal';

interface ShareHatchLinksProps {
  link: string;
  username?: string;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export const ShareHatchLinks: FC<ShareHatchLinksProps> = ({ link, open, username, setOpen }) => {
  const closeModal = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <ShareHatchLinksDialog open={open}>
      <ShareHatchLinksModal
        username={username}
        closeModal={closeModal}
        hatchLinksLink={link}
      />
    </ShareHatchLinksDialog>
  );
};

const ShareHatchLinksDialog = styled(Dialog, {
  name: 'ShareHatchLinksDialog',
})(({ theme }) => ({
  '.MuiBackdrop-root': {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    [theme.breakpoints.down('md')]: {
      display: 'block',
      backgroundColor: 'unset',
    },
  },
}));
