import { Box, Dialog, Divider, styled, Typography } from '@mui/material';
import React, { FC, SyntheticEvent, useCallback, useMemo } from 'react';
import { colors } from '../../ui/shared/Colors';
import { typography } from '../../ui/shared/TypographySharedElements';
import { Button } from '../../ui/buttons/Button';
import { ViewType } from '../../Helpers/shared/Models/ViewType';

export type GuidedToursModalVersion = 'create_show' | 'edit_profile' | 'hatch_link';

export interface OpenGuidedTourModalProps {
  visible: boolean;
  version: GuidedToursModalVersion;
  pos: {
    x: number;
    y: number;
  };
  isMobile: boolean;
  handleClick: (e: SyntheticEvent) => void;
  closeModal: (e: SyntheticEvent) => void;
  viewType: ViewType;
}

export const GuidedToursModal: FC<OpenGuidedTourModalProps> = ({
  visible,
  version,
  pos,
  handleClick,
  closeModal,
  viewType,
}) => {
  const overlayText = useMemo(() => {
    switch (version) {
      case 'create_show':
        return 'You can always create a new show from here. Just click the “Add Show” button to create or first show.';
      case 'edit_profile':
        return 'To complete your profile or edit it whenever you want just click “Edit profile” button.';
      case 'hatch_link':
        return 'You can edit your HatchLink here.';
    }
  }, [version]);

  const buttonCoverTranslate = useMemo(() => {
    switch (viewType) {
      case ViewType.Artist:
        return version === 'create_show' ? 'translate(-65px, -90px)' : 'translate(-55px ,-90px)';
      case ViewType.SRGroup:
        return version === 'create_show' ? 'translate(-65px, -80px)' : 'translate(-60px, -105px)';
      case ViewType.Venue:
        return version === 'create_show' ? 'translate(-65px, -85px)' : 'translate(-70px, -80px)';
    }
  }, [version, viewType]);

  return (
    <GuidedToursDialog open={visible}>
      <Box
        position="relative"
        width="100%"
        height="100%"
      >
        <GuidedToursOverlay
          posX={pos.x}
          posY={pos.y}
          onClick={handleClick}
          buttonCoverTranslate={buttonCoverTranslate}
        >
          <Box
            width="100%"
            height="100%"
            position="relative"
            zIndex={1302}
          >
            <GuidedToursDialogTextBox version={version}>
              <GuidedToursDialogText>{overlayText}</GuidedToursDialogText>
              <GuidedToursDivider />
              <Button
                disabled={false}
                size="small"
                onClick={closeModal}
              >
                Skip
              </Button>
            </GuidedToursDialogTextBox>
          </Box>
        </GuidedToursOverlay>
      </Box>
    </GuidedToursDialog>
  );
};

const GuidedToursOverlay = styled(Box, {
  name: 'GuidedToursOverlay',
  shouldForwardProp: (prop) => prop !== 'posX' && prop !== 'posY' && prop !== 'version',
})<{ posX: number; posY: number; buttonCoverTranslate: string }>(({ posX, posY, buttonCoverTranslate }) => ({
  position: 'absolute',
  display: 'block',
  width: '250px',
  height: '250px',
  top: `${posY}px`,
  left: `${posX}px`,
  borderRadius: '9999px',
  boxShadow: `0 0 9999px 9999px rgba(0,0,0,0.5)`,
  transform: buttonCoverTranslate,
  zIndex: 1301,
  '::before': {
    content: '""',
    top: '0',
    left: '50%',
    width: '100px',
    height: '100px',
    backgroundColor: 'white',
  },
}));

const GuidedToursDialog = styled(Dialog, {
  name: 'GuidedToursDialog',
})({
  width: '100vw',
  height: '100vh',
  background: 'transparent',
  '.MuiPaper-root.MuiPaper-elevation': {
    display: 'block',
    position: 'relative',
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
    background: 'transparent',
    margin: 0,
    overflow: 'hidden',
  },
  '.MuiModal-backdrop': {
    display: 'none',
    background: 'transparent',
  },
});

const GuidedToursDialogTextBox = styled(Box, {
  name: 'GuidedToursDialogTextBox',
  shouldForwardProp: (prop) => prop !== 'version',
})<{ version: string }>(({ version }) => ({
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  left: '0',
  top: version === 'hatch_link' ? '-50%' : '-66%',
  borderRadius: '8px',
  backgroundColor: colors.SystemWhite,
  padding: '16px',
  ':after': {
    content: '" "',
    position: 'absolute',
    top: 'calc(100% - 6px)',
    width: '12px',
    height: '12px',
    left: '50%',
    transform: 'rotate(45deg)',
    backgroundColor: colors.SystemWhite,
  },
}));

const GuidedToursDialogText = styled(Typography, {
  name: 'GuidedToursDialogText',
})({
  fontFamily: 'Satoshi-Variable',
  color: colors.SystemGray900,
  ...typography.bodySmallRegular,
});

const GuidedToursDivider = styled(Divider, {
  name: 'GuidedToursDivider',
})({
  '.MuiDivider-root': {
    backgroundColor: '#D9D9D9',
    height: '1px',
  },
});
