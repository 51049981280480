import React from 'react';
import { Typography, styled } from '@mui/material';
import { Performer, Show } from '../../Helpers/shared/Models/Show';
import { useGetAllShowrunnerGroupsQuery, useGetAllShowsQuery, useGetAllVenuesQuery } from '../../Redux/API/PublicAPI';

import { Link } from 'react-router-dom';
import { openSidebar } from '../../Redux/UI/UISlice';
import { useAppDispatch } from '../../hooks';
import { typography } from '../../ui/shared/TypographySharedElements';
import { colors } from '../../ui/shared/Colors';

interface ExploreShowCardLinkProps {
  show?: Show;
  showID?: string;
}

/**
 * Shows the text for the show card link, either from a show object or a showID
 * @prop show {Show}
 * @prop showID {string}
 * @returns {JSX.Element}
 */
export default function ExploreShowCardLink({ show, showID }: ExploreShowCardLinkProps) {
  const dispatch = useAppDispatch();
  //we have to call useGetAllShowsQuery outside of the if statement because we cant call hooks conditionally
  const venues = useGetAllVenuesQuery();
  const showrunners = useGetAllShowrunnerGroupsQuery();
  const shows = useGetAllShowsQuery(undefined, {
    skip: !showID || show !== undefined,
  });
  //If show is undefined, fetch the show from the API
  if (!show) {
    show = shows?.data?.[showID];
  }
  const venue = show?.venueID && venues?.data?.[show.venueID];
  const showrunner = show?.showrunner?.[0]?.id && showrunners?.data?.[show?.showrunner?.[0].id];

  return (
    <FullText>
      {showrunner ? (
        <>
          Hosted by{' '}
          <LinkText
            to={`/profile/g/${showrunner?._key || showrunner?.SRID}`}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              dispatch(
                openSidebar({
                  status: true,
                  component: 'ViewProfile',
                  data: { profileID: showrunner._key || showrunner.SRID, type: 'showrunner' },
                }),
              );
            }}
          >
            {showrunner?.name}
          </LinkText>
        </>
      ) : (
        <></>
      )}{' '}
      @{' '}
      <LinkText
        to={`/profile/v/${venue?._key}`}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          dispatch(
            openSidebar({
              status: true,
              component: 'ViewProfile',
              data: { profileID: venue._key, type: 'venue' },
            }),
          );
        }}
      >
        {venue?.name}
      </LinkText>
    </FullText>
  );
}

const FullText = styled(Typography, {
  name: 'FullText',
})(({ theme }) => ({
  ...typography.bodySmallRegular,
  fontFamily: 'Satoshi-Variable',
  color: colors.SystemGray900,
  [theme.breakpoints.down('md')]: {
    textAlign: 'center',
  },
}));

const LinkText = styled(Link, {
  name: 'LinkText',
})({
  ...typography.bodySmallRegular,
  fontFamily: 'Satoshi-Variable',
  color: colors.SystemGray900,
  textDecoration: 'underline',
});
