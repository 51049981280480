import axios from 'axios';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';

interface Props {
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

interface TopShowUser {
  id: string;
  avatar: string;
  avatarWebP: string;
}

interface TopShowArtist extends TopShowUser {
  firstName: string;
  lastName: string;
}

interface TopShowVenueOrShowrunner extends TopShowUser {
  name: string;
}

interface TopShow {
  id: string;
  artists: TopShowArtist[];
  name: string;
  showrunnerGroups: TopShowVenueOrShowrunner[];
  venue: TopShowVenueOrShowrunner;
  startTime: string;
}

interface TopShowData {
  data: TopShow[];
  total: number;
}

export const useGetTopShows = ({ setIsLoading }: Props) => {
  const [topShows, setTopShows] = useState<TopShowData>({ data: null, total: 0 });

  const getTopShows = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_PUBLIC_URL}:${process.env.REACT_APP_SERVER_PORT}/api/top-shows?perPage=15&page=1`,
      );
      if (response.status === 200) {
        setIsLoading(false);
        setTopShows({ data: response.data.data, total: response.data.data.length });
      } else {
        setIsLoading(false);
        setTopShows(null);
      }
    } catch {
      setIsLoading(false);
      setTopShows(null);
    }
  }, [setIsLoading]);

  useEffect(() => {
    getTopShows();
  }, [getTopShows]);

  return topShows;
};
