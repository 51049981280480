import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useState,
  useMemo,
  useRef,
  useEffect,
  ReactNode,
} from 'react';
import { ViewType } from '../../../Helpers/shared/Models/ViewType';
import { ShowType } from '../SingleTicketTypeButton';
import { Box, styled, Typography } from '@mui/material';
import { FormInnerCont, FormRowCont, FormSectionCont } from '../Shared/Containers';
import { TitleBar } from '../Shared/TitleBar';
import { ShowFormNavButtons } from './ShowFormNavButtons';
import { Tab, TabPanel, Tabs } from '../../../ui/Tabs/Tabs';
import { DropZone } from '../../../ui/inputs/DropZone';
import { Switch, SwitchControlLabel } from '../../../ui/inputs/Switch';
import { useFormContext, useWatch } from 'react-hook-form';
import { TH_FLYER_TEMPLATES } from '../../../Helpers/flyerConfig';
import { Slider } from '../../../ui/inputs/Slider';
import { ButtonIcon } from '../../../ui/shared/ButtonIcon';
import { Button } from '../../../ui/buttons/Button';
import { isEmpty } from 'lodash';
import { Show } from '../../../Helpers/shared/Models/Show';
import { FlyerVisualizer } from './FlyerVisualizer/FlyerVisualizer';
import { Venue } from '../../../Helpers/shared/Models/Venue';
import { useGetAllArtistsQuery, useGetAllVenuesQuery } from '../../../Redux/API/PublicAPI';
import { SingleOption } from '../../../ui/inputs/ChipMultiselect';
import Avatar from '../../../Images/Avatar.webp';
import html2canvas from 'html2canvas';
import { HiddenField } from '../../../ui/inputs/HiddenField';
import { useDispatch } from 'react-redux';
import { addStatusMessage } from '../../../Redux/UI/UISlice';
import LoadingSpinner from '../../../Components/LoadingSpinner';
import { format } from 'date-fns';

interface ShowFormStep4Props {
  type: ViewType;
  handleChangeStep: (fieldsToCheck: any[], back?: boolean) => void;
  showType: ShowType;
  setOpen: Dispatch<SetStateAction<boolean>>;
  show: Show;
  venue: Venue | undefined;
  tab: 'builder' | 'upload';
  setTab: Dispatch<SetStateAction<'builder' | 'upload'>>;
  selectedFiles: File[];
  setSelectedFiles: Dispatch<SetStateAction<File[]>>;
  previewImage: ReactNode;
  setPreviewImage: Dispatch<SetStateAction<ReactNode>>;
  sliderValue: number;
  setSliderValue: Dispatch<SetStateAction<number>>;
  selectedTemplate: any;
  setSelectedTemplate: Dispatch<SetStateAction<any>>;
}

export const ShowFormStep4: FC<ShowFormStep4Props> = ({
  type,
  handleChangeStep,
  showType,
  setOpen,
  show,
  venue,
  tab,
  setTab,
  selectedFiles,
  setSelectedFiles,
  previewImage,
  setPreviewImage,
  sliderValue,
  setSliderValue,
  selectedTemplate,
  setSelectedTemplate,
}) => {
  const dispatch = useDispatch();
  const flyerRef = useRef();
  const [downloadBtnLoading, setDownloadBtnLoading] = useState(false);
  const createShowForm = useFormContext();
  const [nextDisabled, setNextDisabled] = useState(false);
  const { name, startTime, ticketPrice, minAge, artists, venues, repeatType, repeatingShow } =
    createShowForm.getValues();
  const dayName = startTime && repeatType ? format(startTime, 'EEEE') : '-';
  const isAddressVisible = useWatch({ name: 'showAddress', defaultValue: true });
  const hideArtistImages = useWatch({ name: 'hideArtist', defaultValue: false });
  const artistsDataFromQuery = useGetAllArtistsQuery();
  const venuesDataFromQuery = useGetAllVenuesQuery();
  const [flyerChangeTimer, setFlyerChangeTimer] = useState(true);
  const showVenue = useMemo(() => (venue ? venue : venuesDataFromQuery?.data?.[venues[0].id]), [venuesDataFromQuery]);
  const performersObjects = useMemo(() => {
    if (!isEmpty(artistsDataFromQuery.data) && !isEmpty(artists)) {
      const artistsFullObjects = artists.map((performer: SingleOption) => {
        const singleArtitsObject = artistsDataFromQuery.data[performer.id];
        if (performer.id !== '0') {
          return {
            name: singleArtitsObject?.stagename
              ? singleArtitsObject?.stagename
              : `${singleArtitsObject?.firstname} ${singleArtitsObject?.lastname}`,
            icon: singleArtitsObject?.avatarWebP ?? singleArtitsObject?.avatar,
          };
        } else {
          return {
            name: singleArtitsObject?.stagename
              ? singleArtitsObject?.stagename
              : `${singleArtitsObject?.firstname} ${singleArtitsObject?.lastname}`,
            icon: Avatar,
          };
        }
      });
      return artistsFullObjects;
    } else {
      return [];
    }
  }, [artistsDataFromQuery.data, artists]);

  const handleChangeTab = useCallback((e: React.SyntheticEvent, value: 'builder' | 'upload') => {
    e.preventDefault();
    setTab(value);
  }, []);

  const getSrcFromFile = useCallback(async () => {
    setNextDisabled(true);
    const imageFile = selectedFiles[0];
    const url = window.URL.createObjectURL(imageFile);
    const reader = new FileReader();
    const previewFile = <FlyerPreview src={url} />;
    reader.readAsDataURL(imageFile);
    setTimeout(() => {
      createShowForm.setValue('flyer', reader.result);
      setPreviewImage(previewFile);
      setNextDisabled(false);
    }, 100);
  }, [selectedFiles, createShowForm]);

  const dataURLtoFile = useCallback((dataurl: string, filename: string) => {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }, []);

  const getCanvasFromHtml = useCallback(
    async (element: HTMLElement) => {
      const canvas = await html2canvas(element, {
        scale: 10,
        useCORS: true,
        onclone: (cloneDoc) => {},
      });
      const base64Img = canvas.toDataURL('image/jpeg', 0.7);
      createShowForm.setValue('flyer', base64Img);
      const file = dataURLtoFile(base64Img, 'flyer.jpg');
      setSelectedFiles([file]);
      return file;
    },
    [html2canvas],
  );

  const changeStep = useCallback(
    (back?: boolean) => {
      if (back) {
        handleChangeStep([], true);
      } else {
        createShowForm.trigger('flyer');
        setTimeout(() => {
          handleChangeStep(['flyer']);
        }, 100);
      }
      window.scrollTo(0, 0);
    },
    [tab, selectedFiles],
  );
  const handleDownloadFlyer = useCallback(async () => {
    if (!isEmpty(selectedFiles)) {
      setDownloadBtnLoading(true);
      try {
        const file = await getCanvasFromHtml(flyerRef.current);
        const url = window.URL.createObjectURL(file);
        const link = window.document.createElement('a');
        link.style.display = 'none';
        link.href = url;
        link.download = file.name;
        window.document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        setDownloadBtnLoading(false);
      } catch {
        setDownloadBtnLoading(false);
        dispatch(
          addStatusMessage({
            type: 'error',
            message: 'Something went wrong.',
          }),
        );
      }
    }
  }, [selectedFiles, dispatch, addStatusMessage, flyerRef.current, getCanvasFromHtml]);
  //go back
  useEffect(() => {
    setNextDisabled(true);
    const timeoutId = setTimeout(() => {
      if (flyerRef.current) {
        getCanvasFromHtml(flyerRef.current);
      }
      setNextDisabled(false);
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [flyerRef, selectedTemplate]);

  useEffect(() => {
    if (!isEmpty(selectedFiles)) {
      getSrcFromFile();
    }
  }, [selectedFiles]);

  if (isEmpty(artistsDataFromQuery?.data) || isEmpty(venuesDataFromQuery.data)) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <LoadingSpinner />
      </Box>
    );
  }
  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <TitleBar
        formName="Create Show"
        step={4}
        maxSteps={5}
        stepTitle="Add flyer"
        setOpen={setOpen}
      />
      <FormInnerCont>
        <FormSectionCont>
          <HiddenField {...createShowForm.register('flyer')} />
          {tab === 'builder' ? (
            <FlyerVisualizer
              venueIcon={showVenue?.avatarWebP ? showVenue?.avatarWebP : showVenue?.avatar}
              selectedTemplate={selectedTemplate}
              showName={name}
              repeatingShow={repeatingShow}
              repeatingShowDay={dayName}
              repeatingShowFrequency={repeatType}
              showDate={startTime}
              showLocation={isAddressVisible ? showVenue?.location?.address : ''}
              ticketPrice={ticketPrice}
              ageRequirement={minAge}
              performers={hideArtistImages ? [] : performersObjects}
              flyerRef={flyerRef}
            />
          ) : (
            <FlyerPreviewCont>{previewImage}</FlyerPreviewCont>
          )}

          <Box
            marginBottom="16px"
            width="100%"
          >
            <Button
              disabled={downloadBtnLoading}
              size="large"
              startIcon={
                <ButtonIcon
                  disabled={downloadBtnLoading}
                  size="large"
                  iconName="download"
                />
              }
              fullWidth
              onClick={handleDownloadFlyer}
              isLoading={downloadBtnLoading}
            >
              Download flyer image
            </Button>
          </Box>
          <Tabs
            value={tab}
            onChange={handleChangeTab}
          >
            <Tab
              value="builder"
              label="Flyer Builder"
            />
            <Tab
              value="upload"
              label="Upload Flyer"
            />
          </Tabs>
          <TabPanel
            value={tab}
            index="builder"
          >
            <Box
              display="flex"
              flexDirection="column"
              gap="16px"
              marginTop="24px"
            >
              <FormRowCont>
                {
                  <Box
                    display="flex"
                    flexDirection="column"
                    gap="16px"
                    width="100%"
                  >
                    <Typography
                      width="100%"
                      textAlign="center"
                    >
                      {TH_FLYER_TEMPLATES[sliderValue].name}
                    </Typography>
                    <Slider
                      {...createShowForm.register('flyerDesign')}
                      min={0}
                      value={sliderValue}
                      max={TH_FLYER_TEMPLATES.length - 1}
                      onChange={(e: any) => {
                        setSliderValue(e.target.value);
                        setSelectedTemplate(TH_FLYER_TEMPLATES[e.target.value]);
                      }}
                    />
                  </Box>
                }
              </FormRowCont>
              <FormRowCont>
                <SwitchControlLabel
                  label="Show address on flyer"
                  control={
                    <Switch
                      {...createShowForm.register('showAddress')}
                      defaultChecked={true}
                    />
                  }
                />
              </FormRowCont>
              <FormRowCont>
                <SwitchControlLabel
                  label="Hide artist image"
                  control={<Switch {...createShowForm.register('hideArtist')} />}
                />
              </FormRowCont>
            </Box>
          </TabPanel>
          <TabPanel
            value={tab}
            index="upload"
          >
            <DropZone
              maxFiles={1}
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
            />
          </TabPanel>
        </FormSectionCont>
        <ShowFormNavButtons
          changeStep={changeStep}
          disabledSubmit={nextDisabled}
        />
      </FormInnerCont>
    </Box>
  );
};

const FlyerPreviewCont = styled(Box, {
  name: 'FlyerPreviewCont',
})({
  width: '360px',
  height: '360px',
  position: 'relative',
});

const FlyerPreview = styled('img', {
  name: 'FlyerPreview',
})({
  position: 'absolute',
  left: '0',
  top: '0',
  width: '100%',
  height: '100%',
});
