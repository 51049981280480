import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../hooks';
import { logOut } from '../Redux/User/UserSlice';
import { Navigate, useNavigate } from 'react-router-dom';

export default function Logout() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user.data);
  useEffect(() => {
    if (user.uid) {
      dispatch(logOut());
      navigate('/');
    }
  }, []);

  return user.uid ? (
    <></>
  ) : (
    <>
      <Navigate to="/" />
    </>
  );
}
