import { Box, MenuItem, styled } from '@mui/material';
import React, { FC, useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { TH_GENRES } from '../../../Helpers/configConstants';
import { DataCy } from '../../../Helpers/DataCy';
import { Select, TextField } from '../../../ui/inputs/TextField';
import { ArtistFormData } from '../../../Forms/AccountInfoForms/Artist/ArtistFormData';
import {
  FormTitleWithSubtitleContainer,
  FormSectionContainer,
  FormInputGroupWrapper,
  FormInputGroupElementWrapper,
  FormMainContainerColumn,
} from '../Shared/Containers';
import { FormToggleGroup, Toggle } from '../Shared/Other';
import { FormSectionTitle, FormSectionSubtitle, GrayText } from '../Shared/RegistrationTypographies';
import { LocationField } from '../../../ui/inputs/LocationField';

export const ArtistPersonalDetailsForm: FC = () => {
  const artistForm = useFormContext<ArtistFormData.Step1Values>();
  const signed = artistForm.watch('recordLabel.signed');

  const handleChangeSigned = useCallback(
    (event: React.MouseEvent<HTMLElement> | any, value: boolean) => {
      artistForm.setValue('recordLabel.signed', value, { shouldDirty: true });
      if (value === false) {
        artistForm.setValue('recordLabel.label', null, { shouldDirty: true });
      } else {
        artistForm.setValue('recordLabel.label', '', { shouldDirty: true });
      }
    },
    [artistForm],
  );

  return (
    <>
      <FormSectionContainer>
        <FormSectionTitle>Who are you?</FormSectionTitle>
        <FormInputGroupWrapper>
          <FormInputGroupElementWrapper>
            <TextField
              {...artistForm.register('firstName')}
              type="text"
              label="First Name"
              placeholder="Enter first name"
              error={!!artistForm.formState.errors?.firstName?.message}
              helperText={
                !!artistForm.formState.errors?.firstName?.message && (
                  <>{artistForm.formState.errors?.firstName?.message}</>
                )
              }
            />
          </FormInputGroupElementWrapper>
          <FormInputGroupElementWrapper>
            <TextField
              {...artistForm.register('lastName')}
              type="text"
              label="Last Name"
              placeholder="Enter last name"
              error={!!artistForm.formState.errors?.lastName?.message}
              helperText={
                !!artistForm.formState.errors?.lastName?.message && (
                  <>{artistForm.formState.errors?.lastName?.message}</>
                )
              }
            />
          </FormInputGroupElementWrapper>
        </FormInputGroupWrapper>
        <TextField
          {...artistForm.register('stageName')}
          type="text"
          label={
            <>
              Stage Name <GrayText>(Optional)</GrayText>
            </>
          }
          placeholder="Enter stage name"
          error={!!artistForm.formState.errors?.stageName?.message}
          helperText={
            !!artistForm.formState.errors?.stageName?.message && <>{artistForm.formState.errors?.stageName?.message}</>
          }
        />
        <FormInputGroupWrapper>
          <FormInputGroupElementWrapper>
            <Select
              {...artistForm.register('genre')}
              type="text"
              label="Genre"
              error={!!artistForm.formState.errors?.genre?.message}
              helperText={
                !!artistForm.formState.errors?.genre?.message && <>{artistForm.formState.errors?.genre?.message}</>
              }
              select
              placeholder="Select"
              defaultValue={artistForm.getValues('genre')}
            >
              <MenuItem
                value="none"
                disabled
              >
                Select
              </MenuItem>
              {TH_GENRES.map((genre) => {
                return (
                  <MenuItem
                    key={genre}
                    value={genre}
                  >
                    {genre}
                  </MenuItem>
                );
              })}
            </Select>
          </FormInputGroupElementWrapper>
          <FormInputGroupElementWrapper>
            <TextField
              {...artistForm.register('subGenre')}
              type="text"
              label={
                <>
                  Any sub-genre? <GrayText>(Optional)</GrayText>
                </>
              }
              placeholder="Enter sub genre"
              error={!!artistForm.formState.errors?.subGenre?.message}
              helperText={
                !!artistForm.formState.errors?.subGenre?.message && (
                  <>{artistForm.formState.errors?.subGenre?.message}</>
                )
              }
            />
          </FormInputGroupElementWrapper>
        </FormInputGroupWrapper>
      </FormSectionContainer>
      <FormSectionContainer>
        <FormSectionTitle>
          Are you signed? <GrayText>(Optional)</GrayText>
        </FormSectionTitle>
        <ToggleWithInputContainer>
          <Controller
            name="recordLabel.signed"
            control={artistForm.control}
            render={(field) => {
              return (
                <FormToggleGroup
                  {...field}
                  onChange={handleChangeSigned}
                  exclusive
                  aria-label="recordLabel.signed"
                >
                  <Toggle
                    value={true}
                    selected={artistForm.getValues('recordLabel.signed')}
                    data-cy={DataCy.button('imSigned')}
                  >
                    Yes
                  </Toggle>
                  <Toggle
                    value={false}
                    selected={!artistForm.getValues('recordLabel.signed')}
                    data-cy={DataCy.button('imNotSigned')}
                  >
                    No
                  </Toggle>
                </FormToggleGroup>
              );
            }}
          />
          <FormMainContainerColumn>
            <TextField
              {...artistForm.register('recordLabel.label')}
              disabled={signed === false}
              type="text"
              label="Your label"
              placeholder="Enter your label"
              error={!!artistForm.formState.errors?.recordLabel?.label?.message}
              helperText={
                !!artistForm.formState.errors?.recordLabel?.label?.message && (
                  <>{artistForm.formState.errors?.recordLabel?.label?.message}</>
                )
              }
            />
          </FormMainContainerColumn>
        </ToggleWithInputContainer>
      </FormSectionContainer>
      <FormSectionContainer>
        <FormTitleWithSubtitleContainer>
          <FormSectionTitle>Where do you want to play?</FormSectionTitle>
          <FormSectionSubtitle>
            Your primary city is where you play regularly, when your secondary city is where you play 2nd most often.
          </FormSectionSubtitle>
        </FormTitleWithSubtitleContainer>
        <FormInputGroupWrapper>
          <FormInputGroupElementWrapper>
            <LocationField
              fieldName={'primaryCity'}
              label="Primary City"
              placeholder="Enter your primary city"
              types={['locality']}
            />
          </FormInputGroupElementWrapper>
          <FormInputGroupElementWrapper>
            <LocationField
              fieldName={'secondaryCity'}
              label="Secondary City"
              placeholder="Enter your secondary city"
              types={['locality']}
            />
          </FormInputGroupElementWrapper>
        </FormInputGroupWrapper>
      </FormSectionContainer>
      <FormSectionContainer>
        <FormTitleWithSubtitleContainer>
          <FormSectionTitle>How Should Bookers Reach You?</FormSectionTitle>
        </FormTitleWithSubtitleContainer>
        <FormInputGroupWrapper>
          <FormInputGroupElementWrapper>
            <TextField
              {...artistForm.register('contactEmail')}
              type="email"
              label="Contact Email"
              placeholder="Enter your email"
              error={!!artistForm.formState.errors?.contactEmail?.message}
              helperText={
                !!artistForm.formState.errors?.contactEmail?.message && (
                  <>{artistForm.formState.errors?.contactEmail?.message}</>
                )
              }
            />
          </FormInputGroupElementWrapper>
          <FormInputGroupElementWrapper>
            <TextField
              {...artistForm.register('contactPhone')}
              type="text"
              label="Contact Phone"
              placeholder="Enter your phone number"
              error={!!artistForm.formState.errors?.contactPhone?.message}
              helperText={
                !!artistForm.formState.errors?.contactPhone?.message && (
                  <>{artistForm.formState.errors?.contactPhone?.message}</>
                )
              }
            />
          </FormInputGroupElementWrapper>
        </FormInputGroupWrapper>
      </FormSectionContainer>
    </>
  );
};

const ToggleWithInputContainer = styled(Box, {
  name: 'ToggleWithInputContainer',
})({
  display: 'flex',
  gap: '20px',
  width: '100%',
});
