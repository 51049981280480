import { zodResolver } from '@hookform/resolvers/zod';
import axios from 'axios';
import React, { useCallback, useState, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { PromoterFormData } from '../../Forms/AccountInfoForms/Promoter/PromoterFormData';
import { PromoterPersonalDetailsForm } from '../../Forms/AccountInfoForms/Promoter/PromoterPersonalDetailsForm';
import { PromoterPublicProfileForm } from '../../Forms/AccountInfoForms/Promoter/PromoterPublicProfileForm';
import { ArtistsWorkedWithForm } from '../../Forms/AccountInfoForms/Promoter/ArtistsWorkedWithForm';
import { useAppSelector, useAppDispatch } from '../../hooks';
import {
  EditProfileHeadlineContainer,
  EditProfileFormContainer,
  EditProfileInnerContainer,
  StyledTabs,
  StyledTab,
  TabLabel,
  EditProfileTitle,
  StyledButton,
  CancelButton,
  TitleAndButtonsContainer,
} from './Shared/Styles';
import { addStatusMessage } from '../../Redux/UI/UISlice';
import { TabPanel } from './Shared/Other';
import { useGetAllArtistsQuery, useGetAllShowrunnerGroupsQuery } from '../../Redux/API/PublicAPI';
import Lo from 'lodash';
import { SRGroupMemberType } from '../../Redux/API/ShowrunnerAPI';
import { HatchlinksForm } from '../../Forms/AccountInfoForms/HatchLinks/HatchlinksForm';
import { useEditPromoterProfileMutation } from '../../Redux/API/UserAPI';
import { CancelModal } from './CancelModal/CancelModal';
import { ImageUploader } from '../../ui/shared/ImageUploader';
import { HatchlinksFormData } from '../../Forms/AccountInfoForms/HatchLinks/HatchlinksFormData';
import { ShareHatchLinks } from '../ShareHatchLinks/ShareHatchLinks';
import { ViewType } from '../../Helpers/shared/Models/ViewType';

interface ShowrunnerGroupsData {
  id: string;
  name?: string;
  genre?: string;
  subgenre?: string;
  contact?: {
    phone?: string;
    email?: string;
  };
  role: string;
}

export const PromoterForm = (props: { id?: string; startingTab: string }) => {
  const user = useAppSelector((select) => select.user.data);
  const srGroups = useGetAllShowrunnerGroupsQuery();
  const artistList = useGetAllArtistsQuery();
  const showrunner = srGroups.data?.[props.id];
  const [avatar, setAvatar] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentTab, setCurrentTab] = React.useState(props.startingTab || 'personalDetails');
  const navigate = useNavigate();
  const dipatch = useAppDispatch();
  const [editPromoterProfile, editPromoterProfileStatus] = useEditPromoterProfileMutation();
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [forceDirty, setForceDirty] = useState(false);
  const [shareHatchLinkModalOpen, setShareHatchLinkModalOpen] = useState(false);

  const isDirty = () => {
    return promoterForm.formState.isDirty || forceDirty;
  };

  const members = showrunner?.members;
  const artistMembers = useMemo(
    () => (members ?? []).filter((member) => Lo.get(member, 'type') !== SRGroupMemberType.Admin),
    [members],
  );
  const artistInfo = useMemo(() => {
    var artistInfo: any[] = [];
    artistMembers.forEach((member) => {
      var singleArtist = artistList.data?.[member.id];
      artistInfo.push({
        label: singleArtist?.stagename || singleArtist?.firstname + ' ' + singleArtist?.lastname,
        id: member.id,
        avatar: singleArtist?.avatar,
      });
    });
    return artistInfo;
  }, [artistMembers]);

  const initialPromoterFormValues: PromoterFormData.ValuesFull = {
    groupName: showrunner?.name ?? '',
    genre: showrunner?.genre ?? 'none',
    subGenre: showrunner?.subgenre ?? null,
    firstName: user.firstname ?? '',
    lastName: user.lastname ?? '',
    contact: {
      phone: showrunner?.contact?.phone ?? '',
      email: showrunner?.contact?.email ?? '',
    },
    primaryCity: showrunner?.meta?.primaryCity?.city
      ? showrunner?.meta?.primaryCity
      : { city: showrunner?.primaryCity ?? '', displayAddress: showrunner?.primaryCity ?? '' },
    secondaryCity: showrunner?.meta?.secondaryCity?.city
      ? showrunner?.meta?.secondaryCity
      : showrunner?.secondaryCity
        ? { city: showrunner?.secondaryCity, displayAddress: showrunner?.secondaryCity }
        : null,
    socials: {
      instagram: showrunner?.socials?.instagram ?? null,
      tiktok: showrunner?.socials?.tiktokLink ?? null,
      spotify: showrunner?.socials?.spotifyLink ?? null,
      youtube: showrunner?.socials?.youtubeLink ?? null,
    },
    about: showrunner?.bio,
    roster: artistMembers.map((member) => member.id),
    username: showrunner?.username,
    externalLinks: showrunner?.externalLinks,
    customOptions: {
      alternativeHatchLinkLabel: showrunner?.customOptions?.alternativeHatchLinkLabel ?? false,
      colors: {},
    },
  };

  const promoterForm = useForm({
    resolver: zodResolver(PromoterFormData.formSchemaFull),
    defaultValues: initialPromoterFormValues,
    values: initialPromoterFormValues,
    mode: 'all',
  });

  const submitPromoter = async (data: PromoterFormData.ValuesFull) => {
    try {
      const formData = new FormData();
      formData.append(
        'body',
        JSON.stringify({
          id: props.id,
          name: data.groupName,
          genre: data.genre,
          subgenre: data.subGenre,
          showrunner: {
            firstName: data.firstName,
            lastName: data.lastName,
          },
          contact: {
            phone: data.contact.phone,
            email: data.contact.email,
          },
          socials: {
            instagramLink: data.socials.instagram ?? null,
            youtubeLink: data.socials.youtube ?? null,
            spotifyLink: data.socials.spotify ?? null,
            tikTokLink: data.socials.tiktok ?? null,
          },
          primaryCity: {
            name: data.primaryCity.city,
            meta: data.primaryCity,
          },
          secondaryCity: data.secondaryCity?.displayAddress
            ? {
                name: data.secondaryCity.city,
                meta: data.secondaryCity,
              }
            : null,
          about: data.about,
          roster: [...data.roster],
          username: data.username,
          hatchLinks: data.externalLinks,
          customOptions: {
            alternativeHatchLinkLabel: data.customOptions.alternativeHatchLinkLabel,
            colors: data.customOptions.colors,
          },
        }),
      );
      formData.append('avatar', avatar);
      const response = await editPromoterProfile(formData);

      if ('error' in response) {
        window.alert('Please fix any errors before saving.');
        setCurrentTab('personalDetails');
      } else {
        setForceDirty(false);
        promoterForm.reset(promoterForm.getValues());
        dipatch(addStatusMessage({ type: 'success', message: 'Profile successfully updated' }));
      }
    } catch (error: unknown) {
      if (error instanceof axios.AxiosError && error.response.status === 413) {
        window.alert('Uploaded images are too large');
        setCurrentTab('publicProfile');
      } else {
        setCurrentTab('personalDetails');
        window.alert('Unknown server error');
        console.log(error);
      }
    }
  };

  const handleSaveChanges = () => {
    promoterForm.trigger();
    const data = promoterForm.getValues();
    // Validate each tab manually so we can set the correct tab if there's an error
    if (!PromoterFormData.step1Schema.safeParse(data).success) return setCurrentTab('personalDetails');
    if (!PromoterFormData.step2Schema.safeParse(data).success) return setCurrentTab('publicProfile');
    if (!PromoterFormData.step3Schema.safeParse(data).success) return setCurrentTab('artistsWorkedWith');
    if (!HatchlinksFormData.formSchema.safeParse(data).success) return setCurrentTab('hatchlinks');
    if (currentTab === 'hatchlinks') {
      setShareHatchLinkModalOpen(true);
    }
    setLoading(true);
    promoterForm.handleSubmit(submitPromoter)();
    setLoading(false);
  };

  const handleChangeTab = useCallback((e: React.SyntheticEvent, newTab: string) => {
    e.preventDefault();
    setCurrentTab(newTab);
  }, []);

  return (
    <FormProvider {...promoterForm}>
      <EditProfileHeadlineContainer>
        <EditProfileInnerContainer>
          <TitleAndButtonsContainer>
            <EditProfileTitle>Edit your profile</EditProfileTitle>
            <CancelButton
              size="large"
              onClick={() => (isDirty() ? setCancelModalOpen(true) : navigate(-1))} //navigate(-1) is the same as window.history.back() but rerenders the route instead of reloading the page
            >
              Back
            </CancelButton>
            <StyledButton
              isPrimary
              size="large"
              onClick={handleSaveChanges}
              isLoading={loading}
              disabled={!isDirty() || loading}
            >
              Save changes
            </StyledButton>
          </TitleAndButtonsContainer>
        </EditProfileInnerContainer>
        <EditProfileInnerContainer>
          <StyledTabs
            variant="scrollable" //support for mobile
            value={currentTab}
            onChange={handleChangeTab}
          >
            <StyledTab
              value="personalDetails"
              label={<TabLabel>Personal Details</TabLabel>}
            />
            <StyledTab
              value="publicProfile"
              label={<TabLabel>Public Profile</TabLabel>}
            />
            <StyledTab
              value="artistsWorkedWith"
              label={<TabLabel>Artists you work with</TabLabel>}
            />
            <StyledTab
              value="hatchlinks"
              label={<TabLabel>Hatchlink</TabLabel>}
            />
            <StyledTab
              value="photos"
              label={<TabLabel>Your photos</TabLabel>}
            />
          </StyledTabs>
        </EditProfileInnerContainer>
      </EditProfileHeadlineContainer>
      <form onSubmit={promoterForm.handleSubmit(submitPromoter)}>
        <TabPanel
          index="personalDetails"
          value={currentTab}
        >
          <EditProfileFormContainer>
            <EditProfileInnerContainer>
              <PromoterPersonalDetailsForm />
            </EditProfileInnerContainer>
          </EditProfileFormContainer>
        </TabPanel>
        <TabPanel
          index="publicProfile"
          value={currentTab}
        >
          <EditProfileFormContainer>
            <EditProfileInnerContainer>
              <PromoterPublicProfileForm
                setAvatar={setAvatar}
                selectedAvatar={avatar}
                defaultAvatar={showrunner?.avatarWebP}
                setForceDirty={setForceDirty}
              />
            </EditProfileInnerContainer>
          </EditProfileFormContainer>
        </TabPanel>
        <TabPanel
          index="artistsWorkedWith"
          value={currentTab}
        >
          <EditProfileFormContainer>
            <EditProfileInnerContainer>
              <ArtistsWorkedWithForm
                defaultRoster={artistInfo}
                setForceDirty={setForceDirty}
              />
            </EditProfileInnerContainer>
          </EditProfileFormContainer>
        </TabPanel>
        <TabPanel
          index="hatchlinks"
          value={currentTab}
        >
          <EditProfileFormContainer>
            <EditProfileInnerContainer>
              <HatchlinksForm
                setForceDirty={setForceDirty}
                profileName={showrunner?.name}
                avatarUrl={showrunner?.avatarWebP}
                colors={showrunner?.customOptions?.colors}
                viewType={ViewType.SRGroup}
              />
            </EditProfileInnerContainer>
          </EditProfileFormContainer>
        </TabPanel>
        <TabPanel
          index="photos"
          value={currentTab}
        >
          <EditProfileFormContainer>
            <EditProfileInnerContainer>
              {showrunner && (
                <ImageUploader
                  maxNumber={5}
                  entityType={'showrunner'}
                  resourceId={showrunner._key}
                  field="images"
                  forceDirty={setForceDirty}
                />
              )}
            </EditProfileInnerContainer>
          </EditProfileFormContainer>
        </TabPanel>
      </form>
      <CancelModal
        open={cancelModalOpen}
        setOpen={setCancelModalOpen}
      />
      <ShareHatchLinks
        open={shareHatchLinkModalOpen}
        setOpen={setShareHatchLinkModalOpen}
        link={
          showrunner?.username
            ? `https://tunehatch.link/${showrunner.username}`
            : `https://tunehatch.com/hl/showrunner/${showrunner?._key}`
        }
      />
    </FormProvider>
  );
};
