interface CalTagInner {
  label: string;
  tag: string;
  color: string;
}

export const DEFAULT_CALTAGS: { [key: string]: CalTagInner } = {
  green: {
    label: 'Green',
    tag: 'default',
    color: '#74c680',
  },
  red: {
    label: 'Red',
    tag: 'unpublished',
    color: '#ff7979',
  },
  blue: {
    label: 'Blue',
    tag: 'needs_attention',
    color: '#6495ed',
  },
  sand: {
    label: 'Sand',
    tag: 'sand',
    color: '#c2a22e',
  },
  navy: {
    label: 'Navy',
    tag: 'navy',
    color: '#002e72',
  },
  deep_red: {
    label: 'Deep Red',
    tag: 'deep_red',
    color: '#cb333b',
  },
  turquoise: {
    label: 'Turquoise',
    tag: 'turquoise',
    color: '#408091',
  },
  grey: {
    label: 'Grey',
    tag: 'grey',
    color: 'gray',
  },
};
