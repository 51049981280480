import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { SERVER_URL } from '../../Helpers/configConstants';

export enum RTKTags {
  VenueMetaCredentials = 'VenueMetaCredentials',
}
export const RTKTagTypes = [
  'Show',
  'ExternalShow',
  'Artist',
  'Venue',
  'SRG',
  'Ticket',
  'Notification',
  'Region',
  'Message',
  'CashLedger',
  RTKTags.VenueMetaCredentials,
] as const;

// initialize an empty api service that we'll inject endpoints into later as needed
export const baseAPI = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: SERVER_URL + '/api' }),
  tagTypes: RTKTagTypes,
  endpoints: () => ({}),
});
