import { Box, FormControlLabel, FormGroup, styled, Switch, Typography } from '@mui/material';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Button } from '../../ui/buttons/Button';
import { useGetAllVenuesQuery } from '../../Redux/API/PublicAPI';
import { useUpdateShowCreatePermissionsMutation } from '../../Redux/API/VenueAPI';
import { useAppDispatch } from '../../hooks';
import { openModal } from '../../Redux/UI/UISlice';
import { colors } from '../../ui/shared/Colors';
import { typography } from '../../ui/shared/TypographySharedElements';

interface ShowManagementPermissionProps {
  venueID: string;
}

export const ShowManagementPermission: FC<ShowManagementPermissionProps> = ({ venueID }) => {
  const venues = useGetAllVenuesQuery();
  const venue = venues?.data?.[venueID];
  const dispatch = useAppDispatch();
  const [toggleValue, setToggleValue] = useState(Boolean(venue?.isOpenForManagers));
  const [isSuccess, setIsSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [updateShowCreatePermissions] = useUpdateShowCreatePermissionsMutation();

  const handleSubmit = useCallback(() => {
    setLoading(true);
    updateShowCreatePermissions({ venueId: venueID, applyToExistingShows: true, isOpenForManagers: toggleValue })
      .then(() => {
        setIsSuccess(true);
        setLoading(false);
      })
      .catch(() => {
        setIsError(true);
        setLoading(false);
      });
  }, [venueID, toggleValue, updateShowCreatePermissions]);

  useEffect(() => {
    if (isSuccess === true) {
      setTimeout(() => {
        setIsSuccess(false);
      }, 3000);
    }
  }, [isSuccess]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      padding="40px"
      gap="16px"
    >
      <Box
        display="flex"
        justifyContent="flex-end"
        width="100%"
      >
        <CloseIcon
          className="material-symbols-outlined"
          onClick={() => {
            dispatch(openModal({ status: false }));
          }}
        >
          close
        </CloseIcon>
      </Box>
      <UpdateShowCreatePermissionsText>
        Remember that your choice will affect the show's that were already created.
      </UpdateShowCreatePermissionsText>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              defaultChecked={toggleValue}
              onChange={() => setToggleValue((toggle) => !toggle)}
              color="warning"
            />
          }
          label="Managers can create shows"
        />
      </FormGroup>
      <Button
        isPrimary
        disabled={isSuccess || loading}
        size="large"
        isLoading={loading}
        onClick={handleSubmit}
      >
        Save changes
      </Button>
      {isSuccess && (
        <SuccessMessage>
          You have succesfully changed the setting. If you want to change it again, please wait a few seconds.
        </SuccessMessage>
      )}
      {isError && <ErrorMessage>Something went wrong! Please try again.</ErrorMessage>}
    </Box>
  );
};

const CloseIcon = styled('i', {
  name: 'CloseIcon',
})({
  fontSize: '24px',
  width: '24px',
  height: '24px',
  color: colors.SystemGray500,
});

const UpdateShowCreatePermissionsText = styled(Typography, {
  name: 'UpdateShowCreatePermissionsText',
})({
  color: colors.SystemGray900,
  ...typography.headlineSmall,
});

const SuccessMessage = styled(Typography, {
  name: 'SuccessMessage',
})({
  color: colors.THOrange,
  ...typography.bodyLargeBold,
});

const ErrorMessage = styled(Typography, {
  name: 'ErrorMessage',
})({
  color: colors.AlertRed,
  ...typography.bodyLargeBold,
});
