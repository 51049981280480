import React, { useCallback } from 'react';
import { format } from 'date-fns';

export const useOpenBookDemoLink = () => {
  const date = new Date();
  const linkDate = format(date, 'yyyy-MM');
  const bookDemoLink = `https://calendly.com/nathan-tunehatch/30min?month=${linkDate}`;

  const openBookDemoLink = useCallback(() => {
    window.open(bookDemoLink);
  }, [bookDemoLink]);
  return openBookDemoLink;
};
