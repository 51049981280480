import React, { FC, useCallback, useState } from 'react';
import { FieldErrorsImpl, useFormContext } from 'react-hook-form';
import { TextField } from '../../../ui/inputs/TextField';
import { SingleOption } from '../../../ui/inputs/ChipMultiselect';
import { FormRowCont, OffPlatformFormPartCont } from '../Shared/Containers';
import { OffPlatformSubTitle, OffPlatformTitle } from '../Shared/TextElements';
import { OffPlatformAvatar } from '../Shared/Other';
import { HiddenField } from '../../../ui/inputs/HiddenField';
import { IconButton } from '../../../ui/buttons/IconButton';
import { Button } from '../../../ui/buttons/Button';

interface OffPlatformArtistFormProps {
  singleOption: SingleOption;
  index: number;
}

export const OffPlatformArtistForm: FC<OffPlatformArtistFormProps> = ({ singleOption, index }) => {
  const [isHidden, setIsHidden] = useState(false);
  const createShowForm = useFormContext();
  const artistErrors = createShowForm.formState.errors as unknown as FieldErrorsImpl<{
    artists: {
      stageName: string;
      email: string;
    }[];
  }>;

  const handleHide = useCallback(() => {
    createShowForm.trigger([`artists[${index}].stageName`, `artists[${index}].email`]);
    if (!artistErrors?.artists?.[index]?.stageName?.message && !artistErrors?.artists?.[index]?.email?.message) {
      setIsHidden(true);
    }
  }, [artistErrors, createShowForm]);

  return (
    <OffPlatformFormPartCont hiddenElements={isHidden}>
      <FormRowCont>
        <OffPlatformAvatar src={singleOption.avatar} />
        <OffPlatformTitle>{`Off platform: ${singleOption.label}`}</OffPlatformTitle>
        {isHidden && (
          <IconButton
            isPrimary
            disabled={false}
            size="small"
            onClick={() => {
              setIsHidden(false);
            }}
            iconName="Edit"
            sx={{ marginLeft: 'auto' }}
          />
        )}
      </FormRowCont>
      <FormRowCont hidden={isHidden}>
        <OffPlatformSubTitle>
          This artist is not yet on TuneHatch. Please enter their information to generate their Artist Profile.
        </OffPlatformSubTitle>
      </FormRowCont>
      <FormRowCont hidden={isHidden}>
        <HiddenField
          {...createShowForm.register(`artists[${index}].id`)}
          value={'0'}
        />
        <TextField
          {...createShowForm.register(`artists[${index}].stageName`)}
          type="text"
          label="Stage Name"
          placeholder="Enter stage name"
          defaultValue={singleOption.label}
          error={!!artistErrors?.artists?.[index]?.stageName?.message}
          helperText={
            !!artistErrors?.artists?.[index]?.stageName?.message && (
              <>{artistErrors?.artists?.[index]?.stageName?.message}</>
            )
          }
        />
        <TextField
          {...createShowForm.register(`artists[${index}].email`)}
          type="text"
          label="Email"
          placeholder="Enter email"
          error={!!artistErrors?.artists?.[index]?.email?.message}
          helperText={
            !!artistErrors?.artists?.[index]?.email?.message && <>{artistErrors?.artists?.[index]?.email?.message}</>
          }
        />
      </FormRowCont>
      <FormRowCont>
        <Button
          isPrimary
          disabled={false}
          onClick={handleHide}
          size="small"
        >
          Submit
        </Button>
      </FormRowCont>
    </OffPlatformFormPartCont>
  );
};
