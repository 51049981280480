import { Box, styled, Typography } from '@mui/material';
import React, { FC, useRef, useEffect, useState, Ref, SyntheticEvent } from 'react';
import { typography } from '../../../../ui/shared/TypographySharedElements';
import dayjs from 'dayjs';
import { colors } from '../../../../ui/shared/Colors';
import ChickLogo from '../../../../Images/Avatar.webp';

interface PerformerInfo {
  name: string;
  icon: string;
}

interface FlyerVisualizerProps {
  selectedTemplate: any;
  showName: string;
  showDate: string;
  showLocation: string;
  repeatingShow: boolean;
  repeatingShowDay?: string;
  repeatingShowFrequency?: string;
  venueIcon: string;
  ticketPrice: string | number;
  ageRequirement: string | number;
  performers: PerformerInfo[];
  flyerRef: Ref<HTMLElement>;
}

export const FlyerVisualizer: FC<FlyerVisualizerProps> = ({
  selectedTemplate,
  showName,
  showDate,
  repeatingShow,
  repeatingShowDay,
  repeatingShowFrequency,
  venueIcon,
  showLocation,
  ticketPrice,
  ageRequirement,
  performers,
  flyerRef,
}) => {
  const [titleFontSize, setTitleFontSize] = useState(28);
  const titleRef = useRef<HTMLElement>(null);
  const [performerTileSize, setPerformerTileSize] = useState(100);
  const [performerFontSize, setPerformerFontSize] = useState(14);
  const [performerLineHeight, setPerformerLineHeight] = useState(16);
  const performersRowRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const resizeTitle = () => {
      if (titleRef.current) {
        let fontSize = 28;
        titleRef.current.style.fontSize = `${fontSize}px`;
        titleRef.current.style.lineHeight = `${fontSize * 1.14}px`;

        while (titleRef.current.offsetHeight > 80 && fontSize > 10) {
          fontSize -= 0.5;
          titleRef.current.style.fontSize = `${fontSize}px`;
          titleRef.current.style.lineHeight = `${fontSize * 1.14}px`;
        }

        setTitleFontSize(fontSize);
      }
    };

    resizeTitle();
    window.addEventListener('resize', resizeTitle);

    return () => {
      window.removeEventListener('resize', resizeTitle);
    };
  }, []);

  const showTime = dayjs(showDate).format('h:mmA');
  showDate = dayjs(showDate).format('MMMM D');

  useEffect(() => {
    const resizePerformerTiles = () => {
      if (performersRowRef.current) {
        let tileSize = 75;
        let fontSize = 14;
        let lineHeight = 16;
        const maxHeight = 160;
        const containerWidth = performersRowRef.current.offsetWidth;
        const tilesPerRow = Math.floor(containerWidth / tileSize);
        const rows = Math.ceil(performers.length / tilesPerRow);

        while ((tileSize * rows > maxHeight || tileSize * tilesPerRow > containerWidth) && tileSize > 50) {
          tileSize -= 1;
          fontSize = Math.max(8, fontSize - 0.2);
          lineHeight = Math.max(10, lineHeight - 0.2);
        }

        setPerformerTileSize(tileSize);
        setPerformerFontSize(fontSize);
        setPerformerLineHeight(lineHeight);
      }
    };

    resizePerformerTiles();
    window.addEventListener('resize', resizePerformerTiles);

    return () => {
      window.removeEventListener('resize', resizePerformerTiles);
    };
  }, [performers]);

  return (
    <FlyerPreviewContainer ref={flyerRef}>
      <FlyerBackground
        src={selectedTemplate.src}
        alt="flyer"
      />
      <FlyerOverlay>
        <FlyerInformationRow>
          <FlyerInformationColumn>
            <FlyerInformationText fontColor={selectedTemplate.fontColor}>
              {!repeatingShow ? showDate : ''}
            </FlyerInformationText>
            <FlyerInformationText fontColor={selectedTemplate.fontColor}>
              {!repeatingShow ? showTime : showTime}
            </FlyerInformationText>
          </FlyerInformationColumn>
          {venueIcon && (
            <FlyerVenueIcon
              src={venueIcon}
              alt="venue icon"
              onError={(event) => {
                const target = event.target as HTMLImageElement;
                target.src = ChickLogo;
                target.onerror = null;
              }}
            />
          )}
          <FlyerInformationColumn>
            {ticketPrice && (
              <FlyerInformationText fontColor={selectedTemplate.fontColor}>${ticketPrice} tickets</FlyerInformationText>
            )}
            <FlyerInformationText fontColor={selectedTemplate.fontColor}>{ageRequirement}+</FlyerInformationText>
          </FlyerInformationColumn>
        </FlyerInformationRow>
        <FlyerTitle
          ref={titleRef}
          fontColor={selectedTemplate.fontColor}
          fontSize={titleFontSize}
        >
          {showName}
        </FlyerTitle>
        <FlyerInformationText fontColor={selectedTemplate.fontColor}>{showLocation}</FlyerInformationText>
        <FlyerInformationRow
          ref={performersRowRef}
          paddingTop={'16px'}
          maxHeight={'185px'}
          paddingLeft={'16px'}
          paddingRight={'16px'}
          wrap
        >
          {performers.map((performer, index) => {
            //limit to 18 performers
            if (index < 18 && performer.icon) {
              return (
                <FlyerPerformerIconContainer
                  key={index}
                  size={performerTileSize}
                >
                  <FlyerPerformerIcon
                    src={performer.icon}
                    alt={`${performer.name} icon`}
                    size={performerTileSize}
                    onError={(e: SyntheticEvent<HTMLImageElement>) => {
                      const targetsParent = e.currentTarget.parentElement;
                      targetsParent.style.display = 'none';
                    }}
                  />
                </FlyerPerformerIconContainer>
              );
            }
            return null; // Return null for performers beyond index 17
          })}
        </FlyerInformationRow>
      </FlyerOverlay>
    </FlyerPreviewContainer>
  );
};

const FlyerPreviewContainer = styled(Box, {
  name: 'FlyerPreviewContainer',
})({
  width: '360px',
  height: '360px',
  position: 'relative',
  marginLeft: 'auto',
  marginRight: 'auto',
});

const FlyerBackground = styled('img', {
  name: 'FlyerBackground',
})({
  width: '100%',
  height: '100%',
  position: 'absolute',
});

const FlyerOverlay = styled(Box, {
  name: 'FlyerOverlay',
})({
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
});

const FlyerTitle = styled(Typography, {
  name: 'FlyerTitle',
  shouldForwardProp: (prop) => prop !== 'fontColor' && prop !== 'fontSize',
})<{ fontColor: string; fontSize: number }>(({ fontColor, fontSize }) => ({
  ...typography.headlineSmall,
  lineHeight: `${fontSize * 1.14}px`, // Maintain the original line-height ratio
  fontSize: `${fontSize}px`,
  padding: '8px',
  paddingLeft: '18px',
  paddingRight: '18px',
  textAlign: 'center',
  width: '100%',
  color: fontColor,
  overflow: 'hidden',
}));

const FlyerInformationRow = styled(Box, {
  name: 'FlyerInformationRow',
})<{ maxHeight?: string; wrap?: boolean }>(({ maxHeight, wrap }) => ({
  display: 'flex',
  width: '100%',
  flexWrap: wrap ? 'wrap' : 'nowrap',
  marginLeft: '16px',
  marginRight: '16px',
  gap: '8px',
  justifyContent: 'center',
  alignItems: 'center',
  maxHeight: maxHeight || 'auto',
}));

const FlyerInformationColumn = styled(Box, {
  name: 'FlyerInformationColumn',
})({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

const FlyerInformationText = styled(Typography, {
  name: 'FlyerInformationText',
  shouldForwardProp: (prop) => prop !== 'fontColor',
})<{ fontColor: string }>(({ fontColor }) => ({
  ...typography.bodyLargeRegular,
  textTransform: 'capitalize',
  lineHeight: '16px',
  width: '100%',
  fontSize: '14px',
  textAlign: 'center',
  color: fontColor,
}));

const FlyerVenueIcon = styled('img', {
  name: 'FlyerVenueIcon',
})({
  width: '72px',
  height: '72px',
  flexShrink: 0,
  backgroundColor: 'white',
  objectFit: 'cover',
  borderRadius: '100%',
});

const FlyerPerformerIconContainer = styled(Box, {
  name: 'FlyerPerformerIconContainer',
})<{ size: number }>(({ size }) => ({
  width: `${size}px`,
  height: `${size}px`,
  backgroundColor: 'white',
  borderRadius: '10px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  overflow: 'hidden',
}));

const FlyerPerformerIcon = styled('img', {
  name: 'FlyerPerformerIcon',
})<{ size: number }>(({ size }) => ({
  width: `${size}px`,
  height: `${size}px`,
  objectFit: 'cover',
}));
