import React from 'react';
// import VenueCard from "../Components/Cards/VenueCard";
// import ShowrunnerCard from "../Components/Cards/ManageShowrunnerCard";
// import TargetCardCollection from "../Components/Collections/TargetCardCollection";
// import TimestampPicker from "../Components/TimestampPicker";
// import { useGetAllShowsQuery } from "../Redux/API/PublicAPI";
// import PlaceAutoComplete from "../Components/Inputs/InputTypes/PlaceAutoComplete";
// import Form from "../Components/Inputs/Form";
// import FilterInput from "../Components/Inputs/InputTypes/FilterInput";
// import ShowrunnerProfile from "../Pages/Profile/ShowrunnerProfile";
// import PaymentAgreementBuilder from "../Forms/PaymentAgreementBuilder";
// import ManageShowTickets from "../Tools/Venue/ManageShowTickets";
// import NotificationCenter from "../Components/NotificationCenter";
// import { useAppSelector } from "../hooks";
// import EmbedCenter from "./Embeds/EmbedCenter";
// import AltImageArrayUploader from "../Components/Inputs/InputTypes/MediaUpload/AltImageArrayUploader";
// import TextReplyToShow from "./QuickConfirm";

export default function Playground() {
  // const SECRET_UID = useAppSelector((state) => state.user.data.uid);
  // let venueList = useAppSelector((state) => state.user.data.venues);
  // const notifications = useAppSelector((state) => state.user.notifications);
  return <></>;
  // return <PaymentAgreementBuilder form="createShow" venueID="17857582"/>
  // return <ManageShowTickets />
  // return <TextReplyToShow showName={"A Show"} venueName={"A Venue"} SECRET_UID={"eyJhbGciOiJIUzI1NiJ9.Mjk4MTQ4MzE.2eT8B_2tuVZn4ke3j0uGX349f4Mih4M8-94cLUJeBbk"} showDate={ {month: "December", day: "Saturday", time: "8pm"} }/>
  // return <EmbedCenter venueID={"17857582"}/>
  // return <AltImageArrayUploader limit={3}/>
}
