import React, { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ViewType } from '../../Helpers/shared/Models/ViewType';
import { Box, Typography, Tabs, Tab, styled } from '@mui/material';
import { TextField } from '../../ui/inputs/TextField';
import { Button } from '../../ui/buttons/Button';
import { typography } from '../../ui/shared/TypographySharedElements';
import { colors } from '../../ui/shared/Colors';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useForm, FormProvider } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import z from 'zod';
import { useResetPasswordMutation, useUpdateAccountSettingsMutation } from '../../Redux/API/UserAPI';
import { openModal } from '../../Redux/UI/UISlice';
import { CancelModal } from './CancelModal/CancelModal';

export const AccountSettings: FC = () => {
  const user = useAppSelector((select) => select.user.data);
  const navigate = useNavigate();
  const [updateAccountSettings, updateAccountSettingsStatus] = useUpdateAccountSettingsMutation();
  const [resetPassword, resetPasswordStatus] = useResetPasswordMutation();
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const initialArtistFormValues = {
    firstName: user?.firstname ?? '',
    lastName: user?.lastname ?? '',
    email: user?.email ?? '',
  };

  const settingsForm = useForm({
    resolver: zodResolver(
      z.object({
        firstName: z.string().min(1, 'You must enter your first name'),
        lastName: z.string().min(1, 'You must enter your last name'),
        email: z.string().min(1, 'You must enter your email').email('The provided value must be an email'),
      }),
    ),
    defaultValues: initialArtistFormValues,
    values: initialArtistFormValues,
    mode: 'all',
  });

  const submitSettings = async (data: any) => {
    setLoading(true);
    const response = await updateAccountSettings(data);
    if ('error' in response) {
      setLoading(false);
      window.alert('Please fix any errors before saving.');
    } else {
      setLoading(false);
      settingsForm.reset(settingsForm.getValues());
    }
  };

  return (
    <>
      <HeadlineContainer>
        <InnerContainer>
          <TitleAndButtonsContainer>
            <Title>Settings</Title>
            {settingsForm.formState.isDirty && (
              <>
                <CancelButton
                  size="large"
                  onClick={() => setCancelModalOpen(true)}
                >
                  Cancel
                </CancelButton>
                <StyledButton
                  isPrimary
                  size="large"
                  disabled={loading}
                  isLoading={loading}
                  onClick={settingsForm.handleSubmit(submitSettings)}
                >
                  Save changes
                </StyledButton>
              </>
            )}
          </TitleAndButtonsContainer>
        </InnerContainer>
      </HeadlineContainer>
      <form onSubmit={settingsForm.handleSubmit(submitSettings)}>
        <FormContainer>
          <InnerContainer>
            <FormSectionTitle>Account</FormSectionTitle>
            <FormSectionContainer>
              <FormInputGroupWrapper>
                <FormInputGroupElementWrapper>
                  <TextField
                    {...settingsForm.register('firstName')}
                    type="text"
                    label="First Name"
                    placeholder="Enter first name"
                    error={!!settingsForm.formState.errors?.firstName?.message}
                    helperText={
                      !!settingsForm.formState.errors?.firstName?.message && (
                        <>{settingsForm.formState.errors?.firstName?.message}</>
                      )
                    }
                  />
                </FormInputGroupElementWrapper>
                <FormInputGroupElementWrapper>
                  <TextField
                    {...settingsForm.register('lastName')}
                    type="text"
                    label="Last Name"
                    placeholder="Enter last name"
                    error={!!settingsForm.formState.errors?.lastName?.message}
                    helperText={
                      !!settingsForm.formState.errors?.lastName?.message && (
                        <>{settingsForm.formState.errors?.lastName?.message}</>
                      )
                    }
                  />
                </FormInputGroupElementWrapper>
              </FormInputGroupWrapper>
              <TextField
                {...settingsForm.register('email')}
                type="text"
                label="Email"
                placeholder="Enter email"
                error={!!settingsForm.formState.errors?.email?.message}
                helperText={
                  !!settingsForm.formState.errors?.email?.message && (
                    <>{settingsForm.formState.errors?.email?.message}</>
                  )
                }
              />
            </FormSectionContainer>
            <FormSectionTitle>Security</FormSectionTitle>
            <FormSectionContainer>
              <PasswordResetBox>
                <PasswordResetDescription>
                  <LockIcon className="material-symbols-outlined">lock</LockIcon>
                  <Box
                    display="flex"
                    flexDirection="column"
                    width="303px"
                    gap="4px"
                  >
                    <Typography sx={{ ...typography.bodyLargeBold, fontFamily: 'Satoshi-Variable' }}>
                      Password
                    </Typography>
                    <Typography sx={{ ...typography.bodySmallRegular, fontFamily: 'Satoshi-Variable' }}>
                      Reset a password to protect your account.
                    </Typography>
                  </Box>
                </PasswordResetDescription>
                <Button
                  sx={{ width: 'fit-content', height: 'fit-content' }}
                  size="small"
                  onClick={() => {
                    const response = resetPassword({ email: user?.email });
                    if ('error' in response) {
                      window.alert('Please fix any errors before saving.');
                    } else {
                      navigate('/reset-password-link-sent');
                    }
                  }}
                >
                  Reset
                </Button>
              </PasswordResetBox>
            </FormSectionContainer>
          </InnerContainer>
        </FormContainer>
      </form>
      <CancelModal
        open={cancelModalOpen}
        setOpen={setCancelModalOpen}
      />
    </>
  );
};

const PasswordResetDescription = styled(Box, {
  name: 'PasswordResetDescription',
})(({ theme }) => ({
  display: 'flex',
  width: 'fit-content',
  gap: '16px',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

const PasswordResetBox = styled(Box, {
  name: 'PasswordResetBox',
})(({ theme }) => ({
  display: 'flex',
  padding: '20px',
  gap: '0px',
  borderRadius: '12px',
  border: '1px solid ' + colors.SystemGray100,
  justifyContent: 'space-between',
  alignItems: 'center',
  opacity: '0px',
  [theme.breakpoints.down('sm')]: {
    gap: '24px',
    flexDirection: 'column',
    alignItems: 'normal',
  },
}));

const LockIcon = styled('i', {
  name: 'LockIcon',
})(({ theme }) => ({
  width: '48px',
  height: '48px',
  fontSize: '24px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: colors.SystemGray100,
  borderRadius: '999px',
  [theme.breakpoints.down('sm')]: {
    width: '36px',
    height: '36px',
    fontSize: '16px',
  },
}));

export const FormSectionTitle = styled(Typography, {
  name: 'FormSectionTitle',
})({
  color: colors.SystemGray900,
  ...typography.bodyLargeBold,
});

export const FormSectionContainer = styled(Box, {
  name: 'FormSectionContainer',
})({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
});

export const FormInputGroupWrapper = styled(Box, {
  name: 'FormInputGroupWrapper',
})(({ theme }) => ({
  display: 'flex',
  gap: '16px',
  flexGrow: 1,
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

export const FormInputGroupElementWrapper = styled(Box, {
  name: 'FormInputGroupElementWrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: 'calc(50% - 8px)',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

export const HeadlineContainer = styled(Box, {
  name: 'HeadlineContainer',
})({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  gap: '32px',
  flexWrap: 'wrap',
  width: '100%',
  backgroundColor: colors.SystemGray100,
  padding: '32px 20px',
});

export const FormContainer = styled(Box, {
  name: 'FormContainer',
})({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '40px 20px',
});

export const InnerContainer = styled(Box, {
  name: 'InnerContainer',
})({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: '800px',
  gap: '16px',
});

export const Title = styled(Typography, {
  name: 'Title',
})(({ theme }) => ({
  textWrap: 'nowrap',
  flex: '1 1 auto',
  ...typography.headlineLarge,
  [theme.breakpoints.down('sm')]: {
    flex: '1 1 100%',
  },
}));

export const TitleAndButtonsContainer = styled(Box)({
  display: 'flex',
  gap: '16px',
  flexWrap: 'wrap',
});

export const StyledButton = styled(Button, {
  name: 'StyledButton',
})({
  flex: '0 0 auto',
  textWrap: 'nowrap',
});

export const CancelButton = styled(StyledButton, {
  name: 'CancelButton',
})({
  backgroundColor: colors.SystemWhite,
});
