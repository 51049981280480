import { FormControlLabel, Switch as MuiSwitch, styled } from '@mui/material';
import { colors } from '../shared/Colors';
import { typography } from '../shared/TypographySharedElements';

export const Switch = styled(MuiSwitch, {
  name: 'Switch',
})({});

export const SwitchControlLabel = styled(FormControlLabel, {
  name: 'FormControlLabel',
})({
  '.MuiTypography-root': {
    color: colors.SystemGray800,
    ...typography.buttonLarge,
  },
  '.MuiSwitch-root': {
    '.MuiButtonBase-root.MuiSwitch-switchBase': {
      '&.Mui-checked': {
        color: colors.SystemBlack,
      },
    },
    '&.Mui-checked': {
      '.MuiSwitch-track': {
        backgroundColor: colors.SystemGray300,
      },
    },
    '.MuiSwitch-track': {
      backgroundColor: colors.SystemGray300,
    },
  },
});
