import { get } from 'lodash';
import { BadRequestError } from './RequestErrors';

/**
 * We map BadRequestError to RTK FetchBaseQueryError
 * @see https://redux-toolkit.js.org/rtk-query/usage-with-typescript#type-safe-error-handling
 */
function isFetchBaseQueryCustomError(error: unknown): error is { status: 'CUSTOM_ERROR', error: string, data?: unknown } {
  return get(error, 'status') === 'CUSTOM_ERROR' && typeof get(error, 'error') === 'string';
}

export function mapErrorToMessage(error: unknown): string | null {
  if (error instanceof BadRequestError) {
    return ErrorCodeToMessageMap[error.response.code] ?? null
  }
  if (isFetchBaseQueryCustomError(error)) {
    return ErrorCodeToMessageMap[error.error] ?? null
  }
  return get(error, 'data') ?? null
}

export const ErrorCodeToMessageMap: Record<string, string> = {
  'HostEmailAlreadyOccupied': 'The email address provided for the off-platform venue is already taken!'
}
