import { Box, styled } from '@mui/material';
import React, { FC, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { DataCy } from '../../../Helpers/DataCy';
import { Button } from '../../../ui/buttons/Button';
import {
  FormStepHeadlineContainer,
  FormStepInnerContainer,
  FormTitleWithSubtitleContainer,
  FormStepFormContainerOuter,
  FormMainContainerColumn,
} from '../Shared/Containers';
import { FormStepHeadlineStepCounter, FormStepHeadlineTitle } from '../Shared/RegistrationTypographies';
import { FormStepProps } from '../Shared/Types';
import { ArtistFormData } from '../../../Forms/AccountInfoForms/Artist/ArtistFormData';
import { ArtistPersonalDetailsForm } from '../../../Forms/AccountInfoForms/Artist/ArtistPersonalDetailsForm';

export const ArtistFormStep1: FC<FormStepProps> = ({ step, setStep }) => {
  const artistForm = useFormContext<ArtistFormData.Step1Values>();

  const handleChangeSigned = useCallback(
    (event: React.MouseEvent<HTMLElement> | any, value: boolean) => {
      artistForm.setValue('recordLabel.signed', value);
      if (value === false) {
        artistForm.resetField('recordLabel.label');
      } else {
        artistForm.setValue('recordLabel.label', '');
      }
    },
    [artistForm],
  );

  const handleNextStep = useCallback(async () => {
    const isValid = await artistForm.trigger(ArtistFormData.step1Fields);
    if (isValid === true) {
      setStep((step) => step + 1);
    }
  }, [artistForm, setStep]);

  return (
    <FormMainContainerColumn>
      <FormStepHeadlineContainer>
        <FormStepInnerContainer>
          <FormTitleWithSubtitleContainer>
            <FormStepHeadlineStepCounter>Step 1 of 2</FormStepHeadlineStepCounter>
            <FormStepHeadlineTitle>Introduce yourself</FormStepHeadlineTitle>
          </FormTitleWithSubtitleContainer>
        </FormStepInnerContainer>
      </FormStepHeadlineContainer>
      <FormStepFormContainerOuter>
        <FormStepInnerContainer>
          <ArtistPersonalDetailsForm></ArtistPersonalDetailsForm>
          <Button
            isPrimary
            onClick={handleNextStep}
            size="large"
            data-cy={DataCy.button('nextStep')}
          >
            Next
          </Button>
        </FormStepInnerContainer>
      </FormStepFormContainerOuter>
    </FormMainContainerColumn>
  );
};
