import { zodResolver } from '@hookform/resolvers/zod';
import axios from 'axios';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { PromoterFormData } from '../../../Forms/AccountInfoForms/Promoter/PromoterFormData';
import { getClaimData } from '../Shared/ClaimDataHelpers';
import { PromoterFormStep1 } from './PromoterForm1';
import { PromoterFormStep2 } from './PromoterForm2';
import { PromoterFormStep3 } from './PromoterForm3';
import { setNavbarLogoOnly } from '../../../Redux/UI/UISlice';
import { useAppDispatch } from '../../../hooks';
import { useEditPromoterProfileMutation, useCreatePromoterProfileMutation } from '../../../Redux/API/UserAPI';

interface ShowrunnerGroupsData {
  id: string;
  name?: string;
  genre?: string;
  subgenre?: string;
  contact?: {
    phone?: string;
    email?: string;
  };
  role: string;
}

export const PromoterForm = () => {
  const [step, setStep] = useState(1);
  const [avatar, setAvatar] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [editPromoterProfile, editPromoterProfileStatus] = useEditPromoterProfileMutation();
  const [createPromoterProfile, createPromoterProfileStatus] = useCreatePromoterProfileMutation();

  useEffect(() => {
    dispatch(setNavbarLogoOnly({ status: true }));
    return () => {
      dispatch(setNavbarLogoOnly({ status: false }));
    };
  }, []);

  const parsedInitialData = getClaimData();
  const showrunnerGroupData = !isEmpty(parsedInitialData?.showrunnerGroups)
    ? parsedInitialData.showrunnerGroups.find((group: ShowrunnerGroupsData) => group.role === 'admin')
    : null;

  const initialPromoterFormValues: PromoterFormData.Values = {
    groupName: showrunnerGroupData?.name ?? '',
    genre: showrunnerGroupData?.genre ?? 'none',
    subGenre: showrunnerGroupData?.subgenre ?? null,
    firstName: parsedInitialData?.firstName ?? '',
    lastName: parsedInitialData?.lastName ?? '',
    contact: {
      phone: showrunnerGroupData?.contact.phone ?? '',
      email: showrunnerGroupData?.contact.email ?? '',
    },
    primaryCity: {},
    secondaryCity: null,
    socials: {
      instagram: null,
      tiktok: null,
      spotify: null,
      youtube: null,
    },
    about: '',
    roster: [],
  };

  const promoterForm = useForm({
    resolver: zodResolver(PromoterFormData.formSchema),
    defaultValues: initialPromoterFormValues,
    values: initialPromoterFormValues,
    mode: 'all',
  });

  const submitPromoter = async (data: PromoterFormData.Values) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append(
        'body',
        JSON.stringify({
          id: showrunnerGroupData?.id,
          name: data.groupName,
          genre: data.genre,
          subgenre: data.subGenre,
          showrunner: {
            firstName: data.firstName,
            lastName: data.lastName,
          },
          contact: {
            phone: data.contact.phone,
            email: data.contact.email,
          },
          socials: {
            instagramLink: data.socials.instagram ?? null,
            youtubeLink: data.socials.youtube ?? null,
            spotifyLink: data.socials.spotify ?? null,
            tikTokLink: data.socials.tiktok ?? null,
          },
          primaryCity: {
            name: data.primaryCity.city,
            meta: data.primaryCity,
          },
          secondaryCity: data.secondaryCity?.displayAddress
            ? {
                name: data.secondaryCity.city,
                meta: data.secondaryCity,
              }
            : null,
          about: data.about,
          roster: [...data.roster],
        }),
      );
      formData.append('avatar', avatar);
      const response = showrunnerGroupData?.id
        ? await editPromoterProfile(formData)
        : await createPromoterProfile(formData);

      setLoading(false);
      if ('error' in response) {
        console.log(response.error);
        window.alert('please fix any errors before submitting.');
        setStep(1);
      } else {
        navigate('/register/success/promoter');
      }
    } catch (error: unknown) {
      setLoading(false);
      if (error instanceof axios.AxiosError && error.response.status === 413) {
        window.alert('Uploaded images are too large');
        setStep(2);
      } else {
        setStep(1);
        window.alert('Unknown server error');
        console.log(error);
      }
    }
  };

  return (
    <FormProvider {...promoterForm}>
      <form onSubmit={promoterForm.handleSubmit(submitPromoter)}>
        {step === 1 && (
          <PromoterFormStep1
            step={step}
            setStep={setStep}
          />
        )}
        {step === 2 && (
          <PromoterFormStep2
            step={step}
            setStep={setStep}
            setAvatar={setAvatar}
            selectedAvatar={avatar}
          />
        )}
        {step === 3 && (
          <PromoterFormStep3
            step={step}
            setStep={setStep}
            submitPromoter={submitPromoter}
            isLoading={loading}
          />
        )}
      </form>
    </FormProvider>
  );
};
