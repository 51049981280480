import React, { FC, useCallback, useMemo, useState } from 'react';
import { Button } from '../../../ui/buttons/Button';
import { Box, styled, Typography } from '@mui/material';
import { typography } from '../../../ui/shared/TypographySharedElements';
import { colors } from '../../../ui/shared/Colors';
import { RightHandSideComponentTitle } from '../Shared/SharedElements';
import { RightHandSideComponentContainer } from '../Shared/SharedContainers';
import Img from '../../../Components/Images/Img';
import { IconButton } from '../../../ui/buttons/IconButton';

interface PhotoGalleryProps {
  images: string[];
}

export const PhotoGallery: FC<PhotoGalleryProps> = ({ images }) => {
  const [page, setPage] = useState(1);
  const maxPages = useMemo(() => {
    return Math.ceil(images.length / 3);
  }, [images.length]);
  const imagesToShow = useMemo(() => {
    return images.slice((page - 1) * 3, page * 3);
  }, [images, page]);
  const isNextPageEnabled = useMemo(() => {
    return page < maxPages;
  }, [page, maxPages]);
  const isPrevPageEnabled = useMemo(() => {
    return page > 1;
  }, [page]);

  const setNextPage = useCallback(() => {
    if (isNextPageEnabled) {
      setPage((page) => page + 1);
    }
  }, [isNextPageEnabled]);

  const setPreviousPage = useCallback(() => {
    if (isPrevPageEnabled) {
      setPage((page) => page - 1);
    }
  }, [isPrevPageEnabled]);

  return (
    <RightHandSideComponentContainer>
      <HeadingContainer>
        <RightHandSideComponentTitle>Photos</RightHandSideComponentTitle>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap="8px"
        >
          <IconButton
            isPrimary
            onClick={setPreviousPage}
            disabled={!isPrevPageEnabled}
            size="small"
            iconName="chevron_left"
          />
          <NavText>
            {page} of {maxPages}
          </NavText>
          <IconButton
            isPrimary
            onClick={setNextPage}
            disabled={!isNextPageEnabled}
            size="small"
          />
        </Box>
      </HeadingContainer>
      {/* 
      Note: we are currently scaling down non-standard images.
      In future versions, we want to implement a lightbox functionality. @RDF
       */}
      <PhotosContainer>
        {imagesToShow.map((image) => (
          <Img
            src={image}
            style={{ borderRadius: '8px', maxHeight: '235px' }}
          />
        ))}
      </PhotosContainer>
    </RightHandSideComponentContainer>
  );
};

const NavText = styled(Typography, {
  name: 'NavText',
})({
  color: colors.SystemBlack,
  ...typography.bodySmallRegular,
});

const HeadingContainer = styled(Box, {
  name: 'HeadingContainer',
})({
  display: 'flex',
  justifyContent: 'space-between',
});

const PhotosContainer = styled(Box, {
  name: 'PhotosContainer',
})({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  justifyItems: 'center',
  alignItems: 'center',
  height: '235px',
  gap: '16px',
});
