import { Box, styled, Typography } from '@mui/material';
import React, { FC } from 'react';
import { colors } from '../../../ui/shared/Colors';
import { typography } from '../../../ui/shared/TypographySharedElements';

interface ShowWarningProps {
  distance: number;
}

export const LongShowWarning: FC<ShowWarningProps> = ({ distance }) => {
  return (
    <ShowWarningCont>
      <ShowWarningTitle>This is a pretty long show!</ShowWarningTitle>
      <ShowWarningText>
        {`This show is currently set to be ${distance} hours .
                If this is intentional, please continue normally. Otherwise, double-check your start and end dates and double-check that they are entered correctly.`}
      </ShowWarningText>
    </ShowWarningCont>
  );
};

export const ShortShowWarning: FC<ShowWarningProps> = ({ distance }) => {
  return (
    <ShowWarningCont>
      <ShowWarningTitle>This is a pretty short show!</ShowWarningTitle>
      <ShowWarningText>
        {`This show is currently set to be less than 1 hour.
              If this is intentional, please continue normally. Otherwise, double-check your start and end dates and double-check that they are entered correctly.`}
      </ShowWarningText>
    </ShowWarningCont>
  );
};

const ShowWarningCont = styled(Box, {
  name: 'ShowWarningCont',
})({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  justifyContent: 'center',
});

const ShowWarningTitle = styled(Typography, {
  name: 'ShowWarningTitle',
})({
  color: colors.SystemBlack,
  ...typography.bodyMediumBold,
});
const ShowWarningText = styled(Typography, {
  name: 'ShowWarningText',
})({
  color: colors.SystemGray800,
  ...typography.bodyMediumRegular,
});
