import { z } from 'zod';
import { locationValueSchema } from '../../../ui/inputs/LocationField';
import { compareAsc } from 'date-fns';
import { isArray, isEmpty } from 'lodash';

export const getShowCreationFormSchema = (isEdit: boolean) => {
  const showCreationFormSchema = z
    .object({
      name: z.string().min(2),
      description: z.string().optional(),
      showrunners: z
        .object({
          id: z.string(),
          name: z.string().min(1).optional(),
          email: z.string().email().optional(),
        })
        .array()
        .optional(),
      artists: z
        .object({
          id: z.string(),
          stageName: z.string().min(1).optional(),
          email: z.string().email().optional(),
        })
        .array()
        .optional(),
      venues: z
        .object({
          id: z.string(),
          name: z.string().min(1, 'You must add a venue name').optional(),
          bookingContactName: z.string().min(1, 'You must add a booking contact name').optional(),
          email: z.string().email().optional(),
          location: z
            .object({
              ...locationValueSchema.shape,
            })
            .optional(),
        })
        .array()
        .min(1),
      startTime: z.date(),
      endTime: z.date(),
      minAge: z.string().min(1, 'You must provide minimum age'),
      repeatingShow: z.boolean().optional(),
      repeatType: z.string().optional(),
      repeatTimes: z.number().optional(),
      isPrivate: z.boolean().optional(),
      isManageableByArtists: z.boolean().optional(),
      calendarColor: z.string(),
      numberOfAvailableTickets: z.string().min(1, 'Number of available tickets is required'),
      advancedTicketing: z.boolean(),
      ticketPrice: z.string().optional(),
      doorPrice: z.string().optional(),
      tickets: z
        .object({
          tierName: z.string().min(1, 'Tier name is required').optional(),
          tierDescription: z.string().min(1, 'Tier description is required').optional(),
          ticketPrice: z.string().min(1, 'Ticket price is required').optional(),
          quantity: z.string().min(1, 'Quantity is required').optional(),
        })
        .array()
        .optional(),
      paymentType: z.string().optional(),
      guaranteeAmount: z.string().optional().nullable(),
      productionFee: z.string().optional().nullable(),
      flyer: z.string().min(1),
    })
    .superRefine((args, ctx) => {
      if (compareAsc(args.startTime, args.endTime) >= 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Start time must be before end time',
          path: ['startTime'],
        });
      }
      if (compareAsc(args.startTime, args.endTime) >= 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'End time must be after start time',
          path: ['endTime'],
        });
      }
      if (args.numberOfAvailableTickets && isNaN(+args.numberOfAvailableTickets)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'You must provide a correct number of available tickets',
          path: ['numberOfAvailableTickets'],
        });
      }
      if (args.advancedTicketing && isEmpty(args.tickets)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'You must provide at least one ticket tier',
          path: ['tickets'],
        });
      }
      if (args.advancedTicketing) {
        args.tickets.map((ticket, idx) => {
          if (!ticket.tierName) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'You must provide tier name',
              path: [`tickets[${idx}].tierName`],
            });
          }
          if (!ticket.tierDescription) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'You must provide tier description',
              path: [`tickets[${idx}].tierDescription`],
            });
          }
          if (!ticket.ticketPrice || isNaN(+ticket.ticketPrice)) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'You must provide the tickets price',
              path: [`tickets[${idx}].ticketPrice`],
            });
          }
          if (!ticket.quantity || isNaN(+ticket.quantity)) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'You must provide quantity',
              path: [`tickets[${idx}].quantity`],
            });
          }
        });
      }
      if (!args.advancedTicketing && (!args.ticketPrice || isNaN(Number(args.ticketPrice)))) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'You must provide a price for the tickets',
          path: ['ticketPrice'],
        });
      }
      if (args.repeatingShow && !args.repeatTimes) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'You must set how many times to repeat the show',
          path: ['repeatTimes'],
        });
      }
      if (args.repeatingShow && !args.repeatType) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'You must select weekly or monthly',
          path: ['repeatType'],
        });
      }
      args.venues.map((venue, idx) => {
        if (venue.id === '0') {
          if (!venue.name) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'You must add a venue name',
              path: [`venues[${idx}].name`],
            });
          }
          if (!venue.bookingContactName) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'You must add an booking contact name',
              path: [`venues[${idx}].bookingContactName`],
            });
          }
          if (!venue.email) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'You must add an email',
              path: [`venues[${idx}].email`],
            });
          }
          if (
            isEmpty(venue.location) ||
            !venue.location?.address ||
            !venue.location?.city ||
            !venue.location?.displayAddress ||
            !venue.location?.latitude ||
            !venue.location?.longitude ||
            !venue.location?.state ||
            !venue.location?.zip
          ) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'You must add an address',
              path: [`venues[${idx}].location`],
            });
          }
        }
      });
      if (!isEmpty(args.showrunners)) {
        args.showrunners.map((SRG, idx) => {
          if (SRG.id === '0') {
            if (!SRG.name) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'You must add a stage name',
                path: [`showrunners[${idx}].name`],
              });
            }
            if (!SRG.email) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'You must add an email',
                path: [`showrunners[${idx}].email`],
              });
            }
          }
        });
      }
      if (!isEmpty(args.artists)) {
        args.artists.map((artist, idx) => {
          if (artist.id === '0') {
            if (!artist.stageName) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'You must add a stage name',
                path: [`artists[${idx}].stageName`],
              });
            }
            if (!artist.email) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'You must add an email',
                path: [`artists[${idx}].email`],
              });
            }
          }
        });
      }
      if (isArray(args.tickets)) {
        args.tickets.map((ticket, index) => {
          if (!ticket.tierName) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'You must add a tier name',
              path: [`tickets[${index}].tierName`],
            });
          }
          if (!ticket.tierDescription) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'You must add a description',
              path: [`tickets[${index}].tierDescription`],
            });
          }
          if (!ticket.ticketPrice) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'You must add ticket price',
              path: [`tickets[${index}].ticketPrice`],
            });
          }
          if (!ticket.quantity) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'You must add quantity',
              path: [`tickets[${index}].quantity`],
            });
          }
        });
      }
    });

  return showCreationFormSchema;
};
