import { useCallback, useState } from 'react';
import { ColorExtended } from './ColorPickers';
import { FastAverageColor } from 'fast-average-color';

interface GetColorsFromImageProps {
  avatarUrl: string;
}

export const useGetColorsFromImage = ({ avatarUrl }: GetColorsFromImageProps) => {
  const getColorFromImage = useCallback(async () => {
    const fac = new FastAverageColor();
    try {
      const colorFull = await fac.getColorAsync(avatarUrl, { algorithm: 'dominant' });
      fac.destroy();
      return { hex: colorFull.hex, isDark: colorFull.isDark };
    } catch {
      console.log('sth went wrong');
      fac.destroy();
    }
  }, []);
  return { getColorFromImage };
};
