import { Box, styled, useMediaQuery, useTheme } from '@mui/material';
import Lo, { isEmpty } from 'lodash';
import React, { FC, Ref, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingSpinner from '../../Components/LoadingSpinner';
import { GENRE_MAP } from '../../Helpers/configConstants';
import { getDisplayName, parseProfileData } from '../../Helpers/HelperFunctions';
import { LinkFactory } from '../../Helpers/LinkFactory';
import { ViewType } from '../../Helpers/shared/Models/ViewType';
import { useAppSelector, useAppDispatch } from '../../hooks';
import AvatarImage from '../../Images/Avatar.webp';
import { useGetAllArtistsQuery, useGetAllShowrunnerGroupsQuery, useGetAllVenuesQuery } from '../../Redux/API/PublicAPI';
import { setDarkMode } from '../../Redux/UI/UISlice';
import { Button } from '../../ui/buttons/Button';
import { HatchLinksList } from '../../ui/HatchLinks/HatchLinksList';
import { ShareEPKLink } from '../ShareEPKLink/ShareEPKLink';
import { ShareHatchLinks } from '../ShareHatchLinks/ShareHatchLinks';
import { About } from './About/About';
import { Badges } from './Badges/Badges';
import { Instagram } from './Instagram/Instagram';
import { ProfileHeadline } from './ProfileHeadline/ProfileHeadline';
import { SocialStats } from './SocialStats/SocialStats';
import { Spotify } from './Spotify/Spotify';
import { TikTok } from './TikTok/TikTok';
import { TuneStats } from './TuneStats/TuneStats';
import { SmallUpcomingShows } from './UpcomingShows/SmallUpcomingShows';
import { UpcomingShows } from './UpcomingShows/UpcomingShows';
import { YouTube } from './YouTube/YouTube';
import { LivePerformance } from './YouTube/LivePerformance';
import { ContactInfo } from './ContactInfo/ContactInfo';
import { PhotoGallery } from './PhotoGallery/PhotoGallery';
import { GuidedTours } from '../GuidedTours/GuidedTours';
import { RecentlyPlayedWith } from './RecentlyPlayedWith/RecentlyPlayedWith';
import { useGetColorsFromImage } from '../EditProfile/HatchLinksColors/useGetColorsFromImage';
import { useSendColorData } from '../EditProfile/HatchLinksColors/useSendColorData';
import { ColorExtended } from '../EditProfile/HatchLinksColors/ColorPickers';
import { profile } from 'console';

function resolveReadableType(type: string): ViewType {
  if (type === 'a' || type === 'artist') {
    return ViewType.Artist;
  } else if (type === 'v' || type === 'venue') {
    return ViewType.Venue;
  } else if (type === 'g' || type === 'showrunner') {
    return ViewType.SRGroup;
  } else if (type === 'u' || type === 'user') {
    return ViewType.Artist;
  } else {
    throw new Error('Unsupported user type!');
  }
}

export function useCurrentData(type: ViewType, entityId: string): { data: any; isLoading: boolean; type: ViewType } {
  const venues = useGetAllVenuesQuery();
  const artists = useGetAllArtistsQuery();
  const showrunners = useGetAllShowrunnerGroupsQuery();

  const artistData = Lo.get(artists.data, entityId);
  if (type === ViewType.Artist || (type === ViewType.User && artistData !== undefined)) {
    return { data: artistData, isLoading: artists.isLoading, type: ViewType.Artist };
  }
  if (type === ViewType.Venue) {
    return { data: Lo.get(venues.data, entityId), isLoading: venues.isLoading, type };
  }
  if (type === ViewType.SRGroup) {
    return { data: Lo.get(showrunners.data, entityId), isLoading: showrunners.isLoading, type };
  }
  return { data: null, isLoading: false, type };
}

interface ProfilePageProps {
  userType?: string;
  userID?: string;
  forceMobile?: boolean;
}

export const ProfilePage: FC<ProfilePageProps> = ({ userType, userID, forceMobile }) => {
  const user = useAppSelector((select) => select.user.data);
  const possibleUserIDs: string[] = [user.displayUID, ...Object.keys(user.sr_groups), ...user.venues];
  const [shareHatchLinksOpen, setShareHatchLinksOpen] = useState(false);
  const [shareEPKLinkOpen, setShareEPKLinkOpen] = useState(false);
  const [topBgColor, setTopBgColor] = useState(null);
  const { profileID } = useParams();
  const { type } = useParams<string>();
  userID = userID || profileID;
  const actualUserType = userType || type;
  const createShowBtnRef: Ref<HTMLButtonElement> = useRef(null);
  const editProfileBtnRef: Ref<HTMLButtonElement> = useRef(null);
  const editHLBtnRef: Ref<HTMLButtonElement> = useRef(null);
  const readableType = useMemo(() => resolveReadableType(actualUserType), [actualUserType]);
  const navigate = useNavigate();
  const sendColorData = useSendColorData({ viewType: readableType });
  const isOwnProfile = possibleUserIDs.includes(profileID);
  const venues = useGetAllVenuesQuery();
  const theme = useTheme();
  const isMobileBreakpoint = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = forceMobile || isMobileBreakpoint;
  const dispatch = useAppDispatch();
  const currentData = useCurrentData(readableType, userID);
  const { getColorFromImage } = useGetColorsFromImage({ avatarUrl: currentData?.data?.avatar ?? AvatarImage });

  const handleGetColorFromImage = useCallback(async () => {
    if (
      !isEmpty(currentData) &&
      isEmpty(currentData?.data?.customOptions?.colors?.profileColor) &&
      currentData?.data?.avatar
    ) {
      const avatarColor = await getColorFromImage();
      const colorDataPayload =
        readableType === ViewType.Artist
          ? { profileColor: avatarColor }
          : { profileID: profileID, profileColor: avatarColor };
      sendColorData(colorDataPayload);
      setTopBgColor(avatarColor?.hex);
      if (avatarColor?.isDark) {
        dispatch(setDarkMode({ status: true }));
      } else {
        dispatch(setDarkMode({ status: false }));
      }
    }
  }, [getColorFromImage, setTopBgColor, dispatch, setDarkMode, currentData, readableType]);

  const aboutText = useMemo(() => {
    if (currentData.isLoading) return '';
    switch (readableType) {
      case ViewType.Artist:
        return currentData.data.about;
      case ViewType.Venue:
        return currentData.data.description;
      case ViewType.SRGroup:
        return currentData.data.bio;
    }
  }, [readableType, currentData.data]);
  const genreText = useMemo(() => {
    if (currentData.isLoading) return '';
    switch (readableType) {
      case ViewType.Artist:
        return (
          currentData.data.type?.artist?.subgenre ||
          (!isEmpty(currentData.data.type?.artist?.genre)
            ? GENRE_MAP[currentData.data.type?.artist?.genre.toLowerCase().replace(/\s/g, '_')]
            : '')
        );
      case ViewType.Venue:
        return '';
      case ViewType.SRGroup:
        return (
          currentData.data.subgenre ||
          (currentData.data.genre ? GENRE_MAP[currentData.data.genre.toLowerCase().replace(/\s/g, '_')] : '')
        );
    }
  }, [readableType, currentData.data]);

  const locationText = useMemo(() => {
    if (currentData.isLoading || isEmpty(currentData.data.meta?.primaryCity)) return '';
    switch (readableType) {
      case ViewType.Artist:
        return `${currentData.data.meta?.primaryCity?.city}, ${currentData.data.meta?.primaryCity?.state}`;
      case ViewType.Venue:
        return `${currentData.data.location.address}, ${currentData.data.location.city}, ${currentData.data.location.state} ${currentData.data.location.zip}, United States`;
      case ViewType.SRGroup:
        return `${currentData.data.meta?.primaryCity?.city}, ${currentData.data.meta?.primaryCity?.state}`;
    }
  }, [readableType, currentData.data]);
  const secondaryLocationText = useMemo(() => {
    if (currentData.isLoading || !currentData.data.meta?.secondaryCity) return '';
    switch (readableType) {
      case ViewType.Artist:
        return `${currentData.data.meta?.secondaryCity?.city}, ${currentData.data.meta?.secondaryCity?.state}`;
      case ViewType.Venue:
        return '';
      case ViewType.SRGroup:
        return `${currentData.data.meta?.secondaryCity?.city}, ${currentData.data.meta?.secondaryCity?.state}`;
    }
  }, [readableType, currentData.data]);
  const socials = useMemo(() => {
    if (currentData.isLoading) return {};
    switch (readableType) {
      case ViewType.Artist:
        return currentData.data.type?.artist?.socials;
      case ViewType.Venue:
        return currentData.data.socials;
      case ViewType.SRGroup:
        return currentData.data.socials;
    }
  }, [readableType, currentData.data]);

  const emailText = useMemo(() => {
    console.log('currentData.data', currentData.data);
    if (currentData.isLoading) return '';
    switch (readableType) {
      case ViewType.Artist:
        return currentData.data.type?.artist?.contactEmail;
      case ViewType.Venue:
        return currentData.data.contact?.email;
      case ViewType.SRGroup:
        return currentData.data.contact?.email;
    }
  }, [readableType, currentData.data]);
  const phoneText = useMemo(() => {
    if (currentData.isLoading) return '';
    var phone;
    switch (readableType) {
      case ViewType.Artist:
        phone = currentData.data.type?.artist?.contactPhone;
        break;
      case ViewType.Venue:
        phone = currentData.data.contact?.phone;
        break;
      case ViewType.SRGroup:
        phone = currentData.data.contact?.phone;
        break;
    }
    if (!phone) return '';
    //remove all non-numeric characters
    phone = phone.replace(/\D/g, '');
    //if country code is included, format it seporately
    if (phone.length > 10) {
      phone = `+${phone.slice(0, -10)} ${phone.slice(-10)}`;
    }
    //add spaces in the phone number
    return `${phone?.slice(0, -7)} ${phone?.slice(-7, -4)} ${phone?.slice(-4)}`;
  }, [readableType, currentData.data]);
  const images = useMemo(() => {
    if (currentData.isLoading) return '';
    switch (readableType) {
      case ViewType.Artist:
        return currentData.data.type?.artist?.images;
      case ViewType.Venue:
        return currentData.data.images;
      case ViewType.SRGroup:
        return currentData.data.images;
    }
  }, [readableType, currentData.data]);

  useEffect(() => {
    if (typeof getColorFromImage === 'function' && !topBgColor) {
      if (!isEmpty(currentData?.data?.customOptions?.colors?.profileColor)) {
        setTopBgColor(currentData?.data?.customOptions?.colors?.profileColor?.hex);
      } else {
        handleGetColorFromImage();
      }
    }
  }, [getColorFromImage, handleGetColorFromImage, topBgColor]);

  if (currentData.isLoading === true) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        position="relative"
      >
        <LoadingSpinner />
      </Box>
    );
  }
  if (currentData.data === null && isOwnProfile === true) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        position="relative"
      >
        <Button
          size="large"
          disabled={false}
          onClick={() =>
            navigate(`/profile/edit/${readableType === ViewType.User ? 'artist' : readableType}/${userID}`)
          }
        >
          Edit Profile
        </Button>
      </Box>
    );
  }
  if ((currentData.data === undefined || currentData.data === null) && currentData.isLoading === false) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        position="relative"
      >
        {/* The user does not exist. If you recently created your TuneHatch profile, please refresh the page. */}
      </Box>
    );
  }

  if (isMobile) {
    return (
      <>
        {readableType === ViewType.Artist && isOwnProfile && !currentData.data?.tourStatus?.profileTour?.completed && (
          <GuidedTours
            buttonRefs={{
              show: createShowBtnRef?.current,
              profile: editProfileBtnRef?.current,
              hatchLinks: editHLBtnRef?.current,
            }}
            viewType={ViewType.Artist}
            isMobile={isMobile}
            profileID={userID}
            actualStep={currentData.data?.tourStatus?.profileTour?.step}
          />
        )}
        <ProfilePageMainContainer
          topBgColor={topBgColor}
          isMobile={isMobile}
        >
          <ProfileHeadline
            avatarSrc={currentData.data.avatar}
            profileID={userID}
            profileType={currentData.type}
            stripeEnabled={currentData.data.type?.artist?.stripeEnabled}
            userName={getDisplayName(readableType, currentData.data)}
            isOwnProfile={isOwnProfile}
            setShareHatchLinksOpen={setShareHatchLinksOpen}
            setShareEPKLinkOpen={setShareEPKLinkOpen}
            isMobile={isMobile}
            editProfileRef={editProfileBtnRef}
            hlRef={editHLBtnRef}
          />
          <ProfilePageContentBlock>
            <ProfilePageBlockLeft>
              {(emailText || phoneText || (socials?.instagram && typeof socials?.instagram === 'string')) &&
                (user?.host || isOwnProfile) && (
                  <ContactInfo
                    email={emailText}
                    phone={phoneText}
                    instagram={socials?.instagram}
                  />
                )}
              <SmallUpcomingShows
                userId={userID}
                isMobile={isMobile}
                type={currentData.type}
                isAlternativeLabelView={currentData.data.customOptions?.alternativeHatchLinkLabel}
              />
              <About
                about={aboutText ?? ''}
                genre={genreText}
                location={locationText ?? ''}
                secondaryLocation={secondaryLocationText ?? ''}
                recordLabel={currentData.data.type?.artist?.recordLabelName ?? ''}
                isOwnProfile={isOwnProfile}
                profileID={userID}
                profileType={currentData.type}
              />
              {currentData.type === ViewType.Artist && <RecentlyPlayedWith userID={userID} />}
              {images && images.length > 0 && <PhotoGallery images={images} />}
              {socials?.spotifyLink && <Spotify spotifyLink={socials.spotifyLink} />}
              {socials?.youtubeLink && <YouTube youTubeLink={socials.youtubeLink} />}
              {socials?.instagram && <Instagram instagramUsername={socials?.instagram} />}
              {socials?.tikTokLink && <TikTok tikTokLink={socials.tikTokLink} />}
              {currentData.type === ViewType.Artist && currentData.data.type?.artist?.livePerformance && (
                <LivePerformance youTubeLink={currentData.data.type?.artist?.livePerformance} />
              )}
              {currentData.type === ViewType.Artist && venues.data && (
                <Badges
                  badges={parseProfileData(ViewType.Artist, currentData.data, venues.data).badges}
                  isOwnProfile={isOwnProfile}
                  profileType={currentData.type}
                  profileID={userID}
                />
              )}
              {/* {(currentData.type === ViewType.Artist || currentData.type === ViewType.SRGroup) && socials && (
              <SocialStats
                isOwnProfile={isOwnProfile}
                socials={socials}
                profileID={userID}
                profileType={currentData.type}
              />
            )} */}
              {currentData.type === ViewType.Artist && <TuneStats userID={userID} />}
              {!isEmpty(currentData.data.externalLinks) && (
                <HatchLinksList
                  openInNewTab
                  links={currentData.data.externalLinks || []}
                  maxRenderNumber={4}
                  userId={userID}
                  userType={readableType}
                />
              )}
            </ProfilePageBlockLeft>
            <ShareHatchLinks
              link={
                !currentData.data.username
                  ? LinkFactory.hatchlink({
                      viewType: readableType === ViewType.User ? ViewType.Artist : readableType,
                      id: userID,
                    })
                  : LinkFactory.shortLinkHatchlink(currentData.data.username)
              }
              username={currentData.data.username}
              open={shareHatchLinksOpen}
              setOpen={setShareHatchLinksOpen}
            />
            <ShareEPKLink
              link={`${process.env.REACT_APP_PUBLIC_URL}/profile/${actualUserType === 'user' ? 'artist' : actualUserType}/${userID}`}
              open={shareEPKLinkOpen}
              setOpen={setShareEPKLinkOpen}
            />
          </ProfilePageContentBlock>
        </ProfilePageMainContainer>
      </>
    );
  }

  return (
    <>
      {readableType === ViewType.Artist &&
        isOwnProfile &&
        ((createShowBtnRef?.current && editProfileBtnRef?.current && editHLBtnRef?.current) || isMobile) &&
        !currentData.data?.tourStatus?.profileTour?.completed && (
          <GuidedTours
            buttonRefs={{
              show: createShowBtnRef?.current,
              profile: editProfileBtnRef?.current,
              hatchLinks: editHLBtnRef?.current,
            }}
            viewType={ViewType.Artist}
            isMobile={isMobile}
            profileID={userID}
            actualStep={currentData.data?.tourStatus?.profileTour?.step}
          />
        )}
      <ProfilePageMainContainer
        topBgColor={topBgColor}
        isMobile={isMobile}
      >
        <ProfileHeadline
          avatarSrc={currentData.data.avatar}
          profileType={currentData.type}
          profileID={userID}
          stripeEnabled={currentData.data.type?.artist?.stripeEnabled}
          userName={getDisplayName(currentData.type, currentData.data)}
          isOwnProfile={isOwnProfile}
          setShareEPKLinkOpen={setShareEPKLinkOpen}
          setShareHatchLinksOpen={setShareHatchLinksOpen}
          editProfileRef={editProfileBtnRef}
          hlRef={editHLBtnRef}
        />
        <ProfilePageContentBlock>
          <ProfilePageBlockLeft>
            {(emailText || phoneText || (socials?.instagram && typeof socials?.instagram === 'string')) &&
              (user?.host || isOwnProfile) && (
                <ContactInfo
                  email={emailText}
                  phone={phoneText}
                  instagram={socials?.instagram}
                />
              )}
            <About
              about={aboutText ?? ''}
              genre={genreText}
              location={locationText ?? ''}
              secondaryLocation={secondaryLocationText ?? ''}
              recordLabel={currentData.data.type?.artist?.recordLabelName ?? ''}
              isOwnProfile={isOwnProfile}
              profileID={userID}
              profileType={currentData.type}
            />
            {/* {(currentData.type === ViewType.Artist || currentData.type === ViewType.SRGroup) && socials && (
            <SocialStats
              isOwnProfile={isOwnProfile}
              socials={socials}
              profileID={userID}
              profileType={currentData.type}
            />
          )} */}
            {currentData.type === ViewType.Artist && venues.data && (
              <Badges
                badges={parseProfileData(ViewType.Artist, currentData.data, venues.data).badges}
                isOwnProfile={isOwnProfile}
                profileID={userID}
                profileType={currentData.type}
              />
            )}
            {currentData.type === ViewType.Artist && <TuneStats userID={userID} />}
          </ProfilePageBlockLeft>
          <ProfilePageBlockRight>
            <UpcomingShows
              userId={userID}
              isMobile={isMobile}
              type={currentData.type}
              isOwnProfile={isOwnProfile}
              createShowBtnRef={createShowBtnRef}
            />
            {currentData.type === ViewType.Artist && <RecentlyPlayedWith userID={userID} />}
            {images && images.length > 0 && <PhotoGallery images={images} />}
            {socials?.spotifyLink && <Spotify spotifyLink={socials?.spotifyLink} />}
            {socials?.youtubeLink && <YouTube youTubeLink={socials?.youtubeLink} />}
            {socials?.instagram && <Instagram instagramUsername={socials?.instagram} />}
            {socials?.tikTokLink && <TikTok tikTokLink={socials?.tikTokLink} />}
            {currentData.type === ViewType.Artist && currentData.data.type?.artist?.livePerformance && (
              <LivePerformance youTubeLink={currentData.data.type?.artist?.livePerformance} />
            )}
            <HatchLinksList
              openInNewTab
              links={currentData.data.externalLinks || []}
              maxRenderNumber={4}
              userId={userID}
              userType={readableType}
            />
          </ProfilePageBlockRight>
        </ProfilePageContentBlock>
        <ShareHatchLinks
          link={
            !currentData.data.username
              ? LinkFactory.hatchlink({
                  viewType: readableType === ViewType.User ? ViewType.Artist : readableType,
                  id: userID,
                })
              : LinkFactory.shortLinkHatchlink(currentData.data.username)
          }
          username={currentData.data.username}
          open={shareHatchLinksOpen}
          setOpen={setShareHatchLinksOpen}
        />
        <ShareEPKLink
          link={`${process.env.REACT_APP_PUBLIC_URL}/profile/${readableType === ViewType.User ? ViewType.Artist : readableType}/${userID}`}
          open={shareEPKLinkOpen}
          setOpen={setShareEPKLinkOpen}
        />
      </ProfilePageMainContainer>
    </>
  );
};

const ProfilePageMainContainer = styled(Box, {
  name: 'ProfilePageMainContainer',
  shouldForwardProp: (prop) => prop !== 'topBgColor' && prop !== 'isMobile',
})<{ topBgColor: string; isMobile?: boolean | null }>(({ topBgColor, isMobile }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: isMobile ? '40px' : '64px',
  alignItems: 'center',
  justifyContent: 'center',
  background: `linear-gradient(${topBgColor}, #F8F7F5)`,
  padding: '80px 0',
  width: '100%',
}));

const ProfilePageContentBlock = styled(Box, {
  name: 'ProfilePageContentBlock',
})(({ theme }) => ({
  display: 'flex',
  gap: '16px',
  maxWidth: '1400px',
  width: '100%',
  justifyContent: 'center',
  marginBottom: '-48px',
  [theme.breakpoints.down('lg')]: {
    maxWidth: '100%',
    padding: '0 12px',
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column-reverse',
    alignItems: 'center',
    padding: 0,
  },
}));

const ProfilePageBlockLeft = styled(Box, {
  name: 'BlockLeft',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  maxWidth: '400px',
  width: '100%',
  [theme.breakpoints.down('md')]: {
    padding: '0 20px',
    width: '100%',
  },
}));
const ProfilePageBlockRight = styled(Box, {
  name: 'BlockRight',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  maxWidth: '784px',
  width: '100%',
  [theme.breakpoints.down('md')]: {
    maxWidth: '400px',
  },
}));
