import { Typography, styled } from '@mui/material';
import { Link } from 'react-router-dom';
import { typography } from '../../../ui/shared/TypographySharedElements';
import { colors } from '../../../ui/shared/Colors';

export const UserSelectionHeadline = styled(Typography, {
  name: 'UserSelectionHeadline',
})({
  textTransform: 'none',
  fontSize: typography.headlineMedium.fontSize,
  lineHeight: typography.headlineMedium.lineHeight,
  letterSpacing: typography.headlineMedium.letterSpacing,
  fontWeight: 700,
});

export const RightHandSideTitle = styled(Typography, {
  name: 'RightHandSideTitle',
})({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  color: colors.SystemGray900,
  ...typography.headlineSmall,
});

export const RightHandSideFootText = styled(Typography, {
  name: 'RightHandSideFootText',
})({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  color: colors.SystemGray900,
  ...typography.bodyMediumBold,
});

export const SubRegistrationLink = styled(Link, {
  name: 'SubRegistrationLink',
})({
  color: colors.SystemGray900,
  textDecoration: 'underline',
  ...typography.bodySmallRegular,
});

export const SubRegistrationText = styled(Typography, {
  name: 'SubRegistrationText',
})({
  color: colors.SystemGray900,
  ...typography.bodySmallRegular,
});

export const FormStepHeadlineStepCounter = styled(Typography, {
  name: 'FormStepHeadlineStepCounter',
})({
  ...typography.bodySmallRegular,
});

export const FormStepHeadlineTitle = styled(Typography, {
  name: 'FormStepHeadlineTitle',
})({
  ...typography.headlineSmall,
});

export const FormSectionTitle = styled(Typography, {
  name: 'FormSectionTitle',
})({
  color: colors.SystemGray900,
  ...typography.bodyLargeBold,
});

export const FormSectionSubtitle = styled(Typography, {
  name: 'FormSectionSubtitle',
})({
  color: colors.SystemGray600,
  ...typography.bodySmallRegular,
});

export const LogosInformationText = styled(Typography, {
  name: 'LogosInformationText',
})({
  ...typography.bodyMediumRegular,
});

export const AutocompleteInputLabel = styled(Typography, {
  name: 'AutocompleteInputLabel',
})({
  display: 'flex',
  alignItems: 'center',
});

export const FileInputLikeButtonText = styled(Typography, {
  name: 'FileInputLikeButtonText',
})({
  color: colors.SystemGray900,
  ...typography.buttonSmall,
  marginLeft: '4px',
});

export const GrayText = styled('span', {
  name: 'GrayText',
})({
  color: colors.SystemGray400,
});
