import { get } from 'lodash';
import React, { useEffect, useState, useRef } from 'react';
import { z } from 'zod';
import { useAppDispatch } from '../../../hooks';
import { formUpdate } from '../../../Redux/User/UserSlice';

export type SelectedPlace = {
  address: string;
  location: {
    address: string;
    city: string;
    state: string;
    zip: string;
  };
}

export function isSelectedPlace(value: unknown): value is SelectedPlace {
  return (
    typeof get(value, 'address') === 'string' &&
    typeof get(value, 'location.address') === 'string' &&
    typeof get(value, 'location.city') === 'string' &&
    typeof get(value, 'location.state') === 'string' &&
    typeof get(value, 'location.zip') === 'string'
  )
}
const googlePlaceSchema = z.object({
  formatted_address: z.string(),
  address_components: z.array(z.object({
    types: z.array(z.string()),
    long_name: z.string().optional()
  }))
});

export default function PlaceAutoComplete(props: {
  form: string;
  field: string;
  metaField?: string;
  containerClassName?: string;
  className?: string;
  defaultValue?: string;
  value?: string;
  placeholder?: string;
  label?: string;
  locationTypes?: any[];
  dataCy?: string;
  onChange?: (location: SelectedPlace) => void;
}) {
  const [listenerActive, toggleListener] = useState(false);
  const autoCompleteRef = useRef<any>(null);
  const autocompleteContainerRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const error = false;

  const initAutoComplete = () => {
    const google: any = window.google;
    if (google) {
      autoCompleteRef.current = new google.maps.places.Autocomplete(autocompleteContainerRef.current.children[0], {
        // https://developers.google.com/maps/documentation/places/web-service/supported_types
        types: props.locationTypes || ['(cities)'], // Restrict to cities
        componentRestrictions: { country: 'US' }, // Restrict to the United States
      });
      toggleListener(true);
      autoCompleteRef.current.addListener('place_changed', handlePlaceChanged);
    }
  };

  const handlePlaceChanged = () => {
    const place = autoCompleteRef.current.getPlace();
    const parsedPlace = googlePlaceSchema.safeParse(place);
    if (parsedPlace.success === false) {
      return;
    }
    const address = parsedPlace.data.formatted_address;
    if (props.onChange !== undefined) {
      const components = parsedPlace.data.address_components;
      const streetAddress = `${
        components.find((component) => component.types.includes('street_number'))?.long_name ?? ''
      } ${
        components.find((component) => component.types.includes('route'))?.long_name ?? ''
      }`;
      props.onChange({
        address,
        location: {
          address: streetAddress,
          city: components.find((component) => component.types.includes('locality'))?.long_name ?? '',
          state: components.find((component) => component.types.includes('administrative_area_level_1'))?.long_name ?? '',
          zip: components.find((component) => component.types.includes('postal_code'))?.long_name ?? '',
        }
      })
    } else {
      dispatch(
        formUpdate({
          form: props.form,
          field: `meta.${props.metaField ? props.metaField : props.field + '.placeData'}`,
          value: place,
        }),
      );
      dispatch(formUpdate({ form: props.form, field: props.field, value: address }));
    }
  };

  useEffect(() => {
    initAutoComplete();
    return () => {
      if (listenerActive) {
        toggleListener(false);
        autoCompleteRef.current.removeListener('place_changed');
      }
    };
  }, []);

  return (
    <div
      className={`${props.containerClassName ? props.containerClassName : ''}`}
      ref={autocompleteContainerRef}
    >
      <input
        ref={autoCompleteRef}
        className={`border-b-2 p-1 max-w-full w-full rounded focus:border-orange peer ${
          error ? 'border-red-400' : ''
        }${props.className ? props.className : ''}`}
        id="autocomplete-input"
        type="text"
        placeholder={props.placeholder || props.label || 'Enter a location'}
        onChange={handlePlaceChanged}
        defaultValue={props.defaultValue || null}
        autoComplete="Address"
        data-cy={props.dataCy}
        required
      />
    </div>
  );
}
