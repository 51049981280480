import React, { FC, useCallback } from 'react';
import { DataCy } from '../../../Helpers/DataCy';
import { Button } from '../../../ui/buttons/Button';
import { PromoterFormData } from '../../../Forms/AccountInfoForms/Promoter/PromoterFormData';
import {
  FormStepHeadlineContainer,
  FormStepInnerContainer,
  FormTitleWithSubtitleContainer,
  FormStepFormContainerOuter,
  FormMainContainerColumn,
} from '../Shared/Containers';
import { FormStepHeadlineStepCounter, FormStepHeadlineTitle } from '../Shared/RegistrationTypographies';
import { FormStepProps } from '../Shared/Types';
import { useFormContext } from 'react-hook-form';
import { PromoterPersonalDetailsForm } from '../../../Forms/AccountInfoForms/Promoter/PromoterPersonalDetailsForm';

export const PromoterFormStep1: FC<FormStepProps> = ({ step, setStep }) => {
  const promoterForm = useFormContext();

  const handleNextStep = useCallback(async () => {
    const isValid = await promoterForm.trigger(PromoterFormData.step1Fields);
    if (isValid === true) {
      setStep((step) => step + 1);
    }
  }, [promoterForm, setStep]);

  return (
    <FormMainContainerColumn>
      <FormStepHeadlineContainer>
        <FormStepInnerContainer>
          <FormTitleWithSubtitleContainer>
            <FormStepHeadlineStepCounter>Step 1 of 3</FormStepHeadlineStepCounter>
            <FormStepHeadlineTitle>Introduce yourself</FormStepHeadlineTitle>
          </FormTitleWithSubtitleContainer>
        </FormStepInnerContainer>
      </FormStepHeadlineContainer>
      <FormStepFormContainerOuter>
        <FormStepInnerContainer>
          <PromoterPersonalDetailsForm />
          <Button
            isPrimary
            onClick={handleNextStep}
            size="large"
            data-cy={DataCy.button('nextStep')}
          >
            Next
          </Button>
        </FormStepInnerContainer>
      </FormStepFormContainerOuter>
    </FormMainContainerColumn>
  );
};
