import React from 'react';
import { useGetArtistSocialStatisticsQuery } from '../Redux/API/ArtistAPI';

interface Props {}

function authorizeInstagram(response: fb.AuthResponse) {
  console.log(response);
  // use response.accessToken and sent it PUT /me/instagram/credentials
  // you can also sent to this endpoint an instagram user id otherwise the BE will go through the accessible pages and will try to resolve the ID
  // if you need to use the long lived token, get it from GET '/me/instagram/credentials'
}

function ContinueWithInstagram(props: { requiredScopes: string[] }) {
  /**
   * @see https://developers.facebook.com/docs/facebook-login/web/login-button
   */
  return (
    <button
      onClick={() => {
        FB.login(
          (response) => {
            if (response.status !== 'connected') {
              // TODO check also if response.authResponse.grantedScopes has what props.requiredScopes says
              console.warn('User did not log in');
              return;
            }
            authorizeInstagram(response.authResponse);
          },
          {
            scope: props.requiredScopes.join(','),
            return_scopes: true,
          },
        );
      }}
    >
      Continue with FB
    </button>
  );
}

export function InstagramAuth(props: Props) {
  const stats = useGetArtistSocialStatisticsQuery({ artistId: '37766313' });
  if (stats.isLoading === null || stats.data === undefined) {
    return <>Fetching stats...</>;
  }

  if (stats.data.instagram.isAuthorized === true) {
    return <>User already authorized!</>;
  }

  return (
    <>
      <ContinueWithInstagram requiredScopes={stats.data.instagram.requiredScopes} />
    </>
  );
}
