import { RadioGroup as MuiRadioGroup, Radio as MuiRadio, styled, FormControlLabel, FormLabel } from '@mui/material';
import { colors } from '../shared/Colors';
import { typography } from '../shared/TypographySharedElements';

export const RadioGroup = styled(MuiRadioGroup, {
  name: 'RadioGroup',
})({});

export const Radio = styled(MuiRadio, {
  name: 'Radio',
})({
  '&.MuiButtonBase-root.MuiRadio-root.MuiRadio-colorPrimary.PrivateSwitchBase-root ': {
    color: colors.SystemGray300,
    '&.Mui-checked': {
      color: colors.SystemBlack,
    },
  },
});

export const RadioControlLabel = styled(FormControlLabel, {
  name: 'FormControlLabel',
})({
  '.MuiTypography-root': {
    color: colors.SystemGray800,
    ...typography.buttonLarge,
  },
});

export const RadioGroupLabel = styled(FormLabel, {
  name: 'RadioGroupLabel',
})({
  '.MuiTypography-root': {
    color: colors.SystemGray900,
    ...typography.subtitle,
  },
});
