import React, { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import BROKEN_IMAGE from '../../Images/ChickLogo.webp';
import { useGetAllArtistsQuery, useGetAllShowsQuery, useGetAllVenuesQuery } from '../../Redux/API/PublicAPI';
import dayjs from 'dayjs';
import { ParsedNotifications } from '../../Helpers/shared/Models/Notifications';
import { Avatar, Box, Typography, styled } from '@mui/material';
import { colors } from '../../ui/shared/Colors';
import { useAppSelector } from '../../hooks';
import { typography } from '../../ui/shared/TypographySharedElements';

interface DynamicNotificationProps extends ParsedNotifications {
  closeFunction?: (e: React.MouseEvent) => void;
}

export const DynamicNotification: FC<DynamicNotificationProps> = ({
  data,
  multiple,
  timestamp,
  read,
  closeFunction,
  type,
}) => {
  const venues = useGetAllVenuesQuery();
  const artists = useGetAllArtistsQuery();
  const shows = useGetAllShowsQuery();
  const darkMode = useAppSelector((store) => store.ui.grid.darkMode);

  const { notification, destination, image, time } = useMemo(() => {
    let tempNotif;
    let tempDestination;
    let tempImage;
    let timeStamp = timestamp || '';
    let showID = data?.showID;
    let show = shows?.data?.[data?.showID];
    let showName = show?.name || 'a show';
    let artist = artists?.data?.[data?.artistID];
    let venue = venues?.data?.[show?.venueID];
    let venueName = venue?.name || 'a venue';
    let artistName = artist?.stagename || artist?.firstname ? artist.firstname + ' ' + artist.lastname : 'An artist';

    //add logic fo retrieving the venue when we have the showID
    switch (type) {
      case 'TICKETS_SOLD':
        tempDestination = show?.venueID ? '/venues/manage/' + show?.venueID : '/venues/manage';
        tempNotif = (
          <DynamicNoteText darkMode={darkMode}>
            You sold {data.quantity} ticket{data.quantity > 1 && 's'} to {showName}!
          </DynamicNoteText>
        );
        tempImage = venue?.avatarWebP || venue?.avatar || BROKEN_IMAGE;
        break;
      case 'SET_UP_PAYOUTS':
        tempDestination = '/profile';
        tempNotif = (
          <DynamicNoteText darkMode={darkMode}>
            Please visit your profile to enable payouts for future shows!
          </DynamicNoteText>
        );
        tempImage = BROKEN_IMAGE;
        break;
      case 'NEW_APPLICATION':
        tempDestination = show?.venueID ? '/venues/manage/' + show?.venueID : '/venues/manage';
        tempNotif = multiple ? (
          <DynamicNoteText darkMode={darkMode}>
            {multiple} artists applied to perform at {showName}.
          </DynamicNoteText>
        ) : (
          <DynamicNoteText darkMode={darkMode}>
            {artistName} applied to perform at {showName}.
          </DynamicNoteText>
        );
        tempImage = artist?.avatar || BROKEN_IMAGE;
        break;
      case 'APPLICATION_ACCEPTED':
        tempDestination = '/artist/manage-shows';
        tempNotif = (
          <DynamicNoteText darkMode={darkMode}>
            {venueName} accepted your application to perform at {showName}!
          </DynamicNoteText>
        );
        tempImage = venue?.avatarWebP || venue?.avatar || BROKEN_IMAGE;
        break;
      case 'APPLICATION_ACCEPTED_REQUIRES_CONFIRMATION':
        tempDestination = `/qc/${showID}`;
        tempNotif = (
          <DynamicNoteText darkMode={darkMode}>
            {venueName} accepted your application to perform at {showName} and requires a text confirmation!
          </DynamicNoteText>
        );
        tempImage = venue?.avatarWebP || venue?.avatar || BROKEN_IMAGE;
        break;
      case 'INVITE_ACCEPTED':
        tempDestination = show?.venueID ? '/venues/manage/' + show?.venueID : '/venues/manage';
        tempNotif = multiple ? (
          <DynamicNoteText darkMode={darkMode}>
            {multiple} artists accepted your invite to perform at {showName}.
          </DynamicNoteText>
        ) : (
          <DynamicNoteText darkMode={darkMode}>
            {artistName} accepted your invite to perform at {showName}.
          </DynamicNoteText>
        );
        tempImage = artist?.avatar || BROKEN_IMAGE;
        break;
      case 'NEW_INVITE':
        tempDestination = '/artist/manage-shows';
        tempNotif = multiple ? (
          <DynamicNoteText darkMode={darkMode}>
            {multiple} venues invited you to perform at upcoming shows!
          </DynamicNoteText>
        ) : (
          <DynamicNoteText darkMode={darkMode}>
            {venueName} invited you to perform at {showName}.
          </DynamicNoteText>
        );
        tempImage = venue?.avatarWebP || venue?.avatar || BROKEN_IMAGE;
        break;
      case 'SHOW_RESCHEDULED':
        tempDestination = showID ? '/shows/' + showID : '/shows';
        tempNotif = (
          <DynamicNoteText darkMode={darkMode}>
            {showName} has been rescheduled. The new time is {data.newTime}
          </DynamicNoteText>
        );
        tempImage = venues?.data[show?.venueID]?.avatarWebP || venues?.data[show?.venueID]?.avatar || BROKEN_IMAGE;
        break;
      case 'SHOW_PUBLISHED':
        tempDestination = showID ? '/shows/' + showID : '/shows';
        tempNotif = <DynamicNoteText darkMode={darkMode}>{showName} has been published.</DynamicNoteText>;
        tempImage = venues?.data[show?.venueID]?.avatarWebP || venues?.data[show?.venueID]?.avatar || BROKEN_IMAGE;
        break;
      default:
        break;
    }

    return {
      notification: tempNotif,
      destination: tempDestination,
      image: tempImage,
      time: dayjs(timeStamp).format('MM.D.YY | h:mmA'),
    };
  }, [
    artists?.data,
    data?.artistID,
    data.newTime,
    data.quantity,
    data?.showID,
    multiple,
    shows?.data,
    timestamp,
    type,
    venues?.data,
  ]);

  return notification && destination && image ? (
    <DynamicNoteCont>
      <Link to={destination}>
        <DynamicNoteInnerCont
          read={read}
          darkMode={darkMode}
          onClick={closeFunction}
        >
          <DynamicNoteIconCont>
            <DynamicNoteIcon src={image} />
          </DynamicNoteIconCont>
          <div className="w-full p-2">
            {notification}
            <DynamicNoteSubText darkMode={darkMode}>{time}</DynamicNoteSubText>
          </div>
        </DynamicNoteInnerCont>
      </Link>
    </DynamicNoteCont>
  ) : (
    <></>
  );
};

const DynamicNoteCont = styled(Box, {
  name: 'DynamicNoteCont',
})({
  display: 'flex',
  flexDirection: 'column',
  padding: '16px',
});

const DynamicNoteInnerCont = styled(Box, {
  name: 'DynamicNoteInnerCont',
  shouldForwardProp: (prop) => prop !== 'darkMode' && prop !== 'read',
})<{ read: boolean; darkMode: boolean }>(({ read, darkMode }) => ({
  display: 'flex',
  backgroundColor: darkMode ? colors.SystemBlack : !read ? colors.SystemGray100 : colors.SystemWhite,
}));

const DynamicNoteText = styled(Typography, {
  name: 'DynamicNoteText',
  shouldForwardProp: (prop) => prop !== 'darkMode',
})<{ darkMode: boolean }>(({ darkMode }) => ({
  color: darkMode ? colors.SystemWhite : colors.SystemGray900,
  ...typography.bodyMediumRegular,
}));

const DynamicNoteSubText = styled(Typography, {
  name: 'DynamicNoteSubText',
  shouldForwardProp: (prop) => prop !== 'darkMode',
})<{ darkMode: boolean }>(({ darkMode }) => ({
  color: darkMode ? colors.SystemGray200 : colors.SystemGray500,
  ...typography.bodySmallRegular,
}));

const DynamicNoteIconCont = styled(Box, {
  name: 'DynamicNoteIconCont',
})({
  width: '40px',
  height: '40px',
  marginTop: '8px',
});

const DynamicNoteIcon = styled(Avatar, {
  name: 'DynamicNoteIcon',
})({
  width: '40px',
  height: '40px',
  borderRadius: '999px',
});
