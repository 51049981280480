import { Box, styled } from '@mui/material';
import { colors } from '../../../ui/shared/Colors';

export const LeftSideComponentContainer = styled(Box, {
  name: 'LeftSideComponentContainer',
})({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  gap: '24px',
  padding: '24px',
  backgroundColor: colors.SystemWhite,
  borderRadius: '16px',
  width: '100%',
  maxWidth: '400px',
});

export const RightHandSideComponentContainer = styled(Box, {
  name: 'RightHandSideComponentContainer',
})({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  padding: '24px',
  borderRadius: '16px',
  backgroundColor: colors.SystemWhite,
  height: 'auto',
});

export const RightHandSideComponentContainerDark = styled(Box, {
  name: 'RightHandSideComponentContainerDark',
})({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  padding: '24px',
  borderRadius: '16px',
  backgroundColor: '#576574',
});
