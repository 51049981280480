import { ViewType } from '../../../Helpers/shared/Models/ViewType';
import { useUpdateArtistColorsMutation } from '../../../Redux/API/ArtistAPI';
import { useUpdateSRGColorsMutation } from '../../../Redux/API/ShowrunnerAPI';
import { useUpdateVenueColorsMutation } from '../../../Redux/API/VenueAPI';

interface useSendColorDataProps {
  viewType: ViewType;
}

export const useSendColorData = ({ viewType }: useSendColorDataProps) => {
  const [updateVenueColor] = useUpdateVenueColorsMutation();
  const [updateSRGColor] = useUpdateSRGColorsMutation();
  const [updateArtistColor] = useUpdateArtistColorsMutation();
  switch (viewType) {
    case ViewType.Artist:
      return updateArtistColor;
    case ViewType.SRGroup:
      return updateSRGColor;
    case ViewType.Venue:
      return updateVenueColor;
  }
};
