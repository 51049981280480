import React, { useEffect, useState, useCallback } from 'react';
import { Box, Typography, styled, useTheme, useMediaQuery } from '@mui/material';
import { debounce } from 'lodash'; // Make sure to import debounce from lodash

import { useGetAllArtistsV2Query } from '../../Redux/API/ArtistAPI';
import ArtistCategory from './ExploreArtistsCategory';
import CoverImage from '../../Images/Banners/exploreArtists.webp';
import { objToArray, renderPageTitle } from '../../Helpers/HelperFunctions';
import LoadingWrapper from '../../Components/Layout/LoadingWrapper';
import {
  GENRE_MAP,
  // TH_GENRES,
  TH_SAFE_GENRES,
} from '../../Helpers/configConstants';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { setDarkMode } from '../../Redux/UI/UISlice';
import { TextField } from '../../ui/inputs/TextField';
import { typography } from '../../ui/shared/TypographySharedElements';
import { colors } from '../../ui/shared/Colors';
import Img from '../../Components/Images/Img';

export default function Artists() {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [search, setSearch] = useState('');

  // Only set search after 350ms of no input
  const debouncedSetSearch = useCallback(
    debounce((value: string) => {
      setSearch(value);
    }, 350),
    [],
  );

  const artistsV2 = useGetAllArtistsV2Query({}); //only used for the LoadingWrapper

  useEffect(() => {
    renderPageTitle('Artists');
  }, []);

  useEffect(() => {
    dispatch(setDarkMode({ status: true }));
    return () => {
      dispatch(setDarkMode({ status: false }));
    };
  }, [dispatch]);

  return (
    <LoadingWrapper queryResponse={[artistsV2]}>
      <HeaderSection>
        <HeaderContainer>
          <ImageContainer>
            <BannerImage src={CoverImage} />
          </ImageContainer>
          <HeaderTextSection>
            <HeaderTitle>Explore Artists</HeaderTitle>
            <HeaderSearchBar
              placeholder="Search for an artist"
              onChange={(e) => debouncedSetSearch(e.target.value)}
              InputProps={{
                startAdornment: <i className="material-symbols-outlined">search</i>,
              }}
            />
          </HeaderTextSection>
        </HeaderContainer>
      </HeaderSection>
      <MainContent>
        <ArtistCategoriesContainer>
          {!search && (
            <>
              <ArtistCategory
                hideIfHatchy
                title="Hatched"
                hatched="true"
                search={search}
              />
              <ArtistCategory
                hideIfHatchy
                title="Most Shows"
                sortBy="mostShows"
                search={search}
              />
              <ArtistCategory
                hideIfHatchy
                title="Most Venues"
                sortBy="mostVenues"
                search={search}
              />
              {TH_SAFE_GENRES.map((genre, i) => {
                return (
                  <ArtistCategory
                    hideIfHatchy
                    title={GENRE_MAP[genre]}
                    genre={genre}
                    search={search}
                    minLength={4}
                  />
                );
              })}
            </>
          )}
          <ArtistCategory
            hideIfHatchy
            title="All Artists"
            search={search}
            emptyMessage="Sorry, no artists were found for the given search"
            wrap={!isMobile}
          />
        </ArtistCategoriesContainer>
      </MainContent>
    </LoadingWrapper>
  );
}

const HeaderSection = styled(Box, {
  name: 'HeaderSection',
})({
  backgroundColor: colors.SystemBlack,
  display: 'flex',
  justifyContent: 'center',
});

const HeaderContainer = styled(Box, {
  name: 'HeaderContainer',
})(({ theme }) => ({
  position: 'relative',
  width: '100%',
  maxWidth: '1280px',
  height: '404px',
  maxHeight: '45vh',
  display: 'flex',
  flexDirection: 'row-reverse',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    height: '464px',
  },
}));

const HeaderTextSection = styled(Box, {
  name: 'HeaderTextSection',
})(({ theme }) => ({
  display: 'flex',
  flexGrow: 1,
  flexDirection: 'column',
  padding: '20px',
  gap: '80px',
  [theme.breakpoints.down('lg')]: {
    justifyContent: 'flex-end',
    padding: '32px',
    gap: '32px',
  },
}));

const ImageContainer = styled(Box, {
  name: 'ImageContainer',
})(({ theme }) => ({
  position: 'relative',
  width: '694px',
  height: '100%',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const BannerImage = styled(Img, {
  name: 'BannerImage',
})(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  objectPosition: '0% 75%',
  maskImage: 'linear-gradient(to right, transparent, black 25%, black 75%, transparent)',
  webkitMaskImage: 'linear-gradient(to right, transparent, black 25%, black 75%, transparent)',
  [theme.breakpoints.down('md')]: {
    maskImage: 'linear-gradient(black 75%, transparent)',
    webkitMaskImage: 'linear-gradient(black 75%, transparent)',
  },
}));

const HeaderTitle = styled(Typography, {
  name: 'HeaderTitle',
})(({ theme }) => ({
  ...typography.displaySmall,
  fontFamily: 'Satoshi-Variable',
  color: colors.SystemWhite,
  [theme.breakpoints.down('md')]: {
    ...typography.headlineMedium,
    textAlign: 'center',
  },
}));

const HeaderSearchBar = styled(TextField, {
  name: 'HeaderSearchBar',
})({
  width: '350px',
  '&&&&& .MuiInputBase-input': {
    border: `0px`,
  },
  '&&&&& .MuiInputBase-root': {
    backgroundColor: colors.SystemWhite,
    borderRadius: '12px',
    border: `1px solid ${colors.SystemGray200}`,
    paddingLeft: '12px',
  },
});

const MainContent = styled(Box, {
  name: 'MainContent',
})({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: colors.SystemGray100,
});

const ArtistCategoriesContainer = styled(Box, {
  name: 'ArtistCategoriesContainer',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  margin: '80px',
  gap: '60px',
  maxWidth: '1280px',
  width: '100vw',
  [theme.breakpoints.down('lg')]: {
    padding: '0 20px',
    margin: '40px',
    gap: '40px',
  },
}));
