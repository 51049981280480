import React, { useEffect, useState, useMemo } from 'react';
import { ViewType } from '../Helpers/shared/Models/ViewType';
import { useAppSelector } from '../hooks';
import { getArtistName, groupArtistShows, sortShowsByDate } from '../Helpers/HelperFunctions';
import { Navigate, useNavigate } from 'react-router-dom';
import ShowTileCollection from '../Components/Collections/ShowTileCollection';
import { DataCy } from '../Helpers/DataCy';
import { useGetAllShowsQuery, useGetAllVenuesQuery, useArtistShows } from '../Redux/API/PublicAPI';
import { useParams } from 'react-router-dom';
import ShowCalendar from '../Tools/ShowCalendar';
import LoadingWrapper from '../Components/Layout/LoadingWrapper';
import { LabelButton } from '../Components/Buttons/LabelButton';
import { openModal } from '../Redux/UI/UISlice';
import { FilterByShowTime } from '../Helpers/FilterFunctions/ShowFilterFunctions';
import { Button } from '../Components/Buttons/Button';
import { renderPageTitle } from '../Helpers/HelperFunctions';
import { UpcomingShowShares } from '../Tools/Venue/UpcomingShowShares';
import StripeHandler from '../Components/Buttons/StripeHandler';
import Lo, { isEmpty } from 'lodash';
import { useGetExternalShowsByArtistQuery } from '../Redux/API/ShowAPI';
import ExternalShowTileCollection from '../Components/Collections/ExternalShowTileCollection';

function useGroupedShows(params: { artistShows: ReturnType<typeof useArtistShows>; artistId: string }) {
  const { artistShows, artistId } = params;
  const { isSuccess, data } = artistShows;
  return useMemo(() => {
    const groupedShows = groupArtistShows(data, artistId, true, true);
    return {
      ...groupedShows,
      managingAndPerforming: Lo.keyBy([...groupedShows.performing, ...groupedShows.managing], '_key'),
    };
  }, [isSuccess, data]);
}

export default function ArtistShows() {
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user.data);
  const shows = useArtistShows({ artistId: user.displayUID });
  const groupedShows = useGroupedShows({ artistShows: shows, artistId: user.displayUID });
  const externalShows = useGetExternalShowsByArtistQuery({ artistID: user.displayUID });
  return (
    <LoadingWrapper queryResponse={[shows, externalShows]}>
      {user && !user.uid && (
        <Navigate
          replace
          to="/shows"
        />
      )}
      <>
        <div>
          <h1 className="text-2xl font-black text-center">{getArtistName(user)}</h1>
          <div className="flex flex-col lg:flex-row justify-center mt-2 gap-2 items-center">
            <Button
              className="text-xs text-white border bg-amber-400"
              link={`/profile/edit/artist/${user.displayUID}`}
            >
              Edit Profile
            </Button>
            <StripeHandler
              useLabelButton
              className="flex justify-center transition-all text-center items-center disabled:bg-gray-400 bg-orange gap-1 p-4
                                  rounded-md min-w-max w-28 h-11
                                  text-white border-gray-300
                                  filter hover:brightness-105
                                  cursor-pointer
                                  text-xs border font-bold"
              viewType={ViewType.Artist}
              targetID={user.displayUID}
              stripeID={user.stripeID}
            />
            <Button
              inline
              className="text-xs text-white border bg-blue-400"
              link={'/profile/u/' + user.displayUID}
            >
              Profile Page
            </Button>
          </div>
          <div className="flex justify-center m-2">
            <LabelButton
              className="text-sm text-gray-500 border border-gray-500"
              iconClassName="text-base"
              icon="add"
              onClick={() => {
                navigate(`/create-show/artist/${user.displayUID}`);
              }}
            >
              New Show
            </LabelButton>
          </div>
        </div>
        <ShowCalendar
          artistID={user.displayUID}
          viewType={ViewType.Artist}
          filterFn={FilterByShowTime}
          shows={groupedShows.managingAndPerforming}
        />
      </>
      <div className="flex flex-col">
        <div className="px-2 flex flex-col">
          <ShowTileCollection
            viewType={ViewType.Artist}
            type="confirmed"
            manageView
            title="Upcoming Shows"
            containerClassName="mt-2"
            shows={sortShowsByDate(groupedShows?.performing) || []}
          />
          <ShowTileCollection
            viewType={ViewType.Artist}
            type="invited"
            title="Pending Invites"
            containerClassName="mt-2"
            shows={sortShowsByDate(groupedShows?.invited) || []}
          />
          <ExternalShowTileCollection
            viewType={ViewType.Artist}
            manageView
            title="External Shows"
            containerClassName="mt-2"
            shows={externalShows.data}
          />
          <ShowTileCollection
            viewType={ViewType.Artist}
            type="applied"
            manageView
            title="Applications"
            containerClassName="mt-2"
            shows={sortShowsByDate(groupedShows?.applied) || []}
          />
          <ShowTileCollection
            viewType={ViewType.Artist}
            type="past"
            manageView
            title="Past Shows"
            containerClassName="mt-2"
            shows={sortShowsByDate(groupedShows?.past) || []}
          />
          <ShowTileCollection
            viewType={ViewType.Artist}
            type="confirmed"
            manageView
            title="Managed shows"
            containerClassName="mt-2"
            shows={sortShowsByDate(groupedShows?.managing) || []}
          />
        </div>
        {groupedShows?.applied?.length === 0 && groupedShows?.invited?.length === 0 && (
          <>
            <div className="bg-amber-400 w-50 rounded mt-2 justify-center m-2">
              <h1 className="text-2xl text-center text-white pb-2 font-bold">Want more gigs?</h1>
              <p className="text-sm text-center text-white p-2">
                You currently don't have any pending invites or applications. Check out the{' '}
                <a
                  href="/apply"
                  className="text-blue-400"
                >
                  Apply page
                </a>{' '}
                for gigs you may be interested in playing.
              </p>
            </div>
          </>
        )}
      </div>
    </LoadingWrapper>
  );
}
