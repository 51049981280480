import React, { FC, useState } from 'react';
import { Box, Typography, styled } from '@mui/material';
import { colors } from '../../../ui/shared/Colors';
import { typography } from '../../../ui/shared/TypographySharedElements';
import { Button } from '../../../ui/buttons/Button';
import TicketHand from '../../../Images/VenueLP/ticketHand.svg';
import Mic from '../../../Images/VenueLP/mic.svg';
import VideoPreview from '../../../Images/VenueLP/videoPreview.png';
import { HeroVidModal } from './HeroVidModal';
import { useNavigate } from 'react-router-dom';
import { useOpenBookDemoLink } from '../bookDemo';

export const Hero: FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const openBookDemoLink = useOpenBookDemoLink();

  return (
    <MainHeroContainer>
      <HeroInner>
        <LeftHandSideContainer>
          <InfoChip>
            <InfoChipText>TuneHatch for Venues</InfoChipText>
          </InfoChip>
          <HeroMainContainer>
            <HeroMainText>Organize shows and manage your venue with ease</HeroMainText>
            <HeroMainSubText>
              TuneHatch is an end to end solution for venue management, helping you book talent, promote shows, and sell
              tickets all in one easy to use streamlined platform.
            </HeroMainSubText>
          </HeroMainContainer>
          <ButtonGroup>
            <Button
              isPrimary
              size="large"
              onClick={() => navigate('/register/venue')}
            >
              Create free account
            </Button>
            <Button
              size="large"
              disabled={false}
              onClick={openBookDemoLink}
            >
              Book a demo
            </Button>
          </ButtonGroup>
        </LeftHandSideContainer>
      </HeroInner>
      <HeroVidContainer
        onClick={() => {
          setIsModalOpen(true);
        }}
      >
        <LeftBottomIcon src={Mic} />
        <VideoContent src={VideoPreview} />
        <RightTopIcon src={TicketHand} />
      </HeroVidContainer>
      <HeroVidModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
      />
    </MainHeroContainer>
  );
};

const InfoChipText = styled(Typography, {
  name: 'InfoChipText',
})({
  color: colors.SystemGray900,
  ...typography.buttonSmall,
});

const InfoChip = styled(Box, {
  name: 'InfoChip',
})({
  borderRadius: '0 16px 16px 16px',
  backgroundColor: '#FFB54B',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '12px 20px',
  gap: '8px',
});

const HeroMainText = styled(Typography, {
  name: 'HeroMainText',
})(({ theme }) => ({
  color: colors.SystemGray900,
  ...typography.displaySmall,
  [theme.breakpoints.down('md')]: {
    ...typography.headlineLarge,
  },
}));

const HeroMainSubText = styled(Typography, {
  name: 'HeroMainSubText',
})({
  color: colors.SystemGray800,
  ...typography.bodyLargeRegular,
});

const HeroMainContainer = styled(Box, {
  name: 'HeroMainContainer',
})({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  gap: '12px',
  textAlign: 'left',
});

const ButtonGroup = styled(Box, {
  name: 'ButtonGroup',
})({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  gap: '12px',
});

const LeftHandSideContainer = styled(Box, {
  name: 'LeftHandSideContainer',
})({
  alignSelf: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  gap: '48px',
  maxWidth: '404px',
  width: '100%',
});

const HeroInner = styled(Box, {
  name: 'HeroInner',
})({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
});

const VideoContent = styled('img', {
  name: 'VideoContent',
})({
  borderRadius: '12px',
  maxWidth: '756px',
  overflow: 'hidden',
  width: '100%',
});

const MainHeroContainer = styled(Box, {
  name: 'MainHeroContainer',
})(({ theme }) => ({
  display: 'flex',
  width: '100%',
  position: 'relative',
  backgroundColor: colors.SystemWhite,
  overflow: 'hidden',
  flexShrink: 0,
  textAlign: 'center',
  fontSize: '16px',
  color: '#0e0e0e',
  alignItems: 'center',
  padding: '160px 66px 140px 80px',
  maxWidth: '1440px',
  gap: '13.5%',
  margin: '0 auto',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '48px',
    padding: '40px 20px',
  },
}));

const HeroVidContainer = styled(Box, {
  name: 'HeroVidContainer',
})({
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',
  cursor: 'pointer',
});

const LeftBottomIcon = styled('img', {
  name: 'LeftBottomIcon',
})(({ theme }) => ({
  position: 'absolute',
  top: '85%',
  left: '-5%',
  width: '160px',
  height: '160px',
  [theme.breakpoints.down('md')]: {
    width: '80px',
    height: '80px',
    left: '-2%',
  },
}));

const RightTopIcon = styled('img', {
  name: 'RightTopIcon',
})(({ theme }) => ({
  position: 'absolute',
  top: '-15%',
  right: '-5%',
  width: '136px',
  height: '136px',
  [theme.breakpoints.down('md')]: {
    width: '68px',
    height: '68px',
    right: '-2%',
    top: '-5%',
  },
}));
