import { useMemo } from 'react';
import { ArtistObject } from '../../Helpers/shared/Models/Artist';
import { NewModels } from '../../Helpers/shared/Models/NewModels';
import { Show, ShowManagerType } from '../../Helpers/shared/Models/Show';
import { Showrunner } from '../../Helpers/shared/Models/Showrunner';
import { UserObject } from '../../Helpers/shared/Models/User';
import { Venue } from '../../Helpers/shared/Models/Venue';
import { setAppLoading } from '../UI/UISlice';
import { baseAPI } from './BaseAPI';
import { QueryParser } from './Utils/QueryParser';

export const publicAPI = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    getAllShows: builder.query<{ [id: string]: Show }, void>({
      query: () => '/get-all-shows',
      providesTags: (result) =>
        Object.keys(result!).map((res) => {
          return {
            type: 'Show',
            id: res,
          };
        }),
    }),
    getShow: builder.query<NewModels.Show, { showId: string }>({
      query: (query) => `/shows/${query.showId}`,
      providesTags: (result) => {
        return [
          {
            type: 'Show',
            id: result.id,
          },
        ];
      },
    }),
    listShows: builder.query<
      {
        data: NewModels.Show[];
        total: number;
        perPage: number;
        page: number;
      },
      {
        page?: number;
        perPage?: number;
        venueId?: string;
        showrunnerId?: string;
      }
    >({
      query: (query) => `/shows?${QueryParser.stringify(query)}`,
      providesTags: (result) =>
        result.data.map((show) => {
          return {
            type: 'Show',
            id: show.id,
          };
        }),
    }),
    getAllShowrunnerGroups: builder.query<{ [id: string]: Showrunner }, void>({
      query: () => '/get-sr-groups',
      providesTags: (result) =>
        Object.keys(result!).map((res) => {
          return {
            type: 'SRG',
            id: res,
          };
        }),
    }),
    getAllArtists: builder.query<{ [id: string]: ArtistObject }, void>({
      // The URL for the request is '/api/get-all-artists'
      query: () => '/get-all-artists',
      providesTags: (result) =>
        Object.keys(result!).map((res) => {
          return {
            type: 'Artist',
            id: res,
          };
        }),
    }),
    setPaymentIntent: builder.mutation<any, any>({
      query: (args: { showID: string; cart: object; name?: string; email?: string; intentID: string }) => ({
        method: 'POST',
        url: '/set-payment-intent',
        body: args,
      }),
    }),
    getAllVenues: builder.query<{ [id: string]: Venue }, void>({
      // The URL for the request is '/api/get-all-artists'
      query: () => '/get-all-venues',
      providesTags: (result) =>
        Object.keys(result).map((res) => {
          return {
            type: 'Venue',
            id: res,
          };
        }),
    }),
    getUsers: builder.query<{ [id: string]: UserObject }, string[]>({
      query: (ids) => ({
        method: 'POST',
        url: '/get-users',
        body: { ids },
      }),
      providesTags: (result) =>
        Object.keys(result!).map((res) => {
          return {
            type: 'Artist',
            id: res,
          };
        }),
    }),
    logIn: builder.mutation<any, any>({
      query: (form) => ({
        url: '/log-in',
        method: 'POST',
        body: form,
      }),
    }),
    logOut: builder.mutation<any, any>({
      query: () => ({
        url: '/log-out',
      }),
    }),
    cookieLogIn: builder.mutation<any, any>({
      query: (SECRET_UID) => ({
        method: 'POST',
        url: '/verify-user',
        body: { SECRET_UID },
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        dispatch(setAppLoading(true));

        try {
          await queryFulfilled; // Wait for the query to complete
          dispatch(setAppLoading(false));
        } catch {
          dispatch(setAppLoading(false));
          // Handle the error if the query fails
          // For example: dispatch(someErrorAction());
        }
      },
    }),
    generateTickets: builder.mutation<any, any>({
      query: (args: {
        SECRET_UID: string;
        showID: string;
        venueID: string;
        intentID: string;
        email?: string;
        name?: string;
        cart: object;
        method: string;
      }) => ({
        url: '/generate-tickets',
        method: 'POST',
        body: args,
      }),
    }),
    setWAYGTS: builder.mutation<any, any>({
      query: (args) => ({
        url: '/waygts',
        method: 'POST',
        body: args,
      }),
    }),
    updateStripeReceiptEmail: builder.mutation<any, { email: string; intentID: string; resendTickets?: boolean }>({
      query: (args) => ({
        url: '/update-receipt-email',
        method: 'POST',
        body: args,
      }),
    }),
    resolveUsername: builder.query<any, any>({
      query: (args: { username: string }) => ({
        url: `/v2/username/${args.username}`,
        method: 'GET',
      }),
    }),
    getSpotlight: builder.query<any, void>({
      query: () => ({
        url: '/get-spotlight-info',
        method: 'GET',
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllArtistsQuery,
  useGetSpotlightQuery,
  useLogInMutation,
  useGenerateTicketsMutation,
  useGetUsersQuery,
  useGetAllShowsQuery,
  useGetShowQuery,
  useListShowsQuery,
  useCookieLogInMutation,
  useGetAllVenuesQuery,
  useGetAllShowrunnerGroupsQuery,
  useSetWAYGTSMutation,
  useSetPaymentIntentMutation,
  useUpdateStripeReceiptEmailMutation,
  useResolveUsernameQuery,
} = publicAPI;

const emptyObject = {};
export function useSRGroupShows(params: { srGroupId: string }) {
  const { srGroupId } = params;
  const usedShows = useGetAllShowsQuery();
  const showMap = usedShows.data ?? emptyObject;

  const filteredShowMap = useMemo(() => {
    const shows: Show[] = Object.values(showMap);
    const srGroupShows = shows.filter((show) => {
      const isManagableByManagers = show.manageableByManagers === true;
      const isManager = (show.managers || []).some(
        (manager) => manager.type === ShowManagerType.SRGroup && manager.id === srGroupId,
      );
      const isRunningShow = (show.showrunner || []).some(
        (srGroup) => srGroup.id === srGroupId || srGroup.uid === srGroupId,
      );

      return (isManagableByManagers && isManager) || isRunningShow;
    });

    return Object.fromEntries(srGroupShows.map((show) => [show._key, show]));
  }, [showMap]);

  const { isFetching, isLoading, isError } = usedShows;
  return useMemo(() => {
    return {
      data: filteredShowMap,
      isFetching,
      isLoading,
      isError,
    };
  }, [filteredShowMap, isFetching, isLoading, isError]);
}

export function useVenueShows(params: { venueId: string }) {
  const { venueId } = params;
  const usedShows = useGetAllShowsQuery();
  const showMap = usedShows.data ?? emptyObject;

  const filteredShowMap = useMemo(() => {
    const shows: Show[] = Object.values(showMap);
    const venueShows = shows.filter((show) => show.venueID === venueId);
    return Object.fromEntries(venueShows.map((show) => [show._key, show]));
  }, [showMap]);

  const { isFetching, isLoading, isError } = usedShows;
  return useMemo(() => {
    return {
      data: filteredShowMap,
      isFetching,
      isLoading,
      isError,
    };
  }, [filteredShowMap, isFetching, isLoading, isError]);
}

export function useArtistShows(params: { artistId: string }) {
  const usedShows = useGetAllShowsQuery();
  const showMap = usedShows.data ?? emptyObject;

  const filteredShowMap = useMemo(() => {
    const shows: Show[] = Object.values(showMap);
    const venueShows = shows.filter((show) => {
      const isManagableByManagers = show.manageableByManagers === true;
      const isManager = (show.managers ?? []).some(
        (manager) => manager.type === ShowManagerType.Artist && manager.id === params.artistId,
      );
      const isPerforming = (show.performers ?? []).some(
        (performer) => performer.id === params.artistId || performer.uid === params.artistId,
      );
      const isApplying = (show.applications ?? []).some(
        (performer) => performer.uid === params.artistId && performer.status !== 'rejected',
      );
      const isInvited = (show.invites ?? []).some(
        (performer) => performer.uid === params.artistId && performer.status !== 'rejected',
      );

      return (isManagableByManagers && isManager) || isPerforming || isApplying || isInvited;
    });

    return Object.fromEntries(venueShows.map((show) => [show._key, show]));
  }, [showMap]);

  const { isFetching, isLoading, isError, isSuccess } = usedShows;
  return useMemo(() => {
    return {
      data: filteredShowMap,
      isFetching,
      isLoading,
      isError,
      isSuccess,
    };
  }, [filteredShowMap, isFetching, isLoading, isError, isSuccess]);
}

export const useGetShowsQueryState = publicAPI.endpoints.getAllShows.useQueryState;
