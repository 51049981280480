import React, { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { ShowType } from '../SingleTicketTypeButton';
import { ShowFormStep1 } from './ShowFormStep1';
import { ViewType } from '../../../Helpers/shared/Models/ViewType';
import { ExternalTicketsForm } from '../ExternalTickets/ExternalTicketsForm';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useGetExternalShowsByArtistQuery, useGetExternalShowsByShowrunnerQuery } from '../../../Redux/API/ShowAPI';
import { ShowFormStep2 } from './ShowFormStep2';
import { ShowFormStep3 } from './ShowFormStep3';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import z, { number } from 'zod';
import { ShowFormStep4 } from './ShowFormStep4';
import { ShowFormStep5 } from './ShowFormStep5';
import { CancelModal } from '../CancelModal';
import {
  useGetAllArtistsQuery,
  useGetAllShowrunnerGroupsQuery,
  useGetAllShowsQuery,
  useGetAllVenuesQuery,
} from '../../../Redux/API/PublicAPI';
import { isArray, isEmpty, get } from 'lodash';
import { compareAsc } from 'date-fns';
import { locationValueSchema } from '../../../ui/inputs/LocationField';
import { getDefaultShowDate } from './helperFunctions';
import { getShowCreationFormSchema } from './ShowCreationFormSchema';
import { useAppSelector } from '../../../hooks';
import { SingleOption } from '../../../ui/inputs/ChipMultiselect';
import { TH_FLYER_TEMPLATES } from '../../../Helpers/flyerConfig';

export const ShowForm: FC = () => {
  const { viewType, viewID, showID } = useParams();
  const [search, setSearch] = useSearchParams();
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [showType, setShowType] = useState<ShowType>(null);
  const [showrunnerOptions, setShowrunnerOptions] = useState<SingleOption[]>([]);
  const [showrunnersSelectValue, setShowrunnersSelectValue] = useState<SingleOption[]>([]);
  const [artistOptions, setArtistOptions] = useState<SingleOption[]>([]);
  const [artistSelectValue, setArtistSelectValue] = useState<SingleOption[]>([]);
  const [venueOptions, setVenueOptions] = useState<SingleOption[]>([]);
  const [venueSelectValue, setVenueSelectValue] = useState<SingleOption[]>([]);
  const [offPlatformArtists, setOffPlatformArtists] = useState<SingleOption[]>([]);
  const [offPlatformShowrunners, setOffPlatformShowrunners] = useState<SingleOption[]>([]);
  const [offPlatformVenues, setOffPlatformVenues] = useState<SingleOption[]>([]);
  const [repeatingShow, setRepeatingShow] = useState(false);
  const [showLength, setShowLength] = useState<number>(null);
  const [tab, setTab] = useState<'builder' | 'upload'>('builder');
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [previewImage, setPreviewImage] = useState<ReactNode>(null);
  const [sliderValue, setSliderValue] = useState<number>(0);
  const [selectedTemplate, setSelectedTemplate] = useState<any>(TH_FLYER_TEMPLATES[0]);
  const artistID = viewType === 'artist' ? viewID : null;
  const showrunnerID = viewType === 'showrunner' ? viewID : null;
  const venueID = viewType === 'venue' ? viewID : null;
  const isEdit = showID ? true : false;
  const user = useAppSelector((state) => state.user.data);
  const navigate = useNavigate();
  const showCreationFormSchema = getShowCreationFormSchema(isEdit);
  type ShowCreationFormFieldNames = keyof z.infer<typeof showCreationFormSchema>;
  const externalShowsByArtist = useGetExternalShowsByArtistQuery({ artistID: viewID });
  const externalShowsByShowrunner = useGetExternalShowsByShowrunnerQuery({ SGID: showrunnerID });
  const venues = useGetAllVenuesQuery();
  const artists = useGetAllArtistsQuery();
  const showrunners = useGetAllShowrunnerGroupsQuery();
  const shows = useGetAllShowsQuery(null, { skip: !showID });
  const currentShow = shows?.data?.[showID];
  const showCreationInitialFormValues: z.infer<typeof showCreationFormSchema> = useMemo(
    () => ({
      name: currentShow?.name ? currentShow?.name : '',
      description: currentShow?.description ? currentShow?.description : '',
      showrunners:
        isEdit && !isEmpty(currentShow)
          ? currentShow.showrunner.map((showrunner) => ({ id: showrunner.id })) ?? []
          : viewType === 'showrunner'
            ? [{ id: showrunnerID }]
            : [],
      artists:
        isEdit && !isEmpty(currentShow)
          ? currentShow.performers.map((performer) => ({ id: performer.uid as string })) ?? []
          : viewType === 'artist'
            ? [{ id: artistID }]
            : [],
      venues:
        isEdit && !isEmpty(currentShow) ? [{ id: currentShow.venueID }] : viewType === 'venue' ? [{ id: venueID }] : [],
      startTime:
        isEdit && !isEmpty(currentShow)
          ? new Date(currentShow.starttime)
          : search.get('startDate')
            ? new Date(search.get('startDate'))
            : getDefaultShowDate(true),
      endTime:
        isEdit && !isEmpty(currentShow)
          ? new Date(currentShow.endtime)
          : search.get('endDate')
            ? new Date(search.get('endDate'))
            : getDefaultShowDate(false),
      minAge:
        isEdit && !isEmpty(currentShow)
          ? `${currentShow.min_age}`
          : viewType === 'venue'
            ? `${venues?.data?.[venueID].min_age}`
            : '',
      repeatingShow: false,
      isPrivate: isEdit && !isEmpty(currentShow) ? currentShow.private : false,
      isManageableByArtists: isEdit && !isEmpty(currentShow) ? currentShow.manageableByPerformers : false,
      calendarColor: isEdit && !isEmpty(currentShow) ? (currentShow.calTag as string) : 'green',
      advancedTicketing: false,
      ticketPrice: isEdit && !isEmpty(currentShow) ? `${currentShow.ticket_cost ?? ''}` : '',
      doorPrice: isEdit && !isEmpty(currentShow) ? `${currentShow.doorPrice ?? ''}` : '',
      numberOfAvailableTickets:
        isEdit && !isEmpty(currentShow)
          ? `${currentShow.capacity}`
          : viewType === 'venue'
            ? `${venues?.data?.[venueID].capacity}`
            : '',
      tickets: [],
      paymentType: isEdit && !isEmpty(currentShow) ? currentShow.dealID : 'default',
      guaranteeAmount: null,
      productionFee: null,
      flyer: currentShow?.flyerWebP ?? '',
    }),
    [currentShow, isEdit],
  );

  const showCreationForm = useForm({
    resolver: zodResolver(showCreationFormSchema),
    defaultValues: showCreationInitialFormValues,
    values: showCreationInitialFormValues,
    mode: 'all',
  });

  const show = useMemo(() => {
    if (showID) {
      if (viewType === 'artist' && externalShowsByArtist.isSuccess) {
        return externalShowsByArtist.data?.data?.find((externalShow) => externalShow.id === showID);
      }
      if (viewType === 'showrunner' && externalShowsByShowrunner.isSuccess) {
        return externalShowsByShowrunner.data?.data?.find((externalShow) => externalShow.id === showID);
      }
    }
  }, [viewType, externalShowsByArtist, externalShowsByShowrunner, showID]);

  const handleChangeStep = useCallback(
    async (fieldsToCheck: ShowCreationFormFieldNames[], back?: boolean) => {
      if (back) {
        setStep((step) => step - 1);
      } else {
        fieldsToCheck.forEach((field) => {
          if (field === 'tickets') {
            if (isArray(field)) {
              field.map((singleEl, index) => {
                showCreationForm.trigger(`tickets.${index}.quantity`);
                showCreationForm.trigger(`tickets.${index}.tierName`);
                showCreationForm.trigger(`tickets.${index}.tierDescription`);
                showCreationForm.trigger(`tickets.${index}.ticketPrice`);
              });
            }
          }
          showCreationForm.trigger(field);
        });
        setTimeout(() => {
          const anyInvalidFields = fieldsToCheck.find((field) => {
            return !!showCreationForm.getFieldState(field)?.error;
          });
          if (isEmpty(anyInvalidFields)) {
            setStep((step) => step + 1);
          } else {
            showCreationForm.setFocus(anyInvalidFields);
          }
        }, 200);
      }
    },
    [showCreationForm],
  );

  useEffect(() => {
    if (isEdit && !isEmpty(currentShow)) {
      setShowType(currentShow.type as ShowType);
      setStep(2);
    }
  }, [isEdit, currentShow]);

  useEffect(() => {
    if (isEdit) {
      showCreationForm.unregister(['guaranteeAmount', 'productionFee']);
    }
  }, [isEdit]);

  useEffect(() => {
    if (!isEmpty(user)) {
      if (!user.displayUID) {
        navigate('/login');
      }
    }
  }, [user, navigate]);

  if (
    (isEdit && showType !== ShowType.GIG && showType !== ShowType.IKWP && !isEmpty(show)) ||
    showType === ShowType.EXTERNAL
  ) {
    return (
      <>
        <ExternalTicketsForm
          type={viewType as ViewType}
          SGID={showrunnerID}
          artistID={artistID}
          isEdit={isEdit}
          show={show}
          showID={showID}
          setOpen={setCancelModalOpen}
        />
        <CancelModal
          open={cancelModalOpen}
          setOpen={setCancelModalOpen}
        />
      </>
    );
  }

  return (
    <FormProvider {...showCreationForm}>
      <form noValidate>
        {step === 1 && (
          <ShowFormStep1
            type={viewType as ViewType}
            setShowType={setShowType}
            setStep={setStep}
            setOpen={setCancelModalOpen}
            show={currentShow}
          />
        )}
        {step === 2 && (
          <ShowFormStep2
            type={viewType as ViewType}
            handleChangeStep={handleChangeStep}
            showType={showType}
            setOpen={setCancelModalOpen}
            show={currentShow}
            showrunnerOptions={showrunnerOptions}
            setShowrunnerOptions={setShowrunnerOptions}
            showrunnersSelectValue={showrunnersSelectValue}
            setShowrunnersSelectValue={setShowrunnersSelectValue}
            artistOptions={artistOptions}
            setArtistOptions={setArtistOptions}
            artistSelectValue={artistSelectValue}
            setArtistSelectValue={setArtistSelectValue}
            venueOptions={venueOptions}
            setVenueOptions={setVenueOptions}
            venueSelectValue={venueSelectValue}
            setVenueSelectValue={setVenueSelectValue}
            offPlatformArtists={offPlatformArtists}
            setOffPlatformArtists={setOffPlatformArtists}
            offPlatformShowrunners={offPlatformShowrunners}
            setOffPlatformShowrunners={setOffPlatformShowrunners}
            offPlatformVenues={offPlatformVenues}
            setOffPlatformVenues={setOffPlatformVenues}
            repeatingShow={repeatingShow}
            setRepeatingShow={setRepeatingShow}
            showLength={showLength}
            setShowLength={setShowLength}
          />
        )}
        {step === 3 && (
          <ShowFormStep3
            type={viewType as ViewType}
            handleChangeStep={handleChangeStep}
            showType={showType}
            setOpen={setCancelModalOpen}
            show={currentShow}
          />
        )}
        {step === 4 && (
          <ShowFormStep4
            type={viewType as ViewType}
            handleChangeStep={handleChangeStep}
            showType={showType}
            setOpen={setCancelModalOpen}
            show={currentShow}
            venue={venues?.data?.[venueID]}
            tab={tab}
            setTab={setTab}
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
            previewImage={previewImage}
            setPreviewImage={setPreviewImage}
            sliderValue={sliderValue}
            setSliderValue={setSliderValue}
            selectedTemplate={selectedTemplate}
            setSelectedTemplate={setSelectedTemplate}
          />
        )}
        {step === 5 && (
          <ShowFormStep5
            viewType={viewType as ViewType}
            handleChangeStep={handleChangeStep}
            showType={showType}
            setOpen={setCancelModalOpen}
          />
        )}
      </form>
      <CancelModal
        open={cancelModalOpen}
        setOpen={setCancelModalOpen}
      />
    </FormProvider>
  );
};
