import React from 'react';
import { Box, styled, Typography } from '@mui/material';
import { colors } from '../../ui/shared/Colors';
import { typography } from '../../ui/shared/TypographySharedElements';
import Img from '../../Components/Images/Img';

export type ShowFilterCriteria = {
  venueID?: string;
  date?: Date;
  showName?: string;
  isFree?: boolean;
};

export default function TopVenueCard({
  venue,
  isSelected,
  onClick,
}: {
  venue: any;
  isSelected?: boolean;
  onClick?: () => void;
}) {
  //if there is no avatar, don't render the card
  if (!venue.avatar) {
    return null;
  }
  return (
    <Card
      isSelected={isSelected}
      onClick={onClick}
    >
      <VenueAvatarContainer>
        <VenueAvatar src={venue.avatar} />
      </VenueAvatarContainer>
      <VenueName>{venue.name}</VenueName>
    </Card>
  );
}

const Card = styled(Box, {
  name: 'Card',
  shouldForwardProp: (prop) => prop !== 'isSelected',
})<{ isSelected?: boolean }>(({ theme, isSelected }) => ({
  display: 'flex',
  alignItems: 'center',
  borderRadius: '24px',
  padding: '20px',
  gap: '12px',
  backgroundColor: colors.SystemWhite,
  border: `4px solid ${isSelected ? colors.SystemBlack : 'transparent'}`,
  [theme.breakpoints.down('lg')]: {
    borderRadius: '16px',
    border: `2px solid ${isSelected ? colors.SystemBlack : 'transparent'}`,
    padding: '16px 20px',
  },
}));

const VenueName = styled(Typography, {
  name: 'VenueName',
})(({ theme }) => ({
  ...typography.headlineSmall,
  fontFamily: 'Satoshi-Variable',
  whiteSpace: 'nowrap',
  [theme.breakpoints.down('lg')]: {
    ...typography.buttonLarge,
  },
}));

const VenueAvatarContainer = styled(Box, {
  name: 'VenueAvatarContainer',
})(({ theme }) => ({
  width: '36px',
  height: '36px',
  borderRadius: '50%',
  overflow: 'hidden',
  [theme.breakpoints.down('lg')]: {
    width: '24px',
    height: '24px',
  },
}));

const VenueAvatar = styled(Img, {
  name: 'VenueAvatar',
})({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
});
