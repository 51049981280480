import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { RegisterForm } from './RegisterForm';
import { useAppDispatch } from '../../hooks';
import { setFullscreen } from '../../Redux/UI/UISlice';
import { LoadingPage } from './Loading';
import { FullPageContainer, RightHandSideInnerContainer, SideContainer, SideContainerInner } from './Shared/Containers';
import {
  RightHandSideFootText,
  RightHandSideTitle,
  SubRegistrationLink,
  SubRegistrationText,
  UserSelectionHeadline,
} from './Shared/RegistrationTypographies';
import {
  USER_SELECTION_TEXTS,
  VENUE_SELECTION_TEXTS,
  generateRightHandSideImages,
  generateRightHandSideTexts,
} from './UserTypeSelection';
import { UserTypeEnum } from './UserTypeEnum';
import { useGetUnclaimedData } from './useGetUnclaimedData';
import { isEmpty } from 'lodash';
import { useQuery } from '../../Helpers/shared/useQuery';

export const CreateAccount: FC = () => {
  const search = useQuery();
  const claimCode = search.get('claimCode');
  const [isLoading, setIsLoading] = useState(false);
  const { userType } = useParams();
  const dispatch = useAppDispatch();
  const claimData = useGetUnclaimedData(claimCode ?? null);

  useEffect(() => {
    dispatch(setFullscreen({ status: true }));
    return () => {
      dispatch(setFullscreen({ status: false }));
    };
  }, [dispatch]);

  if (isLoading) {
    return (
      <FullPageContainer>
        <LoadingPage loadingText="Creating your account" />
      </FullPageContainer>
    );
  }

  return (
    <FullPageContainer>
      <SideContainer isLeft={true}>
        <SideContainerInner>
          <UserSelectionHeadline>Create {userType} account</UserSelectionHeadline>
          <RegisterForm
            setIsLoading={setIsLoading}
            claimData={claimData}
            claimCode={claimCode ?? undefined}
          />
          <SubRegistrationText>
            By creating an account, agree to our <SubRegistrationLink to={`/tos`}>terms of service</SubRegistrationLink>{' '}
            and <SubRegistrationLink to={`/privacy`}>privacy policy</SubRegistrationLink>.
          </SubRegistrationText>
          <SubRegistrationText>
            Already have an account? <SubRegistrationLink to={`/login`}>Log in</SubRegistrationLink>
          </SubRegistrationText>
        </SideContainerInner>
      </SideContainer>
      <SideContainer darkMode={userType === UserTypeEnum.VENUE}>
        <RightHandSideTitle darkMode={userType === UserTypeEnum.VENUE}>TuneHatch</RightHandSideTitle>
        <RightHandSideInnerContainer>{generateRightHandSideImages(userType)}</RightHandSideInnerContainer>
        <RightHandSideFootText darkMode={userType === UserTypeEnum.VENUE}>© 2024 TuneHatch Inc.</RightHandSideFootText>
      </SideContainer>
    </FullPageContainer>
  );
};
