import { Box, Typography, styled } from '@mui/material';
import React, { FC, useState } from 'react';
import { colors } from '../../../ui/shared/Colors';
import { typography } from '../../../ui/shared/TypographySharedElements';
import { Button } from '../../../ui/buttons/Button';
import { IconAvatar } from '../Shared/SharedElements';
import AvatarImg from '../../../Images/Avatar.webp';

export interface SingleArtistPlayedWith {
  id: string;
  avatarWebP?: string | null;
  firstName: string;
  lastName: string;
  stageName?: string;
}

interface RecentlyPlayedWithNamesProps {
  artistsList: SingleArtistPlayedWith[];
}

export const RecentlyPlayedWithNames: FC<RecentlyPlayedWithNamesProps> = ({ artistsList }) => {
  const [showAll, setShowAll] = useState(false);
  const maxArtistsToShow = 15;
  if (artistsList.length > 0) {
    const artistListTrimmed = showAll ? artistsList : artistsList.slice(0, maxArtistsToShow);
    return (
      <RecentlyPlayedWithContainer>
        <RecentlyPlayedWithArtistList>
          {artistListTrimmed.map((singleArtist) => {
            return (
              <SingleRecentlyPlayedWithArtist key={singleArtist.id}>
                <IconAvatar src={singleArtist.avatarWebP ? singleArtist.avatarWebP : AvatarImg} />
                <SingleRecentlyPlayedWithArtistName>
                  {singleArtist.stageName
                    ? singleArtist.stageName
                    : `${singleArtist.firstName ? singleArtist.firstName : ''} ${singleArtist.lastName ? singleArtist.lastName : ''}`}
                </SingleRecentlyPlayedWithArtistName>
              </SingleRecentlyPlayedWithArtist>
            );
          })}
        </RecentlyPlayedWithArtistList>
        {artistsList.length > maxArtistsToShow && (
          <ShowMoreButton onClick={() => setShowAll(!showAll)}>{showAll ? 'Show Less' : 'Show More'}</ShowMoreButton>
        )}
      </RecentlyPlayedWithContainer>
    );
  } else {
    return null;
  }
};

const RecentlyPlayedWithContainer = styled(Box, {
  name: 'RecentlyPlayedWithContainer',
})({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
});

const RecentlyPlayedWithArtistList = styled(Box, {
  name: 'RecentlyPlayedWithArtistList',
})({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '16px',
  justifyContent: 'center',
});

const SingleRecentlyPlayedWithArtist = styled(Box, {
  name: 'SingleRecentlyPlayedWithArtist',
})({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
});

const SingleRecentlyPlayedWithArtistName = styled(Typography, {
  name: 'SingleRecentlyPlayedWithArtistName',
})({
  color: colors.SystemBlack,
  ...typography.buttonSmall,
});

const ShowMoreButton = styled(Button, {
  name: 'ShowMoreButton',
})({
  marginTop: '16px',
  alignSelf: 'center',
});
