import React from 'react';
import styled from 'styled-components';
import { Button } from '../../../Components/Buttons/Button';
import {
  useMetaCredentials,
  useGrantMetaAccess,
  useRevokeMetaAccess,
  useShareFlyer,
} from '../../../Redux/API/VenueAPI';
import { AuthorizeWithFBButton } from './components/AuthorizeWithFBButton';
import { MetaPages } from './components/MetaPages';

interface Props {
  showID: string;
  venueId: string;
  className?: string;
}

export default function ShareFlyer(props: Props) {
  return (
    <Box className={props.className}>
      <p className="flex w-full text-center justify-center font-bold">Flyer Sharing</p>
      <ShareFlyerContent {...props} />
    </Box>
  );
}

function ShareFlyerContent(props: Props) {
  const usedCredentials = useMetaCredentials({ venueId: props.venueId });
  const [triggerGrantAccess, grantAccessStatus] = useGrantMetaAccess();
  const [triggerRevokeAccess, revokeAccessStatus] = useRevokeMetaAccess();
  const [triggerShareFlyer, shareFlyerStatus] = useShareFlyer();

  if (revokeAccessStatus.isLoading) {
    return <ContentBox>Removing facebook account access...</ContentBox>;
  }

  if (usedCredentials.isLoading) {
    return <ContentBox>Loading your pages...</ContentBox>;
  }

  if (usedCredentials.isError || revokeAccessStatus.isError || grantAccessStatus.isError) {
    return <ContentBox>Something went wrong</ContentBox>;
  }

  if (usedCredentials.data.credentials === null) {
    if (grantAccessStatus.isLoading) {
      return <ContentBox>Granting access to your Facebook pages...</ContentBox>;
    }
    return (
      <ContentBox>
        <AuthorizeWithFBButton
          onAuthorized={(token) => {
            triggerGrantAccess({
              venueId: props.venueId,
              fbExchangeToken: token,
            })
              .unwrap()
              .then(() => {});
          }}
          onError={() => {}}
          scopes={usedCredentials.data.requiredScopes}
        />
      </ContentBox>
    );
  }

  const pages = usedCredentials?.data?.credentials?.pages;
  return (
    <ContentBox>
      <StyledButton onClick={() => triggerRevokeAccess({ venueId: props.venueId })}>Remove access</StyledButton>
      <MetaPages
        pages={pages}
        onShareFlyer={(data) => {
          triggerShareFlyer({
            venueId: props.venueId,
            showId: props.showID,
            ...data,
          });
        }}
        isSharing={shareFlyerStatus.isLoading}
      />
    </ContentBox>
  );
}

const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 10px;
`;

const ContentBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
`;

const StyledButton = styled(Button)`
  margin-bottom: 8px;
`;
