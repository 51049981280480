import { Avatar, Box, Typography, styled, useMediaQuery, useTheme } from '@mui/material';
import React, { FC } from 'react';
import { DataCy } from '../../Helpers/DataCy';
import { useAppSelector } from '../../hooks';
import AvatarUrl from '../../Images/Avatar.webp';
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { colors } from '../../ui/shared/Colors';
import { typography } from '../../ui/shared/TypographySharedElements';
import { Notifications } from '../Notifications/Notifications';
interface LoginProps {
  toggleUserMenuOpen: () => void;
}

export const Login: FC<LoginProps> = ({ toggleUserMenuOpen }) => {
  const { data, notifications } = useAppSelector((state) => state.user);
  const user = useAppSelector((state) => state.user.data);
  const darkMode = useAppSelector((state) => state.ui.grid.darkMode);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  if (!isEmpty(data.displayUID)) {
    return (
      <LoginContainer>
        <Notifications notifications={notifications} />
        <UserAvatar
          src={user.avatarWebP || AvatarUrl}
          onClick={toggleUserMenuOpen}
          data-cy={'avatar-menu'}
        />
      </LoginContainer>
    );
  }

  return (
    <>
      {!isMobile && (
        <LoginContainer
          onClick={() => navigate('/login')}
          data-cy={DataCy.button('login')}
        >
          <NavbarLoginIcon
            className="material-symbols-outlined"
            darkMode={darkMode}
          >
            login
          </NavbarLoginIcon>
          <NavbarLoginText darkMode={darkMode}>Login</NavbarLoginText>
        </LoginContainer>
      )}
    </>
  );
};

export const LoginContainer = styled(Box, {
  name: 'LoginContainer',
})(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  cursor: 'pointer',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'center',
  },
}));

export const NavbarLoginIcon = styled('i', {
  name: 'NavbarLoginIcon',
  shouldForwardProp: (prop) => prop !== 'darkMode' && prop !== 'isNotification',
})<{ isNotification?: boolean; darkMode: boolean }>(({ isNotification, darkMode }) => ({
  width: '24px',
  height: '24px',
  marginRight: isNotification ? '20px' : 0,
  color: darkMode ? colors.SystemGray500 : colors.SystemGray900,
}));

export const NavbarLoginText = styled(Typography, {
  name: 'NavbarLoginText',
  shouldForwardProp: (prop) => prop !== 'darkMode',
})<{ darkMode: boolean }>(({ darkMode }) => ({
  color: darkMode ? colors.SystemGray100 : colors.SystemGray900,
  ...typography.bodyMediumBold,
}));

const UserAvatar = styled(Avatar, {
  name: 'UserAvatar',
})({
  width: '48px',
  height: '48px',
});
