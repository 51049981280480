import { Box } from '@mui/material';
import React, { Dispatch, FC, SetStateAction } from 'react';

interface SpotifyEmbedProps {
  link: string;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
}

export const SpotifyEmbed: FC<SpotifyEmbedProps> = ({ link, setIsLoading, isLoading }) => {
  var processedLink = link.split('/');
  var linkLength = processedLink.length;
  if (!processedLink[linkLength - 1]) {
    processedLink.pop();
    linkLength = processedLink.length;
  }
  const type = processedLink[linkLength - 2];
  const id = processedLink[linkLength - 1];

  return (
    <Box sx={{ opacity: isLoading ? 0 : 1, height: '352px' }}>
      <iframe
        title="spotify"
        src={'https://open.spotify.com/embed/' + type + '/' + id}
        width="100%"
        height="100%"
        allowFullScreen
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        loading="lazy"
        onLoad={() => setIsLoading(false)}
      ></iframe>
    </Box>
  );
};
