import { Dialog, styled, Typography } from '@mui/material';
import React, { Dispatch, FC, SetStateAction } from 'react';
import { Button } from '../../ui/buttons/Button';
import { IconButton } from '../../ui/buttons/IconButton';
import {
  CancelModalDialogContainer,
  CancelModalDialogBottomContainer,
  CancelModalDialogHeading,
  CancelModalDialogInnerContainer,
  CancelModalDialogTitle,
} from './CancelModalDialog';
import { colors } from '../../ui/shared/Colors';
import { typography } from '../../ui/shared/TypographySharedElements';

interface TabChangeModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  handleChangeFunction: (value: 'basic' | 'advanced') => void;
  value: 'basic' | 'advanced';
}

export const TabChangeModal: FC<TabChangeModalProps> = ({ isOpen, setIsOpen, handleChangeFunction, value }) => {
  return (
    <SuccessDialog open={isOpen}>
      <CancelModalDialogContainer>
        <CancelModalDialogInnerContainer>
          <CancelModalDialogHeading>
            <CancelModalDialogTitle>Confirm changing tab</CancelModalDialogTitle>
            <IconButton
              size="small"
              onClick={() => setIsOpen(false)}
              iconName="close"
            />
          </CancelModalDialogHeading>
          <SuccessDialogSubtitle>
            {`Do you really want to change to ${value === 'basic' ? 'Basic Tickets' : 'Advanced Ticketing'}? All data from the current tabwill be lost.`}
          </SuccessDialogSubtitle>

          <CancelModalDialogBottomContainer>
            <Button
              size="large"
              disabled={false}
              onClick={() => setIsOpen(false)}
            >
              Back to editing
            </Button>
            <Button
              isPrimary
              size="large"
              disabled={false}
              onClick={() => {
                handleChangeFunction(value);
                setIsOpen(false);
              }}
            >
              Change tab
            </Button>
          </CancelModalDialogBottomContainer>
        </CancelModalDialogInnerContainer>
      </CancelModalDialogContainer>
    </SuccessDialog>
  );
};

const SuccessDialog = styled(Dialog, {
  name: 'SuccessDialog',
})(({ theme }) => ({
  '.MuiBackdrop-root': {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    [theme.breakpoints.down('md')]: {
      display: 'block',
      backgroundColor: 'unset',
    },
  },
}));

const SuccessDialogSubtitle = styled(Typography, {
  name: 'SuccessDialogSubtitle',
})({
  color: colors.SystemBlack,
  ...typography.headlineSmall,
  padding: '0 20px',
});
