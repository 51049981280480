import { z } from 'zod';
import { baseAPI } from './BaseAPI';
import { patchRequest, postRequest } from './Request/ApiRequest';
import { handleApiErrors } from './Request/handleErrors';

export namespace UserApi {
  const metaSchema = z.record(z.string(), z.union([z.string(), z.number(), z.boolean()]).nullish());
  const hatchLinksSchema = z.object({
    username: z.string().nullish(),
    hatchLinks: z
      .array(
        z.object({
          text: z.string(),
          url: z.string(),
        }),
      )
      .nullish(),
    customOptions: z
      .object({
        alternativeHatchLinkLabel: z.boolean().optional(),
      })
      .nullish(),
  });
  export const artistSchema = z
    .object({
      id: z.string().nullish(),
      firstName: z.string(),
      lastName: z.string(),
      stageName: z.string().nullish(),
      genre: z.string(),
      subgenre: z.string().nullish(),
      recordLabelName: z.string().nullish(),
      primaryCity: z.object({
        name: z.string(),
        meta: metaSchema,
      }),
      secondaryCity: z
        .object({
          name: z.string().nullish(),
          meta: metaSchema,
        })
        .nullish(),
      socials: z
        .object({
          instagramLink: z.string().nullish(),
          youtubeLink: z.string().nullish(),
          spotifyLink: z.string().nullish(),
          tikTokLink: z.string().nullish(),
        })
        .optional(),
      contactEmail: z.string().nullish(),
      contactPhone: z.string().nullish(),
      livePerformance: z.string().nullish(),
      about: z.string().nullish(),
      assets: z
        .object({
          rider: z.string().nullish(),
        })
        .optional(),
    })
    .merge(hatchLinksSchema);

  const promoterSchema = z
    .object({
      id: z.string().nullish(),
      name: z.string(),
      genre: z.string(),
      subgenre: z.string().nullable().optional(),
      showrunner: z.object({
        firstName: z.string(),
        lastName: z.string(),
      }),
      contact: z.object({
        phone: z.string(),
        email: z.string(),
      }),
      primaryCity: z.object({
        name: z.string(),
        meta: metaSchema,
      }),
      secondaryCity: z
        .object({
          name: z.string().nullish(),
          meta: metaSchema,
        })
        .nullish(),
      socials: z.object({
        instagramLink: z.string().nullish(),
        youtubeLink: z.string().nullish(),
        spotifyLink: z.string().nullish(),
        tikTokLink: z.string().nullish(),
      }),
      about: z.string().nullable().optional(),
      roster: z.array(z.string()),
    })
    .merge(hatchLinksSchema);

  const operatingSpanSchema = z.object({ from: z.string(), to: z.string() }).nullable();
  export const venueSchema = z
    .object({
      id: z.string().nullish(),
      name: z.string(),
      about: z.string().optional(),
      capacity: z.number(),
      minAge: z.number(),
      owner: z.object({
        firstName: z.string(),
        lastName: z.string(),
      }),
      contact: z.object({
        phone: z.string(),
        email: z.string(),
      }),
      socials: z.object({
        instagramLink: z.string().nullable(),
        youtubeLink: z.string().nullable(),
        spotifyLink: z.string().nullable(),
        tikTokLink: z.string().nullable(),
      }),
      operatingHours: z
        .object({
          monday: operatingSpanSchema,
          tuesday: operatingSpanSchema,
          wednesday: operatingSpanSchema,
          thursday: operatingSpanSchema,
          friday: operatingSpanSchema,
          saturday: operatingSpanSchema,
          sunday: operatingSpanSchema,
        })
        .nullable(),
      location: z.object({
        address: z.string(),
        city: z.string(),
        state: z.string(),
        zip: z.string(),
        latitude: z.number().nullable(),
        longitude: z.number().nullable(),
      }),
    })
    .merge(hatchLinksSchema);

  export type Artist = z.TypeOf<typeof artistSchema>;

  export namespace CreateOrEditArtistProfile {
    const schema = z.instanceof(FormData).refine(
      (formData) => {
        const body = formData.get('body');
        return body && typeof body === 'string' && artistSchema.safeParse(JSON.parse(body)).success;
      },
      {
        message: 'Invalid body format',
      },
    );
    export type Params = FormData;
    export const Request = async (params: Params) => {
      const parsed = schema.parse(params);
      return await patchRequest({
        endpoint: '/v2/me/artist',
        data: parsed,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    };
  }
  export namespace CreatePromoterProfile {
    const schema = z.instanceof(FormData).refine(
      (formData) => {
        const body = formData.get('body');
        return body && typeof body === 'string' && promoterSchema.safeParse(JSON.parse(body)).success;
      },
      {
        message: 'Invalid body format',
      },
    );
    export type Params = FormData;
    export const Request = async (params: Params) => {
      const parsed = schema.parse(params);
      return await postRequest({
        endpoint: `/v2/showrunner-groups`,
        data: parsed,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    };
  }
  export namespace EditPromoterProfile {
    const schema = z.instanceof(FormData).refine(
      (formData) => {
        const body = formData.get('body');
        return body && typeof body === 'string' && promoterSchema.safeParse(JSON.parse(body)).success;
      },
      {
        message: 'Invalid body format',
      },
    );
    export type Params = FormData;
    export const Request = async (params: Params) => {
      const parsed = schema.parse(params);
      return await patchRequest({
        endpoint: `/v2/showrunner-groups/${JSON.parse(String(parsed.get('body')))?.id}`,
        data: parsed,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    };
  }
  export namespace CreateVenueProfile {
    const schema = z.instanceof(FormData).refine(
      (formData) => {
        const body = formData.get('body');
        return body && typeof body === 'string' && venueSchema.safeParse(JSON.parse(body)).success;
      },
      {
        message: 'Invalid body format',
      },
    );
    export type Params = FormData;
    export const Request = async (params: Params) => {
      const parsed = schema.parse(params);
      return await postRequest({
        endpoint: `/v2/venues`,
        data: parsed,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    };
  }
  export namespace EditVenueProfile {
    const schema = z.instanceof(FormData).refine(
      (formData) => {
        const body = formData.get('body');
        return body && typeof body === 'string' && venueSchema.safeParse(JSON.parse(body)).success;
      },
      {
        message: 'Invalid body format',
      },
    );
    export type Params = FormData;
    export const Request = async (params: Params) => {
      const parsed = schema.parse(params);
      return await patchRequest({
        endpoint: `/v2/venues/${JSON.parse(String(parsed.get('body')))?.id}`,
        data: parsed,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    };
  }
  export namespace UpdateAccountSettings {
    const schema = z.object({
      firstName: z.string(),
      lastName: z.string(),
      email: z.string().email(),
    });
    export type Params = z.TypeOf<typeof schema>;
    export const Request = async (params: Params) => {
      const parsed = schema.parse(params);
      return await patchRequest({
        endpoint: `/v2/me/user`,
        data: parsed,
      });
    };
  }
}

export const userAPI = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    createOrEditArtistProfile: builder.mutation<any, UserApi.CreateOrEditArtistProfile.Params>({
      async queryFn(data) {
        return await handleApiErrors(async () => await UserApi.CreateOrEditArtistProfile.Request(data));
      },
      invalidatesTags: (result, error, formData) => [{ type: 'Artist' }],
    }),
    createPromoterProfile: builder.mutation<any, UserApi.CreatePromoterProfile.Params>({
      async queryFn(data) {
        return await handleApiErrors(async () => await UserApi.CreatePromoterProfile.Request(data));
      },
      invalidatesTags: (result, error, formData) => [{ type: 'SRG' }],
    }),
    editPromoterProfile: builder.mutation<any, UserApi.EditPromoterProfile.Params>({
      async queryFn(data) {
        return await handleApiErrors(async () => await UserApi.EditPromoterProfile.Request(data));
      },
      invalidatesTags: (result, error, formData) => [{ type: 'SRG' }],
    }),
    createVenueProfile: builder.mutation<any, UserApi.CreateVenueProfile.Params>({
      async queryFn(data) {
        return await handleApiErrors(async () => await UserApi.CreateVenueProfile.Request(data));
      },
      invalidatesTags: (result, error, formData) => [{ type: 'Venue' }],
    }),
    editVenueProfile: builder.mutation<any, UserApi.EditVenueProfile.Params>({
      async queryFn(data) {
        return await handleApiErrors(async () => await UserApi.EditVenueProfile.Request(data));
      },
      invalidatesTags: (result, error, formData) => [{ type: 'Venue' }],
    }),
    updateAccountSettings: builder.mutation<any, any>({
      async queryFn(data) {
        return await handleApiErrors(async () => await UserApi.UpdateAccountSettings.Request(data));
      },
    }),
    resetPassword: builder.mutation<any, any>({
      query: (form) => ({
        method: 'PUT',
        url: '/v2/me/password',
        body: form,
      }),
    }),
    editProfile: builder.mutation<any, any>({
      query: (form) => ({
        method: 'POST',
        url: '/user/edit-profile',
        body: form,
      }),

      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (err) {
          // result.undo();
        }
      },
      invalidatesTags: (result, error, { _key }) => [{ type: 'Artist', id: result.user._key }],
    }),
    getUserTickets: builder.query<any, any>({
      query: (args: { SECRET_UID: string }) => ({
        method: 'POST',
        url: 'user/get-user-tickets',
        body: { SECRET_UID: args.SECRET_UID },
      }),
    }),
    getNotifications: builder.query<any, any>({
      query: (args: { SECRET_UID: string; venueList: any }) => ({
        method: 'POST',
        url: 'user/get-notifications',
        body: { SECRET_UID: args.SECRET_UID, venueList: args.venueList },
      }),
    }),
    clearNotifications: builder.mutation<any, any>({
      query: (args: { SECRET_UID: string; venueList: any }) => ({
        method: 'POST',
        url: 'user/clear-notifs',
        body: args,
      }),
      invalidatesTags: (result, error) => [{ type: 'Notification' }],
    }),
    allNotificationsRead: builder.mutation<any, any>({
      query: (args: { SECRET_UID: string; venueList: any }) => ({
        method: 'POST',
        url: 'user/notifs-all-read',
        body: { SECRET_UID: args.SECRET_UID, venueList: args.venueList },
      }),
      invalidatesTags: (result, error) => [{ type: 'Notification' }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateOrEditArtistProfileMutation,
  useCreatePromoterProfileMutation,
  useEditPromoterProfileMutation,
  useCreateVenueProfileMutation,
  useEditVenueProfileMutation,
  useUpdateAccountSettingsMutation,
  useResetPasswordMutation,
  useEditProfileMutation,
  useGetUserTicketsQuery,
  useGetNotificationsQuery,
  useClearNotificationsMutation,
  useAllNotificationsReadMutation,
} = userAPI;
