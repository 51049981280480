import React, { useState } from 'react';
import Img from '../../../../Components/Images/Img';
import Facebook from '../../../../Images/Icons/Facebook.webp';
import Instagram from '../../../../Images/Icons/Instagram.webp';
import { VenueApi } from '../../../../Redux/API/VenueAPI';
import { ShareSection, OnShareFlyer } from './ShareSection';

interface Props {
  pages: VenueApi.MetaPage[];
  onShareFlyer: OnShareFlyer;
  isSharing: boolean;
}

export function MetaPages(props: Props) {
  const [selectedPageId, selectPageId] = useState<string | null>(null);
  const { pages } = props;
  if (pages.length === 0) {
    return <>You do not have any pages linked to this Facebook Account!</>;
  }

  return (
    <>
      <p className="text-center mb-2">
        {props.pages.length > 1
          ? 'You have multiple accounts with sharing features enabled. Please select the Page you would like to share to.'
          : 'Please confirm the page you would like to share to.'}
      </p>
      <div className="border w-11/12 mx-auto">
        {pages.map((page, index) => (
          <div key={page.id}>
            <div
              className={`p-4 ${index !== 0 ? 'border-t' : ''}`}
              onClick={() => selectPageId((prev) => (prev === page.id ? null : page.id))}
            >
              <h1 className="font-black text-xl mb-1">{page.name}</h1>
              <div className="flex gap-2 pl-2">
                {page.fbPageId && (
                  <Img
                    src={Facebook}
                    className="w-6 h-6"
                  />
                )}
                {page.igPageId && (
                  <Img
                    src={Instagram}
                    className="w-6 h-6"
                  />
                )}
              </div>
            </div>
            {selectedPageId === page.id && (
              <ShareSection
                page={page}
                onShareFlyer={props.onShareFlyer}
                isSharing={props.isSharing}
              />
            )}
          </div>
        ))}
      </div>
    </>
  );
}
