import { Box } from '@mui/material';
import { DataCy } from '../../../Helpers/DataCy';
import { Button } from '../../../ui/buttons/Button';
import React, { FC, useCallback, Dispatch, SetStateAction } from 'react';
import {
  FormStepHeadlineContainer,
  FormStepInnerContainer,
  FormTitleWithSubtitleContainer,
  FormStepFormContainerOuter,
  FormMainContainerColumn,
} from '../Shared/Containers';
import { FormStepHeadlineStepCounter, FormStepHeadlineTitle } from '../Shared/RegistrationTypographies';
import { FormStepProps } from '../Shared/Types';
import { useFormContext } from 'react-hook-form';
import { PromoterFormData } from '../../../Forms/AccountInfoForms/Promoter/PromoterFormData';
import { PromoterPublicProfileForm } from '../../../Forms/AccountInfoForms/Promoter/PromoterPublicProfileForm';

interface PromoterFormStep3 extends FormStepProps {
  setAvatar: Dispatch<SetStateAction<File | null>>;
  selectedAvatar: File | null;
}

export const PromoterFormStep2: FC<PromoterFormStep3> = ({ step, setStep, setAvatar, selectedAvatar }) => {
  const promoterForm = useFormContext();

  const handleBack = useCallback(() => {
    setStep((step) => step - 1);
  }, [setStep]);

  const handleNextStep = useCallback(async () => {
    const isValid = await promoterForm.trigger(PromoterFormData.step2Fields);
    if (isValid === true) {
      setStep((step) => step + 1);
    }
  }, [promoterForm, setStep]);

  return (
    <FormMainContainerColumn>
      <FormStepHeadlineContainer>
        <FormStepInnerContainer>
          <FormTitleWithSubtitleContainer>
            <FormStepHeadlineStepCounter>Step 2 of 3</FormStepHeadlineStepCounter>
            <FormStepHeadlineTitle>Set up your public profile</FormStepHeadlineTitle>
          </FormTitleWithSubtitleContainer>
        </FormStepInnerContainer>
      </FormStepHeadlineContainer>
      <FormStepFormContainerOuter>
        <FormStepInnerContainer>
          <PromoterPublicProfileForm
            setAvatar={setAvatar}
            selectedAvatar={selectedAvatar}
          />
          <Box
            display="flex"
            flexGrow={1}
          >
            <Button
              size="large"
              onClick={handleBack}
              data-cy={DataCy.button('prevStep')}
            >
              Back
            </Button>
            <Box
              display="flex"
              flexDirection="column"
              flexGrow={1}
            >
              <Button
                isPrimary
                size="large"
                onClick={handleNextStep}
                data-cy={DataCy.button('nextStep')}
              >
                Next
              </Button>
            </Box>
          </Box>
        </FormStepInnerContainer>
      </FormStepFormContainerOuter>
    </FormMainContainerColumn>
  );
};
