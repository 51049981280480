import {
  Box,
  Typography,
  styled,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@mui/material';
import React, { useState, useCallback, FC, MouseEvent, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { colors } from '../shared/Colors';
import { isEmpty } from 'lodash';
import Img from '../../Components/Images/Img';
import { deleteRequest } from '../../Redux/API/Request/ApiRequest';
import LoadingSpinner from '../../Components/LoadingSpinner';
import { useUploadImagesMutation } from '../../Redux/API/ImagesAPI';
import { fetchImagesHelper } from './ImageUploader.api.helper';

interface ImageUploaderProps {
  maxNumber: number;
  entityType: string;
  resourceId: string;
  field: string;
  forceDirty?: (value: boolean) => void;
}

export const ImageUploader: FC<ImageUploaderProps> = ({ maxNumber, entityType, resourceId, field, forceDirty }) => {
  const [currentImages, setCurrentImages] = useState<string[]>([]);
  const [imageToDelete, setImageToDelete] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [uploadImages] = useUploadImagesMutation();

  useEffect(() => {
    const fetchImages = async () => {
      const images = await fetchImagesHelper(entityType, field, resourceId);
      setCurrentImages(images || []);
    };

    fetchImages();
  }, []);

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const totalImages = currentImages.length + acceptedFiles.length;
      if (totalImages > maxNumber) {
        alert(`You can only upload up to ${maxNumber} images.`);
        return;
      }

      const formData = new FormData();
      acceptedFiles.forEach((file) => {
        formData.append('images', file);
      });

      try {
        setLoading(true);
        const result = await uploadImages({ entityType, field, resourceId, images: formData });
        if ('data' in result) {
          const uploadedImages = result.data.data.imagesPaths;
          setCurrentImages((prevImages) => [...prevImages, ...uploadedImages]);
        } else {
          console.error('Error uploading images', result.error);
        }
      } catch (error) {
        console.error('Error during image upload', error);
      } finally {
        setLoading(false);
        forceDirty && forceDirty(true);
      }
    },

    [currentImages, maxNumber, entityType, resourceId, field],
  );

  const handleDeleteImage = async (imageUrl: string) => {
    try {
      await deleteRequest({
        endpoint: `/v2/images/${entityType}/${field}/${resourceId}?imagePath=${imageUrl}`,
      });

      setCurrentImages((prevImages) => prevImages.filter((img) => img !== imageUrl));
    } catch (error) {
      console.error('Error deleting image', error);
    } finally {
      forceDirty && forceDirty(true);
    }
  };

  const confirmDeleteImage = (imageUrl: string) => {
    setImageToDelete(imageUrl);
  };

  const cancelDelete = () => {
    setImageToDelete(null);
  };

  const handleDeleteClick = (event: MouseEvent, imageUrl: string) => {
    event.stopPropagation();
    confirmDeleteImage(imageUrl);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { 'image/*': ['.png', '.gif', '.jpeg', '.jpg'] },
    maxFiles: maxNumber - currentImages.length,
  });

  const isMaxImagesReached = currentImages.length >= maxNumber;

  return (
    <>
      <ImageUploaderContainer {...getRootProps()}>
        <input
          {...getInputProps()}
          disabled={isMaxImagesReached || loading}
        />
        {!isEmpty(currentImages) ? (
          <>
            <ImagesContainer>
              {currentImages.map((imageUrl, index) => (
                <Box
                  key={index}
                  position="relative"
                >
                  <ImageStyled
                    src={imageUrl}
                    alt={`Uploaded image ${index + 1}`}
                  />
                  <DeleteIconButton
                    style={{ position: 'absolute', top: 0, right: 0 }}
                    onClick={(event) => handleDeleteClick(event, imageUrl)}
                  >
                    <i className="material-symbols-outlined">close</i>
                  </DeleteIconButton>
                </Box>
              ))}
            </ImagesContainer>
            {isMaxImagesReached && <Typography color="error">Maximum number of images reached.</Typography>}
          </>
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <UploadText>
              Drag and drop your files here or <span>select</span> from your computer
            </UploadText>
          </Box>
        )}

        {loading && (
          <LoadingSpinnerWrapper>
            <LoadingSpinner />
          </LoadingSpinnerWrapper>
        )}
      </ImageUploaderContainer>

      <Dialog
        open={!!imageToDelete}
        onClose={cancelDelete}
      >
        <DialogTitle>Delete Image</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this image?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={cancelDelete}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleDeleteImage(imageToDelete!);
              setImageToDelete(null);
            }}
            color="secondary"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const ImageUploaderContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  minHeight: '30vh',
  border: `1px dashed ${colors.SystemGray200}`,
  borderRadius: '12px',
  gap: '32px',
  position: 'relative',
});

const ImagesContainer = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '16px',
});

const UploadText = styled(Typography)({
  color: colors.SystemGray900,
  textDecoration: 'underline',
});

export const ImageStyled = styled(Img, {
  name: 'ImageStyled',
})({
  width: '200px',
  height: '150px',
  objectFit: 'cover',
  borderRadius: '8px',
});

const DeleteIconButton = styled(IconButton)({
  position: 'absolute',
  top: 0,
  right: 0,
  backgroundColor: colors.SystemGray300,
  color: colors.SystemWhite,
  borderRadius: '50%',
  padding: '4px',
  '&:hover': {
    backgroundColor: colors.SystemGray500,
  },
});

const LoadingSpinnerWrapper = styled(Box)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
});
