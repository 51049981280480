import React from 'react';

export default function Welcome() {
  return (
    <>
      <h1 className="text-5xl font-black text-center">Welcome to TuneHatch.</h1>
      <p>Mini user onboarding here. Nathan, let's work through what this will say together.</p>
    </>
  );
}
