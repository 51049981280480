export const VIEWER_FAQ_DATA = [
  {
    name: 'faq1',
    title: `Where do I create an account to purchase tickets?`,
    details: `TuneHatch is unique in that you will find the concert, venue, or artist, then purchase tickets directly through their TuneHatch account. No "audience member account" needs to be created, and we don't store any of your personal information aside from your email address. TuneHatch accepts Visa, MasterCard, Discover, AMEX, Apple Pay/Google Pay/Samsung Pay, Link, and Klarna. You can choose your preferred payment option at checkout to ensure a smooth and convenient purchasing experience.`,
  },
  {
    name: 'faq2',
    title: `How will I get the tickets I purchased?`,
    details: `They will be sent digitally to any email address you provide in the form of a QR code that you will present at the venue door.`,
  },
  {
    name: 'faq3',
    title: `How many tickets can I (one person) purchase?`,
    details: `You can purchase as many tickets as the venue has left, up to the capacity of the space. If an individual purchases multiple tickets, you can distribute tickets by forwarding the email with the QR codes to your friends, or you can print the tickets for them if they prefer a physical copy.`,
  },
  {
    name: 'faq4',
    title: `Do I need to purchase specific seats for my group to sit together?`,
    details: `No, at this time all TuneHatch tickets are general admission and seating is done on a first-come-first served basis. For specific venue reservations or designated seating – such as for those with disabilities, groups, or events – you should contact the venue directly.`,
  },
  {
    name: 'faq5',
    title: `Can I buy tickets at the door?`,
    details: `Yes, open the camera on your smartphone and the door staff will have a QR Code that will take you right to the purchase page.`,
  },
  {
    name: 'faq6',
    title: `My city, venue, or artist isn't listed on the TuneHatch website anywhere?`,
    details: `Please be patient with us. TuneHatch is a new service that is launching nationally, and we are working on getting all your favorite stages and bands signed up. Please send us an email to info@tunehatch.com to let us know your favorite artists and venues, and we will reach out to them and get them signed up.`,
  },
  {
    name: 'faq7',
    title: `Is there an exorbitant service charge that is added to my tickets?`,
    details: `Around $1.00 to $3.00 is added to your ticket at the time of purchase (along with local taxes, of course). We try to keep these low, around 20%, and even less for more expensive shows.`,
  },
  {
    name: 'faq8',
    title: `Where do I go to have my ticket's QR Codes resent, inquire about refunds, or if I have specific questions about the ticketing process?`,
    details: `For help with tickets, refunds, or any other general questions, please contact us at xxxx@TuneHatch.com, call 1-800-xxx-xxxx, or text 262-xxx-xxxx. A service representative will contact you within 24 hours. For specific concert info and updates, please visit TuneHatch.com or contact the venue directly.`,
  },
  {
    name: 'faq9',
    title: `Where do I find if this show is family-friendly or +21?`,
    details: `TuneHatch show listings will show the age restriction of the show. If you have a specific show in mind, please contact the venue directly.`,
  },
  {
    name: 'faq10',
    title: `There are members of my party that aren't tech-savvy, can I print paper tickets?`,
    details: `Yes, the QR Code TuneHatch sends you can be printed on paper and physically redeemed at the door. You can also take a screenshot/picture of the QR Code and text it to their phone/SMS.`,
  },
];
