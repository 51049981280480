import { Box, styled, Typography } from '@mui/material';
import React, { FC, useState } from 'react';
import { colors } from '../../ui/shared/Colors';
import { typography } from '../../ui/shared/TypographySharedElements';
import { isEmpty } from 'lodash';
import { YouTubeEmbed } from '../../Pages/ProfilePage/YouTube/YouTubeEmbed';

interface ShowDetailsMediaProps {
  link: string;
}

export const ShowDetailsMedia: FC<ShowDetailsMediaProps> = ({ link }) => {
  const [isLoading, setIsLoading] = useState(true);
  if (isEmpty(link)) {
    return null;
  }
  return (
    <ShowDetailsHeadingRightCont>
      <ShowDetailsMediaTitle>Video</ShowDetailsMediaTitle>
      <ShowDetailsMediaInnerCont>
        <YouTubeEmbed
          link={link}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      </ShowDetailsMediaInnerCont>
    </ShowDetailsHeadingRightCont>
  );
};

export const RightHandSideComponentContainer = styled(Box, {
  name: 'RightHandSideComponentContainer',
})({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  padding: '24px',
  borderRadius: '16px',
  maxWidth: '800px',
  backgroundColor: colors.SystemWhite,
  height: 'auto',
});

const ShowDetailsMediaTitle = styled(Typography, {
  name: 'ShowDetailsAboutTitle',
})({
  color: colors.SystemGray900,
  ...typography.headlineSmall,
});

const ShowDetailsMediaInnerCont = styled(Box, {
  name: 'ShowDetailsMediaInnerCont',
})(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  maxWidth: '800px',
  gap: '8px',
  width: '100%',
  [theme.breakpoints.down('md')]: {
    flexWrap: 'nowrap',
    flexDirection: 'column',
  },
}));

const ShowDetailsHeadingRightCont = styled(Box, {
  name: 'ShowDetailsHeadingRightCont',
})({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: '800px',
  height: 'auto',
  gap: '24px',
  backgroundColor: colors.SystemWhite,
  borderRadius: '12px',
  padding: '20px',
});
