import React, { FC } from 'react';
import { styled } from '@mui/material/styles';
import { colors } from '../shared/Colors';
interface ButtonIconProps {
  size: 'small' | 'medium' | 'large';
  disabled: boolean;
  isPrimary?: boolean;
  iconName?: string;
  iconClassName?: string;
}

export const ButtonIcon: FC<ButtonIconProps> = ({
  iconName,
  size,
  disabled,
  isPrimary = false,
  iconClassName = 'material-symbols-outlined',
}) => {
  return (
    <ButtonIconStyled
      className={iconClassName}
      size={size}
      disabled={disabled}
      isPrimary={isPrimary}
    >
      {iconName ? iconName : 'chevron_right'}
    </ButtonIconStyled>
  );
};

export const SpecialButtonIcon: FC<ButtonIconProps> = ({
  iconName,
  size,
  iconClassName = 'material-symbols-outlined',
}) => {
  return (
    <SpecialButtonIconStyled
      className={iconClassName}
      size={size}
    >
      {iconName ? iconName : 'chevron_right'}
    </SpecialButtonIconStyled>
  );
};

const ButtonIconStyled = styled('i', {
  name: 'ButtonIconStyled',
  shouldForwardProp: (prop) => prop !== 'disabled' && prop !== 'size' && prop !== 'isPrimary' && prop !== 'iconName',
})<Omit<ButtonIconProps, 'iconName'>>(({ size, disabled, isPrimary }) => ({
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: size === 'large' ? '24px' : '20px',
  color: isPrimary
    ? disabled
      ? colors.SystemGray300
      : colors.SystemWhite
    : disabled
      ? colors.SystemGray400
      : colors.SystemGray900,
}));

const SpecialButtonIconStyled = styled('i', {
  name: 'ButtonIconStyled',
  shouldForwardProp: (prop) => prop !== 'size' && prop !== 'iconName',
})<Omit<ButtonIconProps, 'iconName' | 'disabled' | 'isPrimary'>>(({ size }) => ({
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: size === 'large' ? '24px' : '20px',
  color: colors.AlertRed,
}));
