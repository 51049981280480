import { Box, Typography, Tabs, Tab, styled } from '@mui/material';
import { Button } from '../../../ui/buttons/Button';
import { typography } from '../../../ui/shared/TypographySharedElements';
import { colors } from '../../../ui/shared/Colors';

export const EditProfileHeadlineContainer = styled(Box, {
  name: 'EditProfileHeadlineContainer',
})({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  gap: '32px',
  flexWrap: 'wrap',
  width: '100%',
  backgroundColor: colors.SystemGray100,
  padding: '32px 20px 0px',
});

export const EditProfileFormContainer = styled(Box, {
  name: 'EditProfileFormContainer',
})({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '40px 20px',
});

export const EditProfileInnerContainer = styled(Box, {
  name: 'EditProfileInnerContainer',
})({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: '800px',
  gap: '32px',
});

export const EditProfileTitle = styled(Typography, {
  name: 'EditProfileTitle',
})(({ theme }) => ({
  textWrap: 'nowrap',
  flex: '1 1 auto',
  ...typography.headlineLarge,
  [theme.breakpoints.down('sm')]: {
    flex: '1 1 100%',
  },
}));

export const StyledTabs = styled(Tabs, {
  name: 'StyledTabs',
})({
  '.MuiTabs-indicator': {
    backgroundColor: colors.SystemGray900,
  },
  '.MuiTabs-flexContainer': {
    width: '100vw',
    gap: '24px',
  },
});

export const StyledTab = styled(Tab, {
  name: 'StyledTab',
})({
  padding: '16px',
  color: colors.SystemGray500, //unselected
  '&.MuiTab-root.Mui-selected': {
    color: colors.SystemGray900,
  },
});
export const TabLabel = styled(Typography, {
  name: 'TabLabel',
})({
  ...typography.buttonLarge,
  textTransform: 'none',
});

export const TitleAndButtonsContainer = styled(Box)({
  display: 'flex',
  gap: '16px',
  flexWrap: 'wrap',
});

export const StyledButton = styled(Button, {
  name: 'StyledButton',
})({
  flex: '0 0 auto',
  textWrap: 'nowrap',
});

export const CancelButton = styled(StyledButton, {
  name: 'CancelButton',
})({
  backgroundColor: colors.SystemWhite,
});
