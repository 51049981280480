import React, { useState } from 'react';
import ShowTile from '../Tiles/ShowTile';
import { ViewType } from '../../Helpers/shared/Models/ViewType';
import { Show } from '../../Helpers/shared/Models/Show';
import { ShowApi } from '../../Redux/API/ShowAPI';
import ExternalShowTile from '../Tiles/ExternalShowTile';

export default function ExternalShowTileCollection(props: {
  title?: string;
  viewType?: ViewType;
  manageView?: boolean;
  containerClassName?: string;
  shows: ShowApi.GetExternalShowsByArtist.Response;
  limit?: number;
}) {
  const [limit, setLimit] = useState(props.limit || 5);
  return (
    <>
      <div className={'flex-col ' + props.containerClassName ? props.containerClassName : ''}>
        <h1 className="font-black text-3xl w-full text-center">{props.title}</h1>
        <div className="border">
          {props.shows?.data?.map((show, i) => {
            if (i < limit) {
              return (
                <ExternalShowTile
                  show={show}
                  viewType={props.viewType}
                  key={show.id}
                  manageView={props.manageView}
                />
              );
            } else {
              return null;
            }
          })}
          {props.shows?.data?.length > limit && (
            <button
              className="p-3 self-end hover:bg-gray-100 w-full bg-white text-blue-400"
              onClick={() => setLimit(limit + (props.limit || 5))}
            >
              Show More
            </button>
          )}
        </div>
      </div>
    </>
  );
}
