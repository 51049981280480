import { Box } from '@mui/material';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { DataCy } from '../../../Helpers/DataCy';
import { Button } from '../../../ui/buttons/Button';
import {
  FormStepHeadlineContainer,
  FormStepInnerContainer,
  FormTitleWithSubtitleContainer,
  FormStepFormContainerOuter,
} from '../Shared/Containers';
import { FormStepHeadlineStepCounter, FormStepHeadlineTitle } from '../Shared/RegistrationTypographies';
import { FormStepProps } from '../Shared/Types';
import { FieldValues, SubmitHandler, useFormContext } from 'react-hook-form';
import { ArtistsWorkedWithForm } from '../../../Forms/AccountInfoForms/Promoter/ArtistsWorkedWithForm';

interface PromoterFormStep3Props extends FormStepProps {
  submitPromoter: SubmitHandler<FieldValues>;
  isLoading?: boolean;
}

export const PromoterFormStep3: FC<PromoterFormStep3Props> = ({ step, setStep, submitPromoter, isLoading }) => {
  const promoterForm = useFormContext();

  const handleBack = useCallback(() => {
    setStep((step) => step - 1);
  }, [setStep]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      flexGrow={1}
    >
      <FormStepHeadlineContainer>
        <FormStepInnerContainer>
          <FormTitleWithSubtitleContainer>
            <FormStepHeadlineStepCounter>Step 3 of 3</FormStepHeadlineStepCounter>
            <FormStepHeadlineTitle>Add artists you work with (optional)</FormStepHeadlineTitle>
          </FormTitleWithSubtitleContainer>
        </FormStepInnerContainer>
      </FormStepHeadlineContainer>
      <FormStepFormContainerOuter>
        <FormStepInnerContainer>
          <ArtistsWorkedWithForm />
          <Box
            display="flex"
            flexGrow={1}
          >
            <Button
              size="large"
              onClick={handleBack}
              data-cy={DataCy.button('prevStep')}
            >
              Back
            </Button>
            <Box
              display="flex"
              flexDirection="column"
              flexGrow={1}
            >
              <Button
                isPrimary
                size="large"
                onClick={promoterForm.handleSubmit(submitPromoter)}
                data-cy={DataCy.button('createPromoter')}
                isLoading={isLoading}
                disabled={isLoading}
              >
                Next
              </Button>
            </Box>
          </Box>
        </FormStepInnerContainer>
      </FormStepFormContainerOuter>
    </Box>
  );
};
