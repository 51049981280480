import { Box, styled, Typography } from '@mui/material';
import TabBottomImg from '../../Images/Homepage/TabBottomImg.png';
import { typography } from '../../ui/shared/TypographySharedElements';
import { colors } from '../../ui/shared/Colors';

//TODO TabBottomImg needs to be changed and probably some cover added
export const TabPanelInnerCont = styled(Box, {
  name: 'TabPanelInnerCont',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '120px 0px 0px 0px',
  height: 'auto',
  zIndex: 1,
  gap: '120px',
  backgroundColor: colors.SystemGray800,
  marginTop: '-40px',
  [theme.breakpoints.down('lg')]: {
    padding: '80px 0',
    gap: '60px',
  },
  [theme.breakpoints.down('md')]: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: '360px',
    paddingTop: '40px',
    gap: '80px',
  },
}));

export const TabHeadlineText = styled(Typography, {
  name: 'HeadlineText',
})(({ theme }) => ({
  maxWidth: '920px',
  width: '100%',
  textAlign: 'center',
  color: colors.SystemWhite,
  ...typography.headlineMedium,
  [theme.breakpoints.down('lg')]: {
    ...typography.headlineSmall,
  },
  [theme.breakpoints.down('md')]: {
    ...typography.headlineSmall,
    padding: '0 20px',
  },
}));

export const TabText = styled(Typography, {
  name: 'TabText',
})(({ theme }) => ({
  width: '100%',
  textAlign: 'center',
  color: colors.SystemWhite,
  ...typography.displaySmall,
  [theme.breakpoints.down('lg')]: {
    ...typography.headlineMedium,
  },
  [theme.breakpoints.down('md')]: {
    ...typography.headlineMedium,
    padding: '20px',
  },
}));

export const TabBodyText = styled(Typography, {
  name: 'TabBodyText',
})({
  width: '100%',
  textAlign: 'center',
  color: colors.SystemWhite,
  ...typography.bodyLargeRegular,
});
