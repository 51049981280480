import { Box, styled, Typography } from '@mui/material';
import React, { FC, PropsWithChildren } from 'react';
import { colors } from '../../ui/shared/Colors';
import { typography } from '../../ui/shared/TypographySharedElements';

interface ShowDetailsUserInfoBoxProps {
  type: 'Artists' | 'Showrunner' | 'Venue';
}

export const ShowDetailsUsersInfo: FC<PropsWithChildren<ShowDetailsUserInfoBoxProps>> = ({ type, children }) => {
  return (
    <ShowDetailsUsersInfoCont>
      <ShowDetailsUserMainText>{type}</ShowDetailsUserMainText>
      <ShowDetailsUsersInfoInnerCont>{children}</ShowDetailsUsersInfoInnerCont>
    </ShowDetailsUsersInfoCont>
  );
};

const ShowDetailsUsersInfoCont = styled(Box, {
  name: 'ShowDetailsUsersInfoCont',
})({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  backgroundColor: colors.SystemWhite,
  borderRadius: '12px',
  padding: '20px',
  width: '100%',
});

export const ShowDetailsUserMainText = styled(Typography, {
  name: 'ShowDetailsUserMainText',
})({
  color: colors.SystemGray900,
  ...typography.headlineSmall,
});

const ShowDetailsUsersInfoInnerCont = styled(Box, {
  name: 'ShowDetailsUsersInfoInnerCont',
})({
  display: 'flex',
  flexWrap: 'wrap',
  width: '100%',
  gap: '24px',
});
