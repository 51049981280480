import React, { FC, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { LocationField } from '../../../ui/inputs/LocationField';
import { TextField } from '../../../ui/inputs/TextField';
import { FormInputGroupElementWrapper, FormInputGroupWrapper, FormSectionContainer } from '../Shared/Containers';
import { FormSectionTitle } from '../Shared/RegistrationTypographies';
import { VenueFormData } from './VenueFormData';

export const VenueGeneralInfoForm: FC = () => {
  const venueForm = useFormContext<VenueFormData.Values>();

  return (
    <>
      <FormSectionContainer>
        <FormSectionTitle>Your venue</FormSectionTitle>
        <TextField
          {...venueForm.register('venueName')}
          type="text"
          label="Venue Name"
          placeholder="Enter venue name"
          error={!!venueForm.formState.errors?.venueName?.message}
          helperText={
            !!venueForm.formState.errors?.venueName?.message && <>{venueForm.formState.errors?.venueName?.message}</>
          }
        />
        <FormInputGroupWrapper>
          <FormInputGroupElementWrapper>
            <TextField
              {...venueForm.register('ageRestriction')}
              type="text"
              label="Age Restrictions"
              placeholder="Enter age restrictions"
              error={!!venueForm.formState.errors?.ageRestriction?.message}
              helperText={
                !!venueForm.formState.errors?.ageRestriction?.message && (
                  <>{venueForm.formState.errors?.ageRestriction?.message}</>
                )
              }
            />
          </FormInputGroupElementWrapper>
          <FormInputGroupElementWrapper>
            <TextField
              {...venueForm.register('capacity')}
              type="text"
              label="Capacity"
              placeholder="Enter capacity"
              error={!!venueForm.formState.errors?.capacity?.message}
              helperText={
                !!venueForm.formState.errors?.capacity?.message && <>{venueForm.formState.errors?.capacity?.message}</>
              }
            />
          </FormInputGroupElementWrapper>
        </FormInputGroupWrapper>
        <FormInputGroupWrapper>
          <FormInputGroupElementWrapper>
            <TextField
              {...venueForm.register('owner.firstName')}
              type="text"
              label="User's First Name"
              placeholder="Enter user's first name"
              error={venueForm.getFieldState('owner.firstName').invalid}
              helperText={
                venueForm.getFieldState('owner.firstName').invalid && (
                  <>{venueForm.getFieldState('owner.firstName').error?.message}</>
                )
              }
            />
          </FormInputGroupElementWrapper>
          <FormInputGroupElementWrapper>
            <TextField
              {...venueForm.register('owner.lastName')}
              type="text"
              label="User's Last Name"
              placeholder="Enter user's last name"
              error={venueForm.getFieldState('owner.lastName').invalid}
              helperText={
                venueForm.getFieldState('owner.lastName').invalid && (
                  <>{venueForm.getFieldState('owner.lastName').error?.message}</>
                )
              }
            />
          </FormInputGroupElementWrapper>
        </FormInputGroupWrapper>
      </FormSectionContainer>
      <FormSectionContainer>
        <FormSectionTitle>Contact Details</FormSectionTitle>
        <FormInputGroupWrapper>
          <FormInputGroupElementWrapper>
            <TextField
              {...venueForm.register('contact.phone')}
              type="tel"
              label="Contact phone"
              placeholder="Enter contact phone"
              error={venueForm.getFieldState('contact.phone').invalid}
              helperText={
                venueForm.getFieldState('contact.phone').invalid && (
                  <>{venueForm.getFieldState('contact.phone').error?.message}</>
                )
              }
            />
          </FormInputGroupElementWrapper>
          <FormInputGroupElementWrapper>
            <TextField
              {...venueForm.register('contact.email')}
              type="email"
              label="Contact Email"
              placeholder="Enter contact email"
              error={venueForm.getFieldState('contact.email').invalid}
              helperText={
                venueForm.getFieldState('contact.email').invalid && (
                  <>{venueForm.getFieldState('contact.email').error?.message}</>
                )
              }
            />
          </FormInputGroupElementWrapper>
        </FormInputGroupWrapper>
      </FormSectionContainer>
      <FormSectionContainer>
        <FormSectionTitle>Address</FormSectionTitle>
        <LocationField
          fieldName={'address'}
          label="Address"
          placeholder="Enter your address"
        />
      </FormSectionContainer>
    </>
  );
};
