import { Box, Typography, styled } from '@mui/material';
import React, { FC, useCallback, useState } from 'react';
import { colors } from '../../ui/shared/Colors';
import { typography } from '../../ui/shared/TypographySharedElements';
import { Button } from '../../ui/buttons/Button';
import { IconButton } from '../../ui/buttons/IconButton';

interface ShareEPKLinkModal {
  closeModal: () => void;
  epkLink: string;
}

export const ShareEPKModal: FC<ShareEPKLinkModal> = ({ closeModal, epkLink }) => {
  const [linkCopied, setLinkCopied] = useState(false);

  const handleCopyLinkToClipboard = useCallback(() => {
    navigator.clipboard.writeText(epkLink);
    setLinkCopied(true);
  }, [epkLink]);

  return (
    <ShareHatchLinksModalContainer>
      <ShareHatchLinksModalInnerContainer>
        <ShareHatchLinksModalHeading>
          <ShareHatchLinksModalTitle>What is an EPK and how does it work?</ShareHatchLinksModalTitle>
          <IconButton
            size="small"
            onClick={closeModal}
            iconName="close"
          />
        </ShareHatchLinksModalHeading>
        <ShareHatchLinksModalText>
          Your TuneHatch profile serves as your electronic press kit (EPK), primed to help you score gigs at amazing
          venues! It’s loaded with all the essential details – show history, song catalog, videos, social media, and key
          data points that venues look for when booking artists. Copy the link below and share it with the next venue
          you’re aiming to perform at!
        </ShareHatchLinksModalText>
        {/* <ShareHatchLinksModalText>
                newline
                </ShareHatchLinksModalText> */}
      </ShareHatchLinksModalInnerContainer>
      <ShareHatchLinksModalBottomContainer>
        <ShareHatchLinksModalLink>{epkLink}</ShareHatchLinksModalLink>
        <Box
          display="flex"
          flexGrow={1}
        >
          {!linkCopied ? (
            <Button
              size="small"
              isPrimary
              onClick={handleCopyLinkToClipboard}
            >
              Copy link
            </Button>
          ) : (
            <ShareHatchLinksModalText>Link copied!</ShareHatchLinksModalText>
          )}
        </Box>
      </ShareHatchLinksModalBottomContainer>
    </ShareHatchLinksModalContainer>
  );
};

const ShareHatchLinksModalContainer = styled(Box, {
  name: 'ShareHatchLinksModalContainer',
})({
  display: 'flex',
  flexDirection: 'column',
  padding: '24px',
  gap: '40px',
  backgroundColor: colors.SystemWhite,
  borderRadius: '16px',
  maxWidth: '520px',
  width: '100%',
  height: '100%',
});

const ShareHatchLinksModalInnerContainer = styled(Box, {
  name: 'ShareHatchLinksModalInnerContainer',
})({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
});

const ShareHatchLinksModalHeading = styled(Box, {
  name: 'ShareHatchLinksModalHeading',
})({
  display: 'flex',
  justifyContent: 'space-between',
});

const ShareHatchLinksModalTitle = styled(Typography, {
  name: 'ShareHatchLinksModalTitle',
})({
  color: colors.SystemGray900,
  ...typography.headlineSmall,
});

const ShareHatchLinksModalText = styled(Typography, {
  name: 'ShareHatchLinksModalText',
})({
  color: colors.SystemGray900,
  ...typography.bodyMediumRegular,
});

const ShareHatchLinksModalBottomContainer = styled(Box, {
  name: 'ShareHatchLinksModalBottomContainer',
})({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '12px 24px',
  alignItems: 'center',
  backgroundColor: colors.SystemGray100,
  maxWidth: '472px',
  width: '100%',
  gap: '16px',
  borderRadius: '12px',
  marginTop: '12px',
});

const ShareHatchLinksModalLink = styled(Typography, {
  name: 'ShareHatchLinksModalLink',
})({
  color: colors.SystemGray900,
  ...typography.bodyMediumRegular,
  textDecoration: 'underline',
  textWrap: 'wrap',
  wordBreak: 'break-all',
  overflowWrap: 'break-word',
});
