import React, { FC } from 'react';
import { TabHeadlineText, TabPanelInnerCont } from '../Shared';
import { Box } from '@mui/material';

import { FAQ } from '../FAQ/FAQ';
import { VENUE_FAQ_DATA } from '../FAQ/venueFaqData';
import { ShowcaseBoxGroup } from '../../VenuesLP/Showcases/ShowcaseBoxGroup';
import { ShowcaseBox } from '../../VenuesLP/Showcases/ShowcaseBox';

import { VENUE_TAB_CONTENT } from './venuesContentData';
import { PricingImage } from '../../VenuesLP/Pricing/Pricing';

// images
import PricingImg from '../../../Images/Homepage/VenueTab/venuepricing.png';
import Showcase1Img from '../../../Images/Homepage/VenueTab/showcase1.png';
import Showcase2Img from '../../../Images/Homepage/VenueTab/showcase2.png';
import Showcase3Img from '../../../Images/Homepage/VenueTab/showcase3.png';
import Showcase4Img from '../../../Images/Homepage/VenueTab/showcase4.png';
import Showcase5Img from '../../../Images/Homepage/VenueTab/showcase5.png';
import Showcase6Img from '../../../Images/Homepage/VenueTab/showcase6.png';
import { CtaBox } from '../CtaBox';
import { VenueReviews } from '../../VenuesLP/VenueReviews/VenueReviews';
import { venueTestimonials } from '../../VenuesLP/VenueReviews/VenueTestimonials';
import { PricingText } from '../PricingText';

export const VenuesContent: FC = () => {
  return (
    <TabPanelInnerCont>
      <TabHeadlineText>
        TuneHatch helps venues efficiently navigate the complexities of booking, promoting and ticketing shows. By
        streamlining operations, managing last-minute changes, and organizing communication, TuneHatch ensures that no
        detail is overlooked. <br />
        It's the smarter way to manage events.
      </TabHeadlineText>

      <ShowcaseBoxGroup
        title="Sell tickets easily"
        bgColor={'transparent'}
        darkMode={true}
      >
        <ShowcaseBox
          text={VENUE_TAB_CONTENT.showcaseText1}
          imageUrl={Showcase1Img}
          darkMode={true}
        />
        <ShowcaseBox
          text={VENUE_TAB_CONTENT.showcaseText2}
          imageUrl={Showcase2Img}
          darkMode={true}
        />
      </ShowcaseBoxGroup>

      <ShowcaseBoxGroup
        title="Promote shows faster"
        bgColor={'transparent'}
        darkMode={true}
      >
        <ShowcaseBox
          text={VENUE_TAB_CONTENT.showcaseText3}
          imageUrl={Showcase3Img}
          darkMode={true}
        />
        <ShowcaseBox
          text={VENUE_TAB_CONTENT.showcaseText4}
          imageUrl={Showcase4Img}
          darkMode={true}
        />
      </ShowcaseBoxGroup>

      <ShowcaseBoxGroup
        title="Book without hassles"
        bgColor={'transparent'}
        darkMode={true}
      >
        <ShowcaseBox
          text={VENUE_TAB_CONTENT.showcaseText5}
          imageUrl={Showcase5Img}
          darkMode={true}
        />
        <ShowcaseBox
          text={VENUE_TAB_CONTENT.showcaseText6}
          imageUrl={Showcase6Img}
          darkMode={true}
        />
      </ShowcaseBoxGroup>

      <PricingText />

      <a href="mailto:info@tunehatch.com">
        <PricingImage src={PricingImg} />
      </a>
      <Box
        display="block"
        position="relative"
        width="100%"
        maxWidth="100vw"
      >
        <VenueReviews testimonials={venueTestimonials} />
      </Box>
      <FAQ faqData={VENUE_FAQ_DATA} />

      <CtaBox isVenue={true}></CtaBox>
    </TabPanelInnerCont>
  );
};
