import { Box, hexToRgb, styled } from '@mui/material';
import React, { FC } from 'react';
import Img from '../../../Components/Images/Img';
import YouTubeIcon from '../../../Images/Icons/YouTube.svg';
import { colors } from '../../../ui/shared/Colors';

export const YouTubeSkeleton: FC = () => {
  return (
    <YouTubeSkeletonContainer>
      <YouTubeIconContainer src={YouTubeIcon} />
    </YouTubeSkeletonContainer>
  );
};

const YouTubeSkeletonContainer = styled(Box, {
  name: 'YouTubeSkeletonContainer',
})({
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
  minWidth: '100%',
  height: '400px',
  //Note: We could get an image to use as YT thumbnail.
  backgroundColor: colors.SystemGray200,
  padding: '16px',
});

const YouTubeIconContainer = styled(Img, {
  name: 'YouTubeIconContainer',
})({
  display: 'block',
  height: '20px',
  width: 'auto',
});
