import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';

export const useGetUnclaimedData = (claimCode: string | null) => {
  const [claimData, setClaimData] = useState(null);
  const getClaimData = useCallback(async () => {
    try {
      if (claimCode) {
        const res = await axios.get(
          `${process.env.REACT_APP_PUBLIC_URL}:${process.env.REACT_APP_SERVER_PORT}/api/v2/unclaimed-users/${claimCode}`,
          {
            headers: {
              Accept: '*/*',
              'Accept-Encoding': 'gzip, deflate, br',
              Connection: 'keep-alive',
            },
          },
        );
        if (res.status === 200) {
          setClaimData(res.data);
        }
      }
    } catch (err) {
      throw new Error('Something went wrong');
    }
  }, [claimCode]);

  useEffect(() => {
    getClaimData();
  }, [getClaimData]);

  return claimData;
};
