import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import {
  useGetAllArtistsQuery,
  useGetAllShowrunnerGroupsQuery,
  useGetAllShowsQuery,
  useGetAllVenuesQuery,
} from '../../../Redux/API/PublicAPI';
import LoadingWrapper from '../../../Components/Layout/LoadingWrapper';
import { useAppSelector } from '../../../hooks';
import { useCalculatePayoutsQuery, useGetShowNotesQuery, useGetSoldTicketsQuery } from '../../../Redux/API/VenueAPI';
import PayoutManager from './PayoutManager';
// import { useArtistCalculatePayoutsQuery } from "../../../Redux/API/ArtistAPI";
import DealVisualizer from '../../../Components/Collections/DealVisualizer';
import { ViewType } from '../../../Helpers/shared/Models/ViewType';
import LedgerEntryField from './LedgerEntryField';

export default function PayoutCalculator(props: { showID: string; viewType: ViewType; venue?: string }) {
  const user = useAppSelector((state) => state.user.data);
  const artists = useGetAllArtistsQuery();
  const venues = useGetAllVenuesQuery();
  const showrunners = useGetAllShowrunnerGroupsQuery();
  const shows = useGetAllShowsQuery();
  const show = shows.data?.[props.showID];
  const payouts = useCalculatePayoutsQuery(
    { SECRET_UID: user.uid, venueID: show.venueID, showID: show._key },
    { skip: !show },
  );
  const notes = useGetShowNotesQuery({
    SECRET_UID: user.uid,
    showID: props.showID,
    venueID: show.venueID,
  });
  console.log(notes);
  // const artistPayouts = useArtistCalculatePayoutsQuery(
  //   { SECRET_UID: user.uid, showID: show._key },
  //   { skip: !show || props.viewType === "venue" }
  // );
  const soldTickets = useGetSoldTicketsQuery(
    { SECRET_UID: user.uid, showID: show?._key, venueID: show?.venueID },
    { skip: !show },
  );
  // const defaultPayout =
  //   payouts.data?.payouts?.[
  //     props.viewType === "venue" ? show.venueID : user.displayUID
  //   ]?.total ||
  //   artistPayouts.data?.payouts?.[
  //     props.viewType === "venue" ? show.venueID : user.displayUID
  //   ]?.total;
  // const lineItems =
  //   payouts.data?.payouts?.[props.venue ? show.venueID : user.displayUID]
  //     ?.lineItems ||
  //   artistPayouts.data?.payouts?.[props.venue ? show.venueID : user.displayUID]
  //     ?.lineItems;
  const deal = show?.deal;
  const tickets: { price?: number }[] = [];
  // const [payout, setPayout] = useState(defaultPayout);
  const [dollarTicketSales, setTicketSales] = useState(0);
  const calculatePayout = () => {
    let ticketSales = 0;
    tickets?.forEach((ticket) => {
      if (ticket) {
        ticketSales = ticketSales + Number(ticket.price);
      }
    });
    setTicketSales(ticketSales);
  };

  useEffect(() => {
    if (show) {
      if (props.venue) {
        // dispatch(loadFullDeal({ dealID: show.dealID, uid, venueID: show.venueID }))
      } else {
        // dispatch(loadPartialDeal({ dealID: show.dealID, uid }))
      }
    }
    if (show && !show.cashLedger) {
    }
  }, [show]);

  useEffect(() => {
    if (tickets) {
      calculatePayout();
    }
  }, [tickets, deal]);
  console.log(tickets);
  return (
    <LoadingWrapper queryResponse={[artists, venues, showrunners, shows, soldTickets, payouts]}>
      <div className="p-2 md:w-1/2 md:mx-auto">
        <DealVisualizer showID={props.showID} />
      </div>
      <div className="w-full text-center flex flex-col items-center">
        {
          <>
            <h1 className="text-2xl font-black text-center">Manage Payouts for {show.name}</h1>
            <p>{dayjs(show.starttime).format('MM/DD/YYYY [at] h:mm A')}</p>
            {new Date(show.starttime).getTime() < 1693353600000 ? (
              <h1>
                You cannot pay out shows before August 30th, 2023. To pay out this show, please contact the TuneHatch
                team.
              </h1>
            ) : (
              <>
                <PayoutManager
                  viewType={props.viewType}
                  showID={props.showID}
                />
                {props.viewType === ViewType.Venue && notes.data?.notes && (
                  <>
                    <h1 className="text-xl font-black mt-3">Show Notes</h1>
                    <p className="text-sm text-black whitespace-pre">{notes.data?.notes}</p>
                  </>
                )}
                {props.viewType === ViewType.Venue && (
                  <>
                    <h1 className="text-xl font-black mt-3">Cash & Bar Sales</h1>
                    <p className="text-sm text-gray-500">
                      TuneHatch allows you to keep track of cash and bar sales directly and keep all of your payment
                      details in one place. <br />
                      Please note that numbers entered here are for your records only: they <b>do not</b> affect payout
                      calculations.
                    </p>
                    <div className="flex flex-row w-4/5 gap-3 mt-2">
                      <LedgerEntryField
                        type={'tickets'}
                        venueID={show?.venueID}
                        showID={props.showID}
                      />
                      <LedgerEntryField
                        type={'bar'}
                        venueID={show?.venueID}
                        showID={props.showID}
                      />
                    </div>
                  </>
                )}
              </>
            )}
          </>
        }
      </div>
    </LoadingWrapper>
  );
}
