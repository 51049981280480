import { Box, styled, Typography } from '@mui/material';
import React, { FC, useCallback, useState } from 'react';
import { colors } from '../../ui/shared/Colors';
import { typography } from '../../ui/shared/TypographySharedElements';
import { Button } from '../../ui/buttons/Button';

interface ShowDetailsAboutProps {
  about: string;
}

export const ShowDetailsAbout: FC<ShowDetailsAboutProps> = ({ about }) => {
  const [expanded, setExpanded] = useState(false);
  const TEXT_MAX_LENGTH_NOT_EXPANDED = 500;
  const aboutExpandable = about.length > TEXT_MAX_LENGTH_NOT_EXPANDED;
  const aboutParts = [
    about.slice(0, TEXT_MAX_LENGTH_NOT_EXPANDED),
    about.slice(TEXT_MAX_LENGTH_NOT_EXPANDED + 1, about.length),
  ];
  const toggleExpanded = useCallback(() => {
    setExpanded((expanded) => !expanded);
  }, []);

  return (
    <ShowDetailsAboutMainCont>
      <ShowDetailsAboutTitle>About show</ShowDetailsAboutTitle>
      <ShowDetailsAboutInnerCont>
        <ShowDetailsAboutText>{aboutParts[0]}</ShowDetailsAboutText>
        {expanded && <ShowDetailsAboutText>{aboutParts[1]}</ShowDetailsAboutText>}
        {aboutExpandable && (
          <Button
            size="small"
            disabled={false}
            onClick={toggleExpanded}
          >{`Show ${!expanded ? 'more' : 'less'}`}</Button>
        )}
      </ShowDetailsAboutInnerCont>
    </ShowDetailsAboutMainCont>
  );
};

const ShowDetailsAboutMainCont = styled(Box, {
  name: 'ShowDetailsAboutMainCont',
})({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  padding: '20px',
  maxWidth: '800px',
  width: '100%',
  backgroundColor: colors.SystemWhite,
  borderRadius: '12px',
});

const ShowDetailsAboutTitle = styled(Typography, {
  name: 'ShowDetailsAboutTitle',
})({
  color: colors.SystemGray900,
  ...typography.headlineSmall,
});

const ShowDetailsAboutInnerCont = styled(Box, {
  name: 'ShowDetailsAboutInnerCont',
})({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  alignItems: 'flex-start',
});

const ShowDetailsAboutText = styled(Typography, {
  name: 'ShowDetailsAboutText',
})({
  color: colors.SystemGray900,
  ...typography.bodyLargeRegular,
});
