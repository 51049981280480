import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';

export const ClaimCodeLegacyRedirect = () => {
  const { claimCode } = useParams();
  const location = useLocation();
  const [redirect, setRedirect] = useState(null);
  useEffect(() => {
    setRedirect('/register/artist?claimCode=' + claimCode);
  }, []);

  return (
    <>
      {redirect ? (
        <>
          <Navigate
            replace
            to={redirect}
          />
        </>
      ) : (
        <></>
      )}
    </>
  );
};
