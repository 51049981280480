import React, { Dispatch, FC, SetStateAction, useCallback, useState } from 'react';
import { FormInnerCont, FormRowCont, FormSectionCont } from '../Shared/Containers';
import { TitleBar } from '../Shared/TitleBar';
import { Box, styled, Typography } from '@mui/material';
import { TextField } from '../../../ui/inputs/TextField';
import { FieldErrorsImpl, useFieldArray, useFormContext } from 'react-hook-form';
import { ViewType } from '../../../Helpers/shared/Models/ViewType';
import { ShowType } from '../SingleTicketTypeButton';
import { TabPanel, Tab, Tabs } from '../../../ui/Tabs/Tabs';
import { Button } from '../../../ui/buttons/Button';
import { colors } from '../../../ui/shared/Colors';
import { typography } from '../../../ui/shared/TypographySharedElements';
import { ShowFormNavButtons } from './ShowFormNavButtons';
import { useParams } from 'react-router-dom';
import { Show } from '../../../Helpers/shared/Models/Show';
import { HiddenField } from '../../../ui/inputs/HiddenField';
import { useEditShowDetailsMutation } from '../../../Redux/API/ShowAPI';
import { z } from 'zod';
import { getTicketsFromCurrentStructure } from './helperFunctions';
import { useAppDispatch } from '../../../hooks';
import { addStatusMessage } from '../../../Redux/UI/UISlice';
import { ShowFormSuccessModal } from '../ShowFormSuccessModal';
import { getShowCreationFormSchema } from './ShowCreationFormSchema';
import { TabChangeModal } from '../TabChangeModal';

interface ShowFormStep3Props {
  type: ViewType;
  handleChangeStep: (fieldsToCheck: any[], back?: boolean) => void;
  showType: ShowType;
  setOpen: Dispatch<SetStateAction<boolean>>;
  show: Show;
}

export const ShowFormStep3: FC<ShowFormStep3Props> = ({ type, handleChangeStep, showType, setOpen }) => {
  const dispatch = useAppDispatch();
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [changeTabDialogOpen, setChangeTabDialogOpen] = useState(false);
  const [changeTabValue, setChangeTabValue] = useState<'basic' | 'advanced'>(null);
  const createShowForm = useFormContext();
  const isAdvancedTicketing = createShowForm.getValues('advancedTicketing');
  const [tab, setTab] = useState<'basic' | 'advanced'>(isAdvancedTicketing ? 'advanced' : 'basic');
  const { showID, viewID } = useParams();
  const isEdit = showID ? true : false;
  const { fields, append, remove } = useFieldArray({
    name: 'tickets',
  });
  const [editShowDetails] = useEditShowDetailsMutation();
  const showCreationFormSchema = getShowCreationFormSchema(!!showID);
  const [loading, setLoading] = useState(false);

  const handleOpenModalWithChangeTabAccess = useCallback((e: React.SyntheticEvent, value: 'basic' | 'advanced') => {
    e.preventDefault();
    setChangeTabValue(value);
    setChangeTabDialogOpen(true);
  }, []);

  const handleChangeTab = useCallback(
    (value: 'basic' | 'advanced') => {
      if (value === 'advanced') {
        createShowForm.setValue('advancedTicketing', true);
        append({ tierName: '', tierDescription: '', ticketPrice: '', quantity: '' });
      } else {
        remove();
        createShowForm.setValue('advancedTicketing', false);
      }
      setTab(value);
    },
    [createShowForm, append, remove],
  );

  const handleAddTicketTier = useCallback(() => {
    append({ tierName: '', tierDescription: '', ticketPrice: '', quantity: '' });
  }, [append]);

  const handleRemoveTicketTier = useCallback(
    (index: number) => {
      if (index > 0) {
        remove(index);
      }
    },
    [remove],
  );

  const changeStep = useCallback((back?: boolean) => {
    if (back) {
      handleChangeStep([], true);
    } else {
      handleChangeStep(['numberOfAvailableTickets', 'ticketPrice', 'doorPrice', 'tickets', 'advancedTicketing']);
    }
    window.scrollTo(0, 0);
  }, []);

  const submitEditForm = useCallback(
    async (data: z.infer<typeof showCreationFormSchema>) => {
      setLoading(true);
      try {
        await editShowDetails({
          name: data.name,
          description: data.description,
          calendarColor: data.calendarColor,
          endTime: data.endTime.toISOString(),
          startTime: data.startTime.toISOString(),
          isManageableByPerformers: data.isManageableByArtists,
          isPrivate: data.isPrivate,
          minAge: Number(data.minAge),
          showId: showID,
          showrunnerGroups: data.showrunners.map((showrunner) => {
            return { ...showrunner, isNew: showrunner.id === '0' };
          }),
          tickets: getTicketsFromCurrentStructure(data, isEdit),
        });
        dispatch(
          addStatusMessage({
            type: 'success',
            message: 'Your show was succesfully updated',
          }),
        );
        setSuccessModalOpen(true);
        setLoading(false);
      } catch {
        setLoading(false);
        dispatch(
          addStatusMessage({
            type: 'error',
            message: 'Something went wrong',
          }),
        );
      }
    },
    [editShowDetails, dispatch],
  );

  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <TitleBar
        formName="Create Show"
        step={3}
        maxSteps={!showID ? 5 : 3}
        stepTitle="Add ticket details"
        setOpen={setOpen}
      />
      <FormInnerCont>
        <FormSectionCont>
          <TextField
            {...createShowForm.register('numberOfAvailableTickets')}
            type="text"
            label="Number of Available Tickets"
            placeholder="Number of Available Tickets"
            error={!!createShowForm.formState.errors?.numberOfAvailableTickets?.message}
            helperText={
              !!createShowForm.formState.errors?.numberOfAvailableTickets?.message && (
                <>{createShowForm.formState.errors?.numberOfAvailableTickets?.message}</>
              )
            }
            fullWidth
          />
          <HiddenField {...createShowForm.register('advancedTicketing')} />
          <Tabs
            value={tab}
            onChange={handleOpenModalWithChangeTabAccess}
          >
            <Tab
              value="basic"
              label="Basic Tickets"
            />
            <Tab
              value="advanced"
              label="Advanced Ticketing"
            />
          </Tabs>
          <TabPanel
            value={tab}
            index="basic"
          >
            <BasicTicketCont>
              <FormRowCont>
                <TextField
                  {...createShowForm.register(`ticketPrice`)}
                  type="text"
                  label="Ticket Price"
                  placeholder="Ticket Price"
                  error={!!createShowForm.formState.errors?.ticketPrice?.message}
                  helperText={
                    !!createShowForm.formState.errors?.ticketPrice?.message && (
                      <>{createShowForm.formState.errors?.ticketPrice?.message}</>
                    )
                  }
                  fullWidth
                />
                <TextField
                  {...createShowForm.register('doorPrice')}
                  type="text"
                  label="Door Price (optional)"
                  placeholder="Door Price"
                  error={!!createShowForm.formState.errors?.doorPrice?.message}
                  helperText={
                    !!createShowForm.formState.errors?.doorPrice?.message && (
                      <>{createShowForm.formState.errors?.doorPrice?.message}</>
                    )
                  }
                  fullWidth
                />
              </FormRowCont>
            </BasicTicketCont>
          </TabPanel>
          <TabPanel
            value={tab}
            index="advanced"
          >
            {tab === 'advanced' &&
              fields.map((field, index) => {
                const fieldErrors = createShowForm.formState?.errors as unknown as FieldErrorsImpl<{
                  tickets: { tierName: string; tierDescription: string; ticketPrice: string; quantity: string }[];
                }>;
                return (
                  <TicketTierCont key={index + 1}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                    >
                      <TicketTierTitle>Tier {index + 1}</TicketTierTitle>
                      <i
                        className="material-symbols-outlined"
                        onClick={() => handleRemoveTicketTier(index)}
                      >
                        delete
                      </i>
                    </Box>
                    <TextField
                      {...createShowForm.register(`tickets[${index}].tierName`)}
                      key={field.id}
                      type="text"
                      label="Tier name"
                      placeholder="Enter tier name"
                      error={!!fieldErrors?.tickets?.[index]?.tierName?.message}
                      helperText={
                        !!fieldErrors?.tickets?.[index]?.tierName?.message && (
                          <>{fieldErrors?.tickets?.[index]?.tierName?.message}</>
                        )
                      }
                      fullWidth
                    />
                    <TextField
                      {...createShowForm.register(`tickets[${index}].tierDescription`)}
                      key={field.id}
                      type="text"
                      label="Tier Description"
                      placeholder="Enter tier description"
                      error={!!fieldErrors?.tickets?.[index]?.tierDescription?.message}
                      helperText={
                        !!fieldErrors?.tickets?.[index]?.tierDescription?.message && (
                          <>{fieldErrors?.tickets?.[index]?.tierDescription?.message}</>
                        )
                      }
                      fullWidth
                    />
                    <FormRowCont>
                      <TextField
                        {...createShowForm.register(`tickets[${index}].ticketPrice`)}
                        key={field.id}
                        type="text"
                        label="Ticket Price"
                        placeholder="Enter Ticket Price"
                        error={!!fieldErrors?.tickets?.[index]?.ticketPrice?.message}
                        helperText={
                          !!fieldErrors?.tickets?.[index]?.ticketPrice?.message && (
                            <>{fieldErrors?.tickets?.[index]?.ticketPrice?.message}</>
                          )
                        }
                        fullWidth
                      />
                      <TextField
                        {...createShowForm.register(`tickets[${index}].quantity`)}
                        key={field.id}
                        type="text"
                        label="Quantity"
                        placeholder="Enter Quantity"
                        error={!!fieldErrors?.tickets?.[index]?.quantity?.message}
                        helperText={
                          !!fieldErrors?.tickets?.[index]?.quantity?.message && (
                            <>{fieldErrors?.tickets?.[index]?.quantity?.message}</>
                          )
                        }
                        fullWidth
                      />
                    </FormRowCont>
                  </TicketTierCont>
                );
              })}
            <Box
              display="flex"
              justifyContent="center"
              width="100%"
            >
              <Button
                disabled={false}
                onClick={handleAddTicketTier}
              >
                +Add ticket tier
              </Button>
            </Box>
          </TabPanel>
        </FormSectionCont>
        {isEdit ? (
          <Button
            isPrimary
            onClick={createShowForm.handleSubmit(submitEditForm, (err) => console.log('error', err))}
            disabled={loading}
            isLoading={loading}
            size="large"
          >
            Done
          </Button>
        ) : (
          <ShowFormNavButtons changeStep={changeStep} />
        )}
      </FormInnerCont>
      <ShowFormSuccessModal
        viewType={type}
        viewID={viewID}
        isEdit={isEdit}
        isOpen={successModalOpen}
        setIsOpen={setSuccessModalOpen}
        showID={showID}
      />
      <TabChangeModal
        isOpen={changeTabDialogOpen}
        setIsOpen={setChangeTabDialogOpen}
        value={changeTabValue}
        handleChangeFunction={handleChangeTab}
      />
    </Box>
  );
};

const TicketTierCont = styled(Box, {
  name: 'TicketTierCont',
})({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  marginBottom: '24px',
  borderRadius: '12px',
  background: colors.SystemGray50,
  padding: '16px',
});

const BasicTicketCont = styled(Box, {
  name: 'BasicTicketCont',
})({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  marginBottom: '24px',
  padding: '16px',
});

const TicketTierTitle = styled(Typography, {
  name: 'TicketTierTitle',
})({
  color: colors.SystemBlack,
  ...typography.buttonLarge,
});
