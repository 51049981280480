import React, { FC } from 'react';
import dayjs from 'dayjs';
import {
  ContentSection,
  DateSection,
  DateSubContainer,
  HeadlineDateText,
  ShowCardContainer,
  SubtitleDateText,
  Title,
} from './ShowCard';

export interface ExternalShowCardProps {
  id?: string;
  title?: string;
  startTime?: string;
  link?: string;
}

export const ExternalShowCard: FC<ExternalShowCardProps> = ({ id, title, startTime, link }) => {
  const parsedDate = new Date(startTime);
  const month = dayjs(parsedDate).format('MMM');
  const year = parsedDate.getFullYear();
  const day = parsedDate.getDate();

  return (
    <ShowCardContainer
      onClick={() => {
        window.open(link, '_blank');
      }}
    >
      <DateSection>
        <DateSubContainer>
          <SubtitleDateText>{month}</SubtitleDateText>
          <HeadlineDateText>{day}</HeadlineDateText>
          <SubtitleDateText>{year}</SubtitleDateText>
        </DateSubContainer>
      </DateSection>
      <ContentSection>
        <Title variant="h5">{title}</Title>
      </ContentSection>
    </ShowCardContainer>
  );
};
