import * as Lo from 'lodash';
import { stringify as qsStringify } from 'qs';

export type QueryObject = Record<string, unknown>;

export namespace QueryParser {
  export type QueryObject = Record<string, unknown>;

  export function stringify(obj: QueryObject): string {
    return Lo.flow(
      (obj) => Lo.omitBy(obj, (value) => value === undefined || value === null || value === ''),
      (obj) => qsStringify(obj),
    )(obj);
  }
}
