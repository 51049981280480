import { z } from 'zod';
import { baseAPI } from './BaseAPI';
import { patchRequest, postRequest } from './Request/ApiRequest';
import { handleApiErrors } from './Request/handleErrors';
import { omit } from 'lodash';

export enum SRGroupMemberType {
  Admin = 'admin',
  Member = 'member',
}
namespace UpdateShowrunnerGuidedTours {
  const paramsSchema = z.object({
    tourStatus: z.object({
      profileTour: z.object({
        step: z.number(),
        completed: z.boolean(),
      }),
    }),
    profileID: z.string(),
  });

  export type Params = z.TypeOf<typeof paramsSchema>;
  export const Request = async (params: Params) => {
    const parsed = paramsSchema.parse(params);
    return await postRequest({
      endpoint: `/v2/sr-groups/${parsed.profileID}/update-guided-tours`,
      data: omit(parsed, 'profileID'),
    });
  };
}

export namespace UpdateShowrunnerColors {
  const paramsSchema = z.object({
    profileColor: z.object({ hex: z.string(), isDark: z.boolean() }).optional(),
    colorsToPickFrom: z.array(z.object({ hex: z.string(), isDark: z.boolean() })).optional(),
    profileID: z.string(),
  });

  export type Params = z.TypeOf<typeof paramsSchema>;
  export const Request = async (params: Params) => {
    const parsed = paramsSchema.parse(params);
    return await patchRequest({
      endpoint: `/v2/sr-groups/${parsed.profileID}/colors`,
      data: omit(parsed, 'profileID'),
    });
  };
}

export const showrunnerAPI = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    createSRG: builder.mutation<any, any>({
      query: (args) => ({
        method: 'POST',
        url: '/user/create-sr-group',
        body: args,
      }),
      invalidatesTags: (result, error) => [{ type: 'SRG' }],
    }),
    editSRG: builder.mutation<any, any>({
      query: (args) => ({
        method: 'POST',
        url: '/sr/edit-sr-group',
        body: args,
      }),
      invalidatesTags: (result, error) => [{ type: 'SRG' }],
    }),
    updateSRGGuidedTours: builder.mutation<unknown, UpdateShowrunnerGuidedTours.Params>({
      async queryFn(data) {
        return await handleApiErrors(async () => await UpdateShowrunnerGuidedTours.Request(data));
      },
      invalidatesTags: (res, err) => [{ type: 'SRG' }],
    }),
    updateSRGColors: builder.mutation<unknown, UpdateShowrunnerColors.Params>({
      async queryFn(data) {
        return await handleApiErrors(async () => await UpdateShowrunnerColors.Request(data));
      },
      invalidatesTags: (res, err) => [{ type: 'SRG' }],
    }),
  }),

  overrideExisting: false,
});

export const { useCreateSRGMutation, useEditSRGMutation, useUpdateSRGGuidedToursMutation, useUpdateSRGColorsMutation } =
  showrunnerAPI;
