import { Box, Typography, styled } from '@mui/material';
import React, { Dispatch, FC, Ref, SetStateAction } from 'react';
import Img from '../../../Components/Images/Img';
import { DataCy } from '../../../Helpers/DataCy';
import { colors } from '../../../ui/shared/Colors';
import { typography } from '../../../ui/shared/TypographySharedElements';
import { Button } from '../../../ui/buttons/Button';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import VerifiedIcon from '../../../Images/Icons/verified.svg';
import { ViewType } from '../../../Helpers/shared/Models/ViewType';
import StripeHandler from '../../../Components/Buttons/StripeHandler';
import { useNavigate } from 'react-router-dom';

interface ProfileHeadlineProps {
  avatarSrc: string;
  userName: string;
  stripeEnabled: boolean;
  profileID: string;
  profileType: ViewType;
  isOwnProfile: boolean;
  isMobile?: boolean;
  setShareHatchLinksOpen: Dispatch<SetStateAction<boolean>>;
  setShareEPKLinkOpen: Dispatch<SetStateAction<boolean>>;
  editProfileRef: Ref<HTMLButtonElement>;
  hlRef: Ref<HTMLButtonElement>;
}

export const ProfileHeadline: FC<ProfileHeadlineProps> = ({
  avatarSrc,
  userName,
  stripeEnabled,
  isOwnProfile,
  profileType,
  profileID,
  isMobile,
  setShareHatchLinksOpen,
  setShareEPKLinkOpen,
  editProfileRef,
  hlRef,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const darkMode = useAppSelector((state) => state.ui.grid.darkMode);
  return (
    <ProfileHeadlineContainer>
      <UserAvatar
        src={avatarSrc}
        dataCy={DataCy.image('avatar')}
      />
      <ProfileHeadlineInnerContainer>
        <UserName isDark={darkMode}>{userName}</UserName>
        {stripeEnabled && <UserEnabledIcon src={VerifiedIcon}></UserEnabledIcon>}
      </ProfileHeadlineInnerContainer>
      {isOwnProfile && (
        <ProfileHeadlineInnerContainer isMobile={isMobile}>
          <Button
            isPrimary
            size={isMobile ? 'small' : 'large'}
            disabled={false}
            onClick={() => {
              setShareEPKLinkOpen(true);
            }}
          >
            {isMobile ? 'Share EPK' : 'Share electronic press-kit'}
          </Button>
          <Button
            size={isMobile ? 'small' : 'large'}
            disabled={false}
            ref={hlRef}
            onClick={() =>
              navigate(
                `/profile/edit/${profileType === ViewType.User ? 'artist' : profileType}/${profileID}/hatchlinks`,
              )
            }
          >
            Edit HatchLink
          </Button>
          {(profileType === ViewType.Artist || profileType === ViewType.User || profileType === ViewType.SRGroup) && (
            <StripeHandler
              viewType={profileType}
              targetID={profileID}
              buttonSize={isMobile ? 'small' : 'large'}
            />
          )}
          <Button
            size={isMobile ? 'small' : 'large'}
            disabled={false}
            ref={editProfileRef}
            onClick={() =>
              navigate(`/profile/edit/${profileType === ViewType.User ? 'artist' : profileType}/${profileID}`)
            }
            data-cy={DataCy.button('editProfile')}
          >
            Edit Profile
          </Button>
        </ProfileHeadlineInnerContainer>
      )}
    </ProfileHeadlineContainer>
  );
};

const ProfileHeadlineContainer = styled(Box, {
  name: 'ProfilePageHeadlineContainer',
})({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  flexGrow: 1,
  gap: '24px',
  maxWidth: '1200px',
});

const ProfileHeadlineInnerContainer = styled(Box, {
  name: 'ProfileHeadlineInnerContainer',
})<{ isMobile?: boolean }>(({ isMobile }) =>
  isMobile
    ? {
        gap: '12px',
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
      }
    : {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '12px',
      },
);

const UserAvatar = styled(Img, {
  name: 'UserAvatar',
})(({ theme }) => ({
  width: '200px',
  height: '200px',
  borderRadius: '999px',
  [theme.breakpoints.down('lg')]: {
    width: '140px',
    height: '140px',
  },
  [theme.breakpoints.down('md')]: {
    width: '100px',
    height: '100px',
  },
}));

const UserName = styled(Typography, {
  name: 'UserName',
  shouldForwardProp: (prop) => prop !== 'isDark',
})<{ isDark: boolean }>(({ isDark, theme }) => ({
  textAlign: 'center',
  color: isDark ? colors.SystemWhite : colors.SystemBlack,
  ...typography.displaySmall,
  [theme.breakpoints.down('lg')]: {
    ...typography.headlineMedium,
  },
  [theme.breakpoints.down('md')]: {
    ...typography.headlineSmall,
  },
}));

const UserEnabledIcon = styled('img', {
  name: 'UserEnabledIcon',
})(({ theme }) => ({
  width: '40px',
  height: '40px',
  [theme.breakpoints.down('lg')]: {
    width: '25px',
    height: '25px',
  },
}));
