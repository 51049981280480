import React, { useCallback, useEffect, useState } from 'react';
import { Box, Typography, styled } from '@mui/material';
import { ShowFilterCriteria } from './ExploreShows';
import { typography } from '../../ui/shared/TypographySharedElements';
import { TextField, AutocompleteElement, Select } from '../../ui/inputs/TextField';
import { DatePickerElement as DatePicker } from '../../ui/inputs/DateTimePicker';
import { Checkbox, FormControlLabel } from '../../ui/inputs/Checkbox';
import { Radio, RadioGroup, RadioControlLabel } from '../../ui/inputs/Radio';
import { Button } from '../../ui/buttons/Button';
import { colors } from '../../ui/shared/Colors';
import { Venue } from '../../Helpers/shared/Models/Venue';
import { useSearchParams } from 'react-router-dom';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { debounce } from 'lodash';
import { Show } from '../../Helpers/shared/Models/Show';
import dayjs from 'dayjs';

interface ShowsFiltersProps {
  venues: Venue[];
  canHide?: boolean;
  gigs?: boolean;
  shows?: Show[];
}

interface weekdayCounter {
  Monday: number;
  Tuesday: number;
  Wednesday: number;
  Thursday: number;
  Friday: number;
  Saturday: number;
  Sunday: number;
}
/**
 * Filters for upcoming shows
 * @prop filterCriteria - the current filter criteria
 * @prop setFilterCriteria - the function to set the filter criteria
 * @prop venues - the list of venues for the autocomplete
 * @prop gigs - whether the filters should be for people looking for gigs
 * @prop shows - the list of shows for the weekday count
 */
export default function ShowsFilters({ venues, canHide, gigs, shows }: ShowsFiltersProps) {
  const initialWeekdayCount: weekdayCounter = {
    Monday: 0,
    Tuesday: 0,
    Wednesday: 0,
    Thursday: 0,
    Friday: 0,
    Saturday: 0,
    Sunday: 0,
  };

  const [search, setSearch] = useSearchParams();
  const [hidden, setHidden] = useState(false);
  const [weekdayCount, setWeekdayCount] = useState<weekdayCounter>(initialWeekdayCount);

  // Only set search after 350ms of no input
  // const debouncedSetSearch = useCallback(
  //   debounce((func: (params: URLSearchParams) => URLSearchParams) => {
  //     console.log('running debounce');
  //     setSearch(func);
  //   }, 350),
  //   [],
  // );

  //legacy code
  const calculateWeekdayCount = () => {
    let availableShows: Array<Show> = [];
    if (shows) {
      Object.keys(shows).forEach((showKey: any) => {
        let show = shows[showKey];
        if (dayjs(show.starttime).format('MMMM') === search.get('month')) {
          availableShows.push(show);
        }
      });
      let tWeekdayCount = { ...initialWeekdayCount };
      availableShows.map((show: Show) => {
        let starttime = dayjs(show.starttime);
        let weekday: any = starttime.format('dddd');
        tWeekdayCount[weekday as keyof weekdayCounter] = tWeekdayCount[weekday as keyof weekdayCounter] + 1;
      });
      console.log(tWeekdayCount);
      setWeekdayCount(tWeekdayCount);
    } else {
      return false;
    }
  };

  useEffect(() => {
    calculateWeekdayCount();
  }, [shows, search.get('month')]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MainContent>
        <TitleContainer>
          <Title>Filters</Title>
          {canHide && <WhiteButton onClick={() => setHidden(!hidden)}>{hidden ? 'Show' : 'Hide'}</WhiteButton>}
        </TitleContainer>
        {(!canHide || !hidden) && (
          <>
            {gigs && (
              <WhiteAutocomplete
                options={[
                  'January',
                  'February',
                  'March',
                  'April',
                  'May',
                  'June',
                  'July',
                  'August',
                  'September',
                  'October',
                  'November',
                  'December',
                ]}
                value={search.get('month')}
                onChange={(event, value) =>
                  setSearch((params) => {
                    if (value) {
                      params.set('month', String(value));
                    } else {
                      params.delete('month');
                    }
                    return params;
                  })
                }
                renderInput={(params) => (
                  <Select
                    label="Select Month"
                    placeholder="Select Month"
                    {...params}
                  />
                )}
              />
            )}
            {gigs && (
              <RadioGroup
                value={search.get('weekday')}
                onChange={(event, value) =>
                  setSearch((params) => {
                    if (value) {
                      params.set('weekday', String(value));
                    } else {
                      params.delete('weekday');
                    }
                    return params;
                  })
                }
              >
                {Object.keys(weekdayCount).map((weekday) => (
                  <RadioControlLabel
                    value={weekday}
                    control={<Radio />}
                    label={<>{weekday}</>}
                  />
                ))}
              </RadioGroup>
            )}
            <WhiteAutocomplete
              options={venues.filter((venue) => venue.name).map((venue) => venue.name) || []}
              value={search.get('venueID') || ''} // the '' is needed so that selecting all venues with TopVenueSelector can actually clear the field
              onChange={(event, value) =>
                setSearch((params) => {
                  if (value) {
                    params.set('venueID', String(value));
                  } else {
                    params.delete('venueID');
                  }
                  return params;
                })
              }
              renderInput={(params) => (
                <Select
                  label="Select Venue"
                  placeholder="Select Venue"
                  {...params}
                />
              )}
              freeSolo
              forcePopupIcon
              autoSelect
            />
            {!gigs && (
              <WhiteDatePicker
                label="Show Date"
                name="date"
                value={search.get('date') ? new Date(search.get('date')!) : null}
                onChange={(value: any) =>
                  setSearch((params) => {
                    if (value) {
                      params.set('date', String(value));
                    } else {
                      params.delete('date');
                    }
                    return params;
                  })
                }
                disablePast
                slots={{
                  openPickerIcon: () => <i className="material-symbols-outlined">calendar_today</i>,
                }}
                slotProps={{
                  actionBar: {
                    actions: ['today', 'accept'],
                  },
                  textField: {
                    placeholder: 'Select Date',
                  },
                }}
              />
            )}
            {!gigs && (
              <WhiteTextField
                label="Show Name"
                value={search.get('showName')}
                placeholder="Enter Show Name"
                onChange={(e) =>
                  setSearch((params) => {
                    if (e.target.value) {
                      params.set('showName', e.target.value);
                    } else {
                      params.delete('showName');
                    }
                    return params;
                  })
                }
              />
            )}
            {!gigs && (
              <CheckboxContainer>
                <CheckboxLabel>Price</CheckboxLabel>
                {
                  !gigs ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={search.get('isFree') === 'true'}
                          onChange={(e) =>
                            setSearch((params) => {
                              if (e.target.checked) {
                                params.set('isFree', 'true');
                              } else {
                                params.delete('isFree');
                              }
                              return params;
                            })
                          }
                        />
                      }
                      label="Free Only"
                    />
                  ) : null
                  // <FormControlLabel
                  //   control={
                  //     <Checkbox
                  //       checked={search.get('paidOnly') === 'true'}
                  //       onChange={(e) =>
                  //         setSearch((params) => {
                  //           if (e.target.checked) {
                  //             params.set('paidOnly', 'true');
                  //           } else {
                  //             params.delete('paidOnly');
                  //           }
                  //           return params;
                  //         })
                  //       }
                  //     />
                  //   }
                  //   label="Paid Only"
                  // />
                }
              </CheckboxContainer>
            )}
            <WhiteButton
              size="small"
              isPrimary={false}
              onClick={() =>
                setSearch((params) => {
                  params.delete('month');
                  params.delete('weekday');
                  params.delete('venueID');
                  params.delete('date');
                  params.delete('showName');
                  params.delete('isFree');
                  params.delete('paidOnly');
                  return params;
                })
              }
            >
              Reset Filters
            </WhiteButton>
          </>
        )}
      </MainContent>
    </LocalizationProvider>
  );
}

const MainContent = styled(Box, {
  name: 'MainContent',
})({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
});

const TitleContainer = styled(Box, {
  name: 'TitleContainer',
})({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const Title = styled(Typography, {
  name: 'Title',
})(({ theme }) => ({
  ...typography.headlineMedium,
  fontFamily: 'Satoshi-Variable',
  color: colors.SystemGray900,
  [theme.breakpoints.down('lg')]: {
    ...typography.headlineSmall,
  },
}));

const WhiteAutocomplete = styled(AutocompleteElement, {
  name: 'WhiteAutocomplete',
})({
  '&&&&& .MuiInputBase-input': {
    backgroundColor: colors.SystemWhite,
    borderRadius: '12px',
    border: `1px solid ${colors.SystemGray200}`,
  },
});

const WhiteTextField = styled(TextField, {
  name: 'WhiteTextField',
})({
  '&&&&&& .MuiInputBase-input': {
    backgroundColor: colors.SystemWhite,
    borderRadius: '12px',
    border: `1px solid ${colors.SystemGray200}`,
  },
});

const WhiteDatePicker = styled(DatePicker, {
  name: 'WhiteDatePicker',
})({
  '&&&&& .MuiInputBase-input': {
    backgroundColor: colors.SystemWhite,
    borderRadius: '12px',
    border: `1px solid ${colors.SystemGray200}`,
  },
});

const CheckboxContainer = styled(Box, {
  name: 'CheckboxContainer',
})({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
});

const CheckboxLabel = styled(Typography, {
  name: 'CheckboxLabel',
})({
  ...typography.bodySmallRegular,
  fontFamily: 'Satoshi-Variable',
});

const WhiteButton = styled(Button, {
  name: 'WhiteButton',
})({
  width: 'fit-content',
  alignSelf: 'center',
  backgroundColor: colors.SystemWhite,
});
