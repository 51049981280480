import { Box, styled } from '@mui/material';
import React, { Dispatch, FC, SetStateAction, useCallback } from 'react';
import { ViewType } from '../../Helpers/shared/Models/ViewType';
import { ShowType, SingleTicketTypeButton } from './SingleTicketTypeButton';
interface TicketTypeButtonsProps {
  type: ViewType;
  setStep: Dispatch<SetStateAction<number>>;
  setShowType: Dispatch<SetStateAction<ShowType>>;
}

export const TicketTypeButtons: FC<TicketTypeButtonsProps> = ({ type, setStep, setShowType }) => {
  const handleClick = useCallback(
    (showType: ShowType) => {
      setShowType(showType);
      setStep(2);
    },
    [setShowType, setStep],
  );

  return (
    <TicketTypeButtonCont>
      <SingleTicketTypeButton
        code={ShowType.IKWP}
        title="Create A Ticket Listing"
        subtitle="For shows that already have a confirmed lineup"
        handleClick={handleClick}
      />
      {type === 'venue' && (
        <SingleTicketTypeButton
          code={ShowType.GIG}
          title="I’m Looking For Talent"
          subtitle="Post a gig and pick from over 1,000 local artist through TuneHatch"
          handleClick={handleClick}
        />
      )}
      {(type === 'artist' || type === 'showrunner') && (
        <SingleTicketTypeButton
          code={ShowType.EXTERNAL}
          title="External Ticket Link"
          subtitle="Get your link and be able to view all of your shows in the upcoming shows on your TuneHatch profile"
          handleClick={handleClick}
        />
      )}
    </TicketTypeButtonCont>
  );
};

const TicketTypeButtonCont = styled(Box, {
  name: 'TicketTypeButtonCont',
})({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: '20px',
});
