import React, { FC, useCallback, useEffect, useState } from 'react';
import { FieldErrorsImpl, useFormContext } from 'react-hook-form';
import { TextField } from '../../../ui/inputs/TextField';
import { SingleOption } from '../../../ui/inputs/ChipMultiselect';
import { FormRowCont, OffPlatformFormPartCont } from '../Shared/Containers';
import { LocationField } from '../../../ui/inputs/LocationField';
import { OffPlatformSubTitle, OffPlatformTitle } from '../Shared/TextElements';
import { OffPlatformAvatar } from '../Shared/Other';
import { HiddenField } from '../../../ui/inputs/HiddenField';
import { Box } from '@mui/material';
import { Button } from '../../../ui/buttons/Button';
import { IconButton } from '../../../ui/buttons/IconButton';

interface OffPlatformVenueFormProps {
  singleOption: SingleOption;
  index: number;
}

export const OffPlatformVenueForm: FC<OffPlatformVenueFormProps> = ({ singleOption, index }) => {
  const createShowForm = useFormContext();
  const [isHidden, setIsHidden] = useState(false);
  const venueErrors = createShowForm.formState.errors as unknown as FieldErrorsImpl<{
    venues: {
      name: string;
      bookingContactName: string;
      email: string;
      location: string;
    }[];
  }>;

  const handleHide = useCallback(() => {
    createShowForm.trigger([
      `venues[${index}].stageName`,
      `venues[${index}].email`,
      `venues[${index}].bookingContactName`,
      `venues[${index}].id`,
      `venues[${index}].location`,
    ]);
    setTimeout(() => {
      if (
        !venueErrors?.venues?.[index]?.name?.message &&
        !venueErrors?.venues?.[index]?.bookingContactName?.message &&
        !venueErrors?.venues?.[index]?.email?.message &&
        !venueErrors?.venues?.[index]?.location?.message
      ) {
        setIsHidden(true);
      }
    }, 100);
  }, [venueErrors, createShowForm]);

  useEffect(() => {
    createShowForm.setValue(`venues[${index}].id`, '0');
  }, [createShowForm]);

  return (
    <OffPlatformFormPartCont hiddenElements={isHidden}>
      <FormRowCont>
        <OffPlatformAvatar src={singleOption.avatar} />
        <OffPlatformTitle>{`Off platform: ${singleOption.label}`}</OffPlatformTitle>
        {isHidden && (
          <IconButton
            isPrimary
            disabled={false}
            size="small"
            onClick={() => {
              setIsHidden(false);
            }}
            iconName="Edit"
            sx={{ marginLeft: 'auto' }}
          />
        )}
      </FormRowCont>
      <FormRowCont hidden={isHidden}>
        <OffPlatformSubTitle>
          This venue is not yet on TuneHatch. Please enter their information to generate their Venue Profile.
        </OffPlatformSubTitle>
      </FormRowCont>
      <FormRowCont hidden={isHidden}>
        <HiddenField
          {...createShowForm.register(`venues[${index}].id`)}
          value={'0'}
        />
        <TextField
          {...createShowForm.register(`venues[${index}].name`)}
          type="text"
          label="Venue Name"
          placeholder="Enter venue name"
          defaultValue={singleOption.label}
          error={!!venueErrors?.venues?.[index].name?.message}
          helperText={!!venueErrors?.venues?.[index].name?.message && <>{venueErrors?.venues?.[index].name?.message}</>}
        />
        <TextField
          {...createShowForm.register(`venues[${index}].bookingContactName`)}
          type="text"
          label="Booking Contact Name"
          placeholder="Enter booking contact"
          error={!!venueErrors?.venues?.[index].bookingContactName?.message}
          helperText={
            !!venueErrors?.venues?.[index].bookingContactName?.message && (
              <>{venueErrors?.venues?.[index].bookingContactName?.message}</>
            )
          }
        />
      </FormRowCont>
      <Box hidden={isHidden}>
        <TextField
          {...createShowForm.register(`venues[${index}].email`)}
          type="text"
          label="Email"
          placeholder="Enter email"
          error={!!venueErrors?.venues?.[index].email?.message}
          helperText={
            !!venueErrors?.venues?.[index].email?.message && <>{venueErrors?.venues?.[index].email?.message}</>
          }
          fullWidth
        />
        <LocationField
          fieldName={`venues[${index}].location`}
          label="Address"
          placeholder="Enter a location"
          formFieldName={`venues[${index}].location`}
        />
      </Box>

      <FormRowCont hidden={isHidden}>
        <Button
          isPrimary
          disabled={false}
          onClick={handleHide}
          size="small"
        >
          Submit
        </Button>
      </FormRowCont>
    </OffPlatformFormPartCont>
  );
};
