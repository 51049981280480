import React, { useEffect, useState } from 'react';
import { useCheckCashLedgerQuery, useUpdateCashLedgerMutation } from '../../../Redux/API/VenueAPI';
import { useAppSelector } from '../../../hooks';
import LoadingSpinner from '../../../Components/LoadingSpinner';

export default function LedgerEntryField(props: { type: 'tickets' | 'bar'; venueID: string; showID: string }) {
  const SECRET_UID = useAppSelector((user) => user.user.data.uid);
  const ledgerData = useCheckCashLedgerQuery({
    SECRET_UID: SECRET_UID,
    venueID: props.venueID,
    showID: props.showID,
  });
  const [updateLedger, ledgerUpdateStatus] = useUpdateCashLedgerMutation();
  const [timeoutID, setTimeoutID] = useState<any>();
  const [value, setValue] = useState(ledgerData.data?.entries?.[props.type]);
  useEffect(() => {
    let targetEntry = ledgerData.data?.entries?.[props.type];
    console.log(ledgerData.data?.entries);
    console.log(targetEntry);
    if (targetEntry) {
      setValue(targetEntry);
    }
  }, [ledgerData.data?.entries?.[props.type]]);

  const updateValue = (value: string) => {
    setValue(value);
    clearTimeout(timeoutID);
    setTimeoutID(() =>
      setTimeout(() => {
        updateLedger({
          SECRET_UID: SECRET_UID,
          venueID: props.venueID,
          showID: props.showID,
          type: props.type,
          amount: value,
        });
      }, 1000),
    );
  };
  return ledgerData.data ? (
    <>
      <div className="flex flex-col w-full">
        <p className="text-xs">{props.type === 'tickets' ? 'Cash Sales' : 'Bar Sales'}</p>
        <input
          className="border border-gray-400 rounded-md text-base p-1 w-full"
          onChange={(e) => {
            updateValue(e.target.value);
          }}
          value={value}
          type="number"
          placeholder={props.type === 'tickets' ? 'Cash Sales' : 'Bar Sales'}
        />
        {ledgerUpdateStatus.isSuccess ? <p className="text-xs">Saved!</p> : <></>}
      </div>
    </>
  ) : (
    <>
      <LoadingSpinner />
    </>
  );
}
