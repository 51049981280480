import React, { FC } from 'react';
import { Box } from '@mui/material';

import { TabHeadlineText, TabPanelInnerCont } from '../Shared';
import { FAQ } from '../FAQ/FAQ';
import { PERFORMER_FAQ_DATA } from '../FAQ/performerFaqData';
import { ShowcaseBoxGroup } from '../../VenuesLP/Showcases/ShowcaseBoxGroup';
import { ShowcaseBox } from '../../VenuesLP/Showcases/ShowcaseBox';

import { PricingImage } from '../../VenuesLP/Pricing/Pricing';

import { PERFORMERS_TAB_CONTENT } from './performentsContentData';

import PricingImg from '../../../Images/Homepage/VenueTab/venuepricing.png';
import Showcase1Img from '../../../Images/Homepage/PerformersTab/showcase1.png';
import Showcase2Img from '../../../Images/Homepage/PerformersTab/showcase2.png';
import Showcase3Img from '../../../Images/Homepage/PerformersTab/showcase3.png';
import Showcase4Img from '../../../Images/Homepage/PerformersTab/showcase4.png';
import Showcase5Img from '../../../Images/Homepage/PerformersTab/showcase5.png';
import Showcase6Img from '../../../Images/Homepage/PerformersTab/showcase6.png';
import Showcase7Img from '../../../Images/Homepage/PerformersTab/showcase7.png';
import { CtaBox } from '../CtaBox';
import { VenueReviews } from '../../VenuesLP/VenueReviews/VenueReviews';
import { performersTestimonials } from '../../VenuesLP/VenueReviews/performersTestimonials';
import { PricingText } from '../PricingText';

export const PerformersContent: FC = () => {
  return (
    <TabPanelInnerCont>
      <TabHeadlineText>
        Loving to perform doesn’t always mean loving the behind-the-scenes tasks. Booking, promoting, and ticketing can
        be time-consuming and stressful. TuneHatch handles the details for you, so you can focus on your shows.
      </TabHeadlineText>

      <ShowcaseBoxGroup
        title="Create your own ticket links"
        bgColor={'transparent'}
        darkMode={true}
      >
        <ShowcaseBox
          text={PERFORMERS_TAB_CONTENT.showcaseText1}
          imageUrl={Showcase1Img}
          darkMode={true}
        />
        <ShowcaseBox
          text={PERFORMERS_TAB_CONTENT.showcaseText2}
          imageUrl={Showcase2Img}
          darkMode={true}
        />
        <ShowcaseBox
          text={PERFORMERS_TAB_CONTENT.showcaseText3}
          imageUrl={Showcase3Img}
          darkMode={true}
        />
        <ShowcaseBox
          text={PERFORMERS_TAB_CONTENT.showcaseText4}
          imageUrl={Showcase4Img}
          darkMode={true}
        />
      </ShowcaseBoxGroup>

      <ShowcaseBoxGroup
        title="Book and get booked"
        bgColor={'transparent'}
        darkMode={true}
      >
        <ShowcaseBox
          text={PERFORMERS_TAB_CONTENT.showcaseText5}
          imageUrl={Showcase5Img}
          darkMode={true}
        />
        <ShowcaseBox
          text={PERFORMERS_TAB_CONTENT.showcaseText6}
          imageUrl={Showcase6Img}
          darkMode={true}
        />
        <ShowcaseBox
          text={PERFORMERS_TAB_CONTENT.showcaseText7}
          imageUrl={Showcase7Img}
          darkMode={true}
        />
      </ShowcaseBoxGroup>

      <PricingText />
      <a href="mailto:info@tunehatch.com">
        <PricingImage src={PricingImg} />
      </a>
      <Box
        display="block"
        position="relative"
        width="100%"
        maxWidth="100vw"
      >
        <VenueReviews testimonials={performersTestimonials} />
      </Box>
      <FAQ faqData={PERFORMER_FAQ_DATA} />

      <CtaBox isVenue={false} />
    </TabPanelInnerCont>
  );
};
