import React, { FC, useCallback, ChangeEvent, Dispatch, SetStateAction, useMemo } from 'react';
import { DataCy } from '../../../Helpers/DataCy';
import { Button } from '../../../ui/buttons/Button';
import {
  FormStepHeadlineContainer,
  FormStepInnerContainer,
  FormTitleWithSubtitleContainer,
  FormStepFormContainerOuter,
  FormMainContainer,
  FormMainContainerColumn,
} from '../Shared/Containers';
import { FormStepHeadlineStepCounter, FormStepHeadlineTitle } from '../Shared/RegistrationTypographies';
import { FormStepProps } from '../Shared/Types';
import { FieldValues, SubmitHandler, useFormContext } from 'react-hook-form';
import { ArtistPublicProfileForm } from '../../../Forms/AccountInfoForms/Artist/ArtistPublicProfileForm';

interface ArtistFormStep2Props extends FormStepProps {
  setAvatar: Dispatch<SetStateAction<File | null>>;
  selectedAvatar: File | null;
  submitArtist: SubmitHandler<FieldValues>;
  isLoading?: boolean;
}

export const ArtistFormStep2: FC<ArtistFormStep2Props> = ({
  step,
  setStep,
  setAvatar,
  submitArtist,
  selectedAvatar,
  isLoading,
}) => {
  const artistForm = useFormContext();

  const handleBack = useCallback(() => {
    setStep((step) => step - 1);
  }, [setStep]);

  return (
    <FormMainContainerColumn>
      <FormStepHeadlineContainer>
        <FormStepInnerContainer>
          <FormTitleWithSubtitleContainer>
            <FormStepHeadlineStepCounter>Step 2 of 2s</FormStepHeadlineStepCounter>
            <FormStepHeadlineTitle>Set up your public profile</FormStepHeadlineTitle>
          </FormTitleWithSubtitleContainer>
        </FormStepInnerContainer>
      </FormStepHeadlineContainer>
      <FormStepFormContainerOuter>
        <FormStepInnerContainer>
          <ArtistPublicProfileForm
            setAvatar={setAvatar}
            selectedAvatar={selectedAvatar}
          ></ArtistPublicProfileForm>
          <FormMainContainer>
            <Button
              size="large"
              onClick={handleBack}
              data-cy={DataCy.button('prevStep')}
            >
              Back
            </Button>
            <FormMainContainerColumn>
              <Button
                isPrimary
                size="large"
                onClick={artistForm.handleSubmit(submitArtist, () => console.log(artistForm.formState.errors))}
                type="submit"
                data-cy={DataCy.button('saveArtist')}
                disabled={isLoading}
                isLoading={isLoading}
              >
                Next
              </Button>
            </FormMainContainerColumn>
          </FormMainContainer>
        </FormStepInnerContainer>
      </FormStepFormContainerOuter>
    </FormMainContainerColumn>
  );
};
