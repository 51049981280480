import React, { FC } from 'react';
import { TabPanelInnerCont } from '../Shared';
import { TopShows } from '../TopShows/TopShows';
import { Box } from '@mui/material';
import { VIEWER_FAQ_DATA } from '../FAQ/viewerFaqData';
import { FAQ } from '../FAQ/FAQ';

export const ViewersContent: FC = () => {
  return (
    <TabPanelInnerCont>
      {
        //TODO add title here
      }
      <Box
        display="flex"
        justifyContent="center"
        width="100%"
      >
        <TopShows />
      </Box>
      <FAQ faqData={VIEWER_FAQ_DATA} />
    </TabPanelInnerCont>
  );
};
