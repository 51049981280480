import { Autocomplete } from '@react-google-maps/api';
import { get } from 'lodash';
import React, { useState, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import z from 'zod';
import { HiddenField } from './HiddenField';
import { TextField } from './TextField';

export const locationValueSchema = z.object({
  displayAddress: z.string().nullish(),
  address: z.string().nullish(),
  city: z.string().nullish(),
  state: z.string().nullish(),
  zip: z.string().nullish(),
  latitude: z.number().or(z.literal('')).nullish(), //'' is the default value for the field
  longitude: z.number().or(z.literal('')).nullish(),
});
type ValueFields = keyof typeof locationValueSchema.shape;

function getComponentValue(params: {
  components: google.maps.GeocoderAddressComponent[];
  type: string;
  form: 'long' | 'short';
  defaultValue: string;
}) {
  const component = params.components.find((component) => component.types.includes(params.type));
  if (component === undefined) {
    return params.defaultValue;
  }
  const value = params.form === 'long' ? component?.long_name : component?.short_name;
  return value ?? params.defaultValue;
}

type Props = {
  fieldName: string;
  label?: string;
  placeholder?: string;
  types?: string[];
  formFieldName?: string;
};

const restrictions: google.maps.places.ComponentRestrictions = { country: 'US' };

/**
 * @see https://developers.google.com/maps/documentation/places/web-service/supported_types
 */
export function LocationField(props: Props) {
  /**
   * Without this, if you open directly the page(without using the app navigation)
   * with the form using this field it will throw that the google script
   * is not loaded yet.
   */
  if (get(window, 'google.maps.places') === undefined) {
    return <></>;
  }

  return <LocationFieldComponent {...props} />;
}

function LocationFieldComponent(props: Props) {
  const { register, setValue, formState, trigger } = useFormContext();
  const [autocomplete, setAutocomplete] = useState(null);
  const fieldNames = useMemo<Record<ValueFields, string>>(
    () => ({
      displayAddress: `${props.fieldName}.displayAddress`,
      address: `${props.fieldName}.address`,
      city: `${props.fieldName}.city`,
      state: `${props.fieldName}.state`,
      zip: `${props.fieldName}.zip`,
      latitude: `${props.fieldName}.latitude`,
      longitude: `${props.fieldName}.longitude`,
    }),
    [props.fieldName],
  );

  const onPlaceChanged = () => {
    if (autocomplete === null) {
      return;
    }
    const place = autocomplete.getPlace();
    const components: google.maps.GeocoderAddressComponent[] = place.address_components;
    setValue(
      fieldNames.address,
      `${getComponentValue({
        components,
        type: 'street_number',
        form: 'long',
        defaultValue: '',
      })} ${getComponentValue({
        components,
        type: 'route',
        form: 'long',
        defaultValue: '',
      })}`,
    );
    setValue(
      fieldNames.city,
      getComponentValue({
        components,
        type: 'locality',
        form: 'long',
        defaultValue: '',
      }),
    );
    setValue(
      fieldNames.state,
      getComponentValue({
        components,
        type: 'administrative_area_level_1',
        form: 'short',
        defaultValue: '',
      }),
    );
    setValue(
      fieldNames.zip,
      getComponentValue({
        components,
        type: 'postal_code',
        form: 'short',
        defaultValue: '',
      }),
    );
    setValue(fieldNames.latitude, place.geometry.location.lat());
    setValue(fieldNames.longitude, place.geometry.location.lng());
    if (props.formFieldName) {
      trigger(props.formFieldName);
    }
  };
  const errorMsg = get(
    formState.errors,
    `${props.formFieldName ? props.formFieldName : fieldNames.displayAddress}.message`,
  );
  console.log(errorMsg, 'error in location');
  return (
    <>
      <Autocomplete
        onLoad={(autocomplete) => {
          setAutocomplete(autocomplete);
        }}
        onPlaceChanged={onPlaceChanged}
        types={props.types ?? ['address']}
        restrictions={restrictions}
      >
        <TextField
          {...register(fieldNames.displayAddress)}
          label={props.label}
          placeholder={props.placeholder}
          error={!!errorMsg}
          helperText={!!errorMsg && <>{errorMsg}</>}
          style={{ width: '100%' }}
        />
      </Autocomplete>
      <HiddenField {...register(fieldNames.address)} />
      <HiddenField {...register(fieldNames.city)} />
      <HiddenField {...register(fieldNames.state)} />
      <HiddenField {...register(fieldNames.zip)} />
      <HiddenField {...register(fieldNames.latitude)} />
      <HiddenField {...register(fieldNames.longitude)} />
    </>
  );
}
