import { ArtistApi } from './../../Redux/API/ArtistAPI';
import { getRequest } from '../../Redux/API/Request/ApiRequest';

// To DO: create a venue and showrunner types
type ImageResponse = {
  images: string[];
};

export const fetchImagesHelper = async (entityType: string, field: string, resourceId: string) => {
  if (entityType === 'venue') {
    const venue = (await getRequest({
      endpoint: `/v2/venues/${resourceId}`,
    })) as ImageResponse;

    return venue.images || [];
  }

  if (entityType === 'artist') {
    const artist = (await getRequest({
      endpoint: `/v2/artists/${resourceId}`,
    })) as ArtistApi.Artist;

    if (field === 'images') {
      return artist.images;
    }

    if (field === 'headshots') {
      return artist.assets?.headshotsImages || [];
    }

    if (field === 'stagePlot') {
      return artist.assets?.stagePlotImages || [];
    }
  }

  if (entityType === 'showrunner') {
    const sggroup = (await getRequest({
      endpoint: `/v2/showrunner-groups/${resourceId}`,
    })) as ImageResponse;
    return sggroup.images;
  }
};
