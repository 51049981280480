export const DataCy = {
  button(id?: string) {
    return `button-${id}`;
  },
  input(id?: string) {
    return `input-${id}`;
  },
  select(id?: string) {
    return `select-${id}`;
  },
  modal(id?: string) {
    return `modal-${id}`;
  },
  link(id?: string) {
    return `link-${id}`;
  },
  image(id?: string) {
    return `image-${id}`;
  },
};
