import React, { FC, Dispatch, SetStateAction, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { DataCy } from '../../Helpers/DataCy';
import { ApiErrorCodes } from '../../Redux/API/ErrorCodes';
import { isBadRequestResponse } from '../../Redux/API/Request/RequestErrors';
import { TextField } from '../../ui/inputs/TextField';
import { Button } from '../../ui/buttons/Button';
import { FormStyled, LeftHandSideContainerInner } from './Shared/Containers';
import z from 'zod';
import axios, { AxiosError } from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { setCookie } from '../../Helpers/HelperFunctions';
import { isNil, omitBy } from 'lodash';
import { setClaimData } from './Shared/ClaimDataHelpers';

interface RegisterFormProps {
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  claimData?: {
    email: string;
  };
  claimCode?: string;
}

interface RegisterFormFormItems {
  email: string;
  password: string;
}

const schema = z.object({
  email: z.string({ message: 'The email must present' }).email('The email must be a valid email'),
  password: z
    .string({ message: 'The password must present' })
    .min(8, 'Password needs to be at least 8 characters long'),
});

export const RegisterForm: FC<RegisterFormProps> = ({ setIsLoading, claimData, claimCode }) => {
  const { userType } = useParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const initialRegisterState = {
    email: claimData?.email ?? '',
    password: '',
  };
  const registerForm = useForm<RegisterFormFormItems>({
    resolver: zodResolver(schema),
    defaultValues: initialRegisterState,
    values: initialRegisterState,
    mode: 'all',
  });

  const submitRegister: SubmitHandler<RegisterFormFormItems> = async (data) => {
    setIsLoading(true);
    setLoading(true);
    try {
      const registrationData = {
        ...data,
        claimCode,
      };
      const registrationDataClean = omitBy(registrationData, isNil);
      const result = await axios.post(
        `${process.env.REACT_APP_PUBLIC_URL}:${process.env.REACT_APP_SERVER_PORT}/api/v2/users`,
        registrationDataClean,
        {
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
          },
        },
      );
      if (result.status === 201) {
        setIsLoading(false);
        setLoading(false);
        setCookie('SECRET_UID', result.data.token);
        if (claimCode) {
          setClaimData(result.data);
        }
        navigate(`/register/new-${userType}`);
      } else {
        setIsLoading(false);
        setLoading(false);
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        setLoading(false);
        const response = error.response.data;
        if (isBadRequestResponse(response) && response.code === ApiErrorCodes.UserEmailAlreadyOccupied) {
          window.alert('Given email is already taken');
        } else {
          console.warn({ error: error.response });
          window.alert('Unknown server error');
        }
      } else {
        console.warn({ error });
        window.alert('Unknown server error');
      }
      setIsLoading(false);
    }
  };

  return (
    <FormStyled
      onSubmit={registerForm.handleSubmit(submitRegister)}
      noValidate
    >
      <LeftHandSideContainerInner>
        <TextField
          {...registerForm.register('email')}
          label="Email"
          type="email"
          placeholder="name@example.com"
          error={!!registerForm.formState.errors?.email?.message}
          helperText={
            !!registerForm.formState.errors.email?.message && <>{registerForm.formState.errors?.email.message}</>
          }
        />
        <TextField
          {...registerForm.register('password')}
          label="Password"
          type="password"
          placeholder="***********"
          error={!!registerForm.formState.errors?.password?.message}
          helperText={
            !!registerForm.formState.errors.password?.message && <>{registerForm.formState.errors?.password?.message}</>
          }
        />
      </LeftHandSideContainerInner>
      <Button
        isPrimary
        size="large"
        type="submit"
        data-cy={DataCy.button('createAccount')}
        isLoading={loading}
        disabled={loading}
      >
        Create an account
      </Button>
    </FormStyled>
  );
};
