import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from 'react';
import { DataCy } from '../../../Helpers/DataCy';
import { MultipleSelectChip } from '../../../ui/inputs/ChipMultiselect';
import { FormSectionContainer } from '../Shared/Containers';
import { FormSectionTitle } from '../Shared/RegistrationTypographies';
import { useFormContext } from 'react-hook-form';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import { isArray, uniq } from 'lodash';

interface ArtistType {
  id: string;
  firstname: string;
  lastname: string;
  avatar?: string | null;
  avatarWebP: string | null;
  stageName: string;
}
interface SingleOption {
  label: string;
  id: string;
  avatar: string | null;
}
export const ArtistsWorkedWithForm: FC<{
  defaultRoster?: SingleOption[];
  setForceDirty?: Dispatch<SetStateAction<boolean>>;
}> = ({ defaultRoster, setForceDirty }) => {
  const [selectValue, setSelectValue] = useState<SingleOption[]>([]);
  const promoterForm = useFormContext();
  const [isLoading, setIsLoading] = useState(false);
  const [artistList, setArtistsList] = useState<SingleOption[]>([]);
  const [search, setSearch] = useSearchParams();

  const handleToggleRosterElements = useCallback(
    (value: SingleOption | SingleOption[], isDelete?: boolean) => {
      if (isDelete) {
        const actualValues: string[] = promoterForm.getValues('roster');
        const deletedElements: SingleOption | null = selectValue.find((singleartist) => {
          if (!isArray(value)) {
            return singleartist.label === value.label;
          } else {
            return value.map((singleValue) => singleartist.label === singleValue.label);
          }
        });
        if (deletedElements) {
          const newValues = actualValues.filter((singleValue) => singleValue !== deletedElements.id);
          promoterForm.setValue('roster', newValues);
        }
      } else {
        const filteredOptions = artistList.filter((singleOption) => {
          if (!isArray(value)) {
            return singleOption.label === value.label;
          } else {
            return !!value.find((singleValue) => {
              return singleValue.label === singleOption.label;
            });
          }
        });
        const indexesList = filteredOptions.map((opt) => opt.id);
        promoterForm.setValue('roster', uniq(filteredOptions));
      }
      setForceDirty?.(true);
    },
    [artistList, promoterForm, selectValue],
  );

  const getArtistList = useCallback(async () => {
    try {
      setIsLoading(true);
      const result = await axios.get(
        `${process.env.REACT_APP_PUBLIC_URL}:${process.env.REACT_APP_SERVER_PORT}/api/v2/artists${search.get('search') ? '?phrase=' + search.get('search') : ''}`,
      );
      if (result.status === 200) {
        const artistsListUpdated = result.data.data.map((singleResult: ArtistType) => {
          return {
            id: singleResult.id,
            label: singleResult.stageName
              ? singleResult.stageName
              : `${singleResult.firstname ?? ''} ${singleResult.lastname ?? ''}`,
            avatar: singleResult.avatarWebP,
          };
        });
        setArtistsList(artistsListUpdated);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        window.alert(result.data.message);
      }
    } catch {
      setIsLoading(false);
      window.alert('Unknown server error');
    }
  }, [setArtistsList, search]);

  useEffect(() => {
    getArtistList();
  }, [getArtistList]);

  useEffect(() => {
    if (defaultRoster) {
      setSelectValue(defaultRoster);
    }
  }, []);

  useEffect(() => {
    const newSelectVal = [...selectValue];
    const newRosterIds = newSelectVal.map((val) => val.id);
    promoterForm.setValue('roster', newRosterIds);
  }, [selectValue]);

  return (
    <FormSectionContainer>
      <FormSectionTitle>Search for artist you work with</FormSectionTitle>
      <MultipleSelectChip
        selectValue={selectValue}
        setSelectValue={setSelectValue}
        options={artistList}
        handleChangeOuter={handleToggleRosterElements}
        label="Add artists"
        placeholder="Search for an artist"
        setSearch={setSearch}
        search={search.get('search')}
        dataCy={DataCy.select('artists')}
        isLoading={isLoading}
        name="artists"
        error={promoterForm.getFieldState('artists').invalid}
        helperText={
          promoterForm.getFieldState('artists').invalid && <>{promoterForm.getFieldState('artists').error.message}</>
        }
      />
    </FormSectionContainer>
  );
};
