import { Box, styled, Typography } from '@mui/material';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { colors } from '../../ui/shared/Colors';
import { typography } from '../../ui/shared/TypographySharedElements';
import { GoogleMapsEmbed } from './GoogleMapsEmbed';
import { Button } from '../../ui/buttons/Button';
import { openModal } from '../../Redux/UI/UISlice';
import { displayTicketPrice } from '../../Helpers/HelperFunctions';
import { isNil } from 'lodash';
import calculateEventTime from '../../Helpers/shared/calculateEventTime';
import { useGetActiveRegionsQuery } from '../../Redux/API/RegionAPI';
import { getVenueLocationCode } from '../../Helpers/shared/getVenueLocationCode';
import { Venue } from '../../Helpers/shared/Models/Venue';
import { useAppDispatch } from '../../hooks';

export interface ShowDetailsMapWithAddrProps {
  location: {
    address: string;
    city: string;
    state: string;
    zip: string;
  };
  show: any;
  venue: Venue;
  isBottomLine?: boolean;
  applicationDetails?: boolean;
  manageView?: boolean;
}

export const ShowDetailsMapWithAddr: FC<ShowDetailsMapWithAddrProps> = ({
  location,
  show,
  venue,
  isBottomLine,
  applicationDetails,
  manageView,
}) => {
  const activeRegions = useGetActiveRegionsQuery();
  const [timezone, setTimezone] = useState('America/Chicago');
  const regions = activeRegions.data;
  const regionCode = getVenueLocationCode(venue);
  const dispatch = useAppDispatch();

  const displayTime = useMemo(() => {
    return isNil(show) === false
      ? calculateEventTime({ starttime: show.startTime, endtime: show.endTime }, timezone)
      : undefined;
  }, [show, timezone]);

  const ticketPrice = useMemo(
    () =>
      displayTicketPrice({
        ticket_tiers: show.ticketTiers,
        ticket_cost: show.ticketCost,
        doorPrice: show.doorPrice,
      }),
    [show],
  );

  const getPurschaseButtonLabel = () => {
    if (show.rsvpOnly) {
      return 'Sign In';
    }

    if (ticketPrice === 'Free') {
      return 'Show Info';
    }

    return 'Buy Tickets';
  };

  const handlePurchaseTicket = useCallback(() => {
    let modalData: {
      showID: string;
      free?: boolean;
      rsvp?: boolean;
    } = { showID: show.id };

    if (show.rsvpOnly) {
      modalData.rsvp = true;
    } else if (ticketPrice === 'Free') {
      modalData.free = true;
    }

    dispatch(
      openModal({
        status: true,
        component: 'TicketPurchase',
        data: modalData,
      }),
    );
  }, [ticketPrice, show.id, dispatch]);

  useEffect(() => {
    if (regions && regionCode) {
      regions.forEach((region) => {
        if (region.locations.includes(regionCode)) {
          setTimezone(region.timezone);
        }
      });
    }
  }, [regions, regionCode]);

  return (
    <ShowDetailsMapWithAddrCont>
      {venue?.name && <ShowDetailsMapWithAddrTitle>{show?.name}</ShowDetailsMapWithAddrTitle>}
      <Box height="120px">
        <GoogleMapsEmbed address={`${location.address} ${location.city} ${location.state} ${location.zip}`} />
      </Box>
      <ShowDetailsMapWithAddrAddress>
        <ShowDetailsMapWithAddrAddressItem>
          <ShowDetailsMapWithAddrAddressItemIconBox>
            <ShowDetailsMapWithAddrAddressItemIcon className="material-symbols-outlined">
              distance
            </ShowDetailsMapWithAddrAddressItemIcon>
          </ShowDetailsMapWithAddrAddressItemIconBox>
          <ShowDetailsMapWithAddrAddressSubCont>
            <ShowDetailsMapWithAddrAddressSubTitle>{venue?.name}</ShowDetailsMapWithAddrAddressSubTitle>
            <ShowDetailsMapWithAddrAddressSubText>{`${location.city}, ${location.state}, USA`}</ShowDetailsMapWithAddrAddressSubText>
          </ShowDetailsMapWithAddrAddressSubCont>
        </ShowDetailsMapWithAddrAddressItem>
        <ShowDetailsMapWithAddrAddressItem>
          <ShowDetailsMapWithAddrAddressItemIconBox>
            <ShowDetailsMapWithAddrAddressItemIcon className="material-symbols-outlined">
              event
            </ShowDetailsMapWithAddrAddressItemIcon>
          </ShowDetailsMapWithAddrAddressItemIconBox>
          <ShowDetailsMapWithAddrAddressSubCont>
            <ShowDetailsMapWithAddrAddressSubTitle>{`${displayTime.startdate.fragments.abbrMonth} ${displayTime.startdate.fragments.day}`}</ShowDetailsMapWithAddrAddressSubTitle>
            <ShowDetailsMapWithAddrAddressSubText>{`at ${displayTime.starttime}`}</ShowDetailsMapWithAddrAddressSubText>
          </ShowDetailsMapWithAddrAddressSubCont>
        </ShowDetailsMapWithAddrAddressItem>
        <ShowDetailsMapWithAddrAddressItem>
          <ShowDetailsMapWithAddrAddressItemIconBox>
            <ShowDetailsMapWithAddrAddressItemIcon className="material-symbols-outlined">
              attach_money
            </ShowDetailsMapWithAddrAddressItemIcon>
          </ShowDetailsMapWithAddrAddressItemIconBox>
          <ShowDetailsMapWithAddrAddressSubCont>
            <ShowDetailsMapWithAddrAddressSubTitle>{`From ${ticketPrice}`}</ShowDetailsMapWithAddrAddressSubTitle>
            <ShowDetailsMapWithAddrAddressSubText>{`+${show.minAge}`}</ShowDetailsMapWithAddrAddressSubText>
          </ShowDetailsMapWithAddrAddressSubCont>
        </ShowDetailsMapWithAddrAddressItem>
      </ShowDetailsMapWithAddrAddress>
      {!applicationDetails && !manageView && (
        <ShowDetailsMapWithAddrStickyButtonContainer>
          <Button
            isPrimary
            size="large"
            disabled={false}
            onClick={handlePurchaseTicket}
          >
            {getPurschaseButtonLabel()}
          </Button>
        </ShowDetailsMapWithAddrStickyButtonContainer>
      )}
    </ShowDetailsMapWithAddrCont>
  );
};

const ShowDetailsMapWithAddrCont = styled(Box, {
  name: 'ShowDetailsHeadingRightCont',
})({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: 'auto',
  gap: '24px',
  backgroundColor: colors.SystemWhite,
  borderRadius: '12px',
  padding: '20px',
});

const ShowDetailsMapWithAddrTitle = styled(Typography, {
  name: 'ShowDetailsMapWithAddrTitle',
})({
  color: colors.SystemBlack,
  ...typography.headlineSmall,
});

const ShowDetailsMapWithAddrAddress = styled(Box, {
  name: 'ShowDetailsHeadingAddress',
})({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
});

const ShowDetailsMapWithAddrAddressItem = styled(Box, {
  name: 'ShowDetailsHeadingAddressItem',
})({
  display: 'flex',
  justifyContent: 'flex-start',
  gap: '12px',
  width: '100%',
  alignItems: 'center',
});

const ShowDetailsMapWithAddrAddressItemIconBox = styled(Box, {
  name: 'ShowDetailsMapWithAddrAddressItemIconBox',
})({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '56px',
  height: '56px',
  backgroundColor: colors.SystemGray100,
});

const ShowDetailsMapWithAddrAddressItemIcon = styled('i', {
  name: 'ShowDetailsMapWithAddrAddressItemIcon',
})({
  display: 'block',
  width: '28px',
  height: '28px',
  color: '#1C1B1F',
});

const ShowDetailsMapWithAddrAddressSubCont = styled(Box, {
  name: 'ShowDetailsMapWithAddrAddressSubCont',
})({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  width: '100%',
});

const ShowDetailsMapWithAddrAddressSubTitle = styled(Typography, {
  name: 'ShowDetailsMapWithAddrAddressSubTitle',
})({
  color: colors.SystemBlack,
  ...typography.bodyLargeBold,
});

const ShowDetailsMapWithAddrAddressSubText = styled(Typography, {
  name: 'ShowDetailsMapWithAddrAddressSubText',
})({
  color: colors.SystemBlack,
  ...typography.bodyMediumRegular,
});

const ShowDetailsMapWithAddrStickyButtonContainer = styled(Box, {
  name: 'ShowDetailsMapWithAddrStickyButtonContainer',
})(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  bgcolor: 'transparent',
  [theme.breakpoints.down('md')]: {
    position: 'sticky',
    bottom: '20px',
  },
}));
