import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DataCy } from '../../Helpers/DataCy';
import { Show, ShowManagerType } from '../../Helpers/shared/Models/Show';
import { ViewType } from '../../Helpers/shared/Models/ViewType';
import { useGetAllShowsQuery, useGetAllVenuesQuery, useVenueShows } from '../../Redux/API/PublicAPI';
import { useNavigate, useParams } from 'react-router-dom';
import ShowCalendar from '../../Tools/ShowCalendar';
import ManageShowsList from '../../Tools/Venue/ManageShowsList';
import LoadingWrapper from '../../Components/Layout/LoadingWrapper';
import { LabelButton } from '../../Components/Buttons/LabelButton';
import { openModal } from '../../Redux/UI/UISlice';
import { FilterByShowTime } from '../../Helpers/FilterFunctions/ShowFilterFunctions';
import { Button } from '../../Components/Buttons/Button';
import { renderPageTitle } from '../../Helpers/HelperFunctions';
import { UpcomingShowShares } from '../../Tools/Venue/UpcomingShowShares';
import StripeHandler from '../../Components/Buttons/StripeHandler';
import { GuidedTours } from '../GuidedTours/GuidedTours';
import { useMediaQuery, useTheme } from '@mui/material';
import LoadingSpinner from '../../Components/LoadingSpinner';

export const VenueDashboard: FC = () => {
  const { venueID } = useParams();
  const navigate = useNavigate();
  const venues = useGetAllVenuesQuery();
  const shows = useVenueShows({ venueId: venueID });
  const venue = venues?.data?.[venueID!];
  const createShowBtnRef = useRef<HTMLButtonElement>(null);
  const editProfileBtnRef = useRef<HTMLButtonElement>(null);
  const editHLBtnRef = useRef<HTMLButtonElement>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    if (venue) {
      renderPageTitle('Manage ' + venue.name);
    }
  }, [venue]);

  if (venues.isLoading || shows.isLoading) {
    return (
      <div className="h-full absolute flex-grow w-full flex justify-center items-center">
        <LoadingSpinner className="relative -top-10" />
      </div>
    );
  }

  return (
    <>
      {venue && !venue.tourStatus?.profileTour?.completed && (
        <GuidedTours
          viewType={ViewType.Venue}
          buttonRefs={{
            show: createShowBtnRef?.current,
            profile: editProfileBtnRef?.current,
            hatchLinks: editHLBtnRef?.current,
          }}
          isMobile={isMobile}
          profileID={venueID}
          actualStep={venue?.tourStatus?.profileTour?.step}
        />
      )}
      {venue && (
        <div>
          <h1 className="text-2xl font-black text-center">{venue.name}</h1>
          <h2 className="text-sm font-black text-center">
            {venue?.location?.address}, <br />
            {venue?.location?.city} {venue?.location?.state}
          </h2>
          <div className="flex flex-col lg:flex-row justify-center mt-2 gap-2 items-center">
            <Button
              className="text-xs text-white border bg-amber-400"
              link={`/profile/edit/venue/${venueID}`}
              buttonRef={editProfileBtnRef}
              dataCy={DataCy.button('editVenue')}
            >
              Edit Venue
            </Button>
            <Button
              className="text-xs text-white border bg-amber-400"
              link={`/profile/edit/venue/${venueID}/hatchlinks`}
              buttonRef={editHLBtnRef}
            >
              Edit HatchLinks
            </Button>
            <Button
              className="text-xs text-white border bg-red-400"
              action={openModal({
                status: true,
                component: 'EditVenueToolbox',
                data: { venueID },
              })}
            >
              Venue Toolbox
            </Button>
            <StripeHandler
              useLabelButton
              className="flex justify-center transition-all text-center items-center disabled:bg-gray-400 bg-orange gap-1 p-4
                                  rounded-md min-w-max w-28 h-11
                                  text-white border-gray-300
                                  filter hover:brightness-105
                                  cursor-pointer
                                  text-xs border font-bold"
              viewType={ViewType.Venue}
              targetID={venueID}
              stripeID={venue?.stripe?.id}
            />
            <Button
              inline
              className="text-xs text-white border bg-blue-400"
              link={'/venues/' + venueID}
            >
              Venue Page
            </Button>
          </div>
          <div className="flex justify-center m-2">
            <LabelButton
              className="text-sm text-gray-500 border border-gray-500"
              iconClassName="text-base"
              icon="add"
              onClick={() => {
                navigate(`/create-show/venue/${venueID}`);
              }}
              linkRef={createShowBtnRef}
            >
              New Show
            </LabelButton>
          </div>
        </div>
      )}
      <ShowCalendar
        venueID={venueID}
        viewType={ViewType.Venue}
        filterFn={FilterByShowTime}
        shows={shows.data}
      />
      <UpcomingShowShares venueID={venueID} />
      <ManageShowsList
        venueID={venueID}
        shows={shows.data}
      />
    </>
  );
};
