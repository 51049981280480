import { APP_URL, SHORT_LINK_URL } from './configConstants';
import { ViewType } from './shared/Models/ViewType';

export namespace LinkFactory {
  const urlBase = APP_URL;

  function buildAppLink(path: string) {
    const origin = window.location.origin;
    if (urlBase === origin) {
      return path;
    }
    return `${urlBase}${path}`;
  }

  function buildShortLink(path: string) {
    return `${SHORT_LINK_URL}${path}`;
  }

  export const homepage = () => buildAppLink('');
  export const show = (showId: string) => buildAppLink(`/shows/${showId}`);
  export const hatchlink = (params: { viewType: ViewType; id: string }) =>
    buildAppLink(`/hl/${params.viewType}/${params.id}`);
  export const shortLinkHatchlink = (username: string) => buildShortLink(`/${username}`);
}
