import { Box, styled, useMediaQuery, useTheme } from '@mui/material';
import React, { FC } from 'react';

import { useNavigate } from 'react-router-dom';
import { useOpenBookDemoLink } from '../VenuesLP/bookDemo';
import { typography } from '../../ui/shared/TypographySharedElements';
import { Button } from '../../ui/buttons/Button';
import { colors } from '../../ui/shared/Colors';

type CtaBoxProps = {
  isVenue: boolean;
};

export const CtaBox: FC<CtaBoxProps> = ({ isVenue }) => {
  const navigate = useNavigate();
  const openBookDemoLink = useOpenBookDemoLink();
  const theme = useTheme();
  const isSmallDesktop = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <CTABoxContainer isVenue={isVenue}>
      <CTAInnerBoxText>Ready to join?</CTAInnerBoxText>
      <CTAButtonBox>
        <Button
          size={isSmallDesktop ? 'small' : 'large'}
          disabled={false}
          onClick={() => navigate(`/register${isVenue ? '/venue' : ''}`)}
        >
          Create free account
        </Button>
        {isVenue && (
          <ReverseButton
            isPrimary
            size={isSmallDesktop ? 'small' : 'large'}
            disabled={false}
            onClick={openBookDemoLink}
          >
            {' '}
            Book a demo
          </ReverseButton>
        )}
      </CTAButtonBox>{' '}
    </CTABoxContainer>
  );
};

const CTABoxContainer = styled(Box, {
  name: 'CTABoxContainer',
  shouldForwardProp: (prop) => prop !== 'isVenue',
})<{ isVenue: boolean }>(({ isVenue, theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  backgroundColor: colors.SystemBlack,
  maxWidth: '800px',
  borderRadius: '24px',
  backgroundRepeat: 'no-repeat',
  backgroundAttachment: 'fixed',
  backgroundPosition: 'center',
  backgroundSize: 'auto',
  padding: isVenue ? '32px' : '64px',
  [theme.breakpoints.down('lg')]: {
    padding: isVenue ? '24px' : '48px',
  },
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const CTAInnerBoxText = styled(Box, {
  name: 'CTAInnerBoxText',
})(({ theme }) => ({
  color: colors.SystemWhite,
  ...typography.headlineLarge,
  [theme.breakpoints.down('lg')]: {
    ...typography.headlineMedium,
  },
}));

const CTAButtonBox = styled(Box, {
  name: 'CTAButtonBox',
})({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
});

const ReverseButton = styled(Button, {
  name: 'ReverseButton',
})({
  '&.MuiButtonBase-root.MuiButton-root': {
    backgroundColor: colors.SystemGray700,
    color: colors.SystemWhite,
    border: `2px solid ${colors.SystemBlack}`,
    '&:hover': {
      backgroundColor: colors.SystemGray600,
    },
  },
});
