export const PERFORMER_FAQ_DATA = [
  {
    name: 'faq1',
    title: `I signed up, how do I get started?`,
    details: `If you are in an established market, find your favorite venues and start booking shows.
If you are in a newer market, reach out to your favorite venues and tell them about TuneHatch. If you or they want a live demo, email info@tunehatch.com and we will set one up.`,
  },
  {
    name: 'faq2',
    title: `Where do audience members purchase tickets to my shows?`,
    details: `Once a stage is booked, for your specific show (time/date/venue), TuneHatch will generate a unique QR Code that will automatically populate to all of your social media and the venues website calendar.
This unique show-code can be cut-and-pasted to almost any medium – digital, print, posters, flyers, email, social media, facial tattoos, tee shirts, etc.`,
  },
  {
    name: 'faq3',
    title: `Will I know how many tickets have been sold?`,
    details: `Yes, all in real-time. You and the venue will see all the information as-it's-happening on your TuneHatch dashboard.
This will also help you prove to future venues that your music is worth what you are charging – simple, useable, relevant analytics.`,
  },
  {
    name: 'faq4',
    title: `How is the money handled?`,
    details: `TuneHatch has partnered with one of the most secure and reliable payment-processing companies on the planet. They will collect the money, and minutes after the show, your cut will push the agreed payouts directly to your preselected account. No more walking out the back with a beer-soaked ball of cash or having shady venues shrink your portion of the door.`,
  },
  {
    name: 'faq5',
    title: `What does TuneHatch cost?`,
    details: `TuneHatch is free to the artist and venue. A small service charge is added to each ticket at the time of purchase – usually $2.00 to $4.00 – which is far below industry standard.`,
  },
  // {
  //   name: 'faq6',
  //   title: `How does TuneHatch help with marketing and promotion of my upcoming shows?`,
  //   details: `[NATuneHatchAN]`,
  // },
  // {
  //   name: 'faq7',
  //   title: `As this is a digital platform, what other useful analytics will be on my dashboard?`,
  //   details: `[NATuneHatchAN]`,
  // },
  {
    name: 'faq8',
    title: `I have a couple bands that I help manage -or- I'm a show runner. Is TuneHatch right for me?`,
    details: `Absolutely! TuneHatch allows you to manage multiple artists seamlessly, all within one account. You can easily track each show’s progress, communicate with venues, and handle payments for all your artists. Plus, you can promote your shows through automated social media postings and have real-time data at your fingertips to make informed decisions.`,
  },
  {
    name: 'faq9',
    title: `I'm having issues with a venue I performed at. Who should I contact?`,
    details: `Please contact the manager or owner of the venue directly. We try not to get involved in discussions that don't involve the TuneHatch platform directly.`,
  },
  {
    name: 'faq10',
    title: `I'm having issues with TuneHatch and I'm stuck. Is there an ACTUAL PERSON I can speak with?`,
    details: `Yes! TuneHatch offers real-time support through our customer service team. You can contact us by email at info@tunehatch.com, or call us at +1-954-515-7809 for direct assistance. We're here to help you with anything you need!`,
  },
];
