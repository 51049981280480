import { isEmpty } from 'lodash';
import { useState } from 'react';

export interface ClaimDataObj {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  token: string;
  artist?: {
    stagename: string;
  };
  venues: VenueClaimData[];
  showrunnerGroups: ShowrunnerGroupData[];
}

interface VenueClaimData {
  id: string;
  name: string;
  description: string;
  contact: {
    phone: string;
    email: string;
  };
  location: {
    address: string,
    city: string | null,
    state: string | null,
    zip: string | null,
    latitude: number | null,
    longitude: number | null,
  }
}

interface ShowrunnerGroupData {
  id: string;
  name: string;
  genre: string;
  subgenre: string;
  contact: {
    phone: string;
    email: string;
  };
  role: string;
}

export const setClaimData = (obj: ClaimDataObj) => {
  sessionStorage.setItem('claimData', JSON.stringify(obj));
};

export const getClaimData = () => {
  const claimDataString = sessionStorage.getItem('claimData');
  const claimData: ClaimDataObj = JSON.parse(claimDataString);

  return claimData;
};
