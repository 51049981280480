import { Avatar, Box, Typography, styled } from '@mui/material';
import React, { FC } from 'react';
import { colors } from '../../../ui/shared/Colors';
import { typography } from '../../../ui/shared/TypographySharedElements';

interface SingleStepToTuneHatchParams {
  number: number;
  stepTitle: string;
  stepText: string;
}

export const SingleStepToTuneHatch: FC<SingleStepToTuneHatchParams> = ({ number, stepTitle, stepText }) => {
  return (
    <SingleStepContainer>
      <SingleStepCountAvatar>{number}</SingleStepCountAvatar>
      <SingleStepTitle>{stepTitle}</SingleStepTitle>
      <SingleStepText>{stepText}</SingleStepText>
    </SingleStepContainer>
  );
};

const SingleStepContainer = styled(Box, {
  name: 'SingleStepContainer',
})({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: '395px',
  alignItems: 'center',
});

const SingleStepCountAvatar = styled(Avatar, {
  name: 'SingleStepCountAvatar',
})({
  width: '100px',
  height: '100px',
  backgroundColor: '#FFB54B',
  color: colors.SystemBlack,
  ...typography.displayMedium,
});

const SingleStepTitle = styled(Typography, {
  name: 'SingleStepTitle',
})({
  color: colors.SystemGray900,
  ...typography.headlineMedium,
  paddingTop: '32px',
});

const SingleStepText = styled(Typography, {
  name: 'SingleStepText',
})({
  color: colors.SystemGray900,
  ...typography.bodyLargeRegular,
  paddingTop: '12px',
  textAlign: 'center',
});
