import React, { useState } from 'react';
import { Box, styled, Typography } from '@mui/material';
import { useGetAllShowrunnerGroupsQuery, useGetAllVenuesQuery } from '../../Redux/API/PublicAPI';
import { Show } from '../../Helpers/shared/Models/Show';
import { colors } from '../../ui/shared/Colors';
import Img from '../../Components/Images/Img';
import { typography } from '../../ui/shared/TypographySharedElements';
import ExploreShowCardLink from './ExploreShowCardLink';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import ExtraDetails from './ExtraDetails';
import ExploreShowCardButtons from './ExploreShowCardButtons';
dayjs.extend(advancedFormat);

export default function ExploreShowCard({ show, isGig }: { show: Show; isGig?: boolean }) {
  const [showDetails, setShowDetails] = useState(false);
  const venues = useGetAllVenuesQuery();
  const showrunners = useGetAllShowrunnerGroupsQuery();
  const showrunner = show?.showrunner?.[0] ? showrunners.data?.[show?.showrunner[0].id] : null;

  return (
    <Card onClick={() => setShowDetails(!showDetails)}>
      <PrimaryContent>
        <EmblemSection>
          {showrunner?.avatarWebP ? (
            <ShowImageContainer>
              <SmallVenueImage src={venues?.data[show.venueID]?.avatarWebP} />
              <SmallShowrunnerImage src={showrunner?.avatarWebP} />
            </ShowImageContainer>
          ) : (
            <ShowImageContainer>
              <ShowImage src={venues?.data[show.venueID]?.avatar} />
            </ShowImageContainer>
          )}
        </EmblemSection>
        <ShowDetails>
          <Title>{show.name}</Title>
          <ExploreShowCardLink
            show={show}
            showID={show._key}
          />
          <MinorDetails>
            <DetailText>{dayjs(show.starttime).format('dddd MMMM Do | hA z')} </DetailText>
            <DetailText>Age: +{show.min_age}</DetailText>
          </MinorDetails>
        </ShowDetails>
        <ButtonsContainer>
          <ExploreShowCardButtons
            show={show}
            isGig={isGig}
          />
        </ButtonsContainer>
      </PrimaryContent>
      {showDetails && <ExtraDetails show={show} />}
    </Card>
  );
}

const Card = styled(Box, {
  name: 'Card',
})({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '24px',
  backgroundColor: colors.SystemWhite,
  '&:hover': {
    boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
    transform: 'translateX(1px)',
  },
});

const PrimaryContent = styled(Box, {
  name: 'PrimaryContent',
})(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

// Handling padding gets awkward if I put it in ShowImageContainer
const EmblemSection = styled(Box, {
  name: 'EmblemSection',
})({
  display: 'flex',
  padding: '16px 24px',
});

const ShowImageContainer = styled(Box, {
  name: 'ShowImageContainer',
})(({ theme }) => ({
  alignSelf: 'center',
  position: 'relative',
  height: '120px',
  width: '120px',
  [theme.breakpoints.down('md')]: {
    height: '80px',
    width: '80px',
  },
}));

const ShowImage = styled(Img, {
  name: 'ShowImage',
})({
  height: '100%',
  borderRadius: '50%',
});

const SmallVenueImage = styled(Img, {
  name: 'SmallVenueImage',
})(({ theme }) => ({
  position: 'absolute',
  width: '65%',
  height: '65%',
  borderRadius: '50%',
}));

const SmallShowrunnerImage = styled(SmallVenueImage, {
  name: 'SmallShowrunnerImage',
})({
  bottom: '0px',
  right: '0px',
});

const ShowDetails = styled(Box, {
  name: 'ShowDetails',
})(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  padding: '20px 0px',
  gap: '12px',
  [theme.breakpoints.down('md')]: {
    padding: '12px 16px',
    gap: '8px',
  },
}));

const MinorDetails = styled(Box, {
  name: 'MinorDetails',
})({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
});

const ButtonsContainer = styled(Box, {
  name: 'ButtonsContainer',
})(({ theme }) => ({
  height: 'fit-content',
  width: '147px',
  padding: '16px 24px',
  border: '0px 0.5px 0px 0px',
  alignSelf: 'center',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    padding: '16px',
  },
}));

const Title = styled(Typography, {
  name: 'Title',
})(({ theme }) => ({
  ...typography.headlineSmall,
  fontFamily: 'Satoshi-Variable',
  color: colors.SystemGray900,
  [theme.breakpoints.down('md')]: {
    textAlign: 'center',
  },
}));

const DetailText = styled(Typography, {
  name: 'DetailText',
})(({ theme }) => ({
  ...typography.subtitle,
  fontFamily: 'Satoshi-Variable',
  color: colors.SystemGray900,
  [theme.breakpoints.down('md')]: {
    textAlign: 'center',
  },
}));
