import { ViewType } from '../../Helpers/shared/Models/ViewType';
import { useUpdateArtistGuidedToursMutation } from '../../Redux/API/ArtistAPI';
import { useUpdateSRGGuidedToursMutation } from '../../Redux/API/ShowrunnerAPI';
import { useUpdateVenueGuidedToursMutation } from '../../Redux/API/VenueAPI';

export const useUpdateGuidedTours = (viewType: ViewType) => {
  const updateArtistGuidedTours = useUpdateArtistGuidedToursMutation();
  const updateSRGGuidedTours = useUpdateSRGGuidedToursMutation();
  const updateVenueGuidedTours = useUpdateVenueGuidedToursMutation();
  if (viewType === ViewType.Artist) {
    return updateArtistGuidedTours;
  }
  if (viewType === ViewType.SRGroup) {
    return updateSRGGuidedTours;
  }
  if (viewType === ViewType.Venue) {
    return updateVenueGuidedTours;
  }
};
