import { Box, Typography, styled } from '@mui/material';
import React, { FC, useCallback } from 'react';
import { colors } from '../../ui/shared/Colors';
import { typography } from '../../ui/shared/TypographySharedElements';
import { Button } from '../../ui/buttons/Button';
import { IconButton } from '../../ui/buttons/IconButton';

interface CancelModalDialogProps {
  closeModal: () => void;
}

export const CancelModalDialog: FC<CancelModalDialogProps> = ({ closeModal }) => {
  const handleGoBack = useCallback(() => {
    window.history.back();
  }, []);

  return (
    <CancelModalDialogContainer>
      <CancelModalDialogInnerContainer>
        <CancelModalDialogHeading>
          <CancelModalDialogTitle>Cancel show creation?</CancelModalDialogTitle>
          <IconButton
            size="small"
            onClick={closeModal}
            iconName="close"
          />
        </CancelModalDialogHeading>
        <CancelModalDialogText>
          Are you sure you want to cancel? All provided data will not be saved.
        </CancelModalDialogText>
      </CancelModalDialogInnerContainer>
      <CancelModalDialogBottomContainer>
        <Button
          size="large"
          disabled={false}
          onClick={closeModal}
          fullWidth
        >
          Keep editing show
        </Button>
        <Button
          isPrimary
          size="large"
          disabled={false}
          onClick={handleGoBack}
          fullWidth
        >
          Cancel
        </Button>
      </CancelModalDialogBottomContainer>
    </CancelModalDialogContainer>
  );
};

export const CancelModalDialogContainer = styled(Box, {
  name: 'CancelModalDialogContainer',
})({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: colors.SystemWhite,
  borderRadius: '16px',
  maxWidth: '520px',
  width: '100%',
  height: '100%',
});

export const CancelModalDialogInnerContainer = styled(Box, {
  name: 'CancelModalDialogInnerContainer',
})({
  display: 'flex',
  flexDirection: 'column',
});

export const CancelModalDialogHeading = styled(Box, {
  name: 'CancelModalDialogHeading',
})({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '24px',
});

export const CancelModalDialogTitle = styled(Typography, {
  name: 'CancelModalDialogTitle',
})({
  color: colors.SystemGray900,
  ...typography.headlineSmall,
});

export const CancelModalDialogText = styled(Typography, {
  name: 'CancelModalDialogText',
})({
  color: colors.SystemGray900,
  ...typography.bodyLargeRegular,
  padding: '24px',
});

export const CancelModalDialogBottomContainer = styled(Box, {
  name: 'CancelModalDialogBottomContainer',
})({
  display: 'flex',
  justifyContent: 'center',
  padding: '24px',
  alignItems: 'center',
  width: '100%',
  gap: '24px',
  borderRadius: '12px',
});
