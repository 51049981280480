import React from 'react';
import { Link } from 'react-router-dom';
import { DataCy } from '../../Helpers/DataCy';
import { useAppDispatch } from '../../hooks';
import { openCover } from '../../Redux/UI/UISlice';

interface Props {
  links: { [key: string]: { label?: string | React.ReactNode } };
}

export default function MobileNav({ links }: Props) {
  const dispatch = useAppDispatch();
  return (
    <div className="min-h-full min-w-full flex flex-col flex-grow justify-center items-center gap-2">
      {Object.keys(links).map((link, i) => {
        const label = links[link].label;
        return (
          <Link
            onClick={() => dispatch(openCover({ status: false }))}
            className="flex"
            key={`mnavlinks/${i}`}
            to={link}
            data-cy={DataCy.link(String(label))}
          >
            <h1 className="text-4xl font-black">{label}</h1>
          </Link>
        );
      })}
    </div>
  );
}
