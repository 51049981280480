import React, { useEffect, useMemo, useState } from 'react';
import { Box, MenuItem, styled, Typography, useTheme, useMediaQuery } from '@mui/material';
import { useGetAllShowsQuery, useGetAllVenuesQuery } from '../../Redux/API/PublicAPI';
import { useGetShowListQuery } from '../../Redux/API/ShowAPI';
import LoadingWrapper from '../../Components/Layout/LoadingWrapper';
// import BannerImage from '../../Components/Images/BannerImage';
import CoverImage from '../../Images/Banners/exploreGigs.webp';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { setDarkMode } from '../../Redux/UI/UISlice';
import TopVenueSelector from './TopVenueSelector';
import ShowsFilters from './ShowsFilters';
import ExploreUpcomingShows from './ExploreUpcomingShows';
import { colors } from '../../ui/shared/Colors';
import { useAppSelector } from '../../hooks';
import { displayTicketPrice, sortShowsByDate } from '../../Helpers/HelperFunctions';
import { isArray, isEmpty } from 'lodash';
import { getVenueLocationCode } from '../../Helpers/shared/getVenueLocationCode';
import { typography } from '../../ui/shared/TypographySharedElements';
import { useGetActiveRegionsQuery } from '../../Redux/API/RegionAPI';
import { Select, AutocompleteElement } from '../../ui/inputs/TextField';
import Img from '../../Components/Images/Img';
import dayjs from 'dayjs';

export type ShowFilterCriteria = {
  venueID?: string;
  paidOnly?: boolean;
  locations?: string;
  month?: string;
  weekday?: string;
};

export default function Gigs() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const shows = useGetAllShowsQuery();
  const venues = useGetAllVenuesQuery();
  const [filteringActive, setFilteringActive] = useState(false);
  const [rawDateInput, setRawDateInput] = useState<string>();
  const [sortedShows, setSortedShows] = useState([]);
  const [filterResults, setFilterResults] = useState([]);
  const currentRegion = useAppSelector((state) => state.user.location.currentRegion);
  const currentRegionLocations = useAppSelector((state) => state.user.location.currentRegionLocations);
  const currentRegionFeatured = useAppSelector((state) => state.user.location.currentRegionFeatured);
  const activeRegions = useGetActiveRegionsQuery();
  const dispatch = useDispatch();
  const [search, setSearch] = useSearchParams();
  // const showsList = useGetShowListQuery({
  //   name: search.get('showName'),
  // });

  useEffect(() => {
    dispatch(setDarkMode({ status: true }));
    return () => {
      dispatch(setDarkMode({ status: false }));
    };
  }, [dispatch]);

  useEffect(() => {
    if (shows.data) {
      setSortedShows(sortShowsByDate(shows.data as any));
    }
  }, [shows.data]);

  useEffect(() => {
    let now = Date.now();
    let active = false;

    const filterFn = (show: any) => {
      let match = false;
      if (
        typeof show === 'object' &&
        !show.deleted &&
        !show.private &&
        !show.lineup_locked &&
        new Date(show.endtime).getTime() > now
      ) {
        match = true;
      }
      if (search.get('venueID') && match) {
        match = search.get('venueID') === venues?.data?.[show.venueID]?.name;
      }
      if (search.get('showName') && match) {
        let name = search.get('showName')?.toLowerCase();
        let showName = show.name.toLowerCase();
        match = showName.includes(name);
      }
      if (search.get('paidOnly') && match) {
        console.log('show!!', show);
        match = displayTicketPrice(show) !== 'Free';
      }
      if (search.get('locations') && match) {
        const venueLocationCode = getVenueLocationCode(venues?.data?.[show.venueID] || null);
        //venues in locations that arent assigned a region yet will return null so make sure it's not null
        if (venueLocationCode && search.get('locations')?.includes(venueLocationCode)) {
          match = true;
        } else {
          match = false;
        }
      }
      if (search.get('month') && match) {
        let showMonth = dayjs(show.starttime).format('MMM');
        match = showMonth === search.get('month');
      }
      if (search.get('weekday') && match) {
        let showWeekday = dayjs(show.starttime).format('dddd');
        if (showWeekday === search.get('weekday')) {
          match = true;
        } else {
          match = false;
        }
      }
      return match;
    };

    search.forEach((criteria) => {
      if (isEmpty(criteria)) {
        active = true;
      }
    });
    let tFilterResults = sortedShows.filter((show) => filterFn(show));
    setFilterResults(tFilterResults);
    setFilteringActive(active);
  }, [search, sortedShows]);

  useEffect(() => {
    setSearch((params) => {
      if (!isEmpty(currentRegionLocations)) {
        params.set('locations', currentRegionLocations.join(','));
      } else {
        params.delete('locations');
      }
      return params;
    });
  }, [currentRegionLocations]);

  const topVenues = useMemo(() => {
    return venues?.data ? Object.keys(venues.data).map((venue) => venues.data[venue]) : [];
  }, [venues?.data]);

  return (
    <LoadingWrapper queryResponse={[shows, venues]}>
      <HeaderSection>
        <HeaderContainer>
          <ImageContainer>
            <BannerImage src={CoverImage} />
          </ImageContainer>
          <HeaderTextSection>
            <HeaderTitle>Get Booked</HeaderTitle>
            <HeaderAutocomplete
              disablePortal
              options={activeRegions?.data?.filter((region) => region.active).map((region) => region.label) || []}
              defaultValue={search.get('locations')}
              renderInput={(params) => (
                <Select
                  placeholder="Search by city"
                  {...params}
                />
              )}
              onChange={(event, value) =>
                setSearch((params) => {
                  if (value) {
                    params.set('locations', String(value));
                  } else {
                    params.delete('locations');
                  }
                  return params;
                })
              }
            />
          </HeaderTextSection>
        </HeaderContainer>
      </HeaderSection>
      <Background>
        <MainContent>
          <FiltersAndShowsContainer>
            <FiltersBox>
              <ShowsFilters
                venues={topVenues}
                canHide={isMobile}
                gigs
                // shows={shows.data}
              />
            </FiltersBox>
            <ShowsBox>
              <ExploreUpcomingShows
                shows={Object.values(filterResults)}
                gigs
              />
            </ShowsBox>
          </FiltersAndShowsContainer>
        </MainContent>
      </Background>
    </LoadingWrapper>
  );
}

const HeaderSection = styled(Box, {
  name: 'HeaderSection',
})({
  backgroundColor: colors.SystemBlack,
  display: 'flex',
  justifyContent: 'center',
});

const HeaderContainer = styled(Box, {
  name: 'HeaderContainer',
})(({ theme }) => ({
  position: 'relative',
  width: '100%',
  maxWidth: '1280px',
  height: '404px',
  maxHeight: '45vh',
  display: 'flex',
  flexDirection: 'row-reverse',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    height: '464px',
  },
}));

const HeaderTextSection = styled(Box, {
  name: 'HeaderTextSection',
})(({ theme }) => ({
  display: 'flex',
  flexGrow: 1,
  flexDirection: 'column',
  padding: '20px',
  gap: '80px',
  [theme.breakpoints.down('lg')]: {
    justifyContent: 'flex-end',
    padding: '32px',
    gap: '32px',
  },
}));

const ImageContainer = styled(Box, {
  name: 'ImageContainer',
})(({ theme }) => ({
  position: 'relative',
  width: '694px',
  height: '100%',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const BannerImage = styled(Img, {
  name: 'BannerImage',
})(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  objectPosition: '0% 50%',
  maskImage: 'linear-gradient(to right, transparent, black 25%, black 75%, transparent)',
  webkitMaskImage: 'linear-gradient(to right, transparent, black 25%, black 75%, transparent)',
  [theme.breakpoints.down('md')]: {
    maskImage: 'linear-gradient(black 75%, transparent)',
    webkitMaskImage: 'linear-gradient(black 75%, transparent)',
  },
}));

const HeaderTitle = styled(Typography, {
  name: 'HeaderTitle',
})(({ theme }) => ({
  ...typography.displaySmall,
  fontFamily: 'Satoshi-Variable',
  color: colors.SystemWhite,
  [theme.breakpoints.down('md')]: {
    ...typography.headlineMedium,
    textAlign: 'center',
  },
}));

const HeaderAutocomplete = styled(AutocompleteElement, {
  name: 'HeaderAutocomplete',
})({
  width: '350px',
  '&&&&& .MuiInputBase-input': {
    backgroundColor: colors.SystemWhite,
    borderRadius: '12px',
    border: `1px solid ${colors.SystemGray200}`,
  },
});

const MainContent = styled(Box, {
  name: 'MainContent',
})(({ theme }) => ({
  maxWidth: '1280px',
  width: '100vw',
  display: 'flex',
  flexDirection: 'column',
  padding: '20px',
  margin: '60px',
  gap: '60px',
  [theme.breakpoints.down('lg')]: {
    padding: '20px',
    margin: '20px',
    gap: '40px',
  },
}));

const Background = styled(Box, {
  name: 'Background',
})({
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: colors.SystemGray100,
});

const FiltersAndShowsContainer = styled(Box, {
  name: 'FiltersAndShowsContainer',
})(({ theme }) => ({
  display: 'flex',
  gap: '80px',
  [theme.breakpoints.down('lg')]: {
    gap: '40px',
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

const FiltersBox = styled(Box, {
  name: 'FiltersBox',
})(({ theme }) => ({
  width: '320px',
  [theme.breakpoints.down('lg')]: {
    width: '250px',
  },
  [theme.breakpoints.down('md')]: {
    width: 'auto',
  },
}));

const ShowsBox = styled(Box, {
  name: 'ShowsBox',
})({
  flex: '1',
});
