import { z } from 'zod';
import { ShowApi } from '../../../Redux/API/ShowAPI';
import { getShowCreationFormSchema } from './ShowCreationFormSchema';

export const getTicketsFromCurrentStructure = (data: any, isEdit: boolean) => {
  const showCreationFormSchema = getShowCreationFormSchema(isEdit);
  type dataType = z.infer<typeof showCreationFormSchema>;
  const dataTyped = data as dataType;
  if (dataTyped.advancedTicketing) {
    let tickets: ShowApi.TiersTicketType = { type: 'tiers', tiers: {} };
    dataTyped.tickets.map((ticket, index) => {
      tickets.tiers[index] = {
        name: ticket.tierName,
        description: ticket.tierDescription,
        price: Number(ticket.ticketPrice),
        quantity: Number(ticket.quantity),
      };
    });
    return tickets;
  } else {
    const tickets: ShowApi.FlatTicketType = {
      type: 'flat',
      cost: Number(data.ticketPrice),
      doorPrice: Number(data.doorPrice),
      quantity: Number(data.numberOfAvailableTickets),
    };
    return tickets;
  }
};

export const getDefaultShowDate = (isStart: boolean) => {
  const date = new Date();
  date.setDate(new Date().getDate() + 7);
  date.setHours(isStart ? 18 : 20, 0, 0, 0);
  return date;
};
