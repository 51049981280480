import React, { FC } from 'react';
import { BadgeCategoryEnum, IBadge } from '../../../Helpers/shared/badgesConfig';
import { BadgeGroup } from './BadgeGroup';
import { isEmpty } from 'lodash';
import { LeftSideComponentContainer } from '../Shared/SharedContainers';
import { LeftHandSideComponentTitle } from '../Shared/SharedElements';
import { Button } from '../../../ui/buttons/Button';
import { ViewType } from '../../../Helpers/shared/Models/ViewType';
import { useNavigate } from 'react-router-dom';

interface BadgesProps {
  badges: IBadge[];
  isOwnProfile: boolean;
  profileType: ViewType;
  profileID: string;
}

export const Badges: FC<BadgesProps> = ({ badges, isOwnProfile, profileType, profileID }) => {
  const navigate = useNavigate();

  if (isEmpty(badges) && !isOwnProfile) {
    return null;
  } else if (isEmpty(badges) && isOwnProfile) {
    return (
      <LeftSideComponentContainer>
        <LeftHandSideComponentTitle>Badges</LeftHandSideComponentTitle>
        <Button
          isPrimary
          size="large"
          disabled={false}
          onClick={() => navigate(`/profile/edit/${profileType}/${profileID}`)}
        >
          Complete your profile and perform at shows to populate your badges!
        </Button>
      </LeftSideComponentContainer>
    );
  }

  const aboutBadges = badges.filter((singleBadge) => singleBadge.category === BadgeCategoryEnum.ABOUT);
  const venueBadges = badges.filter((singleBadge) => singleBadge.category === BadgeCategoryEnum.VENUES);
  const cityBadges = badges.filter((singleBadge) => singleBadge.category === BadgeCategoryEnum.CITIES);

  return (
    <LeftSideComponentContainer>
      <LeftHandSideComponentTitle>Badges</LeftHandSideComponentTitle>
      {!isEmpty(aboutBadges) && (
        <BadgeGroup
          title={BadgeCategoryEnum.ABOUT}
          badges={aboutBadges}
        />
      )}
      {!isEmpty(venueBadges) && (
        <BadgeGroup
          title={BadgeCategoryEnum.VENUES}
          badges={venueBadges}
        />
      )}
      {!isEmpty(cityBadges) && (
        <BadgeGroup
          title={BadgeCategoryEnum.CITIES}
          badges={cityBadges}
        />
      )}
    </LeftSideComponentContainer>
  );
};
