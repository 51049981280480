import React, { FC, Dispatch, SetStateAction } from 'react';
import { DropZone } from '../../../ui/inputs/DropZone';
import { FormSectionContainer } from '../Shared/Containers';
import { FormSectionTitle } from '../Shared/RegistrationTypographies';
import { useFormContext } from 'react-hook-form';

interface VenuePhotosProps {
  setVenueImages: Dispatch<SetStateAction<File[] | null>>;
  venueImages: File[];
}

export const VenuePhotosForm: FC<VenuePhotosProps> = ({ setVenueImages, venueImages }) => {
  return (
    <>
      <FormSectionContainer data-cy={'venueImages'}>
        <FormSectionTitle>Photos of your venue(max 6)</FormSectionTitle>
        <DropZone
          setSelectedFiles={setVenueImages}
          maxFiles={6}
          selectedFiles={venueImages}
        />
      </FormSectionContainer>
    </>
  );
};
