import { Box, styled } from '@mui/material';
import React, { FC } from 'react';
import { Button } from '../../../ui/buttons/Button';
import { useNavigate } from 'react-router-dom';
import { colors } from '../../../ui/shared/Colors';
import { typography } from '../../../ui/shared/TypographySharedElements';
import { SingleStepToTuneHatch } from './SingleStepToTuneHatch';
import ArrowSpecial from '../../../Images/VenueLP/arrowSpecial.svg';

export const StepsToTuneHatch: FC = () => {
  const navigate = useNavigate();

  return (
    <StepsToTuneHatchMainContainer>
      <StepsToTuneHatchTitle>Get started with TuneHatch in three easy steps</StepsToTuneHatchTitle>
      <StepsToTuneHatchContainer>
        <SingleStepToTuneHatch
          number={1}
          stepTitle="Create an account"
          stepText="Make your venue profile in as little as 2 minutes!"
        ></SingleStepToTuneHatch>
        <Arrow1 src={ArrowSpecial} />
        <SingleStepToTuneHatch
          number={2}
          stepTitle="Post your first show"
          stepText="Post a show and start selling tickets (or promote and book) in seconds."
        ></SingleStepToTuneHatch>
        <Arrow2 src={ArrowSpecial} />
        <SingleStepToTuneHatch
          number={3}
          stepTitle="Get paid"
          stepText="As soon as your show is over, you can pay yourself and your artists, in one click!"
        ></SingleStepToTuneHatch>
      </StepsToTuneHatchContainer>
      <Box
        display="flex"
        justifyContent="center"
      >
        <Button
          isPrimary
          size="large"
          disabled={false}
          onClick={() => {
            navigate('/register/venue');
          }}
        >
          Create free account
        </Button>
      </Box>
    </StepsToTuneHatchMainContainer>
  );
};
const StepsToTuneHatchMainContainer = styled(Box, {
  name: 'StepsToTuneHatchMainContainer',
})(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  gap: '60px',
  padding: '120px 0',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    padding: '40px 20px',
  },
}));

const StepsToTuneHatchTitle = styled(Box, {
  name: 'StepsToTuneHatchTitle',
})({
  color: colors.SystemBlack,
  ...typography.headlineLarge,
});

const StepsToTuneHatchContainer = styled(Box, {
  name: 'StepsToTuneHatchContainer',
})(({ theme }) => ({
  display: 'flex',
  gap: '48px',
  width: '100%',
  maxWidth: '1280px',
  margin: '0 auto',
  justifyContent: 'center',
  alignItems: 'flex-start',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const Arrow1 = styled('img', {
  name: 'Arrow1',
})(({ theme }) => ({
  position: 'absolute',
  left: '35%',
  top: '40%',
  transform: 'translate(-50%, -50%)',
  width: '215px',
  height: '215px',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const Arrow2 = styled('img', {
  name: 'Arrow2',
})(({ theme }) => ({
  position: 'absolute',
  left: '65%',
  top: '40%',
  transform: 'translate(-50%, -50%)',
  width: '215px',
  height: '215px',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));
