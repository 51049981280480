import { number, z } from 'zod';
import { Show } from '../../Helpers/shared/Models/Show';
import { baseAPI } from './BaseAPI';
import { publicAPI } from './PublicAPI';
import { putRequest, getRequest, postRequest, patchRequest } from './Request/ApiRequest';
import { handleApiErrors } from './Request/handleErrors';
import { isEmpty, omit } from 'lodash';

export namespace ArtistApi {
  const artistSchema = z.object({
    id: z.string(),
    images: z.array(z.string()).optional(),
    assets: z.object({
      headshotsImages: z.array(z.string()).optional(),
      stagePlotImages: z.array(z.string()).optional(),
    }),
    firstName: z.string(),
    lastName: z.string(),
    avatar: z.string().nullable(),
    avatarWebP: z.string().nullable(),
    about: z.string(),
    stageName: z.string().nullable(),
    primaryCity: z.string(),
    secondaryCity: z.string().nullable(),
    genre: z.string(),
    subgenre: z.string().nullable(),
    externalLinks: z.array(
      z.object({
        label: z.string(),
        link: z.string(),
      }),
    ),
    isVerified: z.boolean(),
    socials: z.object({
      instagram: z.object({
        link: z.string().nullable(),
      }),
      tikTok: z.object({
        link: z.string().nullable(),
      }),
      youtube: z.object({
        link: z.string().nullable(),
      }),
      spotify: z.object({
        link: z.string().nullable(),
      }),
    }),
  });
  export type Artist = z.TypeOf<typeof artistSchema>;

  const socialStatisticsSchema = z.object({
    spotify: z.object({
      link: z.string().nullable(),
      stats: z
        .object({
          popularity: z.number(),
        })
        .nullable(),
    }),
    tikTok: z
      .object({
        link: z.string().nullable(),
        stats: z
          .object({
            likeQty: z.number(),
            likeQtyDisplay: z.string(),
          })
          .nullable(),
        isAuthorized: z.boolean(),
        authorizeLink: z.string().optional(),
      })
      .optional()
      .nullable(),
    instagram: z
      .object({
        link: z.string().nullable(),
        stats: z
          .object({
            followerQty: z.number(),
          })
          .nullable(),
        isAuthorized: z.boolean(),
        requiredScopes: z.array(z.string()),
      })
      .optional()
      .nullable(),
  });
  export type SocialStatistics = z.TypeOf<typeof socialStatisticsSchema>;

  export namespace UpdateSpotifyCredentials {
    const schema = z.object({
      spotifyAccessCode: z.string(),
    });
    export type Params = z.TypeOf<typeof schema>;
    export const Request = async (params: Params) => {
      const parsed = schema.parse(params);
      return await putRequest({
        endpoint: '/v2/me/spotify/credentials',
        data: parsed,
      });
    };
  }

  export namespace UpdateTikTokCredentials {
    const schema = z.object({
      tikTokAccessCode: z.string(),
    });
    export type Params = z.TypeOf<typeof schema>;
    export const Request = async (params: Params) => {
      const parsed = schema.parse(params);
      return await putRequest({
        endpoint: '/v2/me/tik-tok/credentials',
        data: parsed,
      });
    };
  }

  export namespace GetSocialStatistics {
    const responseSchema = socialStatisticsSchema;
    const paramsSchema = z.object({
      artistId: z.string(),
    });

    export type Params = z.TypeOf<typeof paramsSchema>;
    export type Response = z.TypeOf<typeof responseSchema>;
    export const Request = async (params: Params) => {
      const parsed = paramsSchema.parse(params);
      const response = await getRequest({
        endpoint: `/v2/artists/${parsed.artistId}/social-statistics`,
      });

      return responseSchema.parse(response);
    };
  }

  export namespace GetArtist {
    const responseSchema = artistSchema;
    const paramsSchema = z.object({
      artistId: z.string(),
    });

    export type Params = z.TypeOf<typeof paramsSchema>;
    export type Response = z.TypeOf<typeof responseSchema>;
    export const Request = async (params: Params) => {
      const parsed = paramsSchema.parse(params);
      const response = await getRequest({
        endpoint: `/v2/artists/${parsed.artistId}`,
      });

      return responseSchema.parse(response);
    };
  }

  export namespace GetAllArtistsV2 {
    const responseSchema = artistSchema;
    const paramsSchema = z.object({
      page: z.string().optional(),
      perPage: z.string().optional(),
      phrase: z.string().optional(),
      hatched: z.enum(['true', 'false']).optional(),
      genre: z.string().optional(),
      sortBy: z.enum(['mostVenues', 'mostShows']).optional(),
      sortDirection: z.enum(['ASC', 'DESC']).optional(),
    });

    export type Params = z.TypeOf<typeof paramsSchema>;
    // export type Response = z.TypeOf<typeof responseSchema>;
    export const Request = async (params: Params) => {
      // Filter out undefined values
      const filteredParams = Object.fromEntries(Object.entries(params).filter(([_, value]) => value));
      const parsed = paramsSchema.parse(filteredParams);

      let response;
      if (Object.values(parsed).some((value) => value)) {
        response = await getRequest({ endpoint: `/v2/artists?${new URLSearchParams(parsed).toString()}` });
      } else {
        response = await getRequest({ endpoint: `/v2/artists` });
      }

      return response;
    };
  }

  export namespace UpdateGuidedTours {
    const paramsSchema = z.object({
      tourStatus: z.object({
        profileTour: z.object({
          step: z.number(),
          completed: z.boolean(),
        }),
      }),
    });

    export type Params = z.TypeOf<typeof paramsSchema>;
    export const Request = async (params: Params) => {
      const parsed = paramsSchema.parse(params);
      return await postRequest({
        endpoint: `/v2/artists/update-guided-tours`,
        data: parsed,
      });
    };
  }

  export namespace UpdateArtistColors {
    const paramsSchema = z.object({
      profileColor: z.object({ hex: z.string(), isDark: z.boolean() }).optional(),
      colorsToPickFrom: z.array(z.object({ hex: z.string(), isDark: z.boolean() })).optional(),
    });

    export type Params = z.TypeOf<typeof paramsSchema>;
    export const Request = async (params: Params) => {
      const parsed = paramsSchema.parse(params);
      return await patchRequest({
        endpoint: `/v2/me/artist/colors`,
        data: omit(parsed, 'profileID'),
      });
    };
  }
}

export const artistAPI = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    artistApply: builder.mutation<any, any & Pick<Show, 'showID'>>({
      query: (args: { SECRET_UID: string; id: string; showID: string; venueID: string; phone?: string }) => ({
        method: 'POST',
        url: '/artist/apply',
        body: args,
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        const result = dispatch(
          publicAPI.util.updateQueryData('getAllShows', undefined, (draft: any) => {
            return {
              ...draft,
              [args.showID]: {
                ...draft[args.showID],
                applications: [
                  {
                    uid: args.id,
                    type: 'application',
                  },
                ],
              },
            };
          }),
        );

        try {
          const result = await queryFulfilled;
        } catch (err) {
          result.undo();
        }
      },
      invalidatesTags: (result, error, { showID }) => [{ type: 'Show', id: result.showID }],
    }),
    artistCalculatePayouts: builder.query<any, any & Pick<Show, 'showID'>>({
      query: (args: { SECRET_UID: string; showID: string }) => ({
        method: 'POST',
        url: '/artist/calculate-payouts',
        body: args,
      }),
    }),
    respondToArtistPerformance: builder.mutation<
      Show,
      {
        SECRET_UID: string;
        showID: string;
        artistID: string;
        venueID: string;
        status: 'accepted' | 'rejected';
      } & Pick<Show, '_key'>
    >({
      query: (args) => ({
        method: 'POST',
        url: '/artist/respond-to-performance',
        body: args,
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        const result = dispatch(
          publicAPI.util.updateQueryData('getAllShows', undefined, (draft) => {
            return {
              ...draft,
              [args.showID]: {
                ...draft[args.showID],
                lineup_locked: args.status,
              },
            } as any;
          }),
        );

        try {
          await queryFulfilled; // Wait for the query to complete
        } catch {
          result.undo();
          // Handle the error if the query fails
        }
      },
      invalidatesTags: (result, error, { showID }) => [{ type: 'Show', id: result.showID }],
    }),
    updateSpotifyCredentials: builder.mutation<unknown, ArtistApi.UpdateSpotifyCredentials.Params>({
      async queryFn(data) {
        return await handleApiErrors(async () => await ArtistApi.UpdateSpotifyCredentials.Request(data));
      },
    }),
    updateTikTokCredentials: builder.mutation<unknown, ArtistApi.UpdateTikTokCredentials.Params>({
      async queryFn(data) {
        return await handleApiErrors(async () => await ArtistApi.UpdateTikTokCredentials.Request(data));
      },
    }),
    getArtistSocialStatistics: builder.query<
      ArtistApi.GetSocialStatistics.Response,
      ArtistApi.GetSocialStatistics.Params
    >({
      async queryFn(params) {
        return {
          data: await ArtistApi.GetSocialStatistics.Request(params),
        };
      },
    }),
    getArtist: builder.query<ArtistApi.GetArtist.Response, ArtistApi.GetArtist.Params>({
      async queryFn(params) {
        return {
          data: await ArtistApi.GetArtist.Request(params),
        };
      },
    }),
    getAllArtistsV2: builder.query<any, ArtistApi.GetAllArtistsV2.Params>({
      async queryFn(params) {
        return {
          data: await ArtistApi.GetAllArtistsV2.Request(params),
        };
      },
    }),
    updateArtistGuidedTours: builder.mutation<unknown, ArtistApi.UpdateGuidedTours.Params>({
      async queryFn(data) {
        return await handleApiErrors(async () => await ArtistApi.UpdateGuidedTours.Request(data));
      },
      invalidatesTags: (res, err) => [{ type: 'Artist' }],
    }),
    updateArtistColors: builder.mutation<unknown, ArtistApi.UpdateArtistColors.Params>({
      async queryFn(data) {
        return await handleApiErrors(async () => await ArtistApi.UpdateArtistColors.Request(data));
      },
      invalidatesTags: (res, err) => [{ type: 'Artist' }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useArtistApplyMutation,
  useArtistCalculatePayoutsQuery,
  useRespondToArtistPerformanceMutation,
  useUpdateSpotifyCredentialsMutation,
  useUpdateTikTokCredentialsMutation,
  useGetArtistSocialStatisticsQuery,
  useGetArtistQuery,
  useGetAllArtistsV2Query,
  useUpdateArtistGuidedToursMutation,
  useUpdateArtistColorsMutation,
} = artistAPI;
