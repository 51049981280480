import React, { useEffect } from 'react';
import { renderPageTitle } from '../Helpers/HelperFunctions';

export default function Privacy() {
  useEffect(() => {
    renderPageTitle('Privacy Policy');
  }, []);
  return (
    <>
      <div className="flex-col std-pad">
        <div className="flex-col">
          <strong>
            <span style={{ fontSize: 26 }}>
              <span data-custom-class="title">PRIVACY NOTICE</span>
            </span>
          </strong>
        </div>
        <div className="flex-col">
          <br />
        </div>
        <div className="flex-col">
          <span style={{ color: 'rgb(127, 127, 127)' }}>
            <strong>
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="subtitle">
                  Last updated{' '}
                  <div
                    id="bdt"
                    className="question"
                  >
                    November 23, 2022
                  </div>
                </span>
              </span>
            </strong>
          </span>
        </div>
        <div className="flex-col">
          <br />
        </div>
        <div className="flex-col">
          <br />
        </div>
        <div className="flex-col">
          <br />
        </div>
        <div
          className="flex-col"
          style={{ lineHeight: '1.5' }}
        >
          <span style={{ color: 'rgb(127, 127, 127)' }}>
            <span style={{ color: 'rgb(89, 89, 89)', fontSize: 15 }}>
              <span data-custom-class="body_text">
                This privacy notice for{' '}
                <div
                  id="bdt"
                  className="question"
                >
                  TuneHatch, Inc.
                </div>
                <span style={{ color: 'rgb(89, 89, 89)' }}>
                  <span data-custom-class="body_text">
                    <div
                      id="bdt"
                      className="block-component"
                    />
                  </span>
                </span>{' '}
                (
                <div
                  id="bdt"
                  className="block-component"
                />
                "
                <div
                  id="bdt"
                  className="block-component"
                />
                <strong>Company</strong>
                <div
                  id="bdt"
                  className="statement-end-if-in-editor"
                />
                ," "<strong>we</strong>," "<strong>us</strong>," or "<strong>our</strong>"
                <div
                  id="bdt"
                  className="statement-end-if-in-editor"
                />
              </span>
              <span data-custom-class="body_text">
                ), describes how and why we might collect, store, use, and/or share (
                <div
                  id="bdt"
                  className="block-component"
                />
                "<strong>process</strong>"
                <div
                  id="bdt"
                  className="statement-end-if-in-editor"
                />
                ) your information when you use our services (
                <div
                  id="bdt"
                  className="block-component"
                />
                "<strong>Services</strong>"
                <div
                  id="bdt"
                  className="statement-end-if-in-editor"
                />
                ), such as when you:
              </span>
            </span>
          </span>
          <span style={{ fontSize: 15 }}>
            <span style={{ color: 'rgb(127, 127, 127)' }}>
              <span data-custom-class="body_text">
                <span style={{ color: 'rgb(89, 89, 89)' }}>
                  <span data-custom-class="body_text">
                    <div
                      id="bdt"
                      className="block-component"
                    />
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <ul>
          <li style={{ lineHeight: '1.5' }}>
            <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
              <span
                style={{
                  fontSize: 15,
                  color: 'rgb(89, 89, 89)',
                }}
              >
                <span data-custom-class="body_text">
                  Visit our website
                  <div
                    id="bdt"
                    className="block-component"
                  />{' '}
                  at{' '}
                  <div
                    id="bdt"
                    className="question"
                  >
                    <a
                      href="http://www.tunehatch.com"
                      target="_blank"
                      data-custom-class="link"
                      rel="noreferrer"
                    >
                      http://www.tunehatch.com
                    </a>
                  </div>
                  <span style={{ fontSize: 15 }}>
                    <span style={{ color: 'rgb(89, 89, 89)' }}>
                      <span data-custom-class="body_text">
                        <span style={{ fontSize: 15 }}>
                          <span
                            style={{
                              color: 'rgb(89, 89, 89)',
                            }}
                          >
                            <div
                              id="bdt"
                              className="statement-end-if-in-editor"
                            >
                              , or any website of ours that links to this privacy notice
                            </div>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>
        <div className="flex-col">
          <div
            id="bdt"
            className="block-component"
          >
            <span style={{ fontSize: 15 }}>
              <span style={{ fontSize: 15 }}>
                <span style={{ color: 'rgb(127, 127, 127)' }}>
                  <span data-custom-class="body_text">
                    <span style={{ color: 'rgb(89, 89, 89)' }}>
                      <span data-custom-class="body_text">
                        <div
                          id="bdt"
                          className="block-component"
                        />
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <span style={{ fontSize: 15 }}>
              <span style={{ color: 'rgb(127, 127, 127)' }}>
                <span data-custom-class="body_text">
                  <span style={{ color: 'rgb(89, 89, 89)' }}>
                    <span data-custom-class="body_text">
                      <div
                        id="bdt"
                        className="block-component"
                      />
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <ul>
            <li style={{ lineHeight: '1.5' }}>
              <span
                style={{
                  fontSize: 15,
                  color: 'rgb(89, 89, 89)',
                }}
              >
                <span
                  style={{
                    fontSize: 15,
                    color: 'rgb(89, 89, 89)',
                  }}
                >
                  <span data-custom-class="body_text">
                    Engage with us in other related ways, including any sales, marketing, or events
                    <span style={{ fontSize: 15 }}>
                      <span
                        style={{
                          color: 'rgb(89, 89, 89)',
                        }}
                      >
                        <span data-custom-class="body_text">
                          <span style={{ fontSize: 15 }}>
                            <span
                              style={{
                                color: 'rgb(89, 89, 89)',
                              }}
                            >
                              <div
                                id="bdt"
                                className="statement-end-if-in-editor"
                              />
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <span style={{ fontSize: 15 }}>
              <span style={{ color: 'rgb(127, 127, 127)' }}>
                <span data-custom-class="body_text">
                  <strong>Questions or concerns?&nbsp;</strong>
                  Reading this privacy notice will help you understand your privacy rights and choices. If you do not
                  agree with our policies and practices, please do not use our Services. If you still have any questions
                  or concerns, please contact us at{' '}
                  <div
                    id="bdt"
                    className="question"
                  >
                    info@tunehatch.com
                  </div>
                  .
                </span>
              </span>
            </span>
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <br />
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <br />
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <strong>
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="heading_1">SUMMARY OF KEY POINTS</span>
              </span>
            </strong>
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <br />
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <strong>
                  <em>
                    This summary provides key points from our privacy notice, but you can find out more details about
                    any of these topics by clicking the link following each key point or by using our table of contents
                    below to find the section you are looking for. You can also click&nbsp;
                  </em>
                </strong>
              </span>
            </span>
            <a
              data-custom-class="link"
              href="#toc"
            >
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <strong>
                    <em>here</em>
                  </strong>
                </span>
              </span>
            </a>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <strong>
                  <em>&nbsp;to go directly to our table of contents.</em>
                </strong>
              </span>
            </span>
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <br />
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <strong>What personal information do we process?</strong> When you visit, use, or navigate our Services,
                we may process personal information depending on how you interact with{' '}
                <div
                  id="bdt"
                  className="block-component"
                />
                <div
                  id="bdt"
                  className="question"
                >
                  TuneHatch, Inc.
                </div>
                <div
                  id="bdt"
                  className="statement-end-if-in-editor"
                />{' '}
                and the Services, the choices you make, and the products and features you use. Click&nbsp;
              </span>
            </span>
            <a
              data-custom-class="link"
              href="#personalinfo"
            >
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">here</span>
              </span>
            </a>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">&nbsp;to learn more.</span>
            </span>
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <br />
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <strong>Do we process any sensitive personal information?</strong>{' '}
                <div
                  id="bdt"
                  className="block-component"
                />
                We may process sensitive personal information when necessary with your consent or as otherwise permitted
                by applicable law. Click&nbsp;
              </span>
            </span>
            <a
              data-custom-class="link"
              href="#sensitiveinfo"
            >
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">here</span>
              </span>
            </a>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                &nbsp;to learn more.
                <div
                  id="bdt"
                  className="statement-end-if-in-editor"
                />
              </span>
            </span>
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <br />
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <strong>Do we receive any information from third parties?</strong>{' '}
                <div
                  id="bdt"
                  className="block-component"
                />
                We may receive information from public databases, marketing partners, social media platforms, and other
                outside sources. Click&nbsp;
              </span>
            </span>
            <a
              data-custom-class="link"
              href="#othersources"
            >
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">here</span>
              </span>
            </a>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                &nbsp;to learn more.
                <div
                  id="bdt"
                  className="statement-end-if-in-editor"
                />
              </span>
            </span>
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <br />
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <strong>How do we process your information?</strong> We process your information to provide, improve,
                and administer our Services, communicate with you, for security and fraud prevention, and to comply with
                law. We may also process your information for other purposes with your consent. We process your
                information only when we have a valid legal reason to do so. Click&nbsp;
              </span>
            </span>
            <a
              data-custom-class="link"
              href="#infouse"
            >
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">here</span>
              </span>
            </a>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">&nbsp;to learn more.</span>
            </span>
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <br />
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <strong>
                  In what situations and with which{' '}
                  <div
                    id="bdt"
                    className="block-component"
                  />
                  parties do we share personal information?
                </strong>{' '}
                We may share information in specific situations and with specific{' '}
                <div
                  id="bdt"
                  className="block-component"
                />
                third parties. Click&nbsp;
              </span>
            </span>
            <a
              data-custom-class="link"
              href="#whoshare"
            >
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">here</span>
              </span>
            </a>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                &nbsp;to learn more.
                <div
                  id="bdt"
                  className="block-component"
                />
              </span>
            </span>
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <br />
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <strong>How do we keep your information safe?</strong> We have{' '}
                <div
                  id="bdt"
                  className="block-component"
                />
                organizational
                <div
                  id="bdt"
                  className="statement-end-if-in-editor"
                />{' '}
                and technical processes and procedures in place to protect your personal information. However, no
                electronic transmission over the internet or information storage technology can be guaranteed to be 100%
                secure, so we cannot promise or guarantee that hackers, cybercriminals, or other{' '}
                <div
                  id="bdt"
                  className="block-component"
                />
                unauthorized
                <div
                  id="bdt"
                  className="statement-end-if-in-editor"
                />{' '}
                third parties will not be able to defeat our security and improperly collect, access, steal, or modify
                your information. Click&nbsp;
              </span>
            </span>
            <a
              data-custom-class="link"
              href="#infosafe"
            >
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">here</span>
              </span>
            </a>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                &nbsp;to learn more.
                <div
                  id="bdt"
                  className="statement-end-if-in-editor"
                />
              </span>
            </span>
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <br />
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <strong>What are your rights?</strong> Depending on where you are located geographically, the applicable
                privacy law may mean you have certain rights regarding your personal information. Click&nbsp;
              </span>
            </span>
            <a
              data-custom-class="link"
              href="#privacyrights"
            >
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">here</span>
              </span>
            </a>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">&nbsp;to learn more.</span>
            </span>
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <br />
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <strong>How do you exercise your rights?</strong> The easiest way to exercise your rights is by filling
                out our data subject request form available{' '}
                <div
                  id="bdt"
                  className="block-component"
                />
              </span>
            </span>
            <a
              data-custom-class="link"
              href="https://app.termly.io/notify/e90335b2-4a90-46bf-a07c-adbc67a1f766"
              rel="noopener noreferrer"
              target="_blank"
            >
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">here</span>
              </span>
            </a>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <div
                  id="bdt"
                  className="block-component"
                />
                , or by contacting us. We will consider and act upon any request in accordance with applicable data
                protection laws.
              </span>
            </span>
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <br />
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                Want to learn more about what{' '}
                <div
                  id="bdt"
                  className="block-component"
                />
                <div
                  id="bdt"
                  className="question"
                >
                  TuneHatch, Inc.
                </div>
                <div
                  id="bdt"
                  className="statement-end-if-in-editor"
                />{' '}
                does with any information we collect? Click&nbsp;
              </span>
            </span>
            <a
              data-custom-class="link"
              href="#toc"
            >
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">here</span>
              </span>
            </a>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">&nbsp;to review the notice in full.</span>
            </span>
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <br />
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <br />
          </div>
          <div
            className="flex-col"
            id="toc"
            style={{ lineHeight: '1.5' }}
          >
            <span style={{ fontSize: 15 }}>
              <span style={{ color: 'rgb(127, 127, 127)' }}>
                <span style={{ color: 'rgb(0, 0, 0)' }}>
                  <strong>
                    <span data-custom-class="heading_1">TABLE OF CONTENTS</span>
                  </strong>
                </span>
              </span>
            </span>
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <br />
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <span style={{ fontSize: 15 }}>
              <a
                data-custom-class="link"
                href="#infocollect"
              >
                <span style={{ color: 'rgb(89, 89, 89)' }}>1. WHAT INFORMATION DO WE COLLECT?</span>
              </a>
            </span>
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <span style={{ fontSize: 15 }}>
              <a
                data-custom-class="link"
                href="#infouse"
              >
                <span style={{ color: 'rgb(89, 89, 89)' }}>
                  2. HOW DO WE PROCESS YOUR INFORMATION?
                  <div
                    id="bdt"
                    className="block-component"
                  />
                </span>
              </a>
            </span>
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <span style={{ fontSize: 15 }}>
              <span style={{ color: 'rgb(89, 89, 89)' }}>
                <a
                  data-custom-class="link"
                  href="#whoshare"
                >
                  3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                </a>
              </span>
              <span data-custom-class="body_text">
                <div
                  id="bdt"
                  className="block-component"
                />
              </span>
              <span style={{ color: 'rgb(127, 127, 127)' }}>
                <span style={{ color: 'rgb(89, 89, 89)' }}>
                  <span data-custom-class="body_text">
                    <span style={{ color: 'rgb(89, 89, 89)' }}>
                      <div
                        id="bdt"
                        className="block-component"
                      />
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <span style={{ fontSize: 15 }}>
              <a
                data-custom-class="link"
                href="#cookies"
              >
                <span style={{ color: 'rgb(89, 89, 89)' }}>4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</span>
              </a>
              <span style={{ color: 'rgb(127, 127, 127)' }}>
                <span style={{ color: 'rgb(89, 89, 89)' }}>
                  <span data-custom-class="body_text">
                    <span style={{ color: 'rgb(89, 89, 89)' }}>
                      <div
                        id="bdt"
                        className="statement-end-if-in-editor"
                      />
                    </span>
                  </span>
                  <span data-custom-class="body_text">
                    <span style={{ color: 'rgb(89, 89, 89)' }}>
                      <span
                        style={{
                          color: 'rgb(89, 89, 89)',
                        }}
                      >
                        <span
                          style={{
                            color: 'rgb(89, 89, 89)',
                          }}
                        >
                          <div
                            id="bdt"
                            className="block-component"
                          />
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <span style={{ fontSize: 15 }}>
              <a
                data-custom-class="link"
                href="#sociallogins"
              >
                <span style={{ color: 'rgb(89, 89, 89)' }}>
                  <span style={{ color: 'rgb(89, 89, 89)' }}>
                    <span style={{ color: 'rgb(89, 89, 89)' }}>5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</span>
                  </span>
                </span>
              </a>
              <span style={{ color: 'rgb(127, 127, 127)' }}>
                <span style={{ color: 'rgb(89, 89, 89)' }}>
                  <span data-custom-class="body_text">
                    <span style={{ color: 'rgb(89, 89, 89)' }}>
                      <span
                        style={{
                          color: 'rgb(89, 89, 89)',
                        }}
                      >
                        <span
                          style={{
                            color: 'rgb(89, 89, 89)',
                          }}
                        >
                          <div
                            id="bdt"
                            className="statement-end-if-in-editor"
                          />
                        </span>
                      </span>
                      <div
                        id="bdt"
                        className="block-component"
                      />
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <span style={{ fontSize: 15 }}>
              <a
                data-custom-class="link"
                href="#inforetain"
              >
                <span style={{ color: 'rgb(89, 89, 89)' }}>6. HOW LONG DO WE KEEP YOUR INFORMATION?</span>
              </a>
              <span style={{ color: 'rgb(127, 127, 127)' }}>
                <span style={{ color: 'rgb(89, 89, 89)' }}>
                  <span data-custom-class="body_text">
                    <span style={{ color: 'rgb(89, 89, 89)' }}>
                      <span
                        style={{
                          color: 'rgb(89, 89, 89)',
                        }}
                      >
                        <div
                          id="bdt"
                          className="block-component"
                        />
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <span style={{ fontSize: 15 }}>
              <a
                data-custom-class="link"
                href="#infosafe"
              >
                <span style={{ color: 'rgb(89, 89, 89)' }}>7. HOW DO WE KEEP YOUR INFORMATION SAFE?</span>
              </a>
              <span style={{ color: 'rgb(127, 127, 127)' }}>
                <span style={{ color: 'rgb(89, 89, 89)' }}>
                  <span data-custom-class="body_text">
                    <span style={{ color: 'rgb(89, 89, 89)' }}>
                      <div
                        id="bdt"
                        className="statement-end-if-in-editor"
                      />
                      <div
                        id="bdt"
                        className="block-component"
                      />
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <span style={{ fontSize: 15 }}>
              <span style={{ color: 'rgb(89, 89, 89)' }}>
                <a
                  data-custom-class="link"
                  href="#privacyrights"
                >
                  8. WHAT ARE YOUR PRIVACY RIGHTS?
                </a>
              </span>
            </span>
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <span style={{ fontSize: 15 }}>
              <a
                data-custom-class="link"
                href="#DNT"
              >
                <span style={{ color: 'rgb(89, 89, 89)' }}>9. CONTROLS FOR DO-NOT-TRACK FEATURES</span>
              </a>
            </span>
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <span style={{ fontSize: 15 }}>
              <a
                data-custom-class="link"
                href="#caresidents"
              >
                <span style={{ color: 'rgb(89, 89, 89)' }}>
                  10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                </span>
              </a>
            </span>
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <span style={{ fontSize: 15 }}>
              <a
                data-custom-class="link"
                href="#policyupdates"
              >
                <span style={{ color: 'rgb(89, 89, 89)' }}>11. DO WE MAKE UPDATES TO THIS NOTICE?</span>
              </a>
            </span>
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <a
              data-custom-class="link"
              href="#contact"
            >
              <span
                style={{
                  color: 'rgb(89, 89, 89)',
                  fontSize: 15,
                }}
              >
                12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
              </span>
            </a>
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <a
              data-custom-class="link"
              href="#request"
            >
              <span style={{ color: 'rgb(89, 89, 89)' }}>
                13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
              </span>
            </a>
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <br />
          </div>
          <div
            className="flex-col"
            id="infocollect"
            style={{ lineHeight: '1.5' }}
          >
            <span style={{ color: 'rgb(127, 127, 127)' }}>
              <span
                style={{
                  color: 'rgb(89, 89, 89)',
                  fontSize: 15,
                }}
              >
                <span
                  style={{
                    fontSize: 15,
                    color: 'rgb(89, 89, 89)',
                  }}
                >
                  <span
                    style={{
                      fontSize: 15,
                      color: 'rgb(89, 89, 89)',
                    }}
                  >
                    <span
                      id="control"
                      style={{ color: 'rgb(0, 0, 0)' }}
                    >
                      <strong>
                        <span data-custom-class="heading_1">1. WHAT INFORMATION DO WE COLLECT?</span>
                      </strong>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <br />
          </div>
          <div
            className="flex-col"
            id="personalinfo"
            style={{ lineHeight: '1.5' }}
          >
            <span
              data-custom-class="heading_2"
              style={{ color: 'rgb(0, 0, 0)' }}
            >
              <span style={{ fontSize: 15 }}>
                <strong>Personal information you disclose to us</strong>
              </span>
            </span>
          </div>
          <div className="flex-col">
            <div className="flex-col">
              <br />
            </div>
            <div
              className="flex-col"
              style={{ lineHeight: '1.5' }}
            >
              <span style={{ color: 'rgb(127, 127, 127)' }}>
                <span
                  style={{
                    color: 'rgb(89, 89, 89)',
                    fontSize: 15,
                  }}
                >
                  <span data-custom-class="body_text">
                    <span
                      style={{
                        fontSize: 15,
                        color: 'rgb(89, 89, 89)',
                      }}
                    >
                      <span
                        style={{
                          fontSize: 15,
                          color: 'rgb(89, 89, 89)',
                        }}
                      >
                        <span data-custom-class="body_text">
                          <strong>
                            <em>In Short:</em>
                          </strong>
                        </span>
                      </span>
                    </span>
                  </span>
                  <span data-custom-class="body_text">
                    <span
                      style={{
                        fontSize: 15,
                        color: 'rgb(89, 89, 89)',
                      }}
                    >
                      <span
                        style={{
                          fontSize: 15,
                          color: 'rgb(89, 89, 89)',
                        }}
                      >
                        <span data-custom-class="body_text">
                          <strong>
                            <em>&nbsp;</em>
                          </strong>
                          <em>We collect personal information that you provide to us.</em>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <br />
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
              <span
                style={{
                  fontSize: 15,
                  color: 'rgb(89, 89, 89)',
                }}
              >
                <span data-custom-class="body_text">
                  We collect personal information that you voluntarily provide to us when you{' '}
                  <span style={{ fontSize: 15 }}>
                    <div
                      id="bdt"
                      className="block-component"
                    />
                  </span>
                  register on the Services,&nbsp;
                </span>
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: 15 }}>
                      <div
                        id="bdt"
                        className="statement-end-if-in-editor"
                      />
                    </span>
                  </span>
                  <span data-custom-class="body_text">
                    express an interest in obtaining information about us or our products and Services, when you
                    participate in activities on the Services, or otherwise when you contact us.
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <br />
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
              <span
                style={{
                  fontSize: 15,
                  color: 'rgb(89, 89, 89)',
                }}
              >
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15 }}>
                    <div
                      id="bdt"
                      className="block-component"
                    />
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
              <span
                style={{
                  fontSize: 15,
                  color: 'rgb(89, 89, 89)',
                }}
              >
                <span data-custom-class="body_text">
                  <strong>Personal Information Provided by You.</strong> The personal information that we collect
                  depends on the context of your interactions with us and the Services, the choices you make, and the
                  products and features you use. The personal information we collect may include the following:
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <div
                        id="bdt"
                        className="forloop-component"
                      />
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <ul>
            <li style={{ lineHeight: '1.5' }}>
              <span
                style={{
                  fontSize: 15,
                  color: 'rgb(89, 89, 89)',
                }}
              >
                <span
                  style={{
                    fontSize: 15,
                    color: 'rgb(89, 89, 89)',
                  }}
                >
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: 15 }}>
                      <span data-custom-class="body_text">
                        <div
                          id="bdt"
                          className="question"
                        >
                          names
                        </div>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
              <span
                style={{
                  fontSize: 15,
                  color: 'rgb(89, 89, 89)',
                }}
              >
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <div
                        id="bdt"
                        className="forloop-component"
                      />
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <ul>
            <li style={{ lineHeight: '1.5' }}>
              <span
                style={{
                  fontSize: 15,
                  color: 'rgb(89, 89, 89)',
                }}
              >
                <span
                  style={{
                    fontSize: 15,
                    color: 'rgb(89, 89, 89)',
                  }}
                >
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: 15 }}>
                      <span data-custom-class="body_text">
                        <div
                          id="bdt"
                          className="question"
                        >
                          phone numbers
                        </div>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
              <span
                style={{
                  fontSize: 15,
                  color: 'rgb(89, 89, 89)',
                }}
              >
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <div
                        id="bdt"
                        className="forloop-component"
                      />
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <ul>
            <li style={{ lineHeight: '1.5' }}>
              <span
                style={{
                  fontSize: 15,
                  color: 'rgb(89, 89, 89)',
                }}
              >
                <span
                  style={{
                    fontSize: 15,
                    color: 'rgb(89, 89, 89)',
                  }}
                >
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: 15 }}>
                      <span data-custom-class="body_text">
                        <div
                          id="bdt"
                          className="question"
                        >
                          email addresses
                        </div>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
              <span
                style={{
                  fontSize: 15,
                  color: 'rgb(89, 89, 89)',
                }}
              >
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <div
                        id="bdt"
                        className="forloop-component"
                      />
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <ul>
            <li style={{ lineHeight: '1.5' }}>
              <span
                style={{
                  fontSize: 15,
                  color: 'rgb(89, 89, 89)',
                }}
              >
                <span
                  style={{
                    fontSize: 15,
                    color: 'rgb(89, 89, 89)',
                  }}
                >
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: 15 }}>
                      <span data-custom-class="body_text">
                        <div
                          id="bdt"
                          className="question"
                        >
                          usernames
                        </div>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
              <span
                style={{
                  fontSize: 15,
                  color: 'rgb(89, 89, 89)',
                }}
              >
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <div
                        id="bdt"
                        className="forloop-component"
                      />
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <ul>
            <li style={{ lineHeight: '1.5' }}>
              <span
                style={{
                  fontSize: 15,
                  color: 'rgb(89, 89, 89)',
                }}
              >
                <span
                  style={{
                    fontSize: 15,
                    color: 'rgb(89, 89, 89)',
                  }}
                >
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: 15 }}>
                      <span data-custom-class="body_text">
                        <div
                          id="bdt"
                          className="question"
                        >
                          passwords
                        </div>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
              <span
                style={{
                  fontSize: 15,
                  color: 'rgb(89, 89, 89)',
                }}
              >
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <div
                        id="bdt"
                        className="forloop-component"
                      />
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <ul>
            <li style={{ lineHeight: '1.5' }}>
              <span
                style={{
                  fontSize: 15,
                  color: 'rgb(89, 89, 89)',
                }}
              >
                <span
                  style={{
                    fontSize: 15,
                    color: 'rgb(89, 89, 89)',
                  }}
                >
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: 15 }}>
                      <span data-custom-class="body_text">
                        <div
                          id="bdt"
                          className="question"
                        >
                          contact or authentication data
                        </div>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
              <span
                style={{
                  fontSize: 15,
                  color: 'rgb(89, 89, 89)',
                }}
              >
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <div
                        id="bdt"
                        className="forloop-component"
                      />
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <ul>
            <li style={{ lineHeight: '1.5' }}>
              <span
                style={{
                  fontSize: 15,
                  color: 'rgb(89, 89, 89)',
                }}
              >
                <span
                  style={{
                    fontSize: 15,
                    color: 'rgb(89, 89, 89)',
                  }}
                >
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: 15 }}>
                      <span data-custom-class="body_text">
                        <div
                          id="bdt"
                          className="question"
                        >
                          debit/credit card numbers
                        </div>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
              <span
                style={{
                  fontSize: 15,
                  color: 'rgb(89, 89, 89)',
                }}
              >
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <div
                        id="bdt"
                        className="forloop-component"
                      />
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <ul>
            <li style={{ lineHeight: '1.5' }}>
              <span
                style={{
                  fontSize: 15,
                  color: 'rgb(89, 89, 89)',
                }}
              >
                <span
                  style={{
                    fontSize: 15,
                    color: 'rgb(89, 89, 89)',
                  }}
                >
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: 15 }}>
                      <span data-custom-class="body_text">
                        <div
                          id="bdt"
                          className="question"
                        >
                          billing addresses
                        </div>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
              <span
                style={{
                  fontSize: 15,
                  color: 'rgb(89, 89, 89)',
                }}
              >
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <div
                        id="bdt"
                        className="forloop-component"
                      />
                    </span>
                    <span data-custom-class="body_text">
                      <div
                        id="bdt"
                        className="statement-end-if-in-editor"
                      />
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div
            className="flex-col"
            id="sensitiveinfo"
            style={{ lineHeight: '1.5' }}
          >
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <strong>Sensitive Information.</strong>{' '}
                <div
                  id="bdt"
                  className="block-component"
                />
                When necessary, with your consent or as otherwise permitted by applicable law, we process the following
                categories of sensitive information:
                <div
                  id="bdt"
                  className="forloop-component"
                />
              </span>
            </span>
          </div>
          <ul>
            <li style={{ lineHeight: '1.5' }}>
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <div
                    id="bdt"
                    className="question"
                  >
                    financial data
                  </div>
                </span>
              </span>
            </li>
          </ul>
          <div className="flex-col">
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <div
                  id="bdt"
                  className="forloop-component"
                />
              </span>
              <span data-custom-class="body_text">
                <div
                  id="bdt"
                  className="statement-end-if-in-editor"
                />
              </span>
            </span>
            <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
              <span
                style={{
                  fontSize: 15,
                  color: 'rgb(89, 89, 89)',
                }}
              >
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <div
                        id="bdt"
                        className="block-component"
                      />
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
              <span
                style={{
                  fontSize: 15,
                  color: 'rgb(89, 89, 89)',
                }}
              >
                <span data-custom-class="body_text">
                  <strong>Payment Data.</strong> We may collect data necessary to process your payment if you make
                  purchases, such as your payment instrument number, and the security code associated with your payment
                  instrument. All payment data is stored by
                  <div
                    id="bdt"
                    className="forloop-component"
                  />
                  <span
                    style={{
                      fontSize: 15,
                      color: 'rgb(89, 89, 89)',
                    }}
                  >
                    <span
                      style={{
                        fontSize: 15,
                        color: 'rgb(89, 89, 89)',
                      }}
                    >
                      <span data-custom-class="body_text">
                        <span style={{ fontSize: 15 }}>
                          <span data-custom-class="body_text">
                            <div
                              id="bdt"
                              className="block-component"
                            />
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>{' '}
                  <div
                    id="bdt"
                    className="question"
                  >
                    Stripe
                  </div>
                  <span
                    style={{
                      fontSize: 15,
                      color: 'rgb(89, 89, 89)',
                    }}
                  >
                    <span
                      style={{
                        fontSize: 15,
                        color: 'rgb(89, 89, 89)',
                      }}
                    >
                      <span data-custom-class="body_text">
                        <span
                          style={{
                            fontSize: 15,
                            color: 'rgb(89, 89, 89)',
                          }}
                        >
                          <span
                            style={{
                              fontSize: 15,
                              color: 'rgb(89, 89, 89)',
                            }}
                          >
                            <span data-custom-class="body_text">
                              <span
                                style={{
                                  fontSize: 15,
                                }}
                              >
                                <span data-custom-class="body_text">
                                  <div
                                    id="bdt"
                                    className="block-component"
                                  />
                                </span>
                              </span>
                            </span>
                            <span data-custom-class="body_text">
                              <span
                                style={{
                                  fontSize: 15,
                                }}
                              >
                                <span
                                  style={{
                                    color: 'rgb(89, 89, 89)',
                                  }}
                                >
                                  <span data-custom-class="body_text">
                                    <span
                                      style={{
                                        fontSize: 15,
                                      }}
                                    >
                                      <span
                                        style={{
                                          color: 'rgb(89, 89, 89)',
                                        }}
                                      >
                                        <span data-custom-class="body_text">
                                          <span
                                            style={{
                                              color: 'rgb(89, 89, 89)',
                                            }}
                                          >
                                            <span
                                              style={{
                                                fontSize: 15,
                                              }}
                                            >
                                              <span data-custom-class="body_text">
                                                <div
                                                  id="bdt"
                                                  className="forloop-component"
                                                />
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                        . You may find their privacy notice link(s) here:
                        <span
                          style={{
                            fontSize: 15,
                            color: 'rgb(89, 89, 89)',
                          }}
                        >
                          <span
                            style={{
                              fontSize: 15,
                              color: 'rgb(89, 89, 89)',
                            }}
                          >
                            <span data-custom-class="body_text">
                              <div
                                id="bdt"
                                className="forloop-component"
                              />
                              <span
                                style={{
                                  fontSize: 15,
                                  color: 'rgb(89, 89, 89)',
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: 15,
                                    color: 'rgb(89, 89, 89)',
                                  }}
                                >
                                  <span data-custom-class="body_text">
                                    <span
                                      style={{
                                        fontSize: 15,
                                      }}
                                    >
                                      <span data-custom-class="body_text">
                                        <div
                                          id="bdt"
                                          className="block-component"
                                        />
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>{' '}
                        <div
                          id="bdt"
                          className="question"
                        >
                          <a
                            href="https://stripe.com/privacy"
                            target="_blank"
                            data-custom-class="link"
                            rel="noreferrer"
                          >
                            https://stripe.com/privacy
                          </a>
                        </div>
                        <span
                          style={{
                            fontSize: 15,
                            color: 'rgb(89, 89, 89)',
                          }}
                        >
                          <span
                            style={{
                              fontSize: 15,
                              color: 'rgb(89, 89, 89)',
                            }}
                          >
                            <span data-custom-class="body_text">
                              <span
                                style={{
                                  fontSize: 15,
                                  color: 'rgb(89, 89, 89)',
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: 15,
                                    color: 'rgb(89, 89, 89)',
                                  }}
                                >
                                  <span data-custom-class="body_text">
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: 'rgb(89, 89, 89)',
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: 'rgb(89, 89, 89)',
                                        }}
                                      >
                                        <span data-custom-class="body_text">
                                          <span
                                            style={{
                                              fontSize: 15,
                                            }}
                                          >
                                            <span data-custom-class="body_text">
                                              <div
                                                id="bdt"
                                                className="block-component"
                                              />
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                              <div
                                id="bdt"
                                className="forloop-component"
                              />
                              <span
                                style={{
                                  fontSize: 15,
                                }}
                              >
                                <span data-custom-class="body_text">
                                  .
                                  <div
                                    id="bdt"
                                    className="block-component"
                                  />
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <br />
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
              <span
                style={{
                  fontSize: 15,
                  color: 'rgb(89, 89, 89)',
                }}
              >
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <div
                        id="bdt"
                        className="statement-end-if-in-editor"
                      >
                        <div
                          id="bdt"
                          className="block-component"
                        />
                      </div>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
              <span
                style={{
                  fontSize: 15,
                  color: 'rgb(89, 89, 89)',
                }}
              >
                <span data-custom-class="body_text">
                  <strong>Social Media Login Data.&nbsp;</strong>
                  We may provide you with the option to register with us using your existing social media account
                  details, like your Facebook, Twitter, or other social media account. If you choose to register in this
                  way, we will collect the information described in the section called{' '}
                  <div
                    id="bdt"
                    className="block-component"
                  />
                  "
                  <div
                    id="bdt"
                    className="statement-end-if-in-editor"
                  />
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: 15 }}>
                        <span
                          style={{
                            color: 'rgb(89, 89, 89)',
                          }}
                        >
                          <a
                            data-custom-class="link"
                            href="#sociallogins"
                          >
                            HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                          </a>
                        </span>
                      </span>
                    </span>
                  </span>
                  <div
                    id="bdt"
                    className="block-component"
                  />
                  "
                  <div
                    id="bdt"
                    className="statement-end-if-in-editor"
                  />{' '}
                  below.
                </span>
              </span>
            </span>
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <br />
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
              <span
                style={{
                  fontSize: 15,
                  color: 'rgb(89, 89, 89)',
                }}
              >
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15 }}>
                    <div
                      id="bdt"
                      className="statement-end-if-in-editor"
                    >
                      <div
                        id="bdt"
                        className="statement-end-if-in-editor"
                      />
                    </div>
                  </span>
                </span>
              </span>
            </span>
            <div
              id="bdt"
              className="block-component"
            />
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
              <span
                style={{
                  fontSize: 15,
                  color: 'rgb(89, 89, 89)',
                }}
              >
                <span data-custom-class="body_text">
                  All personal information that you provide to us must be true, complete, and accurate, and you must
                  notify us of any changes to such personal information.
                </span>
              </span>
            </span>
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <br />
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
              <span
                style={{
                  fontSize: 15,
                  color: 'rgb(89, 89, 89)',
                }}
              >
                <span data-custom-class="body_text">
                  <div
                    id="bdt"
                    className="block-component"
                  />
                </span>
              </span>
            </span>
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <span
              data-custom-class="heading_2"
              style={{ color: 'rgb(0, 0, 0)' }}
            >
              <span style={{ fontSize: 15 }}>
                <strong>Information automatically collected</strong>
              </span>
            </span>
          </div>
          <div className="flex-col">
            <div className="flex-col">
              <br />
            </div>
            <div
              className="flex-col"
              style={{ lineHeight: '1.5' }}
            >
              <span style={{ color: 'rgb(127, 127, 127)' }}>
                <span
                  style={{
                    color: 'rgb(89, 89, 89)',
                    fontSize: 15,
                  }}
                >
                  <span data-custom-class="body_text">
                    <span
                      style={{
                        fontSize: 15,
                        color: 'rgb(89, 89, 89)',
                      }}
                    >
                      <span
                        style={{
                          fontSize: 15,
                          color: 'rgb(89, 89, 89)',
                        }}
                      >
                        <span data-custom-class="body_text">
                          <strong>
                            <em>In Short:</em>
                          </strong>
                        </span>
                      </span>
                    </span>
                  </span>
                  <span data-custom-class="body_text">
                    <span
                      style={{
                        fontSize: 15,
                        color: 'rgb(89, 89, 89)',
                      }}
                    >
                      <span
                        style={{
                          fontSize: 15,
                          color: 'rgb(89, 89, 89)',
                        }}
                      >
                        <span data-custom-class="body_text">
                          <strong>
                            <em>&nbsp;</em>
                          </strong>
                          <em>
                            Some information — such as your Internet Protocol (IP) address and/or browser and device
                            characteristics — is collected automatically when you visit our Services.
                          </em>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <br />
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
              <span
                style={{
                  fontSize: 15,
                  color: 'rgb(89, 89, 89)',
                }}
              >
                <span data-custom-class="body_text">
                  We automatically collect certain information when you visit, use, or navigate the Services. This
                  information does not reveal your specific identity (like your name or contact information) but may
                  include device and usage information, such as your IP address, browser and device characteristics,
                  operating system, language preferences, referring URLs, device name, country, location, information
                  about how and when you use our Services, and other technical information. This information is
                  primarily needed to maintain the security and operation of our Services, and for our internal
                  analytics and reporting purposes.
                </span>
              </span>
            </span>
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <br />
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
              <span
                style={{
                  fontSize: 15,
                  color: 'rgb(89, 89, 89)',
                }}
              >
                <span data-custom-class="body_text">
                  <div
                    id="bdt"
                    className="block-component"
                  />
                </span>
              </span>
            </span>
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
              <span
                style={{
                  fontSize: 15,
                  color: 'rgb(89, 89, 89)',
                }}
              >
                <span data-custom-class="body_text">
                  Like many businesses, we also collect information through cookies and similar technologies.{' '}
                  <div
                    id="bdt"
                    className="block-component"
                  />
                  <div
                    id="bdt"
                    className="block-component"
                  />
                </span>
              </span>
            </span>
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <br />
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
              <span
                style={{
                  fontSize: 15,
                  color: 'rgb(89, 89, 89)',
                }}
              >
                <span data-custom-class="body_text">
                  <div
                    id="bdt"
                    className="statement-end-if-in-editor"
                  >
                    <span data-custom-class="body_text" />
                  </div>
                </span>
                <span data-custom-class="body_text">
                  <div
                    id="bdt"
                    className="block-component"
                  />
                </span>
              </span>
            </span>
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
              <span
                style={{
                  fontSize: 15,
                  color: 'rgb(89, 89, 89)',
                }}
              >
                <span data-custom-class="body_text">
                  The information we collect includes:
                  <div
                    id="bdt"
                    className="block-component"
                  />
                </span>
              </span>
            </span>
          </div>
          <ul>
            <li style={{ lineHeight: '1.5' }}>
              <span
                style={{
                  fontSize: 15,
                  color: 'rgb(89, 89, 89)',
                }}
              >
                <span
                  style={{
                    fontSize: 15,
                    color: 'rgb(89, 89, 89)',
                  }}
                >
                  <span data-custom-class="body_text">
                    <em>Log and Usage Data.</em> Log and usage data is service-related, diagnostic, usage, and
                    performance information our servers automatically collect when you access or use our Services and
                    which we record in log files. Depending on how you interact with us, this log data may include your
                    IP address, device information, browser type, and settings and information about your activity in
                    the Services
                    <span style={{ fontSize: 15 }}>&nbsp;</span>
                    (such as the date/time stamps associated with your usage, pages and files viewed, searches, and
                    other actions you take such as which features you use), device event information (such as system
                    activity, error reports (sometimes called{' '}
                    <div
                      id="bdt"
                      className="block-component"
                    />
                    "crash dumps"
                    <div
                      id="bdt"
                      className="statement-end-if-in-editor"
                    />
                    ), and hardware settings).
                    <span style={{ fontSize: 15 }}>
                      <span
                        style={{
                          color: 'rgb(89, 89, 89)',
                        }}
                      >
                        <span data-custom-class="body_text">
                          <span style={{ fontSize: 15 }}>
                            <span
                              style={{
                                color: 'rgb(89, 89, 89)',
                              }}
                            >
                              <div
                                id="bdt"
                                className="statement-end-if-in-editor"
                              />
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <div
              id="bdt"
              className="block-component"
            >
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text" />
              </span>
            </div>
          </div>
          <ul>
            <li style={{ lineHeight: '1.5' }}>
              <span
                style={{
                  fontSize: 15,
                  color: 'rgb(89, 89, 89)',
                }}
              >
                <span
                  style={{
                    fontSize: 15,
                    color: 'rgb(89, 89, 89)',
                  }}
                >
                  <span data-custom-class="body_text">
                    <em>Device Data.</em> We collect device data such as information about your computer, phone, tablet,
                    or other device you use to access the Services. Depending on the device used, this device data may
                    include information such as your IP address (or proxy server), device and application identification
                    numbers, location, browser type, hardware model, Internet service provider and/or mobile carrier,
                    operating system, and system configuration information.
                    <span style={{ fontSize: 15 }}>
                      <span
                        style={{
                          color: 'rgb(89, 89, 89)',
                        }}
                      >
                        <span data-custom-class="body_text">
                          <span style={{ fontSize: 15 }}>
                            <span
                              style={{
                                color: 'rgb(89, 89, 89)',
                              }}
                            >
                              <div
                                id="bdt"
                                className="statement-end-if-in-editor"
                              />
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <div
              id="bdt"
              className="block-component"
            >
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text" />
              </span>
            </div>
          </div>
          <ul>
            <li style={{ lineHeight: '1.5' }}>
              <span
                style={{
                  fontSize: 15,
                  color: 'rgb(89, 89, 89)',
                }}
              >
                <span
                  style={{
                    fontSize: 15,
                    color: 'rgb(89, 89, 89)',
                  }}
                >
                  <span data-custom-class="body_text">
                    <em>Location Data.</em> We collect location data such as information about your device's location,
                    which can be either precise or imprecise. How much information we collect depends on the type and
                    settings of the device you use to access the Services. For example, we may use GPS and other
                    technologies to collect geolocation data that tells us your current location (based on your IP
                    address). You can opt out of allowing us to collect this information either by refusing access to
                    the information or by disabling your Location setting on your device. However, if you choose to opt
                    out, you may not be able to use certain aspects of the Services.
                    <span style={{ fontSize: 15 }}>
                      <span
                        style={{
                          color: 'rgb(89, 89, 89)',
                        }}
                      >
                        <span data-custom-class="body_text">
                          <span style={{ fontSize: 15 }}>
                            <span
                              style={{
                                color: 'rgb(89, 89, 89)',
                              }}
                            >
                              <div
                                id="bdt"
                                className="statement-end-if-in-editor"
                              />
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <div className="flex-col">
            <div
              id="bdt"
              className="block-component"
            >
              <span style={{ fontSize: 15 }} />
            </div>
            <div
              id="bdt"
              className="statement-end-if-in-editor"
            />
            <span data-custom-class="body_text">
              <span
                style={{
                  color: 'rgb(89, 89, 89)',
                  fontSize: 15,
                }}
              >
                <span data-custom-class="body_text">
                  <span
                    style={{
                      color: 'rgb(89, 89, 89)',
                      fontSize: 15,
                    }}
                  >
                    <span data-custom-class="body_text">
                      <div
                        id="bdt"
                        className="statement-end-if-in-editor"
                      >
                        <div
                          id="bdt"
                          className="block-component"
                        />
                      </div>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div
            className="flex-col"
            id="othersources"
            style={{ lineHeight: '1.5' }}
          >
            <span
              data-custom-class="heading_2"
              style={{ color: 'rgb(0, 0, 0)' }}
            >
              <span style={{ fontSize: 15 }}>
                <strong>Information collected from other sources</strong>
              </span>
            </span>
          </div>
          <div className="flex-col">
            <div className="flex-col">
              <br />
            </div>
            <div
              className="flex-col"
              style={{ lineHeight: '1.5' }}
            >
              <span style={{ color: 'rgb(127, 127, 127)' }}>
                <span
                  style={{
                    color: 'rgb(89, 89, 89)',
                    fontSize: 15,
                  }}
                >
                  <span data-custom-class="body_text">
                    <span
                      style={{
                        fontSize: 15,
                        color: 'rgb(89, 89, 89)',
                      }}
                    >
                      <span
                        style={{
                          fontSize: 15,
                          color: 'rgb(89, 89, 89)',
                        }}
                      >
                        <span data-custom-class="body_text">
                          <strong>
                            <em>In Short:&nbsp;</em>
                          </strong>
                          <em>
                            We may collect limited data from public databases, marketing partners,{' '}
                            <span
                              style={{
                                color: 'rgb(89, 89, 89)',
                              }}
                            >
                              <span
                                style={{
                                  fontSize: 15,
                                }}
                              >
                                <em>
                                  <span data-custom-class="body_text">
                                    <div
                                      id="bdt"
                                      className="block-component"
                                    />
                                    social media platforms,{' '}
                                    <div
                                      id="bdt"
                                      className="statement-end-if-in-editor"
                                    />
                                  </span>
                                </em>
                              </span>
                            </span>
                            and other outside sources.
                          </em>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <br />
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
              <span
                style={{
                  fontSize: 15,
                  color: 'rgb(89, 89, 89)',
                }}
              >
                <span data-custom-class="body_text">
                  In order to enhance our ability to provide relevant marketing, offers, and services to you and update
                  our records, we may obtain information about you from other sources, such as public databases, joint
                  marketing partners, affiliate programs, data providers,
                  <span style={{ color: 'rgb(89, 89, 89)' }}>
                    <span style={{ fontSize: 15 }}>
                      <span data-custom-class="body_text">
                        <div
                          id="bdt"
                          className="block-component"
                        />{' '}
                        social media platforms,
                        <div
                          id="bdt"
                          className="statement-end-if-in-editor"
                        />
                      </span>
                    </span>
                    &nbsp;
                  </span>
                  and from other third parties. This information includes mailing addresses, job titles, email
                  addresses, phone numbers, intent data (or user{' '}
                  <div
                    id="bdt"
                    className="block-component"
                  />
                  behavior
                  <div
                    id="bdt"
                    className="statement-end-if-in-editor"
                  />{' '}
                  data), Internet Protocol (IP) addresses, social media profiles, social media URLs, and custom
                  profiles, for purposes of targeted advertising and event promotion.
                  <span style={{ color: 'rgb(89, 89, 89)' }}>
                    <span style={{ fontSize: 15 }}>
                      <span data-custom-class="body_text">
                        <span
                          style={{
                            color: 'rgb(89, 89, 89)',
                          }}
                        >
                          <span style={{ fontSize: 15 }}>
                            <span data-custom-class="body_text">
                              <div
                                id="bdt"
                                className="block-component"
                              />
                            </span>
                          </span>
                          &nbsp;
                        </span>
                        If you interact with us on a social media platform using your social media account (e.g.
                        <div
                          id="bdt"
                          className="block-component"
                        />
                        ,
                        <div
                          id="bdt"
                          className="statement-end-if-in-editor"
                        />{' '}
                        Facebook or Twitter), we receive personal information about you such as your name, email
                        address, and gender. Any personal information that we collect from your social media account
                        depends on your social media account's privacy settings.
                      </span>
                    </span>
                  </span>
                </span>
                <span data-custom-class="body_text">
                  <span style={{ color: 'rgb(89, 89, 89)' }}>
                    <span style={{ fontSize: 15 }}>
                      <span data-custom-class="body_text">
                        <span
                          style={{
                            color: 'rgb(89, 89, 89)',
                          }}
                        >
                          <span style={{ fontSize: 15 }}>
                            <span data-custom-class="body_text">
                              <span
                                style={{
                                  color: 'rgb(89, 89, 89)',
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: 15,
                                  }}
                                >
                                  <span data-custom-class="body_text">
                                    <div
                                      id="bdt"
                                      className="statement-end-if-in-editor"
                                    />
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <br />
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
              <span
                style={{
                  fontSize: 15,
                  color: 'rgb(89, 89, 89)',
                }}
              >
                <span data-custom-class="body_text">
                  <span style={{ color: 'rgb(89, 89, 89)' }}>
                    <span style={{ fontSize: 15 }}>
                      <span data-custom-class="body_text">
                        <span
                          style={{
                            color: 'rgb(89, 89, 89)',
                          }}
                        >
                          <span
                            style={{
                              color: 'rgb(89, 89, 89)',
                            }}
                          >
                            <div
                              id="bdt"
                              className="block-component"
                            >
                              <span
                                style={{
                                  color: 'rgb(89, 89, 89)',
                                  fontSize: 15,
                                }}
                              >
                                <span data-custom-class="body_text">
                                  <span
                                    style={{
                                      color: 'rgb(89, 89, 89)',
                                      fontSize: 15,
                                    }}
                                  >
                                    <span data-custom-class="body_text">
                                      <div
                                        id="bdt"
                                        className="statement-end-if-in-editor"
                                      >
                                        <div
                                          id="bdt"
                                          className="statement-end-if-in-editor"
                                        />
                                      </div>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </div>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <div
                  id="bdt"
                  className="block-component"
                />
              </span>
            </span>
          </div>
          <div
            className="flex-col"
            id="infouse"
            style={{ lineHeight: '1.5' }}
          >
            <span style={{ color: 'rgb(127, 127, 127)' }}>
              <span
                style={{
                  color: 'rgb(89, 89, 89)',
                  fontSize: 15,
                }}
              >
                <span
                  style={{
                    fontSize: 15,
                    color: 'rgb(89, 89, 89)',
                  }}
                >
                  <span
                    style={{
                      fontSize: 15,
                      color: 'rgb(89, 89, 89)',
                    }}
                  >
                    <span
                      id="control"
                      style={{ color: 'rgb(0, 0, 0)' }}
                    >
                      <strong>
                        <span data-custom-class="heading_1">2. HOW DO WE PROCESS YOUR INFORMATION?</span>
                      </strong>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div className="flex-col">
            <div
              className="flex-col"
              style={{ lineHeight: '1.5' }}
            >
              <br />
            </div>
            <div
              className="flex-col"
              style={{ lineHeight: '1.5' }}
            >
              <span style={{ color: 'rgb(127, 127, 127)' }}>
                <span
                  style={{
                    color: 'rgb(89, 89, 89)',
                    fontSize: 15,
                  }}
                >
                  <span data-custom-class="body_text">
                    <span
                      style={{
                        fontSize: 15,
                        color: 'rgb(89, 89, 89)',
                      }}
                    >
                      <span
                        style={{
                          fontSize: 15,
                          color: 'rgb(89, 89, 89)',
                        }}
                      >
                        <span data-custom-class="body_text">
                          <strong>
                            <em>In Short:&nbsp;</em>
                          </strong>
                          <em>
                            We process your information to provide, improve, and administer our Services, communicate
                            with you, for security and fraud prevention, and to comply with law. We may also process
                            your information for other purposes with your consent.
                          </em>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <br />
          </div>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
              <span
                style={{
                  fontSize: 15,
                  color: 'rgb(89, 89, 89)',
                }}
              >
                <span data-custom-class="body_text">
                  <strong>
                    We process your personal information for a variety of reasons, depending on how you interact with
                    our Services, including:
                  </strong>
                  <div
                    id="bdt"
                    className="block-component"
                  />
                </span>
              </span>
            </span>
          </div>
          <ul>
            <li style={{ lineHeight: '1.5' }}>
              <span
                style={{
                  fontSize: 15,
                  color: 'rgb(89, 89, 89)',
                }}
              >
                <span
                  style={{
                    fontSize: 15,
                    color: 'rgb(89, 89, 89)',
                  }}
                >
                  <span data-custom-class="body_text">
                    <strong>
                      To facilitate account creation and authentication and otherwise manage user accounts.&nbsp;
                    </strong>
                    We may process your information so you can create and log in to your account, as well as keep your
                    account in working order.
                    <span
                      style={{
                        fontSize: 15,
                        color: 'rgb(89, 89, 89)',
                      }}
                    >
                      <span
                        style={{
                          fontSize: 15,
                          color: 'rgb(89, 89, 89)',
                        }}
                      >
                        <span data-custom-class="body_text">
                          <span style={{ fontSize: 15 }}>
                            <span
                              style={{
                                color: 'rgb(89, 89, 89)',
                              }}
                            >
                              <span data-custom-class="body_text">
                                <span
                                  style={{
                                    fontSize: 15,
                                  }}
                                >
                                  <span
                                    style={{
                                      color: 'rgb(89, 89, 89)',
                                    }}
                                  >
                                    <span data-custom-class="body_text">
                                      <div
                                        id="bdt"
                                        className="statement-end-if-in-editor"
                                      />
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
              <span
                style={{
                  fontSize: 15,
                  color: 'rgb(89, 89, 89)',
                }}
              >
                <span data-custom-class="body_text">
                  <div
                    id="bdt"
                    className="block-component"
                  />
                </span>
              </span>
            </span>
          </div>
          <ul>
            <li style={{ lineHeight: '1.5' }}>
              <span
                style={{
                  fontSize: 15,
                  color: 'rgb(89, 89, 89)',
                }}
              >
                <span
                  style={{
                    fontSize: 15,
                    color: 'rgb(89, 89, 89)',
                  }}
                >
                  <span data-custom-class="body_text">
                    <strong>To deliver and facilitate delivery of services to the user.&nbsp;</strong>
                    We may process your information to provide you with the requested service.
                    <span
                      style={{
                        fontSize: 15,
                        color: 'rgb(89, 89, 89)',
                      }}
                    >
                      <span
                        style={{
                          fontSize: 15,
                          color: 'rgb(89, 89, 89)',
                        }}
                      >
                        <span data-custom-class="body_text">
                          <span
                            style={{
                              fontSize: 15,
                              color: 'rgb(89, 89, 89)',
                            }}
                          >
                            <span
                              style={{
                                fontSize: 15,
                                color: 'rgb(89, 89, 89)',
                              }}
                            >
                              <span data-custom-class="body_text">
                                <span
                                  style={{
                                    fontSize: 15,
                                  }}
                                >
                                  <span
                                    style={{
                                      color: 'rgb(89, 89, 89)',
                                    }}
                                  >
                                    <span data-custom-class="body_text">
                                      <span
                                        style={{
                                          fontSize: 15,
                                        }}
                                      >
                                        <span
                                          style={{
                                            color: 'rgb(89, 89, 89)',
                                          }}
                                        >
                                          <span data-custom-class="body_text">
                                            <div
                                              id="bdt"
                                              className="statement-end-if-in-editor"
                                            />
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <div
            className="flex-col"
            style={{ lineHeight: '1.5' }}
          >
            <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
              <span
                style={{
                  fontSize: 15,
                  color: 'rgb(89, 89, 89)',
                }}
              >
                <span data-custom-class="body_text">
                  <div
                    id="bdt"
                    className="block-component"
                  />
                </span>
              </span>
            </span>
            <div
              className="flex-col"
              style={{ lineHeight: '1.5' }}
            >
              <span
                style={{
                  fontSize: 15,
                  color: 'rgb(89, 89, 89)',
                }}
              >
                <span
                  style={{
                    fontSize: 15,
                    color: 'rgb(89, 89, 89)',
                  }}
                >
                  <span data-custom-class="body_text">
                    <div
                      id="bdt"
                      className="block-component"
                    />
                  </span>
                </span>
              </span>
            </div>
            <ul>
              <li style={{ lineHeight: '1.5' }}>
                <span
                  style={{
                    fontSize: 15,
                    color: 'rgb(89, 89, 89)',
                  }}
                >
                  <span
                    style={{
                      fontSize: 15,
                      color: 'rgb(89, 89, 89)',
                    }}
                  >
                    <span data-custom-class="body_text">
                      <strong>To respond to user inquiries/offer support to users.&nbsp;</strong>
                      We may process your information to respond to your inquiries and solve any potential issues you
                      might have with the requested service.
                      <span
                        style={{
                          fontSize: 15,
                          color: 'rgb(89, 89, 89)',
                        }}
                      >
                        <span
                          style={{
                            fontSize: 15,
                            color: 'rgb(89, 89, 89)',
                          }}
                        >
                          <span data-custom-class="body_text">
                            <span
                              style={{
                                fontSize: 15,
                              }}
                            >
                              <span
                                style={{
                                  color: 'rgb(89, 89, 89)',
                                }}
                              >
                                <span data-custom-class="body_text">
                                  <span
                                    style={{
                                      fontSize: 15,
                                    }}
                                  >
                                    <span
                                      style={{
                                        color: 'rgb(89, 89, 89)',
                                      }}
                                    >
                                      <span data-custom-class="body_text">
                                        <div
                                          id="bdt"
                                          className="statement-end-if-in-editor"
                                        />
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
            </ul>
            <div
              className="flex-col"
              style={{ lineHeight: '1.5' }}
            >
              <span
                style={{
                  fontSize: 15,
                  color: 'rgb(89, 89, 89)',
                }}
              >
                <span
                  style={{
                    fontSize: 15,
                    color: 'rgb(89, 89, 89)',
                  }}
                >
                  <span data-custom-class="body_text">
                    <div
                      id="bdt"
                      className="block-component"
                    />
                  </span>
                </span>
              </span>
              <div
                className="flex-col"
                style={{ lineHeight: '1.5' }}
              >
                <div
                  id="bdt"
                  className="block-component"
                >
                  <span style={{ fontSize: 15 }} />
                </div>
                <div
                  className="flex-col"
                  style={{ lineHeight: '1.5' }}
                >
                  <div
                    id="bdt"
                    className="block-component"
                  >
                    <span style={{ fontSize: 15 }} />
                  </div>
                  <div
                    className="flex-col"
                    style={{ lineHeight: '1.5' }}
                  >
                    <div
                      id="bdt"
                      className="block-component"
                    >
                      <span style={{ fontSize: 15 }} />
                    </div>
                  </div>
                  <ul>
                    <li style={{ lineHeight: '1.5' }}>
                      <span
                        style={{
                          fontSize: 15,
                          color: 'rgb(89, 89, 89)',
                        }}
                      >
                        <span
                          style={{
                            fontSize: 15,
                            color: 'rgb(89, 89, 89)',
                          }}
                        >
                          <span data-custom-class="body_text">
                            <strong>
                              To{' '}
                              <div
                                id="bdt"
                                className="block-component"
                              />
                              fulfill
                              <div
                                id="bdt"
                                className="statement-end-if-in-editor"
                              />{' '}
                              and manage your orders.&nbsp;
                            </strong>
                            We may process your information to{' '}
                            <div
                              id="bdt"
                              className="block-component"
                            />
                            fulfill
                            <div
                              id="bdt"
                              className="statement-end-if-in-editor"
                            />{' '}
                            and manage your orders, payments, returns, and exchanges made through the Services.
                            <span
                              style={{
                                fontSize: 15,
                                color: 'rgb(89, 89, 89)',
                              }}
                            >
                              <span
                                style={{
                                  fontSize: 15,
                                  color: 'rgb(89, 89, 89)',
                                }}
                              >
                                <span data-custom-class="body_text">
                                  <span
                                    style={{
                                      fontSize: 15,
                                      color: 'rgb(89, 89, 89)',
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: 'rgb(89, 89, 89)',
                                      }}
                                    >
                                      <span data-custom-class="body_text">
                                        <span
                                          style={{
                                            fontSize: 15,
                                            color: 'rgb(89, 89, 89)',
                                          }}
                                        >
                                          <span
                                            style={{
                                              fontSize: 15,
                                              color: 'rgb(89, 89, 89)',
                                            }}
                                          >
                                            <span data-custom-class="body_text">
                                              <div
                                                id="bdt"
                                                className="statement-end-if-in-editor"
                                              />
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                  </ul>
                  <div
                    className="flex-col"
                    style={{ lineHeight: '1.5' }}
                  >
                    <div
                      id="bdt"
                      className="block-component"
                    >
                      <span style={{ fontSize: 15 }}>
                        <span data-custom-class="body_text" />
                      </span>
                    </div>
                    <p
                      style={{
                        fontSize: 15,
                        lineHeight: '1.5',
                      }}
                    >
                      <div
                        id="bdt"
                        className="block-component"
                      >
                        <span style={{ fontSize: 15 }} />
                      </div>
                    </p>
                    <ul>
                      <li style={{ lineHeight: '1.5' }}>
                        <span style={{ fontSize: 15 }}>
                          <span
                            style={{
                              color: 'rgb(89, 89, 89)',
                            }}
                          >
                            <span
                              style={{
                                color: 'rgb(89, 89, 89)',
                              }}
                            >
                              <span data-custom-class="body_text">
                                <strong>To enable user-to-user communications.&nbsp;</strong>
                                We may process your information if you choose to use any of our offerings that allow for
                                communication with another user.
                                <span
                                  style={{
                                    color: 'rgb(89, 89, 89)',
                                  }}
                                >
                                  <span
                                    style={{
                                      color: 'rgb(89, 89, 89)',
                                    }}
                                  >
                                    <span data-custom-class="body_text">
                                      <span
                                        style={{
                                          color: 'rgb(89, 89, 89)',
                                        }}
                                      >
                                        <span data-custom-class="body_text">
                                          <span
                                            style={{
                                              color: 'rgb(89, 89, 89)',
                                            }}
                                          >
                                            <span data-custom-class="body_text">
                                              <div
                                                id="bdt"
                                                className="statement-end-if-in-editor"
                                              />
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </li>
                    </ul>
                    <p
                      style={{
                        fontSize: 15,
                        lineHeight: '1.5',
                      }}
                    >
                      <div
                        id="bdt"
                        className="block-component"
                      >
                        <span style={{ fontSize: 15 }} />
                      </div>
                    </p>
                    <p
                      style={{
                        fontSize: 15,
                        lineHeight: '1.5',
                      }}
                    >
                      <div
                        id="bdt"
                        className="block-component"
                      />
                    </p>
                    <p
                      style={{
                        fontSize: 15,
                        lineHeight: '1.5',
                      }}
                    >
                      <div
                        id="bdt"
                        className="block-component"
                      />
                    </p>
                    <div
                      className="flex-col"
                      style={{ lineHeight: '1.5' }}
                    >
                      <div
                        id="bdt"
                        className="block-component"
                      >
                        <span style={{ fontSize: 15 }} />
                      </div>
                    </div>
                    <ul>
                      <li style={{ lineHeight: '1.5' }}>
                        <span
                          style={{
                            fontSize: 15,
                            color: 'rgb(89, 89, 89)',
                          }}
                        >
                          <span
                            style={{
                              fontSize: 15,
                              color: 'rgb(89, 89, 89)',
                            }}
                          >
                            <span data-custom-class="body_text">
                              <strong>To send you marketing and promotional communications.&nbsp;</strong>
                              We may process the personal information you send to us for our marketing purposes, if this
                              is in accordance with your marketing preferences. You can opt out of our marketing emails
                              at any time. For more information, see{' '}
                              <div
                                id="bdt"
                                className="block-component"
                              />
                              "
                              <div
                                id="bdt"
                                className="statement-end-if-in-editor"
                              />
                            </span>
                          </span>
                        </span>
                        <a
                          data-custom-class="link"
                          href="#privacyrights"
                        >
                          <span
                            style={{
                              fontSize: 15,
                              color: 'rgb(89, 89, 89)',
                            }}
                          >
                            <span
                              style={{
                                fontSize: 15,
                                color: 'rgb(89, 89, 89)',
                              }}
                            >
                              <span data-custom-class="body_text">WHAT ARE YOUR PRIVACY RIGHTS?</span>
                            </span>
                          </span>
                        </a>
                        <span
                          style={{
                            fontSize: 15,
                            color: 'rgb(89, 89, 89)',
                          }}
                        >
                          <span
                            style={{
                              fontSize: 15,
                              color: 'rgb(89, 89, 89)',
                            }}
                          >
                            <span data-custom-class="body_text">
                              <div
                                id="bdt"
                                className="block-component"
                              />
                              "
                              <div
                                id="bdt"
                                className="statement-end-if-in-editor"
                              />{' '}
                              below).
                              <span
                                style={{
                                  fontSize: 15,
                                  color: 'rgb(89, 89, 89)',
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: 15,
                                    color: 'rgb(89, 89, 89)',
                                  }}
                                >
                                  <span data-custom-class="body_text">
                                    <span
                                      style={{
                                        fontSize: 15,
                                      }}
                                    >
                                      <span
                                        style={{
                                          color: 'rgb(89, 89, 89)',
                                        }}
                                      >
                                        <span data-custom-class="body_text">
                                          <span
                                            style={{
                                              fontSize: 15,
                                            }}
                                          >
                                            <span
                                              style={{
                                                color: 'rgb(89, 89, 89)',
                                              }}
                                            >
                                              <span data-custom-class="body_text">
                                                <div
                                                  id="bdt"
                                                  className="statement-end-if-in-editor"
                                                />
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </li>
                    </ul>
                    <div
                      className="flex-col"
                      style={{ lineHeight: '1.5' }}
                    >
                      <div
                        id="bdt"
                        className="block-component"
                      >
                        <span style={{ fontSize: 15 }} />
                      </div>
                      <div
                        className="flex-col"
                        style={{ lineHeight: '1.5' }}
                      >
                        <div
                          id="bdt"
                          className="block-component"
                        >
                          <span style={{ fontSize: 15 }} />
                        </div>
                        <div
                          className="flex-col"
                          style={{
                            lineHeight: '1.5',
                          }}
                        >
                          <span style={{ fontSize: 15 }}>
                            <div
                              id="bdt"
                              className="block-component"
                            >
                              <span data-custom-class="body_text" />
                            </div>
                          </span>
                          <div
                            className="flex-col"
                            style={{
                              lineHeight: '1.5',
                            }}
                          >
                            <div
                              id="bdt"
                              className="block-component"
                            >
                              <span
                                style={{
                                  fontSize: 15,
                                }}
                              >
                                <span data-custom-class="body_text" />
                              </span>
                            </div>
                            <div
                              className="flex-col"
                              style={{
                                lineHeight: '1.5',
                              }}
                            >
                              <div
                                id="bdt"
                                className="block-component"
                              >
                                <span
                                  style={{
                                    fontSize: 15,
                                  }}
                                >
                                  <span data-custom-class="body_text" />
                                </span>
                              </div>
                              <div
                                className="flex-col"
                                style={{
                                  lineHeight: '1.5',
                                }}
                              >
                                <div
                                  id="bdt"
                                  className="block-component"
                                >
                                  <span
                                    style={{
                                      fontSize: 15,
                                    }}
                                  >
                                    <span data-custom-class="body_text" />
                                  </span>
                                </div>
                                <div
                                  className="flex-col"
                                  style={{
                                    lineHeight: '1.5',
                                  }}
                                >
                                  <div
                                    id="bdt"
                                    className="block-component"
                                  >
                                    <span
                                      style={{
                                        fontSize: 15,
                                      }}
                                    >
                                      <span data-custom-class="body_text" />
                                    </span>
                                  </div>
                                  <div
                                    className="flex-col"
                                    style={{
                                      lineHeight: '1.5',
                                    }}
                                  >
                                    <div
                                      id="bdt"
                                      className="block-component"
                                    >
                                      <span
                                        style={{
                                          fontSize: 15,
                                        }}
                                      >
                                        <span data-custom-class="body_text" />
                                      </span>
                                    </div>
                                  </div>
                                  <ul>
                                    <li
                                      style={{
                                        lineHeight: '1.5',
                                      }}
                                    >
                                      <span data-custom-class="body_text">
                                        <span
                                          style={{
                                            fontSize: 15,
                                          }}
                                        >
                                          <strong>
                                            To evaluate and improve our Services, products, marketing, and your
                                            experience.
                                          </strong>{' '}
                                          We may process your information when we believe it is necessary to identify
                                          usage trends, determine the effectiveness of our promotional campaigns, and to
                                          evaluate and improve our Services, products, marketing, and your experience.
                                        </span>
                                      </span>
                                      <div
                                        id="bdt"
                                        className="statement-end-if-in-editor"
                                      >
                                        <span
                                          style={{
                                            fontSize: 15,
                                          }}
                                        >
                                          <span data-custom-class="body_text" />
                                        </span>
                                      </div>
                                    </li>
                                  </ul>
                                  <div
                                    className="flex-col"
                                    style={{
                                      lineHeight: '1.5',
                                    }}
                                  >
                                    <div
                                      id="bdt"
                                      className="block-component"
                                    >
                                      <span
                                        style={{
                                          fontSize: 15,
                                        }}
                                      >
                                        <span data-custom-class="body_text" />
                                      </span>
                                    </div>
                                  </div>
                                  <ul>
                                    <li
                                      style={{
                                        lineHeight: '1.5',
                                      }}
                                    >
                                      <span data-custom-class="body_text">
                                        <span
                                          style={{
                                            fontSize: 15,
                                          }}
                                        >
                                          <strong>To identify usage trends.</strong> We may process information about
                                          how you use our Services to better understand how they are being used so we
                                          can improve them.
                                        </span>
                                      </span>
                                      <div
                                        id="bdt"
                                        className="statement-end-if-in-editor"
                                      >
                                        <span
                                          style={{
                                            fontSize: 15,
                                          }}
                                        >
                                          <span data-custom-class="body_text" />
                                        </span>
                                      </div>
                                    </li>
                                  </ul>
                                  <div
                                    className="flex-col"
                                    style={{
                                      lineHeight: '1.5',
                                    }}
                                  >
                                    <div
                                      id="bdt"
                                      className="block-component"
                                    >
                                      <span
                                        style={{
                                          fontSize: 15,
                                        }}
                                      >
                                        <span data-custom-class="body_text" />
                                      </span>
                                    </div>
                                    <div
                                      className="flex-col"
                                      style={{
                                        lineHeight: '1.5',
                                      }}
                                    >
                                      <div
                                        id="bdt"
                                        className="block-component"
                                      >
                                        <span
                                          style={{
                                            fontSize: 15,
                                          }}
                                        >
                                          <span data-custom-class="body_text" />
                                        </span>
                                      </div>
                                      <div
                                        className="flex-col"
                                        style={{
                                          lineHeight: '1.5',
                                        }}
                                      >
                                        <div
                                          id="bdt"
                                          className="block-component"
                                        >
                                          <span
                                            style={{
                                              fontSize: 15,
                                            }}
                                          >
                                            <span data-custom-class="body_text" />
                                          </span>
                                        </div>
                                        <div
                                          className="flex-col"
                                          style={{
                                            lineHeight: '1.5',
                                          }}
                                        >
                                          <div
                                            id="bdt"
                                            className="block-component"
                                          >
                                            <span
                                              style={{
                                                fontSize: 15,
                                              }}
                                            >
                                              <span data-custom-class="body_text" />
                                            </span>
                                          </div>
                                          <div
                                            className="flex-col"
                                            style={{
                                              lineHeight: '1.5',
                                            }}
                                          >
                                            <div
                                              id="bdt"
                                              className="block-component"
                                            >
                                              <span
                                                style={{
                                                  fontSize: 15,
                                                }}
                                              >
                                                <span data-custom-class="body_text" />
                                              </span>
                                            </div>
                                            <div
                                              className="flex-col"
                                              style={{
                                                lineHeight: '1.5',
                                              }}
                                            >
                                              <div
                                                id="bdt"
                                                className="block-component"
                                              >
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                  }}
                                                >
                                                  <span data-custom-class="body_text" />
                                                </span>
                                              </div>
                                              <div
                                                id="bdt"
                                                className="block-component"
                                              >
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                  }}
                                                >
                                                  <span data-custom-class="body_text" />
                                                </span>
                                              </div>
                                              <div
                                                id="bdt"
                                                className="block-component"
                                              >
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                  }}
                                                >
                                                  <span data-custom-class="body_text" />
                                                </span>
                                              </div>
                                              <div
                                                id="bdt"
                                                className="block-component"
                                              >
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                  }}
                                                >
                                                  <span data-custom-class="body_text" />
                                                </span>
                                              </div>
                                            </div>
                                            <div
                                              className="flex-col"
                                              style={{
                                                lineHeight: '1.5',
                                              }}
                                            >
                                              <br />
                                            </div>
                                            <div
                                              className="flex-col"
                                              id="whoshare"
                                              style={{
                                                lineHeight: '1.5',
                                              }}
                                            >
                                              <span
                                                style={{
                                                  color: 'rgb(127, 127, 127)',
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    color: 'rgb(89, 89, 89)',
                                                    fontSize: 15,
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: 'rgb(89, 89, 89)',
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: 'rgb(89, 89, 89)',
                                                      }}
                                                    >
                                                      <span
                                                        id="control"
                                                        style={{
                                                          color: 'rgb(0, 0, 0)',
                                                        }}
                                                      >
                                                        <strong>
                                                          <span data-custom-class="heading_1">
                                                            3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                                                          </span>
                                                        </strong>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </div>
                                            <div
                                              className="flex-col"
                                              style={{
                                                lineHeight: '1.5',
                                              }}
                                            >
                                              <br />
                                            </div>
                                            <div
                                              className="flex-col"
                                              style={{
                                                lineHeight: '1.5',
                                              }}
                                            >
                                              <span
                                                style={{
                                                  fontSize: 15,
                                                  color: 'rgb(89, 89, 89)',
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: 'rgb(89, 89, 89)',
                                                  }}
                                                >
                                                  <span data-custom-class="body_text">
                                                    <strong>
                                                      <em>In Short:</em>
                                                    </strong>
                                                    <em>
                                                      &nbsp;We may share information in specific situations described in
                                                      this section and/or with the following{' '}
                                                      <div
                                                        id="bdt"
                                                        className="block-component"
                                                      />
                                                      third parties.
                                                    </em>
                                                  </span>
                                                </span>
                                              </span>
                                            </div>
                                            <div
                                              className="flex-col"
                                              style={{
                                                lineHeight: '1.5',
                                              }}
                                            >
                                              <span
                                                style={{
                                                  fontSize: 15,
                                                  color: 'rgb(89, 89, 89)',
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: 'rgb(89, 89, 89)',
                                                  }}
                                                >
                                                  <span data-custom-class="body_text">
                                                    <div
                                                      id="bdt"
                                                      className="block-component"
                                                    />
                                                  </span>
                                                </span>
                                              </span>
                                            </div>
                                            <div
                                              className="flex-col"
                                              style={{
                                                lineHeight: '1.5',
                                              }}
                                            >
                                              <br />
                                            </div>
                                            <div
                                              className="flex-col"
                                              style={{
                                                lineHeight: '1.5',
                                              }}
                                            >
                                              <span
                                                style={{
                                                  fontSize: 15,
                                                }}
                                              >
                                                <span data-custom-class="body_text">
                                                  We{' '}
                                                  <div
                                                    id="bdt"
                                                    className="block-component"
                                                  />
                                                  may need to share your personal information in the following
                                                  situations:
                                                </span>
                                              </span>
                                            </div>
                                            <ul>
                                              <li
                                                style={{
                                                  lineHeight: '1.5',
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                  }}
                                                >
                                                  <span data-custom-class="body_text">
                                                    <strong>Business Transfers.</strong> We may share or transfer your
                                                    information in connection with, or during negotiations of, any
                                                    merger, sale of company assets, financing, or acquisition of all or
                                                    a portion of our business to another company.
                                                  </span>
                                                </span>
                                              </li>
                                            </ul>
                                            <div
                                              className="flex-col"
                                              style={{
                                                lineHeight: '1.5',
                                              }}
                                            >
                                              <span
                                                style={{
                                                  fontSize: 15,
                                                }}
                                              >
                                                <span data-custom-class="body_text">
                                                  <div
                                                    id="bdt"
                                                    className="block-component"
                                                  />
                                                </span>
                                              </span>
                                            </div>
                                            <ul>
                                              <li
                                                style={{
                                                  lineHeight: '1.5',
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                  }}
                                                >
                                                  <span data-custom-class="body_text">
                                                    <strong>When we use Google Maps Platform APIs.</strong> We may share
                                                    your information with certain Google Maps Platform APIs (e.g.
                                                    <div
                                                      id="bdt"
                                                      className="block-component"
                                                    />
                                                    ,
                                                    <div
                                                      id="bdt"
                                                      className="statement-end-if-in-editor"
                                                    />{' '}
                                                    Google Maps API, Places API). To find out more about Google's
                                                    Privacy Policy, please refer to this&nbsp;
                                                  </span>
                                                </span>
                                                <a
                                                  data-custom-class="link"
                                                  href="https://policies.google.com/privacy"
                                                  rel="noopener noreferrer"
                                                  target="_blank"
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                    }}
                                                  >
                                                    <span data-custom-class="body_text">link</span>
                                                  </span>
                                                </a>
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                  }}
                                                >
                                                  <span data-custom-class="body_text">
                                                    .
                                                    <div
                                                      id="bdt"
                                                      className="block-component"
                                                    />
                                                    <div
                                                      id="bdt"
                                                      className="block-component"
                                                    />{' '}
                                                    We obtain and store on your device (
                                                    <div
                                                      id="bdt"
                                                      className="block-component"
                                                    />
                                                    "cache"
                                                    <div
                                                      id="bdt"
                                                      className="statement-end-if-in-editor"
                                                    />
                                                    ) your location
                                                    <div
                                                      id="bdt"
                                                      className="block-component"
                                                    />
                                                    . You may revoke your consent anytime by contacting us at the
                                                    contact details provided at the end of this document.
                                                    <div
                                                      id="bdt"
                                                      className="statement-end-if-in-editor"
                                                    />
                                                    <div
                                                      id="bdt"
                                                      className="block-component"
                                                    />
                                                  </span>
                                                </span>
                                              </li>
                                            </ul>
                                            <div
                                              className="flex-col"
                                              style={{
                                                lineHeight: '1.5',
                                              }}
                                            >
                                              <span
                                                style={{
                                                  fontSize: 15,
                                                }}
                                              >
                                                <div
                                                  id="bdt"
                                                  className="block-component"
                                                >
                                                  <span data-custom-class="body_text" />
                                                </div>
                                              </span>
                                              <div
                                                className="flex-col"
                                                style={{
                                                  lineHeight: '1.5',
                                                }}
                                              >
                                                <div
                                                  id="bdt"
                                                  className="block-component"
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                    }}
                                                  >
                                                    <span data-custom-class="body_text" />
                                                  </span>
                                                </div>
                                                <div
                                                  className="flex-col"
                                                  style={{
                                                    lineHeight: '1.5',
                                                  }}
                                                >
                                                  <div
                                                    id="bdt"
                                                    className="block-component"
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text" />
                                                    </span>
                                                  </div>
                                                </div>
                                                <ul>
                                                  <li
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <strong>Other Users.</strong> When you share personal
                                                        information
                                                        <div
                                                          id="bdt"
                                                          className="block-component"
                                                        />{' '}
                                                        (for example, by posting comments, contributions, or other
                                                        content to the Services)
                                                        <div
                                                          id="bdt"
                                                          className="statement-end-if-in-editor"
                                                        />{' '}
                                                        or otherwise interact with public areas of the Services, such
                                                        personal information may be viewed by all users and may be
                                                        publicly made available outside the Services in perpetuity.
                                                        <div
                                                          id="bdt"
                                                          className="block-component"
                                                        />{' '}
                                                        If you interact with other users of our Services and register
                                                        for our Services through a social network (such as Facebook),
                                                        your contacts on the social network will see your name, profile
                                                        photo, and descriptions of your activity.
                                                        <div
                                                          id="bdt"
                                                          className="statement-end-if-in-editor"
                                                        />{' '}
                                                        Similarly, other users will be able to view descriptions of your
                                                        activity, communicate with you within our Services, and view
                                                        your profile.
                                                        <div
                                                          id="bdt"
                                                          className="statement-end-if-in-editor"
                                                        />
                                                      </span>
                                                    </span>
                                                  </li>
                                                </ul>
                                                <div
                                                  className="flex-col"
                                                  style={{
                                                    lineHeight: '1.5',
                                                  }}
                                                >
                                                  <div
                                                    id="bdt"
                                                    className="block-component"
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text" />
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <div
                                                      id="bdt"
                                                      className="block-component"
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text" />
                                                      </span>
                                                    </div>
                                                    <span
                                                      style={{
                                                        color: 'rgb(89, 89, 89)',
                                                        fontSize: 15,
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            color: 'rgb(89, 89, 89)',
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <div
                                                                id="bdt"
                                                                className="block-component"
                                                              >
                                                                <span data-custom-class="heading_1" />
                                                              </div>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    id="cookies"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color: 'rgb(127, 127, 127)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          color: 'rgb(89, 89, 89)',
                                                          fontSize: 15,
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color: 'rgb(89, 89, 89)',
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color: 'rgb(89, 89, 89)',
                                                            }}
                                                          >
                                                            <span
                                                              id="control"
                                                              style={{
                                                                color: 'rgb(0, 0, 0)',
                                                              }}
                                                            >
                                                              <strong>
                                                                <span data-custom-class="heading_1">
                                                                  4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                                                                </span>
                                                              </strong>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: 'rgb(89, 89, 89)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <strong>
                                                            <em>In Short:</em>
                                                          </strong>
                                                          <em>
                                                            &nbsp;We may use cookies and other tracking technologies to
                                                            collect and store your information.
                                                          </em>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: 'rgb(89, 89, 89)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          We may use cookies and similar tracking technologies (like web
                                                          beacons and pixels) to access or store information. Specific
                                                          information about how we use such technologies and how you can
                                                          refuse certain cookies is set out in our Cookie Notice
                                                          <span
                                                            style={{
                                                              color: 'rgb(89, 89, 89)',
                                                              fontSize: 15,
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <div
                                                                id="bdt"
                                                                className="block-component"
                                                              />
                                                              .
                                                            </span>
                                                            <span
                                                              style={{
                                                                color: 'rgb(89, 89, 89)',
                                                                fontSize: 15,
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    color: 'rgb(89, 89, 89)',
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color: 'rgb(89, 89, 89)',
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <div
                                                                          id="bdt"
                                                                          className="statement-end-if-in-editor"
                                                                        />
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                        <span data-custom-class="body_text">
                                                          <span
                                                            style={{
                                                              color: 'rgb(89, 89, 89)',
                                                              fontSize: 15,
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color: 'rgb(89, 89, 89)',
                                                                fontSize: 15,
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color: 'rgb(89, 89, 89)',
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color: 'rgb(89, 89, 89)',
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          color: 'rgb(89, 89, 89)',
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <div
                                                                            id="bdt"
                                                                            className="block-component"
                                                                          />
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    id="sociallogins"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color: 'rgb(127, 127, 127)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          color: 'rgb(89, 89, 89)',
                                                          fontSize: 15,
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color: 'rgb(89, 89, 89)',
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color: 'rgb(89, 89, 89)',
                                                            }}
                                                          >
                                                            <span
                                                              id="control"
                                                              style={{
                                                                color: 'rgb(0, 0, 0)',
                                                              }}
                                                            >
                                                              <strong>
                                                                <span data-custom-class="heading_1">
                                                                  5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                                                                </span>
                                                              </strong>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: 'rgb(89, 89, 89)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <strong>
                                                            <em>In Short:&nbsp;</em>
                                                          </strong>
                                                          <em>
                                                            If you choose to register or log in to our Services using a
                                                            social media account, we may have access to certain
                                                            information about you.
                                                          </em>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: 'rgb(89, 89, 89)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          Our Services offer you the ability to register and log in
                                                          using your third-party social media account details (like your
                                                          Facebook or Twitter logins). Where you choose to do this, we
                                                          will receive certain profile information about you from your
                                                          social media provider. The profile information we receive may
                                                          vary depending on the social media provider concerned, but
                                                          will often include your name, email address, friends list, and
                                                          profile picture, as well as other information you choose to
                                                          make public on such a social media platform.{' '}
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <div
                                                                  id="bdt"
                                                                  className="block-component"
                                                                />
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: 'rgb(89, 89, 89)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          We will use the information we receive only for the purposes
                                                          that are described in this privacy notice or that are
                                                          otherwise made clear to you on the relevant Services. Please
                                                          note that we do not control, and are not responsible for,
                                                          other uses of your personal information by your third-party
                                                          social media provider. We recommend that you review their
                                                          privacy notice to understand how they collect, use, and share
                                                          your personal information, and how you can set your privacy
                                                          preferences on their sites and apps.
                                                          <span
                                                            style={{
                                                              color: 'rgb(89, 89, 89)',
                                                              fontSize: 15,
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color: 'rgb(89, 89, 89)',
                                                                fontSize: 15,
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color: 'rgb(89, 89, 89)',
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    color: 'rgb(89, 89, 89)',
                                                                    fontSize: 15,
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color: 'rgb(89, 89, 89)',
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            color: 'rgb(89, 89, 89)',
                                                                          }}
                                                                        >
                                                                          <span data-custom-class="body_text">
                                                                            <div
                                                                              id="bdt"
                                                                              className="statement-end-if-in-editor"
                                                                            />
                                                                          </span>
                                                                          <div
                                                                            id="bdt"
                                                                            className="block-component"
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              <div
                                                                                id="bdt"
                                                                                className="block-component"
                                                                              />
                                                                            </span>
                                                                          </div>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    id="inforetain"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color: 'rgb(127, 127, 127)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          color: 'rgb(89, 89, 89)',
                                                          fontSize: 15,
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color: 'rgb(89, 89, 89)',
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color: 'rgb(89, 89, 89)',
                                                            }}
                                                          >
                                                            <span
                                                              id="control"
                                                              style={{
                                                                color: 'rgb(0, 0, 0)',
                                                              }}
                                                            >
                                                              <strong>
                                                                <span data-custom-class="heading_1">
                                                                  6. HOW LONG DO WE KEEP YOUR INFORMATION?
                                                                </span>
                                                              </strong>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: 'rgb(89, 89, 89)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <strong>
                                                            <em>In Short:&nbsp;</em>
                                                          </strong>
                                                          <em>
                                                            We keep your information for as long as necessary to{' '}
                                                            <div
                                                              id="bdt"
                                                              className="block-component"
                                                            />
                                                            fulfill
                                                            <div
                                                              id="bdt"
                                                              className="statement-end-if-in-editor"
                                                            />{' '}
                                                            the purposes outlined in this privacy notice unless
                                                            otherwise required by law.
                                                          </em>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: 'rgb(89, 89, 89)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          We will only keep your personal information for as long as it
                                                          is necessary for the purposes set out in this privacy notice,
                                                          unless a longer retention period is required or permitted by
                                                          law (such as tax, accounting, or other legal requirements).
                                                          <div
                                                            id="bdt"
                                                            className="block-component"
                                                          />{' '}
                                                          No purpose in this notice will require us keeping your
                                                          personal information for longer than{' '}
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <div
                                                                  id="bdt"
                                                                  className="block-component"
                                                                />
                                                              </span>
                                                            </span>
                                                          </span>
                                                          <div
                                                            id="bdt"
                                                            className="block-component"
                                                          />
                                                          the period of time in which users have an account with us
                                                          <div
                                                            id="bdt"
                                                            className="block-component"
                                                          />
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <div
                                                                  id="bdt"
                                                                  className="else-block"
                                                                />
                                                              </span>
                                                            </span>
                                                          </span>
                                                          .
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: 'rgb(89, 89, 89)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          When we have no ongoing legitimate business need to process
                                                          your personal information, we will either delete or{' '}
                                                          <div
                                                            id="bdt"
                                                            className="block-component"
                                                          />
                                                          anonymize
                                                          <div
                                                            id="bdt"
                                                            className="statement-end-if-in-editor"
                                                          />{' '}
                                                          such information, or, if this is not possible (for example,
                                                          because your personal information has been stored in backup
                                                          archives), then we will securely store your personal
                                                          information and isolate it from any further processing until
                                                          deletion is possible.
                                                          <span
                                                            style={{
                                                              color: 'rgb(89, 89, 89)',
                                                            }}
                                                          >
                                                            <div
                                                              id="bdt"
                                                              className="block-component"
                                                            />
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    id="infosafe"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color: 'rgb(127, 127, 127)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          color: 'rgb(89, 89, 89)',
                                                          fontSize: 15,
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color: 'rgb(89, 89, 89)',
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color: 'rgb(89, 89, 89)',
                                                            }}
                                                          >
                                                            <span
                                                              id="control"
                                                              style={{
                                                                color: 'rgb(0, 0, 0)',
                                                              }}
                                                            >
                                                              <strong>
                                                                <span data-custom-class="heading_1">
                                                                  7. HOW DO WE KEEP YOUR INFORMATION SAFE?
                                                                </span>
                                                              </strong>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: 'rgb(89, 89, 89)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <strong>
                                                            <em>In Short:&nbsp;</em>
                                                          </strong>
                                                          <em>
                                                            We aim to protect your personal information through a system
                                                            of{' '}
                                                            <div
                                                              id="bdt"
                                                              className="block-component"
                                                            />
                                                            organizational
                                                            <div
                                                              id="bdt"
                                                              className="statement-end-if-in-editor"
                                                            />{' '}
                                                            and technical security measures.
                                                          </em>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: 'rgb(89, 89, 89)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          We have implemented appropriate and reasonable technical and{' '}
                                                          <div
                                                            id="bdt"
                                                            className="block-component"
                                                          />
                                                          organizational
                                                          <div
                                                            id="bdt"
                                                            className="statement-end-if-in-editor"
                                                          />{' '}
                                                          security measures designed to protect the security of any
                                                          personal information we process. However, despite our
                                                          safeguards and efforts to secure your information, no
                                                          electronic transmission over the Internet or information
                                                          storage technology can be guaranteed to be 100% secure, so we
                                                          cannot promise or guarantee that hackers, cybercriminals, or
                                                          other{' '}
                                                          <div
                                                            id="bdt"
                                                            className="block-component"
                                                          />
                                                          unauthorized
                                                          <div
                                                            id="bdt"
                                                            className="statement-end-if-in-editor"
                                                          />{' '}
                                                          third parties will not be able to defeat our security and
                                                          improperly collect, access, steal, or modify your information.
                                                          Although we will do our best to protect your personal
                                                          information, transmission of personal information to and from
                                                          our Services is at your own risk. You should only access the
                                                          Services within a secure environment.
                                                          <span
                                                            style={{
                                                              color: 'rgb(89, 89, 89)',
                                                            }}
                                                          >
                                                            <div
                                                              id="bdt"
                                                              className="statement-end-if-in-editor"
                                                            />
                                                          </span>
                                                          <span
                                                            style={{
                                                              color: 'rgb(89, 89, 89)',
                                                              fontSize: 15,
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <div
                                                                id="bdt"
                                                                className="block-component"
                                                              />
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    id="privacyrights"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color: 'rgb(127, 127, 127)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          color: 'rgb(89, 89, 89)',
                                                          fontSize: 15,
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color: 'rgb(89, 89, 89)',
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color: 'rgb(89, 89, 89)',
                                                            }}
                                                          >
                                                            <span
                                                              id="control"
                                                              style={{
                                                                color: 'rgb(0, 0, 0)',
                                                              }}
                                                            >
                                                              <strong>
                                                                <span data-custom-class="heading_1">
                                                                  8. WHAT ARE YOUR PRIVACY RIGHTS?
                                                                </span>
                                                              </strong>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: 'rgb(89, 89, 89)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <strong>
                                                            <em>In Short:</em>
                                                          </strong>
                                                          <em>
                                                            &nbsp;
                                                            <span
                                                              style={{
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <em>
                                                                    <div
                                                                      id="bdt"
                                                                      className="block-component"
                                                                    />
                                                                  </em>
                                                                </span>
                                                              </span>
                                                              &nbsp;
                                                            </span>
                                                            You may review, change, or terminate your account at any
                                                            time.
                                                          </em>
                                                          <span
                                                            style={{
                                                              color: 'rgb(89, 89, 89)',
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                              }}
                                                            >
                                                              <div
                                                                id="bdt"
                                                                className="block-component"
                                                              />
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: 'rgb(89, 89, 89)',
                                                      }}
                                                    >
                                                      &nbsp;
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: 'rgb(89, 89, 89)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          If you are located in the EEA or UK and you believe we are
                                                          unlawfully processing your personal information, you also have
                                                          the right to complain to your local data protection
                                                          supervisory authority. You can find their contact details
                                                          here:{' '}
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <span
                                                                  style={{
                                                                    color: 'rgb(48, 48, 241)',
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <a
                                                                      data-custom-class="link"
                                                                      href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                                                                      rel="noopener noreferrer"
                                                                      target="_blank"
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                        }}
                                                                      >
                                                                        https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
                                                                      </span>
                                                                    </a>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                          .
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: 'rgb(89, 89, 89)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          If you are located in Switzerland, the contact details for the
                                                          data protection authorities are available here:{' '}
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <span
                                                                  style={{
                                                                    color: 'rgb(48, 48, 241)',
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}
                                                                    >
                                                                      <a
                                                                        data-custom-class="link"
                                                                        href="https://www.edoeb.admin.ch/edoeb/en/home.html"
                                                                        rel="noopener noreferrer"
                                                                        target="_blank"
                                                                      >
                                                                        https://www.edoeb.admin.ch/edoeb/en/home.html
                                                                      </a>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                          .
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    id="withdrawconsent"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: 'rgb(89, 89, 89)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <strong>
                                                            <u>Withdrawing your consent:</u>
                                                          </strong>{' '}
                                                          If we are relying on your consent to process your personal
                                                          information,
                                                          <div
                                                            id="bdt"
                                                            className="block-component"
                                                          />{' '}
                                                          which may be express and/or implied consent depending on the
                                                          applicable law,
                                                          <div
                                                            id="bdt"
                                                            className="statement-end-if-in-editor"
                                                          />{' '}
                                                          you have the right to withdraw your consent at any time. You
                                                          can withdraw your consent at any time by contacting us by
                                                          using the contact details provided in the section{' '}
                                                          <div
                                                            id="bdt"
                                                            className="block-component"
                                                          />
                                                          "
                                                          <div
                                                            id="bdt"
                                                            className="statement-end-if-in-editor"
                                                          />
                                                        </span>
                                                      </span>
                                                    </span>
                                                    <a
                                                      data-custom-class="link"
                                                      href="#contact"
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color: 'rgb(89, 89, 89)',
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </a>
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: 'rgb(89, 89, 89)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <div
                                                            id="bdt"
                                                            className="block-component"
                                                          />
                                                          "
                                                          <div
                                                            id="bdt"
                                                            className="statement-end-if-in-editor"
                                                          />{' '}
                                                          below
                                                          <div
                                                            id="bdt"
                                                            className="block-component"
                                                          />
                                                          .
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        However, please note that this will not affect the lawfulness of
                                                        the processing before its withdrawal nor,
                                                        <div
                                                          id="bdt"
                                                          className="block-component"
                                                        />{' '}
                                                        when applicable law allows,
                                                        <div
                                                          id="bdt"
                                                          className="statement-end-if-in-editor"
                                                        />{' '}
                                                        will it affect the processing of your personal information
                                                        conducted in reliance on lawful processing grounds other than
                                                        consent.
                                                        <div
                                                          id="bdt"
                                                          className="block-component"
                                                        />
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <strong>
                                                          <u>Opting out of marketing and promotional communications:</u>
                                                        </strong>
                                                        <strong>
                                                          <u>&nbsp;</u>
                                                        </strong>
                                                        You can unsubscribe from our marketing and promotional
                                                        communications at any time by
                                                        <div
                                                          id="bdt"
                                                          className="block-component"
                                                        />{' '}
                                                        clicking on the unsubscribe link in the emails that we send,
                                                        <div
                                                          id="bdt"
                                                          className="statement-end-if-in-editor"
                                                        />
                                                        <div
                                                          id="bdt"
                                                          className="block-component"
                                                        />
                                                        <div
                                                          id="bdt"
                                                          className="block-component"
                                                        />{' '}
                                                        or by contacting us using the details provided in the section{' '}
                                                        <div
                                                          id="bdt"
                                                          className="block-component"
                                                        />
                                                        "
                                                        <div
                                                          id="bdt"
                                                          className="statement-end-if-in-editor"
                                                        />
                                                      </span>
                                                    </span>
                                                    <a
                                                      data-custom-class="link"
                                                      href="#contact"
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                                                        </span>
                                                      </span>
                                                    </a>
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <div
                                                          id="bdt"
                                                          className="block-component"
                                                        />
                                                        "
                                                        <div
                                                          id="bdt"
                                                          className="statement-end-if-in-editor"
                                                        />{' '}
                                                        below. You will then be removed from the marketing lists.
                                                        However, we may still communicate with you — for example, to
                                                        send you service-related messages that are necessary for the
                                                        administration and use of your account, to respond to service
                                                        requests, or for other non-marketing purposes.
                                                      </span>
                                                      <span data-custom-class="body_text">
                                                        <div
                                                          id="bdt"
                                                          className="statement-end-if-in-editor"
                                                        />
                                                      </span>
                                                    </span>
                                                    <div
                                                      id="bdt"
                                                      className="block-component"
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text" />
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                      }}
                                                    >
                                                      <span data-custom-class="heading_2">
                                                        <strong>Account Information</strong>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span data-custom-class="body_text">
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                        }}
                                                      >
                                                        If you would at any time like to review or change the
                                                        information in your account or terminate your account, you can:
                                                        <div
                                                          id="bdt"
                                                          className="forloop-component"
                                                        />
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <ul>
                                                    <li
                                                      style={{
                                                        lineHeight: '1.5',
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <div
                                                            id="bdt"
                                                            className="question"
                                                          >
                                                            Log in to your account settings and update your user
                                                            account.
                                                          </div>
                                                        </span>
                                                      </span>
                                                    </li>
                                                  </ul>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span data-custom-class="body_text">
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                        }}
                                                      >
                                                        <div
                                                          id="bdt"
                                                          className="forloop-component"
                                                        />
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <ul>
                                                    <li
                                                      style={{
                                                        lineHeight: '1.5',
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <div
                                                            id="bdt"
                                                            className="question"
                                                          >
                                                            Contact us using the contact information provided.
                                                          </div>
                                                        </span>
                                                      </span>
                                                    </li>
                                                  </ul>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span data-custom-class="body_text">
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                        }}
                                                      >
                                                        <div
                                                          id="bdt"
                                                          className="forloop-component"
                                                        />
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        Upon your request to terminate your account, we will deactivate
                                                        or delete your account and information from our active
                                                        databases. However, we may retain some information in our files
                                                        to prevent fraud, troubleshoot problems, assist with any
                                                        investigations, enforce our legal terms and/or comply with
                                                        applicable legal requirements.
                                                      </span>
                                                    </span>
                                                    <div
                                                      id="bdt"
                                                      className="statement-end-if-in-editor"
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text" />
                                                      </span>
                                                    </div>
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: 'rgb(89, 89, 89)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    color: 'rgb(89, 89, 89)',
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          color: 'rgb(89, 89, 89)',
                                                                        }}
                                                                      >
                                                                        <div
                                                                          id="bdt"
                                                                          className="block-component"
                                                                        />
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: 'rgb(89, 89, 89)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <strong>
                                                            <u>Cookies and similar technologies:</u>
                                                          </strong>{' '}
                                                          Most Web browsers are set to accept cookies by default. If you
                                                          prefer, you can usually choose to set your browser to remove
                                                          cookies and to reject cookies. If you choose to remove cookies
                                                          or reject cookies, this could affect certain features or
                                                          services of our Services. To opt out of interest-based
                                                          advertising by advertisers on our Services visit{' '}
                                                          <span
                                                            style={{
                                                              color: 'rgb(48, 48, 241)',
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <a
                                                                data-custom-class="link"
                                                                href="http://www.aboutads.info/choices/"
                                                                rel="noopener noreferrer"
                                                                target="_blank"
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                  }}
                                                                >
                                                                  http://www.aboutads.info/choices/
                                                                </span>
                                                              </a>
                                                            </span>
                                                          </span>
                                                          .{' '}
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <div
                                                                  id="bdt"
                                                                  className="block-component"
                                                                />
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color: 'rgb(89, 89, 89)',
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          color: 'rgb(89, 89, 89)',
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              color: 'rgb(89, 89, 89)',
                                                                            }}
                                                                          >
                                                                            <div
                                                                              id="bdt"
                                                                              className="statement-end-if-in-editor"
                                                                            />
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                    <div
                                                      id="bdt"
                                                      className="block-component"
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text" />
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span data-custom-class="body_text">
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                        }}
                                                      >
                                                        If you have questions or comments about your privacy rights, you
                                                        may email us at{' '}
                                                        <div
                                                          id="bdt"
                                                          className="question"
                                                        >
                                                          info@tunehatch.com
                                                        </div>
                                                        .
                                                      </span>
                                                    </span>
                                                    <div
                                                      id="bdt"
                                                      className="statement-end-if-in-editor"
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text" />
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    id="DNT"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color: 'rgb(127, 127, 127)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          color: 'rgb(89, 89, 89)',
                                                          fontSize: 15,
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color: 'rgb(89, 89, 89)',
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color: 'rgb(89, 89, 89)',
                                                            }}
                                                          >
                                                            <span
                                                              id="control"
                                                              style={{
                                                                color: 'rgb(0, 0, 0)',
                                                              }}
                                                            >
                                                              <strong>
                                                                <span data-custom-class="heading_1">
                                                                  9. CONTROLS FOR DO-NOT-TRACK FEATURES
                                                                </span>
                                                              </strong>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: 'rgb(89, 89, 89)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          Most web browsers and some mobile operating systems and mobile
                                                          applications include a Do-Not-Track (
                                                          <div
                                                            id="bdt"
                                                            className="block-component"
                                                          />
                                                          "DNT"
                                                          <div
                                                            id="bdt"
                                                            className="statement-end-if-in-editor"
                                                          />
                                                          ) feature or setting you can activate to signal your privacy
                                                          preference not to have data about your online browsing
                                                          activities monitored and collected. At this stage no uniform
                                                          technology standard for{' '}
                                                          <div
                                                            id="bdt"
                                                            className="block-component"
                                                          />
                                                          recognizing
                                                          <div
                                                            id="bdt"
                                                            className="statement-end-if-in-editor"
                                                          />{' '}
                                                          and implementing DNT signals has been{' '}
                                                          <div
                                                            id="bdt"
                                                            className="block-component"
                                                          />
                                                          finalized
                                                          <div
                                                            id="bdt"
                                                            className="statement-end-if-in-editor"
                                                          />
                                                          . As such, we do not currently respond to DNT browser signals
                                                          or any other mechanism that automatically communicates your
                                                          choice not to be tracked online. If a standard for online
                                                          tracking is adopted that we must follow in the future, we will
                                                          inform you about that practice in a revised version of this
                                                          privacy notice.
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    id="caresidents"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color: 'rgb(127, 127, 127)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          color: 'rgb(89, 89, 89)',
                                                          fontSize: 15,
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color: 'rgb(89, 89, 89)',
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color: 'rgb(89, 89, 89)',
                                                            }}
                                                          >
                                                            <span
                                                              id="control"
                                                              style={{
                                                                color: 'rgb(0, 0, 0)',
                                                              }}
                                                            >
                                                              <strong>
                                                                <span data-custom-class="heading_1">
                                                                  10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY
                                                                  RIGHTS?
                                                                </span>
                                                              </strong>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: 'rgb(89, 89, 89)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <strong>
                                                            <em>In Short:&nbsp;</em>
                                                          </strong>
                                                          <em>
                                                            Yes, if you are a resident of California, you are granted
                                                            specific rights regarding access to your personal
                                                            information.
                                                          </em>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: 'rgb(89, 89, 89)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          California Civil Code Section 1798.83, also known as the{' '}
                                                          <div
                                                            id="bdt"
                                                            className="block-component"
                                                          />
                                                          "Shine The Light"
                                                          <div
                                                            id="bdt"
                                                            className="statement-end-if-in-editor"
                                                          />{' '}
                                                          law, permits our users who are California residents to request
                                                          and obtain from us, once a year and free of charge,
                                                          information about categories of personal information (if any)
                                                          we disclosed to third parties for direct marketing purposes
                                                          and the names and addresses of all third parties with which we
                                                          shared personal information in the immediately preceding
                                                          calendar year. If you are a California resident and would like
                                                          to make such a request, please submit your request in writing
                                                          to us using the contact information provided below.
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: 'rgb(89, 89, 89)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          If you are under 18 years of age, reside in California, and
                                                          have a registered account with Services, you have the right to
                                                          request removal of unwanted data that you publicly post on the
                                                          Services. To request removal of such data, please contact us
                                                          using the contact information provided below and include the
                                                          email address associated with your account and a statement
                                                          that you reside in California. We will make sure the data is
                                                          not publicly displayed on the Services, but please be aware
                                                          that the data may not be completely or comprehensively removed
                                                          from all our systems (e.g.
                                                          <div
                                                            id="bdt"
                                                            className="block-component"
                                                          />
                                                          ,
                                                          <div
                                                            id="bdt"
                                                            className="statement-end-if-in-editor"
                                                          />{' '}
                                                          backups, etc.).
                                                          <span
                                                            style={{
                                                              color: 'rgb(89, 89, 89)',
                                                              fontSize: 15,
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <div
                                                                id="bdt"
                                                                className="block-component"
                                                              />
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span
                                                      data-custom-class="heading_2"
                                                      style={{
                                                        color: 'rgb(0, 0, 0)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                        }}
                                                      >
                                                        <strong>CCPA Privacy Notice</strong>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div className="flex-col">
                                                    <div className="flex-col">
                                                      <br />
                                                    </div>
                                                    <div
                                                      className="flex-col"
                                                      style={{
                                                        lineHeight: '1.5',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          color: 'rgb(127, 127, 127)',
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            color: 'rgb(89, 89, 89)',
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color: 'rgb(89, 89, 89)',
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  The California Code of Regulations defines a{' '}
                                                                  <div
                                                                    id="bdt"
                                                                    className="block-component"
                                                                  />
                                                                  "resident"
                                                                  <div
                                                                    id="bdt"
                                                                    className="statement-end-if-in-editor"
                                                                  />{' '}
                                                                  as:
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                      marginLeft: 20,
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: 'rgb(89, 89, 89)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          (1) every individual who is in the State of California for
                                                          other than a temporary or transitory purpose and
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                      marginLeft: 20,
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: 'rgb(89, 89, 89)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          (2) every individual who is domiciled in the State of
                                                          California who is outside the State of California for a
                                                          temporary or transitory purpose
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: 'rgb(89, 89, 89)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          All other individuals are defined as{' '}
                                                          <div
                                                            id="bdt"
                                                            className="block-component"
                                                          />
                                                          "non-residents."
                                                          <div
                                                            id="bdt"
                                                            className="statement-end-if-in-editor"
                                                          />
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: 'rgb(89, 89, 89)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          If this definition of{' '}
                                                          <div
                                                            id="bdt"
                                                            className="block-component"
                                                          />
                                                          "resident"
                                                          <div
                                                            id="bdt"
                                                            className="statement-end-if-in-editor"
                                                          />{' '}
                                                          applies to you, we must adhere to certain rights and
                                                          obligations regarding your personal information.
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: 'rgb(89, 89, 89)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <strong>
                                                            What categories of personal information do we collect?
                                                          </strong>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: 'rgb(89, 89, 89)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          We have collected the following categories of personal
                                                          information in the past twelve (12) months:
                                                          <span
                                                            style={{
                                                              color: 'rgb(89, 89, 89)',
                                                              fontSize: 15,
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <div
                                                                id="bdt"
                                                                className="block-component"
                                                              />
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <table
                                                    style={{
                                                      width: '100%',
                                                    }}
                                                  >
                                                    <tbody>
                                                      <tr>
                                                        <td
                                                          style={{
                                                            width: '33.8274%',
                                                            borderLeft: '1px solid black',
                                                            borderRight: '1px solid black',
                                                            borderTop: '1px solid black',
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color: 'rgb(89, 89, 89)',
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <strong>Category</strong>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </td>
                                                        <td
                                                          style={{
                                                            width: '51.4385%',
                                                            borderTop: '1px solid black',
                                                            borderRight: '1px solid black',
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color: 'rgb(89, 89, 89)',
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <strong>Examples</strong>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </td>
                                                        <td
                                                          style={{
                                                            width: '14.9084%',
                                                            borderRight: '1px solid black',
                                                            borderTop: '1px solid black',
                                                            textAlign: 'center',
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color: 'rgb(89, 89, 89)',
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <strong>Collected</strong>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td
                                                          style={{
                                                            width: '33.8274%',
                                                            borderLeft: '1px solid black',
                                                            borderRight: '1px solid black',
                                                            borderTop: '1px solid black',
                                                          }}
                                                        >
                                                          <div
                                                            className="flex-col"
                                                            style={{
                                                              lineHeight: '1.5',
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color: 'rgb(89, 89, 89)',
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  A. Identifiers
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td
                                                          style={{
                                                            width: '51.4385%',
                                                            borderTop: '1px solid black',
                                                            borderRight: '1px solid black',
                                                          }}
                                                        >
                                                          <div
                                                            className="flex-col"
                                                            style={{
                                                              lineHeight: '1.5',
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color: 'rgb(89, 89, 89)',
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  Contact details, such as real name, alias, postal
                                                                  address, telephone or mobile contact number, unique
                                                                  personal identifier, online identifier, Internet
                                                                  Protocol address, email address, and account name
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td
                                                          style={{
                                                            width: '14.9084%',
                                                            textAlign: 'center',
                                                            verticalAlign: 'middle',
                                                            borderRight: '1px solid black',
                                                            borderTop: '1px solid black',
                                                          }}
                                                        >
                                                          <div
                                                            className="flex-col"
                                                            style={{
                                                              lineHeight: '1.5',
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            className="flex-col"
                                                            style={{
                                                              lineHeight: '1.5',
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color: 'rgb(89, 89, 89)',
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <div
                                                                    id="bdt"
                                                                    className="block-component"
                                                                  />
                                                                  YES
                                                                  <div
                                                                    id="bdt"
                                                                    className="else-block"
                                                                  />
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            className="flex-col"
                                                            style={{
                                                              lineHeight: '1.5',
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td
                                                          style={{
                                                            width: '33.8274%',
                                                            borderLeft: '1px solid black',
                                                            borderRight: '1px solid black',
                                                            borderTop: '1px solid black',
                                                          }}
                                                        >
                                                          <div
                                                            className="flex-col"
                                                            style={{
                                                              lineHeight: '1.5',
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color: 'rgb(89, 89, 89)',
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  B. Personal information categories listed in the
                                                                  California Customer Records statute
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td
                                                          style={{
                                                            width: '51.4385%',
                                                            borderRight: '1px solid black',
                                                            borderTop: '1px solid black',
                                                          }}
                                                        >
                                                          <div
                                                            className="flex-col"
                                                            style={{
                                                              lineHeight: '1.5',
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color: 'rgb(89, 89, 89)',
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  Name, contact information, education, employment,
                                                                  employment history, and financial information
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td
                                                          style={{
                                                            width: '14.9084%',
                                                            textAlign: 'center',
                                                            borderRight: '1px solid black',
                                                            borderTop: '1px solid black',
                                                          }}
                                                        >
                                                          <div
                                                            className="flex-col"
                                                            style={{
                                                              lineHeight: '1.5',
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            className="flex-col"
                                                            style={{
                                                              lineHeight: '1.5',
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color: 'rgb(89, 89, 89)',
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <div
                                                                    id="bdt"
                                                                    className="block-component"
                                                                  />
                                                                  NO
                                                                  <div
                                                                    id="bdt"
                                                                    className="statement-end-if-in-editor"
                                                                  />
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            className="flex-col"
                                                            style={{
                                                              lineHeight: '1.5',
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td
                                                          style={{
                                                            width: '33.8274%',
                                                            borderLeft: '1px solid black',
                                                            borderRight: '1px solid black',
                                                            borderTop: '1px solid black',
                                                          }}
                                                        >
                                                          <div
                                                            className="flex-col"
                                                            style={{
                                                              lineHeight: '1.5',
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color: 'rgb(89, 89, 89)',
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  C. Protected classification characteristics under
                                                                  California or federal law
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td
                                                          style={{
                                                            width: '51.4385%',
                                                            borderRight: '1px solid black',
                                                            borderTop: '1px solid black',
                                                          }}
                                                        >
                                                          <div
                                                            className="flex-col"
                                                            style={{
                                                              lineHeight: '1.5',
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color: 'rgb(89, 89, 89)',
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  Gender and date of birth
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td
                                                          style={{
                                                            width: '14.9084%',
                                                            textAlign: 'center',
                                                            borderRight: '1px solid black',
                                                            borderTop: '1px solid black',
                                                          }}
                                                        >
                                                          <div
                                                            className="flex-col"
                                                            style={{
                                                              lineHeight: '1.5',
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            className="flex-col"
                                                            style={{
                                                              lineHeight: '1.5',
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color: 'rgb(89, 89, 89)',
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <div
                                                                    id="bdt"
                                                                    className="block-component"
                                                                  />
                                                                  NO
                                                                  <div
                                                                    id="bdt"
                                                                    className="statement-end-if-in-editor"
                                                                  />
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            className="flex-col"
                                                            style={{
                                                              lineHeight: '1.5',
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td
                                                          style={{
                                                            width: '33.8274%',
                                                            borderLeft: '1px solid black',
                                                            borderRight: '1px solid black',
                                                            borderTop: '1px solid black',
                                                          }}
                                                        >
                                                          <div
                                                            className="flex-col"
                                                            style={{
                                                              lineHeight: '1.5',
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color: 'rgb(89, 89, 89)',
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  D. Commercial information
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td
                                                          style={{
                                                            width: '51.4385%',
                                                            borderRight: '1px solid black',
                                                            borderTop: '1px solid black',
                                                          }}
                                                        >
                                                          <div
                                                            className="flex-col"
                                                            style={{
                                                              lineHeight: '1.5',
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color: 'rgb(89, 89, 89)',
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  Transaction information, purchase history, financial
                                                                  details, and payment information
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td
                                                          style={{
                                                            width: '14.9084%',
                                                            textAlign: 'center',
                                                            borderRight: '1px solid black',
                                                            borderTop: '1px solid black',
                                                          }}
                                                        >
                                                          <div
                                                            className="flex-col"
                                                            style={{
                                                              lineHeight: '1.5',
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            className="flex-col"
                                                            style={{
                                                              lineHeight: '1.5',
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color: 'rgb(89, 89, 89)',
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <div
                                                                    id="bdt"
                                                                    className="block-component"
                                                                  />
                                                                  NO
                                                                  <div
                                                                    id="bdt"
                                                                    className="statement-end-if-in-editor"
                                                                  />
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            className="flex-col"
                                                            style={{
                                                              lineHeight: '1.5',
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td
                                                          style={{
                                                            width: '33.8274%',
                                                            borderLeft: '1px solid black',
                                                            borderRight: '1px solid black',
                                                            borderTop: '1px solid black',
                                                          }}
                                                        >
                                                          <div
                                                            className="flex-col"
                                                            style={{
                                                              lineHeight: '1.5',
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color: 'rgb(89, 89, 89)',
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  E. Biometric information
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td
                                                          style={{
                                                            width: '51.4385%',
                                                            borderRight: '1px solid black',
                                                            borderTop: '1px solid black',
                                                          }}
                                                        >
                                                          <div
                                                            className="flex-col"
                                                            style={{
                                                              lineHeight: '1.5',
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color: 'rgb(89, 89, 89)',
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  Fingerprints and voiceprints
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td
                                                          style={{
                                                            width: '14.9084%',
                                                            textAlign: 'center',
                                                            borderRight: '1px solid black',
                                                            borderTop: '1px solid black',
                                                          }}
                                                        >
                                                          <div
                                                            className="flex-col"
                                                            style={{
                                                              lineHeight: '1.5',
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            className="flex-col"
                                                            style={{
                                                              lineHeight: '1.5',
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color: 'rgb(89, 89, 89)',
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <div
                                                                    id="bdt"
                                                                    className="block-component"
                                                                  />
                                                                  NO
                                                                  <div
                                                                    id="bdt"
                                                                    className="statement-end-if-in-editor"
                                                                  />
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            className="flex-col"
                                                            style={{
                                                              lineHeight: '1.5',
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td
                                                          style={{
                                                            width: '33.8274%',
                                                            borderLeft: '1px solid black',
                                                            borderRight: '1px solid black',
                                                            borderTop: '1px solid black',
                                                          }}
                                                        >
                                                          <div
                                                            className="flex-col"
                                                            style={{
                                                              lineHeight: '1.5',
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color: 'rgb(89, 89, 89)',
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  F. Internet or other similar network activity
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td
                                                          style={{
                                                            width: '51.4385%',
                                                            borderRight: '1px solid black',
                                                            borderTop: '1px solid black',
                                                          }}
                                                        >
                                                          <div
                                                            className="flex-col"
                                                            style={{
                                                              lineHeight: '1.5',
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color: 'rgb(89, 89, 89)',
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  Browsing history, search history, online{' '}
                                                                  <div
                                                                    id="bdt"
                                                                    className="block-component"
                                                                  />
                                                                  behavior
                                                                  <div
                                                                    id="bdt"
                                                                    className="statement-end-if-in-editor"
                                                                  />
                                                                  , interest data, and interactions with our and other
                                                                  websites, applications, systems, and advertisements
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td
                                                          style={{
                                                            width: '14.9084%',
                                                            textAlign: 'center',
                                                            borderRight: '1px solid black',
                                                            borderTop: '1px solid black',
                                                          }}
                                                        >
                                                          <div
                                                            className="flex-col"
                                                            style={{
                                                              lineHeight: '1.5',
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            className="flex-col"
                                                            style={{
                                                              lineHeight: '1.5',
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color: 'rgb(89, 89, 89)',
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <div
                                                                    id="bdt"
                                                                    className="block-component"
                                                                  />
                                                                  NO
                                                                  <div
                                                                    id="bdt"
                                                                    className="statement-end-if-in-editor"
                                                                  />
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            className="flex-col"
                                                            style={{
                                                              lineHeight: '1.5',
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td
                                                          style={{
                                                            width: '33.8274%',
                                                            borderLeft: '1px solid black',
                                                            borderRight: '1px solid black',
                                                            borderTop: '1px solid black',
                                                          }}
                                                        >
                                                          <div
                                                            className="flex-col"
                                                            style={{
                                                              lineHeight: '1.5',
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color: 'rgb(89, 89, 89)',
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  G. Geolocation data
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td
                                                          style={{
                                                            width: '51.4385%',
                                                            borderRight: '1px solid black',
                                                            borderTop: '1px solid black',
                                                          }}
                                                        >
                                                          <div
                                                            className="flex-col"
                                                            style={{
                                                              lineHeight: '1.5',
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color: 'rgb(89, 89, 89)',
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  Device location
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td
                                                          style={{
                                                            width: '14.9084%',
                                                            textAlign: 'center',
                                                            borderRight: '1px solid black',
                                                            borderTop: '1px solid black',
                                                          }}
                                                        >
                                                          <div
                                                            className="flex-col"
                                                            style={{
                                                              lineHeight: '1.5',
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            className="flex-col"
                                                            style={{
                                                              lineHeight: '1.5',
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color: 'rgb(89, 89, 89)',
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <div
                                                                    id="bdt"
                                                                    className="block-component"
                                                                  />
                                                                  YES
                                                                  <div
                                                                    id="bdt"
                                                                    className="else-block"
                                                                  />
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            className="flex-col"
                                                            style={{
                                                              lineHeight: '1.5',
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td
                                                          style={{
                                                            width: '33.8274%',
                                                            borderLeft: '1px solid black',
                                                            borderRight: '1px solid black',
                                                            borderTop: '1px solid black',
                                                          }}
                                                        >
                                                          <div
                                                            className="flex-col"
                                                            style={{
                                                              lineHeight: '1.5',
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color: 'rgb(89, 89, 89)',
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  H. Audio, electronic, visual, thermal, olfactory, or
                                                                  similar information
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td
                                                          style={{
                                                            width: '51.4385%',
                                                            borderRight: '1px solid black',
                                                            borderTop: '1px solid black',
                                                          }}
                                                        >
                                                          <div
                                                            className="flex-col"
                                                            style={{
                                                              lineHeight: '1.5',
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color: 'rgb(89, 89, 89)',
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  Images and audio, video or call recordings created in
                                                                  connection with our business activities
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td
                                                          style={{
                                                            width: '14.9084%',
                                                            textAlign: 'center',
                                                            borderRight: '1px solid black',
                                                            borderTop: '1px solid black',
                                                          }}
                                                        >
                                                          <div
                                                            className="flex-col"
                                                            style={{
                                                              lineHeight: '1.5',
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            className="flex-col"
                                                            style={{
                                                              lineHeight: '1.5',
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color: 'rgb(89, 89, 89)',
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <div
                                                                    id="bdt"
                                                                    className="block-component"
                                                                  />
                                                                  NO
                                                                  <div
                                                                    id="bdt"
                                                                    className="statement-end-if-in-editor"
                                                                  />
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            className="flex-col"
                                                            style={{
                                                              lineHeight: '1.5',
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td
                                                          style={{
                                                            width: '33.8274%',
                                                            borderLeft: '1px solid black',
                                                            borderRight: '1px solid black',
                                                            borderTop: '1px solid black',
                                                          }}
                                                        >
                                                          <div
                                                            className="flex-col"
                                                            style={{
                                                              lineHeight: '1.5',
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color: 'rgb(89, 89, 89)',
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  I. Professional or employment-related information
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td
                                                          style={{
                                                            width: '51.4385%',
                                                            borderRight: '1px solid black',
                                                            borderTop: '1px solid black',
                                                          }}
                                                        >
                                                          <div
                                                            className="flex-col"
                                                            style={{
                                                              lineHeight: '1.5',
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color: 'rgb(89, 89, 89)',
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  Business contact details in order to provide you our
                                                                  Services at a business level or job title, work
                                                                  history, and professional qualifications if you apply
                                                                  for a job with us
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td
                                                          style={{
                                                            width: '14.9084%',
                                                            textAlign: 'center',
                                                            borderRight: '1px solid black',
                                                            borderTop: '1px solid black',
                                                          }}
                                                        >
                                                          <div
                                                            className="flex-col"
                                                            style={{
                                                              lineHeight: '1.5',
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            className="flex-col"
                                                            style={{
                                                              lineHeight: '1.5',
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color: 'rgb(89, 89, 89)',
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <div
                                                                    id="bdt"
                                                                    className="block-component"
                                                                  />
                                                                  YES
                                                                  <div
                                                                    id="bdt"
                                                                    className="else-block"
                                                                  />
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            className="flex-col"
                                                            style={{
                                                              lineHeight: '1.5',
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td
                                                          style={{
                                                            borderLeft: '1px solid black',
                                                            borderRight: '1px solid black',
                                                            borderTop: '1px solid black',
                                                            width: '33.8274%',
                                                          }}
                                                        >
                                                          <div
                                                            className="flex-col"
                                                            style={{
                                                              lineHeight: '1.5',
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color: 'rgb(89, 89, 89)',
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  J. Education Information
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td
                                                          style={{
                                                            borderRight: '1px solid black',
                                                            borderTop: '1px solid black',
                                                            width: '51.4385%',
                                                          }}
                                                        >
                                                          <div
                                                            className="flex-col"
                                                            style={{
                                                              lineHeight: '1.5',
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color: 'rgb(89, 89, 89)',
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  Student records and directory information
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td
                                                          style={{
                                                            textAlign: 'center',
                                                            borderRight: '1px solid black',
                                                            borderTop: '1px solid black',
                                                            width: '14.9084%',
                                                          }}
                                                        >
                                                          <div
                                                            className="flex-col"
                                                            style={{
                                                              lineHeight: '1.5',
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            className="flex-col"
                                                            style={{
                                                              lineHeight: '1.5',
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color: 'rgb(89, 89, 89)',
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <div
                                                                    id="bdt"
                                                                    className="block-component"
                                                                  />
                                                                  NO
                                                                  <div
                                                                    id="bdt"
                                                                    className="statement-end-if-in-editor"
                                                                  />
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            className="flex-col"
                                                            style={{
                                                              lineHeight: '1.5',
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td
                                                          style={{
                                                            borderWidth: 1,
                                                            borderColor: 'black',
                                                            borderStyle: 'solid',
                                                            width: '33.8274%',
                                                          }}
                                                        >
                                                          <div
                                                            className="flex-col"
                                                            style={{
                                                              lineHeight: '1.5',
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color: 'rgb(89, 89, 89)',
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  K. Inferences drawn from other personal information
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td
                                                          style={{
                                                            borderBottom: '1px solid black',
                                                            borderTop: '1px solid black',
                                                            borderRight: '1px solid black',
                                                            width: '51.4385%',
                                                          }}
                                                        >
                                                          <div
                                                            className="flex-col"
                                                            style={{
                                                              lineHeight: '1.5',
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color: 'rgb(89, 89, 89)',
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  Inferences drawn from any of the collected personal
                                                                  information listed above to create a profile or
                                                                  summary about, for example, an individual's
                                                                  preferences and characteristics
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td
                                                          style={{
                                                            textAlign: 'center',
                                                            borderRight: '1px solid black',
                                                            borderBottom: '1px solid black',
                                                            borderTop: '1px solid black',
                                                            width: '14.9084%',
                                                          }}
                                                        >
                                                          <div
                                                            className="flex-col"
                                                            style={{
                                                              lineHeight: '1.5',
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            className="flex-col"
                                                            style={{
                                                              lineHeight: '1.5',
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color: 'rgb(89, 89, 89)',
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <div
                                                                    id="bdt"
                                                                    className="block-component"
                                                                  />
                                                                  YES
                                                                  <div
                                                                    id="bdt"
                                                                    className="else-block"
                                                                  />
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            className="flex-col"
                                                            style={{
                                                              lineHeight: '1.5',
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <div
                                                      id="bdt"
                                                      className="block-component"
                                                    />
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: 'rgb(89, 89, 89)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          We may also collect other personal information outside of
                                                          these categories through instances where you interact with us
                                                          in person, online, or by phone or mail in the context of:
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color: 'rgb(89, 89, 89)',
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <span
                                                                  style={{
                                                                    color: 'rgb(89, 89, 89)',
                                                                    fontSize: 15,
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <div
                                                                      id="bdt"
                                                                      className="block-component"
                                                                    />
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <ul>
                                                    <li
                                                      style={{
                                                        lineHeight: '1.5',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color: 'rgb(89, 89, 89)',
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            Receiving help through our customer support channels;
                                                          </span>
                                                          <span data-custom-class="body_text">
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color: 'rgb(89, 89, 89)',
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <span
                                                                    style={{
                                                                      color: 'rgb(89, 89, 89)',
                                                                      fontSize: 15,
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <span
                                                                        style={{
                                                                          color: 'rgb(89, 89, 89)',
                                                                          fontSize: 15,
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                color: 'rgb(89, 89, 89)',
                                                                              }}
                                                                            >
                                                                              <span data-custom-class="body_text">
                                                                                <div
                                                                                  id="bdt"
                                                                                  className="statement-end-if-in-editor"
                                                                                />
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </li>
                                                  </ul>
                                                  <div className="flex-col">
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: 'rgb(89, 89, 89)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color: 'rgb(89, 89, 89)',
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <span
                                                                  style={{
                                                                    color: 'rgb(89, 89, 89)',
                                                                    fontSize: 15,
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <div
                                                                      id="bdt"
                                                                      className="block-component"
                                                                    />
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <ul>
                                                    <li
                                                      style={{
                                                        lineHeight: '1.5',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color: 'rgb(89, 89, 89)',
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            Participation in customer surveys or contests; and
                                                          </span>
                                                          <span data-custom-class="body_text">
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color: 'rgb(89, 89, 89)',
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <span
                                                                    style={{
                                                                      color: 'rgb(89, 89, 89)',
                                                                      fontSize: 15,
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <span
                                                                        style={{
                                                                          color: 'rgb(89, 89, 89)',
                                                                          fontSize: 15,
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                color: 'rgb(89, 89, 89)',
                                                                              }}
                                                                            >
                                                                              <span data-custom-class="body_text">
                                                                                <div
                                                                                  id="bdt"
                                                                                  className="statement-end-if-in-editor"
                                                                                />
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </li>
                                                  </ul>
                                                  <div className="flex-col">
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: 'rgb(89, 89, 89)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color: 'rgb(89, 89, 89)',
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <span
                                                                  style={{
                                                                    color: 'rgb(89, 89, 89)',
                                                                    fontSize: 15,
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <div
                                                                      id="bdt"
                                                                      className="block-component"
                                                                    />
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <ul>
                                                    <li
                                                      style={{
                                                        lineHeight: '1.5',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color: 'rgb(89, 89, 89)',
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            Facilitation in the delivery of our Services and to respond
                                                            to your inquiries.
                                                          </span>
                                                          <span data-custom-class="body_text">
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color: 'rgb(89, 89, 89)',
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <span
                                                                    style={{
                                                                      color: 'rgb(89, 89, 89)',
                                                                      fontSize: 15,
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <span
                                                                        style={{
                                                                          color: 'rgb(89, 89, 89)',
                                                                          fontSize: 15,
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                color: 'rgb(89, 89, 89)',
                                                                              }}
                                                                            >
                                                                              <span data-custom-class="body_text">
                                                                                <div
                                                                                  id="bdt"
                                                                                  className="statement-end-if-in-editor"
                                                                                />
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </li>
                                                  </ul>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: 'rgb(89, 89, 89)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <strong>
                                                            How do we use and share your personal information?
                                                          </strong>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: 'rgb(89, 89, 89)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <span
                                                            style={{
                                                              color: 'rgb(89, 89, 89)',
                                                              fontSize: 15,
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <div
                                                                id="bdt"
                                                                className="block-component"
                                                              />
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: 'rgb(89, 89, 89)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color: 'rgb(89, 89, 89)',
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                More information about our data collection and sharing
                                                                practices can be found in this privacy notice
                                                                <div
                                                                  id="bdt"
                                                                  className="block-component"
                                                                />
                                                                .
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                        <span data-custom-class="body_text">
                                                          <span
                                                            style={{
                                                              color: 'rgb(89, 89, 89)',
                                                              fontSize: 15,
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  color: 'rgb(89, 89, 89)',
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <div
                                                                    id="bdt"
                                                                    className="block-component"
                                                                  />
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: 'rgb(89, 89, 89)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          You may contact us{' '}
                                                          <span
                                                            style={{
                                                              color: 'rgb(89, 89, 89)',
                                                              fontSize: 15,
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <div
                                                                id="bdt"
                                                                className="block-component"
                                                              />
                                                            </span>
                                                          </span>
                                                          by email at&nbsp;
                                                        </span>
                                                        <span
                                                          style={{
                                                            color: 'rgb(89, 89, 89)',
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <div
                                                              id="bdt"
                                                              className="question"
                                                            >
                                                              info@tunehatch.com
                                                            </div>
                                                            ,{' '}
                                                            <div
                                                              id="bdt"
                                                              className="statement-end-if-in-editor"
                                                            />
                                                            <div
                                                              id="bdt"
                                                              className="block-component"
                                                            />
                                                          </span>
                                                          <span data-custom-class="body_text">
                                                            <div
                                                              id="bdt"
                                                              className="block-component"
                                                            />
                                                            <div
                                                              id="bdt"
                                                              className="block-component"
                                                            >
                                                              <span data-custom-class="body_text" />
                                                            </div>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                    <span data-custom-class="body_text">
                                                      or by referring to the contact details at the bottom of this
                                                      document.
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: 'rgb(89, 89, 89)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          If you are using an{' '}
                                                          <div
                                                            id="bdt"
                                                            className="block-component"
                                                          />
                                                          authorized
                                                          <div
                                                            id="bdt"
                                                            className="statement-end-if-in-editor"
                                                          />{' '}
                                                          agent to exercise your right to opt out we may deny a request
                                                          if the{' '}
                                                          <div
                                                            id="bdt"
                                                            className="block-component"
                                                          />
                                                          authorized
                                                          <div
                                                            id="bdt"
                                                            className="statement-end-if-in-editor"
                                                          />{' '}
                                                          agent does not submit proof that they have been validly{' '}
                                                          <div
                                                            id="bdt"
                                                            className="block-component"
                                                          />
                                                          authorized
                                                          <div
                                                            id="bdt"
                                                            className="statement-end-if-in-editor"
                                                          />{' '}
                                                          to act on your behalf.
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: 'rgb(89, 89, 89)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <strong>
                                                            Will your information be shared with anyone else?
                                                          </strong>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: 'rgb(89, 89, 89)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          We may disclose your personal information with our service
                                                          providers pursuant to a written contract between us and each
                                                          service provider. Each service provider is a for-profit entity
                                                          that processes the information on our behalf.
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: 'rgb(89, 89, 89)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          We may use your personal information for our own business
                                                          purposes, such as for undertaking internal research for
                                                          technological development and demonstration. This is not
                                                          considered to be{' '}
                                                          <div
                                                            id="bdt"
                                                            className="block-component"
                                                          />
                                                          "selling"
                                                          <div
                                                            id="bdt"
                                                            className="statement-end-if-in-editor"
                                                          />{' '}
                                                          of your personal information.
                                                          <span
                                                            style={{
                                                              color: 'rgb(89, 89, 89)',
                                                              fontSize: 15,
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <div
                                                                id="bdt"
                                                                className="block-component"
                                                              />
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: 'rgb(89, 89, 89)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <div
                                                            id="bdt"
                                                            className="question"
                                                          >
                                                            TuneHatch, Inc.
                                                          </div>
                                                        </span>
                                                        <span data-custom-class="body_text">
                                                          &nbsp;has not disclosed or sold any personal information to
                                                          third parties for a business or commercial purpose in the
                                                          preceding twelve (12) months.{' '}
                                                          <span
                                                            style={{
                                                              color: 'rgb(89, 89, 89)',
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <div
                                                                  id="bdt"
                                                                  className="question"
                                                                >
                                                                  TuneHatch, Inc.
                                                                </div>
                                                              </span>
                                                            </span>
                                                            &nbsp;
                                                          </span>
                                                          will not sell personal information in the future belonging to
                                                          website visitors, users, and other consumers.
                                                          <span
                                                            style={{
                                                              color: 'rgb(89, 89, 89)',
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  color: 'rgb(89, 89, 89)',
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <span
                                                                    style={{
                                                                      color: 'rgb(89, 89, 89)',
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <div
                                                                        id="bdt"
                                                                        className="statement-end-if-in-editor"
                                                                      />
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                              <div
                                                                id="bdt"
                                                                className="block-component"
                                                              />
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                    <span data-custom-class="body_text">
                                                      <span
                                                        style={{
                                                          color: 'rgb(0, 0, 0)',
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <div
                                                            id="bdt"
                                                            className="block-component"
                                                          />
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: 'rgb(89, 89, 89)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <strong>
                                                            Your rights with respect to your personal data
                                                          </strong>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: 'rgb(89, 89, 89)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <u>
                                                            Right to request deletion of the data — Request to delete
                                                          </u>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: 'rgb(89, 89, 89)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          You can ask for the deletion of your personal information. If
                                                          you ask us to delete your personal information, we will
                                                          respect your request and delete your personal information,
                                                          subject to certain exceptions provided by law, such as (but
                                                          not limited to) the exercise by another consumer of his or her
                                                          right to free speech, our compliance requirements resulting
                                                          from a legal obligation, or any processing that may be
                                                          required to protect against illegal activities.
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: 'rgb(89, 89, 89)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <u>Right to be informed — Request to know</u>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: 'rgb(89, 89, 89)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          Depending on the circumstances, you have a right to know:
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color: 'rgb(89, 89, 89)',
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color: 'rgb(89, 89, 89)',
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color: 'rgb(89, 89, 89)',
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <span
                                                                        style={{
                                                                          color: 'rgb(89, 89, 89)',
                                                                          fontSize: 15,
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <div
                                                                            id="bdt"
                                                                            className="block-component"
                                                                          />
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <ul>
                                                    <li
                                                      style={{
                                                        lineHeight: '1.5',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color: 'rgb(89, 89, 89)',
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            whether we collect and use your personal information;
                                                            <span
                                                              style={{
                                                                color: 'rgb(0, 0, 0)',
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <span
                                                                    style={{
                                                                      color: 'rgb(0, 0, 0)',
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <div
                                                                          id="bdt"
                                                                          className="statement-end-if-in-editor"
                                                                        />
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </li>
                                                  </ul>
                                                  <div className="flex-col">
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: 'rgb(89, 89, 89)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color: 'rgb(89, 89, 89)',
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color: 'rgb(89, 89, 89)',
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color: 'rgb(89, 89, 89)',
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <span
                                                                        style={{
                                                                          color: 'rgb(89, 89, 89)',
                                                                          fontSize: 15,
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <div
                                                                            id="bdt"
                                                                            className="block-component"
                                                                          />
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <ul>
                                                    <li
                                                      style={{
                                                        lineHeight: '1.5',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color: 'rgb(89, 89, 89)',
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            the categories of personal information that we collect;
                                                            <span
                                                              style={{
                                                                color: 'rgb(0, 0, 0)',
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <span
                                                                    style={{
                                                                      color: 'rgb(0, 0, 0)',
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <div
                                                                          id="bdt"
                                                                          className="statement-end-if-in-editor"
                                                                        />
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </li>
                                                  </ul>
                                                  <div className="flex-col">
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: 'rgb(89, 89, 89)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color: 'rgb(89, 89, 89)',
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color: 'rgb(89, 89, 89)',
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color: 'rgb(89, 89, 89)',
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <span
                                                                        style={{
                                                                          color: 'rgb(89, 89, 89)',
                                                                          fontSize: 15,
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <div
                                                                            id="bdt"
                                                                            className="block-component"
                                                                          />
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <ul>
                                                    <li
                                                      style={{
                                                        lineHeight: '1.5',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color: 'rgb(89, 89, 89)',
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            the purposes for which the collected personal information is
                                                            used;
                                                            <span
                                                              style={{
                                                                color: 'rgb(0, 0, 0)',
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <span
                                                                    style={{
                                                                      color: 'rgb(0, 0, 0)',
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <div
                                                                          id="bdt"
                                                                          className="statement-end-if-in-editor"
                                                                        />
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </li>
                                                  </ul>
                                                  <div className="flex-col">
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: 'rgb(89, 89, 89)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color: 'rgb(89, 89, 89)',
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color: 'rgb(89, 89, 89)',
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color: 'rgb(89, 89, 89)',
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <span
                                                                        style={{
                                                                          color: 'rgb(89, 89, 89)',
                                                                          fontSize: 15,
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <div
                                                                            id="bdt"
                                                                            className="block-component"
                                                                          />
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <ul>
                                                    <li
                                                      style={{
                                                        lineHeight: '1.5',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color: 'rgb(89, 89, 89)',
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            whether we sell your personal information to third parties;
                                                            <span
                                                              style={{
                                                                color: 'rgb(0, 0, 0)',
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <span
                                                                    style={{
                                                                      color: 'rgb(0, 0, 0)',
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <div
                                                                          id="bdt"
                                                                          className="statement-end-if-in-editor"
                                                                        />
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </li>
                                                  </ul>
                                                  <div className="flex-col">
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: 'rgb(89, 89, 89)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color: 'rgb(89, 89, 89)',
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color: 'rgb(89, 89, 89)',
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color: 'rgb(89, 89, 89)',
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <span
                                                                        style={{
                                                                          color: 'rgb(89, 89, 89)',
                                                                          fontSize: 15,
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <div
                                                                            id="bdt"
                                                                            className="block-component"
                                                                          />
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <ul>
                                                    <li
                                                      style={{
                                                        lineHeight: '1.5',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color: 'rgb(89, 89, 89)',
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            the categories of personal information that we sold or
                                                            disclosed for a business purpose;
                                                            <span
                                                              style={{
                                                                color: 'rgb(0, 0, 0)',
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <span
                                                                    style={{
                                                                      color: 'rgb(0, 0, 0)',
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <div
                                                                          id="bdt"
                                                                          className="statement-end-if-in-editor"
                                                                        />
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </li>
                                                  </ul>
                                                  <div className="flex-col">
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: 'rgb(89, 89, 89)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color: 'rgb(89, 89, 89)',
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color: 'rgb(89, 89, 89)',
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color: 'rgb(89, 89, 89)',
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <span
                                                                        style={{
                                                                          color: 'rgb(89, 89, 89)',
                                                                          fontSize: 15,
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <div
                                                                            id="bdt"
                                                                            className="block-component"
                                                                          />
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <ul>
                                                    <li
                                                      style={{
                                                        lineHeight: '1.5',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color: 'rgb(89, 89, 89)',
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            the categories of third parties to whom the personal
                                                            information was sold or disclosed for a business purpose;
                                                            and
                                                            <span
                                                              style={{
                                                                color: 'rgb(0, 0, 0)',
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <span
                                                                    style={{
                                                                      color: 'rgb(0, 0, 0)',
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <div
                                                                          id="bdt"
                                                                          className="statement-end-if-in-editor"
                                                                        />
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </li>
                                                  </ul>
                                                  <div className="flex-col">
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: 'rgb(89, 89, 89)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color: 'rgb(89, 89, 89)',
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color: 'rgb(89, 89, 89)',
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color: 'rgb(89, 89, 89)',
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <span
                                                                        style={{
                                                                          color: 'rgb(89, 89, 89)',
                                                                          fontSize: 15,
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <div
                                                                            id="bdt"
                                                                            className="block-component"
                                                                          />
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <ul>
                                                    <li
                                                      style={{
                                                        lineHeight: '1.5',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color: 'rgb(89, 89, 89)',
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            the business or commercial purpose for collecting or selling
                                                            personal information.
                                                            <span
                                                              style={{
                                                                color: 'rgb(0, 0, 0)',
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <span
                                                                    style={{
                                                                      color: 'rgb(0, 0, 0)',
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <div
                                                                          id="bdt"
                                                                          className="statement-end-if-in-editor"
                                                                        />
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </li>
                                                  </ul>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: 'rgb(89, 89, 89)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          In accordance with applicable law, we are not obligated to
                                                          provide or delete consumer information that is de-identified
                                                          in response to a consumer request or to re-identify individual
                                                          data to verify a consumer request.
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: 'rgb(89, 89, 89)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <u>
                                                            Right to Non-Discrimination for the Exercise of a Consumer's
                                                            Privacy Rights
                                                          </u>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: 'rgb(89, 89, 89)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          We will not discriminate against you if you exercise your
                                                          privacy rights.
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: 'rgb(89, 89, 89)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <u>Verification process</u>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: 'rgb(89, 89, 89)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          Upon receiving your request, we will need to verify your
                                                          identity to determine you are the same person about whom we
                                                          have the information in our system. These verification efforts
                                                          require us to ask you to provide information so that we can
                                                          match it with information you have previously provided us. For
                                                          instance, depending on the type of request you submit, we may
                                                          ask you to provide certain information so that we can match
                                                          the information you provide with the information we already
                                                          have on file, or we may contact you through a communication
                                                          method (e.g.
                                                          <div
                                                            id="bdt"
                                                            className="block-component"
                                                          />
                                                          ,
                                                          <div
                                                            id="bdt"
                                                            className="statement-end-if-in-editor"
                                                          />{' '}
                                                          phone or email) that you have previously provided to us. We
                                                          may also use other verification methods as the circumstances
                                                          dictate.
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: 'rgb(89, 89, 89)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          We will only use personal information provided in your request
                                                          to verify your identity or authority to make the request. To
                                                          the extent possible, we will avoid requesting additional
                                                          information from you for the purposes of verification.
                                                          However, if we cannot verify your identity from the
                                                          information already maintained by us, we may request that you
                                                          provide additional information for the purposes of verifying
                                                          your identity and for security or fraud-prevention purposes.
                                                          We will delete such additionally provided information as soon
                                                          as we finish verifying you.
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: 'rgb(89, 89, 89)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <u>Other privacy rights</u>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div className="flex-col">
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: 'rgb(89, 89, 89)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color: 'rgb(89, 89, 89)',
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color: 'rgb(89, 89, 89)',
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color: 'rgb(89, 89, 89)',
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <span
                                                                        style={{
                                                                          color: 'rgb(89, 89, 89)',
                                                                          fontSize: 15,
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <div
                                                                            id="bdt"
                                                                            className="block-component"
                                                                          />
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <ul>
                                                    <li
                                                      style={{
                                                        lineHeight: '1.5',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color: 'rgb(89, 89, 89)',
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            You may object to the processing of your personal
                                                            information.
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color: 'rgb(89, 89, 89)',
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <span
                                                                    style={{
                                                                      color: 'rgb(0, 0, 0)',
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <span
                                                                          style={{
                                                                            color: 'rgb(0, 0, 0)',
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                            }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              <div
                                                                                id="bdt"
                                                                                className="statement-end-if-in-editor"
                                                                              />
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </li>
                                                  </ul>
                                                  <div className="flex-col">
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: 'rgb(89, 89, 89)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color: 'rgb(89, 89, 89)',
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color: 'rgb(89, 89, 89)',
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color: 'rgb(89, 89, 89)',
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <span
                                                                        style={{
                                                                          color: 'rgb(89, 89, 89)',
                                                                          fontSize: 15,
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <div
                                                                            id="bdt"
                                                                            className="block-component"
                                                                          />
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <ul>
                                                    <li
                                                      style={{
                                                        lineHeight: '1.5',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color: 'rgb(89, 89, 89)',
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            You may request correction of your personal data if it is
                                                            incorrect or no longer relevant, or ask to restrict the
                                                            processing of the information.
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color: 'rgb(89, 89, 89)',
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <span
                                                                    style={{
                                                                      color: 'rgb(0, 0, 0)',
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <span
                                                                          style={{
                                                                            color: 'rgb(0, 0, 0)',
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                            }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              <div
                                                                                id="bdt"
                                                                                className="statement-end-if-in-editor"
                                                                              />
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </li>
                                                  </ul>
                                                  <div className="flex-col">
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: 'rgb(89, 89, 89)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color: 'rgb(89, 89, 89)',
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color: 'rgb(89, 89, 89)',
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color: 'rgb(89, 89, 89)',
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <span
                                                                        style={{
                                                                          color: 'rgb(89, 89, 89)',
                                                                          fontSize: 15,
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <div
                                                                            id="bdt"
                                                                            className="block-component"
                                                                          />
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <ul>
                                                    <li
                                                      style={{
                                                        lineHeight: '1.5',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color: 'rgb(89, 89, 89)',
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            You can designate an{' '}
                                                            <div
                                                              id="bdt"
                                                              className="block-component"
                                                            />
                                                            authorized
                                                            <div
                                                              id="bdt"
                                                              className="statement-end-if-in-editor"
                                                            />{' '}
                                                            agent to make a request under the CCPA on your behalf. We
                                                            may deny a request from an{' '}
                                                            <div
                                                              id="bdt"
                                                              className="block-component"
                                                            />
                                                            authorized
                                                            <div
                                                              id="bdt"
                                                              className="statement-end-if-in-editor"
                                                            />{' '}
                                                            agent that does not submit proof that they have been validly{' '}
                                                            <div
                                                              id="bdt"
                                                              className="block-component"
                                                            />
                                                            authorized
                                                            <div
                                                              id="bdt"
                                                              className="statement-end-if-in-editor"
                                                            />{' '}
                                                            to act on your behalf in accordance with the CCPA.
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color: 'rgb(89, 89, 89)',
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <span
                                                                    style={{
                                                                      color: 'rgb(0, 0, 0)',
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <span
                                                                          style={{
                                                                            color: 'rgb(0, 0, 0)',
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                            }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              <div
                                                                                id="bdt"
                                                                                className="statement-end-if-in-editor"
                                                                              />
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </li>
                                                  </ul>
                                                  <div className="flex-col">
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: 'rgb(89, 89, 89)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color: 'rgb(89, 89, 89)',
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color: 'rgb(89, 89, 89)',
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color: 'rgb(89, 89, 89)',
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <span
                                                                        style={{
                                                                          color: 'rgb(89, 89, 89)',
                                                                          fontSize: 15,
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <div
                                                                            id="bdt"
                                                                            className="block-component"
                                                                          />
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <ul>
                                                    <li
                                                      style={{
                                                        lineHeight: '1.5',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color: 'rgb(89, 89, 89)',
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            You may request to opt out from future selling of your
                                                            personal information to third parties. Upon receiving an
                                                            opt-out request, we will act upon the request as soon as
                                                            feasibly possible, but no later than fifteen (15) days from
                                                            the date of the request submission.
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color: 'rgb(89, 89, 89)',
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <span
                                                                    style={{
                                                                      color: 'rgb(0, 0, 0)',
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <span
                                                                          style={{
                                                                            color: 'rgb(0, 0, 0)',
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                            }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              <div
                                                                                id="bdt"
                                                                                className="statement-end-if-in-editor"
                                                                              />
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </li>
                                                  </ul>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: 'rgb(89, 89, 89)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          To exercise these rights, you can contact us&nbsp;
                                                        </span>
                                                        <span
                                                          style={{
                                                            color: 'rgb(89, 89, 89)',
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <span
                                                              style={{
                                                                color: 'rgb(89, 89, 89)',
                                                                fontSize: 15,
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <div
                                                                  id="bdt"
                                                                  className="block-component"
                                                                />
                                                                by email at{' '}
                                                                <div
                                                                  id="bdt"
                                                                  className="question"
                                                                >
                                                                  info@tunehatch.com
                                                                </div>
                                                                ,{' '}
                                                                <div
                                                                  id="bdt"
                                                                  className="statement-end-if-in-editor"
                                                                />
                                                                <div
                                                                  id="bdt"
                                                                  className="block-component"
                                                                />
                                                              </span>
                                                              <span data-custom-class="body_text">
                                                                <div
                                                                  id="bdt"
                                                                  className="block-component"
                                                                />
                                                                <div
                                                                  id="bdt"
                                                                  className="block-component"
                                                                >
                                                                  <span data-custom-class="body_text" />
                                                                </div>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                    <span data-custom-class="body_text">
                                                      or by referring to the contact details at the bottom of this
                                                      document. If you have a complaint about how we handle your data,
                                                      we would like to hear from you.
                                                    </span>
                                                    <span
                                                      style={{
                                                        color: 'rgb(89, 89, 89)',
                                                        fontSize: 15,
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <div
                                                          id="bdt"
                                                          className="block-component"
                                                        >
                                                          <div
                                                            id="bdt"
                                                            className="block-component"
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color: 'rgb(89, 89, 89)',
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    color: 'rgb(89, 89, 89)',
                                                                    fontSize: 15,
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <span
                                                                      style={{
                                                                        color: 'rgb(89, 89, 89)',
                                                                        fontSize: 15,
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <span
                                                                          style={{
                                                                            color: 'rgb(89, 89, 89)',
                                                                            fontSize: 15,
                                                                          }}
                                                                        >
                                                                          <span data-custom-class="body_text">
                                                                            <span
                                                                              style={{
                                                                                color: 'rgb(89, 89, 89)',
                                                                                fontSize: 15,
                                                                              }}
                                                                            >
                                                                              <span data-custom-class="body_text">
                                                                                <div
                                                                                  id="bdt"
                                                                                  className="statement-end-if-in-editor"
                                                                                >
                                                                                  <div
                                                                                    id="bdt"
                                                                                    className="statement-end-if-in-editor"
                                                                                  />
                                                                                </div>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </div>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    id="policyupdates"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color: 'rgb(127, 127, 127)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          color: 'rgb(89, 89, 89)',
                                                          fontSize: 15,
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color: 'rgb(89, 89, 89)',
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color: 'rgb(89, 89, 89)',
                                                            }}
                                                          >
                                                            <span
                                                              id="control"
                                                              style={{
                                                                color: 'rgb(0, 0, 0)',
                                                              }}
                                                            >
                                                              <strong>
                                                                <span data-custom-class="heading_1">
                                                                  11. DO WE MAKE UPDATES TO THIS NOTICE?
                                                                </span>
                                                              </strong>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: 'rgb(89, 89, 89)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <em>
                                                            <strong>In Short:&nbsp;</strong>
                                                            Yes, we will update this notice as necessary to stay
                                                            compliant with relevant laws.
                                                          </em>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: 'rgb(89, 89, 89)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          We may update this privacy notice from time to time. The
                                                          updated version will be indicated by an updated{' '}
                                                          <div
                                                            id="bdt"
                                                            className="block-component"
                                                          />
                                                          "Revised"
                                                          <div
                                                            id="bdt"
                                                            className="statement-end-if-in-editor"
                                                          />{' '}
                                                          date and the updated version will be effective as soon as it
                                                          is accessible. If we make material changes to this privacy
                                                          notice, we may notify you either by prominently posting a
                                                          notice of such changes or by directly sending you a
                                                          notification. We encourage you to review this privacy notice
                                                          frequently to be informed of how we are protecting your
                                                          information.
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    id="contact"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color: 'rgb(127, 127, 127)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          color: 'rgb(89, 89, 89)',
                                                          fontSize: 15,
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color: 'rgb(89, 89, 89)',
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color: 'rgb(89, 89, 89)',
                                                            }}
                                                          >
                                                            <span
                                                              id="control"
                                                              style={{
                                                                color: 'rgb(0, 0, 0)',
                                                              }}
                                                            >
                                                              <strong>
                                                                <span data-custom-class="heading_1">
                                                                  12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                                                                </span>
                                                              </strong>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: 'rgb(89, 89, 89)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          If you have questions or comments about this notice, you may{' '}
                                                          <span
                                                            style={{
                                                              color: 'rgb(89, 89, 89)',
                                                              fontSize: 15,
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <div
                                                                id="bdt"
                                                                className="block-component"
                                                              />
                                                              email us at{' '}
                                                              <div
                                                                id="bdt"
                                                                className="question"
                                                              >
                                                                info@tunehatch.com
                                                              </div>
                                                              <div
                                                                id="bdt"
                                                                className="statement-end-if-in-editor"
                                                              />
                                                            </span>
                                                          </span>
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color: 'rgb(89, 89, 89)',
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                &nbsp;or by post to:
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: 'rgb(89, 89, 89)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color: 'rgb(89, 89, 89)',
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color: 'rgb(89, 89, 89)',
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <div
                                                                    id="bdt"
                                                                    className="question"
                                                                  >
                                                                    TuneHatch, Inc.
                                                                  </div>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                        <span data-custom-class="body_text">
                                                          <span
                                                            style={{
                                                              color: 'rgb(89, 89, 89)',
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <div
                                                                id="bdt"
                                                                className="block-component"
                                                              />
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <div
                                                          id="bdt"
                                                          className="question"
                                                        >
                                                          1817 Wedgewood Avenue
                                                        </div>
                                                        <span
                                                          style={{
                                                            color: 'rgb(89, 89, 89)',
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                            }}
                                                          >
                                                            <div
                                                              id="bdt"
                                                              className="block-component"
                                                            />
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <div
                                                          id="bdt"
                                                          className="question"
                                                        >
                                                          Nashville
                                                        </div>
                                                        <span
                                                          style={{
                                                            color: 'rgb(89, 89, 89)',
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                            }}
                                                          >
                                                            <div
                                                              id="bdt"
                                                              className="block-component"
                                                            />
                                                            <div
                                                              id="bdt"
                                                              className="block-component"
                                                            />
                                                            ,{' '}
                                                            <div
                                                              id="bdt"
                                                              className="question"
                                                            >
                                                              TN
                                                            </div>
                                                            <div
                                                              id="bdt"
                                                              className="statement-end-if-in-editor"
                                                            />
                                                            <div
                                                              id="bdt"
                                                              className="block-component"
                                                            />
                                                            <div
                                                              id="bdt"
                                                              className="block-component"
                                                            />{' '}
                                                            <div
                                                              id="bdt"
                                                              className="question"
                                                            >
                                                              37212
                                                            </div>
                                                            <div
                                                              id="bdt"
                                                              className="statement-end-if-in-editor"
                                                            />
                                                            <div
                                                              id="bdt"
                                                              className="block-component"
                                                            />
                                                            <div
                                                              id="bdt"
                                                              className="block-component"
                                                            />
                                                            <div
                                                              id="bdt"
                                                              className="block-component"
                                                            />
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span
                                                      data-custom-class="body_text"
                                                      style={{
                                                        fontSize: 15,
                                                      }}
                                                    >
                                                      <div
                                                        id="bdt"
                                                        className="question"
                                                      >
                                                        United States
                                                        <span data-custom-class="body_text">
                                                          <span
                                                            style={{
                                                              color: 'rgb(89, 89, 89)',
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <span
                                                                  style={{
                                                                    color: 'rgb(89, 89, 89)',
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <span
                                                                        style={{
                                                                          color: 'rgb(89, 89, 89)',
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                          }}
                                                                        >
                                                                          <div
                                                                            id="bdt"
                                                                            className="statement-end-if-in-editor"
                                                                          />
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </div>
                                                    </span>
                                                    <div
                                                      id="bdt"
                                                      className="block-component"
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text" />
                                                      </span>
                                                    </div>
                                                    <div
                                                      id="bdt"
                                                      className="statement-end-if-in-editor"
                                                    />
                                                    <span data-custom-class="body_text">
                                                      <span
                                                        style={{
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <div
                                                            id="bdt"
                                                            className="statement-end-if-in-editor"
                                                          />
                                                        </span>
                                                      </span>
                                                    </span>
                                                    <span data-custom-class="body_text">
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <span
                                                            style={{
                                                              color: 'rgb(89, 89, 89)',
                                                            }}
                                                          >
                                                            <div
                                                              id="bdt"
                                                              className="statement-end-if-in-editor"
                                                            >
                                                              <span
                                                                style={{
                                                                  color: 'rgb(89, 89, 89)',
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <div
                                                                      id="bdt"
                                                                      className="block-component"
                                                                    >
                                                                      <div
                                                                        id="bdt"
                                                                        className="block-component"
                                                                      />
                                                                    </div>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </div>
                                                          </span>
                                                        </span>
                                                      </span>
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <span
                                                            style={{
                                                              color: 'rgb(89, 89, 89)',
                                                              fontSize: 15,
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <div
                                                                  id="bdt"
                                                                  className="statement-end-if-in-editor"
                                                                >
                                                                  <div
                                                                    id="bdt"
                                                                    className="block-component"
                                                                  />
                                                                </div>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    id="request"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color: 'rgb(127, 127, 127)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          color: 'rgb(89, 89, 89)',
                                                          fontSize: 15,
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color: 'rgb(89, 89, 89)',
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color: 'rgb(89, 89, 89)',
                                                            }}
                                                          >
                                                            <span
                                                              id="control"
                                                              style={{
                                                                color: 'rgb(0, 0, 0)',
                                                              }}
                                                            >
                                                              <strong>
                                                                <span data-custom-class="heading_1">
                                                                  13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE
                                                                  COLLECT FROM YOU?
                                                                </span>
                                                              </strong>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    className="flex-col"
                                                    style={{
                                                      lineHeight: '1.5',
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: 'rgb(89, 89, 89)',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: 'rgb(89, 89, 89)',
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <div
                                                            id="bdt"
                                                            className="block-component"
                                                          />
                                                          Based on the applicable laws of your country, you may have the
                                                          right to request access to the personal information we collect
                                                          from you, change that information, or delete it.{' '}
                                                          <div
                                                            id="bdt"
                                                            className="else-block"
                                                          />
                                                          To request to review, update, or delete your personal
                                                          information, please{' '}
                                                          <div
                                                            id="bdt"
                                                            className="block-component"
                                                          />
                                                          submit a request form by clicking&nbsp;
                                                        </span>
                                                        <span
                                                          style={{
                                                            color: 'rgb(48, 48, 241)',
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                              }}
                                                            >
                                                              <a
                                                                data-custom-class="link"
                                                                href="https://app.termly.io/notify/e90335b2-4a90-46bf-a07c-adbc67a1f766"
                                                                rel="noopener noreferrer"
                                                                target="_blank"
                                                              >
                                                                here
                                                              </a>
                                                            </span>
                                                          </span>
                                                        </span>
                                                        <div
                                                          id="bdt"
                                                          className="block-component"
                                                        >
                                                          <span data-custom-class="body_text" />
                                                        </div>
                                                      </span>
                                                    </span>
                                                    <span data-custom-class="body_text">.</span>
                                                  </div>
                                                  <style
                                                    dangerouslySetInnerHTML={{
                                                      __html:
                                                        '\n      ul {\n        list-style-type: square;\n      }\n      ul > li > ul {\n        list-style-type: circle;\n      }\n      ul > li > ul > li > ul {\n        list-style-type: square;\n      }\n      ol li {\n        font-family: Arial ;\n      }\n    ',
                                                    }}
                                                  />
                                                </div>
                                                <div
                                                  className="flex-col"
                                                  style={{
                                                    color: '#595959',
                                                    fontSize: 14,
                                                    fontFamily: 'Arial',
                                                    paddingTop: 16,
                                                  }}
                                                >
                                                  This privacy policy was created using Termly's{' '}
                                                  <a
                                                    style={{
                                                      color: 'rgb(48, 48, 241) !important',
                                                    }}
                                                    href="https://termly.io/products/privacy-policy-generator/"
                                                  >
                                                    Privacy Policy Generator
                                                  </a>
                                                  .
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
