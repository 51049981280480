import { Avatar, Box, Typography, styled } from '@mui/material';
import React, { FC, Fragment, useMemo } from 'react';
import { colors } from '../../../ui/shared/Colors';
import { typography } from '../../../ui/shared/TypographySharedElements';
import { IBadge } from '../../../Helpers/shared/badgesConfig';
import Img from '../../../Components/Images/Img';
import { isEmpty } from 'lodash';

interface BadgeGroupBoxProps {
  title: string;
  badges: IBadge[];
}

export const BadgeGroup: FC<BadgeGroupBoxProps> = ({ title, badges }) => {
  const MAX_NUMBER_OF_SIMULTANEOUS_BADGES = 4;

  const BadgesRendered = useMemo(() => {
    return badges.slice(0, MAX_NUMBER_OF_SIMULTANEOUS_BADGES).map((badge) => {
      if (badge.icon || badge.image) {
        return (
          <Fragment key={`${badge.name}-container`}>
            {badge.image && (
              <BadgeImage
                key={badge.name}
                src={badge?.image ?? null}
              />
            )}
            {badge.icon && (
              <BadgeIcon
                key={badge.name}
                className="material-symbols-outlined"
              >
                {badge.icon}
              </BadgeIcon>
            )}
          </Fragment>
        );
      }
    });
  }, [badges]);

  if (isEmpty(badges)) {
    return null;
  }

  return (
    <BadgeGroupBoxMainContainer>
      <BadgeGroupName>{title}</BadgeGroupName>
      <BadgeIconContainer>
        <>
          {BadgesRendered}
          {badges.length - BadgesRendered.length > 0 && (
            <EmptyBadge>{`+${badges.length - BadgesRendered.length}`}</EmptyBadge>
          )}
        </>
      </BadgeIconContainer>
    </BadgeGroupBoxMainContainer>
  );
};

const BadgeGroupBoxMainContainer = styled(Box, {
  name: 'BadgeGroupBoxMainContainer',
})({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '12px 28px',
  borderRadius: '12px',
  backgroundColor: 'rgba(242, 240, 236, 0.5)',
});

const BadgeGroupName = styled(Typography, {
  name: 'BadgeGroupName',
})({
  color: colors.SystemGray900,
  ...typography.subtitle,
});

const BadgeIconContainer = styled(Box, {
  name: 'BadgeIconContainer',
})({
  display: 'flex',
  flexGrow: 1,
  justifyContent: 'flex-end',
});

const BadgeImage = styled(Img, {
  name: 'BadgeImage',
})(({ theme }) => ({
  width: '48px',
  height: '48px',
  marginLeft: '-16px',
  borderRadius: '50%',
  [theme.breakpoints.down('lg')]: {
    width: '36px',
    height: '36px',
  },
}));

const BadgeIcon = styled('i', {
  name: 'BadgeIcon',
})(({ theme }) => ({
  fontSize: '48px',
  width: '48px',
  height: '48px',
  marginLeft: '-16px',
  borderRadius: '50%',
  [theme.breakpoints.down('lg')]: {
    width: '36px',
    height: '36px',
  },
}));

const EmptyBadge = styled(Avatar, {
  name: 'EmptyBadge',
})(({ theme }) => ({
  width: '48px',
  height: '48px',
  marginLeft: '-16px',
  backgroundColor: colors.SystemGray900,
  color: colors.SystemWhite,
  ...typography.headlineSmall,
  [theme.breakpoints.down('lg')]: {
    width: '36px',
    height: '36px',
    ...typography.bodyMediumBold,
  },
}));
