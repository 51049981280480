import React, { useMemo, useEffect, useRef, useState, useCallback } from 'react';
import { styled, Box, Typography } from '@mui/material';
import { useGetAllArtistsV2Query } from '../../Redux/API/ArtistAPI';
import ArtistCard from './ExploreArtistCard';
import { typography } from '../../ui/shared/TypographySharedElements';
import { colors } from '../../ui/shared/Colors';
import { IconButton } from '../../ui/buttons/IconButton';
import { isEmpty, isEqual } from 'lodash';
/**
 * @param title the title of the category
 * @param search the search phrase
 * @param hatched whether to filter by hatched artists
 * @param genre the genre to filter by
 * @param sortBy the sort order 'mostVenues' or 'mostShows'
 * @param maxLength the maximum number of artists to display
 * @param minLength the minimum number of artists to display
 * @param wrap whether to wrap the artists to the next line when they don't fit in the container, also hides the scroll buttons
 */

export default function ArtistCategory(props: {
  title?: string;
  search?: string;
  hatched?: 'true' | 'false';
  genre?: string;
  sortBy?: 'mostVenues' | 'mostShows';
  maxLength?: number;
  minLength?: number;
  hideIfHatchy?: boolean;
  emptyMessage?: string;
  wrap?: boolean;
}) {
  const [page, setPage] = useState(1);
  const [artistList, setArtistList] = useState<any[]>([]);
  const searchFilters = {
    phrase: props.search,
    hatched: props.hatched,
    genre: props.genre,
    sortBy: props.sortBy,
  };
  const [prevSearchFilters, setPrevSearchFilters] = useState(searchFilters);
  const { data: artists, isFetching } = useGetAllArtistsV2Query({ ...searchFilters, page: page.toString() });
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);

  const cardListRef = useRef<HTMLDivElement>(null);

  // enable/disable scroll buttons based on scroll position
  const updateScrollButtons = useCallback(() => {
    if (!cardListRef.current) return;

    const { scrollLeft, scrollWidth, clientWidth } = cardListRef.current;
    setCanScrollLeft(scrollLeft > 0);
    setCanScrollRight(scrollLeft < scrollWidth - clientWidth);
  }, [cardListRef.current]);

  const handleScroll = useCallback(() => {
    if (cardListRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = cardListRef.current;
      // if we are at the end of the list, fetch the next page
      if (scrollLeft + clientWidth >= scrollWidth - 100 && !isFetching) setPage((prevPage) => prevPage + 1);
      // enable/disable scroll buttons
      updateScrollButtons();
    }
  }, [isFetching, cardListRef.current]);

  useEffect(() => {
    updateScrollButtons();
    cardListRef.current?.addEventListener('scroll', handleScroll);
    return () => {
      cardListRef.current?.removeEventListener('scroll', handleScroll);
    };
  }, [cardListRef.current, artistList]); // cardListRef.current is null when artistList is empty for some reason

  // convert artists object to array and append it to artistList
  useEffect(() => {
    // reset artistList when search, hatched, genre, or sortBy changes
    // we do this here to ensure it would run before we append new artists
    if (!isEqual(searchFilters, prevSearchFilters)) {
      setPrevSearchFilters(searchFilters);
      if (page !== 1) {
        // if we are not on the first page, reset the page to 1 which will call the (probably cached) api again
        setArtistList([]);
        setPage(1);
      } else {
        // otherwise update with what we have since setArtistList only actually updates after the useEffect finishes :(
        setArtistList(artists?.data.map((artist: any) => artist));
      }
      return;
    }
    // if we should have have more artists than we currently have, and there are artists to add, add them
    if (Math.min(artists?.page * artists?.perPage, artists?.total) > artistList.length && !isEmpty(artists?.data)) {
      setArtistList((prevList) => [...prevList, ...artists?.data.map((artist: any) => artist)]);
      // re-enable right scroll since we have more artists
      setCanScrollRight(true);
    }
  }, [artists?.data, page]);

  if (!artistList?.length || artistList.length < (props.minLength || 0)) {
    return props.emptyMessage ? <EmptyMessage>{props.emptyMessage}</EmptyMessage> : null;
  }

  return (
    <CategoryContainer>
      <TitleRow>
        <CategoryTitle>{props.title}</CategoryTitle>
        {!props.wrap && (
          <ArrowButtonsContainer>
            <IconButton
              isPrimary
              onClick={() => (cardListRef.current.scrollLeft -= 400)}
              disabled={!canScrollLeft}
              size="small"
              iconName="chevron_left"
            />
            <IconButton
              isPrimary
              onClick={() => (cardListRef.current.scrollLeft += 400)}
              disabled={!canScrollRight}
              size="small"
              iconName="chevron_right"
            />
          </ArrowButtonsContainer>
        )}
      </TitleRow>
      <CardList
        ref={cardListRef}
        wrap={props.wrap}
      >
        {artistList.map((artist, i) => {
          if (!props.maxLength || i <= props.maxLength) {
            return (
              <ArtistCard
                hideIfHatchy={props.hideIfHatchy}
                key={artist.id + '/' + i}
                artist={artist}
              />
            );
          }
        })}
      </CardList>
    </CategoryContainer>
  );
}

const CategoryContainer = styled(Box, {
  name: 'CategoryContainer',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '1280px',
  gap: '32px',
  [theme.breakpoints.down('lg')]: {
    gap: '24px',
  },
}));

const TitleRow = styled(Box, {
  name: 'TitleRow',
})({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const CategoryTitle = styled(Typography, {
  name: 'CategoryTitle',
})(({ theme }) => ({
  ...typography.headlineLarge,
  fontFamily: 'Satoshi-Variable',
  color: theme.palette.text.primary,
  [theme.breakpoints.down('lg')]: {
    ...typography.headlineMedium,
  },
}));

const ArrowButtonsContainer = styled(Box, {
  name: 'ArrowButtonsContainer',
})({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',
});

const CardList = styled(Box, {
  name: 'CardList',
})(({ wrap }: { wrap: boolean }) => ({
  display: 'flex',
  justifyContent: wrap ? 'center' : 'normal',
  flexWrap: wrap ? 'wrap' : 'nowrap',
  overflowX: 'auto',
  overflowY: 'hidden',
  gap: '16px',
}));

const EmptyMessage = styled(Typography, {
  name: 'EmptyMessage',
})(({ theme }) => ({
  ...typography.bodyMediumRegular,
  color: theme.palette.text.secondary,
}));
