import Lo from 'lodash';
import { ShowApi } from '../../Redux/API/ShowAPI';

function mapFormToTicketTiers(form: any): ShowApi.TiersTicketType['tiers'] {
  return Object.entries(form).reduce((ticketTiers, [formKey]) => {
    if (formKey.includes('ticket_tiers')) {
      const index = formKey.split('.')[1];
      return {
        ...ticketTiers,
        [index]: {
          name: Lo.get(form, `ticket_tiers.${index}.name`),
          description: Lo.get(form, `ticket_tiers.${index}.description`),
          price: Number(Lo.get(form, `ticket_tiers.${index}.price`)),
          quantity: Number(Lo.get(form, `ticket_tiers.${index}.quantity`)),
        },
      };
    }
    return ticketTiers;
  }, {});
}

function mapFormToPerformers(form: any): ShowApi.CreateShowBasePayload['performers'] {
  const formPerformers = Lo.get(form, 'performers');
  const performers = Array.isArray(formPerformers) ? formPerformers : [];

  return performers.map((performer: unknown) => {
    const id = String(Lo.get(performer, 'id'));
    const name = String(Lo.get(performer, 'name'));
    const email = String(Lo.get(performer, 'email'));

    if (id !== '0') {
      return {
        isNew: false,
        id,
      };
    }
    return {
      isNew: true,
      email,
      stageName: name,
    };
  });
}

function mapFormToSRGroups(form: any): ShowApi.CreateShowBasePayload['showrunnerGroups'] {
  const formSRGroups = Lo.get(form, 'showrunner');
  const srGroups = Array.isArray(formSRGroups) ? formSRGroups : [];

  return srGroups.map((performer: unknown) => {
    const id = String(Lo.get(performer, 'id'));
    const name = String(Lo.get(performer, 'name'));
    const email = String(Lo.get(performer, 'email'));

    if (id !== '0') {
      return {
        isNew: false,
        id,
      };
    }
    return {
      isNew: true,
      email,
      name,
    };
  });
}

function mapFormToRepeatSchema(form: any): ShowApi.CreateShowBasePayload['repeatSchema'] {
  const isRepeatingShow = Lo.get(form, 'meta.repeatingShow');
  if (isRepeatingShow !== true) {
    return null;
  }

  const repeatType = Lo.get(form, 'meta.repeatType');
  const repeatNumber = Number(Lo.get(form, 'meta.repeatNumber'));
  return {
    type: repeatType === 'monthly' ? 'monthly' : 'weekly',
    times: repeatNumber,
  };
}

function mapFormToDeal(form: any): ShowApi.CreateShowBasePayload['deal'] {
  const dealType = Lo.get(form, 'deal.type');
  if (dealType === 'guarantee') {
    return {
      type: 'guarantee',
      defaults: {
        guarantee: Number(Lo.get(form, 'deal.defaults.guarantee')),
        production_fee: Number(Lo.get(form, 'deal.defaults.production_fee')),
      },
    };
  }
  if (dealType === 'ticket_split') {
    return {
      type: 'ticket_split',
      defaults: {
        percentage: Number(Lo.get(form, 'deal.defaults.percentage')),
        guarantee: Number(Lo.get(form, 'deal.defaults.guarantee')),
        production_fee: Number(Lo.get(form, 'deal.defaults.production_fee')),
        even_split: Boolean(Lo.get(form, 'deal.defaults.even_split')),
      },
    };
  }
  return {
    type: 'default',
  };
}

function mapFormToTickets(form: any): ShowApi.TiersTicketType | ShowApi.FlatTicketType {
  return Lo.get(form, 'meta.advancedTicketing')
    ? {
        type: 'tiers',
        tiers: mapFormToTicketTiers(form),
      }
    : {
        type: 'flat',
        cost: Number(Lo.get(form, 'ticket_cost')),
        doorPrice: Lo.get(form, 'doorPrice') ? Number(Lo.get(form, 'doorPrice')) : null,
        quantity: Number(Lo.get(form, 'capacity')),
      };
}

export function mapFormToCreateShowBasePayload(form: any): ShowApi.CreateShowBasePayload {
  return {
    name: Lo.get(form, 'name'),
    description: Lo.get(form, 'description'),
    startTime: Lo.get(form, 'starttime'),
    endTime: Lo.get(form, 'endtime'),
    tickets: mapFormToTickets(form),
    minAge: (() => {
      const minAge = Lo.get(form, 'min_age');
      return minAge ? Number(minAge) : null;
    })(),
    isPrivate: Boolean(Lo.get(form, 'private')),
    isManageableByPerformers: Boolean(Lo.get(form, 'cohosted')),
    base64Flyer: Lo.get(form, 'flyer') ?? null,
    calendarColor: String(Lo.get(form, 'calTag')),
    repeatSchema: mapFormToRepeatSchema(form),
    performers: mapFormToPerformers(form),
    showrunnerGroups: mapFormToSRGroups(form),
    deal: mapFormToDeal(form),
  };
}

function mapFormToVenue(form: any) {
  const venue = Lo.get(form, 'venues.0');
  const id = String(Lo.get(venue, 'id'));
  const name = String(Lo.get(venue, 'name'));
  const email = String(Lo.get(venue, 'email'));
  const bookingContactName = String(Lo.get(venue, 'bookingContactName'));
  const address = String(Lo.get(venue, 'location.address'));
  const city = String(Lo.get(venue, 'location.city'));
  const state = String(Lo.get(venue, 'location.state'));
  const zip = String(Lo.get(venue, 'location.zip'));

  if (id !== '0') {
    const isEdited = !!email;
    if (isEdited) {
      return {
        isNew: false,
        isEdited: true,
        id,
        email,
      };
    }

    return {
      isNew: false,
      id,
    };
  }
  return {
    isNew: true,
    email,
    name,
    bookingContactName,
    location: {
      address,
      city,
      state,
      zip,
    },
  };
}

export function mapFormToCreateShowByArtistPayload(form: any): ShowApi.CreateShowByArtist.Params {
  return {
    ...mapFormToCreateShowBasePayload(form),
    venue: mapFormToVenue(form),
  };
}

export function mapFormToCreateShowBySRGroupPayload(params: {
  form: any;
  srGroupId: string;
}): ShowApi.CreateShowBySRGroup.Params {
  return {
    ...mapFormToCreateShowBasePayload(params.form),
    venue: mapFormToVenue(params.form),
    srGroupId: params.srGroupId,
  };
}

export function mapFormToCreateShowByVenuePayload(params: {
  form: any;
  venueId: string;
}): ShowApi.CreateShowByVenue.Params {
  return {
    ...mapFormToCreateShowBasePayload(params.form),
    venueId: params.venueId,
    type: (() => {
      const type = Lo.get(params.form, 'meta.showType');
      return type === 'IKWP' ? 'IKWP' : 'Gig';
    })(),
  };
}

export function mapFormToEditShowDetailsPayload(form: any): ShowApi.EditShowDetails.Params {
  return {
    name: Lo.get(form, 'name'),
    description: Lo.get(form, 'description'),
    startTime: Lo.get(form, 'starttime'),
    endTime: Lo.get(form, 'endtime'),
    tickets: mapFormToTickets(form),
    minAge: (() => {
      const minAge = Lo.get(form, 'min_age');
      return minAge ? Number(minAge) : null;
    })(),
    isPrivate: Boolean(Lo.get(form, 'private')),
    isManageableByPerformers: Boolean(Lo.get(form, 'cohosted')),
    calendarColor: String(Lo.get(form, 'calTag')),
    showrunnerGroups: mapFormToSRGroups(form),
    showId: Lo.get(form, 'showID'),
  };
}
