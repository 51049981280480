import { Box, styled, useMediaQuery, useTheme } from '@mui/material';
import React, { FC, useCallback, useRef, useState } from 'react';
import LogoWhite from '../../Images/TextLogoWhite.svg';
import LogoBlack from '../../Images/TextLogoBlack.svg';
import { colors } from '../../ui/shared/Colors';
import { useAppSelector } from '../../hooks';
import { typography } from '../../ui/shared/TypographySharedElements';
import { Link } from 'react-router-dom';
import { Login } from './Login';
import { ProfileUserMenu } from './ProfileUserMenu';
import { isEmpty } from 'lodash';
import { HamburgerMenu } from './HamburgerMenu';

export const Navbar: FC = () => {
  const userData = useAppSelector((state) => state.user.data);
  const darkMode = useAppSelector((state) => state.ui.grid.darkMode);
  // wtf is going on here ? I got critical errors that property navbar does not exists. I did ducktape fix.
  const navbarLogoOnly = useAppSelector((state) => state?.ui?.grid?.navbar?.logoOnly);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);
  const userMenuAnchorRef = useRef();
  const hamburgerMenuAnchorRef = useRef();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const toggleUserMenuOpen = useCallback(() => {
    setIsUserMenuOpen((prevState) => !prevState);
  }, []);
  const toggleHamburgerMenuOpen = useCallback(() => {
    setIsHamburgerMenuOpen((prevState) => !prevState);
  }, []);
  return (
    // NOTE: changes here do not affect the buttons in Navbar.tsx. This will cause mobile views to conflict with desktop if not updated in both places. @RDF
    <NavbarMainContainer
      ref={userMenuAnchorRef}
      darkMode={darkMode}
    >
      <NavbarMainInnerContainer>
        <a href="/">
          <NavbarLogo src={darkMode ? LogoWhite : LogoBlack} />
        </a>
        {!navbarLogoOnly && (
          <>
            <NavbarElements>
              <NavbarLinks>
                {!isEmpty(userData.displayUID) && (
                  <NavbarLinkItem
                    to="/apply"
                    darkMode={darkMode}
                  >
                    Explore Gigs
                  </NavbarLinkItem>
                )}
                <NavbarLinkItem
                  to="/shows"
                  darkMode={darkMode}
                >
                  Explore Shows
                </NavbarLinkItem>
                <NavbarLinkItem
                  to="/artists"
                  darkMode={darkMode}
                >
                  Explore Artists
                </NavbarLinkItem>
                {/* <NavbarLinkItem
                  to="/venues"
                  darkMode={darkMode}
                >
                  Resources
                </NavbarLinkItem> */}
              </NavbarLinks>
              {isEmpty(userData.displayUID) && !isMobile && (
                <>
                  <NavbarLinkButton
                    href="/register"
                    isVenuesLink={false}
                    darkMode={darkMode}
                  >
                    Join as artist or promoter
                  </NavbarLinkButton>
                  <NavbarLinkButton
                    href="/register/venue"
                    isVenuesLink={true}
                    darkMode={darkMode}
                  >
                    Join as venue
                  </NavbarLinkButton>
                </>
              )}
              <Login toggleUserMenuOpen={toggleUserMenuOpen} />
              <Hamburger
                darkMode={darkMode}
                onClick={toggleHamburgerMenuOpen}
                isExpanded={isHamburgerMenuOpen}
                ref={hamburgerMenuAnchorRef}
              />
            </NavbarElements>
            <ProfileUserMenu
              isUserMenuOpen={isUserMenuOpen}
              setIsUserMenuOpen={setIsUserMenuOpen}
              parentRef={userMenuAnchorRef.current}
            />
            <HamburgerMenu
              isMenuOpen={isHamburgerMenuOpen}
              setIsMenuOpen={setIsHamburgerMenuOpen}
              parentRef={hamburgerMenuAnchorRef.current}
            />
          </>
        )}
      </NavbarMainInnerContainer>
    </NavbarMainContainer>
  );
};

const NavbarMainContainer = styled(Box, {
  name: 'NavbarMainContainer',
  shouldForwardProp: (prop) => prop !== 'darkMode',
})<{ darkMode: boolean }>(({ darkMode, theme }) => ({
  position: 'sticky',
  top: 0,
  left: 0,
  right: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '16px 80px',
  backgroundColor: darkMode ? colors.SystemBlack : colors.SystemWhite,
  borderBottom: `.5px solid ${colors.SystemGray400}`,
  zIndex: 20,
  [theme.breakpoints.down('lg')]: {
    padding: '16px 20px',
  },
  [theme.breakpoints.down('md')]: {
    padding: '20px',
  },
}));

const NavbarMainInnerContainer = styled(Box, {
  name: 'NavbarMainInnerContainer',
})({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  maxWidth: '1440px',
  width: '100%',
});

const NavbarLogo = styled('img', {
  name: 'NavbarLogo',
})({
  width: '173px',
  height: 'auto',
});
const NavbarElements = styled(Box, {
  name: 'NavbarButtons',
})(({ theme }) => ({
  display: 'flex',
  gap: '32px',
  alignItems: 'center',
  [theme.breakpoints.down('lg')]: {
    gap: '16px',
  },
}));

const NavbarLinks = styled(Box, {
  name: 'NavbarLinks',
})(({ theme }) => ({
  display: 'flex',
  gap: '40px',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    gap: '20px',
    display: 'none',
  },
}));

const NavbarLinkItem = styled(Link, {
  name: 'NavbarLinkItem',
  shouldForwardProp: (prop) => prop !== 'darkMode',
})<{ darkMode: boolean }>(({ darkMode }) => ({
  color: darkMode ? colors.SystemWhite : colors.SystemBlack,
  ...typography.bodyMediumRegular,
  cursor: 'pointer',
  '&:hover': {
    color: colors.THOrange,
  },
}));

export const NavbarLinkButton = styled('a', {
  name: 'NavbarLinkButton',
  shouldForwardProp: (prop) => prop !== 'isVenuesLink' && prop !== 'darkMode',
})<{ isVenuesLink: boolean; darkMode: boolean }>(({ isVenuesLink, darkMode, theme }) => ({
  padding: '8px 20px',
  backgroundColor: isVenuesLink ? (darkMode ? colors.SystemGray100 : colors.THOrange) : colors.SystemGray700,
  color: isVenuesLink ? (darkMode ? colors.SystemGray900 : colors.SystemWhite) : colors.SystemWhite,
  borderRadius: '999px',
  ...typography.bodySmallBold,
  '&:hover': {
    backgroundColor: isVenuesLink ? (darkMode ? colors.SystemGray200 : '#FFB54B') : colors.SystemGray600,
    transition: 'all 0.2s ease-in',
  },
  [theme.breakpoints.down('lg')]: {
    display: 'block',
    width: 'calc(100% - 16px)',
    textAlign: 'center',
    padding: '12px auto',
  },
}));

const Hamburger = styled(Box, {
  name: 'Hamburger',
  shouldForwardProp: (prop) => prop !== 'darkMode' && prop !== 'isExpanded',
})<{ darkMode: boolean; isExpanded: boolean }>(({ darkMode, isExpanded, theme }) => ({
  display: 'none',
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    position: 'relative',
    width: '20px',
    height: isExpanded ? 0 : '2px',
    backgroundColor: darkMode ? colors.SystemWhite : colors.SystemGray900,
    margin: '0 8px',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: isExpanded ? 0 : '-6px',
      left: 0,
      width: '20px',
      height: '2px',
      backgroundColor: darkMode ? colors.SystemWhite : colors.SystemGray900,
      transform: isExpanded ? 'rotate(-45deg)' : 'none',
      transition: 'transform 0.5s ease-in-out',
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      top: isExpanded ? 0 : '6px',
      left: 0,
      width: '20px',
      height: '2px',
      backgroundColor: darkMode ? colors.SystemWhite : colors.SystemGray900,
      transform: isExpanded ? 'rotate(45deg)' : 'none',
      transition: 'transform 0.5s ease-in-out',
    },
  },
}));
