import ReactGA from 'react-ga4';
import { GTRACKID } from './Helpers/configConstants';

export const initGA = () => {
  if (GTRACKID) {
    console.log('Analytics ID found. Initializing.');
    ReactGA.initialize(GTRACKID);
  } else {
    console.log('GTRACKID NOT INITIALIZED');
  }
};

export const logPageView = () => {
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
};
