import { Box, Link, Typography, styled } from '@mui/material';
import React, { FC } from 'react';

import { typography } from '../shared/TypographySharedElements';
import { colors } from '../shared/Colors';
import { HatchLinkProps } from './HatchLinksList';

export const HatchLinkNew: FC<HatchLinkProps> = ({ text, url, openInNewTab, darkMode }) => {
  return text && url ? (
    <HatchLinksLinkElement
      href={`${url.includes('http://') || url.includes('https://') ? url : 'http://' + url}`}
      target={openInNewTab ? '_blank' : ''}
    >
      <HatchLinkMainContainer>
        <HatchLinkIconContainer>
          <HatchLinkIcon className="material-symbols-outlined">link</HatchLinkIcon>
        </HatchLinkIconContainer>
        <HatchLinkText>{text}</HatchLinkText>
      </HatchLinkMainContainer>
    </HatchLinksLinkElement>
  ) : (
    <></>
  );
};
const HatchLinksLinkElement = styled(Link, {
  name: 'HatchLinksLinkElement',
})({
  display: 'flex',
  textDecoration: 'none',
  width: '100%',
});

//todo: separate dark mode
const HatchLinkMainContainer = styled(Box, {
  name: 'HatchLinkMainContainer',
})({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  padding: '12px 12px 12px 12px',
  backgroundColor: colors.SystemWhite,
  borderRadius: '28px',
  gap: '16px',
  width: '100%',
  margin: '0 20px',
  // Causing issues on larger displays. Recommend assuming full container width and limiting container sizing from above component-level. -RDF
  // maxWidth: '800px',
});

const HatchLinkIconContainer = styled(Box, {
  name: 'HatchLinkIconContainer',
})({
  position: 'relative',
  width: '40px',
  height: '40px',
  backgroundColor: colors.SystemGray100,
  borderRadius: '999px',
});

const HatchLinkIcon = styled('i', {
  name: 'HatchLinkIcon',
})({
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: '24px',
  height: '24px',
  color: colors.SystemBlack,
});

const HatchLinkText = styled(Typography, {
  name: 'HatchLinkText',
})({
  width: 'calc(100% - 56px)',
  color: colors.SystemBlack,
  ...typography.buttonLarge,
  textAlign: 'center',
});
