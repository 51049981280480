import { Box } from '@mui/material';
import React, { Dispatch, FC, SetStateAction } from 'react';
import { Button } from '../../../ui/buttons/Button';

interface ShowFormNavButtonsProps {
  changeStep: (back?: boolean) => void;
  isSubmit?: boolean;
  submitFn?: () => void;
  disabledSubmit?: boolean;
  isSubmitLoading?: boolean;
}

export const ShowFormNavButtons: FC<ShowFormNavButtonsProps> = ({
  changeStep,
  isSubmit,
  submitFn,
  disabledSubmit,
  isSubmitLoading,
}) => {
  return (
    <Box
      display="flex"
      gap="16px"
      width="100%"
    >
      <Button
        disabled={false}
        onClick={() => changeStep(true)}
        size="large"
      >
        Back
      </Button>
      {!isSubmit && (
        <Box
          display="flex"
          flexDirection="column"
          flexGrow={1}
        >
          <Button
            isPrimary
            disabled={disabledSubmit ? disabledSubmit : false}
            onClick={() => changeStep()}
            size="large"
          >
            Next
          </Button>
        </Box>
      )}
      {isSubmit && (
        <Box
          display="flex"
          flexDirection="column"
          flexGrow={1}
        >
          <Button
            isPrimary
            disabled={disabledSubmit ? disabledSubmit : false}
            onClick={() => submitFn()}
            size="large"
            isLoading={isSubmitLoading}
          >
            Create Show
          </Button>
        </Box>
      )}
    </Box>
  );
};
