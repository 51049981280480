import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { QueryReturnValue } from '@reduxjs/toolkit/src/query/baseQueryTypes';
import { BadRequestError } from './RequestErrors';

export async function handleApiErrors<T>(
  callback: () => Promise<T>,
): Promise<QueryReturnValue<T, FetchBaseQueryError>> {
  try {
    const response = await callback();
    return { data: response, error: undefined, meta: undefined };
  } catch (error) {
    console.log(JSON.stringify(error), error);
    if (error instanceof BadRequestError) {
      return {
        error: {
          status: 'CUSTOM_ERROR',
          error: error.response.code,
          data: error.response.metadata,
        },
        data: undefined,
        meta: undefined,
      };
    } else {
      throw error;
    }
  }
}
