import React from 'react';
import { styled, Box, Typography } from '@mui/material';
import { useGetAllArtistsQuery, useGetAllVenuesQuery } from '../../Redux/API/PublicAPI';
import InfoLabel from '../../Components/Labels/InfoLabel';
import Img from '../../Components/Images/Img';
import { typography } from '../../ui/shared/TypographySharedElements';
import { colors } from '../../ui/shared/Colors';

/**
 * @param performances the performances to be displayed as an array of { venueID: string; count: number }
 */
export default function PerformanceHistoryLabel(props: { performances?: { venueID: string; count: number }[] }) {
  const venues = useGetAllVenuesQuery();

  if (!props.performances?.length)
    return (
      <PerformanceHistoryContainer>
        <NoPerformancesText>No performances yet</NoPerformancesText>
      </PerformanceHistoryContainer>
    );

  return (
    <InfoLabel
      label={
        <PerformanceHistoryContainer>
          <PerformanceHistoryTitle>Performances:</PerformanceHistoryTitle>
          <VenueIconContainer>
            {props.performances.map((performance, i) => {
              let venue = venues.data?.[performance.venueID];
              if (i < 5 && venue) {
                return (
                  <VenueIcon
                    key={'historyIcon/' + i}
                    src={venue.avatarWebP ?? venue.avatar}
                  />
                );
              }
            })}
          </VenueIconContainer>
        </PerformanceHistoryContainer>
      }
    >
      <PopupTextTitle>Performances</PopupTextTitle>
      {props.performances.map((performance, i) => {
        let venue = venues.data?.[performance.venueID];
        if (venue) {
          return (
            <PopupText key={'performanceCount/' + i}>
              {performance.count}x {venue.name}
            </PopupText>
          );
        }
      })}
    </InfoLabel>
  );
}

const PerformanceHistoryContainer = styled(Box, {
  name: 'PerformanceHistoryContainer',
})({
  width: '200px',
  height: '56px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '8px',
});

const PerformanceHistoryTitle = styled(Typography, {
  name: 'PerformanceHistoryTitle',
})({
  ...typography.subtitle,
  fontFamily: 'Satoshi-Variable',
});

const NoPerformancesText = styled(Typography, {
  name: 'NoPerformancesText',
})({
  ...typography.subtitle,
  fontFamily: 'Satoshi-Variable',
  color: colors.SystemGray500,
});

const VenueIconContainer = styled(Box, {
  name: 'VenueIconContainer',
})({
  display: 'flex',
  gap: '4px',
});

const VenueIcon = styled(Img, {
  name: 'VenueIcon',
})({
  width: '28px',
  height: '28px',
  borderRadius: '50%',
});

const PopupTextTitle = styled(Typography, {
  name: 'PopupTextTitle',
})({
  ...typography.bodySmallBold,
  fontFamily: 'Satoshi-Variable',
});

const PopupText = styled(Typography, {
  name: 'PopupText',
})({
  ...typography.bodySmallRegular,
  fontFamily: 'Satoshi-Variable',
});
