import { Box, Typography, styled } from '@mui/material';
import React, { FC, useCallback, useState } from 'react';
import { colors } from '../../ui/shared/Colors';
import { typography } from '../../ui/shared/TypographySharedElements';
import { Button } from '../../ui/buttons/Button';
import { IconButton } from '../../ui/buttons/IconButton';

interface ShareHatchLinksModalProps {
  closeModal: () => void;
  hatchLinksLink: string;
  username?: string;
}

export const ShareHatchLinksModal: FC<ShareHatchLinksModalProps> = ({ closeModal, hatchLinksLink, username }) => {
  const [linkCopied, setLinkCopied] = useState(false);

  const handleCopyLinkToClipboard = useCallback(() => {
    navigator.clipboard.writeText(hatchLinksLink);
    setLinkCopied(true);
  }, [hatchLinksLink]);

  const openHatchLinkPreview = useCallback(() => {
    window.open(hatchLinksLink, '_blank', 'noopener,noreferrer');
  }, [hatchLinksLink]);

  return (
    <ShareHatchLinksModalContainer>
      <ShareHatchLinksModalInnerContainer>
        <ShareHatchLinksModalHeading>
          <ShareHatchLinksModalTitle>What is HatchLink?</ShareHatchLinksModalTitle>
          <IconButton
            size="small"
            onClick={closeModal}
            iconName="close"
          />
        </ShareHatchLinksModalHeading>
        <ShareHatchLinksModalText>
          Hatchlink enables you to create a personalized, customizable page that showcases your upcoming shows and
          essential links for your audience. It's a link-in-bio solution that you can integrate into social platforms
          like Instagram, TikTok, Twitch, Facebook, YouTube, Twitter, LinkedIn, and more.
        </ShareHatchLinksModalText>
        <ShareHatchLinksModalText>Preview the link below and add it to your bio.</ShareHatchLinksModalText>
        {!username && (
          <ShareHatchLinksModalText bold>
            To claim your own custom tunehatch.link URL, fill out the HatchLinks Username section in your Artist
            Profile.
          </ShareHatchLinksModalText>
        )}
      </ShareHatchLinksModalInnerContainer>
      <ShareHatchLinksModalBottomContainer>
        <ShareHatchLinksModalLink>{hatchLinksLink}</ShareHatchLinksModalLink>
        <Box
          display="flex"
          flexGrow={0}
        >
          {!linkCopied ? (
            <Button
              size="small"
              isPrimary
              onClick={handleCopyLinkToClipboard}
            >
              Copy link
            </Button>
          ) : (
            <ShareHatchLinksModalText>Link copied!</ShareHatchLinksModalText>
          )}
        </Box>
      </ShareHatchLinksModalBottomContainer>
      <Button
        size="small"
        isPrimary
        onClick={openHatchLinkPreview}
      >
        Preview HatchLink
      </Button>
    </ShareHatchLinksModalContainer>
  );
};

const ShareHatchLinksModalContainer = styled(Box, {
  name: 'ShareHatchLinksModalContainer',
})({
  display: 'flex',
  flexDirection: 'column',
  padding: '24px',
  gap: '40px',
  backgroundColor: colors.SystemWhite,
  borderRadius: '16px',
  maxWidth: '520px',
  width: '100%',
  height: '100%',
});

const ShareHatchLinksModalInnerContainer = styled(Box, {
  name: 'ShareHatchLinksModalInnerContainer',
})({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
});

const ShareHatchLinksModalHeading = styled(Box, {
  name: 'ShareHatchLinksModalHeading',
})({
  display: 'flex',
  justifyContent: 'space-between',
});

const ShareHatchLinksModalTitle = styled(Typography, {
  name: 'ShareHatchLinksModalTitle',
})({
  color: colors.SystemGray900,
  ...typography.headlineSmall,
});

const ShareHatchLinksModalText = styled(Typography, {
  name: 'ShareHatchLinksModalText',
  shouldForwardProp: (prop) => prop !== 'grow',
})<{ bold?: boolean }>(({ bold }) => ({
  color: colors.SystemGray900,
  ...typography.bodyMediumRegular,
  fontWeight: bold ? typography.bodyMediumBold.fontWeight : typography.bodyMediumRegular.fontWeight,
}));

const ShareHatchLinksModalBottomContainer = styled(Box, {
  name: 'ShareHatchLinksModalBottomContainer',
})({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '12px 24px',
  alignItems: 'center',
  backgroundColor: colors.SystemGray100,
  maxWidth: '472px',
  width: '100%',
  gap: '16px',
  borderRadius: '12px',
  marginTop: '12px',
});

const ShareHatchLinksModalLink = styled(Typography, {
  name: 'ShareHatchLinksModalLink',
})({
  color: colors.SystemGray900,
  ...typography.bodyMediumRegular,
  textDecoration: 'underline',
  textWrap: 'wrap',
  display: 'flex',
  flexGrow: '1',
  wordBreak: 'break-all',
  overflowWrap: 'break-word',
});
