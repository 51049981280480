import { Box, Typography, styled } from '@mui/material';
import React, { FC } from 'react';
import { colors } from '../../ui/shared/Colors';
import { typography } from '../../ui/shared/TypographySharedElements';
import { useAppSelector } from '../../hooks';

export const Footer: FC = () => {
  const darkMode = useAppSelector((state) => state.ui.grid.darkMode);
  const transparentFooter = useAppSelector((state) => state.ui.grid.transparentFooter);
  return (
    <FooterMainContainer
      isDark={darkMode}
      transparentFooter={transparentFooter}
    >
      <FooterMainInnerContainer>
        <FooterLeftContainer href="/">
          <FooterLogoText isDark={darkMode}>TuneHatch</FooterLogoText>
          <FooterSublogoText>©2024 TuneHatch Inc.</FooterSublogoText>
        </FooterLeftContainer>
        <FooterRightContainer>
          <InnerLinkGroup>
            <FooterLink
              href="mailto:info@tunehatch.com"
              isDark={darkMode}
            >
              Contact
            </FooterLink>
            <FooterLink
              href="https://tunehatch.com/refunds"
              isDark={darkMode}
            >
              Refunds
            </FooterLink>
          </InnerLinkGroup>
          <InnerLinkGroup>
            <FooterLink
              href="https://tunehatch.com/privacy"
              isDark={darkMode}
            >
              Privacy Policy
            </FooterLink>
            <FooterLink
              href="https://tunehatch.com/tos"
              isDark={darkMode}
            >
              Terms of Service
            </FooterLink>
          </InnerLinkGroup>
        </FooterRightContainer>
      </FooterMainInnerContainer>
    </FooterMainContainer>
  );
};

const FooterMainContainer = styled(Box, {
  name: 'FooterMainContainer',
  shouldForwardProp: (prop) => prop !== 'isDark' && prop !== 'transparentFooter',
})<{ isDark: boolean; transparentFooter: boolean }>(({ isDark, transparentFooter, theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  padding: '32px 120px',
  marginTop: 'auto',
  backgroundColor: transparentFooter ? 'transparent' : isDark ? colors.SystemBlack : colors.SystemWhite,
  zIndex: 20,
  [theme.breakpoints.down('md')]: {
    padding: '32px 20px',
    flexDirection: 'column-reverse',
    gap: '32px',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
}));

const FooterMainInnerContainer = styled(Box, {
  name: 'FooterMainInnerContainer',
})(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  maxWidth: '1440px',
  width: '100%',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column-reverse',
    gap: '32px',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
}));

const FooterLeftContainer = styled('a', {
  name: 'FooterLeftContainer',
})({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  cursor: 'pointer',
});

const FooterLogoText = styled(Typography, {
  name: 'FooterLogoText',
  shouldForwardProp: (prop) => prop !== 'isDark',
})<{ isDark: boolean }>(({ isDark }) => ({
  display: isDark ? 'none' : 'inline',
  color: colors.SystemGray900,
  ...typography.headlineMedium,
}));

const FooterSublogoText = styled(Typography, {
  name: 'FooterSublogoText',
})({
  color: colors.SystemGray500,
  ...typography.subtitle,
});

const FooterRightContainer = styled(Box, {
  name: 'FooterRightContainer',
})(({ theme }) => ({
  display: 'flex',
  gap: '40px',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'space-between',
  },
}));
const InnerLinkGroup = styled(Box, {
  name: 'InnerLinkGroup',
})(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '40px',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    gap: '20px',
    alignItems: 'flex-start',
  },
}));

const FooterLink = styled('a', {
  name: 'FooterLink',
  shouldForwardProp: (prop) => prop !== 'isDark',
})<{ isDark: boolean }>(({ isDark }) => ({
  color: isDark ? colors.SystemWhite : colors.SystemGray900,
  textDecoration: 'none',
  cursor: 'pointer',
  ...typography.buttonLarge,
}));
