import { styled } from '@mui/material';
import { colors } from '../../../ui/shared/Colors';
import Img from '../../../Components/Images/Img';

export const IconBig = styled('i', {
  name: 'IconBig',
})({
  display: 'block',
  fontSize: '48px',
  width: '48px',
  height: '48px',
  color: colors.THOrange,
});

export const Avatar = styled(Img, {
  name: 'Avatar',
})({
  width: '80px',
  height: '80px',
  borderRadius: '9999px',
});
