import { Box, styled, Tab, Tabs, Typography } from '@mui/material';
import React, { FC, ReactNode, useCallback, useState } from 'react';
import { TabLabel } from './TabLabel';
import VenueIcon from '../../../Images/Homepage/VenuesIcon.svg';
import PerformersIcon from '../../../Images/Homepage/PerformersIcon.svg';
import ViewersIcon from '../../../Images/Homepage/ViewersIcon.svg';
import { colors } from '../../../ui/shared/Colors';
import { typography } from '../../../ui/shared/TypographySharedElements';
import { color } from 'html2canvas/dist/types/css/types/color';

interface HomepageTabsProps {
  venuesTabContent: ReactNode;
  performersTabContent: ReactNode;
  viewersTabContent: ReactNode;
}

type HomepageTabValue = 'venues' | 'performers' | 'viewers';

export const HomepageTabs: FC<HomepageTabsProps> = ({ venuesTabContent, performersTabContent, viewersTabContent }) => {
  const [tab, setTab] = useState<HomepageTabValue>('venues');

  const handleChangeTab = useCallback((e: React.SyntheticEvent, value: HomepageTabValue) => {
    e.preventDefault();
    setTab(value);
  }, []);

  return (
    <HomepageTabsMainContainer>
      <TabsTitle>Discover TuneHatch</TabsTitle>
      <StyledTabs
        centered
        value={tab}
        onChange={handleChangeTab}
      >
        <StyledTab
          value="venues"
          label={
            <TabLabel
              icon={VenueIcon}
              selected={tab === 'venues'}
              labelText="I manage a live music venue"
            />
          }
        />
        <StyledTab
          value="performers"
          label={
            <TabLabel
              icon={PerformersIcon}
              selected={tab === 'performers'}
              labelText="I perform at music venues"
            />
          }
        />
        <StyledTab
          value="viewers"
          label={
            <TabLabel
              icon={ViewersIcon}
              selected={tab === 'viewers'}
              labelText="I want to go see live music"
            />
          }
        />
      </StyledTabs>
      <TabPanel
        index="venues"
        value={tab}
      >
        {venuesTabContent}
      </TabPanel>
      <TabPanel
        index="performers"
        value={tab}
      >
        {performersTabContent}
      </TabPanel>
      <TabPanel
        index="viewers"
        value={tab}
      >
        {viewersTabContent}
      </TabPanel>
    </HomepageTabsMainContainer>
  );
};

//this will be in UI -> but after show creation form revamp is done we must change the place we take it from
interface TabPanelProps {
  children?: React.ReactNode;
  index: string;
  value: string;
}

const TabPanel: FC<TabPanelProps> = ({ children, value, index }) => {
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index && <Box width="100%">{children}</Box>}
    </Box>
  );
};
// Regular styled elements to leave here

const HomepageTabsMainContainer = styled(Box, {
  name: 'HomepageTabsMainContainer',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '40px',
  padding: '40px',
  zIndex: 2,
  [theme.breakpoints.down('md')]: {
    padding: 0,
  },
}));

const StyledTabs = styled(Tabs, {
  name: 'StyledTabs',
})(({ theme }) => ({
  height: '100%',
  '.MuiTabs-indicator': {
    backgroundColor: colors.SystemGray500,
    marginLeft: '2px',
    '&:before': {
      content: '" "',
      display: 'block',
      right: 0,
      top: 0,
      width: 'calc(100% - 4px)',
      height: '2px',
      backgroundColor: colors.SystemGray800,
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  '.MuiTabs-flexContainer': {
    width: '100vw',
    gap: '24px',
    borderBottom: `2px solid ${colors.SystemGray500}`,
    [theme.breakpoints.down('md')]: {
      paddingBottom: '40px',
      borderBottom: `2px solid ${colors.SystemGray700}`,
      flexDirection: 'column',
      gap: '24px',
      alignItems: 'center',
    },
  },
}));

const StyledTab = styled(Tab, {
  name: 'StyledTab',
})(({ theme }) => ({
  padding: '0',
  height: '100%',
  maxWidth: '384px',
  [theme.breakpoints.down('md')]: {
    padding: 0,
    width: '100%',
  },
}));

const TabsTitle = styled(Typography, {
  name: 'TabsTitle',
})(({ theme }) => ({
  color: colors.SystemWhite,
  ...typography.headlineLarge,
  textAlign: 'center',
  width: '100%',
  [theme.breakpoints.down('lg')]: {
    ...typography.headlineMedium,
  },
}));
