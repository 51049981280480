import { Box, styled } from '@mui/material';
import { colors } from '../../../ui/shared/Colors';

export const FormCont = styled('form', {
  name: 'FormCont',
})({
  display: 'flex',
  flexDirection: 'column',
  padding: '20px',
  maxWidth: '520px',
  width: '100%',
  gap: '40px',
});

export const FormSectionCont = styled(Box, {
  name: 'FormSectionCont',
})({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  marginBottom: '16px',
});

export const FormInnerCont = styled(Box, {
  name: 'FormInnerCont',
})({
  display: 'flex',
  flexDirection: 'column',
  padding: '20px',
  maxWidth: '520px',
  width: '100%',
  gap: '40px',
});

export const FormRowCont = styled(Box, {
  name: 'FormRowCont',
})<{ hidden?: boolean }>(({ hidden }) => ({
  display: 'flex',
  opacity: hidden ? 0 : 1,
  height: hidden ? '1px' : 'auto',
  width: '100%',
  gap: '16px',
  alignItems: 'flex-start',
}));

export const OffPlatformFormPartCont = styled(Box, {
  name: 'OffPlatformFormPartCont',
})<{ hiddenElements?: boolean }>(({ hiddenElements }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '12px',
  padding: '16px',
  gap: hiddenElements ? 0 : '16px',
  background: colors.SystemGray50,
}));
