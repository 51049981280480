import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { TextField } from '../../ui/inputs/TextField';
import { Button } from '../../ui/buttons/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { FormStyled, LeftHandSideContainerInner } from '../Register/Shared/Containers';
import { zodResolver } from '@hookform/resolvers/zod';
import z from 'zod';
import { SubmitHandler, useForm } from 'react-hook-form';
import axios from 'axios';
import { setCookie } from '../../Helpers/HelperFunctions';

interface ResetPasswordFormItems {
  newPassword: string;
  confirmPassword: string;
}
interface ResetPasswordMailItems {
  email: string;
}

const resetPassChangeSchema = z
  .object({
    newPassword: z
      .string({ message: 'The password must present' })
      .min(8, 'Password needs to be at least 8 characters long'),
    confirmPassword: z
      .string({ message: 'The password must present' })
      .min(8, 'Password needs to be at least 8 characters long'),
  })
  .superRefine(({ confirmPassword, newPassword }, ctx) => {
    if (confirmPassword !== newPassword) {
      ctx.addIssue({
        code: 'custom',
        message: 'Passwords do not match',
        path: ['confirmPassword'],
      });
    }
  });

const resetPassEmailSchema = z.object({
  email: z.string({ message: 'The email must present' }).email('The email must be a valid email'),
});

interface ResetPasswordFormProps {
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export const ResetPasswordForm: FC<ResetPasswordFormProps> = ({ setIsLoading }) => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const initialResetPassChangeState = {
    newPassword: '',
    confirmPassword: '',
  };
  const initialResetPassMailState = {
    email: '',
  };

  const resetPassChangeForm = useForm<ResetPasswordFormItems>({
    resolver: zodResolver(resetPassChangeSchema),
    defaultValues: initialResetPassChangeState,
    values: initialResetPassChangeState,
    mode: 'all',
  });

  const resetPassMailForm = useForm<ResetPasswordMailItems>({
    resolver: zodResolver(resetPassEmailSchema),
    defaultValues: initialResetPassMailState,
    values: initialResetPassMailState,
    mode: 'all',
  });

  const submitResetPassMail: SubmitHandler<ResetPasswordMailItems> = async (data) => {
    try {
      setIsLoading(true);
      const result = await axios.put(
        `${process.env.REACT_APP_PUBLIC_URL}:${process.env.REACT_APP_SERVER_PORT}/api/v2/me/password`,
        { email: data.email },
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: '*/*',
            'Accept-Encoding': 'gzip,deflate,br',
            Connection: 'keep-alive',
          },
        },
      );
      if (result.status === 202) {
        setIsLoading(false);
        navigate('/reset-password-link-sent');
      } else {
        setIsLoading(false);
        window.alert('Something went wrong');
      }
    } catch {
      setIsLoading(false);
      window.alert('Unknown server error');
    }
  };

  const submitResetPassChange: SubmitHandler<ResetPasswordFormItems> = async (data) => {
    setLoading(true);
    try {
      const result = await axios.put(
        `${process.env.REACT_APP_PUBLIC_URL}:${process.env.REACT_APP_SERVER_PORT}/api/v2/me/password`,
        {
          token: userId,
          newPassword: data.newPassword,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: '*/*',
            'Accept-Encoding': 'gzip,deflate,br',
            Connection: 'keep-alive',
          },
        },
      );
      setLoading(false);
      if (result.status === 201) {
        setCookie('SECRET_UID', result.data.token);
        navigate('/');
      } else {
        window.alert('Something went wrong');
      }
    } catch {
      setLoading(false);
      window.alert('Unknown server error');
    }
  };

  return (
    <>
      {!!userId ? (
        <FormStyled onSubmit={resetPassChangeForm.handleSubmit(submitResetPassChange)}>
          <LeftHandSideContainerInner>
            <TextField
              {...resetPassChangeForm.register('newPassword')}
              type="password"
              label="New password"
              placeholder="New password"
              error={!!resetPassChangeForm.formState.errors?.newPassword?.message}
              helperText={
                resetPassChangeForm.formState.errors?.newPassword?.message && (
                  <>{resetPassChangeForm.formState.errors?.newPassword?.message}</>
                )
              }
            />
            <TextField
              {...resetPassChangeForm.register('confirmPassword')}
              type="password"
              label="Confirm password"
              placeholder="Confirm password"
              error={!!resetPassChangeForm.formState.errors?.confirmPassword?.message}
              helperText={
                resetPassChangeForm.formState.errors?.confirmPassword?.message && (
                  <>{resetPassChangeForm.formState.errors?.confirmPassword?.message}</>
                )
              }
            />
          </LeftHandSideContainerInner>
          <Button
            isPrimary
            size="large"
            type="submit"
          >
            Reset password
          </Button>
        </FormStyled>
      ) : (
        <FormStyled onSubmit={resetPassMailForm.handleSubmit(submitResetPassMail)}>
          <LeftHandSideContainerInner>
            <TextField
              {...resetPassMailForm.register('email')}
              type="email"
              label="Email"
              placeholder="Enter your email"
              error={!!resetPassMailForm.formState.errors?.email?.message}
              helperText={
                resetPassMailForm.formState.errors?.email?.message && (
                  <>{resetPassMailForm.formState.errors?.email?.message}</>
                )
              }
            />
            <Button
              isPrimary
              size="large"
              type="submit"
              isLoading={loading}
              disabled={loading}
            >
              Reset password
            </Button>
          </LeftHandSideContainerInner>
        </FormStyled>
      )}
    </>
  );
};
