import { Box, Tab as MuiTab, Tabs as MuiTabs, styled } from '@mui/material';
import React from 'react';
import { FC } from 'react';
import { colors } from '../shared/Colors';
import { typography } from '../shared/TypographySharedElements';
import { textTransform } from 'html2canvas/dist/types/css/property-descriptors/text-transform';

export const Tabs = styled(MuiTabs, {
  name: 'Tabs',
})({
  '.MuiTabs-flexContainer': {
    justifyContent: 'center',
    borderBottom: `1px solid #9C9C9C`,
  },
  '.MuiTabs-indicator': {
    backgroundColor: colors.SystemBlack,
  },
});

export const Tab = styled(MuiTab, {
  name: 'Tab',
})({
  '&.MuiTab-root.MuiButtonBase-root.MuiTab-textColorPrimary': {
    color: '#9C9C9C',
    ...typography.buttonLarge,
    textTransform: 'capitalize',
    '&.Mui-selected': {
      color: colors.SystemBlack,
    },
  },
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: string;
  value: string;
}

export const TabPanel: FC<TabPanelProps> = ({ children, value, index }) => {
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index && <Box width="100%">{children}</Box>}
    </Box>
  );
};
