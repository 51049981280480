import { compact, isEmpty } from 'lodash';
import React, { useState, useEffect } from 'react';
import { VenueFormStep1 } from './VenueForm1';
import { VenueFormStep2 } from './VenueForm2';
import { VenueFormStep3 } from './VenueForm3';
import { VenueFormStep4 } from './VenueForm4';
import { FieldValues, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import axios, { AxiosError } from 'axios';
import { getClaimData } from '../Shared/ClaimDataHelpers';
import { VenueFormData } from '../../../Forms/AccountInfoForms/Venue/VenueFormData';
import { useAppDispatch } from '../../../hooks';
import { setNavbarLogoOnly } from '../../../Redux/UI/UISlice';
import { useCreateVenueProfileMutation, useEditVenueProfileMutation } from '../../../Redux/API/UserAPI';

export const VenueForm = () => {
  const parsedInitialData = getClaimData();
  const venueInitialData = parsedInitialData?.venues[0];
  const [step, setStep] = useState(1);
  const [avatar, setAvatar] = useState<File | null>(null);
  const [venueImages, setVenueImages] = useState<File[]>([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [editVenueProfile, editVenueProfileStatus] = useEditVenueProfileMutation();
  const [createVenueProfile, createVenueProfileStatus] = useCreateVenueProfileMutation();

  useEffect(() => {
    dispatch(setNavbarLogoOnly({ status: true }));
    return () => {
      dispatch(setNavbarLogoOnly({ status: false }));
    };
  }, []);

  useEffect(() => {}, [step]);

  const initialVenueFormValues: VenueFormData.Values = {
    venueName: venueInitialData?.name ?? '',
    ageRestriction: '',
    capacity: '',
    owner: {
      firstName: parsedInitialData?.firstName ?? '',
      lastName: parsedInitialData?.lastName ?? '',
    },
    contact: {
      phone: venueInitialData?.contact?.phone ?? '',
      email: venueInitialData?.contact?.email ?? '',
    },
    address: {
      displayAddress: compact([venueInitialData?.location?.address, venueInitialData?.location?.city]).join(', '),
      address: venueInitialData?.location?.address ?? '',
      city: venueInitialData?.location?.city ?? '',
      state: venueInitialData?.location?.state ?? '',
      zip: venueInitialData?.location?.zip ?? '',
      latitude: venueInitialData?.location?.latitude ?? null,
      longitude: venueInitialData?.location?.longitude ?? null,
    },
    operatingHours: null,
    socials: {
      instagram: null,
      tiktok: null,
      spotify: null,
      youtube: null,
    },
    about: venueInitialData?.description ?? '',
  };

  const venueForm = useForm({
    resolver: zodResolver(VenueFormData.formSchema),
    defaultValues: initialVenueFormValues,
    values: initialVenueFormValues,
    mode: 'all',
  });

  const submitVenue: SubmitHandler<FieldValues> = async (data: VenueFormData.Values) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append(
        'body',
        JSON.stringify({
          id: venueInitialData?.id,
          name: data.venueName,
          about: data.about,
          capacity: parseFloat(data.capacity),
          minAge: parseFloat(data.ageRestriction),
          owner: {
            firstName: data.owner.firstName,
            lastName: data.owner.lastName,
          },
          contact: {
            phone: data.contact.phone,
            email: data.contact.email,
          },
          socials: {
            instagramLink: data.socials.instagram ?? null,
            youtubeLink: data.socials.youtube ?? null,
            spotifyLink: data.socials.spotify ?? null,
            tikTokLink: data.socials.tiktok ?? null,
          },
          operatingHours: data.operatingHours,
          location: {
            address: isEmpty(data.address.address) ? data.address.displayAddress : data.address.address,
            city: data.address.city,
            state: data.address.state,
            zip: data.address.zip,
            latitude: data.address.latitude,
            longitude: data.address.longitude,
          },
        }),
      );
      formData.append('logo', avatar);
      venueImages.forEach((venueImage) => {
        return formData.append('images', venueImage);
      });

      const response = venueInitialData?.id ? await editVenueProfile(formData) : await createVenueProfile(formData);
      setLoading(false);
      if ('error' in response) {
        window.alert('Please fix any errors before saving.');
        setStep(1);
      } else {
        navigate('/register/success/venue');
      }
    } catch (error) {
      setLoading(false);
      if (error instanceof AxiosError && error.response.status === 403) {
        window.alert('');
        setStep(1);
      } else if (error instanceof AxiosError && error.response.status === 413) {
        window.alert('Uploaded images are too large');
        setStep(4);
      } else {
        setStep(1);
        window.alert('Unknown server error');
        console.log(error);
      }
    }
  };

  return (
    <FormProvider {...venueForm}>
      <form>
        {step === 1 && (
          <VenueFormStep1
            step={step}
            setStep={setStep}
          />
        )}
        {step === 2 && (
          <VenueFormStep2
            step={step}
            setStep={setStep}
          />
        )}
        {step === 3 && (
          <VenueFormStep3
            setAvatar={setAvatar}
            step={step}
            setStep={setStep}
            selectedAvatar={avatar}
          />
        )}
        {step === 4 && (
          <VenueFormStep4
            setVenueImages={setVenueImages}
            step={step}
            setStep={setStep}
            submitVenue={submitVenue}
            venueImages={venueImages}
            isLoading={loading}
          />
        )}
      </form>
    </FormProvider>
  );
};
