import React, { FC, useState } from 'react';
import { SpotifyEmbed } from './SpotifyEmbed';
import { Box, styled } from '@mui/material';
import { SpotifySkeleton } from './SpotifySkeleton';
import { Button } from '../../../ui/buttons/Button';
import SpoifyIconColor from '../../../Images/Icons/Spotify.webp';
import { RightHandSideComponentContainer } from '../Shared/SharedContainers';
import { RightHandSideComponentTitle, RightHandSideHeadingIcon } from '../Shared/SharedElements';

interface SpotifyProps {
  spotifyLink: string;
}

export const Spotify: FC<SpotifyProps> = ({ spotifyLink }) => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <RightHandSideComponentContainer>
      <SpotifyHeadingContainer>
        <SpotifyInnerHeadingContainer>
          <RightHandSideHeadingIcon src={SpoifyIconColor} />
          <RightHandSideComponentTitle>Spotify</RightHandSideComponentTitle>
        </SpotifyInnerHeadingContainer>
        <Button
          disabled={false}
          size="small"
          onClick={() => window.open(spotifyLink)}
        >
          Listen on Spotify
        </Button>
      </SpotifyHeadingContainer>
      {isLoading && <SpotifySkeleton />}
      <SpotifyEmbed
        link={spotifyLink}
        setIsLoading={setIsLoading}
        isLoading={isLoading}
      />
    </RightHandSideComponentContainer>
  );
};

const SpotifyHeadingContainer = styled(Box, {
  name: 'SpotifyHeadingContainer',
})({
  display: 'flex',
  justifyContent: 'space-between',
});

const SpotifyInnerHeadingContainer = styled(Box, {
  name: 'SpotifyInnerHeadingContainer',
})({
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
});
