import dayjs from 'dayjs';
import React, { FC } from 'react';
import { ExternalShowCardProps } from './ExternalShowCard';
import { SmallShowCardContainer, SmallShowCardVenueIconContainer, SmallShowCardText } from './SmallShowCard';

export const ExternalSmallShowCard: FC<ExternalShowCardProps> = ({ id, startTime, title, link }) => {
  const parsedDate = new Date(startTime);
  const month = dayjs(parsedDate).format('M');
  const day = parsedDate.getDate();

  return (
    <SmallShowCardContainer
      onClick={() => {
        /**
         * When the link does not contain the protocol (http/https) the link leads to the TH domain by default
         * By adding "//" we are ensuring that the link is opened outside of our domain.
         */
        const targetLink = (link ?? '').match(/^http[s]?:\/\//) ? link : '//' + link;
        window.open(targetLink, '_blank');
      }}
    >
      <SmallShowCardVenueIconContainer>{/* <SmallShowCardVenueIcon src={NotTH} /> */}</SmallShowCardVenueIconContainer>
      <SmallShowCardText>{`${month}/${day} • ${title}`}</SmallShowCardText>
    </SmallShowCardContainer>
  );
};
