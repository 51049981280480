import { Box, InputAdornment } from '@mui/material';
import { DataCy } from '../../../Helpers/DataCy';
import { TextField } from '../../../ui/inputs/TextField';
import { Button } from '../../../ui/buttons/Button';
import React, { FC, ChangeEvent, Dispatch, SetStateAction, useMemo } from 'react';
import { colors } from '../../../ui/shared/Colors';
import { ButtonIcon, SpecialButtonIcon } from '../../../ui/shared/ButtonIcon';
import { FormSectionContainer, FormInputGroupWrapper, FormInputGroupElementWrapper } from '../Shared/Containers';
import { FileInputLikeButton } from '../Shared/Other';
import { FormSectionTitle, FileInputLikeButtonText, GrayText } from '../Shared/RegistrationTypographies';
import { Avatar } from '../Shared/RegistrationIcons';
import AvatarImg from '../../../Images/Avatar.webp';
import { useFormContext } from 'react-hook-form';

interface PromoterPublicProfileProps {
  setAvatar: Dispatch<SetStateAction<File | null>>;
  selectedAvatar: File | null;
  defaultAvatar?: string | null;
  setForceDirty?: Dispatch<SetStateAction<boolean>>;
}

export const PromoterPublicProfileForm: FC<PromoterPublicProfileProps> = ({
  setAvatar,
  selectedAvatar,
  defaultAvatar,
  setForceDirty,
}) => {
  const promoterForm = useFormContext();

  const avatarPreview = useMemo<string | null>(() => {
    if (selectedAvatar === null) {
      return null;
    }
    return window.URL.createObjectURL(selectedAvatar);
  }, [selectedAvatar]);

  return (
    <>
      <FormSectionContainer>
        <FormSectionTitle>Upload profile photo</FormSectionTitle>
        <Box
          display="flex"
          alignItems="center"
          gap="16px"
        >
          <Avatar
            src={avatarPreview ?? defaultAvatar ?? AvatarImg}
            dataCy={DataCy.image('uploadedAvatar')}
          />
          <Box data-cy={'groupAvatar'}>
            <FileInputLikeButton
              name="avatar"
              type="file"
              size="small"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setAvatar(e.target.files[0] ?? null);
                setForceDirty?.(true);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <ButtonIcon
                      size="small"
                      disabled={false}
                      iconName="file_upload"
                    />
                    <FileInputLikeButtonText>Upload</FileInputLikeButtonText>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          {avatarPreview !== null && (
            <Button
              sx={{ backgroundColor: colors.AlertRedLight }}
              size="small"
              startIcon={
                <SpecialButtonIcon
                  disabled={false}
                  size="small"
                  iconName="close"
                />
              }
              onClick={() => setAvatar(null)}
            >
              Remove
            </Button>
          )}
        </Box>
      </FormSectionContainer>
      <FormSectionContainer>
        <FormSectionTitle>
          Add social links <GrayText>(Optional)</GrayText>
        </FormSectionTitle>
        <FormInputGroupWrapper>
          <FormInputGroupElementWrapper>
            <TextField
              {...promoterForm.register('socials.instagram')}
              type="text"
              label="Instagram"
              placeholder="Enter your Instagram username"
              error={promoterForm.getFieldState('socials.instagram').invalid}
              helperText={
                promoterForm.getFieldState('socials.instagram').invalid && (
                  <>{promoterForm.getFieldState('socials.instagram').error.message}</>
                )
              }
            />
          </FormInputGroupElementWrapper>
          <FormInputGroupElementWrapper>
            <TextField
              {...promoterForm.register('socials.tiktok')}
              type="text"
              label="TikTok"
              placeholder="Paste a link or write @username"
              error={promoterForm.getFieldState('socials.tiktok').invalid}
              helperText={
                promoterForm.getFieldState('socials.tiktok').invalid && (
                  <>{promoterForm.getFieldState('socials.tiktok').error.message}</>
                )
              }
            />
          </FormInputGroupElementWrapper>
        </FormInputGroupWrapper>
        <FormInputGroupWrapper>
          <FormInputGroupElementWrapper>
            <TextField
              {...promoterForm.register('socials.spotify')}
              type="text"
              label="Spotify"
              placeholder="Paste a link"
              error={promoterForm.getFieldState('socials.spotify').invalid}
              helperText={
                promoterForm.getFieldState('socials.spotify').invalid && (
                  <>{promoterForm.getFieldState('socials.spotify').error.message}</>
                )
              }
            />
          </FormInputGroupElementWrapper>
          <FormInputGroupElementWrapper>
            <TextField
              {...promoterForm.register('socials.youtube')}
              type="text"
              label="YouTube"
              placeholder="Paste a link"
              error={promoterForm.getFieldState('socials.youtube').invalid}
              helperText={
                promoterForm.getFieldState('socials.youtube').invalid && (
                  <>{promoterForm.getFieldState('socials.youtube').error.message}</>
                )
              }
            />
          </FormInputGroupElementWrapper>
        </FormInputGroupWrapper>
      </FormSectionContainer>
      <FormSectionContainer>
        <FormSectionTitle>
          Tell a few words about yourself <GrayText>(Optional)</GrayText>
        </FormSectionTitle>
        <TextField
          {...promoterForm.register('about')}
          type="text"
          label="About"
          placeholder="Write something about yourself"
          error={!!promoterForm.formState.errors?.about?.message}
          helperText={
            !!promoterForm.formState.errors?.about?.message && <>{promoterForm.formState.errors?.about?.message}</>
          }
          multiline
          minRows={5}
          maxRows={5}
        />
      </FormSectionContainer>
    </>
  );
};
