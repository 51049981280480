import { Box } from '@mui/material';
import React, { Dispatch, FC, SetStateAction } from 'react';

interface YouTubeEmbedProps {
  link: string;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
}

export const YouTubeEmbed: FC<YouTubeEmbedProps> = ({ link, setIsLoading, isLoading }) => {
  let short = false;
  if (link?.includes('shorts')) {
    short = true;
  }
  const videoID = link?.match(
    new RegExp(/.*?(?:youtu.be\/|v\/|u\/\w\/|embed\/|shorts\/|watch\?v=|\&v=)([^#\&\?\s]*).*/),
  )?.[1];
  const processedLink = 'https://www.youtube.com/embed/' + videoID;
  return (
    <Box sx={{ opacity: isLoading ? 0 : 1, height: '400px', width: '100%' }}>
      <iframe
        title="YouTube Video Embed"
        src={processedLink}
        width="100%"
        height="100%"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        loading="lazy"
        referrerPolicy="strict-origin-when-cross-origin"
        onLoad={() => setIsLoading(false)}
      ></iframe>
    </Box>
  );
};
