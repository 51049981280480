import { Avatar, Box, Divider, styled, TextField as MuiTextField } from '@mui/material';
import React, { ChangeEvent, Dispatch, FC, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { colors } from '../../../ui/shared/Colors';
import { HiddenField } from '../../../ui/inputs/HiddenField';
import { block } from 'sharp';
import { boxShadow } from 'html2canvas/dist/types/css/property-descriptors/box-shadow';
import { SingleColorToggle } from './SingleColorToggle';
import { getExtendedColorFromHex } from './getExtendedColorFromHex';

export type ColorExtended = {
  hex: string;
  isDark: boolean;
};

interface ColorPickersProps {
  avatarUrl: string;
  colorsToPickFrom: ColorExtended[];
  selectedColor: ColorExtended;
  setSelectedColor: Dispatch<SetStateAction<ColorExtended>>;
}

export const ColorPickers: FC<ColorPickersProps> = ({
  avatarUrl,
  colorsToPickFrom,
  selectedColor,
  setSelectedColor,
}) => {
  const [basicSelectedColor, setBasicSelectedColor] = useState<string>(null);

  const handleBasicColorToExtended = useCallback(async () => {
    const extendedColor = await getExtendedColorFromHex({ hex: basicSelectedColor });
    if (extendedColor) {
      setSelectedColor(extendedColor);
    }
  }, [basicSelectedColor]);

  useEffect(() => {
    if (basicSelectedColor) {
      handleBasicColorToExtended();
    }
  }, [basicSelectedColor]);

  return (
    <ColorPickerMainCont>
      <AvatarIcon src={avatarUrl} />
      <ColorPickerDivider orientation="vertical" />
      {colorsToPickFrom.map((color) => (
        <SingleColorToggle
          selected={selectedColor?.hex === color.hex}
          color={color}
          key={color.hex}
          setSelectedColor={setSelectedColor}
        />
      ))}
      <ColorInput
        name="colorPicker"
        InputProps={{
          type: 'color',
          startAdornment: (
            <ColorInputSemiButtonIcon className="material-symbols-outlined">colorize</ColorInputSemiButtonIcon>
          ),
        }}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          e.preventDefault();
          setBasicSelectedColor(e.target.value);
        }}
      />
    </ColorPickerMainCont>
  );
};

const ColorPickerMainCont = styled(Box, {
  name: 'ColorPickerMainCont',
})({
  display: 'flex',
  gap: '16px',
  alignItems: 'center',
});

const AvatarIcon = styled(Avatar, {
  name: 'AvatarIcon',
})({
  width: '80px',
  height: '80px',
  borderRadius: '9999px',
});

const ColorPickerDivider = styled(Divider, {
  name: 'ColorPickerDivider',
})({
  width: '1px',
  height: '40px',
  color: colors.SystemGray200,
});

const ColorInputSemiButtonIcon = styled('i', {
  name: 'ColorInputSemiButtonIcon',
})({
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  fontSize: '24px',
  color: colors.SystemGray900,
});

const ColorInput = styled(MuiTextField, {
  name: 'ColorInput',
})({
  display: 'flex',
  position: 'relative',
  justifyContent: 'center',
  alignItems: 'center',
  width: '48px',
  height: '48px',
  borderRadius: '9999px',
  backgroundColor: colors.SystemGray100,
  border: 'none',
  padding: 0,
  '&:hover': {
    border: 'none',
  },
  '.MuiInputBase-input': {
    opacity: 0,
    padding: 0,
    width: '100%',
    height: '100%',
  },
  '.MuiInputBase-root': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    width: '100%',
    height: '100%',
  },
  '.MuiOutlinedInput-notchedOutline': {
    display: 'none',
  },
});
