import Lo from 'lodash';
import z from 'zod';
import { locationValueSchema } from '../../../ui/inputs/LocationField';
import { HatchlinksFormData } from '../HatchLinks/HatchlinksFormData';
import { RegularExp } from '../../../Helpers/RegularExp';

export namespace ArtistFormData {
  export const step1Schema = z.object({
    firstName: z.string().min(1, 'You must enter your first name'),
    lastName: z.string().min(1, 'You must enter your last name'),
    stageName: z.string().nullable(),
    genre: z.string().refine((value) => Lo.isEmpty(value) == false && value !== 'none', 'You must choose a genre'),
    subGenre: z.string().nullable(),
    recordLabel: z.discriminatedUnion('signed', [
      z.object({
        signed: z.literal(false),
        label: z.null(),
      }),
      z.object({
        signed: z.literal(true),
        label: z.string().min(1, 'You must enter your record label'),
      }),
    ]),
    primaryCity: z.object({
      ...locationValueSchema.shape,
      displayAddress: z.string().min(1, 'Primary city is required'),
    }),
    secondaryCity: z.object({ ...locationValueSchema.shape }).nullish(),
    contactPhone: z.string().nullish(),
    contactEmail: z.string().nullish(),
  });
  export type Step1Values = z.output<typeof step1Schema>;
  export const step1Fields = Object.keys(step1Schema.shape) as (keyof Step1Values)[];

  export const step2Schema = z.object({
    instagram: z
      .string()
      .regex(RegularExp.InstagramUsername, 'Must be a valid Instagram username')
      .or(z.literal(''))
      .nullable(),
    tiktok: z.string().nullable(),
    spotify: z.string().nullable(),
    youtube: z
      .string()
      .regex(RegularExp.YoutubeVideo, 'The link must be a YouTube video URL, channels are not allowed')
      .or(z.literal(''))
      .nullable(),
    livePerformance: z
      .string()
      .regex(RegularExp.YoutubeVideo, 'The link must be a YouTube video URL')
      .or(z.literal(''))
      .nullable(),
    about: z.string().nullish(),
  });
  export type Step2Values = z.output<typeof step2Schema>;
  export const step2Fields = Object.keys(step2Schema.shape) as (keyof Step2Values)[];

  export const formSchema = step1Schema.merge(step2Schema);
  export const formSchemaFull = step1Schema.merge(step2Schema).merge(HatchlinksFormData.formSchema);
  export type Values = z.TypeOf<typeof formSchema>;
  export type ValuesFull = z.TypeOf<typeof formSchemaFull>;
}
