import { styled } from '@mui/material';
import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { TextField } from '../../../ui/inputs/TextField';
import { FormInputGroupElementWrapper, FormInputGroupWrapper, FormSectionContainer } from '../Shared/Containers';
import { FormSectionSubtitle, FormSectionTitle, GrayText } from '../Shared/RegistrationTypographies';
import { HatchlinksFormData } from './HatchlinksFormData';
import { Radio, RadioControlLabel, RadioGroup, RadioGroupLabel } from '../../../ui/inputs/Radio';
import { Button } from '../../../ui/buttons/Button';
import { HatchLinksColors } from '../../../Pages/EditProfile/HatchLinksColors/HatchLinksColors';
import testImg from '../../../Images/Artists/Alexandria_Corn.webp';
import { ColorExtended } from '../../../Pages/EditProfile/HatchLinksColors/ColorPickers';
import { ViewType } from '../../../Helpers/shared/Models/ViewType';

export const HatchlinksForm: FC<{
  setForceDirty?: Dispatch<SetStateAction<boolean>>;
  avatarUrl: string;
  profileName: string;
  viewType: ViewType;
  colors?: {
    profileColor?: ColorExtended;
    colorsToPickFrom?: ColorExtended[];
    hlColor?: ColorExtended;
  };
}> = ({ setForceDirty, avatarUrl, profileName, colors, viewType }) => {
  const hatchlinkForm = useFormContext<HatchlinksFormData.Values>();
  const [AlternateLabel, setAlternateLabel] = useState(
    hatchlinkForm.getValues('customOptions.alternativeHatchLinkLabel'),
  );

  const { fields, append, remove } = useFieldArray({
    control: hatchlinkForm.control,
    name: 'externalLinks',
  });

  // Manually sync with form
  const handleAlternateLabelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value === 'true';
    setAlternateLabel(value);
    hatchlinkForm.setValue('customOptions.alternativeHatchLinkLabel', value);
    setForceDirty?.(true);
  };

  return (
    <>
      <FormSectionContainer>
        <FormSectionTitle>Your Hatchlink</FormSectionTitle>
        <TextField
          {...hatchlinkForm.register('username')}
          type="text"
          label="Username"
          placeholder="Enter username"
          error={!!hatchlinkForm.formState.errors?.username?.message}
          helperText={
            !!hatchlinkForm.formState.errors?.username?.message && (
              <>{hatchlinkForm.formState.errors?.username?.message}</>
            )
          }
        />
        <FormSectionSubtitle>
          Your HatchLink will be accessible at https://tunehatch.link/{hatchlinkForm.watch('username')}
        </FormSectionSubtitle>
      </FormSectionContainer>
      <FormSectionContainer>
        <FormSectionTitle>Upcoming Show Preview</FormSectionTitle>
        <FormSectionSubtitle>
          All of your upcoming shows on TuneHatch are automatically added to your HatchLink. Choose how these shows are
          displayed below:
        </FormSectionSubtitle>
        <RadioGroup
          value={AlternateLabel}
          onChange={handleAlternateLabelChange}
        >
          <RadioControlLabel
            value={false}
            control={<Radio />}
            label={
              <>
                Date and City, State <GrayText>(ex. 7/30 • Nashville, TN)</GrayText>
              </>
            }
          />
          <RadioControlLabel
            value={true}
            control={<Radio />}
            label={
              <>
                Date and Show Title <GrayText>(ex. 7/30 • The Last Waltz)</GrayText>
              </>
            }
          />
        </RadioGroup>
      </FormSectionContainer>
      <FormSectionContainer>
        <HatchLinksColors
          avatarUrl={avatarUrl}
          profileName={profileName}
          colorsFromDB={colors}
          viewType={viewType}
          setForceDirty={setForceDirty}
        />
      </FormSectionContainer>
      <FormSectionContainer>
        <FormSectionTitle>
          Add links <GrayText>(Optional)</GrayText>
        </FormSectionTitle>
        <FormSectionSubtitle>
          You can add up to five external links here to update your HatchLink and profile.
        </FormSectionSubtitle>
        {fields.map((field, index) => (
          <FormInputGroupWrapper key={field.id}>
            <FormInputGroupElementWrapper>
              <TextField
                {...hatchlinkForm.register(`externalLinks.${index}.url`)}
                type="text"
                label={`Link ${index + 1}`}
                placeholder="Paste a link"
                error={!!hatchlinkForm.formState.errors?.externalLinks?.[index]?.url?.message}
                helperText={hatchlinkForm.formState.errors?.externalLinks?.[index]?.url?.message}
              />
            </FormInputGroupElementWrapper>
            <FormInputGroupElementWrapper>
              <TextField
                {...hatchlinkForm.register(`externalLinks.${index}.text`)}
                type="text"
                label={`Label ${index + 1}`}
                placeholder="Enter name"
                error={!!hatchlinkForm.formState.errors?.externalLinks?.[index]?.text?.message}
                helperText={hatchlinkForm.formState.errors?.externalLinks?.[index]?.text?.message}
              />
            </FormInputGroupElementWrapper>
            <RemoveLinkButton onClick={() => remove(index)}>Remove</RemoveLinkButton>
          </FormInputGroupWrapper>
        ))}
        {fields.length < 10 && <AddLinkButton onClick={() => append({ url: '', text: '' })}>Add Link</AddLinkButton>}
      </FormSectionContainer>
    </>
  );
};

const AddLinkButton = styled(Button, {
  name: 'AddLinkButton',
})({
  width: 'fit-content',
  alignSelf: 'center',
  height: '3.5rem',
});

const RemoveLinkButton = styled(Button, {
  name: 'RemoveLinkButton',
})({
  height: '3.5rem',
  alignSelf: 'flex-end',
});
