import BoweryVault from '../../../Images/Venues/the-bowery-vault.webp';
import The5Spot from '../../../Images/Venues/the-5-spot.webp';
import Mockingbird from '../../../Images/Venues/mockingbird.webp';
import Kimbros from '../../../Images/Venues/kimbros.webp';
import will from '../../../Images/Homepage/VenueTab/will.webp';
import toddtravis from '../../../Images/Homepage/VenueTab/toddtravis.png';
import vero from '../../../Images/Homepage/VenueTab/vero.webp';

export const venueTestimonials = [
  // {
  //         venue: {
  //             id: '12778804',
  //             name: 'The Underdog',
  //             avatarWebp: null,
  //         },
  //         person: {
  //             firstName: 'Amanda',
  //             lastName: '',
  //             title: "Owner",
  //             avatarWebp: ''
  //         },
  //         reviewText: `“I use SquareSpace and struggled to link my website to my ticket links. TuneHatch set up my website in under 30 minutes. Haven't had to update it since.”`
  // },
  {
    venue: {
      id: '14759623',
      name: 'The Mockingbird Theater',
      avatarWebp: Mockingbird,
    },
    person: {
      firstName: 'Will',
      lastName: '',
      title: 'Owner',
      avatarWebp: will,
    },
    reviewText: `“My previous ticketing provider made it so difficult to transfer money after a show. With TuneHatch I can split ticket sales directly with artists and into my venue account in just one click.”`,
  },
  {
    venue: {
      id: '45056',
      name: 'The 5 Spot',
      avatarWebp: The5Spot,
    },
    person: {
      firstName: 'Todd & Travis',
      lastName: '',
      title: 'Owners',
      avatarWebp: toddtravis,
    },
    reviewText: `“We exclusively used cash at the door for ticket sales until artists started creating their own ticket links. We realized we needed to start offering pre-sale tickets and TuneHatch helped get our process set up.”`,
  },
  {
    venue: {
      id: '345',
      name: 'The Bowery Vault',
      avatarWebp: BoweryVault,
    },
    person: {
      firstName: 'Vero',
      lastName: '',
      title: 'Owner',
      avatarWebp: vero,
    },
    reviewText: `“I book about 40 artists a week and it’s difficult to get everyone to send me the info I need. I post open gigs on TuneHatch and artists apply and I just review their profiles and confirm them through the site.”`,
  },
  {
    venue: {
      id: '15982909',
      name: `Kimbro's Picklin' Parlor`,
      avatarWebp: Kimbros,
    },
    person: {
      firstName: 'Will',
      lastName: '',
      title: 'Owner',
      avatarWebp: will,
    },
    reviewText: `“I book about 12 shows per week and I have to update my website, Facebook, and Instagram for every show. TuneHatch allows me to update all of it in one go."`,
  },

  // {
  //     venue: {
  //         id: '282507',
  //         name: 'Music Makers Stage',
  //         avatarWebp: null,
  //     },
  //     person: {
  //         firstName: 'Julie',
  //         lastName: '',
  //         title: "Owner",
  //         avatarWebp: ''
  //     },
  //     reviewText: `“I wanted to post shows somewhere local so people can find them quickly. TuneHatch is a great tool for independent venues because it’s a central location where people can find unique shows.”`
  // },
];
