import React, { FC, useCallback, Dispatch, SetStateAction } from 'react';
import { DataCy } from '../../../Helpers/DataCy';
import { DropZone } from '../../../ui/inputs/DropZone';
import { Button } from '../../../ui/buttons/Button';
import {
  FormStepHeadlineContainer,
  FormStepInnerContainer,
  FormTitleWithSubtitleContainer,
  FormStepFormContainerOuter,
  FormMainContainerColumn,
  FormMainContainer,
} from '../Shared/Containers';
import { FormStepHeadlineStepCounter, FormStepHeadlineTitle } from '../Shared/RegistrationTypographies';
import { FormStepProps } from '../Shared/Types';
import { FieldValues, SubmitHandler, useFormContext } from 'react-hook-form';
import { VenuePhotosForm } from '../../../Forms/AccountInfoForms/Venue/VenuePhotosForm';

interface VenueStep3Props extends FormStepProps {
  setVenueImages: Dispatch<SetStateAction<File[] | null>>;
  venueImages: File[];
  submitVenue: SubmitHandler<FieldValues>;
  isLoading?: boolean;
}

export const VenueFormStep4: FC<VenueStep3Props> = ({
  step,
  setStep,
  setVenueImages,
  submitVenue,
  venueImages,
  isLoading,
}) => {
  const venueForm = useFormContext();

  const handleBack = useCallback(() => {
    setStep((step) => step - 1);
  }, [setStep]);

  return (
    <FormMainContainerColumn>
      <FormStepHeadlineContainer>
        <FormStepInnerContainer>
          <FormTitleWithSubtitleContainer>
            <FormStepHeadlineStepCounter>Step 4 of 4</FormStepHeadlineStepCounter>
            <FormStepHeadlineTitle>Upload photos of your venue (optional)</FormStepHeadlineTitle>
          </FormTitleWithSubtitleContainer>
        </FormStepInnerContainer>
      </FormStepHeadlineContainer>
      <FormStepFormContainerOuter>
        <FormStepInnerContainer>
          <VenuePhotosForm
            setVenueImages={setVenueImages}
            venueImages={venueImages}
          />
          <FormMainContainer>
            <Button
              size="large"
              onClick={handleBack}
              data-cy={DataCy.button('prevStep')}
            >
              Back
            </Button>
            <FormMainContainerColumn>
              <Button
                isPrimary
                size="large"
                onClick={venueForm.handleSubmit(submitVenue, () => console.log(venueForm.getValues('operatingHours')))}
                data-cy={DataCy.button('createVenue')}
                disabled={isLoading}
                isLoading={isLoading}
              >
                Next
              </Button>
            </FormMainContainerColumn>
          </FormMainContainer>
        </FormStepInnerContainer>
      </FormStepFormContainerOuter>
    </FormMainContainerColumn>
  );
};
