import { zodResolver } from '@hookform/resolvers/zod';
import { Box, styled, Typography } from '@mui/material';

import React, { Dispatch, FC, SetStateAction, useCallback, useState } from 'react';
import { isAfter } from 'date-fns';
import { isEmpty } from 'lodash';
import { TitleBar } from '../Shared/TitleBar';
import { FormCont, FormSectionCont } from '../Shared/Containers';
import { SectionTitle } from '../Shared/TextElements';
import { TextField } from '../../../ui/inputs/TextField';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { ViewType } from '../../../Helpers/shared/Models/ViewType';
import {
  ShowApi,
  useCreateExternalShowByArtistMutation,
  useCreateExternalShowByShowrunnerMutation,
  useEditExternalShowByArtistMutation,
  useEditExternalShowByShowrunnerMutation,
} from '../../../Redux/API/ShowAPI';
import { Button } from '../../../ui/buttons/Button';
import { DateTimePicker } from '../../../ui/inputs/DateTimePicker';
import { colors } from '../../../ui/shared/Colors';
import { typography } from '../../../ui/shared/TypographySharedElements';

interface ExternalShow {
  link?: string;
  id?: string;
  startTime?: string;
  endTime?: string;
  title?: string;
}
interface ExternalTicketsFormProps {
  type: ViewType;
  SGID?: string;
  artistID?: string;
  isEdit?: boolean;
  showID?: string;
  show?: ExternalShow;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export type ExternalTicketsData =
  | ShowApi.CreateExternalShowByArtist.Params
  | ShowApi.CreateExternalShowByShowrunner.Params
  | ShowApi.EditExternalShowByArtist.Params
  | ShowApi.EditExternalShowByShowrunner.Params;

export const ExternalTicketsForm: FC<ExternalTicketsFormProps> = ({
  SGID,
  artistID,
  type,
  isEdit,
  showID,
  show,
  setOpen,
}) => {
  const [createExternalShowByArtist] = useCreateExternalShowByArtistMutation();
  const [createExternalShowByShowrunner] = useCreateExternalShowByShowrunnerMutation();
  const [editExternalShowByArtist] = useEditExternalShowByArtistMutation();
  const [editExternalShowByShowrunner] = useEditExternalShowByShowrunnerMutation();
  const [externalShowCreated, setExternalShowCreated] = useState(false);
  const [externalShowEdited, setExternalShowEdited] = useState(false);
  const [loading, setLoading] = useState(false);
  const formSchema = z
    .object({
      title: z.string().min(1, 'Title must be present'),
      startTime: z.date({ message: 'Start time must be selected' }),
      endTime: z.date({ message: 'End time must be selected' }),
      link: z.string().url('Show link must be present and be a proper link'),
    })
    .superRefine((args, ctx) => {
      if (isAfter(args.startTime, args.endTime)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Start date must be before end date',
          path: ['startTime'],
        });
      }
      if (isAfter(args.startTime, args.endTime)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'End date must be after start date',
          path: ['endTime'],
        });
      }
    });

  const initialExternalTicketsFormData: z.infer<typeof formSchema> = {
    title: show?.title ?? '',
    startTime: show?.startTime ? new Date(show?.startTime) : null,
    endTime: show?.endTime ? new Date(show?.endTime) : null,
    link: show?.link ?? '',
  };

  const externalTicketForm = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: initialExternalTicketsFormData,
    values: initialExternalTicketsFormData,
    mode: 'all',
  });

  const submitForm = useCallback(
    async (data: z.infer<typeof formSchema>) => {
      setLoading(true);
      await externalTicketForm.trigger(['title', 'startTime', 'endTime', 'title']);
      if (isEmpty(externalTicketForm.formState.errors)) {
        const parsedData = {
          title: data.title,
          startTime: data.startTime.toISOString(),
          endTime: data.endTime.toISOString(),
          link: data.link,
        };

        if (!isEdit) {
          if (type === ViewType.SRGroup) {
            const showrunnerDataToSend = {
              ...parsedData,
              SGID: SGID,
            };
            try {
              const res = await createExternalShowByShowrunner(showrunnerDataToSend);
              setExternalShowCreated(true);
              setLoading(false);
            } catch (err) {
              setLoading(false);
              window.alert('Something went wrong');
            }
          } else {
            const artistDataToSend = {
              ...parsedData,
              artistID: artistID,
            };
            try {
              const res = await createExternalShowByArtist(artistDataToSend);
              setLoading(false);
              setExternalShowCreated(true);
            } catch (err) {
              setLoading(false);
              window.alert('Something went wrong');
            }
          }
        } else {
          if (type === ViewType.SRGroup) {
            const showrunnerDataToSend = {
              ...parsedData,
              SGID: SGID,
              showID: showID,
            };
            try {
              const res = await editExternalShowByShowrunner(showrunnerDataToSend);
              setExternalShowEdited(true);
              setLoading(false);
            } catch (err) {
              setLoading(false);
              window.alert('Something went wrong');
            }
          } else {
            const artistDataToSend = {
              ...parsedData,
              artistID: artistID,
              showID: showID,
            };
            try {
              const res = await editExternalShowByArtist(artistDataToSend);
              setExternalShowEdited(true);
              setLoading(false);
            } catch (err) {
              setLoading(false);
              window.alert('Something went wrong');
            }
          }
        }
      }
    },
    [
      type,
      artistID,
      SGID,
      createExternalShowByArtist,
      createExternalShowByShowrunner,
      editExternalShowByArtist,
      editExternalShowByShowrunner,
      isEdit,
      showID,
    ],
  );
  if (externalShowCreated || externalShowEdited) {
    return (
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <TitleBar
          formName="External Ticket Link"
          stepTitle="Add your external ticket"
          step={1}
          maxSteps={1}
          setOpen={setOpen}
        />
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          paddingTop="40px"
        >
          <SuccessTypography>{`Your show was succesfully ${externalShowCreated ? 'created' : 'edited'}!`}</SuccessTypography>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <TitleBar
        formName="External Ticket Link"
        stepTitle="Add your external ticket"
        step={2}
        maxSteps={2}
        setOpen={setOpen}
      />
      <FormCont onSubmit={externalTicketForm.handleSubmit(submitForm)}>
        <FormSectionCont>
          <SectionTitle>External ticket information</SectionTitle>

          <TextField
            {...externalTicketForm.register('title')}
            type="text"
            label="Show Title"
            placeholder="Enter the show title"
            error={!!externalTicketForm.formState.errors?.title?.message}
            helperText={
              !!externalTicketForm.formState.errors?.title?.message && (
                <>{externalTicketForm.formState.errors?.title?.message}</>
              )
            }
          />
          <DateTimePicker
            name="startTime"
            disablePast
            control={externalTicketForm.control}
            defaultValue={new Date()}
            disabled={false}
            onDateChange={(date: Date) => {
              externalTicketForm.setValue('startTime', date);
              externalTicketForm.trigger('startTime');
              externalTicketForm.trigger('endTime');
            }}
            label="Enter start time"
            error={!!externalTicketForm.getFieldState('startTime').error?.message}
            helperText={
              !!!!externalTicketForm.getFieldState('startTime').error?.message && (
                <>{externalTicketForm.getFieldState('startTime').error?.message}</>
              )
            }
          />
          <DateTimePicker
            name="endTime"
            disablePast
            control={externalTicketForm.control}
            defaultValue={new Date()}
            disabled={false}
            onDateChange={(date: Date) => {
              externalTicketForm.setValue('endTime', date);
              externalTicketForm.trigger('startTime');
              externalTicketForm.trigger('endTime');
            }}
            label="Enter end time"
            error={!!externalTicketForm.getFieldState('endTime').error?.message}
            helperText={
              !!externalTicketForm.getFieldState('endTime').error?.message && (
                <>{externalTicketForm.getFieldState('endTime').error?.message}</>
              )
            }
          />
          <TextField
            {...externalTicketForm.register('link')}
            type="text"
            label="Show Link"
            placeholder="Enter the link to the show"
            error={!!externalTicketForm.formState.errors?.link?.message}
            helperText={
              !!externalTicketForm.formState.errors?.link?.message && (
                <>{externalTicketForm.formState.errors?.link?.message}</>
              )
            }
          />
        </FormSectionCont>
        <Box
          display="flex"
          justifyContent="center"
          width="100%"
          pt="20px"
        >
          <Button
            type="submit"
            isPrimary
            size="large"
            disabled={loading}
            isLoading={loading}
          >
            Submit
          </Button>
        </Box>
      </FormCont>
    </Box>
  );
};

const SuccessTypography = styled(Typography, {
  name: 'SuccessTypography',
})({
  color: colors.SystemGray900,
  ...typography.headlineSmall,
  textAlign: 'center',
});
