export const typography = {
  displayLarge: {
    fontSize: '6rem',
    lineHeight: '8.75rem',
    fontWeight: 700,
    letterSpacing: '-1.5px',
  },
  displayMedium: {
    fontSize: '3.75rem',
    lineHeight: '88px',
    fontWeight: 700,
    letterSpacing: '-1px',
  },
  displaySmall: {
    fontSize: '3rem',
    lineHeight: '4.5rem',
    fontWeight: 700,
    letterSpacing: '-0.5px',
  },
  headlineLarge: {
    fontSize: '2.25rem',
    lineHeight: '3.25rem',
    fontWeight: 700,
    letterSpacing: '-0.25px',
  },
  headlineMedium: {
    fontSize: '1.75rem',
    lineHeight: '2.5rem',
    fontWeight: 700,
    letterSpacing: '-0.25px',
  },
  headlineSmall: {
    fontSize: '1.313rem',
    lineHeight: '2rem',
    fontWeight: 700,
    letterSpacing: '0px',
  },
  bodyLargeRegular: {
    fontSize: '1.125rem',
    lineHeight: '1.75rem',
    fontWeight: 500,
    letterSpacing: '-0.5px',
  },
  bodyLargeBold: {
    fontSize: '1.125rem',
    lineHeight: '1.175rem',
    fontWeight: 700,
    letterSpacing: '0px',
  },
  bodyMediumRegular: {
    fontSize: '1rem',
    lineHeight: '1.5rem',
    fontWeight: 500,
    letterSpacing: '0px',
  },
  bodyMediumBold: {
    fontSize: '1rem',
    lineHeight: '1.5rem',
    fontWeight: 700,
    letterSpacing: '0px',
  },
  bodySmallRegular: {
    fontSize: '.875rem',
    lineHeight: '1.25rem',
    fontWeight: 500,
    letterSpacing: '0px',
  },
  bodySmallBold: {
    fontSize: '.875rem',
    lineHeight: '1.25rem',
    fontWeight: 700,
    letterSpacing: '0px',
  },
  buttonLarge: {
    fontSize: '1rem',
    lineHeight: '1.5rem',
    fontWeight: 700,
    letterSpacing: '-0.25px',
  },
  buttonSmall: {
    fontSize: '1rem',
    lineHeight: '1.25rem',
    fontWeight: 700,
    letterSpacing: '0px',
  },
  subtitle: {
    fontSize: '.813rem',
    lineHeight: '1.25rem',
    fontWeight: 700,
    letterSpacing: '0px',
  },
};
