import React, { FC } from 'react';

interface GoogleMapsEmbedProps {
  address: string;
}

export const GoogleMapsEmbed: FC<GoogleMapsEmbedProps> = ({ address }) => {
  return (
    <iframe
      width="100%"
      height="100%"
      loading="lazy"
      src={`https://www.google.com/maps/embed/v1/search?q=${address}&key=AIzaSyCZMhjQx75lWDtUC-AAkENbCdUuHBhAti0`}
    ></iframe>
  );
};
