import { Box, Typography, styled } from '@mui/material';
import React, { FC } from 'react';
import { colors } from '../../../ui/shared/Colors';
import { typography } from '../../../ui/shared/TypographySharedElements';
import CTABg from '../../../Images/VenueLP/ctaBG.png';
import { Button } from '../../../ui/buttons/Button';
import { useNavigate } from 'react-router-dom';
import { useOpenBookDemoLink } from '../bookDemo';

export const CTABox: FC = () => {
  const navigate = useNavigate();
  const openBookDemoLink = useOpenBookDemoLink();

  return (
    <CTABoxContainer>
      <CtaCover></CtaCover>
      <CtaInnerContainer>
        <CTAText>
          Together, we can make live music more transparent and profitable
          <DesktopTextPart>
            {' '}
            - all while keeping the warm community vibe that makes these experiences unforgettable.
          </DesktopTextPart>
          <MobileTextPart> for all.</MobileTextPart>
        </CTAText>
        <CTAInnerBox>
          <CTAInnerBoxText>Ready to join?</CTAInnerBoxText>
          <CTAButtonBox>
            <Button
              isPrimary
              size="large"
              disabled={false}
              onClick={() => navigate('/register/venue')}
            >
              Create free account
            </Button>
            <ReverseButton
              isPrimary
              size="large"
              disabled={false}
              onClick={openBookDemoLink}
            >
              {' '}
              Book a demo
            </ReverseButton>
          </CTAButtonBox>
        </CTAInnerBox>
      </CtaInnerContainer>
    </CTABoxContainer>
  );
};
//Together, we can make live music more transparent and profitable for all. -> text for mobile in CTAText
const CTABoxContainer = styled(Box, {
  name: 'CTABoxContainer',
})(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '772px',
  background: `url(${CTABg})`,
  backgroundRepeat: 'no-repeat',
  backgroundAttachment: 'fixed',
  backgroundPosition: 'center',
  backgroundSize: 'auto',
  [theme.breakpoints.down('md')]: {
    height: '500px',
  },
}));

const CTAText = styled(Typography, {
  name: 'CTAText',
})(({ theme }) => ({
  color: colors.SystemBlack,
  ...typography.headlineLarge,
  marginBottom: '120px',
  maxWidth: '900px',
  width: '100%',
  textAlign: 'center',
  marginLeft: 'auto',
  marginRight: 'auto',
  [theme.breakpoints.down('md')]: {
    marginBottom: '40px',
    ...typography.headlineMedium,
  },
}));

const CTAInnerBox = styled(Box, {
  name: 'CTAInnerBox',
})(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: colors.SystemWhite,
  maxWidth: '800px',
  width: '100%',
  borderRadius: '24px',
  padding: '32px',
  margin: '0 auto',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    gap: '20px',
  },
}));
const CTAInnerBoxText = styled(Box, {
  name: 'CTAInnerBoxText',
})(({ theme }) => ({
  color: colors.SystemBlack,
  ...typography.headlineLarge,
  [theme.breakpoints.down('md')]: {
    ...typography.headlineMedium,
  },
}));

const CtaInnerContainer = styled(Box, {
  name: 'CtaInnerContainer',
})(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  padding: '160px',
  zIndex: 11,
  [theme.breakpoints.down('md')]: {
    padding: '40px 20px',
  },
}));

const CtaCover = styled(Box, {
  name: 'CTACover',
})({
  position: 'absolute',
  left: 0,
  top: 0,
  backgroundColor: '#FFB54B',
  width: '100%',
  height: '100%',
  opacity: 0.9,
  zIndex: 10,
});
const CTAButtonBox = styled(Box, {
  name: 'CTAButtonBox',
})({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
});

const ReverseButton = styled(Button, {
  name: 'ReverseButton',
})({
  '&.MuiButtonBase-root.MuiButton-root': {
    backgroundColor: colors.SystemWhite,
    color: colors.SystemBlack,
    border: `2px solid ${colors.SystemBlack}`,
    '&:hover': {
      backgroundColor: colors.SystemGray100,
    },
  },
});

const MobileTextPart = styled(Box, {
  name: 'MobileTextPart',
})(({ theme }) => ({
  display: 'none',
  color: colors.SystemBlack,
  ...typography.headlineMedium,
  [theme.breakpoints.down('md')]: {
    display: 'inline',
  },
}));

const DesktopTextPart = styled(Box, {
  name: 'DesktopTextPart',
})(({ theme }) => ({
  display: 'inline',
  color: colors.SystemBlack,
  ...typography.headlineLarge,
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));
