import { zodResolver } from '@hookform/resolvers/zod';
import axios, { AxiosError } from 'axios';
import React, { useCallback, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ArtistPersonalDetailsForm } from '../../Forms/AccountInfoForms/Artist/ArtistPersonalDetailsForm';
import { ArtistPublicProfileForm } from '../../Forms/AccountInfoForms/Artist/ArtistPublicProfileForm';
import { HatchlinksForm } from '../../Forms/AccountInfoForms/HatchLinks/HatchlinksForm';
import { ArtistFormData } from '../../Forms/AccountInfoForms/Artist/ArtistFormData';
import { useAppSelector, useAppDispatch } from '../../hooks';
import {
  EditProfileHeadlineContainer,
  EditProfileFormContainer,
  EditProfileInnerContainer,
  StyledTabs,
  StyledTab,
  TabLabel,
  EditProfileTitle,
  StyledButton,
  CancelButton,
  TitleAndButtonsContainer,
} from './Shared/Styles';
import { addStatusMessage } from '../../Redux/UI/UISlice';
import { TabPanel } from './Shared/Other';
import { useCreateOrEditArtistProfileMutation } from '../../Redux/API/UserAPI';
import { ShareHatchLinks } from '../ShareHatchLinks/ShareHatchLinks';
import { ShareHatchLinkModal } from './Shared/ShareHatchLinkModal/ShareHatchlinkModal';
import { ImageUploader } from '../../ui/shared/ImageUploader';
import { FormSectionTitle } from './AccountSettings';
import { HatchlinksFormData } from '../../Forms/AccountInfoForms/HatchLinks/HatchlinksFormData';
import { FormSectionSubtitle } from '../../Forms/AccountInfoForms/Shared/RegistrationTypographies';
import { FormSectionContainer } from '../../Forms/AccountInfoForms/Shared/Containers';
import { CancelModal } from './CancelModal/CancelModal';
import { ViewType } from '../../Helpers/shared/Models/ViewType';

export const ArtistForm = (props: { id: string; startingTab: string }) => {
  const user = useAppSelector((select) => select.user.data);
  const [avatar, setAvatar] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dipatch = useAppDispatch();
  const [currentTab, setCurrentTab] = React.useState(props.startingTab || 'personalDetails');
  const [createOrEditArtistProfile, createOrEditArtistProfileStatus] = useCreateOrEditArtistProfileMutation();
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [shareHatchLinkModalOpen, setShareHatchLinkModalOpen] = useState(false);
  const [forceDirty, setForceDirty] = useState(false);

  const isDirty = () => {
    return artistForm.formState.isDirty || forceDirty;
  };

  //genre is obtained from the backend in all lowercase, so we need to format it
  const formattedGenre = user?.type?.artist?.genre
    .split(' ')
    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

  const initialArtistFormValues: ArtistFormData.ValuesFull = {
    firstName: user?.firstname ?? '',
    lastName: user?.lastname ?? '',
    stageName: user?.type?.artist?.stagename ?? null,
    genre: formattedGenre ?? 'None',
    subGenre: user?.type?.artist?.subgenre ?? null,
    recordLabel: {
      signed: !!user?.type?.artist?.recordLabelName,
      label: user?.type?.artist?.recordLabelName ?? null,
    },
    primaryCity: user?.meta?.primaryCity?.city
      ? user?.meta?.primaryCity
      : { city: user?.type?.artist?.primaryCity ?? '', displayAddress: user?.type?.artist?.primaryCity ?? '' },
    secondaryCity: user?.meta?.secondaryCity?.city
      ? user?.meta?.secondaryCity
      : user?.type?.artist?.secondaryCity
        ? { city: user?.type?.artist?.secondaryCity, displayAddress: user?.type?.artist?.secondaryCity }
        : null,
    instagram: user?.type?.artist?.socials?.instagram ?? null,
    tiktok: user?.type?.artist?.socials?.tikTokLink ?? null,
    spotify: user?.type?.artist?.socials?.spotifyLink ?? null,
    youtube: user?.type?.artist?.socials?.youtubeLink ?? null,
    livePerformance: user?.type?.artist?.livePerformance ?? null,
    about: user?.about ?? '',
    username: user?.username ?? '',
    externalLinks: user?.externalLinks ?? [],
    customOptions: {
      alternativeHatchLinkLabel: user?.customOptions?.alternativeHatchLinkLabel ?? false,
      colors: {},
    },
    contactEmail: user?.type?.artist?.contactEmail ?? null,
    contactPhone: user?.type?.artist?.contactPhone ?? null,
  };

  const artistForm = useForm({
    resolver: zodResolver(ArtistFormData.formSchemaFull),
    defaultValues: initialArtistFormValues,
    values: initialArtistFormValues,
    mode: 'all',
  });

  const submitArtist = async (data: ArtistFormData.ValuesFull) => {
    try {
      const formData = new FormData();
      formData.append(
        'body',
        JSON.stringify({
          firstName: data.firstName,
          lastName: data.lastName,
          stageName: data.stageName,
          genre: data.genre,
          subgenre: data.subGenre,
          recordLabelName: data.recordLabel.signed === true ? data.recordLabel.label : null,
          primaryCity: {
            name: data.primaryCity.city,
            meta: data.primaryCity,
          },
          secondaryCity: data.secondaryCity?.displayAddress
            ? {
                name: data.secondaryCity.city,
                meta: data.secondaryCity,
              }
            : null,
          socials: {
            instagramLink: data.instagram ?? null,
            youtubeLink: data.youtube ?? null,
            spotifyLink: data.spotify ?? null,
            tikTokLink: data.tiktok ?? null,
          },
          livePerformance: data.livePerformance ?? null,
          about: data.about,
          username: data.username,
          hatchLinks: data.externalLinks,
          customOptions: {
            alternativeHatchLinkLabel: data.customOptions.alternativeHatchLinkLabel ?? false,
            colors: data.customOptions.colors,
          },
          contactEmail: data.contactEmail,
          contactPhone: data.contactPhone,
        }),
      );
      formData.append('avatar', avatar);
      console.log('body', formData.get('body'));
      const response = await createOrEditArtistProfile(formData);
      if ('error' in response) {
        window.alert('Please fix any errors before saving.');
        setCurrentTab('personalDetails');
      } else {
        artistForm.reset(artistForm.getValues());
        setForceDirty(false);
        dipatch(addStatusMessage({ type: 'success', message: 'Profile successfully updated' }));
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response.status === 413) {
        window.alert('Uploaded images are too large');
        setCurrentTab('publicProfile');
      } else {
        setCurrentTab('personalDetails');
        window.alert('Unknown server error');
        console.log(error);
      }
    }
  };

  const handleSaveChanges = () => {
    artistForm.trigger();
    const data = artistForm.getValues();
    // Validate each tab manually so we can set the correct tab if there's an error
    if (!ArtistFormData.step1Schema.safeParse(data).success) return setCurrentTab('personalDetails');
    if (!ArtistFormData.step2Schema.safeParse(data).success) return setCurrentTab('publicProfile');
    if (!HatchlinksFormData.formSchema.safeParse(data).success) return setCurrentTab('hatchlinks');
    if (currentTab === 'hatchlinks') {
      setShareHatchLinkModalOpen(true);
    }
    setLoading(true);
    artistForm.handleSubmit(submitArtist)();
    setLoading(false);
  };

  const handleChangeTab = useCallback((e: React.SyntheticEvent, newTab: string) => {
    e.preventDefault();
    setCurrentTab(newTab);
  }, []);

  return (
    <FormProvider {...artistForm}>
      <EditProfileHeadlineContainer>
        <EditProfileInnerContainer>
          <TitleAndButtonsContainer>
            <EditProfileTitle>Edit your profile</EditProfileTitle>
            <CancelButton
              size="large"
              onClick={() => (isDirty() ? setCancelModalOpen(true) : navigate(-1))} //navigate(-1) is the same as window.history.back() but rerenders the route instead of reloading the page
            >
              Back
            </CancelButton>
            <StyledButton
              isPrimary
              size="large"
              isLoading={loading}
              onClick={handleSaveChanges}
              disabled={!isDirty() || loading}
            >
              Save changes
            </StyledButton>
          </TitleAndButtonsContainer>
        </EditProfileInnerContainer>
        <EditProfileInnerContainer>
          <StyledTabs
            variant="scrollable" //support for mobile
            value={currentTab}
            onChange={handleChangeTab}
          >
            <StyledTab
              value="personalDetails"
              label={<TabLabel>Personal Details</TabLabel>}
            />
            <StyledTab
              value="publicProfile"
              label={<TabLabel>Public Profile</TabLabel>}
            />
            <StyledTab
              value="hatchlinks"
              label={<TabLabel>HatchLink</TabLabel>}
            />
            <StyledTab
              value="photos"
              label={<TabLabel>Your Photos</TabLabel>}
            />
            <StyledTab
              value="assets"
              label={<TabLabel>Assets</TabLabel>}
            />
          </StyledTabs>
        </EditProfileInnerContainer>
      </EditProfileHeadlineContainer>
      <form onSubmit={artistForm.handleSubmit(submitArtist)}>
        <TabPanel
          index="personalDetails"
          value={currentTab}
        >
          <EditProfileFormContainer>
            <EditProfileInnerContainer>
              <ArtistPersonalDetailsForm />
            </EditProfileInnerContainer>
          </EditProfileFormContainer>
        </TabPanel>
        <TabPanel
          index="publicProfile"
          value={currentTab}
        >
          <EditProfileFormContainer>
            <EditProfileInnerContainer>
              <ArtistPublicProfileForm
                setAvatar={setAvatar}
                selectedAvatar={avatar}
                defaultAvatar={user?.avatarWebP}
                setForceDirty={setForceDirty}
              />
            </EditProfileInnerContainer>
          </EditProfileFormContainer>
        </TabPanel>
        <TabPanel
          index="hatchlinks"
          value={currentTab}
        >
          <EditProfileFormContainer>
            <EditProfileInnerContainer>
              <HatchlinksForm
                setForceDirty={setForceDirty}
                profileName={user?.type?.artist?.stagename}
                avatarUrl={user?.avatarWebP}
                colors={user?.customOptions?.colors}
                viewType={ViewType.Artist}
              />
            </EditProfileInnerContainer>
          </EditProfileFormContainer>
        </TabPanel>
        <TabPanel
          index="photos"
          value={currentTab}
        >
          <EditProfileFormContainer>
            <EditProfileInnerContainer>
              {user && (
                <FormSectionContainer>
                  <FormSectionTitle>Your Photos</FormSectionTitle>
                  <FormSectionSubtitle>These photos will show up on your public profile.</FormSectionSubtitle>
                  <ImageUploader
                    maxNumber={6}
                    entityType="artist"
                    resourceId={user.displayUID}
                    field="images"
                    forceDirty={setForceDirty}
                  />
                </FormSectionContainer>
              )}
            </EditProfileInnerContainer>
          </EditProfileFormContainer>
        </TabPanel>
        <TabPanel
          index="assets"
          value={currentTab}
        >
          <EditProfileFormContainer>
            <EditProfileInnerContainer>
              {user && (
                <FormSectionContainer>
                  <FormSectionSubtitle>
                    These are the most commonly requested assets from bookers at venues. Add your headshot and stage
                    plot below so that the venue has them ahead of time.
                  </FormSectionSubtitle>
                  <FormSectionTitle>Headshots</FormSectionTitle>
                  <ImageUploader
                    maxNumber={5}
                    entityType="artist"
                    resourceId={user.displayUID}
                    field="headshots"
                    forceDirty={setForceDirty}
                  />
                  <FormSectionTitle>Stage Plot</FormSectionTitle>
                  <ImageUploader
                    maxNumber={5}
                    entityType="artist"
                    resourceId={user.displayUID}
                    field="stagePlot"
                    forceDirty={setForceDirty}
                  />
                </FormSectionContainer>
              )}
            </EditProfileInnerContainer>
          </EditProfileFormContainer>
        </TabPanel>
      </form>
      <CancelModal
        open={cancelModalOpen}
        setOpen={setCancelModalOpen}
      />
      <ShareHatchLinks
        open={shareHatchLinkModalOpen}
        setOpen={setShareHatchLinkModalOpen}
        link={
          user?.username
            ? `https://tunehatch.link/${user.username}`
            : `https://tunehatch.com/hl/artist/${user.displayUID}`
        }
      />
    </FormProvider>
  );
};
