import { get } from 'lodash';

interface CreateParams<Response> {
  message: string;
  response: Response;
  status: number;
}

export class HttpError<Response = any> extends Error {
  public response: Response;
  public status: number;

  constructor(params: CreateParams<Response>) {
    super(params.message);
    this.response = params.response;
    this.status = params.status;
  }
}

type BadRequestResponse = {
  message: string;
  code: string;
  metadata?: unknown;
};

export function isBadRequestResponse(response: unknown): response is BadRequestResponse {
  return typeof get(response, 'code') === 'string' && typeof get(response, 'message') === 'string';
}

export class BadRequestError extends HttpError<BadRequestResponse> {}
