import React, { FC } from 'react';
import { ArtistForm } from './ArtistForm';
import { VenueForm } from './VenueForm';
import { PromoterForm } from './PromoterForm';
import { useParams } from 'react-router-dom';
import { ViewType } from '../../Helpers/shared/Models/ViewType';

export const EditProfile: FC = () => {
  const { userType, profileID, startingTab } = useParams();

  if (userType === ViewType.Artist) {
    return (
      <ArtistForm
        id={profileID}
        startingTab={startingTab}
      ></ArtistForm>
    );
  } else if (userType === ViewType.Venue) {
    return (
      <VenueForm
        id={profileID}
        startingTab={startingTab}
      ></VenueForm>
    );
  } else if (userType === ViewType.SRGroup) {
    return (
      <PromoterForm
        id={profileID}
        startingTab={startingTab}
      ></PromoterForm>
    );
  }
  return <b>Please login</b>;
};
